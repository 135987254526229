<template>
	<f-popover size="medium" :open="true" :overlay="true">
		<f-div width="432px" state="secondary" direction="column" variant="curved" padding="none">
			<f-div align="middle-center" padding="medium" border="small solid subtle bottom">
				<f-div height="hug-content" padding="none"
					><f-text variant="heading" size="medium" weight="bold" state="default" align="left">
						{{ componentTitle }} is not deprecated
					</f-text>
				</f-div>
				<f-div width="hug-content" height="hug-content"
					><f-icon-button
						icon="i-close"
						variant="block"
						category="packed"
						state="neutral"
						size="small"
						data-qa="close-deprecate-modal-button"
						@click="$emit('close')"
					></f-icon-button
				></f-div>
			</f-div>

			<f-div gap="medium" direction="column" padding="large" height="hug-content">
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div gap="small" width="hug-content">
						<f-text
							inline
							variant="heading"
							size="small"
							weight="medium"
							state="secondary"
							align="left"
						>
							Reason for rejecting deprecation
						</f-text>
					</f-div>
					<f-div>
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="medium"
							state="secondary"
							align="left"
						>
							{{ deprecationDetails.justification }}
						</f-text>
					</f-div>
				</f-div>
			</f-div>

			<f-divider variant="solid" state="subtle"></f-divider>

			<f-div padding="medium" align="middle-right" gap="small">
				<f-button
					label="GOT IT"
					variant="round"
					category="outline"
					size="small"
					state="neutral"
					data-qa-id="close-modal-deprecation-request-action-reject"
					@click="$emit('acknowledgeDeprecationRejectionAction')"
				></f-button>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import { workflowStore } from "@/modules/workflow/workflow-store";
import { Component } from "@/protocol/cockpit";

export default defineComponent({
	name: "DeprecateViewReason",

	props: {
		selectedComponent: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["close", "acknowledgeDeprecationRejectionAction"],

	computed: {
		...mapState(workflowStore, {
			deprecationDetails: "deprecationDetails"
		}),

		componentTitle() {
			return `${this.selectedComponent.name} ${this.selectedComponent.version}`;
		}
	}
});
</script>
