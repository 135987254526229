<template>
	<f-div direction="column" width="fill-container" height="fill-container" overflow="hidden">
		<f-div height="hug-content" padding="medium" border="small solid subtle bottom">
			<f-text variant="para" size="medium" weight="bold" state="default" align="left">
				Request details
			</f-text>
		</f-div>

		<f-div
			direction="column"
			gap="medium"
			padding="medium"
			overflow="scroll"
			:show-scrollbar="true"
		>
			<f-div gap="small" height="hug-content" direction="column">
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Status
				</f-text>
				<ComponentStatusBadge :component="component" />
			</f-div>
			<f-div v-if="isComponentRejected" align="middle-left" direction="column" height="hug-content">
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Rejected by
				</f-text>
				<f-text variant="para" size="medium" weight="regular" state="default" align="left">
					{{ component.approvedBy }}
				</f-text>
			</f-div>
			<f-div
				v-if="isComponentRejected && rejectedItems.length"
				direction="column"
				height="hug-content"
			>
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Rejected items
				</f-text>
				<f-text
					v-for="item in rejectedItems"
					:key="item"
					variant="para"
					size="small"
					weight="regular"
					state="default"
					align="left"
				>
					&#8226; {{ item }}
				</f-text>
			</f-div>
			<f-div v-if="isComponentRejected" align="middle-left" direction="column" height="hug-content">
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Reason for rejection
				</f-text>
				<f-text variant="para" size="medium" weight="regular" state="default" align="left">
					{{ component.comment }}
				</f-text>
			</f-div>
			<f-div gap="x-small" align="middle-left" direction="column" height="hug-content">
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Requested by
				</f-text>
				<f-text variant="para" size="medium" weight="regular" state="default" align="left">
					{{ component.requestedByEmail }}
				</f-text>
			</f-div>
			<f-div gap="x-small" align="middle-left" direction="column" height="hug-content">
				<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
					Requested on
				</f-text>

				<f-text
					v-if="component.createdAt"
					variant="para"
					size="medium"
					weight="regular"
					state="default"
					align="left"
				>
					{{ formatDateString(component.createdAt) }}
				</f-text>
			</f-div>
		</f-div>

		<f-div
			v-if="hasApprovalAccess"
			padding="large"
			direction="column"
			width="fill-container"
			height="hug-content"
			align="bottom-center"
		>
			<f-div width="100%" padding="none" height="hug-content" gap="none">
				<f-div
					v-if="showActions"
					direction="column"
					gap="none"
					padding="none"
					state="secondary"
					width="100%"
					height="hug-content"
				>
					<f-div
						v-for="(actionButton, index) in actionTypeOptions"
						:key="index"
						padding="medium small medium small"
						gap="small"
						border="small solid subtle bottom"
						:tooltip="actionButton.tooltip"
						:data-qa="`classification-action-buttons-${actionButton.label}`"
						:clickable="true"
						@click="onActionTypeSelection(actionButton.type)"
					>
						<f-icon :source="actionButton.icon" :state="actionButton.state"></f-icon>
						<f-text variant="para" size="medium" weight="medium" :state="actionButton.textState">{{
							actionButton.label
						}}</f-text>
					</f-div>
				</f-div>
				<f-button
					id="popoverTarget"
					variant="block"
					size="medium"
					category="outline"
					state="neutral"
					:disabled="!isClassificationActionEnabled"
					:loading="false"
					:icon-right="showActions ? 'i-chevron-down' : 'i-chevron-up'"
					label="SELECT AN ACTION"
					data-qa="select-classification-action"
					@click="showActions = !showActions"
				></f-button>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FIconState, FTextStateProp } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import ComponentStatusBadge from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentStatusBadge.vue";
import {
	ClassificationActionType,
	rejectClassificationFlagMap,
	getComponentFlags
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_STATUS } from "@/shared/constants";
import { formatDateString } from "@/utils/format-date";

export default defineComponent({
	name: "ClassificationActionRightPane",

	components: {
		ComponentStatusBadge
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["show-classification-action-modal"],

	data() {
		return {
			showActions: false
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore, componentCatalogStore),

		hasApprovalAccess() {
			return this.authStore.userPermissions[USER_PERMISSIONS.workflowApprove]?.enabled;
		},

		isClassificationActionEnabled() {
			return this.component.status === COMPONENT_STATUS.classificationApprovalPending;
		},

		isComponentRejected() {
			return this.component.status === COMPONENT_STATUS.classificationApprovalRejected;
		},

		rejectedItems() {
			return Object.keys(getComponentFlags(this.component))
				.filter(
					key => getComponentFlags(this.component)[key as keyof typeof rejectClassificationFlagMap]
				)
				.map(key => {
					return rejectClassificationFlagMap[key as keyof typeof rejectClassificationFlagMap];
				});
		},

		actionTypeOptions() {
			return [
				{
					type: "approve" as ClassificationActionType,
					label: "APPROVE",
					tooltip: "Approve Classification",
					icon: "i-tick-fill",
					state: "success" as FIconState,
					textState: "success" as FTextStateProp
				},
				{
					type: "reject" as ClassificationActionType,
					label: "REJECT",
					tooltip: "Reject Classification",
					icon: "i-stop",
					state: "danger" as FIconState,
					textState: "danger" as FTextStateProp
				}
			];
		}
	},

	methods: {
		formatDateString,

		onActionTypeSelection(actionType: ClassificationActionType) {
			this.$emit("show-classification-action-modal", actionType);
			this.showActions = false;
		}
	}
});
</script>
