import { defineStore } from "pinia";

import { DEFAULT_UPLOADED_FILE_OBJ, LzComponentReport } from "./lz-compliance-report-types";

export const lzComplianceReportStore = defineStore("lzComplianceReportStore", {
	state: () => ({
		uploadedFile: DEFAULT_UPLOADED_FILE_OBJ,
		lzReportContent: {} as LzComponentReport,
		loadingReport: false
	}),

	actions: {
		setUploadedFileData(fileData: File | undefined) {
			this.uploadedFile = fileData ? fileData : DEFAULT_UPLOADED_FILE_OBJ;
		},

		generatingReport(status: boolean) {
			this.loadingReport = status;
		},

		setLzCompilanceReportContent(content: LzComponentReport) {
			this.lzReportContent = content;
		}
	}
});
