<script setup lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData } from "@ollion/flow-table";
import { computed, onMounted, ref } from "vue";

import { documentMappingStore, getMatchStrengthState } from "../document-mapping-store";

import MappingRefinementDialog from "./MappingRefinementDialog.vue";

const emit = defineEmits(["started-mapping", "finished-mapping", "mapping-progress"]);
const refinedStatmentId = ref<string | null>(null);
const documentStore = documentMappingStore();
const documentState = computed(() => documentStore.currentDocumentState);

const statements = computed(() => {
	return documentStore.currentSelectedStatements;
});

onMounted(async () => {
	emit("started-mapping");
	await documentStore.MAP_SELECTED_STATEMENTS(progress => {
		emit("mapping-progress", progress);
	});
	emit("finished-mapping");
});

const tableData = computed((): FTableSchemaData => {
	return {
		header: {
			id: {
				value: "ID",
				width: "auto",
				align: "middle-center"
			},
			statement: {
				value: "Statement",
				width: "auto",
				align: "middle-left"
			},
			mapping: {
				value: "Compliance statement mapping",
				width: "30%",
				align: "middle-left"
			}
		},
		rows: statements.value.map(({ displayId, str, id }) => {
			const cobitMap = documentState.value.cobitMap[id];
			const userCobitMap = documentState.value.similarityFeedback[id];

			const similarity = userCobitMap?.modelScore ?? cobitMap?.similarity ?? 0;
			const activity = userCobitMap?.statement2 ?? cobitMap?.activity ?? "";
			const mapId = userCobitMap?.id ?? cobitMap?.id ?? "";

			const parsedId = Number(displayId);

			return {
				id,
				data: {
					id: {
						value: isNaN(parsedId) ? displayId : parsedId
					},
					statement: {
						value: str,
						template: () =>
							html`<f-text variant="para" size="small" weight="medium" align="left">${str}</f-text>`
					},
					mapping: {
						value: similarity,
						template: () => {
							if (cobitMap) {
								const roundedSimilarity = Math.floor(similarity * 100);
								const tagState = getMatchStrengthState(similarity);

								return html`
									<f-div width="100%" align="middle-left" gap="medium">
										<f-div>
											<f-tag
												clickable
												category="outline"
												size="small"
												tooltip="${activity}"
												state="${tagState}"
												label="${mapId} | ${roundedSimilarity}"
											>
											</f-tag>
										</f-div>

										<f-icon
											style="visibility:var(--hide-icon);"
											source="i-setting"
											clickable
											@click=${() => showMappingRefinement(id)}
										></f-icon>
									</f-div>
								`;
							}

							return html` <f-div width="100%" loading="skeleton"
								><f-div width="100%"></f-div
							></f-div>`;
						}
					}
				}
			};
		})
	};
});

function showMappingRefinement(statementId: string) {
	refinedStatmentId.value = statementId;
}

const headerCellTemplate = (val: string) => {
	return html` <f-div gap="small" align="middle-center">
		<f-text variant="para" size="small" weight="medium">${val}</f-text>
	</f-div>`;
};
</script>

<template>
	<MappingRefinementDialog
		v-if="refinedStatmentId"
		:statement-id="refinedStatmentId"
		@close="refinedStatmentId = null"
	/>

	<f-div v-show="refinedStatmentId === null" padding="none medium">
		<f-table-schema
			variant="underlined"
			:data="tableData"
			:show-search-bar="true"
			:header-cell-template="headerCellTemplate"
			:highlight-hover="true"
			:highlight-column-hover="false"
			:rows-per-page="200"
			sticky-cell-background="secondary"
			sticky-header
			size="small"
		>
			<f-div slot="no-data" state="warning" variant="curved" width="100%" padding="medium">
				<f-text state="default">No results found for your search query.</f-text>
			</f-div>
		</f-table-schema>
	</f-div>
</template>
../document-mapping-store
