import { AxiosError, AxiosResponse } from "axios";

export type ServerError = {
	Code: string;
	Kind: number;
	Message: string;
	Causes: unknown[];
	Nested: ServerError | null;
};

export function getErrorMessage(error: unknown) {
	if (typeof error === "string") {
		return error;
	}

	const axiosError = (error as AxiosError<ServerError>).response?.data;

	const axiosResponse = (error as AxiosResponse<ServerError>).data;

	if (axiosError) {
		let message: string = "";

		if (typeof axiosError === "string") {
			message = axiosError;
		} else if (typeof axiosError === "object" && "Message" in axiosError) {
			message = axiosError.Message;
		}

		let nested: ServerError["Nested"] = axiosError.Nested;

		// For nested messages, ignore the parent message and merge all nested messages
		if (nested) {
			const nestedMessages: string[] = [];
			while (nested) {
				nestedMessages.push(nested.Message);
				nested = nested.Nested;
			}
			message = nestedMessages.join(":");
		}

		return message;
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (axiosResponse) {
		return axiosResponse.Message;
	} else if ("message" in (error as Error)) {
		return (error as Error).message;
	}

	return JSON.stringify(error);
}
