<template>
	<f-div padding="small" width="100%" loading="loader" align="middle-center"></f-div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "FullPageLoader"
});
</script>
