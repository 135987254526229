import { FIconState, FTextStateProp } from "@ollion/flow-core";

import { Component, JobStatus } from "@/protocol/cockpit";

export interface Level {
	name: string;
	id: string;
}

export interface TaxonomyClassificationPayload {
	level1: Level | undefined;
	level2: Level | undefined;
	level3: Level | undefined;
	level4: Level | undefined;
	level5: Level | undefined;
	level6: Level | undefined;
	level7: Level | undefined;
	level8: Level | undefined;
}

export const onboardingTabIds = ["details", "manual", "automated", "pipeline-logs"] as const;

export type OnboardingTabIdType = (typeof onboardingTabIds)[number];

export type ComponentOnboardingTab = {
	name: string;
	id: OnboardingTabIdType;
	contentComponentName: string;
	progress?: {
		percentage: number;
	};
};

export type ProgressBar = `${number}%`;

export const onboardingTabs = {
	serviceDetails: {
		id: "details",
		name: "Service details",
		contentComponentName: "ComponentOnboardingDetailTab"
	},

	softwareDetails: {
		id: "details",
		name: "Software details",
		contentComponentName: "SoftwareDetailTab"
	},

	assetDetails: {
		id: "details",
		name: "Asset details",
		contentComponentName: "AssetDetailTab"
	},

	manual: {
		id: "manual",
		name: "Manual",
		contentComponentName: "ComponentOnboardingManualTab"
	},

	automated: {
		id: "automated",
		name: "Automated",
		contentComponentName: "ComponentOnboardingAutomatedTab"
	},

	pipelineLogs: {
		id: "pipeline-logs",
		name: "Pipeline logs",
		contentComponentName: "ComponentOnboardingPipelineLogs"
	}
} as Record<OnboardingTabKey, ComponentOnboardingTab>;

export type OnboardingTabKey =
	| "serviceDetails"
	| "softwareDetails"
	| "assetDetails"
	| "manual"
	| "automated"
	| "pipelineLogs";

export type TaxonIconsMap = {
	icon: string;
	text: string;
};

export const TaxonMappingIcons: Record<string, TaxonIconsMap> = {
	T1: {
		icon: "i-domain",
		text: "Domain"
	},
	T2: {
		icon: "i-division",
		text: "Division"
	},
	T3: {
		icon: "i-type",
		text: "Type"
	},

	T4: {
		icon: "i-category",
		text: "Category"
	},
	T5: {
		icon: "i-name",
		text: "Name"
	},
	T6: {
		icon: "i-class",
		text: "Class"
	},
	T7: {
		icon: "i-family",
		text: "Family"
	},
	T8: {
		icon: "i-canonical",
		text: "Canonical Name"
	}
};

export type SelectedStatement = {
	value?: string | undefined;
	statementId: string;
	documentId: string;
	hasEvidences: boolean;
	isRejected: boolean;
	isApproved: boolean;
};

export const JOB_STATUS_TYPES: Record<JobStatus, string> = {
	JOB_STATUS_UNKNOWN: "Unknown",
	JOB_PENDING: "Pending...",
	JOB_IN_PROGRESS: "Running...",
	JOB_COMPLETED: "Completed",
	JOB_FAILED: "Failed"
};

export const COMPONENT_CARD_LIST_ACTION_TYPES: Record<string, string> = {
	componentViewDetails: "component-view-details",
	viewOnConstellationMap: "view-on-constellation-map",
	componentCopyLink: "component-copy-link",
	componentUnlink: "component-i-unlink"
};

export enum ClassificationLevelNames {
	classification1 = "classification1",
	classification2 = "classification2",
	classification3 = "classification3",
	classification4 = "classification4",
	classification5 = "classification5",
	classification6 = "classification6",
	classification7 = "classification7",
	classification8 = "classification8",
	justification = "justification",
	description = "description"
}

export type ValidationRule = {
	validator: (value: string) => boolean;
	message: string;
};

export type ClassificationDetailRowType = {
	classificationLevelKey: ClassificationLevelNames;
	icon: string;
	name: string;
	selectionOptions?: {
		options: string[];
		disabled: boolean;
		validationRules?: ValidationRule[];
	};
	textareaOptions?: {
		placeholder: string;
		validationRules: ValidationRule[];
	};
	isFlagged: boolean;
	showField: boolean;
};

export type ClassificationActionDetails = {
	key: string;
	title: string;
	value: string;
};

export type ClassificationActionType = ClassificationApprove | ClassificationReject;

export type ClassificationApprove = "approve";
export type ClassificationReject = "reject";

export const rejectClassificationFlagMap = {
	flagLevel1: "Domain",
	flagLevel2: "Division",
	flagLevel3: "Inventory category",
	flagLevel4: "Inventory name",
	flagLevel5: "Inventory type",
	flagLevel6: "Class",
	flagLevel7: "Family",
	flagLevel8: "Canonical Name"
};

export type ClassificationTaxonValues<T> = {
	[P in ClassificationLevelNames]: T;
};
export interface COMPONENT_CLASSIFICATION_LEVELS {
	classification1: string;
	classification2: string;
	classification3: string;
	classification4: string;
	classification5: string;
	classification6: string;
	classification7: string;
	classification8: string;
}

export interface COMPONENT_CLASSIFICATION_FLAGS {
	flagLevel1?: boolean;
	flagLevel2?: boolean;
	flagLevel3?: boolean;
	flagLevel4?: boolean;
	flagLevel5?: boolean;
	flagLevel6?: boolean;
	flagLevel7?: boolean;
	flagLevel8?: boolean;
}

export const getTaxonomiesList = (component: Component): string[] => {
	return [
		component.classification1 ?? "",
		component.classification2 ?? "",
		component.classification3 ?? "",
		component.classification4 ?? "",
		component.classification5 ?? "",
		component.classification6 ?? "",
		component.classification7 ?? "",
		component.classification8 ?? ""
	];
};

export const getComponentClassificationLevels = (
	component: Component
): COMPONENT_CLASSIFICATION_LEVELS => {
	return {
		classification1: component.classification1 ?? "",
		classification2: component.classification2 ?? "",
		classification3: component.classification3 ?? "",
		classification4: component.classification4 ?? "",
		classification5: component.classification5 ?? "",
		classification6: component.classification6 ?? "",
		classification7: component.classification7 ?? "",
		classification8: component.classification8 ?? ""
	};
};

export const getComponentFlags = (component: Component): COMPONENT_CLASSIFICATION_FLAGS => {
	return {
		flagLevel1: component.flagLevel1 ?? false,
		flagLevel2: component.flagLevel2 ?? false,
		flagLevel3: component.flagLevel3 ?? false,
		flagLevel4: component.flagLevel4 ?? false,
		flagLevel5: component.flagLevel5 ?? false,
		flagLevel6: component.flagLevel6 ?? false,
		flagLevel7: component.flagLevel7 ?? false,
		flagLevel8: component.flagLevel8 ?? false
	};
};

export type QueryFilters = {
	statusFilter?: string | null;
	typeFilter?: string | null;
	activeTab?: "published" | "drafts";
	searchText?: string;
};

export interface IconState {
	icon: string;
	state: FIconState;
	tooltip?: string;
}
export interface BestPracticeReportStatements {
	statement: string;
	statementId: string;
	statementStatusIcon: IconState;
	evidenceStatusIcons: IconState;
	evidenceId?: string;
}

export type DocumentStats = {
	totalStatements: number;
	noEvidence: number;
	approvedStatements: number;
	rejectedStatements: number;
	pendingStatements: number;
};

export type DocumentCounts = {
	label: string;
	count: number;
	state: FTextStateProp;
};
