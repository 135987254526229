<template>
	<!--Start : multi-line slab -->
	<f-div
		v-if="controlSetStatements.length === 0"
		data-qa="no-statements-found-error"
		align="middle-center"
		padding="medium"
	>
		<f-text variant="heading" size="small" state="secondary" align="center">
			No statements found for this component.
		</f-text>
	</f-div>
	<f-div
		v-for="(controlStatement, index) in controlSetStatements"
		v-else
		:key="controlStatement.statementId"
		width="fill-container"
		direction="column"
		:padding="
			index + 1 < controlSetStatements.length ? 'small none none small' : 'small none x-large small'
		"
		gap="small"
	>
		<f-div width="fill-container" gap="none" height="hug-content" direction="column" padding="none">
			<f-div
				width="fill-container"
				gap="large"
				height="hug-content"
				direction="row"
				padding="small"
			>
				<!--Start : slab-left -->
				<f-div data-f-id="slab-left" height="hug-content" width="hug-content">
					<f-icon
						:source="controlStatement.statementStatusIcon.icon"
						size="small"
						:state="controlStatement.statementStatusIcon.state"
						:tooltip="controlStatement.statementStatusIcon.tooltip"
					></f-icon>
				</f-div>
				<!--End : slab-left -->
				<f-div width="fill-container" gap="x-small" direction="column">
					<!--Start : slab-middle  -->
					<f-div
						data-f-id="slab-middle"
						direction="row"
						gap="medium"
						align="middle-left"
						width="fill-container"
					>
						<f-text variant="para" size="medium" state="secondary" inline>
							{{ controlStatement.statement }}
						</f-text>
					</f-div>
					<!--End : slab-middle  -->
				</f-div>
				<!--START : slab-right -->
				<f-div data-f-id="slab-right" width="22%" gap="x-small" direction="column">
					<f-div align="top-right" gap="medium">
						<f-text
							variant="para"
							size="small"
							state="subtle"
							:inline="true"
							tooltip="Total evidences"
							>{{ controlStatement.totalEvidences }}</f-text
						>
						<f-div v-if="controlStatement.evidences" width="hug-content">
							<a :href="controlStatement.evidences.location" target="_blank">
								<f-icon
									source="i-view"
									size="small"
									state="success"
									tooltip="View evidence"
								></f-icon>
							</a>
						</f-div>
					</f-div>
				</f-div>
				<!--End : slab-right -->
			</f-div>
			<f-div
				v-if="
					controlStatement.regulatoryOrgStatements &&
					controlStatement.regulatoryOrgStatements.length > 0
				"
				width="fill-container"
				gap="none"
				height="hug-content"
				direction="column"
				padding="none none none large"
			>
				<f-div
					v-for="(regulatoryStatement, key) in controlStatement.regulatoryOrgStatements"
					:key="key"
					direction="column"
					gap="small"
					width="fill-container"
					padding="small"
				>
					<f-div
						width="fill-container"
						padding="medium medium medium none"
						gap="medium"
						align="top-center"
					>
						<f-div width="hug-content" align="top-center">
							<f-pictogram
								size="medium"
								state="default"
								variant="squircle"
								category="fill"
								:source="regulatoryStatement.documentName?.toLocaleUpperCase()"
							>
							</f-pictogram>
						</f-div>

						<f-div direction="column" align="middle-center" gap="small">
							<!--Start : top row -->
							<f-div data-f-id="slab-left" height="hug-content" align="top-left" gap="small">
								<f-text
									variant="heading"
									size="small"
									state="subtle"
									weight="bold"
									tooltip="Regulatory document version"
								>
									{{ regulatoryStatement.documentName }}
								</f-text>

								<f-div
									align="middle-right"
									gap="small"
									width="hug-content"
									padding="none none none x-large"
								>
									<f-text variant="para" size="small" state="subtle" align="right">
										{{ regulatoryStatement.documentVersion }}
									</f-text>
								</f-div>
							</f-div>
							<f-div data-f-id="slab-left" height="hug-content" align="top-center">
								<f-text variant="para" size="small" state="subtle">
									{{ regulatoryStatement.statement }}
								</f-text>
							</f-div>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<f-div v-else data-qa="no-statements-found-error" align="middle-center" padding="medium">
				<f-text variant="heading" size="x-small" state="secondary" align="center">
					No regulatory organisation statements found for this best practice.
				</f-text>
			</f-div>
		</f-div>
		<f-divider
			v-if="index + 1 < controlSetStatements.length"
			state="subtle"
			variant="dashed"
			size="large"
		></f-divider>
	</f-div>
	<!--End : multi-line slab -->
</template>

<script lang="ts">
import { FIconState } from "@ollion/flow-core";
import { PropType, defineComponent } from "vue";

import { LzComponent } from "@/modules/lz-compliance-report/lz-compliance-report-types";

export default defineComponent({
	name: "BestPracticeStatements",

	props: {
		// eslint-disable-next-line vue/no-unused-properties
		component: {
			type: Object as PropType<LzComponent>,
			required: true
		}
	},

	computed: {
		controlSetStatements() {
			const statements =
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				this.component.bestPractices?.flatMap(bp =>
					bp.statements.length > 0 ? bp.statements : []
				) || [];

			if (statements.length === 0) {
				return [];
			}
			return statements.map(controlStatementVal => {
				const { evidences } = controlStatementVal;
				const allApproved = evidences.every(evidence => evidence.status === "Passed");
				const evidenceCount = evidences.length;

				const statementStatusIcon = () => {
					if (allApproved && evidenceCount > 0) {
						return {
							icon: "i-shield-success",
							state: "success" as FIconState,
							tooltip: "Compliant"
						};
					}
					return {
						icon: "i-alert",
						state: "danger" as FIconState,
						tooltip: "Non Compliant"
					};
				};

				const regulatoryOrgStatements =
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					controlStatementVal.activities && controlStatementVal.activities.length > 0
						? controlStatementVal.activities.flatMap(activity =>
								// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
								activity.regulatoryOrgStatements && activity.regulatoryOrgStatements.length > 0
									? activity.regulatoryOrgStatements
									: []
							)
						: [];
				return {
					statementStatusIcon: statementStatusIcon(),
					statementId: controlStatementVal.id,
					statement: controlStatementVal.statement,
					totalEvidences: evidenceCount,
					regulatoryOrgStatements,
					evidences: controlStatementVal.evidences[0]
				};
			});
		}
	}
});
</script>
