<template>
	<f-div width="100%" padding="small" gap="medium" state="subtle" height="hug-content">
		<f-div gap="none">
			<f-div v-if="isControlSelected" align="middle-left" padding="none">
				<f-icon-button
					inline
					icon="i-arrow-left"
					variant="block"
					size="small"
					state="custom, #fff"
					type="transparent"
					data-qa="compliance-details-back-button"
					@click="$emit('update-control-selected-status', false)"
				></f-icon-button>
				<f-text
					inline
					data-qa="compliance-header"
					variant="heading"
					size="small"
					weight="bold"
					align="left"
				>
					Compliance Details
				</f-text>
			</f-div>
			<f-div v-else direction="row" gap="small" width="75%" padding="small">
				<f-text
					inline
					data-qa="compliance-header"
					variant="heading"
					size="small"
					weight="bold"
					align="left"
					>{{ comp?.application?.title }}</f-text
				>
				<f-text inline state="primary" variant="heading" size="small" weight="bold" align="left">
					/
				</f-text>
				<f-text
					inline
					data-qa="component-name"
					variant="heading"
					size="small"
					weight="bold"
					align="left"
				>
					{{ comp!.overviewCard.name }}
				</f-text>
				<f-counter :label="comp!.overviewCard.count"></f-counter>
			</f-div>
			<f-div class="padding-rt-18" gap="small" align="middle-right">
				<f-icon-button
					data-qa="compliance-details-info-icon"
					icon="i-info-fill"
					category="transparent"
					state="neutral"
				></f-icon-button>
				<f-icon-button
					icon="i-close"
					category="transparent"
					state="neutral"
					data-qa="compliance-details-close-button"
					@click="toggleSidePanel"
				></f-icon-button>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import { defineComponent } from "vue";

import { complianceReportStore } from "../../compliance-report-store";

export default defineComponent({
	name: "DetailsHeader",

	props: {
		isControlSelected: {
			type: Boolean,
			default: false
		}
	},

	emits: ["update-control-selected-status"],

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			comp: "selectedComponent"
		})
	},

	methods: {
		toggleSidePanel() {
			this.complianceReportStore.toggleSidePanel(false);
		}
	}
});
</script>
