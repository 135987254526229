<template>
	<SlideoutContainer v-if="isAddRowSlideOutPanelVisible" size="small">
		<Slideout>
			<!--suppress VueUnrecognizedSlot -->
			<template #header>
				<Slab size="large">
					<!--suppress VueUnrecognizedSlot -->
					<template #primary-action>
						<Icon
							data-qa-taxonomy-tab-add-row-slideout-close-icon
							name="i-close"
							size="small"
							state="dark"
							type="outlined"
							@click="close"
						/>
					</template>
					<Typography type="h3" weight="bold" data-qa-taxonomy-add-row-to-table>
						Add row to table
					</Typography>
				</Slab>
			</template>

			<!--suppress VueUnrecognizedSlot -->
			<template #default>
				<div style="width: 100%">
					<template v-for="(value, index) in dataTableColumns" :key="index">
						<f-div v-if="index > 0" align="middle-center" padding="large" width="100%">
							<f-suggest
								v-model="selectedValues[index]"
								:suggestions="suggestedOptions[index]"
								placeholder="Enter a value"
							>
								<f-div slot="label" gap="none" padding="none">
									{{ value.title }}
								</f-div>
							</f-suggest>
						</f-div>
					</template>
					<Section v-if="isThereSomeError">
						<Typography color="error" data-qa-taxonomy-error-in-adding-row> {{ error }}</Typography>
					</Section>
				</div>
			</template>

			<!--suppress VueUnrecognizedSlot -->
			<template #footer>
				<Button
					:full-width="true"
					:loading="isSubmitButtonLoading"
					data-qa-taxonomy-tab-add-row-slideout-submit-button
					state="outlined"
					style="margin: 10px"
					type="primary"
					@click="submitTaxonomy"
				>
					Submit
				</Button>
			</template>
		</Slideout>
	</SlideoutContainer>
</template>

<script lang="ts">
import {
	Button,
	Icon,
	Section,
	Slab,
	Slideout,
	SlideoutContainer,
	Typography
} from "@ollion/flow-vue3";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import { taxonomyStore } from "@/modules/taxonomy-table/taxonomy-table-store";
import { CALLBACK_STATUS, TAXONOMY_TYPES } from "@/shared/constants";

export default defineComponent({
	name: "AddRowSlideOutPanel",
	components: { Section, Slideout, Slab, SlideoutContainer, Button, Icon, Typography },

	props: {
		isAddRowSlideOutPanelVisible: Boolean
	},

	emits: ["hideAddRowSlideOutPanel", "taxonomyTableUpdateSuccess"],

	data() {
		return {
			selectedValues: [],
			isSubmitButtonLoading: false,
			isThereSomeError: false,
			error: ""
		};
	},

	computed: {
		...mapState(taxonomyStore, ["selectedTaxonomyTab", "dataTableColumns", "dataTableRows"]),

		suggestedOptions(): string[][] {
			const numColumns = this.dataTableRows[0]?.length ?? 0; // Get the number of columns

			return Array.from({ length: numColumns }, (_, colIndex) => {
				const columnSuggestions = new Set<string>();

				for (const row of this.dataTableRows) {
					columnSuggestions.add(row[colIndex]?.toString() ?? "");
				}

				return Array.from(columnSuggestions);
			});
		}
	},

	beforeUpdate() {
		// ToClear the selected values after closing
		this.selectedValues = [];
	},

	methods: {
		...mapActions(taxonomyStore, [
			"updateTaxonomyDomainList",
			"updateTaxonomyTBMList",
			"updateTaxonomyClassList"
		]),

		close(): void {
			this.$emit("hideAddRowSlideOutPanel");
			this.isThereSomeError = false;
		},

		async submitTaxonomy() {
			this.isSubmitButtonLoading = true;
			this.isThereSomeError = false;
			let status, response;

			switch (this.selectedTaxonomyTab) {
				case TAXONOMY_TYPES.DOMAIN:
					if (this.selectedValues[1] && this.selectedValues[2]) {
						[status, response] = await this.updateTaxonomyDomainList({
							taxonomy_1: this.selectedValues[1],
							taxonomy_2: this.selectedValues[2]
						});
					}
					break;
				case TAXONOMY_TYPES.TBM:
					if (this.selectedValues[1] && this.selectedValues[2] && this.selectedValues[3]) {
						[status, response] = await this.updateTaxonomyTBMList({
							taxonomy_3: this.selectedValues[1],
							taxonomy_4: this.selectedValues[2],
							taxonomy_5: this.selectedValues[3]
						});
					}
					break;
				case TAXONOMY_TYPES.CLASS:
					if (this.selectedValues[1] && this.selectedValues[2] && this.selectedValues[3]) {
						[status, response] = await this.updateTaxonomyClassList({
							taxonomy_6: this.selectedValues[1],
							taxonomy_7: this.selectedValues[2],
							taxonomy_8: this.selectedValues[3]
						});
					}
					break;
			}

			this.isSubmitButtonLoading = false;

			if (status === CALLBACK_STATUS.SUCCESS) {
				this.$emit("taxonomyTableUpdateSuccess", response);
			} else if (response?.totalNodesAdded === 0 && response.totalRowsAdded === 0) {
				this.error = "Taxnomy already exists";
				this.isThereSomeError = true;
			} else {
				this.error = "Error in adding row";
				this.isThereSomeError = true;
			}
		}
	}
});
</script>
