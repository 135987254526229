import { AxiosResponse } from "axios";

import {
	ArtifactResponse,
	CockpitService,
	Component,
	CreateComponentRequest,
	GetComponentsResponse,
	GetJobByComponentIdResponse,
	GetWorkflowByComponentIdResponse,
	ResponseMessage,
	SubmitComponentResponse,
	UpdateComponentInfoRequest
} from "@/protocol/cockpit";
import {
	ComponentDocumentsResponse,
	CorrelationService,
	GetControlEvidenceMappingsResponse,
	GetControlsResponse,
	ListControlGatesResponse,
	ListEnvironmentsResponse
} from "@/protocol/correlation";
import {
	ApproveEvidenceResponse,
	CreateEvidenceResponse,
	EvidenceService,
	GetEvidenceByIdsResponse
} from "@/protocol/evidence";
import {
	AddTaxonomyMessage,
	GetClassByLevelResponse,
	GetClassResponse,
	GetDomainByLevelResponse,
	GetDomainResponse,
	GetTBMByLevelResponse,
	GetTBMResponse,
	GetTaxonomyMatchResponse,
	TaxonomyService,
	UploadClassResponse,
	UploadDomainResponse,
	UploadTBMResponse,
	ValidateResponse
} from "@/protocol/taxonomy";
import {
	TaskCompletionResponse,
	WorkflowService,
	Component as WorkflowComponentType
} from "@/protocol/workflow";
import { addQueryParams } from "@/shared/append-query-parameter-in-url";
import {
	COCKPIT_SERVICE_PATH,
	CORRELATION_SERVICE_PATH,
	EVIDENCE_SERVICE_PATH,
	EVIDENCE_SERVICE_DETAIL_PATH,
	TAXONOMY_SERVICE_PATH
} from "@/shared/service-paths";

import HTTP from "../services/api-service";

export const getComponents = async (): Promise<GetComponentsResponse> => {
	const response = await HTTP.get(`${COCKPIT_SERVICE_PATH}/components`);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateComponent = (compId: number, data: UpdateComponentInfoRequest) => {
	return HTTP.put(`${COCKPIT_SERVICE_PATH}/components/${compId}`, data);
};

export const getJobPipelineById = (componentId: string) => {
	return HTTP.get<GetJobByComponentIdResponse>(
		`${COCKPIT_SERVICE_PATH}/components/${componentId}/job`
	);
};

export const getComponentWorkflow = async (
	componentId: string
): Promise<GetWorkflowByComponentIdResponse> => {
	const response = await HTTP.get(`${COCKPIT_SERVICE_PATH}/components/${componentId}/workflow`);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createArtifact: CockpitService["createArtifact"] = async function (request) {
	const response: AxiosResponse<ArtifactResponse> = await HTTP.post(
		`${COCKPIT_SERVICE_PATH}/artifacts`,
		request
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateArtifact: CockpitService["updateArtifact"] = async function (request) {
	const response: AxiosResponse<ResponseMessage> = await HTTP.put(
		`${COCKPIT_SERVICE_PATH}/artifacts/${request.id}`,
		request
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getComponentById: CockpitService["getComponentById"] = async function (request) {
	const response: AxiosResponse<Component> = await HTTP.get(
		`${COCKPIT_SERVICE_PATH}/components/${request.componentId}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const updateWorkflow: CockpitService["updateWorkflowByComponentId"] = async function ({
	componentId,
	workflow
}) {
	const response = await HTTP.put(`${COCKPIT_SERVICE_PATH}/components/${componentId}/workflow`, {
		componentId,
		workflow
	});
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const notariseComponent: CockpitService["notariseComponent"] = async function ({
	componentId
}) {
	const payload = {
		notarised: true,
		componentId
	};
	const response: AxiosResponse<ResponseMessage> = await HTTP.put(
		`${COCKPIT_SERVICE_PATH}/components/${componentId}/notarise`,
		payload
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const publishComponent: CockpitService["publishComponent"] = async function ({
	componentId
}) {
	const payload = {
		published: true,
		componentId
	};
	const response: AxiosResponse<ResponseMessage> = await HTTP.put(
		`${COCKPIT_SERVICE_PATH}/components/${componentId}/publish`,
		payload
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const createComponent: CockpitService["createComponent"] = async function (request) {
	const response: AxiosResponse<Component> = await HTTP.post(
		`${COCKPIT_SERVICE_PATH}/components/create`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const updateComponentInfo: CockpitService["updateComponentInfo"] = async function (request) {
	const response: AxiosResponse<Component> = await HTTP.put(
		`${COCKPIT_SERVICE_PATH}/components/${request.componentId}`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const submitComponent: CockpitService["submitComponent"] = async function (request) {
	const response: AxiosResponse<SubmitComponentResponse> = await HTTP.put(
		`${COCKPIT_SERVICE_PATH}/components/${request.componentId}/submit`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getLogForComponentControl: CockpitService["getLogForComponentControl"] =
	async function ({ componentId, documentId, statementId }) {
		const response = await HTTP.get(
			`/cockpit-service/v1/cockpit/components/${componentId}/job/documents/${documentId}/statements/${statementId}/logs`
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

// CockpitService ends here

// CorrelationService starts here
export const getComponentDocuments: CorrelationService["getComponentDocuments"] = async function (
	request
) {
	const response: AxiosResponse<ComponentDocumentsResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/components/${request.componentId}/documents`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getControlsByClassification: CorrelationService["getControlsByClassification"] =
	async function (request) {
		const response: AxiosResponse<GetControlsResponse> = await HTTP.post(
			`${CORRELATION_SERVICE_PATH}/controls`,
			request
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

export const getStatementEvidences: CorrelationService["getControlEvidenceMappings"] =
	async function (request) {
		const response: AxiosResponse<GetControlEvidenceMappingsResponse> = await HTTP.post(
			`${CORRELATION_SERVICE_PATH}/mapping/control-evidence`,
			request
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const getEnvironments: CorrelationService["getEnvironments"] = async function () {
	const response: AxiosResponse<ListEnvironmentsResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/environments`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getControlGates: CorrelationService["getControlGates"] = async () => {
	const response: AxiosResponse<ListControlGatesResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/control-gate`
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

// CorrelationService ends here

// TaxonomyService starts here
export const sendNewTaxonomyRequest = (data: AddTaxonomyMessage) => {
	return HTTP.post(`${TAXONOMY_SERVICE_PATH}/request`, data);
};

export const getDomain: TaxonomyService["getDomain"] = async function () {
	const response: AxiosResponse<GetDomainResponse> = await HTTP.get(
		`${TAXONOMY_SERVICE_PATH}/domain`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getDomainByLevel: TaxonomyService["getDomainByLevel"] = async function (request) {
	const response: AxiosResponse<GetDomainByLevelResponse> = await HTTP.get(
		addQueryParams(`${TAXONOMY_SERVICE_PATH}/domain/${request.domainLevel}`, request)
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const uploadDomainByRow: TaxonomyService["uploadDomainByRow"] = async function (request) {
	const response: AxiosResponse<UploadDomainResponse> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/domain`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getTBM: TaxonomyService["getTBM"] = async function () {
	const response: AxiosResponse<GetTBMResponse> = await HTTP.get(`${TAXONOMY_SERVICE_PATH}/tbm`);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getTBMByLevel: TaxonomyService["getTBMByLevel"] = async function (request) {
	const response: AxiosResponse<GetTBMByLevelResponse> = await HTTP.get(
		addQueryParams(`${TAXONOMY_SERVICE_PATH}/tbm/${request.tBMLevel}`, request)
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const uploadTBMByRow: TaxonomyService["uploadTBMByRow"] = async function (request) {
	const response: AxiosResponse<UploadTBMResponse> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/tbm`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getClass: TaxonomyService["getClass"] = async function () {
	const response: AxiosResponse<GetClassResponse> = await HTTP.get(
		`${TAXONOMY_SERVICE_PATH}/class`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getClassByLevel: TaxonomyService["getClassByLevel"] = async function (request) {
	const response: AxiosResponse<GetClassByLevelResponse> = await HTTP.get(
		addQueryParams(`${TAXONOMY_SERVICE_PATH}/class/${request.classLevel}`, request)
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const uploadClassByRow: TaxonomyService["uploadClassByRow"] = async function (request) {
	const response: AxiosResponse<UploadClassResponse> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/class`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const validate: TaxonomyService["validate"] = async function (request) {
	const response: AxiosResponse<ValidateResponse> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/validate`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const addTaxonomy: TaxonomyService["addTaxonomy"] = async function (request) {
	const response: AxiosResponse<AddTaxonomyMessage> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/request`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const getTaxonomyMatch: TaxonomyService["getTaxonomyMatch"] = async function (request) {
	const response: AxiosResponse<GetTaxonomyMatchResponse> = await HTTP.post(
		`${TAXONOMY_SERVICE_PATH}/match`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// TaxonomyService ends here

// EvidenceService starts here
export const createEvidence = async (request: FormData) => {
	const config = {
		headers: {
			"Content-Type": "multipart/form-data"
		},
		body: request
	};

	const response: AxiosResponse<CreateEvidenceResponse> = await HTTP.post(
		EVIDENCE_SERVICE_PATH,
		request,
		config
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const approveEvidence: EvidenceService["approveEvidence"] = async function (request) {
	const response = await HTTP.post<ApproveEvidenceResponse>(
		`${EVIDENCE_SERVICE_PATH}/${request.id}/approve`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const classificationApprovalAction: WorkflowService["approveRejectClassificationTask"] =
	async function (payload) {
		const response: AxiosResponse<TaskCompletionResponse> = await HTTP.put(
			`/workflow-service/v1/workflow/${payload.componentId}/approval`,
			payload
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

export const classificationResendRequest: WorkflowService["classificationReSubmissionTask"] =
	async function (payload) {
		const response: AxiosResponse<WorkflowComponentType> = await HTTP.put(
			`/workflow-service/v1/workflow/classification/${payload.componentId}/re-submit`,
			payload
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

export const createWorkflowComponent = async function ({
	payload
}: {
	payload: CreateComponentRequest;
}) {
	const response = await HTTP.post(`/workflow-service/v1/workflow/components/create`, payload);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getEvidenceByIds: EvidenceService["getEvidenceByIds"] = async function (request) {
	const response: AxiosResponse<GetEvidenceByIdsResponse> = await HTTP.post(
		`${EVIDENCE_SERVICE_DETAIL_PATH}/evidenceByIds`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// EvidenceService ends here

type IngestControlGateSetsResponse = {
	fileName: string;
	successfulEnvironments: number;
	erroredEnvironments: number;
	successfulControlGates: number;
	erroredControlGates: number;
	successfulControlSets: number;
	erroredControlSets: number;
	successfulControlGateControlSetMappings: number;
	erroredControlGateControlSetMappings: number;
	successfulEnvironmentControlGateMappings: number;
	erroredEnvironmentControlGateMappings: number;
};

export const ingestControlSetGates = async (payload: File) => {
	const formData = new FormData();
	formData.append("file", payload);

	const response = await HTTP.post<IngestControlGateSetsResponse>(
		`${CORRELATION_SERVICE_PATH}/control-sets/ingest`,
		formData,
		{
			headers: {
				"Content-Type": "multipart/form-data"
			},
			data: formData
		}
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};
