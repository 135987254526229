import { defineStore } from "pinia";

import { GetControlsComponentIDResponse } from "@/protocol/correlation";

import {
	ApplicationOptions,
	CurrentOrg,
	EvidenceDashboardComponent
} from "./compliance-report-utils";

export const complianceReportStore = defineStore("complianceReportStore", {
	state: () => ({
		selectedComponent: {} as EvidenceDashboardComponent,
		selectedComponentDetails: {} as GetControlsComponentIDResponse,
		showSidePanel: false,
		selectedApp: {} as ApplicationOptions,
		currentOrg: {} as CurrentOrg
	}),
	actions: {
		setSelectedComponent(component: EvidenceDashboardComponent) {
			this.selectedComponent = component;
		},
		setSelectedComponentDetails(component: GetControlsComponentIDResponse) {
			this.selectedComponentDetails = component;
		},
		toggleSidePanel(flag: boolean) {
			this.showSidePanel = flag;
		},
		setSelectedApp(application: ApplicationOptions) {
			this.selectedApp = application;
		},
		setCurrentOrg(currentOrg: CurrentOrg) {
			this.currentOrg = currentOrg;
		}
	}
});
