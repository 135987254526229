<template>
	<PermissionsWrapper>
		<Wrapper data-qa-constellation-map-view>
			<HeaderPageVue
				:type-counts="typeCounts"
				:export-loading="exportLoading"
				:export-error="exportError"
				@zoom-in="zoomIn"
				@zoom-out="zoomOut"
				@export-graph="exportGraph"
			></HeaderPageVue>
			<Container class="width-100-per padding-0">
				<Divider class="padding-0 margin-0 width-100-per" disable-hover />
			</Container>
			<Container v-if="loading" padding="0" :gap="0">
				<Shimmer class="position-absolute" height="2%" width="100%"></Shimmer>
			</Container>
			<Container align="center" class="width-100-per height-100-per border position-relative">
				<Container direction="column" align="center" class="width-100-per height-100-per border">
					<HierarchyTree
						v-if="gNodes.length > 0"
						ref="cluster"
						:nodes="gNodes"
						:node-names="true"
						:node-radius="30"
						:reset-on-drag="true"
						@node-click="handleClick"
						@rclick="appendNeighbors"
						@dblclick="collapseNode"
					>
					</HierarchyTree>

					<Container v-if="startText && gNodes.length === 0" direction="column" align="center">
						<Icon name="i-question-filled" state="dark" type="filled" size="small" />
						<Typography
							class="width-80-per text-align-center"
							type="p1"
							weight="regular"
							color="light"
						>
							{{ errMsg }}
						</Typography>
					</Container>
				</Container>
			</Container>
			<MapFilterVue
				@set-error-msg="setErrorMsg"
				@generate="getShortestPath"
				@clear-all="clearAll"
			></MapFilterVue>
			<ModalBox v-if="openSlideout" open width="450">
				<PopOverCard
					class="width-450"
					:node-data="nodeData"
					@close="openSlideout = false"
				></PopOverCard>
			</ModalBox>
		</Wrapper>
	</PermissionsWrapper>
</template>

<script>
import {
	Container,
	Divider,
	Wrapper,
	Icon,
	Typography,
	Shimmer,
	ModalBox
} from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { CHARACTER_LIMIT } from "@/shared/constants";
import { toTitleCase, captureError, getErrorMessage } from "@/utils";

import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";

import HeaderPageVue from "./components/HeaderPage.vue";
import HierarchyTree from "./components/HierarchyTree/HierarchyTree.vue";
import MapFilterVue from "./components/MapFilter.vue";
import PopOverCard from "./components/PopOverCard.vue";
import typeColors from "./components/typeColors";
import { shortestPath, getNeighbours, exportGraph } from "./correlation-service";

export default defineComponent({
	name: "ConstellationMap",

	components: {
		Divider,
		HierarchyTree,
		Icon,
		Typography,
		Wrapper,
		Container,
		PopOverCard,
		MapFilterVue,
		HeaderPageVue,
		Shimmer,
		ModalBox,
		PermissionsWrapper
	},

	data() {
		return {
			openSlideout: false,
			startText: true,
			gNodes: [],
			nodeData: {},
			resp: {},
			addedNodes: new Set(),
			addedRelationships: new Set(),
			typeCounts: {},
			root: {},
			errMsg: "Select your preferred mode to start generating the constellation map",
			overlay: false,
			loading: false,
			exportLoading: false,
			exportError: false
		};
	},

	computed: {
		...mapStores(notificationsStore)
	},

	methods: {
		handleClick(e) {
			this.nodeData = e;
			this.openSlideout = true;
		},

		zoomIn() {
			this.$refs.cluster.zoomIn();
		},

		zoomOut() {
			this.$refs.cluster.zoomOut();
		},

		setErrorMsg(msg) {
			this.errMsg = msg ?? null;
		},

		getPayload(filters) {
			//transform payload with appropriate order
			const payload = {
				shortestPathNodes: []
			};
			let order = 1;
			//Add Start point if available
			if (Object.getOwnPropertyNames(filters.startPoint).length > 0) {
				payload.shortestPathNodes.push({
					nodeType: filters.startPoint?.startType?.type,
					nodeAttr: filters.startPoint?.startType?.attribute,
					nodeValue: filters.startPoint?.startPoint?.type,
					nodeOrder: order++
				});
			}
			//Add in between points if available
			filters.inbetweenPoints?.forEach(point => {
				payload.shortestPathNodes.push({
					nodeType: point.type.type,
					nodeAttr: point.type?.attribute,
					nodeValue: point.point.type,
					nodeOrder: order++
				});
			});
			//Add Endpoints if available
			if (Object.getOwnPropertyNames(filters.endPoint).length > 0) {
				payload.shortestPathNodes.push({
					nodeType: filters.endPoint?.endType?.type,
					nodeAttr: filters.endPoint?.endType?.attribute,
					nodeValue: filters.endPoint?.endPoint?.type,
					nodeOrder: order++
				});
			}
			return payload;
		},

		async getShortestPath(filters) {
			this.loading = true;
			const payload = this.getPayload(filters);

			try {
				const shortestPathResponse = await shortestPath(payload);
				const nodes = shortestPathResponse.nodes ?? [];
				const relationships = shortestPathResponse.relationships ?? [];

				this.addedNodes = new Set();
				this.addedRelationships = new Set();

				this.gNodes = [];
				this.typeCounts = {};
				this.startText = false;
				this.errMsg = "";
				this.root = nodes.find(
					node =>
						node.props[filters.startPoint?.startType?.attribute] ===
						filters.startPoint?.startPoint?.type
				);
				this.root.root = true;
				const tree = this.buildTree(nodes, relationships, this.root);
				this.gNodes.push(tree);
				this.resp = shortestPathResponse;
				this.loading = false;
			} catch (error) {
				const errorMessage = getErrorMessage(error);
				if (errorMessage.includes("no path exists")) {
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-constellation-map-error",
						title: errorMessage,
						text: "Path not found. Please try again with different filters.",
						status: "info"
					});
				} else if (
					errorMessage.includes(
						"The shortest path algorithm does not work when the start and end nodes are the same"
					)
				) {
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-constellation-map-error",
						title: errorMessage,
						text: "The shortest path algorithm does not work when the start and end nodes are the same. Please try again with different filters.",
						status: "info"
					});
				} else {
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-constellation-map-error",
						title: errorMessage,
						text: "An error occurred while generating the nodes. Please try again.",
						status: "error"
					});
				}

				captureError(error);
				this.startText = true;
				this.loading = false;
				this.errMsg = "No data available. Please select another in filter to generate map";
			}
		},

		buildTree(nodes, relationships, node) {
			// Find the node with the given Id in the nodes array
			const currentNode = nodes.find(n => n.id === node.id);
			// Create a new object with the id, uid, name, and icon properties
			const treeNode = this.getNodeObj(currentNode);
			// Add the current node to the addedNodes Set
			this.addedNodes.add(currentNode.id);
			// Increment count for type in typeCounts object
			if (!this.typeCounts[currentNode.labels[0]]) {
				this.typeCounts[currentNode.labels[0]] = 1;
			} else {
				this.typeCounts[currentNode.labels[0]]++;
			}
			// Find all relationships where the start node is the given node
			const childRelationships = relationships?.filter(r => {
				if (this.addedRelationships.has(r.id)) {
					return false;
				} else {
					return r.startId === node.id || r.endId === node.id;
				}
			});

			// For each relationship, find the end node in the nodes array and
			// recursively call the buildTree function with the end node
			childRelationships?.forEach(r => {
				r.reverse = r.endId === node.id;
				const childNode = nodes.find(n =>
					r.reverse
						? n.id === r.startId && treeNode.uid === r.endId
						: n.id === r.endId && treeNode.uid === r.startId
				);
				if (!childNode) {
					return;
				}
				if (r.reverse && childNode) {
					childNode.relation = [];
					childNode.relation.push({ to: r.endId, relation: r.type });
				} else {
					treeNode.relation.push({ to: r.endId, relation: r.type });
				}
				childNode.reverse = r.reverse;
				this.addedRelationships.add(r.id);
				if (!this.addedNodes.has(childNode.id)) {
					treeNode.children.push(this.buildTree(nodes, relationships, childNode));
				} else if (
					this.addedNodes.has(childNode.id) &&
					(r.startId === childNode.id || r.endId === childNode.id)
				) {
					//If the node has already been added, add the relation to the otherRelations
					const relationObj = { [childNode.id]: r?.type ?? "", reverse: false };
					relationObj.reverse = r.startId === childNode.id;
					treeNode.otherRelations.push(relationObj);
					this.addedRelationships.add(r.id);
					return;
				}
			});
			// Return the current node object
			return treeNode;
		},

		getNodeObj(node) {
			const nodeObj = {
				id: node.labels[0],
				uid: node.id,
				name: toTitleCase(node.labels[0]),
				icon: "i-database",
				children: [],
				otherRelations: [],
				props: node.props,
				relation: node.relation ?? [],
				root: node.root,
				reverse: node.reverse ?? false,
				collapsed: node.collapsed ?? false,
				color: typeColors[node.labels[0]],
				secondaryLabels: this.formatLabel(node.props.name) ?? node.props.id,
				isAppended: node.isAppended ?? false
			};
			return nodeObj;
		},

		async appendNeighbors(selectedNode) {
			this.loading = true;
			const payload = {
				nodeType: selectedNode.data?.id,
				nodeAttr: "id",
				nodeValue: selectedNode.data.props?.id
			};
			const response = await getNeighbours(payload);

			let addedNodeCount = 0;
			this.loading = false;
			response.nodes?.forEach(node => {
				if (!this.addedNodes.has(node.id)) {
					node.isAppended = true;
					this.resp.nodes?.push(node);
					this.addedNodes.add(node.id);
					addedNodeCount += 1;
				}
			});
			response.relationships?.forEach(relation => {
				if (!this.addedRelationships.has(relation.id)) {
					if (!this.resp.relationships) {
						this.resp.relationships = [];
					}
					this.resp.relationships?.push(relation);
					this.addedRelationships.add(relation.id);
				}
			});
			if (addedNodeCount === 0) {
				this.collapseNode(selectedNode);
				return;
			}

			this.gNodes = [];
			this.addedNodes = new Set();
			this.addedRelationships = new Set();
			this.typeCounts = {};
			const tree = this.buildTree(this.resp.nodes, this.resp.relationships, this.root);
			this.gNodes.push(tree);
		},

		collapseNode(selectedNode) {
			const children = selectedNode.data?.children;
			if (children) {
				this.removeNodes(children);
				this.gNodes = [];
				this.addedNodes = new Set();
				this.addedRelationships = new Set();
				this.typeCounts = {};
				const tree = this.buildTree(this.resp.nodes, this.resp.relationships, this.root);
				this.gNodes.push(tree);
			}
		},

		removeNodes(children) {
			for (let i = 0; i < children.length; i++) {
				const child = children[i];
				if (child.isAppended) {
					const nodeIndex = this.resp.nodes.findIndex(node => node.id === child.uid);
					if (nodeIndex !== -1 && child.isAppended) {
						this.resp.nodes.splice(nodeIndex, 1);
						const relIndex = this.resp.relationships.findIndex(
							rel => rel.startId === child.uid || rel.endId === child.uid
						);
						if (relIndex !== -1) {
							this.resp.relationships.splice(relIndex, 1);
						}
					}
					// eslint-disable-next-line no-prototype-builtins
					if (child.hasOwnProperty("children") && Array.isArray(children[i].children)) {
						this.removeNodes(child.children);
					}
				}
			}
		},

		clearAll() {
			this.startText = true;
			this.gNodes = [];
			this.nodeData = {};
			this.resp = [];
			this.addedNodes = new Set();
			this.addedRelationships = new Set();
			this.typeCounts = {};
			this.root = {};
			this.errMsg = "Select your preferred mode to start generating the constellation map";
			this.overlay = false;
		},

		async exportGraph() {
			if (this.addedNodes.size === 0) {
				return;
			}
			this.exportLoading = true;
			this.exportError = false;
			const payload = {
				nodeIds: Array.from(this.addedNodes).map(id => Number(id)),
				relationshipIds: Array.from(this.addedRelationships).map(id => Number(id))
			};
			try {
				const response = await exportGraph(payload);
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${Date.now()}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
				this.exportLoading = false;
			} catch (error) {
				captureError(error);
				this.exportLoading = false;
				this.exportError = true;
			}
		},

		formatLabel(name) {
			return name?.length > CHARACTER_LIMIT ? `${name?.slice(0, CHARACTER_LIMIT)}...` : name;
		}
	}
});
</script>
<style lang="scss">
.rotated {
	transform: rotate(90deg); /* Equal to rotateZ(45deg) */
}
div.flow-dropdown-menu-wrapper > div.flow-dropdown-menu {
	/* position: initial !important; */

	left: 0 !important;
	margin: 115px 15px;
}
div.flow-dropdown-menu-wrapper > div.flow-dropdown-menu-trigger {
	margin-left: -62px;
	margin-top: 22%;
}

.custom-margin {
	top: 250px;
}
.float-right {
	float: right !important;
}
.padding-2 {
	padding: 2px;
}
</style>
