<script setup lang="ts">
import { FSelectOptionObject } from "@ollion/flow-core";
import { FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { computed, ref } from "vue";

import { TaxonomyDetail } from "@/protocol/correlation";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { isValidSemver } from "@/utils";

import { documentMappingStore } from "../document-mapping-store";

import TaxonomyModel from "./TaxonomyModel.vue";

defineProps({
	isParsingPdf: Boolean
});

const documentStore = documentMappingStore();
const documentState = computed(() => documentStore.currentDocumentState);

const showDetailsNotification = ref(true);
const isEditingDocumentName = ref(false);
const isEditingDocumentVersion = ref(false);
const isEditingDocumentType = ref(true);
const isEditingRegulatoryOrg = ref(true);
const showTaxonomyDialog = ref(false);

const nameFormFields = computed((): FormBuilderField => {
	return {
		type: "text",
		placeholder: "Document name",
		validationRules: [{ name: "required" }]
	};
});

const nameFormState = ref<FormBuilderState | null>(null);

const versionFormFields = computed((): FormBuilderField => {
	return {
		type: "text",
		placeholder: "Document version",
		validationRules: [
			{ name: "required" },
			{
				name: "custom",
				message: "Version format should be MAJOR.MINOR.PATCH",
				validate: value => {
					if (typeof value !== "string") {
						return false;
					}
					return isValidSemver(value);
				}
			}
		]
	};
});

const versionFormState = ref<FormBuilderState | null>(null);

const documentTypeFormFields = computed((): FormBuilderField => {
	return {
		type: "select",
		placeholder: "Document type",
		options: [
			{ title: "Best practice", data: { id: "Best_Practice" } },
			{ title: "Guideline", data: { id: "Guideline" } }
		],
		validationRules: [{ name: "required" }]
	};
});

const documentTypeFormState = ref<FormBuilderState | null>(null);

const regulatoryOptions = computed((): RegulatoryOption[] => {
	return documentStore.regulatoryOrgs.map(org => ({
		title: String(org.props?.name ?? ""),
		data: { id: org.id! }
	}));
});

const regulatoryOrgFormFields = computed((): FormBuilderField => {
	return {
		type: "select",
		placeholder: "Regulatory organization",
		options: regulatoryOptions.value,
		validationRules: [{ name: "required" }]
	};
});

const regulatoryOrgFormState = ref<FormBuilderState | null>(null);

const regulatoryOrg = computed<RegulatoryOption | undefined>({
	get() {
		return regulatoryOptions.value.find(
			option => option.data!.id === documentState.value.metadata.regulatoryOrg
		);
	},
	set(value) {
		documentState.value.metadata.regulatoryOrg = value ? value.data!.id : undefined;
	}
});

const renderedClassification = computed(() => {
	return documentState.value.classification
		?.map(classification => classification.taxonomyId)
		.join(" / ");
});

function updateTaxonomy(taxons: TaxonomyDetail[]) {
	documentStore.SET_DRAFT_CLASSIFICATION(taxons);
}

type RegulatoryOption = FSelectOptionObject<{ id: string }>;
</script>

<template>
	<f-div
		v-if="showDetailsNotification"
		state="primary"
		padding="medium"
		align="middle-left"
		gap="small"
		height="hug-content"
	>
		<f-icon source="i-info-fill" state="primary"></f-icon>
		<f-text>The following details are automatically populated based on the added document.</f-text>
		<f-button
			label="Got it"
			size="small"
			state="neutral"
			category="outline"
			@click="showDetailsNotification = false"
		></f-button>
	</f-div>

	<f-div v-if="isParsingPdf" align="middle-center">
		<CustomLoader size="x-large" message="Parsing document..." />
	</f-div>

	<f-div v-else direction="column" padding="medium">
		<!-- Document name start -->
		<f-div
			class="show-on-hover-parent"
			padding="medium"
			align="middle-left"
			gap="large"
			border="small dashed subtle bottom"
			height="hug-content"
		>
			<f-div width="200px" align="middle-left">
				<f-text state="secondary">Document name</f-text>
			</f-div>
			<f-div v-if="isEditingDocumentName" align="middle-left" gap="large">
				<f-div height="hug-content" width="50%">
					<f-form-builder
						:values.prop="documentState.metadata.documentName"
						:field.prop="nameFormFields"
						@input="documentState.metadata.documentName = $event.detail"
						@state-change="nameFormState = $event.detail"
					/>
				</f-div>
				<f-button
					label="Done"
					size="small"
					state="primary"
					category="outline"
					:disabled="!nameFormState?.isValid"
					@click="isEditingDocumentName = false"
				></f-button>
			</f-div>
			<template v-else>
				<f-text weight="medium" inline>{{ documentState.metadata.documentName }}</f-text>
				<f-icon
					source="i-edit"
					state="secondary"
					class="show-on-hover"
					clickable
					@click="isEditingDocumentName = true"
				></f-icon>
			</template>
		</f-div>
		<!-- Document name end -->

		<!-- Document version start -->
		<f-div
			class="show-on-hover-parent"
			padding="medium"
			align="middle-left"
			gap="large"
			border="small dashed subtle bottom"
			height="hug-content"
		>
			<f-div width="200px" align="middle-left">
				<f-text state="secondary">Version</f-text>
			</f-div>

			<f-div v-if="isEditingDocumentVersion" align="middle-left" gap="large">
				<f-div height="hug-content" width="50%">
					<f-form-builder
						:values.prop="documentState.metadata.documentVersion"
						:field.prop="versionFormFields"
						@input="documentState.metadata.documentVersion = $event.detail"
						@state-change="versionFormState = $event.detail"
					/>
				</f-div>
				<f-button
					label="Done"
					size="small"
					state="primary"
					category="outline"
					:disabled="!versionFormState?.isValid"
					@click="isEditingDocumentVersion = false"
				></f-button>
			</f-div>
			<template v-else>
				<f-text weight="medium" inline>{{ documentState.metadata.documentVersion }}</f-text>
				<f-icon
					source="i-edit"
					state="secondary"
					class="show-on-hover"
					clickable
					@click="isEditingDocumentVersion = true"
				></f-icon>
			</template>
		</f-div>
		<!-- Document version start -->

		<!-- Document type start -->
		<f-div
			class="show-on-hover-parent"
			padding="medium"
			align="middle-left"
			gap="large"
			border="small dashed subtle bottom"
			height="hug-content"
		>
			<f-div width="200px" align="middle-left">
				<f-text state="secondary">Document Type</f-text>
			</f-div>

			<f-div v-if="isEditingDocumentType" align="middle-left" gap="large">
				<f-div height="hug-content" width="50%">
					<f-form-builder
						:values.prop="documentState.metadata.documentType"
						:field.prop="documentTypeFormFields"
						@input="documentState.metadata.documentType = $event.detail.data.id"
						@state-change="documentTypeFormState = $event.detail"
					/>
				</f-div>
				<f-button
					label="Done"
					size="small"
					state="primary"
					category="outline"
					:disabled="!documentTypeFormState?.isValid"
					@click="isEditingDocumentType = false"
				></f-button>
			</f-div>
			<template v-else>
				<f-text weight="medium" inline>{{ documentState.metadata.documentType }}</f-text>
				<f-icon
					source="i-edit"
					state="secondary"
					class="show-on-hover"
					clickable
					@click="isEditingDocumentType = true"
				></f-icon>
			</template>
		</f-div>
		<!-- Document type start -->

		<!-- Regulatory org start -->
		<f-div
			v-if="documentState.metadata.documentType === 'Guideline'"
			class="show-on-hover-parent"
			padding="medium"
			align="middle-left"
			gap="large"
			border="small dashed subtle bottom"
			height="hug-content"
		>
			<f-div width="200px" align="middle-left">
				<f-text state="secondary">Regulatory Organisation</f-text>
			</f-div>

			<f-div v-if="isEditingRegulatoryOrg" align="middle-left" gap="large">
				<f-div height="hug-content" width="50%">
					<f-form-builder
						:values.prop="regulatoryOrg"
						:field.prop="regulatoryOrgFormFields"
						@input="regulatoryOrg = $event.detail"
						@state-change="regulatoryOrgFormState = $event.detail"
					/>
				</f-div>
				<f-button
					label="Done"
					size="small"
					state="primary"
					category="outline"
					:disabled="!regulatoryOrgFormState?.isValid"
					@click="isEditingRegulatoryOrg = false"
				></f-button>
			</f-div>
			<template v-else>
				<f-text weight="medium" inline>{{ regulatoryOrg?.title }}</f-text>
				<f-icon
					source="i-edit"
					state="secondary"
					class="show-on-hover"
					clickable
					@click="isEditingRegulatoryOrg = true"
				></f-icon>
			</template>
		</f-div>
		<!-- Regulatory org end -->

		<!-- Classification start -->
		<f-div
			v-if="documentState.metadata.documentType === 'Best_Practice'"
			class="show-on-hover-parent"
			padding="medium"
			align="middle-left"
			gap="large"
			border="small dashed subtle bottom"
			height="hug-content"
		>
			<f-div width="200px" align="middle-left">
				<f-text state="secondary">Classification</f-text>
			</f-div>

			<f-div
				v-if="!documentState.classification || documentState.classification.length === 0"
				align="middle-left"
				gap="large"
			>
				<f-button
					label="Assign classification"
					size="small"
					state="primary"
					category="outline"
					@click="showTaxonomyDialog = true"
				></f-button>
			</f-div>
			<template v-else>
				<f-text weight="medium" inline>{{ renderedClassification }}</f-text>
				<f-icon
					source="i-edit"
					state="secondary"
					class="show-on-hover"
					clickable
					@click="showTaxonomyDialog = true"
				></f-icon>
			</template>
		</f-div>
		<!-- Classification end -->
	</f-div>

	<f-popover v-if="showTaxonomyDialog" open size="custom(864px,400px)">
		<TaxonomyModel @close="showTaxonomyDialog = false" @taxonomy="updateTaxonomy"></TaxonomyModel>
	</f-popover>
</template>
