<template>
	<f-div
		width="fill-container"
		state="tertiary"
		padding="none"
		gap="none"
		direction="column"
		variant="curved"
	>
		<f-div padding="medium" direction="column">
			<f-div padding="none none small none">
				<f-text
					ellipsis
					variant="heading"
					size="x-small"
					state="secondary"
					:data-qa-info-title="comp.id"
					>{{ comp.name }}</f-text
				>
			</f-div>
			<f-div padding="none none small none" gap="none" direction="column">
				<f-div>
					<f-text
						variant="heading"
						size="large"
						:data-qa="`control-count-${comp.name}`"
						:state="stateOfControls.state"
						>{{ comp.count }}
					</f-text>
				</f-div>
				<f-div padding="x-small none none none" :data-qa="`control-total-count-${comp.name}`">
					<f-text> of {{ comp.total }} controls </f-text>
				</f-div>
			</f-div>
		</f-div>
		<f-div direction="row" padding="none" gap="medium">
			<f-progress-bar
				:value="`${stateOfControls.percent}%`"
				:state="stateOfControls.state"
				variant="curved"
				size="medium"
			></f-progress-bar>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import { DashboardComponent, getPercentOfControlsExecuted } from "../compliance-report-utils";

export default defineComponent({
	name: "ComponentCard",

	props: {
		comp: {
			type: Object as PropType<DashboardComponent>,
			required: true
		}
	},

	computed: {
		stateOfControls() {
			return getPercentOfControlsExecuted(this.comp.count, this.comp.total ? this.comp.total : 0);
		}
	}
});
</script>
