import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import { USER_PERMISSIONS } from "../auth/auth-types";

import BestPracticeReportPage from "./views/BestPracticeReportPage.vue";
import ComponentClassification from "./views/ComponentClassification.vue";
import ComponentOnboardingWrapper from "./views/ComponentOnboardingWrapper.vue";
import ReleaseCockpitPage from "./views/ReleaseCockpitPage.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/component-catalog",
		name: "component-catalog",
		component: ReleaseCockpitPage,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.componentCatalogRead
		} as RouteMeta,
		props: true
	},
	{
		path: "/component-catalog/view/:componentId/onboarding/:tabName?",
		name: "component-onboarding-view",
		component: ComponentOnboardingWrapper,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.componentCatalogRead
		} as RouteMeta,
		props: true
	},
	{
		path: "/component-catalog/view/:componentId/classification",
		name: "component-classification-approval",
		component: ComponentClassification,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.componentCatalogRead
		} as RouteMeta,
		props: true
	},
	{
		path: "/component-best-practice-report/:componentId",
		name: "component-best-practice-report",
		component: BestPracticeReportPage,
		meta: {
			requiredRole: USER_PERMISSIONS.componentCatalogRead,
			noLayout: true
		} as RouteMeta,
		props: true
	}
];

export function registerReleaseCockpitV2Routes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
