<template>
	<f-div v-if="isLoading" height="100%" align="middle-center" direction="column" gap="medium">
		<f-icon source="i-loader" size="x-large" loading></f-icon>
		<f-text>Generating report...</f-text>
	</f-div>
	<f-div
		v-else-if="component"
		direction="column"
		max-width="800px"
		align="top-center"
		:style="{ margin: '0 auto' }"
		overflow="visible"
	>
		<!-- Header start -->
		<f-div state="subtle" direction="column" padding="x-large" gap="medium">
			<f-div gap="auto" padding="none">
				<f-div direction="column" width="fill-container">
					<f-tag
						size="large"
						state="neutral"
						category="fill"
						max-width="100%"
						label="COMPONENT BEST PRACTICE REPORT"
					>
					</f-tag>
					<f-div gap="x-small" direction="column" padding="medium none small none">
						<f-text variant="heading" weight="bold" size="x-large" state="primary">
							{{ component?.name }} {{ component?.version }}
						</f-text>
						<f-text variant="heading" size="small" weight="medium" state="secondary">{{
							currentDate
						}}</f-text>
					</f-div>
				</f-div>
				<!-- <f-div align="middle-right">
					<f-button label="Download as pdf" category="fill" state="primary" size="small"></f-button>
				</f-div> -->
			</f-div>
		</f-div>
		<!-- Header end -->

		<f-div
			v-if="component.nonCompliant"
			padding="large"
			gap="medium"
			state="warning"
			align="middle-left"
			height="hug-content"
		>
			<f-icon source="i-alert" size="small" state="inherit"></f-icon>
			<f-text variant="para" size="medium" weight="regular" state="default"
				>This component is non-compliant due to a change in the best practices.</f-text
			>
		</f-div>

		<f-div padding="x-large" state="default" gap="x-large" direction="column">
			<f-text size="medium" weight="bold" state="subtle" align="left"> COMPONENT DETAILS </f-text>

			<f-div direction="column" gap="x-small">
				<f-div v-for="(value, name) in metaData" :key="name" direction="row" overflow="visible">
					<f-div :style="{ minWidth: '200px' }" width="hug-content">
						<f-text variant="heading" size="medium" weight="medium" state="subtle" align="left">{{
							name
						}}</f-text>
					</f-div>

					<f-text size="medium" weight="regular" state="default">{{ value.value }}</f-text>
				</f-div>
			</f-div>

			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<!-- Environment rating start -->
			<template v-if="component.allowedEnvironment && component.allowedEnvironment.length > 0">
				<f-text size="medium" weight="bold" state="subtle" align="left">
					ENVIRONMENT RATING
				</f-text>
				<f-div gap="medium" width="fill-container" direction="column">
					<f-div
						v-for="environment in component.allowedEnvironment"
						:key="environment"
						align="middle-center"
						gap="small"
						width="hug-content"
					>
						<f-pictogram
							:source="getIconFromEnvironment(environment)"
							variant="hexagon"
						></f-pictogram>

						<f-text>{{ environment }}</f-text>
					</f-div>
				</f-div>
				<f-divider state="subtle" variant="dashed" size="large"></f-divider>
			</template>
			<!-- Environment rating end -->

			<!-- Statement compliance start -->
			<f-text size="medium" weight="bold" state="subtle" align="left">
				STATEMENT COMPLIANCE
			</f-text>
			<f-div gap="x-large" direction="column" width="fill-container">
				<f-div gap="medium" direction="row">
					<f-div gap="x-large" direction="column">
						<f-div direction="column" gap="small">
							<f-text variant="heading" size="medium" weight="medium" state="subtle" align="left">
								Total Statements
							</f-text>
							<f-text variant="heading" size="large" weight="bold" state="subtle" align="left">
								{{ complianceStatements.totalStatements }}
							</f-text>
						</f-div>
						<f-div direction="column" gap="small">
							<f-text variant="heading" size="medium" weight="medium" state="success" align="left">
								Compliant
							</f-text>
							<f-text variant="heading" size="large" weight="bold" state="subtle" align="left">
								{{ complianceStatements.totalCompliant }}
							</f-text>
						</f-div>
					</f-div>
					<f-div gap="x-large" direction="column">
						<f-div direction="column" gap="small">
							<f-text variant="heading" size="medium" weight="medium" state="danger" align="left">
								Non Compliant
							</f-text>
							<f-text variant="heading" size="large" weight="bold" state="subtle" align="left">
								{{ complianceStatements.totalNonCompliant }}
							</f-text>
						</f-div>

						<!-- @todo - we don't have ability to know criticality of controls -->
						<f-div v-show="false" direction="row" gap="small">
							<f-div
								v-for="statementComp in statementCompliance"
								:key="statementComp.label"
								width="100px"
								variant="curved"
								gap="x-small"
								:state="statementComp.state"
								direction="column"
								align="middle-left"
								padding="small medium"
							>
								<f-text size="small" weight="bold" state="subtle">
									{{ statementComp.label }}
								</f-text>
								<f-text variant="heading" size="medium" weight="medium" state="subtle">
									{{ statementComp.count }}
								</f-text>
							</f-div>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<!-- Statement compliance end -->
			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<f-text size="medium" weight="bold" state="subtle" align="left">
				BEST PRACTICES IN SCOPE
			</f-text>
			<BestPracticeInScopeList :component="component"></BestPracticeInScopeList>

			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<f-text size="medium" weight="bold" state="subtle" align="left"> STATEMENTS </f-text>
			<BestPracticeInScopeList
				:component="component"
				:show-statements-detail-view="true"
			></BestPracticeInScopeList>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { FDivStateProp } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import BestPracticeInScopeList from "@/modules/release-cockpit-v2/components/best-practice-report/BestPracticeInScopeList.vue";
import { getIconFromEnvironment } from "@/shared/constants";
import { captureError, formatDateString } from "@/utils";

import { getTaxonomiesList } from "../release-cockpit-types";

export default defineComponent({
	name: "BestPracticeReportPage",

	components: {
		BestPracticeInScopeList
	},

	props: {
		componentId: {
			type: String,
			required: true
		}
	},

	data: () => {
		return {
			getIconFromEnvironment,
			hasFetchError: false,
			isLoading: true,

			statementCompliance: [
				{
					label: "LOW",
					count: 0,
					state: "subtle" as FDivStateProp
				},
				{
					label: "MEDIUM",
					count: 0,
					state: "warning" as FDivStateProp
				},
				{
					label: "HIGH",
					count: 0,
					state: "danger" as FDivStateProp
				}
			]
		};
	},

	computed: {
		...mapStores(componentOnboardStore, componentCatalogStore),

		currentDate() {
			return formatDateString(Date.now());
		},

		taxonomies() {
			return getTaxonomiesList(this.component!)
				.filter(taxonomy => taxonomy)
				.join(" / ");
		},

		metaData() {
			return {
				Classification: { type: "text", value: this.taxonomies },

				Justification: {
					type: "secondary",
					value: String(this.component?.metadata?.justification)
				},

				Description: {
					type: "text",
					value: String(this.component?.metadata?.description)
				},

				"Technology approver": {
					type: "secondary",
					value: this.component?.approvedBy
				}
			} as const;
		},

		component() {
			return this.componentOnboardStore.components[this.componentId] ?? null;
		},

		complianceStatements() {
			const componentStats = this.componentOnboardStore.getComponentStats(this.componentId);

			let totalStatements = 0;
			let totalCompliant = 0;

			Object.values(componentStats).forEach(componentStat => {
				totalStatements += componentStat.totalStatements;
				totalCompliant += componentStat.approvedStatements;
			});

			return {
				totalStatements,
				totalCompliant,
				totalNonCompliant: totalStatements - totalCompliant
			};
		}
	},

	async mounted() {
		try {
			this.isLoading = true;
			const component = await this.componentOnboardStore.getComponentById(this.componentId);

			if (!component) {
				this.hasFetchError = true;
				return;
			}

			await Promise.all([
				this.componentOnboardStore.fetchComponentOnboardingDocuments(component),
				this.componentCatalogStore.fetchTaxonomies(),
				this.componentOnboardStore.fetchGatesAndEnvironments()
			]);
		} catch (error) {
			captureError(error);
			this.hasFetchError = true;
		} finally {
			this.isLoading = false;
		}
	}
});
</script>
