<template>
	<PermissionsWrapper>
		<f-div direction="column" gap="none" padding="none" width="fill-container" overflow="visible">
			<f-div
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="secondary"
				height="hug-content"
				border="medium solid subtle bottom"
				overflow="visible"
				align="middle-center"
			>
				<f-text size="medium" variant="para" state="secondary" weight="bold">
					LZ Compliance Report
				</f-text>
			</f-div>

			<f-div direction="column" gap="large" align="middle-center">
				<f-div
					v-if="error.status"
					width="55%"
					height="hug-content"
					state="danger"
					padding="small"
					align="middle-center"
					gap="small"
				>
					<f-icon source="i-alert" state="inherit" size="small"></f-icon>
					<f-text state="default" size="small">{{ error.message }}</f-text>
				</f-div>
				<f-div direction="column" gap="small" padding="small" width="50%" height="hug-content">
					<f-text variant="heading" size="small" weight="bold" state="default" align="left">
						Step 1: Upload document
					</f-text>

					<f-div direction="column" width="fill-container" gap="medium" align="middle-center">
						<f-form-builder
							ref="formBuilder"
							class="width-100-per"
							gap="medium"
							:field.prop="formFields"
							:values.prop="formValues"
							@input="handleInput"
							@state-change="formState = $event.detail"
						/>
						<f-button
							category="fill"
							state="primary"
							variant="round"
							:loading="loading"
							:disabled="!formState?.isValid"
							data-qa-update-file-upload-url-btn
							label="GENERATE REPORT"
							@click="upload"
						></f-button>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</PermissionsWrapper>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { getByType } from "@/modules/constellation-map/correlation-service";
import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { captureError, convertImageFileToBase64 } from "@/utils";

import LzComplianceReportService from "../lz-compliance-report-service";
import { lzComplianceReportStore } from "../lz-compliance-report-store";
import {
	DEFAULT_ERROR_OBJECT,
	FileUploadFormValues,
	DEFAULT_COUNTRY_ITEM,
	CountryOptions,
	CLOUD_PROVIDER_OPTIONS
} from "../lz-compliance-report-types";

export default defineComponent({
	name: "LzComplianceReportForm",

	components: {
		PermissionsWrapper
	},

	data: () => {
		return {
			loading: false,
			error: DEFAULT_ERROR_OBJECT,
			formValues: {} as FileUploadFormValues,
			formState: null as FormBuilderState | null,
			countryList: [] as CountryOptions[],
			cloudProviderOptions: CLOUD_PROVIDER_OPTIONS as CountryOptions[] | []
		};
	},

	computed: {
		...mapStores(lzComplianceReportStore, notificationsStore),

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					file: {
						type: "file",
						qaId: "report-file-upload",
						placeholder: "Drag and drop files or click here to upload",
						label: { title: "Upload Document", subTitle: "Select csv file to generate report" },
						validationRules: [{ name: "required" }],
						multiple: false,
						fileType: ".csv"
					},

					country: {
						type: "select",
						qaId: "country-list",
						placeholder: "Select country",
						label: { title: "Select Country" },
						validationRules: [{ name: "required" }],
						options: this.countryList,
						searchable: true,
						selection: "multiple"
					},

					cloudProvider: {
						type: "radio",
						qaId: "cloud-list",
						label: { title: "Cloud provider" },
						validationRules: [{ name: "required" }],
						options: this.cloudProviderOptions,
						direction: "horizontal"
					},

					companyName: {
						type: "text",
						qaId: "company-name",
						placeholder: "Company name",
						label: { title: "Company name" },
						validationRules: [{ name: "required" }]
					},

					companyLogo: {
						type: "file",
						qaId: "report-company-logo",
						placeholder: "Drag and drop files or click here to upload",
						label: { title: "Upload logo", subTitle: "Select image for company logo" },
						multiple: false,
						fileType: ".png, .jpeg"
					}
				}
			};
		}
	},

	mounted() {
		this.fetchCountryList();
		this.setDefaultFormValues();
	},

	methods: {
		setDefaultFormValues() {
			this.formState = null;
			this.formValues = {
				...this.formValues,
				cloudProvider: CLOUD_PROVIDER_OPTIONS[0]!
			};
		},

		async fetchCountryList() {
			const countries: CountryOptions[] = [DEFAULT_COUNTRY_ITEM];

			const response = await getByType({
				nodeType: "Country"
			});

			response.nodes?.forEach(node =>
				countries.push({ id: String(node.props?.id), title: String(node.props?.name) })
			);
			this.countryList = countries;
		},

		async upload() {
			this.loading = true;
			this.error = DEFAULT_ERROR_OBJECT;
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			try {
				this.loading = true;
				const countryNames = this.formValues.country.map(item => item.title).join(", ");
				const response = await LzComplianceReportService.generateComplianceReport(
					this.formValues.file,
					countryNames,
					this.formValues.cloudProvider.id
				);

				if (response.data.name) {
					const reportContent = response.data;
					reportContent.companyName = this.formValues.companyName;
					reportContent.selectedCountries = countryNames;
					reportContent.cloudProvider = this.formValues.cloudProvider.title;
					convertImageFileToBase64(this.formValues.companyLogo)
						.then(base64 => {
							reportContent.companyLogo = base64;
						})
						.catch(() => {
							reportContent.companyLogo = "";
						});
					this.lzComplianceReportStore.generatingReport(true);
					this.lzComplianceReportStore.setLzCompilanceReportContent(reportContent);

					/* File Upload complete now load MetaData upload screen */
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-meta-data-updated",
						title: "LZ compliance report generated successfully.",
						text: "You can now see generated pdf report for your uploaded document.",
						status: "success"
					});
					setTimeout(() => this.$router.push({ name: "view-lz-compliance-report" }), 1000);
				}
			} catch (error) {
				captureError(error);
				this.error = {
					status: true,
					message: "Error while generating report"
				};
				this.loading = false;
				this.setDefaultFormValues();
			}
		},

		handleInput(event: CustomEvent<FileUploadFormValues>) {
			this.formValues = event.detail;
		}
	}
});
</script>
