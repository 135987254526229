<template>
	<f-div height="100%" width="fill-container">
		<slot v-if="routeAccess"></slot>
		<f-div v-else-if="!routeAccess && !loading" align="middle-center" height="100%">
			<f-div
				direction="column"
				align="middle-center"
				gap="large"
				width="hug-content"
				height="hug-content"
			>
				<f-pictogram source="i-stop" variant="circle" size="x-large" state="danger"></f-pictogram>
				<f-div direction="column" gap="small" align="middle-center" width="233px">
					<f-text variant="heading" size="medium" weight="bold" align="center"
						>Access denied</f-text
					>
					<f-text variant="para" size="medium" weight="regular" align="center">
						You do not have permission to access this page</f-text
					>
				</f-div>
				<f-div align="middle-center">
					<f-button label="GO BACK" size="small" variant="round" @click="goback()"></f-button>
				</f-div>
			</f-div>
		</f-div>
		<f-div v-else-if="loading" height="100%" loading="loader"
			><f-text variant="para" size="medium" weight="regular">loader</f-text>
		</f-div>
		<div v-else />
	</f-div>
</template>
<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";

export default defineComponent({
	name: "PermissionsWrapper",

	computed: {
		...mapStores(authStore),

		loading() {
			return this.authStore.permissionsLoading;
		},

		routeAccess() {
			if (!this.$route.meta.requiredRole) {
				return true;
			}

			return (
				Object.keys(this.authStore.userPermissions).length > 0 &&
				this.authStore.userPermissions[this.$route.meta.requiredRole]?.enabled
			);
		}
	},

	mounted() {
		this.getPermissions();
	},

	methods: {
		async getPermissions() {
			await this.authStore.GET_USER_PERMISSIONS();
		},

		goback() {
			this.$router.go(-1);
		}
	}
});
</script>
