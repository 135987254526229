import { App } from "vue";
import { Router } from "vue-router";

import { getAppVersion, getEnvironment } from ".";

export async function initSentry({ app, router }: { app: App; router: Router }) {
	const Sentry = await import("@sentry/vue");

	// https://cloudcover.sentry.io/issues/?project=4505759161188352
	Sentry.init({
		release: getAppVersion(),
		environment: getEnvironment(),
		app,
		dsn: "https://953a3624194c9a6763b756364c367516@o351621.ingest.sentry.io/4505759161188352",
		integrations: [
			Sentry.browserTracingIntegration({
				router
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0
	});
}
