<template>
	<f-div v-if="isLoading" height="100%" align="middle-center" direction="column" gap="medium">
		<f-icon source="i-loader" size="x-large" loading></f-icon>
		<f-text>Generating report...</f-text>
	</f-div>
	<f-div
		v-else-if="lzReportContent.components && lzReportContent.components.length > 0"
		direction="column"
		max-width="800px"
		align="top-center"
		:style="{ margin: '0 auto' }"
		overflow="visible"
		:show-scrollbar="true"
	>
		<!-- Header start -->
		<f-div state="subtle" direction="column" padding="x-large" gap="medium">
			<f-div gap="auto" padding="none">
				<f-div direction="column" width="fill-container">
					<f-tag
						size="large"
						state="neutral"
						category="fill"
						max-width="100%"
						label="LZ COMPLIANCE REPORT"
					>
					</f-tag>
					<f-div
						align="middle-right"
						width="hug-content"
						:class="noPrint ? 'no-print' : 'save-as-pdf-button'"
					>
						<f-button
							label="Save as PDF"
							category="fill"
							size="small"
							state="primary"
							@click="saveAsPdf"
						></f-button>
					</f-div>
					<f-div gap="x-small" direction="column" padding="medium none small none">
						<f-text variant="heading" weight="bold" size="x-large" state="primary">
							{{ lzReportContent?.name }}
						</f-text>
						<f-text variant="heading" size="small" weight="medium" state="secondary">
							{{ currentDate }}
						</f-text>
					</f-div>
				</f-div>

				<f-div v-if="lzReportContent?.companyLogo" align="middle-right" width="hug-content">
					<img type="image" :src="lzReportContent?.companyLogo" width="100px" height="100px" />
				</f-div>
			</f-div>
		</f-div>
		<!-- Header end -->
		<f-div padding="x-large" state="default" gap="x-large" direction="column">
			<f-text size="medium" weight="bold" state="subtle" align="left"> REPORT DETAILS </f-text>

			<f-div direction="column" gap="x-small">
				<f-div v-for="(value, name) in metaData" :key="name" direction="row" overflow="visible">
					<f-div :style="{ minWidth: '200px' }" width="hug-content">
						<f-text variant="heading" size="medium" weight="medium" state="subtle" align="left">{{
							name
						}}</f-text>
					</f-div>

					<f-text size="medium" weight="regular" state="default">{{ value.value }}</f-text>
				</f-div>
			</f-div>

			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<!-- Statement compliance start -->
			<f-text size="medium" weight="bold" state="subtle" align="left">
				STATEMENT COMPLIANCE
			</f-text>
			<f-div gap="x-large" direction="column" width="fill-container">
				<f-div gap="medium" direction="row">
					<f-div gap="x-large" direction="row">
						<f-div
							v-for="(statement, key) in statementCompliance"
							:key="key"
							direction="column"
							gap="small"
							width="45%"
							padding="small"
						>
							<f-text variant="heading" size="medium" weight="medium" state="subtle" align="left">
								{{ statement.label }}
							</f-text>
							<f-text variant="heading" size="large" weight="bold" state="subtle" align="left">
								{{ statement.count }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<!-- Statement compliance end -->

			<f-text size="medium" weight="bold" state="subtle" align="left">
				BEST PRACTICES IN SCOPE
			</f-text>
			<f-div gap="x-large" direction="column" width="fill-container">
				<f-div gap="medium" direction="row">
					<f-div gap="x-large" direction="row">
						<f-div
							v-for="(statement, key) in lzReportContent.bestPracticesApplied"
							:key="key"
							direction="row"
							gap="small"
							width="45%"
							padding="small"
							align="middle-left"
						>
							<f-pictogram
								size="medium"
								state="primary"
								variant="squircle"
								:category="undefined"
								:auto-bg="true"
								:source="statement?.toLocaleUpperCase()"
							>
							</f-pictogram>
							<f-text variant="heading" size="small" weight="bold" state="subtle" align="left">
								{{ statement }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<f-text size="medium" weight="bold" state="subtle" align="left">
				REGULATIONS IN SCOPE
			</f-text>
			<f-div gap="x-large" direction="column" width="fill-container">
				<f-div gap="medium" direction="row">
					<f-div
						v-if="Object.keys(lzReportContent.regulatoryOrganisationDocuments).length > 0"
						gap="x-large"
						direction="row"
					>
						<f-div
							v-for="(statement, key) in lzReportContent.regulatoryOrganisationDocuments"
							:key="key"
							direction="row"
							gap="small"
							padding="small"
							align="middle-left"
						>
							<f-pictogram
								size="medium"
								state="primary"
								variant="squircle"
								:category="undefined"
								:auto-bg="true"
								:source="key?.toLocaleUpperCase()"
							>
							</f-pictogram>

							<f-div width="hug-content" gap="medium">
								<f-text
									variant="heading"
									size="small"
									weight="bold"
									state="subtle"
									align="left"
									:inline="true"
								>
									{{ key }}
								</f-text>
								<f-text
									variant="para"
									size="small"
									weight="bold"
									state="success"
									align="left"
									:inline="true"
								>
									{{ statement }}
								</f-text>
							</f-div>
						</f-div>
					</f-div>
					<f-div v-else gap="x-large" direction="row">
						<f-text variant="heading" size="small" state="secondary" align="center">
							No regulatory org statements found for selected countries.
						</f-text>
					</f-div>
				</f-div>
			</f-div>
			<f-divider state="subtle" variant="dashed" size="large"></f-divider>

			<f-text size="medium" weight="bold" state="subtle" align="left"> STATEMENTS </f-text>
			<f-div>
				<BestPracticeInScopeList :components="lzReportContent.components"></BestPracticeInScopeList>
			</f-div>
		</f-div>
	</f-div>
	<f-div v-else>
		<EmptyState
			action="Add new document"
			:icon="emptyIcon"
			message="Error in generating report"
			subtitle="No components available"
			shape="circle"
			@actions="redirectToReportUploadPage"
		>
		</EmptyState>
	</f-div>
</template>
<script lang="ts">
import { EmptyState, EmptyStateIconProp } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import BestPracticeInScopeList from "@/modules/lz-compliance-report/components/best-practice-report/BestPracticeInScopeList.vue";
import { LzComponentReport } from "@/modules/lz-compliance-report/lz-compliance-report-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { formatDateString } from "@/utils";

import { lzComplianceReportStore } from "../lz-compliance-report-store";

export default defineComponent({
	name: "LzComplianceReportPage",

	components: {
		BestPracticeInScopeList,
		EmptyState
	},

	data: () => {
		return {
			emptyIcon: {
				name: "i-file",
				type: "filled",
				state: "primary",
				size: "medium",
				color: "gray-200"
			} as EmptyStateIconProp,

			isLoading: true,
			lzReportContent: {} as LzComponentReport,
			noPrint: false,
			statementCompliance: {
				"total-components": {
					label: "Total Components",
					count: 0
				},

				"total-bestPracticeStatements": {
					label: "Total Best Practice Statements",
					count: 0
				},

				"total-compliant": {
					label: "Compliant",
					count: 0
				},

				"total-nonCompliant": {
					label: "Non Compliant",
					count: 0
				}
			}
		};
	},

	computed: {
		...mapStores(lzComplianceReportStore, notificationsStore),

		metaData() {
			return {
				"Company name": { type: "text", value: this.lzReportContent.companyName },

				Countries: {
					type: "secondary",
					value: String(this.lzReportContent.selectedCountries)
				},

				"Cloud Provider": {
					type: "secondary",
					value: String(this.lzReportContent.cloudProvider)
				}
			} as const;
		},

		getLzComplianceReportContent() {
			return this.lzComplianceReportStore.lzReportContent;
		},

		currentDate() {
			return formatDateString(Date.now());
		}
	},

	mounted() {
		const content = this.getLzComplianceReportContent;
		if (
			Object.keys(content).length > 0 &&
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			content.components !== null &&
			content.components.length > 0
		) {
			this.lzReportContent = content;
			this.setStatementCompliance();
		} else {
			this.isLoading = false;
		}
	},

	methods: {
		redirectToReportUploadPage() {
			this.isLoading = false;
			this.notificationsStore.ADD_TOAST({
				qaId: "toast-meta-data-updated",
				title: "Please uploaded Lz Report.",
				text: "Report not available.",
				status: "warning"
			});
			setTimeout(() => this.redirectOnDocUploadPage(), 1000);
		},

		redirectOnDocUploadPage() {
			this.$router.push({ name: "lz-compliance-report" });
		},

		saveAsPdf() {
			this.noPrint = true;
			this.$nextTick(() => {
				window.print();
				this.noPrint = false;
			});
		},

		setStatementCompliance() {
			const { components } = this.lzReportContent;
			let bestPracticeCount = 0,
				compliantStatementsCount = 0,
				nonCompliantStatementsCount = 0;

			components.forEach(component => {
				if (component.bestPractices.length) {
					component.bestPractices.forEach(({ statements }) => {
						bestPracticeCount += statements.length;
						statements.forEach(({ evidences }) => {
							// eslint-disable-next-line max-nested-callbacks
							evidences.forEach(({ status }) => {
								if (["Failed", "No Data"].includes(status)) {
									nonCompliantStatementsCount++;
								} else if (status === "Passed") {
									compliantStatementsCount++;
								}
							});
						});
					});
				}
			});
			this.isLoading = false;
			this.statementCompliance["total-components"].count = components.length;
			this.statementCompliance["total-bestPracticeStatements"].count = bestPracticeCount;
			this.statementCompliance["total-compliant"].count = compliantStatementsCount;
			this.statementCompliance["total-nonCompliant"].count = nonCompliantStatementsCount;
		}
	}
});
</script>

<style lang="scss">
.save-as-pdf-button {
	position: absolute;
	left: 240px;
	z-index: 1;
	top: 3%;
}

.no-print {
	display: none !important;
}
</style>
