<template>
	<f-div direction="column" width="fill-container" height="fill-container">
		<f-div
			padding="medium"
			gap="medium"
			width="100%"
			align="top-left"
			height="hug-content"
			border="small solid secondary bottom"
		>
			<f-text variant="para" size="medium" weight="medium" state="default" align="left">
				Software artifact
			</f-text>
		</f-div>

		<f-div
			v-if="!assetArtifact"
			direction="column"
			padding="medium none medium none"
			width="fill-container"
		>
			<!-- CREATE form -->
			<f-div padding="large" align="top-center" height="hug-content">
				<f-form-builder
					ref="formBuilder"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</f-div>

			<!-- Error message -->
			<f-div
				v-if="submitError"
				padding="medium"
				height="hug-content"
				gap="medium"
				direction="row"
				width="100%"
				state="danger"
			>
				<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
					{{ submitError }}
				</f-text>
				<f-div align="top-right" width="hug-content">
					<f-icon
						class="cursor-pointer"
						source="i-close"
						size="x-small"
						data-qa-error-close-icon
						data-qa-close-control-gate-popover-error-popover
						@click="submitError = ''"
					>
					</f-icon>
				</f-div>
			</f-div>

			<!-- footer -->
			<f-div padding="large" width="fill-container" gap="medium" align="bottom-center">
				<f-button
					label="add artifacts"
					variant="block"
					size="large"
					category="outline"
					state="neutral"
					:disabled="!formState?.isValid"
					:loading="isLoading"
					data-qa="add-artifacts-button"
					@click="addAssetArtifact"
				></f-button>
			</f-div>
		</f-div>
		<f-div v-else>
			<f-div gap="large" direction="column" width="fill-container" padding="medium">
				<f-div direction="column" gap="medium" height="hug-content">
					<f-text variant="para" size="small" weight="regular" state="secondary" align="left">
						Artifact URL
					</f-text>
					<f-text variant="para" size="small" weight="regular" align="left" state="primary">
						{{ assetArtifact?.url }}
					</f-text>
				</f-div>
				<f-button
					label="view artifact"
					variant="round"
					size="small"
					category="fill"
					state="primary"
					@click="viewArtifact"
				></f-button>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import {
	createArtifact,
	updateArtifact
} from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import {
	ArtifactType,
	Component,
	CreateArtifactRequest,
	UpdateArtifactRequest
} from "@/protocol/cockpit";
import { GCP_STORAGE_DETAILS_BASE_URL } from "@/shared/constants";

type FormValues = {
	artifactUrl: string;
};

export default defineComponent({
	name: "SoftwareArtifactRightPane",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => ({
		formState: null as FormBuilderState | null,
		formValues: {
			artifactUrl: ""
		} as FormValues,

		submitError: "",
		isLoading: false
	}),

	computed: {
		...mapStores(authStore, notificationsStore, componentOnboardStore),

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					artifactUrl: {
						type: "text",
						qaId: "artifact-url",
						label: { title: "Artifact URL" },
						placeholder: "Enter artifact URL",
						validationRules: [{ name: "required" }]
					}
				}
			};
		},

		assetArtifact() {
			const { artifact } = this.component;

			if (artifact?.type === ArtifactType.ARTIFACT_UNKNOWN) {
				return null;
			}

			const isSoftwareBinary = artifact?.type === ArtifactType.BINARY;

			if (!isSoftwareBinary) {
				return null;
			}

			const assetDetails = artifact.metadata?.binary;

			if (!assetDetails) {
				return null;
			}

			return assetDetails;
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			const values = { ...event.detail };
			this.formValues = values;
		},

		getUserId() {
			return this.authStore.userSession?.identity?.id;
		},

		viewArtifact() {
			if (this.assetArtifact?.bucketURL) {
				window.open(GCP_STORAGE_DETAILS_BASE_URL + this.assetArtifact.bucketURL, "_blank");
			}
		},

		async addAssetArtifact() {
			this.isLoading = true;
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			try {
				this.isLoading = true;

				const payload: CreateArtifactRequest = {
					componentId: this.component.id ?? "",
					name: this.component.name ?? "",
					status: "active",
					createdBy: this.getUserId() ?? "",
					approvedBy: "",
					type: ArtifactType.BINARY,
					metadata: {
						binary: {
							url: this.formValues.artifactUrl
						}
					}
				};

				const resp = await createArtifact(payload);
				if (resp.artifact?.id) {
					const updateArtifactReq: UpdateArtifactRequest = {
						id: resp.artifact.id,
						componentId: resp.artifact.componentId,
						name: resp.artifact.name,
						status: resp.artifact.status,
						createdBy: resp.artifact.createdBy,
						approvedBy: resp.artifact.approvedBy,
						type: resp.artifact.type,
						metadata: resp.artifact.metadata
					};
					await updateArtifact(updateArtifactReq);
				}

				await this.componentOnboardStore.getComponentById(this.component.id);
				this.isLoading = false;

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated",
					title: "Software artifact",
					text: "Software artifact added successfully.",
					status: "success"
				});
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated-error",
					title: "Error while adding software artifact",
					text:
						error === "string" ? error : "Error while adding software artifact, please try again",
					status: "error"
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
});
</script>
