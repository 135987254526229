import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import EvidenceDisplay from "./components/EvidenceDisplay.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/evidence-display/:evidenceId",
		name: "evidence-display",
		component: EvidenceDisplay,
		meta: {
			requiresAuth: true
		} as RouteMeta,
		props: true
	}
];

export function registeEvidenceRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
