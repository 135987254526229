<template>
	<f-div gap="medium">
		<template v-for="crumb in crumbs" :key="crumb.label">
			<f-div
				:tooltip="crumb.label"
				:state="crumb.state"
				:data-qa-crumb-id="crumb.qaId"
				gap="x-small"
				clickable
				@click="handleClick(crumb)"
			>
				<f-icon
					v-if="crumb.icon"
					:source="crumb.icon.source"
					:state="crumb.icon.state ? crumb.icon.state : 'default'"
					size="small"
				></f-icon>

				<template v-else-if="crumb.emoji">
					<span class="flow-b-crumb-emoji" v-html="crumb.emoji" />
				</template>

				<f-text ellipsis>
					{{ crumb.label }}
				</f-text>
			</f-div>
		</template>
	</f-div>
</template>
<script lang="ts">
import { FDivStateProp, FIconState } from "@ollion/flow-core";
import { defineComponent, type PropType } from "vue";

export type CrumbItem = {
	qaId: string;
	label: string;
	route: {
		name: string;
		props?: Record<string, string>;
	};
	shortLabel?: string;
	tabindex?: number;
	icon?: {
		source: string;
		state?: FIconState;
	};
	emoji?: string;
	state?: FDivStateProp;
};

export default defineComponent({
	name: "Breadcrumbs",

	props: {
		crumbs: {
			type: Array as PropType<CrumbItem[]>,
			default: () => []
		}
	},

	emits: ["click"],

	methods: {
		handleClick(crumb: CrumbItem) {
			/**
			 * Click event.
			 *
			 * @event click
			 */
			this.$emit("click", crumb);
		}
	}
});
</script>
