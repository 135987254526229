import { TaxonomyClassificationPayload } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { TAXONOMY_LEVELS } from "@/shared/constants";

export const getComponentTaxonomy = (component: Component) => {
	const classifications: TaxonomyClassificationPayload = {
		level1: undefined,
		level2: undefined,
		level3: undefined,
		level4: undefined,
		level5: undefined,
		level6: undefined,
		level7: undefined,
		level8: undefined
	};
	for (let i = 1; i <= TAXONOMY_LEVELS; i++) {
		const key = `classification${i}`;
		if (component[key as keyof Component]) {
			const taxonomyValue = component[key as keyof Component] as string;
			classifications[`level${i}` as keyof TaxonomyClassificationPayload] = {
				name: taxonomyValue,
				id: taxonomyValue.toLowerCase()
			};
		}
	}
	return classifications;
};
