<template>
	<f-popover :open="isOpen" position="auto" @overlay-click="closeModal">
		<f-div
			state="secondary"
			direction="column"
			overflow="hidden"
			align="top-left"
			height="fill-container"
			width="fill-container"
		>
			<!-- Header -->
			<f-div
				height="hug-content"
				padding="small medium small large"
				border="small solid subtle bottom"
				align="middle-left"
				width="fill-container"
			>
				<f-text variant="heading" size="medium" state="default" weight="bold" align="left">{{
					headerTitle
				}}</f-text>
				<f-icon-button
					icon="i-close"
					variant="block"
					size="small"
					state="inherit"
					@click="closeModal"
				></f-icon-button>
			</f-div>
			<!-- End Header -->

			<f-div
				v-if="errorMsg"
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="danger"
				height="hug-content"
				border="small solid subtle bottom"
				overflow="visible"
			>
				<f-div align="middle-left" width="hug-content" gap="small">
					<f-icon source="i-alert" size="small" state="default"></f-icon>
					<f-text variant="para" size="small" weight="regular" state="default" align="left">
						{{ errorMsg }}
					</f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						label="CLOSE"
						state="warning"
						variant="round"
						category="outline"
						size="x-small"
						data-qa="onboard-reset-yes-button"
						@click="errorMsg = null"
					></f-button>
				</f-div>
			</f-div>

			<!-- Body -->
			<f-div
				padding="large"
				direction="column"
				gap="small"
				overflow="scroll"
				align="top-left"
				height="fill-container"
			>
				<f-div v-if="actionType === 'reject'" direction="column" gap="small">
					<template v-if="details.length">
						<f-div gap="none" direction="column" height="hug-content">
							<f-div
								v-for="detail in details"
								:key="detail.key"
								direction="row"
								overflow="visible"
								height="hug-content"
								align="middle-left"
							>
								<f-div width="33%" align="middle-left"
									><f-text variant="para" size="small" weight="regular" state="secondary">{{
										detail.title
									}}</f-text></f-div
								>
								<f-div align="middle-left"
									><f-text
										variant="para"
										size="small"
										weight="regular"
										:ellipsis="true"
										:tooltip="detail.value"
										>{{ detail.value }}</f-text
									></f-div
								>
							</f-div>
						</f-div>
						<f-divider state="subtle"></f-divider>
					</template>
					<f-form-builder
						gap="small"
						size="small"
						:field.prop="classificationActionFormFields"
						:values.prop="classificationFormValues"
						@input="classificationFormValues = { ...$event.detail }"
					></f-form-builder>
				</f-div>

				<f-text v-else>Are you sure you want to approve this classification?</f-text>
			</f-div>
			<!-- End Body -->

			<!-- Footer -->
			<f-div
				height="hug-content"
				padding="medium"
				gap="medium"
				align="middle-right"
				border="small solid subtle top"
			>
				<f-button
					category="outline"
					icon-left="i-close"
					label="cancel"
					state="neutral"
					size="small"
					@click="closeModal"
				></f-button>

				<f-button
					:label="submitBtnState.label"
					:state="submitBtnState.state"
					size="small"
					:disabled="!isFormComplete"
					:loading="isLoading"
					:icon-left="submitBtnState.iconLeft"
					@click="classificationApproval"
				></f-button>
			</f-div>
			<!-- End Footer -->
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { FButtonState } from "@ollion/flow-core";
import { FormBuilderField } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { classificationApprovalAction } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import { ClassificationActionDetails } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { ApproveRejectWorkflowTaskRequest } from "@/protocol/workflow";
import { getErrorMessage } from "@/utils";
import { captureError } from "@/utils/capture-error";

type ButtonStateType = {
	label: string;
	state: FButtonState;
	iconLeft: string;
};

export default defineComponent({
	name: "ClassificationActionModal",

	props: {
		actionType: {
			type: String as PropType<"approve" | "reject">,
			required: true
		},

		details: {
			type: Object as PropType<ClassificationActionDetails[]>,
			required: true
		},

		component: {
			type: Object as PropType<Component>,
			required: true
		},

		isOpen: {
			type: Boolean,
			required: true
		}
	},

	emits: ["submit", "closeModal"],

	data() {
		return {
			classificationFormValues: {
				reason: ""
			},

			isLoading: false,

			errorMsg: null as null | string
		};
	},

	computed: {
		...mapStores(notificationsStore, componentCatalogStore),

		headerTitle(): string {
			return this.actionType === "reject" ? "Reject classification" : "Approve classification";
		},

		submitBtnState(): ButtonStateType {
			if (this.actionType === "reject") {
				return {
					label: "Reject",
					state: "danger",
					iconLeft: "i-stop"
				};
			}
			return {
				label: "Approve",
				state: "success",
				iconLeft: "i-tick-fill"
			};
		},

		classificationActionFormFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					reason: {
						type: "textarea",
						label: {
							title: this.actionType === "approve" ? "Reason for approval" : "Reason for rejection",
							iconTooltip:
								this.actionType === "approve"
									? "provide a reason for approving the classification"
									: "provide a reason for rejecting the classification. This will be visible to the submitter."
						},

						placeholder:
							this.actionType === "approve"
								? "Enter reason for approval"
								: "Enter reason for rejection"
					}
				}
			};
		},

		isFormComplete() {
			if (this.actionType === "approve") {
				return true;
			}
			return this.classificationFormValues.reason.length > 0;
		}
	},

	methods: {
		closeModal() {
			this.$emit("closeModal");
		},

		async classificationApproval() {
			try {
				this.isLoading = true;
				this.errorMsg = null;

				const payload: ApproveRejectWorkflowTaskRequest = {
					approved: this.actionType === "approve" ? true : false,
					//@todo - Ideally we shouldn't require any comment for approve actions
					comment:
						this.actionType === "approve" ? "Approved" : this.classificationFormValues.reason,

					flagLevel1: this.details.some(detail => detail.key === "classification1"),
					flagLevel2: this.details.some(detail => detail.key === "classification2"),
					flagLevel3: this.details.some(detail => detail.key === "classification3"),
					flagLevel4: this.details.some(detail => detail.key === "classification4"),
					flagLevel5: this.details.some(detail => detail.key === "classification5"),
					flagLevel6: this.details.some(detail => detail.key === "classification6"),
					flagLevel7: this.details.some(detail => detail.key === "classification7"),
					flagLevel8: this.details.some(detail => detail.key === "classification8"),
					componentId: this.component.id ?? ""
				};

				await classificationApprovalAction(payload);

				this.componentCatalogStore.getComponents();
				const msgStatus = this.actionType === "approve" ? "Approved" : "Rejected";

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-approval-classification",
					title: msgStatus,
					text: `Classification request for ${this.component.name} is ${msgStatus}.`,
					status: this.actionType === "approve" ? "success" : "error"
				});

				this.closeModal();
			} catch (error) {
				captureError(error);
				this.errorMsg = getErrorMessage(error);
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-approval-classification-fail",
					title: "Failed",
					text: `Classification request for ${this.component.name} is failed.`,
					status: "error"
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
});
</script>
