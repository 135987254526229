<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { IndexedParagraph, SentenceType } from "@/utils";

import { documentMappingStore, generateLevels } from "../document-mapping-store";

const documentStore = documentMappingStore();
const documentState = computed(() => documentStore.currentDocumentState);

const searchString = ref("");
const hoveredIndexLevel = ref("");
const showStatementNotification = ref(false);
const showingIndexJoin = ref<null | string>(null);
const showOnlyActivities = ref(true);
const showOnlySelected = ref(false);

//@todo - implement indeterminate state of checkboxes

const filteredParagraphs = computed(() => {
	const filteredStatements = documentState.value.statements.filter(statement => {
		// Handle activity checkbox
		if (showOnlyActivities.value && statement.type === SentenceType.INFORMATION) {
			return false;
		}

		// Handle selected checkbox
		if (showOnlySelected.value && !documentState.value.selectedStatementIds[statement.id]) {
			return false;
		}

		return !isIndexJoinHidden(statement.indexJoin);
	});

	if (searchString.value.length > 0) {
		return filteredStatements.filter(statement =>
			statement.str.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase())
		);
	}

	return filteredStatements;
});

const selectedStatementsCount = computed(() => {
	return Object.values(documentState.value.selectedStatementIds).filter(Boolean).length;
});

const indexLevels = computed(() => {
	return generateLevels(documentState.value.statements);
});

const indexLevelSelectionMap = computed(() => {
	const selectionMap: Record<string, boolean> = {};

	indexLevels.value.forEach(level => {
		const statementsForIndex = documentState.value.statements.filter(statement => {
			return (
				statement.indexJoin === level.indexJoin &&
				statement.type === SentenceType.ACTIVITY &&
				!isIndexJoinHidden(statement.indexJoin)
			);
		});
		selectionMap[level.indexJoin] =
			statementsForIndex.length > 0 &&
			statementsForIndex.every(statement => documentState.value.selectedStatementIds[statement.id]);
	});

	return selectionMap;
});

const areAllStatementsChecked = computed(() => {
	return documentState.value.statements
		.filter(statement => statement.type === SentenceType.ACTIVITY)
		.every(statement => documentState.value.selectedStatementIds[statement.id]);
});

// If the user has deselected all statements, ensure that we show all statements
watch(
	[selectedStatementsCount],
	() => {
		if (selectedStatementsCount.value === 0) {
			showOnlySelected.value = false;
		}
	},
	{ immediate: true }
);

function toggleStatementssWithIndexLevel(indexJoin: string) {
	const isSelected = indexLevelSelectionMap.value[indexJoin];

	toggleStatements(
		documentState.value.statements.filter(statement => statement.indexJoin === indexJoin),
		!isSelected
	);
}

const scheduledHoverTimeout = ref<number | null>(null);

function handleHoverOnIndexLevel(indexJoin: string) {
	hoveredIndexLevel.value = indexJoin;

	scheduledHoverTimeout.value = window.setTimeout(() => {
		document.querySelector(`[data-index-join="${indexJoin}"]`)?.scrollIntoView({
			behavior: "smooth"
		});
	}, 350);
}

function cancelHoverScroll() {
	if (scheduledHoverTimeout.value) {
		window.clearTimeout(scheduledHoverTimeout.value);
	}
}

function toggleAllStatements() {
	const isSelected = areAllStatementsChecked.value;
	toggleStatements(documentState.value.statements, !isSelected);
}

function toggleStatements(statements: IndexedParagraph[], isSelected?: boolean) {
	documentStore.TOGGLE_STATEMENTS_SELECTION(statements, Boolean(isSelected));
}

function showIndexLevel(indexJoin: string) {
	if (showingIndexJoin.value === indexJoin) {
		showingIndexJoin.value = null;
	} else {
		showingIndexJoin.value = indexJoin;
	}
}

function isIndexJoinHidden(indexJoin: string) {
	return showingIndexJoin.value ? showingIndexJoin.value !== indexJoin : false;
}
</script>

<template>
	<f-div
		v-if="showStatementNotification"
		state="primary"
		padding="medium"
		align="middle-left"
		gap="small"
		height="hug-content"
	>
		<f-icon source="i-info-fill" state="primary"></f-icon>
		<f-text>Select all statements to be included in the document.</f-text>
		<f-button
			label="Got it"
			size="small"
			state="neutral"
			category="outline"
			@click="showStatementNotification = false"
		></f-button>
	</f-div>

	<f-div
		padding="medium"
		align="middle-left"
		gap="small"
		border="small solid subtle bottom"
		height="hug-content"
	>
		<f-text inline size="small" weight="medium">Select statements</f-text>
		<f-divider></f-divider>
		<f-search
			category="fill"
			size="small"
			variant="round"
			placeholder="Search statements"
			:value.prop="searchString"
			data-qa="handle-search-cobit-mapping"
			@input="searchString = $event.detail.value"
		></f-search>

		<f-div width="hug-content" gap="medium" align="middle-right">
			<f-switch
				v-model="showOnlyActivities"
				tooltip="Show only statements which are considered as an activity."
				size="medium"
				state="default"
			>
				<f-div slot="label" padding="none">
					<f-text variant="para" size="small">Only activities</f-text>
				</f-div>
			</f-switch>

			<f-divider></f-divider>

			<f-switch
				v-model="showOnlySelected"
				tooltip="Show only statements which have been selected."
				:disabled="selectedStatementsCount === 0"
				size="medium"
				state="default"
			>
				<f-div slot="label" padding="none">
					<f-text variant="para" size="small">Only selected</f-text>
				</f-div>
			</f-switch>
		</f-div>
	</f-div>

	<f-div overflow="hidden" height="fill-container">
		<!-- Statement levels start -->
		<f-div
			width="hug-content"
			style="min-width: 320px"
			max-width="480px"
			border="small solid subtle right"
			direction="column"
			overflow="scroll"
		>
			<f-div border="small solid subtle bottom" padding="small" height="hug-content">
				<f-text weight="medium" state="subtle">Statement levels</f-text>
			</f-div>

			<f-div
				v-for="indexLevel in indexLevels"
				:key="indexLevel.indexJoin"
				class="show-on-hover-parent"
				height="hug-content"
				padding="small x-large small large"
				border="small solid subtle bottom"
				align="middle-left"
				gap="small"
				overflow="visible"
				@mouseover="handleHoverOnIndexLevel(indexLevel.indexJoin)"
				@mouseout="cancelHoverScroll"
			>
				<f-spacer v-for="depth in indexLevel.depth" :key="depth" size="medium"></f-spacer>
				<f-checkbox
					v-if="indexLevel.activityCount > 0"
					size="small"
					:disabled="isIndexJoinHidden(indexLevel.indexJoin)"
					:value="indexLevelSelectionMap[indexLevel.indexJoin] ? 'checked' : 'unchecked'"
					@input="toggleStatementssWithIndexLevel(indexLevel.indexJoin)"
				></f-checkbox>
				<f-text inline :disabled="isIndexJoinHidden(indexLevel.indexJoin)"
					>{{ indexLevel.displayString.text }}
					<f-text inline size="small" state="subtle">{{
						indexLevel.displayString.subText
					}}</f-text></f-text
				>

				<f-icon
					v-if="indexLevel.activityCount > 0"
					source="i-info-fill"
					state="subtle"
					size="small"
					:tooltip="`${indexLevel.activityCount} statements`"
				></f-icon>

				<f-div
					v-if="indexLevel.activityCount > 0"
					align="middle-right"
					:class="showingIndexJoin === indexLevel.indexJoin ? '' : 'show-on-hover'"
				>
					<f-icon
						source="i-view"
						state="subtle"
						size="small"
						clickable
						tooltip="Only show statements in this level"
						@click="showIndexLevel(indexLevel.indexJoin)"
					></f-icon>
				</f-div>
			</f-div>
		</f-div>
		<!-- Statement levels end -->

		<f-div
			v-if="filteredParagraphs.length > 0"
			direction="column"
			overflow="scroll"
			show-scrollbar
			padding="medium"
		>
			<f-div
				v-if="showingIndexJoin === null"
				height="hug-content"
				gap="medium"
				padding="small"
				clickable
				align="middle-left"
				@click="toggleAllStatements"
			>
				<f-checkbox
					:value="areAllStatementsChecked ? 'checked' : 'unchecked'"
					size="small"
					@input="toggleAllStatements"
				></f-checkbox>
				<f-text weight="medium">All statements</f-text>
			</f-div>

			<f-div
				v-for="statement in filteredParagraphs"
				:key="statement.id"
				:data-index-join="statement.type === 'activity' ? statement.indexJoin : null"
				:state="hoveredIndexLevel === statement.indexJoin ? 'secondary' : 'transparent'"
				height="hug-content"
				padding="small"
				align="top-left"
				:style="{ opacity: statement.type === 'information' ? 0.4 : 1 }"
			>
				<template v-if="statement.index.length > 0">
					<f-spacer v-for="idx in statement.index.length - 1" :key="idx" size="large"></f-spacer>
				</template>

				<f-checkbox
					:disabled="statement.type === 'information'"
					size="small"
					:value="documentState.selectedStatementIds[statement.id] ? 'checked' : 'unchecked'"
					@input="toggleStatements([statement], !documentState.selectedStatementIds[statement.id])"
				></f-checkbox>

				<f-spacer size="medium"></f-spacer>

				<f-text v-if="statement.listMatch.listType !== 'paragraph'" inline
					>{{ statement.listMatch.delimiter }} &nbsp;</f-text
				>

				<f-text
					:tooltip="
						statement.type === 'information'
							? 'This statement is informational and cannot be selected'
							: ''
					"
					>{{ statement.str }}</f-text
				>
			</f-div>
		</f-div>
	</f-div>
</template>
