// src/data.ts

import { Component } from "@/protocol/cockpit";

export interface Taxonomy {
	[key: string]: string;
}

export interface TreeNode {
	name: string;
	classificationLevel: string;
	componentId: string;
	children: { [key: string]: TreeNode };
}

export const buildTree = (components: Component[]): TreeNode[] => {
	const roots: { [key: string]: TreeNode } = {};

	components.forEach(component => {
		const taxonomies: Taxonomy = {
			...(component.classification1 && { classification1: component.classification1 }),
			...(component.classification2 && { classification2: component.classification2 }),
			...(component.classification3 && { classification3: component.classification3 }),
			...(component.classification4 && { classification4: component.classification4 }),
			...(component.classification5 && { classification5: component.classification5 }),
			...(component.classification6 && { classification6: component.classification6 }),
			...(component.classification7 && { classification7: component.classification7 }),
			...(component.classification8 && { classification8: component.classification8 }),
			...(component.name && { classification9: component.name })
		};

		if (Object.keys(taxonomies).length === 1) {
			return;
		}
		const classification1 = taxonomies.classification1 ?? "";

		if (!roots[classification1]) {
			roots[classification1] = {
				name: classification1,
				children: {},
				componentId: component.id ?? "",
				classificationLevel: "classification1"
			};
		}

		let currentNode = roots[classification1] as TreeNode;

		Object.keys(taxonomies).forEach((classification, index) => {
			if (index === 0) {
				return;
			} // Skip the classification1 level as it is the root
			const value = taxonomies[classification] ?? "";
			if (!currentNode.children[value]) {
				currentNode.children[value] = {
					name: value,
					children: {},
					componentId: component.id ?? "",
					classificationLevel: classification
				};
			}
			currentNode = currentNode.children[value] as TreeNode;
		});
	});

	return Object.values(roots);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getChildrenOrSingleChild = (nodes: TreeNode[]): TreeNode[] => {
	if (nodes.length > 1) {
		return nodes;
	}

	const [firstNode] = nodes;

	if (!firstNode) {
		return [];
	}

	const children = Object.values(firstNode.children);

	if (children.length > 1) {
		return nodes.flatMap(node => Object.values(node.children));
	}

	const [onlyChild] = children;
	if (!onlyChild) {
		return [firstNode];
	}

	// return [onlyChild];
	return traverse([onlyChild], [onlyChild.name]);
};

const traverse = (nodes: TreeNode[], levels: string[]): TreeNode[] => {
	if (levels.length === 0) {
		return nodes;
		// return getChildrenOrSingleChild(nodes);
	}

	const nextLevel = levels.shift();
	const matchedNodes: TreeNode[] = [];

	for (const node of nodes) {
		if (node.name === nextLevel) {
			matchedNodes.push(node);
		} else if (node.children[nextLevel!]) {
			const childNode = node.children[nextLevel!];
			if (childNode) {
				matchedNodes.push(childNode);
			}
		}
	}

	return traverse(matchedNodes, levels);
};

export const renderTreeFromLevel = (tree: TreeNode[], level: string): TreeNode[] => {
	const levels = level.split(".").filter(Boolean);
	return traverse(tree, levels);
};
