import { AxiosResponse } from "axios";

import {
	ComponentControlsCountResponse,
	ComponentsResponse,
	ControlsCountResponse,
	CorrelationService,
	GetApplicationsResponse,
	GetControlsComponentIDResponse,
	GetCountriesResponse,
	Neo4JEntities
} from "@/protocol/correlation";
import { addQueryParams } from "@/shared/append-query-parameter-in-url";
import { CORRELATION_SERVICE_PATH } from "@/shared/service-paths";

import HTTP, { createCancelToken } from "../services/api-service";

export const generateReport = (application: string) => {
	const cancelTokenSource = createCancelToken();
	const headers = { "Content-Type": "blob" };
	return HTTP.get(`${CORRELATION_SERVICE_PATH}/reportpdf?applicationName=${application}`, {
		method: "GET",
		responseType: "arraybuffer",
		headers,
		cancelToken: cancelTokenSource.token
	});
};
export const getComponentByApplicationId: CorrelationService["getApplicationComponents"] =
	async request => {
		const cancelTokenSource = createCancelToken();
		const response: AxiosResponse<ComponentsResponse> = await HTTP.get(
			addQueryParams(`${CORRELATION_SERVICE_PATH}/applications/components`, request),
			{ cancelToken: cancelTokenSource.token }
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

export const getControlsByComponentId: CorrelationService["getControlsComponentID"] =
	async request => {
		const cancelTokenSource = createCancelToken();
		const response: AxiosResponse<GetControlsComponentIDResponse> = await HTTP.post(
			`${CORRELATION_SERVICE_PATH}/controlsComponentID`,
			request,
			{
				cancelToken: cancelTokenSource.token
			}
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

export const getLobByOrgId: CorrelationService["getLob"] = async request => {
	const cancelTokenSource = createCancelToken();
	const response: AxiosResponse<Neo4JEntities> = await HTTP.get(
		addQueryParams(`${CORRELATION_SERVICE_PATH}/lob`, request),
		{
			cancelToken: cancelTokenSource.token
		}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getSubLobByLobAndOrgId: CorrelationService["getSubLobByLobId"] = async request => {
	const cancelTokenSource = createCancelToken();
	const response: AxiosResponse<Neo4JEntities> = await HTTP.get(
		addQueryParams(`${CORRELATION_SERVICE_PATH}/sublob`, request),
		{
			cancelToken: cancelTokenSource.token
		}
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getCountriesList: CorrelationService["getCountries"] = async request => {
	const cancelTokenSource = createCancelToken();
	const response: AxiosResponse<GetCountriesResponse> = await HTTP.get(
		addQueryParams(`${CORRELATION_SERVICE_PATH}/countries`, request),
		{
			cancelToken: cancelTokenSource.token
		}
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getApplications: CorrelationService["getApplications"] = async request => {
	const cancelTokenSource = createCancelToken();
	const response: AxiosResponse<GetApplicationsResponse> = await HTTP.get(
		addQueryParams(`${CORRELATION_SERVICE_PATH}/applications`, {
			request
		}),
		{
			cancelToken: cancelTokenSource.token
		}
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getControlSCount: CorrelationService["controlsCount"] = async request => {
	const cancelTokenSource = createCancelToken();
	const response: AxiosResponse<ControlsCountResponse> = await HTTP.get(
		addQueryParams(`${CORRELATION_SERVICE_PATH}/controls/count`, {
			request
		}),
		{
			cancelToken: cancelTokenSource.token
		}
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getControlCountByComponentIds: CorrelationService["componentControlsCount"] =
	async request => {
		const cancelTokenSource = createCancelToken();
		const response: AxiosResponse<ComponentControlsCountResponse> = await HTTP.put(
			`${CORRELATION_SERVICE_PATH}/components/controls/count`,
			request,
			{
				cancelToken: cancelTokenSource.token
			}
		);

		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};
