<template>
	<!--Start : multi-line slab -->
	<f-div
		v-for="(controlStatement, index) in controlSetStatements"
		:key="controlStatement.statementId"
		width="fill-container"
		direction="column"
		:padding="
			index + 1 < controlSetStatements.length ? 'small none none small' : 'small none x-large small'
		"
		gap="large"
	>
		<f-div width="fill-container" gap="large" height="hug-content" direction="row" padding="none">
			<!--Start : slab-left -->
			<f-div data-f-id="slab-left" height="hug-content" width="hug-content">
				<f-icon
					:source="controlStatement.statementStatusIcon.icon"
					size="small"
					:state="controlStatement.statementStatusIcon.state"
					:tooltip="controlStatement.statementStatusIcon.tooltip"
				></f-icon>
			</f-div>
			<!--End : slab-left -->
			<f-div width="fill-container" gap="x-small" direction="column">
				<!--Start : slab-middle  -->
				<f-div
					data-f-id="slab-middle"
					direction="row"
					gap="medium"
					align="middle-left"
					width="fill-container"
				>
					<f-text variant="para" size="medium" state="secondary">
						{{ controlStatement.statement }}
					</f-text>
				</f-div>
				<!--End : slab-middle  -->
			</f-div>
			<!--START : slab-right -->
			<f-div data-f-id="slab-right" width="22%" gap="x-small" direction="column">
				<f-div align="top-right" gap="medium">
					<f-text variant="para" size="small" state="subtle" :inline="true">{{
						controlStatement.statementId
					}}</f-text>
					<f-div width="hug-content">
						<a :href="getEvidenceLink(controlStatement.evidenceId ?? '')" target="_blank">
							<f-icon
								:source="controlStatement.evidenceStatusIcons.icon"
								size="small"
								:state="controlStatement.evidenceStatusIcons.state"
								:tooltip="controlStatement.evidenceStatusIcons.tooltip"
							></f-icon>
						</a>
					</f-div>
				</f-div>
			</f-div>
			<!--End : slab-right -->
		</f-div>
		<f-divider
			v-if="index + 1 < controlSetStatements.length"
			state="subtle"
			variant="dashed"
			size="large"
		></f-divider>
	</f-div>
	<!--End : multi-line slab -->
</template>

<script lang="ts">
import { FIconState } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { BestPracticeReportStatements } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { ControlStatement } from "@/protocol/correlation";

export default defineComponent({
	name: "BestPracticeStatements",

	props: {
		// eslint-disable-next-line vue/no-unused-properties
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		statements: {
			type: Array as PropType<ControlStatement[]>,
			required: true
		}
	},

	computed: {
		...mapStores(componentOnboardStore, authStore),

		controlSetStatements(): BestPracticeReportStatements[] {
			const allEvidences = this.componentOnboardStore.evidences[this.component.id ?? ""] ?? {};

			return this.statements.map(controlStatementVal => {
				const evidences =
					allEvidences[controlStatementVal.documentId!]?.[controlStatementVal.id!] ?? [];

				const [latestEvidence] = evidences;
				const latestEvidenceStatus = latestEvidence?.status;

				const isLatestEvidenceApproved = latestEvidenceStatus === "Approved";
				const isLatestEvidenceRejected = latestEvidenceStatus === "Rejected";
				const evidenceStatusIcons = () => {
					if (!latestEvidence) {
						return {
							icon: "i-evidence-rejected",
							state: "subtle" as FIconState,
							tooltip: "No evidence uploaded"
						};
					} else if (isLatestEvidenceApproved) {
						return {
							icon: "i-evidence-approved",
							state: "success" as FIconState,
							tooltip: "Evidence approved"
						};
					} else if (isLatestEvidenceRejected) {
						return {
							icon: "i-evidence-rejected",
							state: "danger" as FIconState,
							tooltip: "Evidence rejected"
						};
					}

					return {
						icon: "i-evidence-missing",
						state: "subtle" as FIconState,
						tooltip: "Evidence approval pending"
					};
				};

				const statementStatusIcon = () => {
					if (latestEvidence && isLatestEvidenceApproved) {
						return {
							icon: "i-shield-success",
							state: "success" as FIconState,
							tooltip: "Compliant"
						};
					}
					return {
						icon: "i-alert",
						state: "danger" as FIconState,
						tooltip: "Non Compliant"
					};
				};
				return {
					statementStatusIcon: statementStatusIcon(),
					evidenceStatusIcons: evidenceStatusIcons(),
					statementId: controlStatementVal.id ?? "",
					statement: controlStatementVal.statement ?? "",
					evidenceId: evidences[0]?.id ?? ""
				};
			});
		}
	},

	methods: {
		getEvidenceLink(evidenceId: string) {
			const { location } = window;
			return `${location.origin}/ui/evidence-display/${evidenceId}`;
		}
	}
});
</script>
