<template>
	<f-div direction="column" gap="medium" padding="large" height="fill-container" overflow="scroll">
		<f-div
			v-for="detail in classificationDetails"
			:key="detail.title"
			direction="column"
			padding="none none medium none"
			height="hug-content"
		>
			<f-div padding="none">
				<f-text variant="para" size="medium" weight="bold" state="default" align="left">
					{{ detail.title }}
				</f-text>
			</f-div>

			<f-div direction="column" height="hug-content" padding="medium none none none">
				<ClassificationDetailRow
					v-for="classification in detail.list"
					:key="classification.name"
					v-model="classificationTaxonFormValues[classification.classificationLevelKey]"
					:show-selection-field="showSelectionField"
					:show-edit-btn="showEditBtn"
					:show-flag-btn="showFlagBtn"
					:show-flag-space="showFlagSpace"
					:classification="classification"
					:flag-value="classificationTaxonFlagValues[classification.classificationLevelKey]"
					@flag-btn-click="onFlagSelection"
					@update:model-value="handleSelectionChange(classification)"
					@field-state-change="onFieldStateChange"
				/>

				<f-div
					v-if="detail.title === 'Requested classification' && !isComponentPendingApproval"
					padding="medium"
				>
					<f-div gap="medium" align="middle-left" width="200px"> </f-div>
					<f-button
						v-if="showOptionalTbmBtn"
						label="OPTIONAL CLASSIFICATION"
						variant="round"
						category="outline"
						state="primary"
						size="small"
						icon-left="i-plus"
						@click="() => handleTBMFieldsVisibility(true)"
					></f-button>
					<f-button
						v-else
						label="OPTIONAL CLASSIFICATION"
						variant="round"
						category="outline"
						state="danger"
						size="small"
						icon-left="i-minus"
						@click="() => handleTBMFieldsVisibility(false)"
					></f-button>
				</f-div>
			</f-div>
		</f-div>
	</f-div>

	<ClassificationActionModal
		v-if="actionType"
		:is-open="!!actionType"
		:action-type="actionType"
		:component="component"
		:details="classificationActionDetails"
		@close-modal="$emit('on-close-modal')"
	/>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import {
	ClassificationActionDetails,
	ClassificationActionType,
	ClassificationDetailRowType,
	ClassificationTaxonValues,
	ClassificationLevelNames,
	getComponentFlags
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { ComponentType, Component } from "@/protocol/cockpit";
import {
	COMPONENT_DESCRIPTION_LENGTH,
	COMPONENT_JUSTIFICATION_LENGTH,
	COMPONENT_STATUS
} from "@/shared/constants";

import ClassificationActionModal from "./ClassificationActionModal.vue";
import ClassificationDetailRow from "./ClassificationDetailRow.vue";

export default defineComponent({
	name: "ClassificationDetails",

	components: {
		ClassificationDetailRow,
		ClassificationActionModal
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		actionType: {
			type: String as PropType<ClassificationActionType | null>,
			required: false
		}
	},

	emits: ["on-close-modal", "on-form-value-change", "on-form-state-change"],

	data: () => ({
		classificationTaxonFormValues: {
			classification1: "",
			classification2: "",
			classification3: "",
			classification4: "",
			classification5: "",
			classification6: "",
			classification7: "",
			classification8: "",
			justification: "",
			description: ""
		} as ClassificationTaxonValues<string>,

		classificationFormState: null as ClassificationTaxonValues<{
			isValid: boolean;
			errors: string[];
		}> | null,

		classificationTaxonFlagValues: {
			classification1: false,
			classification2: false,
			classification3: false,
			classification4: false,
			classification5: false,
			classification6: false,
			classification7: false,
			classification8: false,
			justification: false,
			description: false
		} as ClassificationTaxonValues<boolean>,

		showOptionalTBMFields: false,

		showSelectionField: false,
		classificationActionDetails: [] as ClassificationActionDetails[]
	}),

	computed: {
		...mapStores(componentCatalogStore, authStore),

		showFlagSpace() {
			return this.showFlagBtn
				? true
				: Object.values(getComponentFlags(this.component)).some(Boolean);
		},

		hasCatalogWriteAccess() {
			return Boolean(
				this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled
			);
		},

		hasApproverAccess() {
			return Boolean(this.authStore.userPermissions[USER_PERMISSIONS.workflowApprove]?.enabled);
		},

		showFlagBtn() {
			return this.isComponentPendingApproval && this.hasApproverAccess;
		},

		isComponentPendingApproval() {
			return this.component.status === COMPONENT_STATUS.classificationApprovalPending;
		},

		isComponentRejected() {
			return this.component.status === COMPONENT_STATUS.classificationApprovalRejected;
		},

		showEditBtn() {
			return this.isComponentRejected && this.hasCatalogWriteAccess;
		},

		showOptionalTbmBtn() {
			return this.optionalTBMFields.some(field => !field.showField);
		},

		classificationKeyValuePairs() {
			return [
				{
					classificationLevelKey: ClassificationLevelNames.classification1,
					name: "Domain",
					icon: "i-domain",
					selectionOptions: {
						options: this.domainOptions,
						disabled: false,
						validationRules: [
							{
								validator: (value: string) => value.length > 0,
								message: "Domain is required"
							}
						]
					},

					isFlagged: this.component.flagLevel1 ?? false,
					showField: true
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification2,
					name: "Division",
					icon: "i-division",
					selectionOptions: {
						options: this.divisionOptions,
						disabled: false,
						validationRules: [
							{
								validator: (value: string) => value.length > 0,
								message: "Division is required"
							}
						]
					},

					isFlagged: this.component.flagLevel2 ?? false,
					showField: true
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification6,
					name: "Class",
					icon: "i-class",
					selectionOptions: {
						options: this.classOptions,
						disabled: !this.classificationTaxonFormValues.classification2,
						validationRules: [
							{
								validator: (value: string) => value.length > 0,
								message: "Class is required"
							}
						]
					},

					isFlagged: this.component.flagLevel6 ?? false,
					showField: true
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification7,
					name: "Family",
					icon: "i-family",
					selectionOptions: {
						options: this.familyOptions,
						disabled: !this.classificationTaxonFormValues.classification6,
						validationRules: [
							{
								validator: (value: string) => value.length > 0,
								message: "Family is required"
							}
						]
					},

					isFlagged: this.component.flagLevel7 ?? false,
					showField: true
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification8,
					name: "Canonical Name",
					icon: "i-canonical",
					selectionOptions: {
						options: this.canonicalOptions,
						disabled: !this.classificationTaxonFormValues.classification7,
						validationRules: [
							{
								validator: (value: string) => value.length > 0,
								message: "Canonical Name is required"
							}
						]
					},

					isFlagged: this.component.flagLevel8 ?? false,
					showField: true
				},

				...(this.optionalTBMFields.length > 0
					? this.optionalTBMFields.filter(field => field.showField)
					: [])
			];
		},

		classificationDetails(): { title: string; list: ClassificationDetailRowType[] }[] {
			return [
				{
					title: "Requested classification",
					list: this.classificationKeyValuePairs
				},
				{
					title: "About",
					list: [
						{
							name: "Justification",
							icon: "i-justification",
							classificationLevelKey: ClassificationLevelNames.justification,
							isFlagged: false,
							showField: true,
							textareaOptions: {
								placeholder: "Enter justification",
								validationRules: [
									{
										validator: (value: string) => value.length < COMPONENT_JUSTIFICATION_LENGTH,
										message: `Justification should be less than ${COMPONENT_JUSTIFICATION_LENGTH} characters`
									},
									...(this.component.type === ComponentType.ASSET
										? [
												{
													validator: (value: string) => value.length > 0,
													message: "Justification is required"
												}
											]
										: [])
								]
							}
						},
						{
							name: "Description",
							icon: "i-description",
							classificationLevelKey: ClassificationLevelNames.description,
							showField: true,
							isFlagged: false,
							textareaOptions: {
								placeholder: "Enter description",
								validationRules: [
									{
										validator: (value: string) => value.length < COMPONENT_DESCRIPTION_LENGTH,
										message: `Description should be less than ${COMPONENT_DESCRIPTION_LENGTH} characters`
									}
								]
							}
						}
					]
				}
			];
		},

		optionalTBMFields(): ClassificationDetailRowType[] {
			return [
				{
					classificationLevelKey: ClassificationLevelNames.classification3,
					name: "Inventory category",
					icon: "i-category",
					selectionOptions: {
						options: this.inventoryCategoryOptions,
						disabled: false
					},

					isFlagged: this.component.flagLevel3 ?? false,
					showField: this.showOptionalTBMFields
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification4,
					name: "Inventory name",
					icon: "i-name",
					selectionOptions: {
						options: this.inventoryNameOptions,
						disabled: false
					},

					isFlagged: this.component.flagLevel4 ?? false,
					showField: this.showOptionalTBMFields
				},
				{
					classificationLevelKey: ClassificationLevelNames.classification5,
					name: "Inventory type",
					icon: "i-type",
					selectionOptions: {
						options: this.inventoryTypeOptions,
						disabled: false
					},

					isFlagged: this.component.flagLevel5 ?? false,
					showField: this.showOptionalTBMFields
				}
			];
		},

		firstLevelTaxonomy() {
			const firstLevel = [];
			if (this.component.type === ComponentType.SERVICE) {
				firstLevel.push("service");
			} else if (this.component.type === ComponentType.SOFTWARE) {
				firstLevel.push("software");
			} else if (this.component.type === ComponentType.ASSET) {
				firstLevel.push("document", "configuration");
			}

			return firstLevel;
		},

		domainOptions() {
			const l1Taxons = this.componentCatalogStore.domainTaxons.map(taxon => taxon.taxonomy1);
			return Array.from(new Set(l1Taxons)).filter(l1Taxon =>
				// Filter out the first level taxons that are not in the firstLevelTaxonomy
				this.firstLevelTaxonomy.includes(l1Taxon)
			);
		},

		divisionOptions() {
			const l2Taxons = this.componentCatalogStore.domainTaxons.map(taxon => taxon.taxonomy2);
			return Array.from(new Set(l2Taxons));
		},

		classOptions() {
			const l6Taxons = this.componentCatalogStore.classTaxons.map(taxon => taxon.taxonomy6);
			return Array.from(new Set(l6Taxons));
		},

		familyOptions() {
			const l7Taxons = this.componentCatalogStore.classTaxons
				.filter(taxon => taxon.taxonomy6 === this.classificationTaxonFormValues.classification6)
				.map(taxon => taxon.taxonomy7);
			return Array.from(new Set(l7Taxons));
		},

		canonicalOptions() {
			const l8Taxons = this.componentCatalogStore.classTaxons
				.filter(taxon => taxon.taxonomy7 === this.classificationTaxonFormValues.classification7)
				.map(taxon => taxon.taxonomy8);
			return Array.from(new Set(l8Taxons));
		},

		inventoryCategoryOptions() {
			const l3Taxons = this.componentCatalogStore.tbmTaxons.map(taxon => taxon.taxonomy3);
			return Array.from(new Set(l3Taxons));
		},

		inventoryNameOptions() {
			const l4Taxons = this.componentCatalogStore.tbmTaxons
				.filter(taxon => taxon.taxonomy3 === this.classificationTaxonFormValues.classification3)
				.map(taxon => taxon.taxonomy4);
			return Array.from(new Set(l4Taxons));
		},

		inventoryTypeOptions() {
			const l5Taxons = this.componentCatalogStore.tbmTaxons
				.filter(taxon => taxon.taxonomy4 === this.classificationTaxonFormValues.classification4)
				.map(taxon => taxon.taxonomy5);
			return Array.from(new Set(l5Taxons));
		}
	},

	watch: {
		actionType: {
			immediate: true,

			handler(newVal: ClassificationActionType | null) {
				if (newVal) {
					this.classificationActionDetails = Object.keys(this.classificationTaxonFlagValues)
						.filter(
							key =>
								this.classificationTaxonFlagValues[
									key as keyof typeof this.classificationTaxonFlagValues
								] === true
						)
						.map(key => {
							const classificationLevelKey = key as keyof typeof this.classificationTaxonFlagValues;
							const keyValue = this.classificationTaxonFormValues[classificationLevelKey];
							const keyName =
								this.classificationDetails
									.map(detail => detail.list)
									.flat()
									.find(
										classification =>
											classification.classificationLevelKey === classificationLevelKey
									)?.name ?? "";

							return {
								key,
								title: keyName,
								value: keyValue
							};
						});
				}
			}
		},

		classificationTaxonFormValues: {
			deep: true,

			handler(newVal: ClassificationTaxonValues<string>) {
				this.$emit("on-form-value-change", newVal);
			}
		}
	},

	mounted() {
		this.componentCatalogStore.fetchTaxonomies();

		this.classificationTaxonFormValues.classification1 = this.component.classification1 ?? "";
		this.classificationTaxonFormValues.classification2 = this.component.classification2 ?? "";
		this.classificationTaxonFormValues.classification6 = this.component.classification6 ?? "";
		this.classificationTaxonFormValues.classification7 = this.component.classification7 ?? "";
		this.classificationTaxonFormValues.classification8 = this.component.classification8 ?? "";
		this.classificationTaxonFormValues.classification3 = this.component.classification3 ?? "";
		this.classificationTaxonFormValues.classification4 = this.component.classification4 ?? "";
		this.classificationTaxonFormValues.classification5 = this.component.classification5 ?? "";
		this.classificationTaxonFormValues.justification = String(
			this.component.metadata?.justification ?? ""
		);
		this.classificationTaxonFormValues.description = String(
			this.component.metadata?.description ?? ""
		);
		// show optional TBM fields if they are not empty
		if (
			[
				this.component.classification3,
				this.component.classification4,
				this.component.classification5,
				this.component.flagLevel3,
				this.component.flagLevel4,
				this.component.flagLevel5
			].some(Boolean)
		) {
			this.handleTBMFieldsVisibility(true);
		}
	},

	methods: {
		handleSelectionChange(classification: ClassificationDetailRowType) {
			const { classificationLevelKey } = classification;
			const regex = /.$/;
			// eslint-disable-next-line prefer-destructuring
			const match = classificationLevelKey.match(regex);
			const classificationNumber = match ? Number(match[0]) : -1;

			// Check for L1 and L2 changes
			if (classificationNumber === 1) {
				this.classificationTaxonFormValues.classification2 = "";
				// OR check for L3, L4 and L5 changes
			} else if (classificationNumber >= 3 && classificationNumber <= 5) {
				for (let i = classificationNumber + 1; i <= 5; i++) {
					const key = `classification${i}` as keyof ClassificationTaxonValues<string>;
					this.classificationTaxonFormValues[key] = "";
				}
				// OR check for L6, L7 and L8 changes
			} else if (classificationNumber === 6 || classificationNumber === 7) {
				for (let i = classificationNumber + 1; i <= 8; i++) {
					const key = `classification${i}` as keyof ClassificationTaxonValues<string>;
					this.classificationTaxonFormValues[key] = "";
				}
			}

			if (this.showEditBtn) {
				this.showSelectionField = true;
			}
		},

		handleTBMFieldsVisibility(value: boolean) {
			this.showOptionalTBMFields = value;

			// Reset TBM fields
			if (!value) {
				this.classificationTaxonFormValues.classification3 = "";
				this.classificationTaxonFormValues.classification4 = "";
				this.classificationTaxonFormValues.classification5 = "";
			}

			if (this.showEditBtn) {
				this.showSelectionField = true;
			}
		},

		onFieldStateChange(event: ClassificationTaxonValues<{ errors: string[]; isValid: boolean }>) {
			this.classificationFormState = {
				...this.classificationFormState,
				...event
			};

			this.$emit("on-form-state-change", this.classificationFormState);
		},

		onFlagSelection({
			classificationLevelKey,
			flagValue
		}: {
			classificationLevelKey: keyof ClassificationTaxonValues<boolean>;
			flagValue: boolean;
		}) {
			this.classificationTaxonFlagValues[classificationLevelKey] = flagValue;
		}
	}
});
</script>
