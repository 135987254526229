import {
	FrontendApiDisableMySessionRequest,
	FrontendApiUpdateSettingsFlowRequest
} from "@ory/client";
import { defineStore } from "pinia";

import { captureError } from "@/utils";

import { handleAPIError, sdk } from "../services/ory-sdk-service";

export const DEFAULT_ORG_IDENTIFIER = "default";

export const settingsStore = defineStore("settingsStore", {
	actions: {
		async CREATE_SETTINGS_FLOW() {
			try {
				const resp = await sdk.createBrowserSettingsFlow();
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async SUBMIT_SETTINGS_FLOW(body: FrontendApiUpdateSettingsFlowRequest) {
			try {
				const resp = await sdk.updateSettingsFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				const err = await handleAPIError(error, { isSubmitFlow: true });
				throw err;
			}
		},

		async LIST_MY_SESSIONS() {
			try {
				const resp = await sdk.listMySessions();
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async REVOKE_MY_SESSION(body: FrontendApiDisableMySessionRequest) {
			try {
				const resp = await sdk.disableMySession(body);
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		}
	}
});
