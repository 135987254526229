<template>
	<f-div direction="column" overflow="hidden" width="100%">
		<f-div
			direction="row"
			gap="small"
			padding="medium"
			height="hug-content"
			border="small solid subtle bottom"
		>
			<f-div gap="small" align="middle-left" width="hug-content">
				<f-text variant="heading" size="small" weight="medium" data-qa="dashboard-title-catalog">
					Component catalog
				</f-text>
				<f-divider size="medium" direction="vertical"></f-divider>
			</f-div>

			<f-div width="fill-container" gap="small">
				<f-div :width="searchFieldWidth" style="min-width: 200px">
					<f-search
						placeholder="Search"
						size="small"
						category="fill"
						:value="searchText"
						variant="round"
						@input="handleSearch"
					></f-search>
				</f-div>
				<f-div width="fill-container" max-width="600px" gap="small" overflow="hidden">
					<ComponentCatalogFilter
						:filters="filters"
						:components="componentsFilterByActiveTab"
						@update-filters="updateFilters"
					/>
				</f-div>
			</f-div>

			<f-button
				v-if="hasComponentCatalogWriteAccess"
				id="popover-dropdown"
				data-qa-onboard-types-list
				icon-left="i-plus"
				label="onboard new"
				size="small"
				category="outline"
				variant="round"
				@click="openListPopover = !openListPopover"
			/>
		</f-div>

		<f-tab>
			<f-tab-node
				data-qa-catalog-published-tab
				:active="activeTab === 'published'"
				@click="activeTab = 'published'"
				>Published</f-tab-node
			>
			<f-tab-node
				data-qa-catalog-drafts-tab
				:active="activeTab === 'drafts'"
				@click="activeTab = 'drafts'"
				>Drafts</f-tab-node
			>
		</f-tab>
		<EmptyState
			v-if="noComponentsFound"
			id="popover-dropdown"
			data-qa-catalog-no-component-found
			:icon="{
				name: 'i-box',
				type: 'filled',
				state: 'default',
				size: 'x-large',
				color: 'gray-200'
			}"
			:subtitle="`No components ${activeTab === 'published' ? 'published' : 'created'}`"
			:action="hasComponentCatalogWriteAccess ? 'onboard new' : undefined"
			shape="none"
			@click="hasComponentCatalogWriteAccess ? (openListPopover = !openListPopover) : undefined"
		/>

		<f-div direction="row" padding="none">
			<f-div style="min-width: 280px" max-width="300px">
				<ComponentCatalogTreeFilter
					:components="uniqueComponentsWithLatestVersion"
					:selected-type="filters.category?.id"
					@update-filter="onTreeUpdate"
				/>
			</f-div>
			<f-div height="100%" width="hug-content">
				<f-divider size="medium"></f-divider>
			</f-div>
			<f-div direction="column" overflow="scroll">
				<f-div
					padding="medium"
					width="100%"
					height="hug-content"
					sticky="top"
					state="default"
					border="small solid secondary bottom"
				>
					<f-text
						v-for="(crumb, idx) in treePathBreadcrumb"
						:key="crumb.text"
						variant="heading"
						size="medium"
						:weight="crumb.weight"
						inline
					>
						{{ crumb.text }}<span v-if="idx !== treePathBreadcrumb.length - 1">&nbsp;</span>
					</f-text>
				</f-div>
				<EmptyState
					v-if="noFilterResults"
					:icon="noFilterResults.icon"
					:message="noFilterResults.message"
					:subtitle="noFilterResults.subtitle"
					:shape="noFilterResults.shape"
					:data-qa="noFilterResults.dataQa"
					:action="noFilterResults.action"
					@click.stop="resetFilters()"
				/>
				<ComponentCardGrid v-else :components="filteredComponents" />
			</f-div>
		</f-div>
	</f-div>

	<OnboardNewModal
		v-if="openOnboardPopover"
		:open-popover="openOnboardPopover"
		:component-type="onboardComponentType"
		@close="openOnboardPopover = false"
		@component-created="handleComponentCreated"
	/>

	<OnboardTypesList
		v-if="openListPopover"
		:open-popover="openListPopover"
		@select-onboard-component-type="selectOnboardComponentType"
		@close="openListPopover = false"
	/>
</template>
<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapState, mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import ComponentCardGrid from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentCardGrid.vue";
import OnboardNewModal from "@/modules/release-cockpit-v2/components/onboard-new-component/OnboardNewModal.vue";
import OnboardTypesList from "@/modules/release-cockpit-v2/components/onboard-new-component/OnboardTypesList.vue";
import {
	QueryFilters,
	getTaxonomiesList
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component, ComponentType } from "@/protocol/cockpit";
import { COMPONENT_STATUS, ComponentStatus, MIN_DESKTOP_WIDTH } from "@/shared/constants";
import { compareComponentVersion } from "@/utils";

import {
	CatalogSelectedFilterType,
	DEFAULT_CATEGORY,
	DEFAULT_ENVIRONMENT,
	DEFAULT_STATUS
} from "../catalog-service-types";

import ComponentCatalogFilter from "./ComponentCatalogFilter.vue";
import ComponentCatalogTreeFilter from "./ComponentCatalogTreeFilter.vue";

const publishedTabComponentStatus: ComponentStatus[] = [
	COMPONENT_STATUS.published,
	COMPONENT_STATUS.deprecationPending,
	COMPONENT_STATUS.deprecated,
	COMPONENT_STATUS.nonCompliant
];

const publishedStatusPriority: Partial<Record<ComponentStatus, number>> = {
	[COMPONENT_STATUS.published]: 1,
	[COMPONENT_STATUS.deprecationPending]: 2,
	[COMPONENT_STATUS.deprecated]: 3
	// Add other statuses here if needed, with higher numbers indicating lower priority
};

export default defineComponent({
	name: "ComponentCatalogListPage",

	components: {
		ComponentCatalogTreeFilter,
		ComponentCatalogFilter,
		ComponentCardGrid,
		OnboardTypesList,
		OnboardNewModal,
		EmptyState
	},

	data: () => {
		return {
			openListPopover: false,
			openOnboardPopover: false,
			activeTab: "published" as "published" | "drafts",
			searchText: "",
			onboardComponentType: ComponentType.SOFTWARE,
			filters: {
				category: DEFAULT_CATEGORY,
				status: DEFAULT_STATUS,
				type: DEFAULT_ENVIRONMENT
			} as CatalogSelectedFilterType,

			treeFilters: [] as { name: string; classificationLevel: string; componentId: string }[]
		};
	},

	computed: {
		treePathBreadcrumb() {
			if (this.treeFilters.length === 0) {
				return [
					{
						text: "All components",
						// eslint-disable-next-line @typescript-eslint/prefer-as-const
						weight: "medium" as "medium"
					}
				];
			}
			return this.treeFilters.map((filter, idx) => {
				const weight: "regular" | "medium" =
					this.treeFilters.length - 1 === idx ? "medium" : "regular";
				return {
					text: `${filter.name} ${this.treeFilters.length - 1 === idx ? "" : "/"}`,
					weight
				};
			});
		},

		isSearchActive() {
			return this.searchText.length > 0;
		},

		isFilterActive() {
			return (
				this.filters.category?.id !== DEFAULT_CATEGORY.id ||
				this.filters.status?.id !== DEFAULT_STATUS.id ||
				this.filters.type?.id !== DEFAULT_ENVIRONMENT.id
			);
		},

		noFilterResults() {
			const noComponentsFound =
				this.filteredComponents.length === 0 && this.componentsFilterByActiveTab.length > 0;
			if (!noComponentsFound) {
				return null;
			}

			if (this.isSearchActive) {
				return {
					icon: "i-search",
					message: "No results found",
					subtitle: "No results found for the search term that you entered",
					// eslint-disable-next-line @typescript-eslint/prefer-as-const
					shape: "squircle" as "squircle",
					dataQa: "no-components-exists",
					action: "reset all"
				};
			}

			if (this.isFilterActive) {
				return {
					icon: "i-filter",
					message: "No results found",
					subtitle: "No results found for the filters that you applied",
					// eslint-disable-next-line @typescript-eslint/prefer-as-const
					shape: "squircle" as "squircle",
					dataQa: "no-components-exists",
					action: "reset all"
				};
			}

			return null;
		},

		noComponentsFound() {
			return this.componentsFilterByActiveTab.length === 0;
		},

		searchFieldWidth() {
			const screenWidth = window.innerWidth;
			if (screenWidth < MIN_DESKTOP_WIDTH) {
				return "240px";
			}
			return "500px";
		},

		componentsFilterByActiveTab() {
			if (this.activeTab === "published") {
				return this.components
					.filter(component => {
						const status = component.status as ComponentStatus;
						return (
							publishedTabComponentStatus.includes(status) ||
							(component.nonCompliant && publishedTabComponentStatus.includes(status))
						);
					})
					.sort((a: Component, b: Component) => {
						const priorityA = publishedStatusPriority[a.status as ComponentStatus] ?? 999; // Default to a low priority if status is not in the object
						const priorityB = publishedStatusPriority[b.status as ComponentStatus] ?? 999;

						if (priorityA !== priorityB) {
							return priorityA - priorityB;
						}

						// For statuses with the same priority, sort by request created
						return Number(b.createdAt) - Number(a.createdAt);
					});
			}
			return this.components
				.filter(component => {
					const status = component.status as ComponentStatus;
					return !publishedTabComponentStatus.includes(status);
				})
				.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
		},

		uniqueComponentsWithLatestVersion() {
			const latestComponents: Record<string, Component> = {};

			this.componentsFilterByActiveTab.forEach(component => {
				const uniqueName = [...getTaxonomiesList(component), component.name ?? ""].join("");

				const { version } = component;
				if (
					!latestComponents[uniqueName] ||
					compareComponentVersion(version, latestComponents[uniqueName]?.version) > 0
				) {
					latestComponents[uniqueName] = component;
				}
			});

			return Object.values(latestComponents);
		},

		filteredComponents() {
			return this.uniqueComponentsWithLatestVersion
				.filter(component => {
					const filterByTaxonomy = this.treeFilters.map(taxonomy => taxonomy.name);

					if (!filterByTaxonomy.length) {
						return true;
					}

					const componentTaxonomies = getTaxonomiesList(component);
					return filterByTaxonomy.every(taxonomy => componentTaxonomies.includes(taxonomy));
				})
				.filter(component => {
					const filterByType = this.filters.category?.id;

					if (filterByType === DEFAULT_CATEGORY.id) {
						return true;
					}

					return filterByType === component.type;
				})
				.filter(component => {
					const filterByStatus = this.filters.status?.id;

					if (filterByStatus === DEFAULT_STATUS.id) {
						return true;
					}

					return filterByStatus === component.status;
				})
				.filter(component => {
					const filterByEnv = this.filters.type?.id;

					if (filterByEnv && filterByEnv === DEFAULT_ENVIRONMENT.id) {
						return true;
					}

					return component.allowedEnvironment?.includes(filterByEnv ?? "") ?? false;
				})
				.filter(comp => {
					const searchText = this.searchText.toLocaleLowerCase();
					if (searchText === "") {
						return true;
					}

					return (
						comp.name!.toLocaleLowerCase().includes(searchText) ||
						// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
						comp.version?.toLocaleLowerCase().includes(searchText) ||
						getTaxonomiesList(comp).join("").toLocaleLowerCase().includes(searchText)
					);
				});
		},

		hasComponentCatalogWriteAccess() {
			return this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled;
		},

		...mapState(componentCatalogStore, ["components"]),
		...mapStores(authStore)
	},

	watch: {
		"$route.query": {
			handler() {
				const query = this.$route.query as QueryFilters;

				if (query.activeTab) {
					this.activeTab = query.activeTab;
				}

				if (query.searchText) {
					this.searchText = query.searchText;
				}
			},

			immediate: true
		},

		filteredComponents() {
			this.updateQuery();
		}
	},

	methods: {
		selectOnboardComponentType(id: ComponentType) {
			this.onboardComponentType = id;
			this.openListPopover = false;
			this.openOnboardPopover = true;
		},

		handleSearch(e: CustomEvent) {
			const { value } = e.detail;
			this.searchText = value ?? "";
		},

		updateQuery() {
			this.$router.replace({
				name: "component-catalog",
				query: {
					searchText: this.searchText,
					activeTab: this.activeTab
				} satisfies QueryFilters
			});
		},

		handleComponentCreated() {
			this.activeTab = "drafts";
			this.openOnboardPopover = false;
		},

		updateFilters(filters: CatalogSelectedFilterType) {
			this.treeFilters = [];
			this.filters = filters;
		},

		onTreeUpdate(filters: { name: string; classificationLevel: string; componentId: string }[]) {
			this.treeFilters = filters;
		},

		resetFilters() {
			this.filters = {
				category: DEFAULT_CATEGORY,
				status: DEFAULT_STATUS,
				type: DEFAULT_ENVIRONMENT
			};
			this.searchText = "";
			this.treeFilters = [];
		}
	}
});
</script>
