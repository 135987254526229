/* eslint-disable */
import { HealthCheckRequest, HealthCheckResponse } from './healthcheck';

export enum ArtifactType {
    ARTIFACT_UNKNOWN = 'ARTIFACT_UNKNOWN',
    BINARY = 'BINARY',
    GIT_REPO = 'GIT_REPO',
    PIPELINE = 'PIPELINE',
    ASSET_DOCUMENT = 'ASSET_DOCUMENT',
    CODEPIPES_ARTIFACT = 'CODEPIPES_ARTIFACT',
}

export enum ComponentType {
    COMPONENT_UNKNOWN = 'COMPONENT_UNKNOWN',
    SOFTWARE = 'SOFTWARE',
    ASSET = 'ASSET',
    SERVICE = 'SERVICE',
}

export enum WorkflowStatus {
    WORKFLOW_STATUS_UNKNOWN = 'WORKFLOW_STATUS_UNKNOWN',
    WORKFLOW_PENDING = 'WORKFLOW_PENDING',
    WORKFLOW_IN_PROGRESS = 'WORKFLOW_IN_PROGRESS',
    WORKFLOW_COMPLETED = 'WORKFLOW_COMPLETED',
}

export enum StepStatus {
    STEP_STATUS_UNKNOWN = 'STEP_STATUS_UNKNOWN',
    STEP_PENDING = 'STEP_PENDING',
    STEP_IN_PROGRESS = 'STEP_IN_PROGRESS',
    STEP_COMPLETED = 'STEP_COMPLETED',
}

export enum JobStatus {
    JOB_STATUS_UNKNOWN = 'JOB_STATUS_UNKNOWN',
    JOB_PENDING = 'JOB_PENDING',
    JOB_IN_PROGRESS = 'JOB_IN_PROGRESS',
    JOB_COMPLETED = 'JOB_COMPLETED',
    JOB_FAILED = 'JOB_FAILED',
}

export enum Rule {
    RULE_UNSPECIFIED = 'RULE_UNSPECIFIED',
    RULE_FAIL = 'RULE_FAIL',
    RULE_PASS = 'RULE_PASS',
    RULE_WAIVED = 'RULE_WAIVED',
}

export enum Severities {
    SEVERITIES_UNSPECIFIED = 'SEVERITIES_UNSPECIFIED',
    SEVERITIES_CRITICAL = 'SEVERITIES_CRITICAL',
    SEVERITIES_HIGH = 'SEVERITIES_HIGH',
    SEVERITIES_INFORMATIONAL = 'SEVERITIES_INFORMATIONAL',
    SEVERITIES_LOW = 'SEVERITIES_LOW',
    SEVERITIES_MEDIUM = 'SEVERITIES_MEDIUM',
}

export enum StepType {
    STEP_UNKNOWN = 'STEP_UNKNOWN',
    MANUAL = 'MANUAL',
    AUTOMATED = 'AUTOMATED',
}

export enum StepApplicableType {
    STEP_APPLICABLE_TYPE_UNKNOWN = 'STEP_APPLICABLE_TYPE_UNKNOWN',
    STEP_APPLICABLE_TYPE_TAXONOMY = 'STEP_APPLICABLE_TYPE_TAXONOMY',
    STEP_APPLICABLE_TYPE_COMPONENT = 'STEP_APPLICABLE_TYPE_COMPONENT',
}

export interface GetComponentsResponse {
    components?: Component[];
}

export interface Artifact {
    type?: ArtifactType;
    componentId?: string;
    name?: string;
    status?: string;
    createdBy?: string;
    approvedBy?: string;
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    metadata?: Metadata;
}

export interface DeprecationInfo {
    componentId?: string;
    reason?: string;
    justification?: string;
    requestedBy?: string;
    approver?: string;
    approved?: boolean;
    workflowInstanceId?: string;
    workflowId?: string;
    acknowledge?: boolean;
    status?: string;
}

export interface UpdateArtifactRequest {
    // Options: [{"(validate.rules)":{"uint32":{"gt":0}}}]
    id: number;
    componentId?: string;
    name?: string;
    status?: string;
    createdBy?: string;
    approvedBy?: string;
    type?: ArtifactType;
    metadata?: Metadata;
}

export interface Metadata {
    codePipesArtifact?: CodePipesArtifact;
    binary?: Binary;
    // Terraform Git Repo sources like Github, Bitbucket, Gitlab etc
    terraformGitRepo?: TerraformGitRepo;
    // Add rest of Terraform module source types like s3, local, mercurial etc here
    asset?: Asset;
}

export interface Whitelist {
    id?: number;
    url?: string;
    description?: string;
    createdBy?: string;
    updatedBy?: string;
}

export interface CreateWhitelistsRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    url: string;
    description?: string;
    createdBy?: string;
    updatedBy?: string;
}

export interface GetWhitelistByIdRequest {
    id?: number;
}

export interface GetWhitelistsResponse {
    whitelists?: Whitelist[];
}

export interface CreateComponentRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    name: string;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    version: string;
    requestedBy?: string;
    classification1?: string;
    classification2?: string;
    classification3?: string;
    classification4?: string;
    classification5?: string;
    classification6?: string;
    classification7?: string;
    classification8?: string;
    licenseAgreed?: boolean;
    type?: ComponentType;
    env?: string;
    metadata?: Record<string, unknown>;
    workflowInstanceId?: string;
    workflowId?: string;
    requestedByEmail?: string;
}

export interface Component {
    name?: string;
    version?: string;
    classification1?: string;
    classification2?: string;
    classification3?: string;
    classification4?: string;
    classification5?: string;
    classification6?: string;
    classification7?: string;
    classification8?: string;
    status?: string;
    approvedBy?: string;
    requestedBy?: string;
    country?: string;
    createdBy?: string;
    submittedBy?: string;
    artifact?: Artifact;
    comment?: string;
    flagLevel1?: boolean;
    flagLevel2?: boolean;
    flagLevel3?: boolean;
    flagLevel4?: boolean;
    flagLevel5?: boolean;
    flagLevel6?: boolean;
    flagLevel7?: boolean;
    flagLevel8?: boolean;
    licenseAgreed?: boolean;
    controlsAcknowledgement?: boolean;
    type?: ComponentType;
    metadata?: Record<string, unknown>;
    requestedByEmail?: string;
    id?: string;
    env?: string;
    workflowInstanceId?: string;
    workflowId?: string;
    allowedEnvironment?: string[];
    referenceDocs?: string[];
    nonCompliant?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

export interface GetWorkflowByComponentIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface GetWorkflowByComponentIdResponse {
    workflow?: Workflow;
}

export interface GetJobByComponentIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface GetJobByComponentIdResponse {
    jobDetails?: JobDetails;
}

export interface GetLogForComponentControlRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    documentId: string;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    statementId: string;
}

export interface ControlLogs {
    componentId?: string;
    documentId?: string;
    statementId?: string;
    ruleSummary?: PolicyRuleOutput[];
    // field to summarize rule results
    allRulesPassed?: boolean;
}

export interface ListLogsForComponentControlsRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    documentStatementPairs?: DocumentStatementPair[];
}

export interface DocumentStatementPair {
    documentId?: string;
    statementId?: string;
}

// New response message type for the list API
export interface ListLogsForComponentControlsResponse {
    logsMap?: Record<string, ControlLogs>;
}

export interface EvalResponse {
    summary?: PolicySummaryOutput;
    ruleResults?: PolicyRuleOutput[];
    message?: string;
}

export interface PolicyRuleOutput_SourceLocation {
    path?: string;
    line?: number;
    column?: number;
}

export interface PolicyRuleOutput {
    controls?: string[];
    filepath?: string;
    inputType?: string;
    provider?: string;
    resourceId?: string;
    resourceType?: string;
    ruleDescription?: string;
    ruleId?: string;
    ruleMessage?: string;
    ruleName?: string;
    ruleRawResult?: boolean;
    ruleRemediationDoc?: string;
    ruleResult?: string;
    ruleSeverity?: string;
    ruleSummary?: string;
    sourceLocation?: PolicyRuleOutput_SourceLocation[];
}

export interface PolicySummaryOutput {
    valid?: boolean;
    ruleResults?: Rule;
    severities?: Severities;
    filepaths?: string[];
}

export interface SetJobStatusRequest {
    buildId?: string;
    jobName?: string;
    status?: string;
    iacResolvedCommitHash?: string;
    pacResolvedCommitHash?: string;
}

export interface SetJobStatusResponse {
    status?: boolean;
}

export interface Workflow {
    id?: string;
    name?: string;
    status?: WorkflowStatus;
    steps?: Step[];
}

export interface Step {
    id?: string;
    name?: string;
    status?: StepStatus;
    cobitIds?: string[];
    applicableTo?: StepApplicableType;
    applicableValue?: string;
    type?: StepType;
}

export interface UpdateWorkflowByComponentIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    workflow?: Workflow;
}

export interface UpdateWorkflowByComponentIdResponse {
    workflow?: Workflow;
}

export interface JobDetails {
    id?: string;
    logs?: string;
    status?: JobStatus;
}

export interface GetComponentByIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface GetComponentByNameRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentName: string;
}

export interface GetArtifactByComponentIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface UpdateComponentInfoRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    name?: string;
    version?: string;
    classification1?: string;
    classification2?: string;
    classification3?: string;
    classification4?: string;
    classification5?: string;
    classification6?: string;
    classification7?: string;
    classification8?: string;
    status?: string;
    approvedBy?: string;
    requestedBy?: string;
    createdBy?: string;
    submittedBy?: string;
    licenseAgreed?: boolean;
    metadata?: Record<string, unknown>;
    env?: string;
    jobId?: string;
    jobStatus?: string;
    requestedByEmail?: string;
    allowedEnvironments?: string[];
}

export interface SubmitComponentRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    submittedBy?: string;
    controlsAcknowledgement?: boolean;
}

export interface SubmitComponentResponse {
    component?: Component;
    submittedBy?: string;
}

export interface UpdateComponentDecisionRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    decision?: boolean;
    comment?: string;
    flagLevel1?: boolean;
    flagLevel2?: boolean;
    flagLevel3?: boolean;
    flagLevel4?: boolean;
    flagLevel5?: boolean;
    flagLevel6?: boolean;
    flagLevel7?: boolean;
    flagLevel8?: boolean;
    approvedBy?: string;
}

export interface ResponseMessage {
    message?: string;
}

export interface ComponentIdRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface ArtifactIdRequest {
    // Options: [{"(validate.rules)":{"uint32":{"gt":0}}}]
    artifactId: number;
}

export interface GetArtifactsResponse {
    artifacts?: Artifact[];
}

export interface ArtifactResponse {
    artifact?: Artifact;
}

export interface CreateArtifactRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    name: string;
    status?: string;
    createdBy?: string;
    approvedBy?: string;
    type?: ArtifactType;
    metadata?: Metadata;
}

export interface NotariseComponentRequest {
    notarised?: boolean;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
}

export interface PublishComponentRequest {
    published?: boolean;
    componentId?: string;
}

export interface RepoConfig {
    repo?: string;
    dir?: string;
    branch?: string;
    commithash?: string;
}

export interface TerraformGitRepo {
    iac?: RepoConfig;
    pac?: RepoConfig;
}

export interface Binary {
    // Url fromm where binary file should be downloaded
    url?: string;
    checksum?: string;
    // Url where downloaded binary is stored
    bucketURL?: string;
}

export interface Asset {
    codeRepo?: string;
    baselineEt?: string;
    baselineDDD?: string;
    materialMarketing?: string;
}

export interface CodePipesArtifact {
    githubConfigurations?: GitHubConfigurations;
    dockerHubConfiguration?: DockerHubConfiguration;
}

export interface GitHubConfigurations {
    id?: string;
    repo?: string;
    branch?: string;
    name?: string;
}

export interface DockerHubConfiguration {
    id?: string;
    repo?: string;
    reference?: string;
    name?: string;
}

export interface BitbucketDetailsRequest {
    url?: string;
    commitSha?: string;
    pathToModule?: string;
}

export interface Bitbucketdetails {
    branchesList?: string[];
    tagsList?: string[];
}

export interface Bitbucketcontent {
    content?: Uint8Array;
}

export interface CreateDepRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    reason: string;
    requestedBy?: string;
    workflowInstanceId?: string;
    workflowId?: string;
}

export interface DeprecateComponentRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    approved?: boolean;
    justification?: string;
    approver?: string;
    acknowledge?: boolean;
    acknowledgeBy?: string;
}

export interface ApproveRejectWorkflowTaskRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    approved?: boolean;
    comment?: string;
    approvedBy?: string;
    justification?: string;
}

export interface DepAcknowledgementRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    componentId: string;
    acknowledge?: boolean;
    acknowledgeBy?: string;
    approved?: boolean;
    justification?: string;
    approver?: string;
}

export interface WebhookDocumentUpdateRequest {
    // Options: [{"(validate.rules)":{"string":{"min_len":1}}}]
    documentId: string;
    documentName?: string;
    documentType?: string;
    documentVersion?: string;
}

export interface CockpitService {
    // Health check endpoint
    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/health"}}]
    healthCheck(request: HealthCheckRequest): Promise<HealthCheckResponse>;

    // Component endpoints
    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components"}}]
    getComponents(): Promise<GetComponentsResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components_by_name"}}]
    getComponentsByName(request: GetComponentByNameRequest): Promise<GetComponentsResponse>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/create","body":"*"}}]
    createComponent(request: CreateComponentRequest): Promise<Component>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}"}}]
    getComponentById(request: GetComponentByIdRequest): Promise<Component>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/notarise","body":"*"}}]
    notariseComponent(request: NotariseComponentRequest): Promise<ResponseMessage>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/publish","body":"*"}}]
    publishComponent(request: PublishComponentRequest): Promise<ResponseMessage>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}/workflow"}}]
    getWorkflowByComponentId(
        request: GetWorkflowByComponentIdRequest,
    ): Promise<GetWorkflowByComponentIdResponse>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/workflow","body":"*"}}]
    updateWorkflowByComponentId(
        request: UpdateWorkflowByComponentIdRequest,
    ): Promise<UpdateWorkflowByComponentIdResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}/job"}}]
    getJobByComponentId(request: GetJobByComponentIdRequest): Promise<GetJobByComponentIdResponse>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/job/status","body":"*"}}]
    setJobStatus(request: SetJobStatusRequest): Promise<SetJobStatusResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}/job/documents/{documentId}/statements/{statementId}/log"}}]
    getLogForComponentControl(request: GetLogForComponentControlRequest): Promise<ControlLogs>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/{componentId}/job/logs","body":"*"}}]
    listLogsForComponentControls(
        request: ListLogsForComponentControlsRequest,
    ): Promise<ListLogsForComponentControlsResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}/artifact"}}]
    getArtifactByComponentId(request: GetArtifactByComponentIdRequest): Promise<ArtifactResponse>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}","body":"*"}}]
    updateComponentInfo(request: UpdateComponentInfoRequest): Promise<Component>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/submit","body":"*"}}]
    submitComponent(request: SubmitComponentRequest): Promise<SubmitComponentResponse>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/{componentId}/approval","body":"*"}}]
    updateComponentDecision(request: UpdateComponentDecisionRequest): Promise<ResponseMessage>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/{componentId}/update-status","body":"*"}}]
    updateComponentStatus(request: ComponentIdRequest): Promise<ResponseMessage>;

    // Whitelist endpoints
    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/whitelists"}}]
    getWhitelists(): Promise<GetWhitelistsResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/whitelists/{id}"}}]
    getWhitelistById(request: GetWhitelistByIdRequest): Promise<Whitelist>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/whitelists","body":"*"}}]
    createWhitelist(request: CreateWhitelistsRequest): Promise<Whitelist>;

    // Artifact endpoints
    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/artifacts"}}]
    getArtifacts(): Promise<GetArtifactsResponse>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/artifacts/{artifactId}"}}]
    getArtifactById(request: ArtifactIdRequest): Promise<ArtifactResponse>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/artifacts","body":"*"}}]
    createArtifact(request: CreateArtifactRequest): Promise<ArtifactResponse>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/artifacts/{id}","body":"*"}}]
    updateArtifact(request: UpdateArtifactRequest): Promise<ResponseMessage>;

    // Options: [{"(google.api.http)":{"get":"/cockpit-service/v1/cockpit/components/{componentId}/deprecations"}}]
    componentDeprecationById(request: GetComponentByIdRequest): Promise<DeprecationInfo>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/components/{componentId}/deprecation","body":"*"}}]
    createDeprecationRequest(request: CreateDepRequest): Promise<DeprecationInfo>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/deprecation/approval","body":"*"}}]
    deprecateComponent(request: ApproveRejectWorkflowTaskRequest): Promise<DeprecationInfo>;

    // Options: [{"(google.api.http)":{"put":"/cockpit-service/v1/cockpit/components/{componentId}/deprecation/acknowledge","body":"*"}}]
    acknowledgeDeprecation(request: DepAcknowledgementRequest): Promise<DeprecationInfo>;

    // Options: [{"(google.api.http)":{"post":"/cockpit-service/v1/cockpit/webhooks/document-update","body":"*"}}]
    webhookDocumentUpdate(request: WebhookDocumentUpdateRequest): Promise<ResponseMessage>;
}
