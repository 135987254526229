<template>
	<f-div direction="column" width="fill-container" height="hug-content">
		<f-div gap="none" direction="column" height="fill-container" width="fill-container">
			<f-div padding="large" align="top-center" direction="column" gap="small">
				<f-div
					v-for="(item, index) in gitContents"
					:key="index"
					:data-qa-tab="index"
					width="fill-container"
					height="hug-content"
					direction="column"
					gap="small"
				>
					<f-text variant="para" size="small" weight="regular" state="subtle" align="left">
						{{ item.label }}
					</f-text>

					<f-text
						:variant="item.valueVariant"
						size="medium"
						weight="regular"
						:state="item.textState"
						align="left"
					>
						{{ item.value }}
					</f-text>
				</f-div>

				<f-div gap="medium">
					<f-button
						v-if="showEdit && isArtifactEditable"
						icon-left="i-edit"
						size="small"
						variant="curved"
						category="outline"
						state="neutral"
						label="Edit"
						@click="$emit('edit-terraform-module')"
					>
					</f-button>
					<f-button
						v-if="!showServiceTabGitArtifacts"
						variant="curved"
						size="small"
						category="outline"
						state="primary"
						:disabled="false"
						:loading="false"
						icon-left="i-pipe"
						label="VIEW LOGS"
						@click="renderOnLogScreen"
					></f-button>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FTextStateProp } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { Component, TerraformGitRepo } from "@/protocol/cockpit";
import { COMPONENT_STATUS } from "@/shared/constants";

export default defineComponent({
	name: "TerraformViewModuleComponent",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		artifactData: {
			type: Object as PropType<TerraformGitRepo>,
			required: true
		},

		showServiceTabGitArtifacts: {
			type: Boolean,
			default: false
		},

		showEdit: {
			type: Boolean,
			default: () => true
		}
	},

	emits: ["edit-terraform-module"],

	data: () => {
		return {
			COMPONENT_STATUS
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore),

		isArtifactEditable() {
			return (
				this.component.status !== this.COMPONENT_STATUS.notarised &&
				this.component.status !== this.COMPONENT_STATUS.published
			);
		},

		gitContents() {
			const gitDetails = this.showServiceTabGitArtifacts
				? this.artifactData.iac
				: this.artifactData.pac;

			return {
				repo: {
					value: gitDetails?.repo,
					label: "Module Git URL",
					textState: "primary" as FTextStateProp,
					valueVariant: "para" as "heading" | "para" | "code"
				},

				branch: {
					value: gitDetails?.branch,
					label: "Branch",
					textState: "default" as FTextStateProp,
					valueVariant: "code" as "heading" | "para" | "code"
				},

				dir: {
					value: gitDetails?.dir,
					label: "Directory path",
					textState: "default" as FTextStateProp,
					valueVariant: "code" as "heading" | "para" | "code"
				}
			};
		}
	},

	methods: {
		renderOnLogScreen() {
			this.$router.replace({
				name: "component-onboarding-view",
				params: {
					componentId: this.component.id,
					tabName: "pipeline-logs"
				}
			});
		}
	}
});
</script>
