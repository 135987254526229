import { Document, ControlStatement } from "@/protocol/correlation";
import { Evidence } from "@/protocol/evidence";

export interface EvidenceMapping {
	[key: string]: Evidence[];
}

export type DocumentWithStatements = {
	document: Document;
	statements: ControlStatement[];
};

export const DEFAULT_CATEGORY = {
	id: "all-categories",
	title: "All Categories"
};

export const DEFAULT_STATUS = {
	id: "all-status",
	title: "All Status"
};

export const DEFAULT_ENVIRONMENT = {
	id: "all-environments",
	title: "All Environments"
};

export interface FilterType {
	id: string;
	size: "small" | "medium";
	options: Option[];
}

export interface Option {
	title: string;
	icon?: string;
	id: string;
}

export type CatalogSelectedFilterType = { [key: string]: Option };
