<template>
	<f-div
		v-if="!isComponentOfAssetType && isRouteManualOrAutomated"
		align="middle-center"
		height="hug-content"
		direction="row"
		width="fill-container"
	>
		<f-div align="middle-left" gap="medium" width="200px">
			<f-search
				variant="round"
				placeholder="Search statements"
				size="small"
				@input="handleSearch"
			/>
		</f-div>

		<f-div v-if="showAllDropdown" align="middle-left" gap="medium">
			<f-div padding="medium">
				<f-button
					size="small"
					data-qa-evidence-filter
					:icon-left="statusFilter ? selectedEvidenceStatusIcon?.icon.icon : 'i-paper-clip'"
					:icon-right="isFilterPopoverOpen ? 'i-chevron-up' : 'i-chevron-down'"
					:state="statusFilter ? selectedEvidenceStatusIcon?.state : buttonDefaultState"
					:label="statusFilter ? statusFilter : 'Show all'"
					@click="isFilterPopoverOpen = true"
				>
				</f-button>
			</f-div>
		</f-div>
	</f-div>
	<f-popover
		target="[data-qa-evidence-filter]"
		:open="isFilterPopoverOpen"
		placement="bottom-start"
		size="small"
		:overlay="false"
		shadow
		:close-on-escape="true"
		@esc="isFilterPopoverOpen = false"
		@overlay-click="isFilterPopoverOpen = false"
	>
		<f-div
			v-for="evidenceState in evidenceStatuses"
			:key="evidenceState.text"
			padding="small medium"
			clickable
			gap="medium"
			:data-qa-catalog-status-filter="evidenceState.text"
			:selected="statusFilter === evidenceState.text ? 'notch-right' : 'none'"
			@click="handleEvidenceFilter(evidenceState.text)"
		>
			<f-icon
				:source="evidenceState.icon.icon"
				:state="evidenceState.icon.color"
				size="x-small"
			></f-icon>
			<f-text variant="heading" size="x-small" weight="regular">{{ evidenceState.text }}</f-text>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { FButtonState } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { Component, ComponentType } from "@/protocol/cockpit";
import { EVIDENCE_STATUS_DISPLAY } from "@/shared/constants";

export default defineComponent({
	name: "ComponentOnboardingSubHeader",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		showAllDropdown: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	emits: ["update-search-value", "update-evidence-filter"],

	data: () => {
		return {
			isFilterPopoverOpen: false,
			statusFilter: "",
			buttonDefaultState: "custom, #dad5d3" as FButtonState
		};
	},

	computed: {
		...mapStores(
			componentOnboardStore,
			featureFlagStore,
			componentCatalogStore,
			notificationsStore,
			authStore
		),

		componentType() {
			return this.component.type;
		},

		isRouteManualOrAutomated() {
			return this.$route.params.tabName === "manual" || this.$route.params.tabName === "automated";
		},

		isComponentOfAssetType() {
			return this.componentType === ComponentType.ASSET;
		},

		evidenceStatuses() {
			return EVIDENCE_STATUS_DISPLAY;
		},

		selectedEvidenceStatusIcon() {
			return EVIDENCE_STATUS_DISPLAY[this.statusFilter];
		}
	},

	methods: {
		handleSearch(e: CustomEvent) {
			const { value } = e.detail;
			this.$emit("update-search-value", value ?? "");
		},

		handleEvidenceFilter(statusFilter: string) {
			if (this.statusFilter === statusFilter || statusFilter === "Show all") {
				this.statusFilter = "";
				this.isFilterPopoverOpen = false;
				this.$emit("update-evidence-filter", "");
				return;
			}
			this.statusFilter = statusFilter;
			this.isFilterPopoverOpen = false;
			this.$emit("update-evidence-filter", statusFilter);
		}
	}
});
</script>
