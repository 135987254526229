<script setup lang="ts">
import { Loader } from "@ollion/flow-vue3";
import Worker from "pdfjs-dist/build/pdf.worker.mjs?worker&url";
import { computed, ref, toRefs, watch } from "vue";

const pdfJs = await import("pdfjs-dist");

pdfJs.GlobalWorkerOptions.workerSrc = Worker;

const props = defineProps({
	content: String
});

const pdfCanvas = ref<HTMLCanvasElement>();

const { content } = toRefs(props);

const numPages = ref(0);
const currentPage = ref(0);
const isLoading = ref(false);

//@todo - handle all error states

const pdfContent = computed(() => {
	const data = content?.value ? content.value : null;

	if (!data) {
		return null;
	}
	let url = data;

	// In development, we use a proxy to avoid CORS issues
	if (process.env.NODE_ENV === "development") {
		url = data.replace("https://storage.googleapis.com", "/api");
	}

	return pdfJs.getDocument({
		isEvalSupported: false,
		url
	});
});

watch(
	[pdfContent],
	() => {
		isLoading.value = true;
		pdfContent.value?.promise.then(() => {
			isLoading.value = false;
		});
		currentPage.value = 0;
	},
	{ immediate: true }
);

watch(
	[pdfContent, pdfCanvas, currentPage],
	async () => {
		if (!pdfCanvas.value || !pdfContent.value) {
			return;
		}

		const result = await pdfContent.value.promise;

		numPages.value = result.numPages;

		const page = await result.getPage(currentPage.value + 1);

		// Get original page's height
		const originalPageHeight = page.getViewport({ scale: 0.5 }).height;

		// Set new width based on our canvas size
		const viewport = page.getViewport({ scale: pdfCanvas.value.offsetHeight / originalPageHeight });

		pdfCanvas.value.height = viewport.height;
		pdfCanvas.value.width = viewport.width;

		const canvasContext = pdfCanvas.value.getContext("2d");

		if (!canvasContext) {
			return;
		}

		page.render({ canvasContext, viewport });
	},
	{ immediate: true }
);
</script>

<template>
	<f-div direction="column" data-pdf-renderer height="fill-container">
		<f-div
			v-if="numPages > 1"
			height="hug-content"
			align="middle-right"
			padding="small"
			border="medium solid subtle bottom"
		>
			<f-button
				tooltip="Previous page"
				state="neutral"
				category="transparent"
				size="small"
				icon-left="i-chevron-double-left"
				label="Prev page"
				:disabled="currentPage === 0"
				@click="currentPage--"
			></f-button>
			<f-button
				tooltip="Next page"
				state="neutral"
				category="transparent"
				size="small"
				icon-right="i-chevron-double-right"
				label="Next page"
				:disabled="currentPage >= numPages - 1"
				@click="currentPage++"
			></f-button>
		</f-div>
		<f-div v-if="isLoading" align="middle-center" height="fill-container" width="fill-container">
			<Loader />
		</f-div>
		<f-div align="top-center" overflow="scroll" class="flow-add-scrollbar" height="fill-container">
			<canvas ref="pdfCanvas"></canvas>
		</f-div>
	</f-div>
</template>

<style lang="scss">
[data-pdf-renderer] canvas {
	height: 100%;
}
</style>
