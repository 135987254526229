import { FormBuilderGenericValidationRule } from "@ollion/flow-form-builder";

export const validRelativeDirectoryRule = {
	name: "custom",
	validate(value?: string | unknown[]): boolean {
		if (typeof value !== "string" || Array.isArray(value)) {
			return false;
		}

		return !value.startsWith("/") && !value.startsWith("./") && !value.startsWith("../");
	},
	message: "Avoid absolute prefixes like /, ./, ../"
} satisfies FormBuilderGenericValidationRule;
