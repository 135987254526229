<template>
	<f-div
		v-if="
			selectedComponentDetails.component != null &&
			Object.keys(selectedComponentDetails.component).length > 0
		"
		width="100%"
		padding="small"
		gap="medium"
		height="hug-content"
		direction="column"
	>
		<f-div gap="medium" align="middle-center">
			<ComponentStatusBadge v-if="component" :component="component" />
			<f-text variant="para" size="small" weight="regular" align="left"
				>Created on {{ formattedCreationDate }}</f-text
			>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent } from "vue";

import ComponentStatusBadge from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentStatusBadge.vue";
import { formatDateString } from "@/utils/format-date";

import { complianceReportStore } from "../../compliance-report-store";

export default defineComponent({
	name: "StatusHeader",

	components: {
		ComponentStatusBadge
	},

	computed: {
		...mapState(complianceReportStore, {
			selectedComponent: state => state.selectedComponent,
			selectedComponentDetails: state => state.selectedComponentDetails
		}),

		formattedCreationDate() {
			return formatDateString(String(this.selectedComponentDetails.component?.createdAt));
		},

		component() {
			return this.selectedComponentDetails.component;
		}
	}
});
</script>
