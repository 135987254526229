<template>
	<Container direction="column" padding="0" overflow="visible">
		<Container direction="row" align="space-between" padding="0">
			<Container :gap="14">
				<Container :gap="8" :padding="0" direction="column" class="margin-tp-16">
					<Typography type="h1" color="light">Constellation Map</Typography>
				</Container>
				<Container :gap="8" :padding="0" direction="column">
					<Typography type="p3" color="light">Zoom</Typography>
					<Wrapper height="36px" background="element-light" border-radius="4px">
						<Container :gap="16" :padding="8">
							<Icon
								data-qa-constellation-map-zoom-out
								name="i-minus"
								state="dark"
								type="filled"
								size="small"
								@click="zoomOut()"
							/>
							<Icon name="i-location" type="filled" state="dark" size="20px" />
							<Icon
								data-qa-constellation-map-zoom-in
								name="i-plus"
								state="dark"
								type="filled"
								size="small"
								@click="zoomIn()"
							/>
						</Container>
					</Wrapper>
				</Container>
				<Container :gap="8" :padding="0" direction="column">
					<Typography type="p3" color="light">View</Typography>
					<Wrapper height="36px" width="100%" background="element-light" border-radius="4px">
						<Container :gap="16">
							<Icon
								:data-qa="`constellation-map-full-screen-view${fullscreenIconName}`"
								:name="fullscreenIconName"
								state="dark"
								type="filled"
								size="20px"
								@click="toggleFullScreen"
							/>
						</Container>
					</Wrapper>
				</Container>
			</Container>

			<Container :padding="0" align="right center">
				<Container :gap="8" direction="column" :padding="0" class="margin-rt-14 margin-tp-20">
					<Wrapper background="element-light" border-radius="4px">
						<Button
							size="normal"
							data-qa-constellation-map-export
							:type="getErrorState"
							state="curved"
							:full-width="false"
							:loading="isLoading"
							@click="exportGraph"
						>
							<Typography weight="medium" type="p2" color="dark">Export</Typography>
							<Icon v-if="!isLoading" size="small" state="dark" name="i-download"></Icon>
						</Button>
					</Wrapper>
				</Container>
			</Container>
		</Container>
		<Container direction="row" padding="0" class="margin-10" overflow="scroll" flex-wrap>
			<Container
				v-for="(count, type) in typeCounts"
				:key="type"
				direction="row"
				padding="0"
				class="margin-lt-10"
				:data-qa="`constellation-map-type-counts-${type}`"
			>
				<span class="square" :style="getColor(type)"></span>
				<Typography type="p2" weight="medium">{{ getTitleCase(type) }} — {{ count }}</Typography>
			</Container>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Typography, Wrapper, Icon, Button } from "@ollion/flow-vue3";
import { defineComponent, PropType } from "vue";

import { toTitleCase } from "@/utils";

import typeColors from "./typeColors";

export default defineComponent({
	name: "HeaderPage",

	components: {
		Icon,
		Wrapper,
		Typography,
		Button,
		Container
	},

	props: {
		typeCounts: {
			type: Object as PropType<Record<string, number>>,
			required: true
		},

		exportLoading: {
			type: Boolean,
			required: true
		},

		exportError: {
			type: Boolean,
			required: false
		}
	},

	data() {
		return {
			fullscreen: false,
			isLoading: false
		};
	},

	computed: {
		getErrorState(): "default" | "error" | undefined {
			return this.exportError ? `error` : `default`;
		},

		fullscreenIconName() {
			return this.fullscreen ? "i-shrink" : "i-expand-2";
		}
	},

	watch: {
		exportLoading() {
			this.isLoading = this.exportLoading;
		}
	},

	mounted() {
		document.addEventListener("fullscreenchange", this.onFullScreenChange);
	},

	beforeUnmount() {
		document.removeEventListener("fullscreenchange", this.onFullScreenChange);
	},

	methods: {
		zoomIn() {
			this.$emit("zoomIn");
		},

		zoomOut() {
			this.$emit("zoomOut");
		},

		getColor(type: string) {
			return `background-color:${typeColors[type]}`;
		},

		exportGraph() {
			this.$emit("exportGraph");
		},

		toggleFullScreen() {
			if (!this.fullscreen) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(document.documentElement as any).requestFullscreen();
			} else {
				document.exitFullscreen();
			}
		},

		onFullScreenChange() {
			this.fullscreen = document.fullscreenElement !== null;
		},

		getTitleCase(key: string) {
			return toTitleCase(key);
		}
	}
});
</script>
<style scoped>
.square {
	width: 10px;
	height: 10px;
	border-radius: 1px;
}
</style>
