<template>
	<f-select
		v-model="latestVersion"
		:options="componentVersions"
		category="fill"
		max-options-width="350px"
		size="small"
		:option-template="versionOptionTemplate"
		@input="onVersionSelect"
	>
		<f-div v-if="showVersionLabel" slot="label" padding="none" gap="none">Version</f-div>
	</f-select>
</template>

<script lang="ts">
import {
	FSelectOptionObject,
	html,
	FSelectSingleOption,
	FSelectOptionTemplate
} from "@ollion/flow-core";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { Component } from "@/protocol/cockpit";
import { COMPONENT_DISPLAY, ComponentStatus } from "@/shared/constants";
import { compareComponentVersion } from "@/utils";

import { componentCatalogStore } from "../../component-catalog-store";
import { getTaxonomiesList } from "../../release-cockpit-types";

import { HeaderStateType } from "./ComponentStatusBadge.vue";

export default defineComponent({
	name: "ComponentVersionSelect",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		selectedComponent: {
			type: Object as PropType<Component>,
			required: true
		},

		showVersionLabel: {
			type: Boolean,
			default: false
		}
	},

	emits: ["update:selectedComponent", "on-version-select"],

	computed: {
		...mapStores(componentCatalogStore),

		componentTaxonomiesAndName(): string {
			return [...getTaxonomiesList(this.component), this.component.name ?? ""].join("");
		},

		componentVersions(): FSelectOptionObject[] {
			return this.componentCatalogStore.components
				.filter(component => {
					const componentTaxonomiesAndName = [
						...getTaxonomiesList(component),
						component.name ?? ""
					].join("");

					return this.componentTaxonomiesAndName === componentTaxonomiesAndName;
				})
				.map(component => ({ title: `V ${component.version}`, data: component }))
				.sort((a, b) => compareComponentVersion(b.data.version, a.data.version));
		},

		latestVersion() {
			return {
				title: `V ${this.selectedComponent.version}`,
				data: this.selectedComponent
			};
		}
	},

	mounted() {
		if (this.componentCatalogStore.components.length < 1) {
			this.componentCatalogStore.getComponents();
		}
	},

	methods: {
		onVersionSelect(event: CustomEvent) {
			if (event.detail.value.data && event.detail.value.data.id !== this.selectedComponent.id) {
				this.$emit("update:selectedComponent", event.detail.value.data);
				this.$emit("on-version-select", event.detail.value.data);
			}
		},

		componentBadge(status: ComponentStatus): HeaderStateType | null {
			const componentBadge = COMPONENT_DISPLAY[status];

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (!componentBadge) {
				return null;
			}

			return {
				headerState: componentBadge.state,
				headerIcon: componentBadge.icon,
				statusText: componentBadge.text
			};
		},

		versionOptionTemplate(
			option: FSelectSingleOption,
			isSelected?: boolean
		): ReturnType<FSelectOptionTemplate> {
			const customOption = option as FSelectOptionObject;

			if (isSelected) {
				return html`<f-text size="small" weight="medium" ellipsis>${customOption.title}</f-text> `;
			}
			const badge = this.componentBadge(customOption.data?.status as ComponentStatus);
			const isVersionLongString = customOption.title.length > 8;
			const templateDirection = isVersionLongString ? "column" : "row";
			return html`
				<f-div .direction="${templateDirection}" gap="small">
					<f-div>
						<f-text size="small" weight="medium">${customOption.title}</f-text>
					</f-div>
					${badge
						? html`<f-div
								width="hug-content"
								gap="small"
								align="middle-right"
								state="tertiary"
								overflow="wrap"
								variant="curved"
								padding="none small none none"
								.data-qa-artifact-status="${badge.statusText}"
							>
								<f-div
									width="hug-content"
									align="middle-center"
									.state="${badge.headerState}"
									padding="x-small"
								>
									<f-icon
										.source="${badge.headerIcon.icon}"
										size="x-small"
										state="custom, white"
									></f-icon>
								</f-div>
								<f-text size="small" weight="medium">${badge.statusText}</f-text>
							</f-div>`
						: ""}
				</f-div>
			`;
		}
	}
});
</script>
