<template>
	<f-div v-for="(comp, i) in overviewCards" :key="i" width="hug-content" height="45px">
		<f-div
			:controls-id="'comp-' + comp.id"
			padding="none x-large none none"
			direction="column"
			height="fill-container"
		>
			<f-div padding="none">
				<f-text
					variant="heading"
					size="small"
					state="secondary"
					:data-qa="`card-title-${comp.name}`"
					>{{ comp.name }}</f-text
				>
			</f-div>
			<f-div padding="none" gap="none" direction="column">
				<f-div>
					<f-text
						variant="heading"
						size="large"
						:data-qa="`card-count-${comp.name}`"
						:state="stateOfControls(comp.count, comp.total ? comp.total : 0).state"
					>
						{{ comp.count }}
					</f-text>
				</f-div>
			</f-div>
		</f-div>
		<f-divider
			v-if="i + 1 < overviewCards.length"
			variant="solid"
			size="medium"
			state="default"
		></f-divider>
	</f-div>
</template>
<script lang="ts">
import { cloneDeep } from "lodash-es";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { Application } from "@/protocol/correlation";

import { getControlSCount } from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import { DASHBOARD_INIT_COMPONENT, getPercentOfControlsExecuted } from "../compliance-report-utils";

export default defineComponent({
	name: "TabsCardComponents",

	props: {
		selectedApp: {
			type: Object as PropType<Application>,
			required: true
		}
	},

	data: () => {
		return {
			overviewCards: cloneDeep(DASHBOARD_INIT_COMPONENT)
		};
	},

	computed: {
		...mapStores(complianceReportStore)
	},

	watch: {
		selectedApp(newVal) {
			if (newVal) {
				this.getControlsCount();
			}
		}
	},

	mounted() {
		if (Object.values(this.selectedApp).length > 0) {
			this.getControlsCount();
		}
	},

	methods: {
		stateOfControls(count: number, total: number) {
			return getPercentOfControlsExecuted(count, total);
		},

		async getControlsCount() {
			//Get critical, non-critical and total controls based on organisation
			if (!this.complianceReportStore.currentOrg.id) {
				return;
			}

			const [
				totalAppControls,
				criticalControls,
				nonCriticalControls,
				complaintControls,
				exemptedControls
			] = await Promise.all([
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					applicationId: this.selectedApp.id
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					applicationId: this.selectedApp.id,
					filter: "critical",
					status: "non-compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					applicationId: this.selectedApp.id,
					filter: "non-critical",
					status: "non-compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					applicationId: this.selectedApp.id,
					status: "compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					applicationId: this.selectedApp.id,
					filter: "exempted",
					status: "non-compliant"
				})
			]);
			for (const card of this.overviewCards) {
				card.total = Number(totalAppControls.controlsCount);
				if (card.name === "CRITICAL VIOLATION") {
					card.count = Number(criticalControls.controlsCount);
				}
				if (card.name === "NON-CRITICAL VIOLATION") {
					card.count = Number(nonCriticalControls.controlsCount);
				}
				if (card.name === "COMPLIANT") {
					card.count = Number(complaintControls.controlsCount);
				}
				if (card.name === "EXEMPTED") {
					card.count = Number(exemptedControls.controlsCount);
				}
			}
		}
	}
});
</script>
