import { AxiosResponse } from "axios";

import { LinkDocumentRequest } from "@/protocol/correlation";
import {
	DocumentService,
	FineTuningRequest,
	FineTuningResponse,
	GetDocumentRequest,
	GetDocumentResponse,
	GetModelRequest,
	GetModelResponse,
	IngestResponse,
	ListDocumentRequest,
	ListDocumentResponse,
	ListStatementByDocumentIdRequest,
	ListStatementByDocumentIdResponse
} from "@/protocol/document";
import { addQueryParams } from "@/shared/append-query-parameter-in-url";
import { CORRELATION_SERVICE_PATH, DOCUMENT_SERVICE_PATH } from "@/shared/service-paths";

import HTTP from "../services/api-service";

export type DocumentIngestResponse = {
	successfulStatements: number;
	erroredStatements: number;
	successfulDocuments: number;
	erroredDocuments: number;
	documentIds: string[];
	documentTypes: string[];
};

export async function linkTaxonomyToDocument(payload: LinkDocumentRequest) {
	const response = await HTTP.post<{ message: string }>(
		`${CORRELATION_SERVICE_PATH}/document/taxonomy`,
		payload
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
}

// Options: [{"(google.api.http)":{"get":"/document-service/v1/document"}}]
export const listDocument: DocumentService["listDocument"] = async function (
	request: ListDocumentRequest
) {
	const response: AxiosResponse<ListDocumentResponse> = await HTTP.get(
		addQueryParams(`${DOCUMENT_SERVICE_PATH}/document`, request)
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// Options: [{"(google.api.http)":{"get":"/document-service/v1/document/{documentId}"}}]
export const getDocument: DocumentService["getDocument"] = async function (
	request: GetDocumentRequest
) {
	const response: AxiosResponse<GetDocumentResponse> = await HTTP.get(
		`${DOCUMENT_SERVICE_PATH}/document/${request.documentId}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// Options: [{"(google.api.http)":{"get":"/document-service/v1/models/download"}}]
export const getModel: DocumentService["getModel"] = async function (request: GetModelRequest) {
	const response: AxiosResponse<GetModelResponse> = await HTTP.get(
		addQueryParams(`${DOCUMENT_SERVICE_PATH}/models/download`, {
			modelId: request.modelId
		})
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// Options: [{"(google.api.http)":{"post":"/document-service/v1/models/fine-tune","body":"*"}}]
export const storeFineTuningFeedback: DocumentService["storeFineTuningFeedback"] = async function (
	request: FineTuningRequest
) {
	const response: AxiosResponse<FineTuningResponse> = await HTTP.post(
		`${DOCUMENT_SERVICE_PATH}/models/fine-tune`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

// Options: [{"(google.api.http)":{"get":"/document-service/v1/document/{documentId}/statements"}}]
export const listStatementByDocumentId: DocumentService["listStatementByDocumentId"] =
	async function (request: ListStatementByDocumentIdRequest) {
		const response: AxiosResponse<ListStatementByDocumentIdResponse> = await HTTP.get(
			`${DOCUMENT_SERVICE_PATH}/document/${request.documentId}/statements`
		);

		if (response.status === 200) {
			return response.data;
		}

		throw response;
	};

export const ingestDocumentsV2: DocumentService["ingestDocuments"] = async request => {
	const response: AxiosResponse<IngestResponse> = await HTTP.post(
		`/document-service/v2/document/ingest`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}
	throw response;
};
