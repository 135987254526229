export function addQueryParams(apiPath: string, options: object): string {
	const filterLength = Object.keys(options).length;
	if (filterLength > 0) {
		/* Append filter options in path dynamically */
		let queryItems = "";
		Object.entries(options).forEach(([key, value], i) => {
			if (value !== undefined && value !== null) {
				queryItems = `${queryItems}${i > 0 ? "&" : ""}${key}=${encodeURIComponent(value)}`;
			}
		});

		if (queryItems) {
			apiPath = `${apiPath}?${queryItems}`;
		}
	}
	return apiPath;
}
