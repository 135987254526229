<template>
	<PermissionsWrapper>
		<f-div v-if="isLoading" width="fill-container">
			<CustomLoader />
		</f-div>
		<ErrorPage v-else-if="hasFetchError" message="Something went wrong when getting components" />
		<f-div
			v-else
			direction="row"
			variant="block"
			state="transparent"
			gap="medium"
			padding="none"
			align="top-left"
			width="100%"
			height="100%"
			selected="none"
			sticky="none"
		>
			<f-div direction="column" gap="none" :width="showSidePanel ? '60%' : '100%'" padding="none">
				<ComplianceReportComponentContainer />
			</f-div>

			<f-div
				v-if="showSidePanel"
				direction="column"
				align="top-right"
				gap="none"
				width="39%"
				padding="none"
				height="100%"
			>
				<PanelDetails />
			</f-div>
		</f-div>
	</PermissionsWrapper>
</template>

<script>
import { mapState } from "pinia";
import { defineComponent } from "vue";

import { cancelAllRequests } from "@/modules/services/api-service";
import CustomLoader from "@/shared/components/CustomLoader.vue";

import ErrorPage from "../auth/components/ErrorPage.vue";
import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";

import { complianceReportStore } from "./compliance-report-store";
import ComplianceReportComponentContainer from "./components/ComplianceReportComponentContainer.vue";
import PanelDetails from "./components/side-panel/PanelDetails.vue";

export default defineComponent({
	name: "ComplianceReport",

	components: {
		ComplianceReportComponentContainer,
		ErrorPage,
		PanelDetails,
		PermissionsWrapper,
		CustomLoader
	},

	data: () => {
		return {
			hasFetchError: false,
			isLoading: false
		};
	},

	computed: {
		...mapState(complianceReportStore, {
			showSidePanel: "showSidePanel"
		})
	},

	beforeUnmount() {
		cancelAllRequests();
	}
});
</script>
