<template>
	<f-div overflow="visible" gap="small" align="middle-right" flex-wrap>
		<f-div
			v-for="step in steps"
			:key="step.name"
			align="middle-center"
			width="hug-content"
			height="hug-content"
			gap="small"
			overflow="visible"
		>
			<f-div
				gap="small"
				overflow="visible"
				align="middle-center"
				width="hug-content"
				height="hug-content"
			>
				<f-icon-button
					:icon="step.icon.name"
					size="small"
					category="packed"
					:state="step.icon.state"
					:effect="step.status === 'ongoing' ? 'pulse' : undefined"
					:tooltip="step.tooltip"
				></f-icon-button>
				<f-text :state="getTextState(step)" :weight="getTextWeight(step)">{{ step.name }}</f-text>
			</f-div>
			<f-icon v-if="step.name !== 'Publish'" state="subtle" source="i-arrow-right"></f-icon>
		</f-div>
		<f-button
			v-if="showPublishButton"
			label="publish"
			size="small"
			category="outline"
			:state="isPublishBtnEnabled ? 'primary' : 'neutral'"
			:disabled="!isPublishBtnEnabled"
			:loading="isPublishing"
			icon-left="i-arrow-up"
			@click="publishComponent"
		></f-button>
		<f-divider
			size="medium"
			state="secondary"
			variant="solid"
			:style="{ height: '28px' }"
		></f-divider>

		<!---- Open List Popup for all respective Component Card -->
	</f-div>
</template>

<script lang="ts">
import { FIconButtonState } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { Component, ComponentType, WorkflowStatus } from "@/protocol/cockpit";
import { COMPONENT_STATUS, ComponentStatus } from "@/shared/constants";
import { captureError } from "@/utils";

export type StagesType = "pending" | "completed" | "ongoing" | "alert";

export type Step = {
	name: string;
	icon: {
		name: string;
		state: FIconButtonState;
	};
	status: StagesType;
	tooltip?: string;
};

export type StepStages = {
	[key in StagesType]: Step;
};

export default defineComponent({
	name: "ComponentLifeCycleStepper",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => {
		return {
			COMPONENT_STATUS,
			isPublishing: false
		};
	},

	computed: {
		...mapStores(componentOnboardStore, componentCatalogStore, notificationsStore),

		statements() {
			return this.componentOnboardStore.getEligibleControls(this.component.id);
		},

		isAssetComponent() {
			return this.component.type === ComponentType.ASSET;
		},

		steps() {
			const { status } = this.component;
			return this.getComponentLifecycleStage(status as ComponentStatus | "");
		},

		isPublishBtnEnabled() {
			return this.component.status === this.COMPONENT_STATUS.notarised;
		},

		allEvidencesApproved() {
			const allEvidences = this.componentOnboardStore.evidences[this.component.id!];
			return this.statements.every(statement => {
				const evidences = allEvidences?.[statement.documentId!]?.[statement.id!];
				return (
					evidences &&
					evidences.length > 0 &&
					evidences.every(evidence => evidence.status === "Approved")
				);
			});
		},

		isWorkflowCompleted() {
			return (
				this.componentOnboardStore.workflow[this.component.id!]?.status ===
				WorkflowStatus.WORKFLOW_COMPLETED
			);
		},

		showPublishButton() {
			const { status } = this.component;
			return (
				status === this.COMPONENT_STATUS.notarised ||
				status === this.COMPONENT_STATUS.onboardingComplete ||
				status === this.COMPONENT_STATUS.artifactRequired ||
				status === this.COMPONENT_STATUS.evidencePartiallyApproved ||
				status === this.COMPONENT_STATUS.evidenceApprovalPending ||
				status === this.COMPONENT_STATUS.classificationApprovalPending ||
				status === this.COMPONENT_STATUS.classificationApprovalRejected
			);
		}
	},

	methods: {
		async publishComponent() {
			try {
				this.isPublishing = true;
				await this.componentOnboardStore.componentPublish(this.component.id);
			} catch (error) {
				captureError(error);
				this.notificationsStore.ADD_TOAST({
					qaId: "publish-component-failed-toast",
					title: "Publish failed",
					text:
						error === "string"
							? error
							: "Something went wrong while publishing the component. Please try again.",
					status: "error"
				});
			} finally {
				this.isPublishing = false;
			}
		},

		getTextState(step: Step) {
			if (step.status === "completed") {
				return "success";
			}
			if (step.status === "ongoing") {
				return "default";
			}
			return "secondary";
		},

		getTextWeight(step: Step) {
			if (step.status === "completed") {
				return "medium";
			}
			if (step.status === "ongoing") {
				return "bold";
			}
			return "regular";
		},

		// eslint-disable-next-line complexity
		getComponentLifecycleStage(statusKey: string) {
			switch (statusKey) {
				case this.COMPONENT_STATUS.classificationApprovalPending:
				case this.COMPONENT_STATUS.draft:
					return [
						classificationStages.ongoing,
						onboardingStages.pending,
						...(this.isAssetComponent ? [] : [notarizationStages.pending])
					];
				case this.COMPONENT_STATUS.classificationApprovalRejected:
					return [classificationStages.alert, onboardingStages.pending, notarizationStages.pending];
				case this.COMPONENT_STATUS.artifactRequired:
				case this.COMPONENT_STATUS.evidencePartiallyApproved:
				case this.COMPONENT_STATUS.evidenceApprovalPending:
					return [
						classificationStages.completed,
						onboardingStages.ongoing,
						...(this.isAssetComponent ? [] : [notarizationStages.pending])
					];

				case this.COMPONENT_STATUS.readyToRelease:
				case this.COMPONENT_STATUS.onboardingComplete:
					if (this.allEvidencesApproved && this.isWorkflowCompleted) {
						return [
							classificationStages.completed,
							onboardingStages.completed,
							...(this.isAssetComponent ? [] : [notarizationStages.ongoing])
						];
					}

					return [
						classificationStages.completed,
						onboardingStages.ongoing,
						...(this.isAssetComponent ? [] : [notarizationStages.pending])
					];

				case this.COMPONENT_STATUS.notarised:
					return [
						classificationStages.completed,
						onboardingStages.completed,
						...(this.isAssetComponent ? [] : [notarizationStages.completed])
					];

				case this.COMPONENT_STATUS.deprecated:
					return [
						classificationStages.completed,
						onboardingStages.completed,
						...(this.isAssetComponent ? [] : [notarizationStages.completed]),
						publish.completed
					];
				case this.COMPONENT_STATUS.deprecationPending:
					return [
						classificationStages.completed,
						onboardingStages.completed,
						...(this.isAssetComponent ? [] : [notarizationStages.completed]),
						publish.completed
					];
				case this.COMPONENT_STATUS.published:
					return [
						classificationStages.completed,
						onboardingStages.completed,
						...(this.isAssetComponent ? [] : [notarizationStages.completed]),
						publish.completed
					];
				default:
					return [
						classificationStages.ongoing,
						onboardingStages.pending,
						...(this.isAssetComponent ? [] : [notarizationStages.pending])
					];
			}
		}
	}
});

const classificationStages: StepStages = {
	ongoing: {
		name: "Classification",
		icon: {
			name: "i-circle",
			state: "primary"
		},
		status: "ongoing",
		tooltip: "Classification is ongoing"
	},
	pending: {
		name: "Classification",
		icon: {
			name: "i-lock",
			state: "neutral"
		},
		status: "pending",
		tooltip: "Classification is pending"
	},
	completed: {
		name: "Classification",
		icon: {
			name: "i-tick-fill",
			state: "success"
		},
		status: "completed",
		tooltip: "Classification is completed"
	},

	alert: {
		name: "Classification",
		icon: {
			name: "i-alert",
			state: "danger"
		},
		status: "alert",
		tooltip: "Classification is rejected"
	}
};

const onboardingStages: StepStages = {
	ongoing: {
		name: "Onboarding",
		icon: {
			name: "i-circle",
			state: "primary"
		},
		status: "ongoing",
		tooltip: "Onboarding is ongoing"
	},
	pending: {
		name: "Onboarding",
		icon: {
			name: "i-lock",
			state: "neutral"
		},
		status: "pending",
		tooltip: "Onboarding is pending"
	},
	completed: {
		name: "Onboarding",
		icon: {
			name: "i-tick-fill",
			state: "success"
		},
		status: "completed",
		tooltip: "Onboarding is completed"
	},
	alert: {
		name: "Onboarding",
		icon: {
			name: "i-alert",
			state: "warning"
		},
		status: "alert",
		tooltip: "Onboarding is rejected"
	}
};

const notarizationStages: StepStages = {
	ongoing: {
		name: "Notarization",
		icon: {
			name: "i-circle",
			state: "primary"
		},
		status: "ongoing",
		tooltip: "Notarization is ongoing"
	},
	pending: {
		name: "Notarization",
		icon: {
			name: "i-lock",
			state: "neutral"
		},
		status: "pending",
		tooltip: "Notarization is pending"
	},
	completed: {
		name: "Notarization",
		icon: {
			name: "i-tick-fill",
			state: "success"
		},
		status: "completed",
		tooltip: "Notarization is completed"
	},
	alert: {
		name: "Onboarding",
		icon: {
			name: "i-alert",
			state: "warning"
		},
		status: "alert",
		tooltip: "Notarization is rejected"
	}
};

const publish = {
	completed: {
		name: "Publish",
		icon: {
			name: "i-tick-fill",
			state: "success"
		},
		status: "completed",
		tooltip: "Component is published"
	} as Step
};
</script>
