/**
 * This file contains the paths to the services.
 *
 * We try to use the paths provided by the environment variables before using our default values.
 */

export const CORRELATION_SERVICE_PATH =
	window.VUE_APP_CORRELATION_SERVICE_PATH ?? "/correlation-service/v1/correlation";

export const EVIDENCE_SERVICE_PATH =
	window.VUE_APP_EVIDENCE_SERVICE_PATH ?? "/evidence-service/v1/evidence";

export const EVIDENCE_SERVICE_DETAIL_PATH =
	window.VUE_APP_EVIDENCE_SERVICE_PATH ?? "/evidence-service/v1";

export const COCKPIT_SERVICE_PATH =
	window.VUE_APP_COCKPIT_SERVICE_PATH ?? "/cockpit-service/v1/cockpit";

export const DOCUMENT_SERVICE_PATH = window.VUE_APP_DOCUMENT_SERVICE_PATH ?? "/document-service/v1";

export const DOCUMENT_MAPPING_SERVICE_PATH =
	window.VUE_APP_DOCUMENT_MAPPING_SERVICE_PATH ?? "/document-mapping-service/v1/document-mapping";

export const KRATOS_SERVICE_PATH =
	window.VUE_APP_KRATOS_SERVICE_PATH ?? `${window.VUE_APP_API_BASE_URL}/kratos`;

export const USER_SERVICE_PATH = window.VUE_APP_USER_SERVICE_PATH ?? "/user-service/v1/user";

export const TAXONOMY_SERVICE_PATH =
	window.VUE_APP_TAXONOMY_SERVICE_PATH ?? "/taxonomy-service/v1/taxonomy";
