import { AxiosResponse } from "axios";

import {
	ExchangeCodeWithTokenRequest,
	ExchangeCodeWithTokenResponse,
	ListPermissionsRequest,
	ListPermissionsResponse,
	UserListResponse,
	UserPermissionsRequest,
	UserPermissionsResponse,
	UserService
} from "@/protocol/users";
import { USER_SERVICE_PATH } from "@/shared/service-paths";

import HTTP from "../services/api-service";

export const listPermissions: UserService["listPermissions"] = async function (
	request: ListPermissionsRequest
) {
	const response: AxiosResponse<ListPermissionsResponse> = await HTTP.post(
		`${USER_SERVICE_PATH}/permissions`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const listAllUsers: UserService["userList"] = async function () {
	const response: AxiosResponse<UserListResponse> = await HTTP.get(`${USER_SERVICE_PATH}/users`);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const ProvidePermission: UserService["grantPermission"] = async function (
	request: UserPermissionsRequest
) {
	const response: AxiosResponse<UserPermissionsResponse> = await HTTP.post(
		`${USER_SERVICE_PATH}/authorize`,
		request
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};

export const exchangeCodeWithToken: UserService["exchangeCodeWithToken"] = async function (
	request: ExchangeCodeWithTokenRequest
) {
	const response: AxiosResponse<ExchangeCodeWithTokenResponse> = await HTTP.get(
		`${USER_SERVICE_PATH}/token?code=${request.code}&redirectUri=${request.redirectUri}`
	);

	if (response.status === 200) {
		return response.data;
	}

	throw response;
};
