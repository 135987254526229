<template>
	<div class="node-net-container">
		<div :id="id" ref="nodenet" class="node-net" />
		<slot />
	</div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { Icon } from "@ollion/flow-vue3";
import * as d3 from "d3";
import { defineComponent } from "vue";

import zoom from "./zoom";

export default defineComponent({
	name: "HierarchyTree",

	components: {
		Icon
	},

	props: {
		nodes: {
			type: Array,
			default: () => []
		},

		nodeRadius: {
			type: Number,
			default: 30
		},

		nodeNames: {
			type: Boolean,
			default: true
		},

		resetOnDrag: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			id: "nodeNetwork",
			lineageRef: null,
			container: null,
			width: null,
			height: null
		};
	},

	watch: {
		nodes: {
			handler: "init",
			deep: true
		},

		links: {
			handler: "init",
			deep: true
		},

		nodeRadius: {
			handler: "init"
		},

		nodeNames: {
			handler() {
				document.querySelectorAll(`#${this.id} .net-node-labels`).forEach(label => {
					if (!this.nodeNames) {
						label.classList.add("hide");
					} else {
						label.classList.remove("hide");
					}
				});
			}
		}
	},

	beforeMount() {
		this.id = this.generateId();
	},

	mounted() {
		document.addEventListener("fullscreenchange", this.setFullscreenCanvas);
		this.init();
	},

	beforeUnmount() {
		document.removeEventListener("fullscreenchange", this.onFullScreenChange);
	},

	methods: {
		...zoom,

		isSafari() {
			return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		},

		setFullscreenCanvas() {
			this.width = this.$refs.nodenet.offsetWidth;
			this.height = this.$refs.nodenet.offsetHeight + 200;
			this.svg.attr("width", this.width);
			this.svg.attr("height", this.height);
		},

		appendArrows(svg) {
			/**
			 * Arrow for links
			 */
			svg
				.append("svg:defs")
				.append("svg:marker")
				.attr("id", "arrow")
				.attr("viewBox", "0 -8 16 16")
				.attr("refX", 15)
				.attr("refY", 0)
				.attr("markerWidth", 8)
				.attr("markerHeight", 8)
				.attr("orient", "auto")
				.attr("fill", "var(--dashboard-font-light)")
				.append("svg:path")
				.attr("d", "M0,-8L16,0L0,8");
			svg
				.append("svg:defs")
				.append("svg:marker")
				.attr("id", "arrow-error")
				.attr("viewBox", "0 -8 16 16")
				.attr("refX", 15)
				.attr("refY", 0)
				.attr("markerWidth", 8)
				.attr("markerHeight", 8)
				.attr("orient", "auto")
				.attr("fill", "var(--dashboard-danger)")
				.append("svg:path")
				.attr("d", "M0,-8L16,0L0,8");
			svg
				.append("svg:defs")
				.append("svg:marker")
				.attr("id", "arrow-warning")
				.attr("viewBox", "0 -8 16 16")
				.attr("refX", 15)
				.attr("refY", 0)
				.attr("markerWidth", 8)
				.attr("markerHeight", 8)
				.attr("orient", "auto")
				.attr("fill", "var(--dashboard-warning)")
				.append("svg:path")
				.attr("d", "M0,-8L16,0L0,8");
		},

		/**
		 * Delete when nodes accepted through props
		 */
		getComputedLinks(linkContainer) {
			const links = linkContainer
				.append("g")
				.attr("class", "node-link")
				.attr("fill", "transparent")
				.attr("stroke-width", "1px")
				.attr("marker-end", function (d) {
					if (d.state === "error") {
						return "url(#arrow-error)";
					} else if (d.state === "warning") {
						return "url(#arrow-warning)";
					}
					return "url(#arrow)";
				})
				.attr("id", function (d, i) {
					return `label-${i}`;
				})
				.attr("stroke", function (d) {
					if (d.state === "error") {
						return "var(--dashboard-danger)";
					} else if (d.state === "warning") {
						return "var(--dashboard-warning)";
					} else if (d.state === "primary") {
						return "var(--dashboard-primary)";
					} else if (d.state === "success") {
						return "var(--dashboard-success)";
					} else if (d.state === "selected") {
						return "var(--dashboard-font-dark)";
					}
					return "var(--dashboard-font-light)";
				});

			// Add path element for the link
			links
				.append("svg:path")
				.attr("class", "node-link")
				.attr("fill", "transparent")
				.attr("stroke-width", "1px")
				.attr("id", function (d, i) {
					return `label-${i}`;
				});

			// Add text element for the label
			links
				.append("text")
				.attr("dx", 20)
				.attr("dy", 3)
				.attr("class", "link-label")
				.attr("text-anchor", "middle")
				.attr("startOffset", "50%")
				.attr("fill", function (d) {
					if (d.state === "selected") {
						return "var(--dashboard-font-dark)";
					}
					return "var(--dashboard-font-light)";
				});

			return links;
		},

		getNodes(container, rootNodes, nodeRadius) {
			return container
				.append("g")
				.attr("class", "net-nodes")
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("circle")
				.attr("class", "node-circle")
				.attr("r", nodeRadius)
				.attr("data-qa", function (d) {
					const name = d?.data?.name?.replace(/ /g, "-") ?? "no-name";
					return `node-circle-${name}`;
				})
				.attr("id", function (d) {
					return d.data.id.replace(/ /g, "_");
				})
				.attr("fill", function (d) {
					if (d.data.color) {
						return d.data.color;
					}
					switch (d.depth) {
						case 0:
							return "var(--primary-400)";
						case 1:
							return "var(--color2-200)";
						case 2:
							return "var(--success-400)";
						case 3:
							return "var(--color5-300)";
						default:
							return "var(--dashboard-element)";
					}
				});
		},

		getNodeLabels(container, rootNodes) {
			return container
				.append("g")
				.attr("class", "net-node-labels")
				.classed("hide", !this.nodeNames)
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("text")
				.attr("text-anchor", "middle")
				.attr("class", "node-label")
				.attr("data-qa", function (d) {
					const name = String(d.data.name).trim();
					return `node-name-${name}`;
				})
				.text(function (d) {
					return d.data.name;
				});
		},

		getSecondaryLabels(container, rootNodes) {
			return container
				.append("g")
				.attr("class", "net-node-labels")
				.classed("hide", !this.nodeNames)
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("text")
				.attr("text-anchor", "middle")
				.attr("class", "node-label")
				.attr("data-qa", function (d) {
					const name = String(d.data.name).trim();
					return `secondary-label-${name}`;
				})
				.text(function (d) {
					return d.data.secondaryLabels;
				});
		},

		getNodeStates(container, rootNodes, nodeRadius) {
			return container
				.append("g")
				.attr("class", "net-node-states")
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("circle")
				.attr("class", "node-state-circle")
				.attr("r", nodeRadius - 3)
				.attr("fill", "transparent")
				.attr("data-qa", function (d) {
					const name = String(d.data.name).trim();
					return `node-state-${name}`;
				})
				.attr("stroke", function (d) {
					if (d.data.state === "error") {
						return "var(--dashboard-danger)";
					} else if (d.data.state === "warning") {
						return "var(--dashboard-warning)";
					} else if (d.data.state === "primary") {
						return "var(--dashboard-primary)";
					} else if (d.data.state === "success") {
						return "var(--dashboard-success)";
					} else if (d.data.state === "selected") {
						return "var(--dashboard-font-dark)";
					} else {
						return "transparent";
					}
				})
				.attr("stroke-width", 1);
		},

		getNodeStateCountCircle(container, rootNodes) {
			return container
				.append("g")
				.attr("class", "net-node-state-counts")
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("circle")
				.attr("class", "node-state-count-circle")
				.attr("r", 8)
				.classed("hide", function (d) {
					return !d.data.collapsed;
				})
				.attr("fill", function (d) {
					if (d.data.state === "error") {
						return "var(--dashboard-danger)";
					} else if (d.data.state === "warning") {
						return "var(--dashboard-warning)";
					} else if (d.data.state === "primary") {
						return "var(--dashboard-primary)";
					} else if (d.data.state === "success") {
						return "var(--dashboard-success)";
					} else if (d.data.state === "selected") {
						return "var(--dashboard-font-dark)";
					} else {
						return "var(--dashboard-font-light)";
					}
				});
		},

		getNodeChildCounts(container, rootNodes) {
			return container
				.append("g")
				.attr("class", "net-node-child-counts")
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("text")
				.attr("class", "node-state-child-count")
				.attr("text-anchor", "middle")
				.classed("hide", function (d) {
					return !d.data.collapsed;
				})
				.text(function (d) {
					if (d.data._children && d.data._children.length > 0) {
						return d.data._children.length;
					}
					return 0;
				});
		},

		getNodeIcons(container, rootNodes, nodeRadius) {
			return container
				.append("g")
				.attr("class", "node-icons")
				.selectAll("g")
				.data(rootNodes)
				.enter()
				.append("foreignObject")
				.attr("width", nodeRadius * 2)
				.attr("height", nodeRadius * 2)
				.html(d => {
					if (d.data.icon) {
						let propsData = { type: "filled", effects: false };
						if (typeof d.data.icon === "string") {
							propsData.name = d.data.icon;
						} else if (typeof d.data.icon === "object") {
							propsData = { ...propsData, ...d.data.icon };
						}
						return `<f-icon
							class="node-icon-element"
							variant="round"
							state="tertiary"
							source="${propsData.name}"
							size="large"
						/>`;
					} else {
						return "";
					}
				});
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getLineage({ rootNodes, rootLinks }, linkWidth) {
			return (
				d3
					.forceSimulation(rootNodes)
					//repel nodes from each other
					.force("charge", d3.forceManyBody().strength(-800))
					.force(
						"link",
						d3
							.forceLink(rootLinks)
							.id(function (d) {
								return d.data.id;
							})
							.distance(linkWidth)
							.strength(1)
					)
					//set fixed children height
					.force("growth", () => {
						rootNodes.forEach((node, i) => {
							node.y = i % 2 === 0 ? node.depth * 220 : node.depth * 200;
						});
					})
					//collide nodes
					.force("collide", d3.forceCollide(120))
					//Animation speed
					.alphaDecay(0.6)
			);
		},

		initializeContainer() {
			/**
			 * Getting height and width of container
			 */
			this.width = this.$refs.nodenet.offsetWidth;
			this.height = this.$refs.nodenet.offsetHeight;
			/**
			 * Initializing svg
			 */
			const wrapper = document.getElementById(this.id);
			wrapper.innerHTML = "";
			this.svg = d3
				.select(`#${this.id}`)
				.append("svg")
				.attr("width", this.width)
				.attr("height", this.height);
			const container = this.svg.append("g");
			/**
			 * Binding zoom
			 */
			this.svg
				.call(
					d3
						.zoom()
						.scaleExtent([0.3, 4])
						.on("zoom", () => {
							this.zoomContainer(d3.event.transform);
							if (d3.event.sourceEvent.type === "mousemove" && this.isSafari()) {
								const t = d3.zoomTransform(this.container.node());
								t.k += 0.0001;
								this.zoomContainer(t);
							}
						})
				)
				.on("dblclick.zoom", null);
			return container;
		},

		// eslint-disable-next-line max-statements
		init() {
			let container = null;

			const { nodeRadius } = this;
			const linkWidth = 200;

			function addNodes(nodelist) {
				const nodes = [];
				nodelist.forEach(n => {
					if (n.children && n.children.length > 0) {
						addNodes(n.children);
						if (n.collapsed) {
							n._children = n.children;
							n.children = null;
						}
					}
					nodes.push(n);
				});
				return nodes.reduce(n => n);
			}

			container = this.initializeContainer();
			this.container = container;

			const data = addNodes(this.nodes);
			const treeLayout = d3.tree().size([this.width, this.height]);
			const root = d3.hierarchy(data);
			treeLayout(root);
			const rootNodes = root.descendants();
			const rootLinks = root.links();

			rootNodes.forEach(node => {
				node.y = node.depth * 180;
				node.data?.otherRelations?.forEach(relation => {
					const source = Object.keys(relation)[0];
					const target = rootNodes.find(rn => rn.data.uid.toString() === source.toString());
					if (target) {
						const nodeObj = relation.reverse
							? { source: node, target }
							: { source: target, target: node };
						rootLinks.push(nodeObj);
					}
				});
			});
			rootLinks.forEach(r => {
				const srcLabel = r.source.data.relation?.find(
					srcRelation => srcRelation.to === r.target.data.uid
				);
				const tarLabel = r.target.data.relation?.find(
					tarRelation => tarRelation.to === r.source.data.uid
				);
				r.label = srcLabel?.relation ?? tarLabel?.relation;
			});
			//Auto adjust zoom based on nodes
			const depth = root.descendants()[root.descendants().length - 1]?.depth;
			const t = d3.zoomTransform(this.container.node());
			t.k = depth <= 3 ? 1 : 1 - depth / 12;
			if (t.k < 0.3) {
				t.k = 0.3;
			}
			this.zoomContainer(t);
			this.appendArrows(this.svg);
			const rootNodeIndex = rootNodes.findIndex(x => x.data?.root);
			const w = depth <= 3 ? this.width / 2 : this.width / 1.4 - (depth - 4) * 0.2;
			if (rootNodeIndex > -1) {
				rootNodes[rootNodeIndex].fx = w;
				rootNodes[rootNodeIndex].fy = 30;
			}
			function neigh(a, b) {
				return a === b;
			}
			/**
			 * Adding link container
			 */
			const linkContainer = container
				.append("g")
				.attr("class", "links")
				.selectAll("g")
				.data(rootLinks)
				.enter()
				.append("g");
			/**
			 * Adding links
			 */
			const computedLinks = this.getComputedLinks(linkContainer);
			/**
			 * Adding nodes
			 */
			const computedNodes = this.getNodes(container, rootNodes, nodeRadius);

			let nodeNames = null;
			/**
			 * Adding node labels
			 */
			nodeNames = this.getNodeLabels(container, rootNodes);
			let secondaryNames = null;

			secondaryNames = this.getSecondaryLabels(container, rootNodes);
			/**
			 * Adding node icons if any
			 */
			const nodeIcons = this.getNodeIcons(container, rootNodes, nodeRadius);
			/**
			 * Adding node states
			 */
			const computedNodeStates = this.getNodeStates(container, rootNodes, nodeRadius);
			/**
			 * Adding node state count
			 */
			const computedNodeStateCountCircles = this.getNodeStateCountCircle(container, rootNodes);
			/**
			 * display children count
			 */
			const childCounts = this.getNodeChildCounts(container, rootNodes);

			/**
			 * Binding mouseover, mouseout, click events
			 */
			computedNodes
				.on("mouseover", focus)
				.on("mouseout", unfocus)
				.on("click", this.handleClick)
				.on("dblclick", this.handleDbClick)
				.on("contextmenu", this.handleRightClick);
			/**
			 * binding drag: start, drag, end  events
			 */
			computedNodes.call(
				d3.drag().on("start", this.dragstarted).on("drag", this.dragged).on("end", this.dragended)
			);

			this.lineageRef = this.getLineage(
				{ rootNodes, rootLinks },
				linkWidth,
				this.width,
				this.height
			).on("tick", updatePositions);
			function focus() {
				const { index } = d3.select(d3.event.target).datum();
				const nofocusopacity = 0.4;
				computedNodes.style("opacity", function (o) {
					return neigh(index, o.index) ? 1 : nofocusopacity;
				});
				if (nodeNames) {
					nodeNames.style("opacity", function (o) {
						return neigh(index, o.index) ? 1 : nofocusopacity;
					});
				}
				if (secondaryNames) {
					secondaryNames.style("opacity", function (o) {
						return neigh(index, o.index) ? 1 : nofocusopacity;
					});
				}
				computedNodeStates.style("opacity", function (o) {
					return neigh(index, o.index) ? 1 : nofocusopacity;
				});
				computedNodeStateCountCircles.style("opacity", function (o) {
					return neigh(index, o.index) ? 1 : nofocusopacity;
				});
				childCounts.style("opacity", function (o) {
					return neigh(index, o.index) ? 1 : nofocusopacity;
				});
				nodeIcons.style("opacity", function (o) {
					return neigh(index, o.index) ? 1 : nofocusopacity;
				});
				computedLinks.style("opacity", function (o) {
					return o.source.index === index || o.target.index === index ? 1 : nofocusopacity;
				});
			}
			function unfocus() {
				computedNodes.style("opacity", 1);
				computedNodeStates.style("opacity", 1);
				computedNodeStateCountCircles.style("opacity", 1);
				childCounts.style("opacity", 1);
				if (nodeNames) {
					nodeNames.style("opacity", 1);
				}
				if (secondaryNames) {
					secondaryNames.style("opacity", 1);
				}
				nodeIcons.style("opacity", 1);
				computedLinks.style("opacity", 1);
			}
			/**
			 * Fix infinite values
			 */
			function fixna(x) {
				if (isFinite(x)) {
					return x;
				}
				return 0;
			}
			/**
			 * Update positions
			 */
			function updatePositions() {
				computedNodes.call(function (node) {
					node.attr("transform", function (d) {
						return `translate(${fixna(d.x)},${fixna(d.y)})`;
					});
				});
				computedNodeStates.call(function (node) {
					node.attr("transform", function (d) {
						return `translate(${fixna(d.x)},${fixna(d.y)})`;
					});
				});
				computedNodeStateCountCircles.call(function (node) {
					node.attr("transform", function (d) {
						return `translate(${fixna(d.x + nodeRadius - 12)},${fixna(d.y - nodeRadius + 12)})`;
					});
				});
				childCounts.call(function (node) {
					node.attr("transform", function (d) {
						return `translate(${fixna(d.x + nodeRadius - 12)},${fixna(d.y - nodeRadius + 15)})`;
					});
				});
				if (nodeNames) {
					nodeNames.call(function (node) {
						node.attr("transform", function (d) {
							return `translate(${fixna(d.x)},${fixna(d.y + nodeRadius + 18)})`;
						});
					});
				}
				if (secondaryNames) {
					secondaryNames.call(function (node) {
						node.attr("transform", function (d) {
							return `translate(${fixna(d.x)},${fixna(d.y + nodeRadius + 28)})`;
						});
					});
				}
				nodeIcons.call(function (icon) {
					icon.attr("transform", function (d) {
						return `translate(${fixna(d.x - nodeRadius)},${fixna(d.y - nodeRadius)})`;
					});
				});
				computedLinks.call(function (link) {
					link.select("path").attr("d", function (d) {
						const pathAngle = 0;
						const t_radius = nodeRadius + 6; // nodeWidth is just a custom attribute I calculate during the creation of the nodes depending on the node width
						const dx = d.target.x - d.source.x;
						const dy = d.target.y - d.source.y;
						const gamma = Math.atan2(dy, dx); // Math.atan2 returns the angle in the correct quadrant as opposed to Math.atan
						const tx = d.target.x - Math.cos(gamma) * t_radius;
						const ty = d.target.y - Math.sin(gamma) * t_radius;
						const sx = d.source.x + Math.cos(gamma) * t_radius;
						const sy = d.source.y + Math.sin(gamma) * t_radius - 5;
						if (d.target?.data?.reverse) {
							//Reverse link
							//If node is to the left of parent
							const clock = d.target.x > d.source.x ? 0 : 1;
							return `M${d.target.x},${
								d.target.y
							}A${pathAngle},${pathAngle} 0 0,${clock} ${+sx},${sy}`;
						} else {
							const clock = d.target.x > d.source.x ? 1 : 0;
							return `M${d.source.x},${
								d.source.y
							}A${pathAngle},${pathAngle} 0 0,${clock} ${+tx},${ty}`;
						}
					});
					link
						.select("text")
						.text(function (d) {
							if (d.source?.children?.length > 30 || d.target?.children?.length > 30) {
								return "";
							}
							return d.label || d.source.data.relation || d.target.data.relation;
						})
						.attr("transform", function (d) {
							//If too many childrens, don't add label to avoid performance issues
							if (d.source?.children?.length > 30 || d.target?.children?.length > 30) {
								return;
							}
							const x = (d.source.x + d.target.x) / 2;
							const y = (d.source.y + d.target.y) / 2;
							const dx = d.target.x - d.source.x;
							const dy = d.target.y - d.source.y;
							const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
							return `translate(${x}, ${y}) rotate(${angle}) translate(0, -10)`;
						});
				});
			}
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		handleDbClick(d) {
			this.$emit("dblclick", d3.select(d3.event.target).datum());
		},

		handleClick() {
			d3.event.stopPropagation();
			d3.event.preventDefault();
			const d = d3.select(d3.event.target).datum();
			this.$emit("node-click", d);
		},

		handleRightClick() {
			d3.event.stopPropagation();
			d3.event.preventDefault();
			this.$emit("rclick", d3.select(d3.event.target).datum());
			return;
		},

		dragstarted(d) {
			d3.event.sourceEvent.stopPropagation();
			if (!d3.event.active) {
				this.lineageRef.alphaTarget(0.3).restart();
			}
			d.fx = d.x;
			d.fy = d.y;
			/**
			 * Whenever node drag started
			 */
			this.$emit("dragstart", d);
		},

		dragged(d) {
			d.fx = d3.event.x;
			d.fy = d3.event.y;
			/**
			 * Whenever node dragged
			 */
			this.$emit("dragged", d);
		},

		dragended(d) {
			if (this.resetOnDrag) {
				if (!d3.event.active) {
					this.lineageRef.alphaTarget(0);
				}
				d.fx = null;
				d.fy = null;
			}
			/**
			 * Whenever node drag ends
			 */
			this.$emit("dragend", d);
		},

		/**
		 * Generate random ID for each Instance
		 */
		generateId() {
			let id = "";
			const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
			const charsLength = chars.length;
			const { crypto } = window;
			const arrayOfNumbers = new Uint32Array(1);
			for (let i = 0; i < 10; i++) {
				crypto.getRandomValues(arrayOfNumbers);
				const randomNo = arrayOfNumbers[0] / (Math.pow(2, 32) - 1);
				id += chars.charAt(Math.floor(randomNo * charsLength));
			}
			return id;
		}
	}
});
</script>
<style lang="scss">
div.node-net-container {
	width: 100%;
	height: 100%;
	align-self: stretch;

	div.node-net {
		width: 100%;
		height: 100%;
		align-self: stretch;
		circle.node-circle {
			stroke: var(--dashboard-background);
			stroke-width: 6px;
			cursor: pointer;
		}
		.node-state-circle {
			pointer-events: none;
		}
		.link-label {
			font-size: 10px;
		}
		.node-label {
			font-size: 10px;
			fill: var(--dashboard-font-dark);
		}
		.node-state-child-count {
			font-size: 10px;
			fill: var(--dashboard-background);
		}
		.hide {
			display: none;
		}

		foreignObject {
			pointer-events: none;

			// f-icon can't be styled normally
			> * {
				height: 36px;
				width: 36px;
				top: 50%;
				margin-top: -14px;
				position: relative;
				left: 50%;
				margin-left: -14px;
			}
		}
	}

	.node-popover {
		display: none;
	}
	> div.node-net-overlay {
		content: "";
		position: fixed;
		top: 0px;
		left: 0px;
		background-color: #000;
		transition: opacity 0.3s linear;
		z-index: 103;
		opacity: 0;
		pointer-events: none;
	}
	> div.node-net-overlay {
		&[open] {
			opacity: 0.7;
			pointer-events: auto;
			width: 100%;
			height: 100%;
		}
	}
	> *:not(div.node-net-overlay) {
		z-index: 104;
	}
}
</style>
