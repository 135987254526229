import HTTP from "../services/api-service";

export default {
	generateComplianceReport(file: File, countries: string, cloudProvider: string) {
		const formData = new FormData();
		formData.append("file", file, file.name);
		formData.append("countries", countries);
		formData.append("cloudProvider", cloudProvider);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return HTTP.post(`/correlation-service/v1/correlation/lz/report`, formData, config);
	}
};
