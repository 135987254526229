<template>
	<f-popover size="medium" :open="true" :overlay="true">
		<f-div
			v-if="rejectDeprecateRequest"
			width="432px"
			state="secondary"
			direction="column"
			variant="curved"
			padding="none"
		>
			<f-div align="middle-center" padding="medium" border="small solid subtle bottom">
				<f-div height="hug-content" padding="none" gap="small">
					<f-icon
						size="medium"
						state="subtle"
						source="i-arrow-left"
						class="cursor-pointer"
						@click="rejectDeprecateRequest = false"
					></f-icon>
					<f-text variant="heading" size="medium" weight="bold" state="default" align="left">
						Reject deprecation
					</f-text>
				</f-div>
				<f-div width="hug-content" height="hug-content"
					><f-icon-button
						icon="i-close"
						variant="block"
						category="packed"
						state="neutral"
						size="small"
						data-qa="close-deprecate-modal-button"
						@click="closeModal"
					></f-icon-button
				></f-div>
			</f-div>

			<f-div gap="medium" direction="column" padding="large" height="hug-content">
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div gap="small" width="hug-content">
						<f-text
							inline
							variant="heading"
							size="small"
							weight="medium"
							state="secondary"
							align="left"
						>
							Reason for rejecting deprecation
						</f-text>
					</f-div>
					<f-div>
						<f-text-area
							v-model="deprecationReason"
							height="73px"
							padding="small"
							placeholder="Enter reason for rejecting deprecation"
						></f-text-area>
					</f-div>
				</f-div>
			</f-div>

			<f-divider variant="solid" state="subtle"></f-divider>

			<f-div padding="medium" align="middle-right" gap="small">
				<f-button
					label="REJECT"
					variant="round"
					category="outline"
					size="small"
					state="neutral"
					icon-left="i-close"
					data-qa-id="close-modal-deprecation-request-action-reject"
					:disabled="!deprecationReason.trim()"
					@click="rejectDeprecateRequestByApprover"
				></f-button>
			</f-div>
		</f-div>
		<f-div
			v-else
			width="432px"
			state="secondary"
			direction="column"
			variant="curved"
			padding="none"
		>
			<f-div align="middle-center" padding="medium" border="small solid subtle bottom">
				<f-div height="hug-content" padding="none"
					><f-text variant="heading" size="medium" weight="bold" state="default" align="left">
						Deprecation request
					</f-text>
				</f-div>
				<f-div width="hug-content" height="hug-content"
					><f-icon-button
						icon="i-close"
						variant="block"
						category="packed"
						state="neutral"
						size="small"
						data-qa="close-deprecate-modal-button"
						@click="closeModal"
					></f-icon-button
				></f-div>
			</f-div>

			<f-div gap="medium" direction="column" padding="large" height="hug-content">
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div>
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="medium"
							state="secondary"
							align="left"
						>
							Requested by
						</f-text>
					</f-div>
					<f-div>
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="medium"
							state="secondary"
							align="left"
						>
							{{ deprecationDetails.requestedBy }}
						</f-text>
					</f-div>
				</f-div>
				<f-divider variant="solid" state="subtle"></f-divider>
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div gap="small" width="hug-content">
						<f-text
							inline
							variant="heading"
							size="small"
							weight="medium"
							state="secondary"
							align="left"
						>
							Reason for deprecation
						</f-text>
					</f-div>
					<f-div>
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="medium"
							state="secondary"
							align="left"
						>
							{{ deprecationDetails.reason }}
						</f-text>
					</f-div>
				</f-div>
			</f-div>

			<f-divider variant="solid" state="subtle"></f-divider>
			<f-div
				v-if="!isComponentDeprecated && hasApproverRights"
				padding="medium"
				align="middle-right"
				gap="small"
			>
				<f-button
					label="REJECT"
					variant="round"
					category="outline"
					size="small"
					state="neutral"
					icon-left="i-close"
					data-qa-id="close-modal-deprecation-request-action-reject"
					@click="rejectDeprecateRequest = true"
				></f-button>
				<f-button
					label="APPROVE"
					variant="round"
					size="small"
					state="danger"
					icon-left="i-unlink"
					data-qa-id="close-modal-deprecation-request-action-approve"
					:loading="isLoading"
					@click="approveDeprecationAction"
				></f-button>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { workflowStore } from "@/modules/workflow/workflow-store";
import { Component } from "@/protocol/cockpit";
import { DeprecationInfo } from "@/protocol/workflow";
import { COMPONENT_STATUS } from "@/shared/constants";

import { componentOnboardStore } from "../../component-onboard-store";

export default defineComponent({
	name: "DeprecateComponentRequestAction",

	props: {
		selectedComponent: {
			type: Object as PropType<Component>,
			required: true
		},

		hasApproverRights: {
			type: Boolean,
			required: true
		},

		deprecationDetails: {
			type: Object as PropType<DeprecationInfo>,
			required: true
		}
	},

	emits: ["closeModal"],

	data() {
		return {
			deprecationReason: "",
			rejectDeprecateRequest: false,
			isLoading: false
		};
	},

	computed: {
		...mapStores(notificationsStore, workflowStore, componentOnboardStore),

		componentTitle() {
			return `${this.selectedComponent.name} ${this.selectedComponent.version}`;
		},

		isComponentDeprecated() {
			return this.selectedComponent.status === COMPONENT_STATUS.deprecated;
		}
	},

	methods: {
		async approveDeprecationAction() {
			this.isLoading = true;
			try {
				await this.workflowStore.approveRejectDeprecationTask({
					componentId: this.selectedComponent.id,
					approved: true,
					comment: "Request approved"
				});
				await this.componentOnboardStore.getComponentById(this.selectedComponent.id);
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Deprecation request approved",
					text: `${this.componentTitle} is deprecated successfully.`,
					status: "info"
				});
				this.isLoading = false;
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Request not sent for deprecation approval",
					text: "There is some error in processcing your request, please try again!!!",
					status: "warning"
				});
				this.isLoading = false;
			}
			this.closeModal();
		},

		closeModal() {
			this.$emit("closeModal");
		},

		async rejectDeprecateRequestByApprover() {
			this.isLoading = true;
			try {
				await this.workflowStore.approveRejectDeprecationTask({
					componentId: this.selectedComponent.id,
					approved: false,
					comment: this.deprecationReason
				});

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Deprecation request is rejected",
					text: `Deprecation request for ${this.componentTitle} is rejected.`,
					status: "info"
				});
				this.isLoading = false;
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Request not sent for deprecation approval",
					text: "There is some error in processcing your request, please try again!!!",
					status: "warning"
				});
				this.isLoading = false;
			}

			this.closeModal();
		}
	}
});
</script>
