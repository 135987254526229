<template>
	<f-div direction="column" gap="medium" height="fill-container" overflow="scroll">
		<f-div height="100%" width="100%" class="f-three-columns">
			<f-div class="f-middle-column" padding="large small large none">
				<f-div direction="column" height="hug-content">
					<f-div
						v-for="item in classificationsList"
						:key="item.type"
						height="hug-content"
						border="small solid secondary bottom"
						padding="large"
						gap="small"
						align="top-left"
					>
						<f-div gap="large" align="middle-left" height="hug-content">
							<f-div align="middle-left" width="20%">
								<f-text
									inline
									variant="para"
									size="medium"
									weight="regular"
									state="secondary"
									align="left"
								>
									{{ item.name }}
								</f-text>
							</f-div>
							<f-div align="middle-left" padding="x-small none none none" gap="medium">
								<ComponentIcon
									v-if="item.type === 'service-icon'"
									:component="component"
									size="large"
								></ComponentIcon>
								<f-button
									v-else-if="item.type === 'jira-link' && getJiraBoardCode"
									:label="getJiraBoardCode"
									size="small"
									category="outline"
									variant="curved"
									weight="regular"
									icon-right="i-new-tab"
									state="primary"
									align="left"
									@click="openLink()"
								>
								</f-button>
								<f-text
									v-else
									inline
									class="capital-heading"
									variant="para"
									size="medium"
									weight="medium"
									state="default"
									align="left"
								>
									{{ item.value }}
								</f-text>

								<!--START: View on map -->
								<!-- Constellation map linking feature is broken right now -->
								<!-- <f-icon
									v-if="item.type === 'name-version'"
									source="i-tree"
									size="small"
									state="primary"
									@click="openConstellationMap"
								></f-icon> -->
								<!--END: View on map-->
							</f-div>
						</f-div>
					</f-div>
				</f-div>
			</f-div>

			<f-div
				width="350px"
				state="default"
				class="f-right-column"
				border="small solid secondary left"
			>
				<AssetArtifactRightPane :component="component" />
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import ComponentIcon from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentIcon.vue";
import AssetArtifactRightPane from "@/modules/release-cockpit-v2/components/details/AssetArtifactRightPane.vue";
import { getTaxonomiesList } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
// import { duplicateComponentDeeplinking } from "@/utils/component-deeplinking";

export default defineComponent({
	name: "AssetDetailTab",

	components: {
		AssetArtifactRightPane,
		ComponentIcon
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	computed: {
		classificationsList(): { type: string; name: string; value: string }[] {
			return [
				{
					type: "service-icon",
					name: "Asset icon",
					value: this.component.name!
				},
				{
					type: "name-version",
					name: "Name and version",
					value: `${this.component.name}  ${this.component.version}`
				},
				{
					type: "classification",
					name: "Classification",
					value: getTaxonomiesList(this.component)
						.filter(taxonomy => taxonomy)
						.join(" / ")
				},
				{
					type: "jira-link",
					name: "Jira issue",
					value: String(this.component.metadata?.jiralink)
				},
				{
					type: "justification",
					name: "Justification",
					value: String(this.component.metadata?.justification ?? "")
				},
				{
					type: "description",
					name: "Description",
					value: String(this.component.metadata?.description ?? "")
				}
			];
		},

		jiraLink() {
			return String(this.component.metadata?.jiralink);
		},

		getJiraBoardCode() {
			if (!this.jiraLink) {
				return "";
			}
			const urlParts = this.jiraLink.split("/");
			return urlParts[urlParts.length - 1] ?? "";
		}
	},

	methods: {
		// openConstellationMap() {
		// 	const queryParams = duplicateComponentDeeplinking(this.component);
		// 	this.$router.push({ name: "constellation-map", query: queryParams });
		// },

		openLink() {
			if (this.jiraLink) {
				window.open(this.jiraLink, "_blank");
			}
		}
	}
});
</script>
