<template>
	<AuthLayout :header="type">
		<AuthForm
			:api-error-msg="apiErrorMsg"
			:type="type"
			:is-submitting="isSubmitting"
			@submit="submitChangePasswordFlow"
		></AuthForm>
	</AuthLayout>
</template>

<script lang="ts">
import { RegistrationFlow, UiNode, UpdateSettingsFlowBody } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";

import { settingsStore } from "../../settings/settings-store";
import { CHANGE_PASSWORD } from "../auth-constants";
import { FormPayload } from "../auth-types";
import AuthLayout from "../views/AuthLayout.vue";

import AuthForm from "./AuthForm.vue";

export default defineComponent({
	name: "ChangePassword",

	components: {
		AuthLayout,
		AuthForm
	},

	data() {
		return {
			isSubmitting: false,
			settingsFlowid: "",
			apiErrorMsg: "",
			csrfToken: "",
			type: CHANGE_PASSWORD
		};
	},

	computed: {
		...mapStores(notificationsStore, settingsStore)
	},

	methods: {
		async createChangePasswordFlow() {
			//Create settings flow id
			const resp = await this.settingsStore.CREATE_SETTINGS_FLOW();
			this.settingsFlowid = resp?.data.id as string;
			const attributes = resp?.data.ui.nodes[0]?.attributes as { value: string };
			this.csrfToken = attributes.value;
		},

		async submitChangePasswordFlow(passwordPayload: FormPayload) {
			await this.createChangePasswordFlow();
			const body: UpdateSettingsFlowBody = {
				csrf_token: this.csrfToken,
				method: "password",
				password: passwordPayload.password.value
			};

			try {
				this.isSubmitting = true;
				await this.settingsStore.SUBMIT_SETTINGS_FLOW({
					flow: this.settingsFlowid,
					updateSettingsFlowBody: body
				});
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-change-password-success",
					title: `Password changed successfully`,
					text: "",
					status: "success"
				});
				this.$router.replace({ name: "login" });
			} catch (err) {
				const error = err as RegistrationFlow;
				const passwdnode = error.ui.nodes.find((node: UiNode) => node.messages.length > 0);
				this.apiErrorMsg =
					passwdnode?.messages[0]?.type === "error" ? passwdnode.messages[0]?.text : "";
				if (error.ui.messages && typeof error.ui.messages[0]?.text === "string") {
					this.apiErrorMsg = error.ui.messages[0]?.text;
				}
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
