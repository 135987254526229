<template>
	<FullPageLoader v-if="isLoading" />
	<f-div
		v-else-if="documents.length === 0 && hasComponentCatalogWriteAccess"
		align="middle-center"
		direction="column"
		gap="medium"
	>
		<f-icon source="i-alert-fill" size="large" state="danger"></f-icon>
		<f-text>No best practices found for onboarding.</f-text>
		<!-- Component level upload doesn't work -->
		<!--
		<f-icon source="i-document-add" size="large" state="primary"></f-icon>
		<f-text>Upload a best practice document for this component.</f-text>
		<f-div height="hug-content" width="100px">
			<f-file-upload
				placeholder="Upload"
				state="primary"
				size="small"
				file-type=".xlsx"
				:loading="isUploadingBestPractice"
				@input="uploadBestPractice"
			>
			</f-file-upload>
		</f-div> -->
		<f-text v-if="bestPracticeUploadError" state="danger">{{ bestPracticeUploadError }}</f-text>
	</f-div>
	<f-div v-else height="fill-container" width="fill-container" class="f-three-columns">
		<!--Start : left column-->
		<f-div
			width="320px"
			:data-open="isLeftOpen"
			border="small solid secondary right"
			state="default"
			class="f-left-column"
			overflow="hidden"
		>
			<OnboardingLeftPanel
				:component="component"
				:documents="documents"
				@collapse-panel="isLeftOpen = false"
			/>
		</f-div>

		<f-div
			v-if="!isLeftOpen"
			class="f-left-notch"
			height="76px"
			state="tertiary"
			width="hug-content"
			align="middle-center"
			clickable
			:data-open="isLeftOpen"
			@click="toggleLeftColumn"
		>
			<f-icon
				:source="isLeftOpen ? 'i-notch-left' : 'i-notch-right'"
				size="small"
				state="secondary"
			></f-icon>
		</f-div>
		<!--End : left column-->
		<!--Start : middle column-->
		<f-div direction="column">
			<f-div
				padding="small"
				direction="row"
				height="hug-content"
				gap="medium"
				overflow="wrap"
				align="middle-left"
				border="medium dashed subtle bottom"
			>
				<f-div
					direction="row"
					width="hug-content"
					gap="medium"
					height="hug-content"
					align="middle-center"
				>
					<f-div align="middle-center" width="250px">
						<f-select
							v-model="selectedTargetEnv"
							:height="300"
							size="small"
							:options="targetEnvOptions"
							placeholder="Select target environment"
						>
						</f-select>
						<f-icon-button
							data-qa="select-target-info-icon"
							icon="i-info-fill"
							category="transparent"
							state="neutral"
							tooltip="#tooltipTarget"
						></f-icon-button>
						<f-tooltip id="tooltipTarget" placement="bottom-start">
							<f-div direction="column" width="250px">
								<f-text variant="heading" weight="bold" size="small"
									>Select target environment</f-text
								>
								<f-text variant="para" size="small">
									You will have to fulfill the required statements applicable to this target
									environment.
								</f-text>
								<f-text variant="para" size="small">
									This component will be prepared specifically for this target environment.
								</f-text>
							</f-div></f-tooltip
						>
					</f-div>
				</f-div>
				<ComponentOnboardingSubHeader
					:component="component"
					:show-all-dropdown="true"
					@update-search-value="updateSearchValue"
					@update-evidence-filter="updateEvidenceFilter"
				></ComponentOnboardingSubHeader>
			</f-div>
			<OnboardingControlStatementList
				:component="component"
				:is-selection-enabled="false"
				:documents="statementGroupByDocument"
				:search-string="searchString"
				@view-statement="onStatementView"
			/>
		</f-div>

		<!--End : middle column-->
		<!--Start : right column-->
		<f-div
			v-if="hasComponentCatalogWriteAccess"
			width="320px"
			state="default"
			border="small solid secondary left"
			direction="column"
			overflow="scroll"
			show-scrollbar
		>
			<f-div
				state="success"
				padding="large medium"
				height="hug-content"
				direction="column"
				gap="medium"
			>
				<f-text
					>Write <f-text inline weight="bold">Terraform code</f-text> and
					<f-text inline weight="bold">Tests</f-text> against these controls.</f-text
				>

				<f-button
					icon-left="p-vscode"
					label="Open in VSCode"
					variant="curved"
					category="outline"
					@click="openInVScode"
				></f-button>
				<f-button
					disabled
					:icon-left="intellijLogo"
					label="Open in IntelliJ"
					variant="curved"
					category="outline"
				></f-button>
			</f-div>
			<!-- Terraform code -->
			<f-accordion
				body-padding="none"
				icon-placement="left"
				icon="plus"
				:open="!gitTerraformArtifact"
			>
				<f-div padding="none" align="middle-left">
					<f-text weight="medium"> Terraform module </f-text>
					<f-tag
						v-if="gitTerraformArtifact"
						label="Added"
						icon-left="i-tick"
						state="success"
						size="small"
						>Added</f-tag
					>
				</f-div>
				<f-div slot="body" direction="column" padding="none">
					<TerraformViewModuleComponent
						v-if="gitTerraformArtifact && !editTerraformModule"
						:component="component"
						:artifact-data="gitTerraformArtifact"
						:show-service-tab-git-artifacts="true"
						:show-edit="hasComponentCatalogWriteAccess"
						@edit-terraform-module="editTerraformModule = true"
					/>
					<TerraformFormComponent
						v-else
						:component="component"
						:artifact-data="gitTerraformArtifact ?? undefined"
						:title="`Add Terraform module for ${component.name} ${component.version}. This is required to run the automated tests.`"
						@artifact-created="editTerraformModule = false"
					/>
				</f-div>
			</f-accordion>

			<f-divider></f-divider>

			<!-- Terraform tests -->
			<f-accordion body-padding="none" icon-placement="left" icon="plus">
				<f-div padding="none">
					<f-text weight="medium">Tests for the terraform module</f-text>
					<f-tag
						v-if="testTerraformArtifact"
						label="Added"
						icon-left="i-tick"
						state="success"
						size="small"
						>Added</f-tag
					>
				</f-div>
				<f-div slot="body" direction="column" padding="none">
					<TerraformViewModuleComponent
						v-if="testTerraformArtifact && !editTestModule"
						:component="component"
						:artifact-data="testTerraformArtifact"
						:show-service-tab-git-artifacts="false"
						@edit-terraform-module="editTestModule = true"
					/>
					<TerraformFormComponent
						v-if="showEditTerraformModule"
						:component="component"
						:artifact-data="testTerraformArtifact ?? undefined"
						title="This test will be applied to all the statements"
						:is-test-repo-form="true"
						@artifact-created="editTestModule = false"
					/>
				</f-div>
			</f-accordion>
		</f-div>
		<!--End : right column-->
	</f-div>
</template>

<script lang="ts">
import { FSelectOptionObject } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import ComponentOnboardingSubHeader from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingSubHeader.vue";
import OnboardingControlStatementList from "@/modules/release-cockpit-v2/components/component-catalog-detail/OnboardingControlStatementList.vue";
import OnboardingLeftPanel from "@/modules/release-cockpit-v2/components/component-catalog-detail/OnboardingLeftPanel.vue";
import TerraformFormComponent from "@/modules/release-cockpit-v2/components/details/TerraformFormComponent.vue";
import TerraformViewModuleComponent from "@/modules/release-cockpit-v2/components/details/TerraformViewModuleComponent.vue";
import { getComponentClassificationLevels } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { addQueryParams } from "@/shared/append-query-parameter-in-url";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";
import { COMPONENT_STATUS, EVIDENCE_STATUS, MIN_DESKTOP_WIDTH } from "@/shared/constants";
import { captureError, getErrorMessage } from "@/utils";

type EnvironmentOption = FSelectOptionObject<{ id: string }>;

export default defineComponent({
	name: "ComponentOnboardingAutomatedTab",

	components: {
		OnboardingControlStatementList,
		TerraformViewModuleComponent,
		TerraformFormComponent,
		OnboardingLeftPanel,
		FullPageLoader,
		ComponentOnboardingSubHeader
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => ({
		isLeftOpen: true,
		editTerraformModule: false,
		editTestModule: false,
		isUploadingBestPractice: false,
		bestPracticeUploadError: null as string | null,
		selectedTargetEnv: null as EnvironmentOption | null,
		searchString: "" as string | "",
		evidenceFilterString: "" as string | ""
	}),

	computed: {
		...mapStores(componentOnboardStore, authStore),

		environments() {
			return this.componentOnboardStore.environments;
		},

		intellijLogo() {
			return `${window.location.protocol}//${window.location.host}/ui/intellij-logo.svg`;
		},

		showEditTerraformModule() {
			if (
				(this.hasComponentCatalogWriteAccess && !this.testTerraformArtifact) ||
				this.editTestModule
			) {
				return true;
			}
			return false;
		},

		hasComponentCatalogWriteAccess() {
			const { status } = this.component;

			return (
				Boolean(this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled) &&
				status !== COMPONENT_STATUS.deprecationPending &&
				status !== COMPONENT_STATUS.deprecated &&
				status !== COMPONENT_STATUS.notarised &&
				status !== COMPONENT_STATUS.published
			);
		},

		isLoading() {
			return this.componentOnboardStore.loadingComponent[this.component.id!];
		},

		gitTerraformArtifact() {
			const terraformGitRepo = this.component.artifact?.metadata?.terraformGitRepo;
			if (terraformGitRepo?.iac?.repo && this.component.artifact?.type === "GIT_REPO") {
				return terraformGitRepo;
			} else {
				return null;
			}
		},

		testTerraformArtifact() {
			const terraformGitRepo = this.component.artifact?.metadata?.terraformGitRepo;

			if (terraformGitRepo?.pac?.repo && this.component.artifact?.type === "GIT_REPO") {
				return terraformGitRepo;
			} else {
				return null;
			}
		},

		documents() {
			return this.componentOnboardStore.getAutomatedDocuments(this.component.id);
		},

		statements() {
			return this.componentOnboardStore.getAutomatedStatements(this.component.id);
		},

		filteredStatements() {
			const searchStr = this.searchString.toLocaleLowerCase().trim();
			if (searchStr.length < 3) {
				return this.statements;
			}

			return this.statements.filter(statement => {
				const statementText = statement.statement?.toLocaleLowerCase();
				return statementText?.includes(searchStr);
			});
		},

		statementGroupByDocument() {
			if (!this.component.id) {
				return [];
			}

			const allEvidences = this.componentOnboardStore.evidences[this.component.id] ?? {};

			return this.documents.map(document => {
				const statements = this.filteredStatements.filter(
					statement => statement.documentId === document.id
				);

				if (!this.evidenceFilterString.trim()) {
					return {
						document,
						statements
					};
				}

				return {
					document,
					statements: statements.filter(controlStatementVal => {
						const firstEvidence = allEvidences[document.id!]?.[controlStatementVal.id!]?.[0];
						const hasEvidences = Boolean(firstEvidence);
						const isEvidenceStatusMatchingFilter =
							firstEvidence?.status === this.evidenceFilterString;

						if (this.evidenceFilterString === EVIDENCE_STATUS.notAvailable) {
							return !hasEvidences;
						}

						return hasEvidences && isEvidenceStatusMatchingFilter;
					})
				};
			});
		},

		targetEnvOptions() {
			return this.environments.map(env => ({
				data: { id: env.controlGate?.id ?? "" },
				title: env.name ?? ""
			}));
		},

		currentTargetEnv() {
			const targetEnv = this.componentOnboardStore.targetEnvironment[this.component.id ?? ""];
			return this.targetEnvOptions.find(env => env.data.id === targetEnv);
		}
	},

	watch: {
		selectedTargetEnv() {
			const selectedEnvId = this.selectedTargetEnv?.data?.id;

			if (selectedEnvId) {
				this.componentOnboardStore.setTargetEnvironment(this.component.id, selectedEnvId);
			}
		},

		currentTargetEnv: {
			handler() {
				if (this.currentTargetEnv) {
					this.selectedTargetEnv = this.currentTargetEnv;
				}
			},

			immediate: true
		}
	},

	mounted() {
		const screenWidth = window.innerWidth;
		if (screenWidth < MIN_DESKTOP_WIDTH) {
			this.isLeftOpen = false;
		}
	},

	methods: {
		toggleLeftColumn() {
			this.isLeftOpen = !this.isLeftOpen;
		},

		openInVScode() {
			const url = addQueryParams(`vscode://ollion.floweng-vscode-plugin/vscode`, {
				componentId: this.component.id,
				componentName: `${this.component.name} ${this.component.version}`,
				classification: Object.values(getComponentClassificationLevels(this.component))
					.filter(Boolean)
					.join("/"),
				controlsLength: this.statements.length,
				selectedGate: this.selectedTargetEnv?.title ?? "",
				environmentId: this.selectedTargetEnv?.data?.id ?? "",
				baseURL: window.location.host
			});
			window.open(url, "_blank");
		},

		// todo: waiting for sachin to implement the batch API
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		async onStatementView(statement: { documentId: string; statementId: string }) {
			// await getLogForComponentControl({
			// 	componentId: this.component.id ?? "",
			// 	documentId: statement.documentId,
			// 	statementId: statement.statementId
			// });
		},

		// eslint-disable-next-line vue/no-unused-properties
		async uploadBestPractice(e: CustomEvent<{ value: File }>) {
			const file = e.detail.value;

			const formData = new FormData();
			formData.append("documentFiledata", file);
			formData.append("documentFilename", file.name);

			try {
				this.isUploadingBestPractice = true;

				await this.componentOnboardStore.fetchComponentOnboardingDocuments(this.component);
			} catch (err) {
				this.bestPracticeUploadError = getErrorMessage(err);
				captureError(err);
			} finally {
				this.isUploadingBestPractice = false;
			}
		},

		updateSearchValue(value: string) {
			this.searchString = value;
		},

		updateEvidenceFilter(value: string) {
			this.evidenceFilterString = value;
		}
	}
});
</script>

<style lang="scss">
.f-three-columns {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		transition: opacity 300ms linear;
		background-color: #000;
		opacity: 0;
		z-index: 2;
	}
}
.f-left-notch {
	margin-top: 12px;
	padding: 2px;
	border-radius: 0px 8px 8px 0px !important;
}
.f-right-notch {
	margin-top: 12px;
	padding: 2px;
	border-radius: 8px 0px 0px 8px !important;
}

.f-left-column,
.f-right-column {
	transition: width 300ms linear;

	&[data-open="false"] {
		width: 0px !important;
	}
}
@media (max-width: 1270px) {
	.f-three-columns {
		&[data-overlay="true"] {
			&::before {
				top: 0px;
				bottom: 0px;
				left: 0px;
				right: 0px;
				opacity: 0.6;
			}
		}
	}
	.f-left-column,
	.f-right-column {
		position: absolute;
		z-index: 2;
	}
	.f-left-notch,
	.f-right-notch {
		position: absolute;
		z-index: 2;
	}
	.f-left-notch {
		transition: left 300ms linear;
		left: 320px;
		&[data-open="false"] {
			left: 0px;
		}
	}
	.f-right-notch {
		transition: right 300ms linear;
		right: 320px;
		&[data-open="false"] {
			right: 0px;
		}
	}
	.f-right-column {
		right: 0px;
	}
}

#f-select-wrapper {
	border-bottom: none !important;
}
</style>
