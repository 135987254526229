import Fuse from "fuse.js";
import { defineStore } from "pinia";

import {
	getClass,
	getComponents,
	getDomain,
	getTBM,
	sendNewTaxonomyRequest
} from "@/modules/release-cockpit-v2/component-catalog-service";
import { getTaxonomiesList } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { Class, Domain, TBM } from "@/protocol/taxonomy";
import { COMPONENT_STATUS } from "@/shared/constants";

import { CLASS_ITEM } from "../taxonomy-table/taxonomy-types";

export type SearchableTaxon = {
	displayStr: string;
};

type MergedTaxonWithoutTBM = {
	taxonomy1?: string;
	taxonomy2?: string;
	taxonomy6?: string;
	taxonomy7?: string;
	taxonomy8?: string;
};

type TaxonSearchIndex = Record<string, TaxonSearchItem>;

export type TaxonSearchItem = {
	displayStr: string;
	mergedTaxon: MergedTaxonWithoutTBM;
};

type ComponentSearchItem = {
	displayStr: string;
} & Component;

export const componentCatalogStore = defineStore("componentCatalogStore", {
	state: () => ({
		components: [] as Component[],
		taxonomies: [] as CLASS_ITEM[],
		domainTaxons: [] as Domain[],
		tbmTaxons: [] as TBM[],
		classTaxons: [] as Class[]
	}),

	getters: {
		taxonomyFuse(): Fuse<TaxonSearchItem> {
			return new Fuse<TaxonSearchItem>(Object.values(this.searchIndex), {
				keys: [
					// Domain
					{ name: "mergedTaxon.taxonomy1", weight: 1 },
					{ name: "mergedTaxon.taxonomy2", weight: 2 },

					// TBM
					{ name: "mergedTaxon.taxonomy3", weight: 3 },
					{ name: "mergedTaxon.taxonomy4", weight: 4 },
					{ name: "mergedTaxon.taxonomy5", weight: 5 },

					// Class
					{ name: "mergedTaxon.taxonomy6", weight: 6 },
					{ name: "mergedTaxon.taxonomy7", weight: 7 },
					{ name: "mergedTaxon.taxonomy8", weight: 8 }
				],
				includeScore: true,
				shouldSort: true,
				minMatchCharLength: 3,
				threshold: 0.7
			});
		},

		//@todo - remove this after updating the search in onboard new component flow.
		duplicateTaxonomyFuse(): Fuse<TaxonSearchItem> {
			return new Fuse<TaxonSearchItem>(Object.values(this.searchIndex), {
				keys: [
					// Domain
					{ name: "mergedTaxon.taxonomy1", weight: 1 },
					{ name: "mergedTaxon.taxonomy2", weight: 2 },

					// TBM
					{ name: "mergedTaxon.taxonomy3", weight: 3 },
					{ name: "mergedTaxon.taxonomy4", weight: 4 },
					{ name: "mergedTaxon.taxonomy5", weight: 5 },

					// Class
					{ name: "mergedTaxon.taxonomy6", weight: 6 },
					{ name: "mergedTaxon.taxonomy7", weight: 7 },
					{ name: "mergedTaxon.taxonomy8", weight: 8 }
				],
				includeScore: true,
				shouldSort: true,
				threshold: 0.9
			});
		},

		componentFuse(): Fuse<ComponentSearchItem> {
			const components = this.components
				.filter(component => {
					if (
						component.status === COMPONENT_STATUS.deprecated ||
						component.status === COMPONENT_STATUS.nonCompliant ||
						component.status === COMPONENT_STATUS.classificationApprovalRejected
					) {
						return false;
					}
					return true;
				})
				.map(component => ({
					...component,
					displayStr: getTaxonomiesList(component).join(" ")
				}));

			return new Fuse<ComponentSearchItem>(components, {
				keys: [
					{ name: "name", weight: 9 },
					// { name: "displayStr", weight: 10 },
					// Domain
					{ name: "classification1", weight: 1 },
					{ name: "classification2", weight: 2 },

					// TBM
					{ name: "classification3", weight: 3 },
					{ name: "classification4", weight: 4 },
					{ name: "classification5", weight: 5 },

					// Class
					{ name: "classification6", weight: 6 },
					{ name: "classification7", weight: 7 },
					{ name: "classification8", weight: 8 }
				],
				includeScore: true,
				shouldSort: true,

				// High threshold to match most components if possible, we rank and sort them anyway
				threshold: 0.3
			});
		},

		searchIndex(state) {
			const searchIndex: TaxonSearchIndex = {};

			state.domainTaxons.forEach(taxon => {
				const displayStr = getTaxonomyString(taxon);
				searchIndex[displayStr] = {
					displayStr,
					mergedTaxon: taxon
				};
			});

			state.classTaxons.forEach(taxon => {
				state.domainTaxons.forEach(domainTaxon => {
					const displayStr = `${getTaxonomyString(domainTaxon)} / ${getTaxonomyString(taxon)}`;
					searchIndex[displayStr] = {
						displayStr,
						mergedTaxon: {
							...taxon,
							...domainTaxon
						}
					};
				});
			});

			return searchIndex;
		}
	},

	actions: {
		async fetchTaxonomies() {
			const [domains, tmbs, classes] = await Promise.all([getDomain(), getTBM(), getClass()]);

			if (domains.domains) {
				this.domainTaxons = domains.domains;
			}

			if (tmbs.TBMs) {
				this.tbmTaxons = tmbs.TBMs;
			}

			if (classes.classes) {
				this.classTaxons = classes.classes;
			}
		},

		async getComponents() {
			const response = await getComponents();
			if (!response.components?.length) {
				return [];
			}

			this.components = response.components;
		},

		async sendNewTaxonomyRequest(data: string) {
			const response = await sendNewTaxonomyRequest({
				message: data
			});
			if (response.status === 200) {
				return response.data;
			}
			throw response.data;
		}
	}
});

export function getTaxonomyString(item: Domain | TBM | Class) {
	const finalQuery: string[] = [];

	if ("taxonomy1" in item) {
		finalQuery.push(item.taxonomy1);
	}
	if ("taxonomy2" in item) {
		finalQuery.push(item.taxonomy2);
	}
	if ("taxonomy3" in item) {
		finalQuery.push(item.taxonomy3);
	}
	if ("taxonomy4" in item) {
		finalQuery.push(item.taxonomy4);
	}
	if ("taxonomy5" in item) {
		finalQuery.push(item.taxonomy5);
	}
	if ("taxonomy6" in item) {
		finalQuery.push(item.taxonomy6);
	}
	if ("taxonomy7" in item) {
		finalQuery.push(item.taxonomy7);
	}
	if ("taxonomy8" in item) {
		finalQuery.push(item.taxonomy8);
	}

	return finalQuery.join(" / ");
}
