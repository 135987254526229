export function downloadFile(fileContents: string, fileName: string) {
	const element = document.createElement("a");
	element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(fileContents)}`);
	element.setAttribute("download", fileName);
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}

export function downloadBase64File(fileContents: string, fileName: string) {
	const element = document.createElement("a");
	element.setAttribute("href", `data:application/octet-stream;base64,${fileContents}`);
	element.setAttribute("download", fileName);
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}
