<template>
	<f-div overflow="visible" direction="column" padding="large none none none" height="hug-content">
		<f-div v-for="(value, key) in listData" :key="key" direction="column" state="transparent">
			<f-div height="hug-content" padding="x-small" gap="auto" align="middle-left">
				<f-text variant="para" size="small" weight="regular">{{ key }}</f-text>
				<f-text variant="para" size="medium" weight="regular">{{ value }}</f-text>
			</f-div>
			<f-divider v-if="showDivider" size="medium"></f-divider>
		</f-div>
		<f-div v-if="!showDivider" direction="column" padding="large large none none">
			<f-divider size="medium"></f-divider
		></f-div>
	</f-div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "ControlTabsSection",

	props: {
		listData: {
			type: Object as PropType<object> | undefined,
			default: null
		},

		showDivider: {
			type: Boolean,
			default: true
		}
	}
});
</script>
