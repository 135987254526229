export interface DocumentType {
	title: string;
	data: {
		id: number;
		qaId: string;
	};
}
export type FileUploadFormValues = {
	file: File;
	country: CountryOptions[];
	companyName: string;
	companyLogo: File;
	cloudProvider: CountryOptions;
};
export const DEFAULT_COUNTRY_ITEM = {
	id: "",
	title: "Select Country"
};
export interface CountryOptions {
	id: string;
	title: string;
}

export const CLOUD_PROVIDER_OPTIONS = [
	{ id: "aws", title: "AWS" },
	{ id: "gcp", title: "GCP" }
];

export const DEFAULT_CLOUD_PROVIDER_OPTIONS = { id: "aws", title: "AWS" };

export const DEFAULT_ERROR_OBJECT = { status: false, message: "" };
export const ALLOWED_FILE_TYPES = [
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

export const DEFAULT_UPLOADED_FILE_OBJ = { type: "" as string, name: "" as string };

export interface LzComponentEvidences {
	id: string | null;
	name: string;
	cobitStatement: string;
	isExpired: string;
	neo4jImportId: number;
	status: string;
	location: string;
	type: string;
	approvalLocation: string;
	approvalType: string;
	componentId: string;
}
export interface LzComponentRegulatoryOrgStatements {
	name: string;
	statement: string;
	documentId: string;
	documentType: string;
	documentName: string;
	documentVersion: string;
}
export interface LzComponentActivities {
	Id: number;
	activity: string;
	name: string;
	regulatoryOrgStatements: LzComponentRegulatoryOrgStatements[];
}
export interface LzComponentStatements {
	id: number;
	name: string;
	statement: string;
	documentId: string;
	documentType: string;
	documentName: string;
	documentVersion: string;
	activities: LzComponentActivities[];
	evidences: LzComponentEvidences[];
}

export interface LzComponentBestPracticeStatements {
	id: number;
	name: string;
	documentType: string;
	publishedDate: string;
	statements: LzComponentStatements[];
}
export interface LzComponent {
	id: string | null;
	name: string;
	acronym: string;
	componentId: string;
	t8: {
		id: number;
		name: string;
		t7: {
			id: number;
			name: string;
			t6: {
				id: number;
				name: string;
				bestPractices: string | null;
			};
			bestPractices: string | null;
		};
		bestPractices: string | null;
	};
	t5: string | null;
	t2: {
		id: number;
		name: string;
		t1: {
			id: number;
			name: string;
			bestPractices: string | null;
		};
		bestPractices: string | null;
	};
	bestPractices: LzComponentBestPracticeStatements[];
}

export interface LzComponentReport {
	identifier: string;
	name: string;
	companyName?: string;
	companyLogo?: string;
	selectedCountries?: string;
	cloudProvider?: string;
	instances: [
		{
			id: string | null;
			identifier: string;
			name: string;
			deployedAt: null;
			serves: [
				{
					id: number;
					countryCode: string;
					name: string;
					regulatoryOrganisations: [
						{
							id: number;
							name: string;
							documents: [
								{
									id: number;
									name: string;
									documentType: string;
									publishedDate: string;
									statements: string | null;
								}
							];
						}
					];
				}
			];
			applicationAssembly: string | null;
		}
	];
	businessProcess: string | null;
	organisation: string | null;
	lobs: string | null;
	sublobs: string | null;
	departments: string | null;
	teams: string | null;
	components: LzComponent[];
	regulatoryOrganisationDocuments: Record<string, string>;
	bestPracticesApplied: Record<string, string>;
}
