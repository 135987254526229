import { App } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { authStore } from "../auth/auth-store";
import { UserPermissions } from "../auth/auth-types";
import { StorageService } from "../services/storage-service";
import { pinia } from "../store";

class RouterModule {
	router = createRouter({
		history: createWebHistory("ui"),
		routes: []
	});

	install(app: App<Element>) {
		this.registerBeforeEachHook();
		app.use(this.router);
	}

	isUserLoggedIn() {
		return Boolean(StorageService.getToken());
	}

	registerBeforeEachHook() {
		const authStoreInstance = authStore(pinia);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		this.router.beforeEach((to, _from) => {
			const isUserLoggedIn = this.isUserLoggedIn();
			// if user tries to access route without login
			if (to.meta.requiresAuth && !isUserLoggedIn) {
				return {
					name: "login"
				};
			} else if (to.meta.onlyWhenLoggedOut && isUserLoggedIn) {
				return {
					name: "authenticated"
				};
			}

			// if user is on login routes and already has session
			// if user tries to forbidden routes
			if (to.meta.onlyWhenLoggedOut && isUserLoggedIn) {
				const route = getAuthenticatedRoute(authStoreInstance.userPermissions);

				if (route) {
					return route;
				}
			}
		});
	}
}

// eslint-disable-next-line complexity
export function getAuthenticatedRoute(userPermissions: UserPermissions) {
	if (userPermissions["component-catalog-read"]?.enabled) {
		return {
			name: "component-catalog"
		};
	}
	if (userPermissions["compliance-report"]?.enabled) {
		return {
			name: "compliance-report"
		};
	}
	if (userPermissions["constellation-map"]?.enabled) {
		return {
			name: "constellation-map"
		};
	}
	if (userPermissions["document-ingest"]?.enabled) {
		return {
			name: "document-ingestion"
		};
	}
	if (userPermissions["document-mapping"]?.enabled) {
		return {
			name: "document-mapping"
		};
	}
	if (userPermissions["workflow-approve"]?.enabled) {
		return {
			name: "workflow"
		};
	}
	if (userPermissions["release-cockpit"]?.enabled) {
		return {
			name: "release-cockpit"
		};
	}
	if (userPermissions["taxonomy-table"]?.enabled) {
		return {
			name: "taxonomy-table"
		};
	}
	if (userPermissions["evidence-ui"]?.enabled) {
		return {
			name: "evidence-components"
		};
	}
	if (
		userPermissions["compliance-report"]?.enabled &&
		userPermissions["component-catalog-read"]?.enabled &&
		userPermissions["component-catalog-write"]?.enabled &&
		userPermissions["constellation-map"]?.enabled &&
		userPermissions["document-ingest"]?.enabled &&
		userPermissions["document-mapping"]?.enabled &&
		userPermissions["evidence-ui"]?.enabled &&
		userPermissions["release-cockpit"]?.enabled &&
		userPermissions["taxonomy-table"]?.enabled &&
		userPermissions["workflow-approve"]?.enabled
	) {
		return {
			name: "manage-members"
		};
	}
	if (userPermissions["lz-report"]?.enabled) {
		return {
			name: "lz-compliance-report"
		};
	}
}

export default new RouterModule();
