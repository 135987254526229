import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import { USER_PERMISSIONS } from "../auth/auth-types";

import DocumentIngestion from "./DocumentIngestion.vue";
import DocumentMapping from "./DocumentMapping.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/document-ingestion",
		name: "document-ingestion",
		component: DocumentIngestion,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.documentIngest
		} as RouteMeta,
		props: true
	},

	{
		path: "/draft-document/:documentId",
		name: "draft-document",
		component: DocumentMapping,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.documentMapping
		} as RouteMeta,
		props: true
	}
];

export function registerDocumentRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
