<template>
	<EmptyState
		v-if="documents.length === 0"
		:icon="{
			name: 'i-document',
			size: 'medium',
			state: 'light'
		}"
		message="No documents found for onboarding."
		data-qa="no-documents-found-error"
	/>
	<f-div v-else direction="column" width="320px">
		<f-div height="hug-content" padding="medium">
			<f-text variant="para" size="medium" weight="bold" align="left">Requirements</f-text>
			<f-icon-button
				icon="i-arrow-sm-left"
				size="small"
				variant="round"
				state="neutral"
				@click="onCollapse"
			/>
		</f-div>
		<f-div overflow="scroll" direction="column">
			<f-div
				v-for="(document, index) in documents"
				:key="document.id"
				height="hug-content"
				border="small solid secondary bottom"
				gap="small"
				align="middle-left"
				state="transparent"
				:class="index === documents.length - 1 ? 'margin-bt-40' : ''"
				clickable
			>
				<f-div gap="medium" width="fill-container" padding="medium" direction="column">
					<f-div gap="medium" width="fill-container" padding="none" height="hug-content">
						<f-div width="hug-content" align="middle-left" padding="none medium none none">
							<f-text variant="heading" weight="medium" size="small">{{ index + 1 }}</f-text>
						</f-div>
						<f-div>
							<f-text variant="para" weight="medium" size="medium">{{ document.name }}</f-text>
							<f-progress-bar variant="circle" size="small" :value="progressBar(document.id!)" />
						</f-div>
					</f-div>

					<f-div
						gap="medium"
						width="fill-container"
						padding="none none none x-large"
						height="hug-content"
					>
						<f-div width="hug-content" align="middle-left" padding="none none none x-small">
							<f-icon
								:source="documentLevelIcon(document.applicableTo ?? '')?.icon ?? 'i-domain'"
								state="subtle"
								size="x-small"
							></f-icon>
						</f-div>
						<f-div width="hug-content" align="middle-left" padding="none">
							<f-text variant="para" weight="regular" size="small" state="subtle">
								{{ toTitleCase(document.applicableValue ?? "") }}
							</f-text>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { ProgressBar, TaxonMappingIcons } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { Document } from "@/protocol/correlation";
import { toTitleCase } from "@/utils";

export default defineComponent({
	name: "OnboardingLeftPanel",

	components: {
		EmptyState
	},

	props: {
		documents: {
			type: Array as PropType<Document[]>,
			required: true
		},

		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["collapse-panel"],

	data() {
		return {
			toTitleCase
		};
	},

	computed: {
		...mapStores(componentOnboardStore),

		documentWiseProgress() {
			return this.componentOnboardStore.getDocumentWiseProgress(this.component.id);
		}
	},

	methods: {
		progressBar(documentId: string): ProgressBar {
			const progressPercentage = Number(
				this.documentWiseProgress.find(progress => progress.documentId === documentId)?.progress ??
					0
			);
			return `${progressPercentage}%`;
		},

		onCollapse() {
			this.$emit("collapse-panel", true);
		},

		documentLevelIcon(level: string) {
			return TaxonMappingIcons[level];
		}
	}
});
</script>
