import {
	getComponentClassificationLevels,
	COMPONENT_CLASSIFICATION_LEVELS
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { CLASSIFICATION_STRING } from "@/shared/constants";

export const componentDeeplinking = (component: Component) => {
	// Determine the number of classifications in the component
	const numClassifications = Object.keys(component).filter(key =>
		key.startsWith(CLASSIFICATION_STRING)
	).length;
	const types = ["Component"];
	const points = [component.name];
	// Populate the types and points arrays based on the component's classifications
	//TODO remove hardcoded 6. 6 here reprents taxonomy levels till L6 i.e Use taxnomy
	for (let i = numClassifications; i >= 6; i--) {
		const classificationValue = component[`classification${i}` as keyof Component];
		types.push(`T${i}`);
		points.push(String(classificationValue).toLowerCase());
	}
	return {
		type: types,
		point: points
	};
};

//TODO: remove it when refactoring the code
export const duplicateComponentDeeplinking = (component: Component) => {
	// Determine the number of classifications in the component
	const numClassifications = Object.keys(component).filter(key =>
		key.startsWith(CLASSIFICATION_STRING)
	).length;
	const types = ["Component"];
	const points = [component.name];
	// Populate the types and points arrays based on the component's classifications
	//TODO remove hardcoded 6. 6 here reprents taxonomy levels till L6 i.e Use taxnomy
	for (let i = numClassifications; i >= 6; i--) {
		const classificationValue =
			getComponentClassificationLevels(component)[
				`classification${i}` as keyof COMPONENT_CLASSIFICATION_LEVELS
			];
		types.push(`T${i}`);
		points.push(String(classificationValue).toLowerCase());
	}
	return {
		type: types,
		point: points
	};
};
