<template>
	<AuthLayout :header="type" show-back-icon @back-click="backToLogin">
		<f-div direction="row" gap="medium" overflow="hidden">
			<f-div width="50%">
				<f-input v-model="name.first" placeholder="Enter first name">
					<f-div slot="label" padding="none" gap="none">First name</f-div></f-input
				>
			</f-div>
			<f-div width="50%">
				<f-input v-model="name.last" placeholder="Enter last name">
					<f-div slot="label" padding="none" gap="none">Last name</f-div></f-input
				>
			</f-div>
		</f-div>
		<AuthForm
			:api-error-msg="apiErrorMsg"
			:type="type"
			:is-submitting="isSubmitting"
			@submit="submitSignupFlow"
			@submitoidc="submitSignupoidcFlow"
		></AuthForm>
	</AuthLayout>
</template>

<script lang="ts">
import {
	ContinueWithVerificationUi,
	RegistrationFlow,
	UiNode,
	UpdateRegistrationFlowBody
} from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";

import { SIGN_UP } from "../auth-constants";
import { authStore } from "../auth-store";
import { FormPayload, OIDCProvider } from "../auth-types";
import AuthLayout from "../views/AuthLayout.vue";

import AuthForm from "./AuthForm.vue";

export default defineComponent({
	name: "Signup",

	components: {
		AuthLayout,
		AuthForm
	},

	data() {
		return {
			isSubmitting: false,
			signupFlowid: "",
			apiErrorMsg: "",
			csrfToken: "",
			name: {
				first: "",
				last: ""
			},

			type: SIGN_UP
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore)
	},

	methods: {
		backToLogin() {
			this.$router.push({ name: "login" });
		},

		async createSignupFlow() {
			//Create signup flow id
			const resp = await this.authStore.CREATE_SIGNUP_FLOW();
			this.signupFlowid = resp?.data.id as string;
			const attributes = resp?.data.ui.nodes[0]?.attributes as { value: string };
			this.csrfToken = attributes.value;
		},

		async submitSignupFlow(signupPayload: FormPayload) {
			await this.createSignupFlow();
			const body: UpdateRegistrationFlowBody = {
				csrf_token: this.csrfToken,
				method: "password",
				password: signupPayload.password.value,
				traits: {
					email: signupPayload.email.value,
					name: this.name
				}
			};

			try {
				this.isSubmitting = true;
				const resp = await this.authStore.SUBMIT_SIGNUP_FLOW({
					flow: this.signupFlowid,
					updateRegistrationFlowBody: body
				});
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-signup",
					title: `Email sent successfully to ${signupPayload.email.value}`,
					text: "Please verify your account",
					status: "success"
				});
				if (resp.data.continue_with?.[0]?.action) {
					//Redirect to verification page
					const continue_with = resp.data.continue_with[0] as ContinueWithVerificationUi;
					this.$router.push({ name: "verification", query: { flow: continue_with.flow.id } });
				}
			} catch (err) {
				const error = err as RegistrationFlow;
				const passwdnode = error.ui.nodes.find((node: UiNode) => node.messages.length > 0);
				this.apiErrorMsg =
					passwdnode?.messages[0]?.type === "error" ? passwdnode.messages[0]?.text : "";
				if (error.ui.messages && typeof error.ui.messages[0]?.text === "string") {
					this.apiErrorMsg = error.ui.messages[0]?.text;
				}
			} finally {
				this.isSubmitting = false;
			}
		},

		async submitSignupoidcFlow(provider: OIDCProvider) {
			await this.createSignupFlow();
			const body: UpdateRegistrationFlowBody = {
				csrf_token: this.csrfToken,
				method: "oidc",
				provider
			};

			try {
				this.isSubmitting = true;
				await this.authStore.SUBMIT_SIGNUP_FLOW({
					flow: this.signupFlowid,
					updateRegistrationFlowBody: body
				});
			} catch (err) {
				const error = err as RegistrationFlow;
				const passwdnode = error.ui.nodes.find((node: UiNode) => node.messages.length > 0);
				this.apiErrorMsg =
					passwdnode?.messages[0]?.type === "error" ? passwdnode.messages[0]?.text : "";
				if (error.ui.messages && typeof error.ui.messages[0]?.text === "string") {
					this.apiErrorMsg = error.ui.messages[0]?.text;
				}
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
