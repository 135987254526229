<template>
	<f-div width="100%">
		<f-div>
			<f-tab class="margin-lt-20 margin-rt-20">
				<f-tab-node
					v-for="tab of tabNodes"
					:key="tab.id"
					:data-qa-tab="tab.name"
					:active="selected === tab.id"
					:content-id="String(tab.id)"
					@click="updateSelectedTabId(tab.id)"
					><f-div width="100%" height="100%" align="middle-center" direction="column">
						<f-div align="middle-center" height="hug-content" width="hug-content">
							<f-div padding="none" gap="small" align="middle-left"
								>{{ tab.name }}
								<f-counter v-if="tab.count" :label="tab.count"></f-counter>
							</f-div>
						</f-div>
					</f-div>
				</f-tab-node>
			</f-tab>
			<f-tab-content>
				<f-div direction="column" width="100%" padding="large">
					<ApplicationsTab v-if="selected === 0" />
					<GeographyTab v-else-if="selected === 1" />
					<OrgStructureTab v-else-if="selected === 2" />
					<TechnologyTab v-else-if="selected === 3" />
				</f-div>
			</f-tab-content>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { complianceReportStore } from "../compliance-report-store";
import { TabNodesObj } from "../compliance-report-utils";

import ApplicationsTab from "./ApplicationsTab.vue";
import GeographyTab from "./GeographyTab.vue";
import OrgStructureTab from "./OrgStructureTab.vue";
import TechnologyTab from "./TechnologyTab.vue";

export default defineComponent({
	name: "TabsContainer",

	components: {
		ApplicationsTab,
		GeographyTab,
		OrgStructureTab,
		TechnologyTab
	},

	emits: ["open-side-panel"],

	data() {
		return {
			selected: 0,
			tabNodes: [
				{ name: "Application", id: 0, count: 0 },
				{ name: "Geography", id: 1, count: 0 },
				{ name: "Org Structure", id: 2, count: 0 }
			] as Array<TabNodesObj & { count: number }>
		};
	},

	computed: {
		...mapStores(complianceReportStore)
	},

	methods: {
		updateSelectedTabId(tabId: number) {
			this.selected = tabId;
			this.$emit("open-side-panel", false);
			this.complianceReportStore.toggleSidePanel(false);
		}
	}
});
</script>
