export function getFeatureMap(): FeatureMapFull {
	return {
		SIMULATE_INT_PROD: {
			isEnabled: false,
			description: "Simulate the Ollion Internal Prod environment",
			ticket: ""
		},

		DOCUMENT_COMPARISON: {
			isEnabled: false,
			description: "Enable the document comparison feature",
			ticket: ""
		},

		DOCUMENT_EXPORT: {
			isEnabled: false,
			description: "Enable the draft document export feature",
			ticket: ""
		}
	};
}

export type ClientFeatureKey = "SIMULATE_INT_PROD" | "DOCUMENT_COMPARISON" | "DOCUMENT_EXPORT";

export type FeatureMapFull = Record<ClientFeatureKey, ClientFeatureConfig>;

export type ClientFeatureConfig = {
	isEnabled: boolean;
	description: string;
	ticket: string;
};
