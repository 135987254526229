<template>
	<AuthLayout header="Send Verification Email" show-back-icon @back-click="backToLogin">
		<AuthForm
			:api-error-msg="apiErrorMsg"
			:type="type"
			:is-submit-allowed="isSubmitAllowed"
			:is-submitting="isSubmitting"
			@submit="sendVerificationEmail"
		></AuthForm>
	</AuthLayout>
</template>

<script lang="ts">
import { UiNode, UiNodeInputAttributes, UpdateVerificationFlowBody } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { captureError } from "@/utils";

import { SEND_VERIFICATION_EMAIL } from "../auth-constants";
import { authStore } from "../auth-store";
import { FormPayload } from "../auth-types";
import AuthLayout from "../views/AuthLayout.vue";

import AuthForm from "./AuthForm.vue";

export default defineComponent({
	name: "SendVerificationEmail",

	components: {
		AuthLayout,
		AuthForm
	},

	data() {
		return {
			isSubmitting: false,
			emailFlowId: "",
			email: {
				value: "",
				state: "default",
				errorMsg: ""
			},

			apiErrorMsg: "",
			csrfToken: "",
			type: SEND_VERIFICATION_EMAIL
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore),

		isSubmitAllowed(): boolean {
			return this.email.state != "error";
		}
	},

	methods: {
		backToLogin() {
			this.$router.push({ name: "login" });
		},

		async createVerificationFlow() {
			//Create verification flow id
			const resp = await this.authStore.CREATE_VERIFICATION_FLOW();
			this.emailFlowId = resp?.data.id as string;
			const csrfTokenNode = resp?.data.ui.nodes.find(
				(node: UiNode) => (node.attributes as UiNodeInputAttributes).name === "csrf_token"
			);
			this.csrfToken = (csrfTokenNode?.attributes as UiNodeInputAttributes).value;
		},

		async sendVerificationEmail(payload: FormPayload) {
			await this.createVerificationFlow();

			const body: UpdateVerificationFlowBody = {
				csrf_token: this.csrfToken,
				method: "code",
				email: payload.email.value
			};
			try {
				this.isSubmitting = true;
				await this.authStore.SUBMIT_VERIFICATION_FLOW({
					flow: this.emailFlowId,
					updateVerificationFlowBody: body
				});

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Email sent successfully",
					text: "Please verify your account",
					status: "success"
				});
			} catch (error) {
				captureError(error);
				if (typeof error === "string") {
					this.apiErrorMsg = error;
				}
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
