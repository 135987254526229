import { createPinia, setMapStoreSuffix } from "pinia";

// completely remove the suffix: this.user, this.cart
setMapStoreSuffix("");

export const pinia = createPinia();
declare module "pinia" {
	export interface MapStoresCustomization {
		// set it to the same value as above
		suffix: "";
	}
}
