<template>
	<Container direction="column" align="stretch space-between" basis="100%" :padding="0" :gap="0">
		<Container id="dashboard-step-2" direction="column" align="stretch top" :padding="0" :gap="0">
			<SidebarItem v-for="menu in sidebarMenu" :key="menu.id">
				<Slab
					size="small"
					type="bottom-border"
					:selected="isActive(menu.routeName)"
					:effects="true"
					@click="selectMenu(menu)"
				>
					<Pictogram :effects="true" type="transparent">
						<f-div direction="column" gap="x-small" align="middle-center">
							<f-icon
								size="medium"
								:source="menu.icon"
								:tooltip="menu.name"
								type="filled"
								:data-qa-sidebar-tab-icon="menu.icon"
							></f-icon>
							<f-tag v-if="menu.isBeta" size="small" label="alpha" state="primary"></f-tag>
						</f-div>
					</Pictogram>
					<Typography type="p1" color="dark" overflow="nowrap"> {{ menu.name }} </Typography>
				</Slab>
			</SidebarItem>
		</Container>
		<Container id="dashboard-step-3" direction="column" align="stretch top" :padding="0" :gap="0">
			<SidebarItem v-for="menu in sidebarMenuBottom" :key="menu.id">
				<Slab
					size="small"
					type="bottom-border"
					:selected="isActive(menu.routeName)"
					:effects="true"
					@click="selectMenu(menu)"
				>
					<Pictogram :effects="true" type="transparent">
						<f-div direction="column" gap="x-small" align="middle-center">
							<f-icon
								size="medium"
								:source="menu.icon"
								:tooltip="menu.name"
								type="filled"
								:data-qa-sidebar-tab-icon="menu.icon"
							></f-icon>
							<f-tag v-if="menu.isBeta" size="small" label="alpha" state="primary"></f-tag>
						</f-div>
					</Pictogram>
					<Typography type="p1" color="dark" overflow="nowrap"> {{ menu.name }} </Typography>
				</Slab>
			</SidebarItem>
		</Container>
	</Container>
</template>
<script lang="ts">
import { Container, Pictogram, SidebarItem, Slab, Typography } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";

interface MenuItem {
	id: number;
	name: string;
	icon: string;
	routeName: string;
	isBeta?: boolean;
}

export default defineComponent({
	name: "Sidebar",

	components: {
		Pictogram,
		Container,
		Slab,
		Typography,
		SidebarItem
	},

	computed: {
		...mapStores(authStore, featureFlagStore),

		userPermissions() {
			return this.authStore.userPermissions;
		},

		sidebarMenuBottom(): MenuItem[] {
			const sidebarMenu: MenuItem[] = [];
			if (this.userPermissions["manage-members"]?.enabled) {
				sidebarMenu.push({
					id: 8,
					name: "Members",
					icon: "i-user-double",
					routeName: "manage-members"
				});
			}
			return sidebarMenu;
		},

		sidebarMenu(): MenuItem[] {
			const sidebarMenu: MenuItem[] = [];
			if (Object.keys(this.userPermissions).length === 0) {
				return sidebarMenu;
			}

			if (this.userPermissions["release-cockpit"]?.enabled) {
				sidebarMenu.push({
					id: 1,
					name: "Component Catalog",
					icon: "i-catalog",
					routeName: "component-catalog"
				});
			}
			if (this.userPermissions["constellation-map"]?.enabled) {
				sidebarMenu.push({
					id: 2,
					name: "Constellation Map",
					icon: "i-tree",
					routeName: "constellation-map"
				});
			}
			if (this.userPermissions["document-ingest"]?.enabled) {
				sidebarMenu.push({
					id: 3,
					name: "Document Ingestion",
					icon: "i-file",
					routeName: "document-ingestion"
				});
			}
			if (this.userPermissions["compliance-report"]?.enabled) {
				sidebarMenu.push({
					id: 51,
					name: "Compliance Report",
					icon: "i-text",
					routeName: "compliance-report"
				});
			}
			if (this.userPermissions["taxonomy-table"]?.enabled) {
				sidebarMenu.push({
					id: 6,
					name: "Taxonomy Table",
					icon: "i-column",
					routeName: "taxonomy-table"
				});
			}
			if (this.userPermissions["lz-report"]?.enabled) {
				sidebarMenu.push({
					id: 8,
					name: "Lz Compliance Report",
					icon: "i-document-report",
					routeName: "lz-compliance-report"
				});
			}
			if (this.featureFlagStore.featureMap.DOCUMENT_COMPARISON) {
				sidebarMenu.push({
					id: 9,
					name: "Document Comparison",
					icon: "i-document-duplicate",
					routeName: "document-comparison"
				});
			}

			return sidebarMenu;
		}
	},

	methods: {
		selectMenu(menu: { id: number; routeName: string }) {
			this.$router.replace({
				name: menu.routeName
			});
		},

		isActive(routeName: string) {
			const { path } = this.$route;
			const pathSegments = path.split("/").filter(segment => segment !== "");
			if (pathSegments.length === 0) {
				return false;
			}
			return pathSegments[0] === routeName;
		}
	}
});
</script>
