import { Configuration, ErrorBrowserLocationChangeRequired, FrontendApi } from "@ory/client";
import { AxiosError } from "axios";

import routerModule from "@/modules/router/router-module";
import { KRATOS_SERVICE_PATH } from "@/shared/service-paths";

import { notificationsStore } from "../notifications/notifications-store";
import { pinia } from "../store";

const notificationsStoreInstance = notificationsStore(pinia);

export const sdk = new FrontendApi(
	new Configuration({
		basePath: KRATOS_SERVICE_PATH,
		// we always want to include the cookies in each request
		// cookies are used for sessions and CSRF protection
		baseOptions: {
			withCredentials: true
		}
	})
);

type ServerError = {
	error?: {
		message: string;
		reason: string;
	};
	ui?: {
		messages: Array<{
			text: string;
		}>;
	};
};

export const handleAPIError = async (
	error: unknown,
	submitFlow?: { isSubmitFlow: boolean },
	redirectTo?: string
	// eslint-disable-next-line require-await
) => {
	const axiosError = error as AxiosError<ServerError>;
	const responseData = axiosError.response?.data;

	const errorTitle = responseData?.error?.message ?? "Something went wrong";
	const errorText = responseData?.error?.reason ?? "Please try again";

	switch (axiosError.response?.status) {
		case 400:
			// Handle 400 Invalid parameters
			// Show error message in component
			if (submitFlow?.isSubmitFlow) {
				return responseData;
			}

			//Show toast for logout token, check session, get flow, create flow
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-invalid-parameters",
				title: errorTitle,
				text: errorText,
				status: "error"
			});
			return Promise.resolve(null);
		case 401:
			// Handle 401 Unauthorized error
			if (!window.location.pathname.includes("login")) {
				routerModule.router.push({ name: "login" });
			}
			return Promise.resolve(null);
		case 404:
			// Handle 404 Forbidden error
			//Show toast for get flow responseData
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-forbidden",
				title: errorTitle,
				text: errorText,
				status: "error"
			});
			return Promise.resolve(null);
		case 410:
			// Handle 410 Flow expired
			// Show toast for get flow, login
			if (redirectTo) {
				routerModule.router.replace({ name: redirectTo });
				location.reload();
			}
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-flow-expired",
				title: "Flow ID expired",
				text: "Please try again",
				status: "error"
			});
			return Promise.resolve(null);
		case 422:
			//TODO - STA-624 Handle 422 browserlocation change required
			if (redirectTo) {
				// redirect to change password if forgot password flow
				routerModule.router.replace({ name: redirectTo });
			} else {
				// redirect to oidc provider(Google)
				const errorWithRedirect = responseData as ErrorBrowserLocationChangeRequired;
				if (errorWithRedirect.redirect_browser_to) {
					window.location.replace(errorWithRedirect.redirect_browser_to);
				}
			}
			return Promise.resolve(null);
		default:
			// Handle other errors
			// Show toast for logout token and check session
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-default-error",
				title: errorTitle,
				text: errorText,
				status: "error"
			});
			return Promise.resolve(null);
	}
};
