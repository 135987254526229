import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import Settings from "./Settings.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/settings",
		name: "settings",
		component: Settings,
		meta: {
			requiresAuth: true
		} as RouteMeta,
		props: true
	}
];

export function registerSettingsRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
