<template>
	<PermissionsWrapper>
		<f-div height="100%" overflow="hidden" state="default">
			<SettingsMenu @select-menu="selectMenu"></SettingsMenu>
			<ProfileSettings
				v-if="selectedMenu.menu === `profile-settings`"
				@submit="submitSettings"
			></ProfileSettings>
			<PasswordSettings
				v-if="selectedMenu.menu === `password-settings`"
				@submit="submitSettings"
			></PasswordSettings>
			<UserSessions v-if="selectedMenu.menu === `sessions`"></UserSessions>
		</f-div>
	</PermissionsWrapper>
</template>

<script lang="ts">
import {
	ContinueWithVerificationUi,
	SettingsFlow,
	UiNode,
	UpdateSettingsFlowBody
} from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";

import { authStore } from "../auth/auth-store";
import { notificationsStore } from "../notifications/notifications-store";

import PasswordSettings from "./components/PasswordSettings.vue";
import ProfileSettings from "./components/ProfileSettings.vue";
import SettingsMenu from "./components/SettingsMenu.vue";
import UserSessions from "./components/UserSessions.vue";
import { settingsStore } from "./settings-store";
import { Profile, SettingsMenuObject } from "./settings-types";

export default defineComponent({
	name: "Settings",
	components: { PermissionsWrapper, ProfileSettings, PasswordSettings, SettingsMenu, UserSessions },

	data() {
		return {
			selectedMenu: { menu: "profile-settings" } as SettingsMenuObject,
			settingsFlowid: "",
			csrfToken: "",
			isSubmitting: false,
			apiErrorMsg: ""
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore, settingsStore)
	},

	methods: {
		selectMenu(selected: SettingsMenuObject) {
			this.selectedMenu = selected;
		},

		async createSettingsFlow() {
			//Create settings flow id
			const resp = await this.settingsStore.CREATE_SETTINGS_FLOW();
			this.settingsFlowid = resp?.data.id as string;
			const attributes = resp?.data.ui.nodes[0]?.attributes as { value: string };
			this.csrfToken = attributes.value;
		},

		getSettingsRequestBody(profileData: Profile) {
			if (this.selectedMenu.menu === `profile-settings`) {
				return {
					csrf_token: this.csrfToken,
					method: "profile",
					traits: profileData
				};
			} else {
				return {
					csrf_token: this.csrfToken,
					method: "password",
					password: profileData.password ?? ""
				};
			}
		},

		async submitSettings(profileData: Profile) {
			await this.createSettingsFlow();
			const body = this.getSettingsRequestBody(profileData) as UpdateSettingsFlowBody;

			try {
				this.isSubmitting = true;
				const resp = await this.settingsStore.SUBMIT_SETTINGS_FLOW({
					flow: this.settingsFlowid,
					updateSettingsFlowBody: body
				});
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-change-password-success",
					title: resp.data.ui.messages?.[0]?.text ?? "Profile updated successfully",
					text: "",
					status: "success"
				});
				if (resp.data.continue_with?.[0]?.action) {
					//Creates logout token if session is present
					const logoutresp = await this.authStore.CREATE_LOGOUT_TOKEN();
					const continue_with = resp.data.continue_with[0] as ContinueWithVerificationUi;
					//Redirect to verification page
					if (logoutresp?.data.logout_token) {
						await this.authStore.LOGOUT_USER();
						this.$router.push({ name: "verification", query: { flow: continue_with.flow.id } });
					}
				}
			} catch (err) {
				const error = err as SettingsFlow;
				const passwdnode = error.ui.nodes.find((node: UiNode) => node.messages.length > 0);
				this.apiErrorMsg =
					passwdnode?.messages[0]?.type === "error" ? passwdnode.messages[0]?.text : "";
				if (error.ui.messages && typeof error.ui.messages[0]?.text === "string") {
					this.apiErrorMsg = error.ui.messages[0]?.text;
				}
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
