import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";

export const ENVIRONMENTS = {
	DEVELOPMENT: "DEVELOPMENT",
	PRODUCTION: "PRODUCTION",
	INT_PRODUCTION: "INT_PRODUCTION",
	STAGING: "STAGING"
};

// @todo - This information should come from provenance/deployment information
// for the time being it's hardcoded
export function getEnvironment(host = window.location.host) {
	if (host === "prod.stance.coauth.io") {
		return ENVIRONMENTS.PRODUCTION;
	} else if (host === "intprod.stance.coauth.io") {
		return ENVIRONMENTS.INT_PRODUCTION;
	} else if (host === "stage.stance.coauth.io") {
		return ENVIRONMENTS.STAGING;
	}

	return ENVIRONMENTS.DEVELOPMENT;
}

export function isProductionEnvironment() {
	return (
		getEnvironment() === ENVIRONMENTS.PRODUCTION || getEnvironment() === ENVIRONMENTS.INT_PRODUCTION
	);
}

export function isInternalProd() {
	const featureStoreInstance = featureFlagStore();

	// Used for testing int prod features by QA
	if (featureStoreInstance.featureMap.SIMULATE_INT_PROD) {
		return true;
	}

	return getEnvironment() === ENVIRONMENTS.INT_PRODUCTION;
}
