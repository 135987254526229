import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import DocumentComparison from "./DocumentComparison.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/document-comparison",
		name: "document-comparison",
		component: DocumentComparison,
		meta: {
			requiresAuth: false
		} as RouteMeta,
		props: true
	}
];

export function registerDocumentComparisonRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
