<template>
	<router-view v-if="noLayout" v-slot="{ Component }">
		<component :is="Component" />
	</router-view>
	<Layout
		v-else
		id="app"
		v-model:open-sidebar="expandSidebar"
		:data-theme="userTheme?.theme"
		class="home fc-dark"
		:mode="userTheme?.mode"
	>
		<template v-if="isUserLoggedIn" #sidebar>
			<Sidebar></Sidebar>
		</template>

		<template #sidebar-header>
			<Container
				:direction="expandSidebar ? 'row' : 'column'"
				:shrink="0"
				align="center top"
				:padding="expandSidebar ? '12px 12px 12px 0px' : '0px 12px 12px 12px'"
				:grow="1"
				:gap="8"
			>
			</Container>
		</template>

		<template #notification>
			<Container
				v-if="updatedVersion"
				data-qa-stance-version-update-notification
				align="left center"
				background="primary-dark"
				:grow="1"
			>
				<Icon size="small" name="i-info-fill" type="filled" state="white" :effects="false" />
				<Typography color="white"
					>An updated version of Stance (v{{ updatedVersion }}) is available, you can update by
					reloading this tab.
				</Typography>
				<Container align="right center" :padding="0" :grow="1">
					<Button size="small" data-qa-reload-page-on-update @click="reloadPage">
						<Typography color="white" weight="bold">RELOAD</Typography>
					</Button>
					<Icon
						size="small"
						name="i-close"
						state="white"
						data-qa-dismiss-update-dialog
						@click="updatedVersion = null"
					/>
				</Container>
			</Container>
		</template>

		<NotificationManager />
		<AppUpdateChecker @version-updated="updatedVersion = $event" />

		<div v-if="allowDebugMenu">
			<DebugMenu />
		</div>

		<template v-if="isUserLoggedIn" #navbar>
			<Navbar :logout-token="logoutToken"></Navbar>
		</template>

		<router-view v-slot="{ Component }">
			<component :is="Component" />
		</router-view>
	</Layout>
	<XPathInspector v-if="xPathInspectorState.isEnabled" />
</template>
<script lang="ts">
import { Button, Container, Icon, Layout, Typography } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineAsyncComponent, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import XPathInspector, { xPathInspectorState } from "@/modules/debug/XPathInspector.vue";
import NotificationManager from "@/modules/notifications/components/NotificationManager.vue";
import routerModule from "@/modules/router/router-module";
import { StorageService } from "@/modules/services/storage-service";
import { isProductionEnvironment } from "@/utils";

import AppUpdateChecker from "./AppUpdateChecker.vue";
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";

const DebugMenu = defineAsyncComponent(() => import("@/modules/debug/debug-menu.vue"));

export default defineComponent({
	name: "App",

	components: {
		Icon,
		Typography,
		Button,
		Navbar,
		Sidebar,
		Layout,
		Container,
		NotificationManager,
		DebugMenu,
		XPathInspector,
		AppUpdateChecker
	},

	data: () => ({
		allowDebugMenu: !isProductionEnvironment(),
		expandSidebar: false,
		logoutToken: "",
		xPathInspectorState,
		updatedVersion: null as null | string
	}),

	computed: {
		...mapStores(authStore),

		isUserLoggedIn() {
			return routerModule.isUserLoggedIn();
		},

		noLayout() {
			return this.$route.meta.noLayout;
		},

		userTheme() {
			return StorageService.getTheme();
		}
	},

	mounted() {
		this.checkSession();
	},

	methods: {
		async checkSession() {
			// Check if session is already present else redirect to login pageS
			if (this.$route.meta.onlyWhenLoggedOut) {
				return true;
			}
			await this.authStore.CHECK_SESSION();
		},

		reloadPage() {
			window.location.reload();
		}
	}
});
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
	transition: all 0.5s ease-in-out;
	transform: translateX(0px);
	opacity: 1;
	will-change: transform, opacity;
}
.fade-slide-enter-from {
	transform: translateX(20px);
	opacity: 0;
}

.fade-slide-leave-to {
	transform: translateX(-20px);
	opacity: 0;
}

// Allows hiding/showing icons in f-table-schema
f-table-schema::part(row):hover {
	--hide-icon: visible;
}
f-table-schema::part(row) {
	--hide-icon: hidden;
}

.show-on-hover-parent {
	&:hover {
		.show-on-hover {
			visibility: visible !important;
		}
	}
	.show-on-hover {
		visibility: hidden !important;
	}
}
</style>
