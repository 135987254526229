<template>
	<f-popover
		:target="`#${taggedListId}`"
		:open="openPopover"
		size="small"
		placement="bottom-start"
		:overlay="false"
		shadow
		:close-on-escape="true"
		@esc="onClose()"
		@overlay-click="onClose()"
	>
		<f-div direction="column" state="secondary">
			<f-div
				v-for="item in menuItem"
				:key="item.id"
				padding="medium"
				gap="small"
				:border="item.id === 'admin' ? 'small solid subtle top' : undefined"
			>
				<f-div
					data-id="menu-list-section-left"
					gap="medium"
					align="middle-left"
					width="hug-content"
				>
					<f-checkbox
						:value="selectedRoles.includes(item.label) ? 'checked' : 'unchecked'"
						:disabled="isSubmitting"
						@input="select(item.label)"
					>
					</f-checkbox>
					<f-text
						variant="para"
						size="medium"
						weight="regular"
						state="default"
						style="text-transform: capitalize"
					>
						{{ item.label.toLocaleLowerCase() }}
					</f-text>
					<f-icon
						v-if="isSubmitting && assigningRole === item.label"
						source="i-loading"
						size="x-small"
						:loading="true"
					></f-icon>
				</f-div>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { ProvidePermission } from "@/modules/user/user-service";
import { userStore } from "@/modules/user/user-store";
import { RoleTypesList, EligibleRolesForAdmin, isAdminRole } from "@/modules/user/user-types";
import { User } from "@/protocol/users";

export default defineComponent({
	name: "AssignRoles",

	props: {
		selectedUser: {
			type: Object as PropType<User>,
			required: true
		},

		openPopover: {
			type: Boolean,
			required: true
		},

		taggedListId: {
			type: String,
			required: true
		}
	},

	emits: ["close"],

	data: () => {
		return {
			selectedRoles: [] as string[],
			isSubmitting: false,
			assigningRole: ""
		};
	},

	computed: {
		...mapStores(userStore, notificationsStore),

		menuItem() {
			const menu = RoleTypesList.map(role => {
				return {
					id: role,
					label: role
				};
			});

			menu.push({
				id: "admin",
				label: "Admin"
			});
			return menu;
		}
	},

	watch: {
		selectedRoles: {
			handler(roles) {
				if (isAdminRole(roles) && !roles.includes("Admin")) {
					this.selectedRoles.push("Admin");
				} else if (!isAdminRole(roles) && roles.includes("Admin")) {
					this.selectedRoles.splice(roles.indexOf("Admin"), 1);
				}
			},

			deep: true,
			immediate: true
		}
	},

	mounted() {
		/* Set selected roles of user if already present */
		this.selectedRoles = this.selectedUser.role ?? [];
	},

	methods: {
		async select(roleType: string) {
			this.assigningRole = roleType;
			let updateRoles: string[] = [...this.selectedRoles];
			if (updateRoles.includes(roleType)) {
				/* Check if Role deselected as Admin then remove other roles as well */
				if (roleType === "Admin") {
					updateRoles = updateRoles.filter(role => {
						return ![...EligibleRolesForAdmin, roleType].includes(role);
					});
				} else {
					updateRoles.splice(updateRoles.indexOf(roleType), 1);
				}
			} else {
				/* Check if roleType is Admin than we need to check all relevant roles */
				if (roleType === "Admin") {
					updateRoles = [...EligibleRolesForAdmin, roleType];
				} else {
					updateRoles.push(roleType);
				}
			}

			try {
				this.isSubmitting = true;
				const apiPermissions = updateRoles;
				if (apiPermissions.includes("Admin")) {
					apiPermissions.splice(apiPermissions.indexOf("Admin"), 1);
				}
				const resp = await ProvidePermission({
					userId: this.selectedUser.id,
					permissions: apiPermissions
				});

				this.selectedRoles = updateRoles;

				/* Call updating role inside table */
				this.userStore.updateSelectedMemberRole(this.selectedUser, resp.roles);
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-recovery-email-sent",
					title: "Error in assigning role",
					text: error as string,
					status: "error"
				});
			} finally {
				this.isSubmitting = false;
				this.assigningRole = "";
			}
		},

		onClose() {
			this.$emit("close");
		}
	}
});
</script>
