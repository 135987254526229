<template>
	<f-div
		data-qa-component-card
		height="fill-container"
		direction="column"
		state="secondary"
		variant="curved"
		clickable
		@click="gotoComponentPage()"
	>
		<!-- Header -->
		<f-div
			:state="headerState"
			gap="medium"
			padding="small"
			align="middle-left"
			height="hug-content"
			border="small solid subtle bottom"
		>
			<f-div align="middle-left">
				<ComponentStatusBadge :component="selectedComponent" />
			</f-div>

			<f-text size="small" weight="medium" :inline="true" data-qa-component-type
				>{{ COMPONENT_TYPE_DISPLAY[selectedComponent.type ?? ""]?.text }}
			</f-text>

			<f-icon
				:source="COMPONENT_TYPE_DISPLAY[selectedComponent.type ?? '']?.icon ?? 'i-box'"
				size="x-small"
			></f-icon>

			<f-divider state="subtle"></f-divider>

			<f-icon
				:id="`view-more-${selectedComponent.id}`"
				:data-qa="`data-qa-view-more-${selectedComponent.id}`"
				source="i-more"
				size="medium"
				@click.stop="showMoreOptions = !showMoreOptions"
			></f-icon>
		</f-div>

		<!-- Body -->
		<f-div padding="medium" gap="medium" overflow="scroll" width="100%" align="middle-center">
			<ComponentIcon :component="component" size="x-large"></ComponentIcon>
			<!-- Name and date start -->
			<f-div
				direction="column"
				gap="x-small"
				overflow="visible"
				align="top-left"
				style="width: calc(100% - 104px)"
			>
				<f-div gap="small" height="hug-content">
					<f-div width="fill-container" align="middle-left" gap="small">
						<f-text
							variant="heading"
							size="medium"
							weight="bold"
							:tooltip="`${selectedComponent.name} ${selectedComponent.version}`"
							:inline="true"
							data-qa-component-name-version
							:ellipsis="true"
							>{{ selectedComponent.name }}
						</f-text>
						<f-tag
							size="small"
							category="outline"
							:label="`V ${selectedComponent.version}`"
						></f-tag>
					</f-div>
					<f-div width="hug-content" align="middle-right" style="min-width: 100px">
						<ComponentVersionSelect
							:selected-component="selectedComponent"
							:component="component"
							@on-version-select="onVersionSelect"
						/>
					</f-div>
				</f-div>
				<f-text
					variant="para"
					size="small"
					weight="regular"
					state="secondary"
					data-qa-component-created-date
				>
					{{ componentDateTimeString }}
				</f-text>
			</f-div>
		</f-div>

		<!-- Footer -->
		<f-div align="bottom-left">
			<f-div height="hug-content" border="small solid subtle top" padding="medium">
				<f-text
					ellipsis
					variant="para"
					size="small"
					weight="regular"
					state="subtle"
					data-qa-component-taxonomy
					:tooltip="componentClassification.tooltip"
				>
					{{ componentClassification.text }}
				</f-text>
			</f-div>
		</f-div>
	</f-div>
	<ViewMoreList
		v-if="showMoreOptions"
		:open-popover="showMoreOptions"
		:tagged-list-id="`view-more-${selectedComponent.id}`"
		:selected-component="component"
		@close="showMoreOptions = false"
	/>
</template>
<script lang="ts">
import { mapStores, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import ComponentIcon from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentIcon.vue";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_DISPLAY, COMPONENT_STATUS, ComponentStatus } from "@/shared/constants";
import { COMPONENT_TYPE_DISPLAY } from "@/shared/icons";
import { capitalize } from "@/utils";
import { formatDateString } from "@/utils/format-date";

import { componentCatalogStore } from "../../component-catalog-store";
import { componentOnboardStore } from "../../component-onboard-store";
import { getComponentClassificationLevels } from "../../release-cockpit-types";

import ComponentStatusBadge from "./ComponentStatusBadge.vue";
import ComponentVersionSelect from "./ComponentVersionSelect.vue";
import ViewMoreList from "./ViewMoreList.vue";

export default defineComponent({
	name: "ComponentCard",

	components: {
		ComponentVersionSelect,
		ComponentStatusBadge,
		ComponentIcon,
		ViewMoreList
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data() {
		return {
			COMPONENT_TYPE_DISPLAY,
			showMoreOptions: false,
			selectedComponent: {} as Component
		};
	},

	computed: {
		...mapStores(componentOnboardStore),
		...mapState(componentCatalogStore, ["components"]),

		headerState() {
			if (!this.componentStatus) {
				return "default";
			}

			return COMPONENT_DISPLAY[this.componentStatus].headerState;
		},

		componentStatus() {
			const statuses = Object.values(COMPONENT_STATUS);
			const { status, nonCompliant } = this.selectedComponent;
			if (nonCompliant) {
				return COMPONENT_STATUS.nonCompliant;
			}

			if (status && statuses.includes(status as ComponentStatus)) {
				return status as ComponentStatus | "";
			}
			return null;
		},

		componentDateTimeString() {
			const isPublished =
				this.componentStatus === COMPONENT_STATUS.published ||
				this.componentStatus === COMPONENT_STATUS.deprecated ||
				this.componentStatus === COMPONENT_STATUS.deprecationPending ||
				this.componentStatus === COMPONENT_STATUS.nonCompliant;

			return `${isPublished ? "Published" : "Created"} • ${this.getFormattedDate(String(this.selectedComponent.createdAt))}`;
		},

		componentClassification() {
			const tooltip = Object.values(getComponentClassificationLevels(this.selectedComponent))
				.map(value => String(value))
				.filter(value => value !== "")
				.join(" / ")
				.trim();

			if (!this.selectedComponent.classification1 && !this.selectedComponent.classification6) {
				return {
					tooltip: "Classification pending",
					text: "Classification pending"
				};
			}
			return {
				tooltip,
				text: `${capitalize(this.selectedComponent.classification1 ?? "")} / ${capitalize(this.selectedComponent.classification6 ?? "")}`
			};
		}
	},

	mounted() {
		this.selectedComponent = this.component;
	},

	methods: {
		getFormattedDate(date: string | undefined) {
			if (date) {
				return formatDateString(date);
			}
		},

		gotoComponentPage() {
			switch (this.componentStatus) {
				case COMPONENT_STATUS.classificationApprovalPending:
					this.$router.push({
						name: "component-classification-approval",
						params: { componentId: this.selectedComponent.id }
					});
					break;
				case COMPONENT_STATUS.classificationApprovalRejected:
				case COMPONENT_STATUS.draft:
					this.$router.push({
						name: "component-classification-approval",
						params: { componentId: this.selectedComponent.id }
					});
					break;
				default:
					this.$router.push({
						name: "component-onboarding-view",
						params: { componentId: this.selectedComponent.id }
					});
					break;
			}
		},

		onVersionSelect(selectedComponent: Component) {
			this.selectedComponent = selectedComponent;
		}
	}
});
</script>
