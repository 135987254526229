<template>
	<f-div height="100%" width="fill-container">
		<f-div align="middle-center" height="100%">
			<f-div
				direction="column"
				align="middle-center"
				gap="large"
				width="hug-content"
				height="hug-content"
			>
				<f-pictogram source="i-stop" variant="circle" size="x-large" state="danger"></f-pictogram>
				<f-div direction="column" gap="small" align="middle-center" width="233px">
					<f-text variant="heading" size="medium" weight="bold" align="center"
						>Page not found</f-text
					>
					<f-text variant="para" size="medium" weight="regular" align="center"
						>The requested page could not be found</f-text
					>
				</f-div>
				<f-div align="middle-center"> </f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "PageNotFound"
});
</script>
