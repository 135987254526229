<template>
	<f-accordion
		v-if="hasChildren"
		:key="`${node.name}`"
		:open="open"
		icon="caret"
		icon-placement="left"
		max-height="1000vh"
		header-padding="medium none medium medium"
		body-padding="none none none small"
		@click.stop="handleClick"
		@toggle.stop="onToggle"
	>
		<f-div gap="medium" align="middle-center">
			<f-icon :source="icon" size="x-small" state="default"></f-icon>
			<f-text variant="para" size="medium" weight="regular">{{ node.name }}</f-text>
		</f-div>
		<!-- Important: only render the children of the node if the accordion is open -->
		<f-div
			v-if="open"
			slot="body"
			width="100%"
			height="hug-content"
			direction="column"
			:state="open ? 'secondary' : 'default'"
			variant="curved"
		>
			<TaxonomyHierarchyTreeNode
				v-for="(child, key, idx) in node.children"
				:key="key"
				:node="child"
				:is-last-child="idx === Object.keys(node.children).length - 1"
				@node-clicked="handleChildClick"
			/>
		</f-div>
	</f-accordion>
	<f-div v-else direction="column" height="hug-content">
		<f-div
			direction="row"
			width="100%"
			:tooltip="`view ${node.name} component`"
			padding="none small none x-large"
			gap="small"
		>
			<f-divider class="accordion-template-divider" state="secondary"></f-divider>
			<f-div
				gap="small"
				padding="small"
				align="middle-left"
				height="hug-content"
				direction="row"
				overflow="scroll"
				clickable
				variant="curved"
				@click.stop="openComponent"
			>
				<ComponentIcon v-if="component" :component="component" size="small"></ComponentIcon>
				<f-text variant="heading" size="small" weight="medium" state="secondary" ellipsis>{{
					node.name
				}}</f-text>
				<f-tag size="small" category="outline" :label="componentVersion"></f-tag>
			</f-div>
		</f-div>
		<f-spacer v-if="isLastChild" size="small"></f-spacer>
	</f-div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import ComponentIcon from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentIcon.vue";
import { TaxonMappingIcons } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";

import { componentCatalogStore } from "../component-catalog-store";

import { TreeNode } from "./tree";

export default defineComponent({
	name: "TaxonomyHierarchyTreeNode",

	components: {
		ComponentIcon
	},

	props: {
		node: {
			type: Object as PropType<TreeNode>,
			required: true
		},

		isOpen: {
			type: Boolean,
			default: false
		},

		isLastChild: {
			type: Boolean,
			default: false
		}
	},

	emits: ["node-clicked"],

	data() {
		return {
			open: false
		};
	},

	computed: {
		...mapState(componentCatalogStore, ["components"]),

		hasChildren() {
			return Object.keys(this.node.children).length > 0;
		},

		componentId() {
			return this.node.componentId;
		},

		componentVersion() {
			return `V ${this.component?.version}`;
		},

		component(): Component | undefined {
			return this.components.find(component => component.id === this.componentId);
		},

		nodeInfo() {
			return {
				name: this.node.name,
				classificationLevel: this.node.classificationLevel,
				componentId: this.node.componentId
			};
		},

		icon() {
			const taxonomyId = `T${getLastNumericCharacter(this.node.classificationLevel)}`;
			const icon = TaxonMappingIcons[taxonomyId];
			if (icon) {
				return icon.icon;
			}
			return "i-box";
		}
	},

	watch: {
		isOpen: {
			handler(value: boolean) {
				this.open = value;
			},

			immediate: true
		}
	},

	methods: {
		openComponent() {
			this.$emit("node-clicked", [this.nodeInfo]);
		},

		handleClick() {
			if (!this.open) {
				return this.$emit("node-clicked", []);
			}
			this.$emit("node-clicked", [this.nodeInfo]);
		},

		handleChildClick(path: { name: string; classificationLevel: string }[]) {
			if (!this.open) {
				return this.$emit("node-clicked", [...path]);
			}
			this.$emit("node-clicked", [this.nodeInfo, ...path]);
		},

		onToggle() {
			this.open = !this.open;
		}
	}
});

const getLastNumericCharacter = (str: string): number | null => {
	const match = str.match(/\d+$/);
	return match ? parseInt(match[0], 10) : null;
};
</script>

<style scoped>
ul {
	list-style-type: none;
	padding-left: 20px;
}
</style>
