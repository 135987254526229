import axios, { CancelTokenSource } from "axios";

import routerModule from "@/modules/router/router-module";

import { notificationsStore } from "../notifications/notifications-store";
import { pinia } from "../store";

import { StorageService } from "./storage-service";

const notificationsStoreInstance = notificationsStore(pinia);

// Base axios instance
const axiosInstance = axios.create({
	baseURL: window.VUE_APP_API_BASE_URL,
	withCredentials: false,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json"
	}
});

// Add a response interceptor
// No need to handle Login, Signup APIs as they are never called through this axios instance
axiosInstance.interceptors.response.use(
	response => response,
	error => {
		if (error.response?.status === 401) {
			// Redirect the user to the login page
			StorageService.removeToken();
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-unauthorized",
				title: "Unauthorized",
				text: "Please log in to continue",
				status: "error"
			});
			routerModule.router.push({ name: "login" });
		}
		if (error.response?.status === 403) {
			notificationsStoreInstance.ADD_TOAST({
				qaId: "toast-ory-forbidden",
				title: "Access Denied",
				text: "You do not have access to the resource",
				status: "error"
			});
		}
		return Promise.reject(error);
	}
);

let cancelTokenSources: CancelTokenSource[] = [];

export const createCancelToken = () => {
	const cancelTokenSource = axios.CancelToken.source();
	cancelTokenSources.push(cancelTokenSource);
	return cancelTokenSource;
};

export const cancelAllRequests = () => {
	cancelTokenSources.forEach(source => source.cancel("Request canceled by user."));
	cancelTokenSources = [];
};

export default axiosInstance;

declare global {
	interface Window {
		VUE_APP_API_BASE_URL: string;

		VUE_APP_CORRELATION_SERVICE_PATH?: string;
		VUE_APP_EVIDENCE_SERVICE_PATH?: string;
		VUE_APP_COCKPIT_SERVICE_PATH?: string;
		VUE_APP_DOCUMENT_SERVICE_PATH?: string;
		VUE_APP_DOCUMENT_MAPPING_SERVICE_PATH?: string;
		VUE_APP_TAXONOMY_SERVICE_PATH?: string;
		VUE_APP_KRATOS_SERVICE_PATH?: string;
		VUE_APP_USER_SERVICE_PATH?: string;
	}
}
