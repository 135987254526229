<template>
	<f-div overflow="scroll" state="default">
		<f-div padding="none" direction="column" gap="small" height="hug-content">
			<f-div
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="secondary"
				height="hug-content"
				border="medium solid subtle bottom"
				overflow="visible"
				align="middle-center"
			>
				<f-div align="middle-left" width="hug-content" padding="none">
					<f-text size="medium" variant="para" state="secondary" weight="bold"> Password </f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						data-qa="password-settings-submit-password"
						label="Save"
						state="primary"
						variant="round"
						size="medium"
						category="outline"
						:disabled="!isSubmitAllowed"
						@click.stop="submitPassword()"
					></f-button>
				</f-div>
			</f-div>
			<f-div direction="column" padding="large">
				<f-div direction="column" gap="medium" padding="medium">
					<f-div
						border="small dashed secondary bottom"
						gap="medium"
						padding="large none medium none"
						align="middle-left"
					>
						<f-icon size="medium" source="i-key"></f-icon>
						<f-text variant="heading" size="small" weight="medium">Change Password</f-text>
					</f-div>
					<f-div padding="medium" gap="medium">
						<f-div direction="column" gap="x-small" width="30%">
							<f-input
								v-model="password.value"
								data-qa="password-settings-new-password"
								:state="password.state"
								type="password"
								placeholder="Enter new password"
								@keyup="validatePassword()"
							>
								<f-div slot="label" padding="none" gap="none">New password</f-div>
								<f-text slot="help" variant="para" size="small">{{ password.errorMsg }}</f-text>
							</f-input>
						</f-div>
					</f-div>
					<f-div padding="medium" gap="medium">
						<f-div direction="column" gap="x-small" width="30%">
							<f-input
								v-model="confirmPassword.value"
								:state="confirmPassword.state"
								data-qa="password-settings-confirm-password"
								type="password"
								placeholder="Confirm new password"
								@keyup="matchPassword()"
							>
								<f-div slot="label" padding="none" gap="none">Confirm new password</f-div>
								<f-text slot="help" variant="para" size="small">{{
									confirmPassword.errorMsg
								}}</f-text>
							</f-input>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FInputState } from "@ollion/flow-core";
import { defineComponent } from "vue";

import { PASSWORD_LENGTH } from "@/shared/constants";

export default defineComponent({
	name: "PasswordSettings",

	data() {
		return {
			password: {
				value: "",
				state: "default" as FInputState,
				errorMsg: ""
			},

			confirmPassword: {
				value: "",
				state: "default" as FInputState,
				errorMsg: ""
			}
		};
	},

	computed: {
		isSubmitAllowed() {
			return (
				this.password.value &&
				this.confirmPassword.value &&
				!this.password.errorMsg &&
				!this.confirmPassword.errorMsg &&
				this.password.value === this.confirmPassword.value
			);
		}
	},

	methods: {
		submitPassword() {
			this.$emit("submit", { password: this.password.value });
		},

		validatePassword() {
			this.password.state = "danger"; // set error state by default
			if (this.password.value === "") {
				this.password.errorMsg = "The Password field is required";
			} else if (this.password.value.length < PASSWORD_LENGTH) {
				this.password.errorMsg = "The Password field must be at least 8 characters long";
			} else {
				this.password.errorMsg = "";
				this.password.state = "default";
			}
		},

		matchPassword() {
			this.confirmPassword.state = "danger";
			if (this.password.value !== this.confirmPassword.value) {
				this.confirmPassword.errorMsg = "The Password does not match";
			} else {
				this.confirmPassword.errorMsg = "";
				this.confirmPassword.state = "default";
			}
		}
	}
});
</script>
