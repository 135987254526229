export function compareComponentVersion(v1?: string | number, v2?: string | number) {
	const regex = /(\d+)(?:\.(\d+))?(?:\.(\d+))?(-[\da-z-]+)?(\+[\da-z-]+)?/i;

	const version1 = String(v1);
	const version2 = String(v2);

	// Attempt to parse the versions
	const match1 = version1.match(regex);
	const match2 = version2.match(regex);

	// Handle cases where either version is invalid by assigning lowest precedence to invalid versions
	if (!match1 && !match2) {
		return 0;
	} // Both versions are invalid and considered equal
	if (!match1) {
		return -1;
	} // First version is invalid, consider it less
	if (!match2) {
		return 1;
	} // Second version is invalid, consider it less

	// Parse the version numbers from the regex matches
	const nums1 = match1.slice(1, 4).map(num => parseInt(num, 10) || 0);
	const nums2 = match2.slice(1, 4).map(num => parseInt(num, 10) || 0);

	// Compare major, minor, and patch numbers
	for (let i = 0; i < 3; i++) {
		if ((nums1[i] ?? 0) > (nums2[i] ?? 0)) {
			return 1;
		}
		if ((nums1[i] ?? 0) < (nums2[i] ?? 0)) {
			return -1;
		}
	}

	// Pre-release comparison
	if (match1[4] && !match2[4]) {
		return -1;
	} // v1 is a pre-release, v2 is not
	if (!match1[4] && match2[4]) {
		return 1;
	} // v2 is a pre-release, v1 is not
	if (match1[4] && match2[4]) {
		return match1[4].localeCompare(match2[4]); // Compare pre-release versions lexicographically
	}

	return 0; // Versions are the same
}
