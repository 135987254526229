<template>
	<f-div
		v-if="badge"
		width="hug-content"
		gap="small"
		align="middle-right"
		state="tertiary"
		variant="curved"
		padding="none small none none"
		:data-qa-artifact-status="badge.statusText"
	>
		<f-div width="hug-content" align="middle-center" :state="badge.headerState" padding="x-small">
			<f-icon :source="badge.headerIcon.icon" size="x-small" state="custom, white"></f-icon>
		</f-div>
		<f-text size="small" weight="medium">{{ badge.statusText }}</f-text>
	</f-div>
</template>

<script lang="ts">
import { FDivStateProp } from "@ollion/flow-core";
import { defineComponent, PropType } from "vue";

import { Component, ComponentType } from "@/protocol/cockpit";
import {
	ComponentStatus,
	COMPONENT_DISPLAY,
	COMPONENT_STATUS,
	getIconFromEnvironment
} from "@/shared/constants";
import { ComponentStatusIcon } from "@/shared/icons";

export type HeaderStateType = {
	headerState: FDivStateProp;
	headerIcon: ComponentStatusIcon;
	statusText: string;
};

export default defineComponent({
	name: "ComponentStatusBadge",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	computed: {
		componentStatus(): ComponentStatus {
			const statuses = Object.values(COMPONENT_STATUS);
			const { status, nonCompliant } = this.component;
			if (nonCompliant) {
				return COMPONENT_STATUS.nonCompliant;
			}

			if (status && statuses.includes(status as ComponentStatus)) {
				return status as ComponentStatus;
			}
			return "" as ComponentStatus;
		},

		publishedComponentBadge(): HeaderStateType | null {
			const allowedEnvs = this.component.allowedEnvironment ?? [];
			const lastEnv = allowedEnvs[allowedEnvs.length - 1];

			if (lastEnv && this.isComponentPublished && this.isComponentServiceType) {
				return {
					headerState: COMPONENT_DISPLAY[this.componentStatus].state,
					headerIcon: {
						icon: getIconFromEnvironment(lastEnv),
						color: COMPONENT_DISPLAY[this.componentStatus].icon.color
					},

					statusText: lastEnv
				};
			}

			return null;
		},

		isComponentServiceType() {
			return this.component.type === ComponentType.SERVICE;
		},

		isComponentPublished() {
			return this.componentStatus === COMPONENT_STATUS.published;
		},

		badge(): HeaderStateType | null {
			const componentBadge = COMPONENT_DISPLAY[this.componentStatus];

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (!componentBadge) {
				return null;
			}

			if (this.isComponentPublished && this.isComponentServiceType) {
				return this.publishedComponentBadge;
			}

			return {
				headerState: componentBadge.state,
				headerIcon: componentBadge.icon,
				statusText: componentBadge.text
			};
		}
	}
});
</script>

<style>
[data-qa-artifact-status] {
	border-radius: 4px !important;
}
</style>
