<template>
	<f-div gap="medium">
		<f-div v-if="componentLoading" padding="small" width="100%" gap="large" loading="skeleton">
			<f-div
				v-for="i of 7"
				:key="i"
				padding="medium"
				direction="column"
				state="secondary"
				variant="curved"
			>
				<f-div padding="none none small none" gap="small">
					<f-icon-button icon="i-plus" size="x-small"></f-icon-button>
					<f-text ellipsis variant="heading" size="small" state="secondary">Name</f-text>
				</f-div>
				<f-div padding="x-small" gap="none" direction="column">
					<f-div>
						<f-text variant="para" size="medium">count </f-text>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
		<f-div
			v-for="(item, i) in applicationCards"
			:key="i"
			direction="column"
			align="top-left"
			height="hug-content"
			width="fill-container"
			style="max-width: 174px; min-width: 150px"
			:state="selectedApplicationCard === item.id ? 'tertiary' : 'secondary'"
			padding="none"
			gap="small"
			variant="curved"
			:data-qa="`technology-cards-${item.id}`"
			@click="openDetails(item)"
		>
			<f-div padding="medium" direction="column">
				<f-div padding="none none small none" gap="small">
					<f-icon-button
						:icon="item.icon"
						:category="item.state === 'success' ? 'fill' : 'transparent'"
						:state="item.state"
						size="x-small"
						:data-qa="`application-card-icon-${item.state}`"
					></f-icon-button>
					<f-text
						ellipsis
						variant="heading"
						size="small"
						state="secondary"
						:data-qa-application-cards-title="item.id"
						>{{ item.name }}</f-text
					>
				</f-div>
				<f-div padding="x-small" gap="none" direction="column">
					<f-div>
						<f-text variant="para" size="medium">{{ item.count }} </f-text>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { mapStores, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import { captureError } from "@/utils";

import {
	getComponentByApplicationId,
	getControlCountByComponentIds
} from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import {
	ApplicationOptions,
	DashboardComponent,
	EvidenceDashboardComponent,
	DASHBOARD_INIT_COMPONENT
} from "../compliance-report-utils";

export default defineComponent({
	name: "TechnologyCardComponents",

	props: {
		applicationId: {
			type: String,
			required: true
		},

		appOptions: {
			type: Array as PropType<ApplicationOptions[]>,
			required: true
		}
	},

	emits: ["open-side-panel"],

	data: () => {
		return {
			selectedApplicationCard: 0 as number,
			applicationCards: [] as DashboardComponent[],
			overviewCards: DASHBOARD_INIT_COMPONENT,
			componentLoading: true
		};
	},

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			showSidePanel: "showSidePanel"
		})
	},

	mounted() {
		if (this.applicationId) {
			this.getComponentsList();
		}
	},

	methods: {
		async getComponentsList() {
			try {
				this.componentLoading = true;
				const response = await getComponentByApplicationId({ applicationId: this.applicationId });
				const { components } = response;
				if (components?.length) {
					const componentIds: string[] = components
						.map(component => component.id ?? "")
						.filter(Boolean);

					const controlsCountResponse = await getControlCountByComponentIds({ componentIds });
					const controlsCountMap = new Map<string, number>();
					controlsCountResponse.componentControlsCount?.forEach(controlCount => {
						controlsCountMap.set(
							controlCount.componentId ?? "",
							Number(controlCount.controlsCount)
						);
					});
					this.applicationCards = components.map((comp, index) => {
						const controlCount = controlsCountMap.get(comp.id ?? "") ?? 0;
						return {
							id: index + 1,
							compId: comp.id ?? "",
							name: comp.acronym ?? "",
							count: controlCount,
							icon: "i-tick",
							state: "success"
						};
					});
					this.componentLoading = false;
				}
			} catch (error) {
				captureError(error);
				this.applicationCards = [];
				this.componentLoading = false;
			}
		},

		openDetails(item: DashboardComponent) {
			this.selectedApplicationCard = item.id as number;
			this.complianceReportStore.toggleSidePanel(true);

			const selectedCard: EvidenceDashboardComponent = {
				component: this.overviewCards.find(comp => comp.id === item.id),
				application: this.appOptions.find(app => app.id === this.applicationId),
				overviewCard: item
			};
			this.complianceReportStore.setSelectedComponent(selectedCard);
		}
	}
});
</script>
