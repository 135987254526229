import { defineStore } from "pinia";

import { LinkDocumentRequest } from "@/protocol/correlation";
import { Document, Statement } from "@/protocol/document";
import { captureError } from "@/utils";

import {
	linkTaxonomyToDocument,
	listDocument,
	listStatementByDocumentId
} from "./document-ingestion-service";

export const documentIngestionStore = defineStore("documentIngestionStore", {
	state() {
		return {
			documents: [] as Document[],
			statements: {} as Record<string, Statement[]>
		};
	},

	actions: {
		async FETCH_DOCUMENTS() {
			try {
				const docResponse = await listDocument({});
				this.documents = docResponse.documents ?? [];
			} catch (err) {
				captureError(err);
			}
		},

		async FETCH_STATEMENTS(documentId: string) {
			const statements = (
				await listStatementByDocumentId({
					documentId
				})
			).statement;

			if (statements) {
				this.statements[documentId] = statements;
			}
		},

		async LINK_TAXONOMY(documentId: string, taxonomies: LinkDocumentRequest["taxonomy"]) {
			return await linkTaxonomyToDocument({
				documentId,
				// Backend needs the highest taxon to be sent first
				taxonomy: taxonomies?.sort((taxonomyA, taxonomyB) =>
					taxonomyB.taxonomyLevel && taxonomyA.taxonomyLevel
						? taxonomyB.taxonomyLevel - taxonomyA.taxonomyLevel
						: 0
				)
			});
		}
	}
});
