<template>
	<f-popover
		:open="openPopover"
		size="custom(864px, 80vh)"
		position="auto"
		:close-on-escape="isFormTouched ? undefined : true"
		@overlay-click="closeModal"
		@esc="closeModal"
		@close="closeModal"
	>
		<f-div
			state="secondary"
			direction="column"
			overflow="hidden"
			align="top-left"
			height="fill-container"
			width="fill-container"
		>
			<!-- Header -->
			<f-div
				height="hug-content"
				padding="small medium small large"
				border="small solid subtle bottom"
				align="middle-left"
				width="fill-container"
			>
				<f-text variant="para" size="medium" state="default" weight="bold"
					>Onboard new {{ modalTitle }}</f-text
				>
				<f-icon-button
					icon="i-close"
					variant="block"
					categroy="packed"
					size="small"
					state="inherit"
					@click="closeModal"
				></f-icon-button>
			</f-div>
			<!-- End Header -->

			<!-- error -->
			<f-div
				v-if="errorMsg"
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="danger"
				height="hug-content"
				border="small solid subtle bottom"
				overflow="visible"
			>
				<f-div align="middle-left" width="hug-content" gap="small">
					<f-icon source="i-alert" size="small" state="default"></f-icon>
					<f-text variant="para" size="small" weight="regular" state="default" align="left">
						{{ errorMsg }}
					</f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						label="CLOSE"
						state="warning"
						variant="round"
						category="outline"
						size="x-small"
						data-qa="onboard-reset-yes-button"
						@click="errorMsg = null"
					></f-button>
				</f-div>
			</f-div>
			<!-- error end -->
			<!-- Warning -->
			<f-div
				v-if="enableWarningMsg"
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="warning"
				height="hug-content"
				border="small solid subtle bottom"
				overflow="visible"
			>
				<f-div align="middle-left" width="hug-content" gap="small">
					<f-icon source="i-alert" size="small" state="default"></f-icon>
					<f-text variant="para" size="small" weight="regular" state="default" align="left">
						The changes you made will be lost if you navigate away. Are you sure?
					</f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						label="NO, STAY"
						state="warning"
						variant="round"
						category="outline"
						size="x-small"
						data-qa="onboard-reset-no-button"
						@click="() => (enableWarningMsg = false)"
					></f-button>
					<f-button
						label="YES, CLOSE"
						state="warning"
						variant="round"
						category="outline"
						size="x-small"
						data-qa="onboard-reset-yes-button"
						@click="$emit('close')"
					></f-button>
				</f-div>
			</f-div>
			<!-- END Warning-->

			<!-- Body -->
			<f-div
				padding="large"
				direction="column"
				gap="large"
				overflow="scroll"
				align="top-left"
				height="fill-container"
			>
				<f-div
					height="hug-content"
					gap="medium"
					overflow="hidden"
					direction="column"
					padding="none none medium none"
					border="small dashed subtle bottom"
				>
					<f-text weight="bold" size="medium"
						>What {{ selectedComponentType.toLocaleLowerCase() }} would you like to onboard?
					</f-text>
					<f-form-builder
						gap="small"
						size="small"
						:field.prop="serviceDetailFormFields"
						:values.prop="classificationFormValues"
						@input="classificationFormValues = { ...$event.detail }"
						@state-change="formState.serviceDetail = $event.detail"
					/>
				</f-div>

				<f-div gap="none">
					<f-div
						direction="column"
						gap="small"
						:padding="showPossibleMatches ? 'none large none none' : 'none'"
						:border="showPossibleMatches ? 'small solid subtle right' : undefined"
					>
						<f-div height="hug-content">
							<f-form-builder
								gap="small"
								size="small"
								:field.prop="classificationSearchFormField"
								:values.prop="classificationSearchFormValues"
								@input="handleSearch"
							></f-form-builder>
						</f-div>
						<f-div>
							<f-div v-if="!showClassificationTaxonForm" direction="column" align="middle-center">
								<f-icon source="i-search" size="medium" state="subtle"></f-icon>
								<f-spacer></f-spacer>
								<f-text state="subtle">Search for classification above to begin.</f-text>
								<f-text state="subtle"
									>Or
									<f-text inline @click="showClassificationTaxonForm = true"
										><a>classify manually</a></f-text
									>.</f-text
								>
								<template v-if="showAssetSlackChannelInfo">
									<f-spacer></f-spacer>
									<f-text state="subtle">Unable to find the classification you need?</f-text>
									<f-div height="hug-content" align="middle-center" gap="small">
										<f-text state="subtle" inline>Request it on</f-text>
										<f-icon source="p-slack" size="x-small"></f-icon>
										<f-text state="subtle" inline
											><a :href="assetSlackChannelLink" target="_blank">#asset-onboarding</a
											>.</f-text
										>
									</f-div>
								</template>
							</f-div>
							<f-form-builder
								v-if="showClassificationTaxonForm"
								class="show-scrollbar"
								gap="small"
								size="small"
								:field.prop="classificationTaxonFormFields"
								:values.prop="classificationTaxonFormValues"
								@input="onFormValueChange"
								@state-change="formState.classificationTaxon = $event.detail"
							></f-form-builder>
						</f-div>
					</f-div>

					<f-div
						v-if="showPossibleMatches"
						padding="none"
						direction="column"
						overflow="hidden"
						:width="collapseRightPane ? '270px' : '40px'"
						gap="medium"
					>
						<f-div padding="x-large medium medium medium" height="hug-content" align="middle-left">
							<f-text
								v-if="collapseRightPane"
								variant="heading"
								size="x-small"
								weight="medium"
								state="secondary"
								align="left"
								>Possible matches</f-text
							>
							<f-icon-button
								:icon="collapseRightPane ? 'i-arrow-sm-right' : 'i-arrow-sm-left'"
								size="small"
								variant="round"
								state="neutral"
								@click="collapseRightPane = !collapseRightPane"
							/>
						</f-div>

						<f-div
							v-if="collapseRightPane && componentSuggestions.length"
							overflow="scroll"
							direction="column"
						>
							<f-div
								v-for="component in componentSuggestions"
								:key="component.id"
								direction="column"
								height="hug-content"
								padding="small none none none"
								gap="none"
								border="small solid subtle bottom"
							>
								<f-div padding="none none medium medium" direction="column" gap="small">
									<f-div gap="small" align="top-left">
										<f-pictogram
											size="large"
											:source="component.name!.toLocaleUpperCase()"
											variant="square"
										>
										</f-pictogram>
										<f-div direction="column" gap="x-small">
											<f-text weight="bold" size="small" variant="para">
												{{ component.name }} {{ component.version }}
											</f-text>
											<f-text
												variant="para"
												size="small"
												weight="regular"
												state="secondary"
												align="left"
												style="text-transform: capitalize"
												>{{ component.type?.toLocaleLowerCase() }}</f-text
											>
										</f-div>
									</f-div>
									<f-div padding="none" align="middle-left" height="hug-content">
										<f-text size="x-small" state="secondary" variant="para" align="left">
											{{ getClassificationString(component) }}
										</f-text>
									</f-div>
								</f-div>
							</f-div>
						</f-div>
						<f-div v-else direction="column" align="middle-center" height="fill-container">
							<f-text
								v-if="collapseRightPane"
								variant="para"
								size="small"
								weight="regular"
								state="subtle"
								>No matches found.</f-text
							>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<!-- End Body -->

			<!-- Footer -->
			<f-div
				height="hug-content"
				padding="medium"
				gap="medium"
				align="middle-left"
				border="small solid subtle top"
			>
				<f-div align="middle-left">
					<f-checkbox :value="isAcknowledged" @input="isAcknowledged = $event.detail.value">
						<f-text slot="label" inline size="small" state="subtle"
							>I am authorized to use this component in our organization.</f-text
						>
					</f-checkbox>
				</f-div>

				<f-button
					tooltip="#create-request-btn-tooltip"
					:disabled="!isFormComplete"
					:loading="isSubmitting"
					label="Create Request"
					state="success"
					@click="submitComponent"
				></f-button>
				<f-tooltip v-if="!isFormComplete" id="create-request-btn-tooltip" placement="top-end">
					<f-div direction="column" width="200px">
						<f-text variant="para" size="small">
							Enter all classification details to create an onboarding service request
						</f-text>
					</f-div>
				</f-tooltip>
			</f-div>
			<!-- End Footer -->
		</f-div>
	</f-popover>
</template>

<script lang="ts">
// eslint-disable max-lines
import { html } from "@ollion/flow-core";
import { FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { createWorkflowComponent } from "@/modules/release-cockpit-v2/component-catalog-service";
import {
	TaxonSearchItem,
	componentCatalogStore
} from "@/modules/release-cockpit-v2/component-catalog-store";
import { Component, ComponentType, CreateComponentRequest } from "@/protocol/cockpit";
import { COMPONENT_DESCRIPTION_LENGTH, COMPONENT_JUSTIFICATION_LENGTH } from "@/shared/constants";
import { captureError, getErrorMessage, isInternalProd } from "@/utils";
import { getComponentTaxonomy } from "@/utils/get-taxonomy-classification";

type ClassificationSearchFormValues = {
	suggest?: string;
};

type ClassificationFormValues = {
	service_details?: {
		service_name: string;
		service_version: string;
	};
};

type ClassificationTaxonFormValues = {
	domain?: string | null;
	division?: string | null;
	class?: string | null;
	family?: string | null;
	canonical_name?: string | null;
	inventory_category?: {
		inventory_category?: string;
	};
	inventory_name?: {
		inventory_name?: string;
	};
	inventory_type?: {
		inventory_type?: string;
	};
	justification?: string | null;
	description?: string | null;
};

export default defineComponent({
	name: "OnboardNewModal",

	props: {
		openPopover: {
			type: Boolean,
			required: true
		},

		componentType: {
			type: String as PropType<ComponentType>,
			required: true
		}
	},

	emits: ["close", "component-created"],

	data: () => {
		return {
			classificationFormValues: {} as ClassificationFormValues,
			classificationTaxonFormValues: {} as ClassificationTaxonFormValues,
			classificationSearchFormValues: {} as ClassificationSearchFormValues,
			searchString: "",
			showClassificationTaxonForm: false,
			isAcknowledged: "unchecked" as "checked" | "unchecked",
			isSubmitting: false,
			showingDescriptionField: false,
			showingJustificationField: false,
			showInventoryCategoryField: false,
			showInventoryNameField: false,
			showInventoryTypeField: false,
			enableWarningMsg: false,
			showPossibleMatches: false,
			collapseRightPane: false,
			errorMsg: null as null | string,
			formState: {
				serviceDetail: null as FormBuilderState | null,
				classificationTaxon: null as FormBuilderState | null
			}
		};
	},

	computed: {
		...mapStores(componentCatalogStore),

		isFormTouched() {
			return (
				Object.entries(this.classificationFormValues).length > 0 ||
				Object.entries(this.classificationTaxonFormValues).length > 0 ||
				Object.entries(this.classificationSearchFormValues).length > 0
			);
		},

		selectedComponentType() {
			return `${this.componentType.charAt(0).toUpperCase()}${this.componentType.toLocaleLowerCase().slice(1)}`;
		},

		showAssetSlackChannelInfo() {
			return this.componentType === ComponentType.ASSET && isInternalProd();
		},

		//@todo - this is a temporary measure until we build a proper taxonony request feature
		assetSlackChannelLink() {
			return "https://ollion.enterprise.slack.com/archives/C06UGFMK79U";
		},

		firstLevelTaxonomy() {
			const firstLevel = [];
			if (this.componentType === ComponentType.SERVICE) {
				firstLevel.push("service");
			} else if (this.componentType === ComponentType.SOFTWARE) {
				firstLevel.push("software");
			} else if (this.componentType === ComponentType.ASSET) {
				firstLevel.push("document", "configuration");
			}

			return firstLevel;
		},

		isFormComplete() {
			const isFormValid =
				this.classificationFormValues.service_details?.service_name &&
				this.classificationFormValues.service_details.service_version &&
				this.classificationTaxonFormValues.domain &&
				this.classificationTaxonFormValues.canonical_name &&
				this.classificationTaxonFormValues.class &&
				this.classificationTaxonFormValues.division &&
				this.classificationTaxonFormValues.family &&
				this.isAcknowledged === "checked" &&
				this.formState.serviceDetail?.isValid &&
				this.formState.classificationTaxon?.isValid;

			if (isFormValid && this.componentType === ComponentType.ASSET) {
				return this.classificationTaxonFormValues.justification;
			}

			return isFormValid;
		},

		classificationSearchFormField(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					suggest: {
						type: "suggest",
						qaId: "suggest",
						iconLeft: "i-search",
						placeholder: "Search by classification, e.g. Database",
						label: { title: `Classify this ${this.selectedComponentType.toLocaleLowerCase()}` },
						optionsMaxHeight: "250px",
						suggestions: this.searchSuggestions,
						suggestWhen: () => true
					}
				}
			};
		},

		domainOptions() {
			const l1Taxons = this.componentCatalogStore.domainTaxons.map(taxon => taxon.taxonomy1);
			return Array.from(new Set(l1Taxons)).filter(l1Taxon =>
				// Filter out the first level taxons that are not in the firstLevelTaxonomy
				this.firstLevelTaxonomy.includes(l1Taxon)
			);
		},

		divisionOptions() {
			const l2Taxons = this.componentCatalogStore.domainTaxons.map(taxon => taxon.taxonomy2);
			return Array.from(new Set(l2Taxons));
		},

		classOptions() {
			const l6Taxons = this.componentCatalogStore.classTaxons.map(taxon => taxon.taxonomy6);
			return Array.from(new Set(l6Taxons));
		},

		familyOptions() {
			const l7Taxons = this.componentCatalogStore.classTaxons
				.filter(taxon => taxon.taxonomy6 === this.classificationTaxonFormValues.class)
				.map(taxon => taxon.taxonomy7);
			return Array.from(new Set(l7Taxons));
		},

		canonicalOptions() {
			const l8Taxons = this.componentCatalogStore.classTaxons
				.filter(taxon => taxon.taxonomy7 === this.classificationTaxonFormValues.family)
				.map(taxon => taxon.taxonomy8);
			return Array.from(new Set(l8Taxons));
		},

		inventoryCategoryOptions() {
			const l3Taxons = this.componentCatalogStore.tbmTaxons.map(taxon => taxon.taxonomy3);
			return Array.from(new Set(l3Taxons));
		},

		inventoryNameOptions() {
			const l4Taxons = this.componentCatalogStore.tbmTaxons
				.filter(
					taxon =>
						taxon.taxonomy3 ===
						this.classificationTaxonFormValues.inventory_category?.inventory_category
				)
				.map(taxon => taxon.taxonomy4);
			return Array.from(new Set(l4Taxons));
		},

		inventoryTypeOptions() {
			const l5Taxons = this.componentCatalogStore.tbmTaxons
				.filter(
					taxon =>
						taxon.taxonomy4 === this.classificationTaxonFormValues.inventory_name?.inventory_name
				)
				.map(taxon => taxon.taxonomy5);
			return Array.from(new Set(l5Taxons));
		},

		modalTitle() {
			return this.componentType.toLocaleLowerCase();
		},

		classificationTaxonFormFields(): FormBuilderField {
			const isFamilyDisabled = !this.classificationTaxonFormValues.class;
			const isCanonicalDisabled = isFamilyDisabled || !this.classificationTaxonFormValues.family;

			const {
				showingDescriptionField,
				removeDescription,
				showingJustificationField,
				removeJustification,
				showInventoryCategoryField,
				showInventoryNameField,
				showInventoryTypeField
			} = this;

			return {
				type: "object",
				direction: "vertical",
				fields: {
					domain: {
						type: "select",
						layout: "label-left",
						searchable: true,
						placeholder: "Select domain",
						options: this.domainOptions,
						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-icon state="secondary" source="i-domain" size="small"></f-icon>
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Domain</f-text
								>
							</f-div>`
						}
					},

					division: {
						type: "select",
						layout: "label-left",
						searchable: true,
						placeholder: "Select division",
						options: this.divisionOptions,
						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-icon state="secondary" source="i-division" size="small"></f-icon>
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Division</f-text
								>
							</f-div>`
						}
					},

					class: {
						type: "select",
						layout: "label-left",
						searchable: true,
						placeholder: "Select class",
						options: this.classOptions,
						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-icon state="secondary" source="i-class" size="small"></f-icon>
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Class</f-text
								>
							</f-div>`
						}
					},

					family: {
						type: "select",
						layout: "label-left",
						searchable: true,
						placeholder: "Select family",
						disabled: isFamilyDisabled,
						options: this.familyOptions,
						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-icon state="secondary" source="i-family" size="small"></f-icon>
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Family</f-text
								>
							</f-div>`
						}
					},

					canonical_name: {
						type: "select",
						layout: "label-left",
						placeholder: "Canonical name",
						disabled: isCanonicalDisabled,
						validationRules: [{ name: "required" }],
						options: this.canonicalOptions,
						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-icon state="secondary" source="i-canonical" size="small"></f-icon>
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Canonical name</f-text
								>
							</f-div>`
						}
					},

					inventory_category: {
						type: "object",
						direction: "horizontal",
						fields: {
							inventory_category: {
								type: "select",
								layout: "label-left",
								searchable: true,
								placeholder: "Select inventory category",
								options: this.inventoryCategoryOptions,
								showWhen: () => showInventoryCategoryField,
								label: {
									title: html`<f-div gap="medium" align="middle-left" width="200px">
										<f-icon state="secondary" source="i-category" size="small"></f-icon>
										<f-text state="secondary" variant="para" weight="regular" size="small"
											>Inventory category</f-text
										>
									</f-div>`
								}
							},

							remove: {
								type: "icon-button",
								icon: "i-close",
								state: "neutral",
								category: "transparent",
								size: "small",
								showWhen: () => showInventoryCategoryField,
								onClick: () => {
									this.showInventoryCategoryField = false;
								}
							}
						}
					},

					inventory_name: {
						type: "object",
						direction: "horizontal",
						fields: {
							inventory_name: {
								type: "select",
								layout: "label-left",
								searchable: true,
								placeholder: "Select inventory name",
								options: this.inventoryNameOptions,
								showWhen: () => showInventoryNameField,
								label: {
									title: html`<f-div gap="medium" align="middle-left" width="200px">
										<f-icon state="secondary" source="i-name" size="small"></f-icon>
										<f-text state="secondary" variant="para" weight="regular" size="small"
											>Inventory name</f-text
										>
									</f-div>`
								}
							},

							remove: {
								type: "icon-button",
								icon: "i-close",
								state: "neutral",
								category: "transparent",
								size: "small",
								showWhen: () => showInventoryNameField,
								onClick: () => {
									this.showInventoryNameField = false;
								}
							}
						}
					},

					inventory_type: {
						type: "object",
						direction: "horizontal",
						fields: {
							inventory_type: {
								type: "select",
								layout: "label-left",
								searchable: true,
								placeholder: "Select inventory type",

								options: this.inventoryTypeOptions,
								showWhen: () => showInventoryTypeField,
								label: {
									title: html`<f-div gap="medium" align="middle-left" width="200px">
										<f-icon state="secondary" source="i-type" size="small"></f-icon>
										<f-text state="secondary" variant="para" weight="regular" size="small"
											>Inventory type</f-text
										>
									</f-div>`
								}
							},

							remove: {
								type: "icon-button",
								icon: "i-close",
								state: "neutral",
								category: "transparent",
								size: "small",
								showWhen: () => showInventoryTypeField,
								onClick: () => {
									this.showInventoryTypeField = false;
								}
							}
						}
					},

					description: {
						type: "textarea",
						layout: "label-left",
						placeholder: "Why do you need this component?",
						showWhen: () => showingDescriptionField,
						validationRules: [
							{
								name: "custom",
								validate: value => value.length < COMPONENT_DESCRIPTION_LENGTH,

								message: `Description should be less than ${COMPONENT_DESCRIPTION_LENGTH} characters`
							}
						],

						helperText: html`
							<f-button
								label="Remove"
								size="x-small"
								state="danger"
								category="transparent"
								@click=${removeDescription}
							></f-button>
						`,

						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Description</f-text
								>
							</f-div>`
						}
					},

					justification: {
						type: "textarea",
						layout: "label-left",
						placeholder: "Why do you need this component?",
						showWhen: () => showingJustificationField,
						...(this.componentType === ComponentType.ASSET
							? {
									validationRules: [
										{ name: "required" },
										{
											name: "custom",
											validate: value => value.length < COMPONENT_JUSTIFICATION_LENGTH,

											message: `Justification should be less than ${COMPONENT_JUSTIFICATION_LENGTH} characters`
										}
									]
								}
							: {
									helperText: html`
										<f-button
											label="Remove"
											size="x-small"
											state="danger"
											category="transparent"
											@click=${removeJustification}
										></f-button>
									`,

									validationRules: [
										{
											name: "custom",
											validate: value => value.length < COMPONENT_JUSTIFICATION_LENGTH,

											message: `Justification should be less than ${COMPONENT_JUSTIFICATION_LENGTH} characters`
										}
									]
								}),

						label: {
							title: html`<f-div gap="medium" align="middle-left" width="200px">
								<f-text state="secondary" variant="para" weight="regular" size="small"
									>Justification</f-text
								>
							</f-div>`
						}
					},

					buttonGroup: {
						type: "object",
						fields: {
							optionalClassificationButton: {
								type: "button",
								label: "optional classification",
								category: "transparent",
								iconLeft: "i-plus",
								size: "small",
								showWhen: () =>
									!showInventoryCategoryField || !showInventoryNameField || !showInventoryTypeField,

								onClick: () => {
									this.showInventoryCategoryField = true;
									this.showInventoryNameField = true;
									this.showInventoryTypeField = true;
								}
							},

							justificationButton: {
								type: "button",
								label: "justification",
								category: "transparent",
								iconLeft: "i-plus",
								size: "small",
								showWhen: () => !showingJustificationField,
								onClick: () => {
									this.showingJustificationField = true;
								}
							},

							descriptionButton: {
								type: "button",
								label: "description",
								category: "transparent",
								iconLeft: "i-plus",
								size: "small",
								showWhen: () => !showingDescriptionField,
								onClick: () => {
									this.showingDescriptionField = true;
								}
							}
						}
					}
				}
			};
		},

		serviceDetailFormFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",

				fields: {
					service_details: {
						type: "object",
						direction: "horizontal",
						gap: "large",
						fields: {
							service_name: {
								type: "text",
								qaId: `${this.selectedComponentType}-name`,
								placeholder: `${this.selectedComponentType} name`,
								label: { title: `${this.selectedComponentType} name` },
								validationRules: [
									{ name: "required" },
									{
										name: "regex",
										message: "Special characters are not allowed",
										params: {
											regex: /^[a-zA-Z0-9\s_-]+$/
										}
									}
								]
							},

							service_version: {
								type: "text",
								qaId: `${this.selectedComponentType}-version`,
								placeholder: `${this.selectedComponentType} version`,
								label: { title: `${this.selectedComponentType} version` },
								validationRules: [
									{ name: "required" },
									{
										name: "regex",
										message: "Special characters and spaces are not allowed",

										params: {
											regex: /^[\w.-]+$/
										}
									}
								]
							}
						}
					}
				}
			};
		},

		searchSuggestions() {
			if (!this.searchString) {
				return Object.values(this.componentCatalogStore.searchIndex)
					.filter(suggestion =>
						this.firstLevelTaxonomy.includes(suggestion.mergedTaxon.taxonomy1 ?? "")
					)
					.map(suggestion => {
						return this.suggestionTemplate(suggestion);
					})
					.slice(0, 50);
			}

			const suggestions = this.componentCatalogStore.taxonomyFuse
				.search(this.searchString.replaceAll("/", ""))
				.filter(taxonResult =>
					this.firstLevelTaxonomy.includes(taxonResult.item.mergedTaxon.taxonomy1 ?? "")
				)
				.map(taxonResult => this.suggestionTemplate(taxonResult.item))
				.slice(0, 50);

			if (suggestions.length === 0) {
				return [this.suggestionTemplate({ displayStr: "No results found", mergedTaxon: {} })];
			}

			return suggestions;
		},

		componentSuggestions() {
			return this.componentCatalogStore.componentFuse
				.search(
					(this.searchString.replaceAll("/", "") ||
						this.classificationFormValues.service_details?.service_name) ??
						""
				)
				.filter(taxonResult =>
					this.firstLevelTaxonomy.includes(taxonResult.item.classification1 ?? "")
				)
				.map(result => result.item)
				.slice(0, 5);
		}
	},

	watch: {
		searchString() {
			const matchingItem = this.componentCatalogStore.searchIndex[this.searchString]?.mergedTaxon;

			if (matchingItem) {
				this.classificationTaxonFormValues = {
					domain: "taxonomy1" in matchingItem ? matchingItem.taxonomy1 : null,
					division: "taxonomy2" in matchingItem ? matchingItem.taxonomy2 : null,
					class: "taxonomy6" in matchingItem ? matchingItem.taxonomy6 : null,
					family: "taxonomy7" in matchingItem ? matchingItem.taxonomy7 : null,
					canonical_name: "taxonomy8" in matchingItem ? matchingItem.taxonomy8 : null
				};

				this.showClassificationTaxonForm = true;
			}
		},

		componentSuggestions() {
			if (!this.showPossibleMatches) {
				this.showPossibleMatches = this.componentSuggestions.length > 0;
				this.collapseRightPane = true;
			}
		}
	},

	mounted() {
		if (this.componentType === ComponentType.ASSET) {
			this.showingJustificationField = true;
		}
	},

	methods: {
		suggestionTemplate(taxon: TaxonSearchItem) {
			return {
				value: taxon.displayStr,
				template: () => html`
					<f-div padding="small" direction="column">
						<f-text variant="para" size="small" weight="medium" highlight="${this.searchString}">
							${taxon.displayStr}</f-text
						>
					</f-div>
				`,

				toString: () => taxon.displayStr
			};
		},

		getClassificationString(component: Component) {
			return Object.values(getComponentTaxonomy(component))
				.filter(Boolean)
				.map(taxonomy => taxonomy.name)
				.join(" / ");
		},

		removeDescription() {
			this.classificationTaxonFormValues.description = "";
			this.showingDescriptionField = false;
		},

		removeJustification() {
			this.classificationTaxonFormValues.justification = "";
			this.showingJustificationField = false;
		},

		handleSearch(event: CustomEvent) {
			this.searchString = event.detail.suggest ?? "";
		},

		closeModal() {
			if (this.isFormTouched) {
				this.enableWarningMsg = true;
			} else {
				this.$emit("close");
			}
		},

		onFormValueChange(event: CustomEvent) {
			this.classificationTaxonFormValues = {
				...event.detail
			};
		},

		async submitComponent() {
			try {
				this.isSubmitting = true;
				this.errorMsg = null;
				const serviceDetails = this.classificationFormValues.service_details!;
				const req: CreateComponentRequest = {
					name: serviceDetails.service_name.trim(),
					version: serviceDetails.service_version.trim(),
					classification1: this.classificationTaxonFormValues.domain ?? undefined,
					classification2: this.classificationTaxonFormValues.division ?? undefined,
					classification3:
						this.classificationTaxonFormValues.inventory_category?.inventory_category ?? undefined,

					classification4:
						this.classificationTaxonFormValues.inventory_name?.inventory_name ?? undefined,

					classification5:
						this.classificationTaxonFormValues.inventory_type?.inventory_type ?? undefined,

					classification6: this.classificationTaxonFormValues.class ?? undefined,
					classification7: this.classificationTaxonFormValues.family ?? undefined,
					classification8: this.classificationTaxonFormValues.canonical_name ?? undefined,
					licenseAgreed: true,
					metadata: {
						justification: this.classificationTaxonFormValues.justification ?? undefined,
						description: this.classificationTaxonFormValues.description ?? undefined
					},

					type: this.componentType
				};

				await createWorkflowComponent({ payload: req });

				// Fetch components
				await this.componentCatalogStore.getComponents();

				this.$emit("component-created");
			} catch (err) {
				captureError(err);
				this.errorMsg = getErrorMessage(err);
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>

<style lang="scss">
.show-scrollbar {
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		display: block;
		width: 6px;
		height: 6px;
	}
}
</style>
