<template>
	<PermissionsWrapper>
		<div v-if="isLoading" class="width-100-per" data-qa="loading-component-catalog-list">
			<Container class="position-relative height-100-vh">
				<Loader position="absolute"><p class="paragraph-2 fc-light">Loading</p></Loader>
			</Container>
		</div>
		<ErrorPage
			v-else-if="hasFetchError"
			message="Something went wrong when getting components"
			data-qa-release-cockpit-fetch-error
		/>
		<ComponentCatalogListPage v-else />
	</PermissionsWrapper>
</template>
<script lang="ts">
import { Container, Loader } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";

import { componentOnboardStore } from "../component-onboard-store";

import ComponentCatalogListPage from "./ComponentCatalogListPage.vue";

export default defineComponent({
	name: "ReleaseCockpitPage",

	components: {
		ComponentCatalogListPage,
		PermissionsWrapper,
		ErrorPage,
		Loader,
		Container
	},

	data: () => {
		return {
			hasFetchError: false,
			isLoading: true
		};
	},

	computed: {
		...mapStores(componentCatalogStore, componentOnboardStore)
	},

	mounted() {
		this.componentCatalogStore.fetchTaxonomies();
		this.componentOnboardStore.fetchGatesAndEnvironments();
		this.componentCatalogStore
			.getComponents()
			.catch(() => (this.hasFetchError = true))
			.finally(() => {
				this.isLoading = false;
			});
	}
});
</script>
