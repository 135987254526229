<template>
	<f-div
		v-for="filter in componentFilters"
		:key="filter.id"
		max-width="160px"
		style="min-width: 150px"
	>
		<f-select
			variant="curved"
			:value="filters[filter.id]"
			category="fill"
			:data-qa="`selected-app-${filter.id}`"
			:size="filter.size"
			:state="filtersActiveState[filter.id]"
			:options="filter.options"
			max-options-width="300px"
			:option-template="statusOptionTemplate"
			@input="onFilterUpdate(filter.id, $event)"
		>
		</f-select>
	</f-div>
</template>

<script lang="ts">
import {
	FSelectOptionObject,
	FSelectOptionTemplate,
	FSelectSingleOption,
	html
} from "@ollion/flow-core";
import { defineComponent, PropType } from "vue";

import { Component } from "@/protocol/cockpit";
import { ComponentStatus, COMPONENT_DISPLAY, getIconFromEnvironment } from "@/shared/constants";
import { COMPONENT_TYPE_DISPLAY } from "@/shared/icons";

import {
	DEFAULT_CATEGORY,
	DEFAULT_STATUS,
	DEFAULT_ENVIRONMENT,
	Option,
	CatalogSelectedFilterType,
	FilterType
} from "../catalog-service-types";

export default defineComponent({
	name: "ComponentCatalogFilter",

	props: {
		components: {
			type: Array as PropType<Component[]>,
			required: true
		},

		filters: {
			type: Object as PropType<CatalogSelectedFilterType>,
			required: true
		}
	},

	emits: ["update-filters"],

	computed: {
		filtersActiveState(): Record<string, "default" | "primary"> {
			return {
				category:
					this.filters.category?.id !== DEFAULT_CATEGORY.id
						? "primary"
						: ("default" as "default" | "primary"),

				status:
					this.filters.status?.id !== DEFAULT_STATUS.id
						? "primary"
						: ("default" as "default" | "primary"),

				type:
					this.filters.type?.id !== DEFAULT_ENVIRONMENT.id
						? "primary"
						: ("default" as "default" | "primary")
			};
		},

		componentStatusesOptions(): Option[] {
			const statuses = new Set<ComponentStatus>();
			const selectedCategoryId = this.filters.category?.id;
			this.components
				.filter(component => {
					if (selectedCategoryId !== DEFAULT_CATEGORY.id) {
						return component.type === selectedCategoryId;
					}
					return true;
				})
				.forEach(component => {
					if (component.status) {
						statuses.add(component.status as ComponentStatus);
					}
				});

			return (
				Array.from(statuses)
					// Published status is differentiated using tabs
					.filter(state => Boolean(COMPONENT_DISPLAY[state]))
					.map(state => {
						return {
							id: COMPONENT_DISPLAY[state].text,
							title: COMPONENT_DISPLAY[state].text,
							data: {
								icon: COMPONENT_DISPLAY[state].icon.icon,
								state: COMPONENT_DISPLAY[state].state
							}
						};
					})
			);
		},

		componentTypesOptions(): Option[] {
			const types = new Set<string>();

			this.components.forEach(component => {
				if (component.type) {
					types.add(component.type);
				}
			});

			return Array.from(types).map(type => {
				return {
					title: COMPONENT_TYPE_DISPLAY[type]?.text ?? "",
					id: type,
					data: {
						icon: COMPONENT_TYPE_DISPLAY[type]?.icon,
						state: "default"
					}
				};
			});
		},

		componentEnvOptions(): Option[] {
			const envs = new Set<string>();

			this.components
				.filter(component => {
					const selectedCategoryId = this.filters.category?.id;
					if (selectedCategoryId !== DEFAULT_CATEGORY.id) {
						return component.type === selectedCategoryId;
					}
					return true;
				})
				.forEach(component => {
					if (component.allowedEnvironment?.length) {
						component.allowedEnvironment.map(env => envs.add(env));
					}
				});

			return Array.from(envs).map(env => {
				return {
					title: env,
					id: env,
					data: COMPONENT_TYPE_DISPLAY[env] ?? {
						id: env,
						icon: getIconFromEnvironment(env),
						state: "default",
						text: env
					}
				};
			});
		},

		componentFilters(): FilterType[] {
			return [
				{
					id: "category",
					size: "small",
					options: [DEFAULT_CATEGORY, ...this.componentTypesOptions]
				},
				{
					id: "status",
					size: "small",
					options: [DEFAULT_STATUS, ...this.componentStatusesOptions]
				},
				{
					id: "type",
					size: "small",
					options: [DEFAULT_ENVIRONMENT, ...this.componentEnvOptions]
				}
			];
		}
	},

	methods: {
		onFilterUpdate(filterKey: string, event: CustomEvent) {
			const filter = this.filters;
			filter[filterKey] = {
				id: event.detail.value.id,
				title: event.detail.value.title
			};
			this.$emit("update-filters", filter);
		},

		statusOptionTemplate(option: FSelectSingleOption): ReturnType<FSelectOptionTemplate> {
			const customOption = option as FSelectOptionObject;
			if (customOption.data?.icon) {
				return html`
					<f-div gap="small">
						<f-icon
							.source="${customOption.data.icon}"
							.state="${customOption.data.state}"
							size="x-small"
						></f-icon>
						<f-text variant="heading" size="x-small" weight="regular" ellipsis="${true}"
							>${customOption.title}</f-text
						>
					</f-div>
				`;
			}
			return html`
				<f-div gap="small">
					<f-text variant="heading" size="x-small" weight="regular" ellipsis="${true}"
						>${customOption.title}</f-text
					>
				</f-div>
			`;
		}
	}
});
</script>
