import * as pdfJs from "pdfjs-dist";
import { defineStore } from "pinia";

import { TaxonomyDetail } from "@/protocol/correlation";
import { SimilarityFeedback } from "@/protocol/document";
import {
	CobitMap,
	IndexedParagraph,
	getParagraphsFromDocument,
	similarityWorkerPool
} from "@/utils";

export const documentComparisonStore = defineStore("documentComparisonStore", {
	state: () => ({
		embeddings: {} as Record<string, Record<string, ParagraphWithEmbedding>>,
		cosineMap: {} as Record<string, CosineSimilarityResponse[]>
	}),

	actions: {
		async CALCULATE_EMBEDDINGS(
			id: string,
			statements: IndexedParagraph[],
			progress: (done: number, total: number) => void
		) {
			this.embeddings[id] = {};

			const total = statements.length;
			let done = 0;

			progress(done, total);
			await Promise.all(
				statements.map(async statement => {
					const embedding = (await similarityWorkerPool.exec("getEmbedding", [
						statement.parsedStr.text()
					])) as number[];

					this.embeddings[id]![statement.id] = {
						...statement,
						embedding
					};

					progress(++done, total);
				})
			);
			progress(done, total);
		},

		async CALCULATE_COSINE_SIMILARITY(
			paragroup1: Record<string, ParagraphWithEmbedding>,
			paragroup2: Record<string, ParagraphWithEmbedding>,
			progress: (done: number, total: number) => void
		) {
			const leftStatements = Object.values(paragroup1);
			const rightStatements = Object.values(paragroup2);

			const total = leftStatements.length;
			let done = 0;

			const embeddingFormat = rightStatements.map(p => ({
				id: p.id,
				str: p.parsedStr.text(),
				// Proxies from Vue can't be passed to webworkers
				embedding: [...p.embedding]
			}));

			const cosineMap: Record<string, CosineSimilarityResponse[]> = {};

			progress(done, total);
			await Promise.all(
				leftStatements.map(async p1 => {
					const embedding = (await similarityWorkerPool.exec("getCosineSimilarity", [
						p1.parsedStr.text(),
						embeddingFormat,
						10
					])) as CosineSimilarityResponse[];

					cosineMap[p1.id] = embedding;

					progress(++done, total);
				})
			);
			progress(done, total);

			this.cosineMap = cosineMap;

			return cosineMap;
		}
	}
});

export type CosineSimilarityResponse = {
	id: string;
	str: string;
	similarity: number;
};

export type SimilarityFeedbackWithId = Required<SimilarityFeedback> & { id: string };

type ParagraphWithEmbedding = IndexedParagraph & { embedding: number[] };

export type DraftDocument = {
	documentId: string;
	documentStep: DraftDcumentSteps;
	selectedStatementIds: Record<string, boolean>;
	statements: IndexedParagraph[];
	cobitMap: Record<string, CobitMap>;
	classification?: TaxonomyDetail[];
	similarityFeedback: Record<string, SimilarityFeedbackWithId>;
	metadata: {
		documentName: string;
		documentVersion: string;
		documentType?: "Best_Practice" | "Guideline";
		regulatoryOrg?: string;
	};
};

export type DraftDcumentSteps = "upload" | "details" | "statements" | "mapping";

export async function getDocumentStateFromDocument(
	documentId: string,
	document: pdfJs.PDFDocumentProxy,
	fileName: string
): Promise<DraftDocument> {
	const documentMeta = await document.getMetadata();
	const statements = await getParagraphsFromDocument(document);

	return {
		documentStep: "details",
		documentId,
		statements,
		selectedStatementIds: {},
		cobitMap: {},
		similarityFeedback: {},
		metadata: {
			//@ts-expect-error
			documentName: documentMeta.info.Title ?? fileName,
			//@ts-expect-error
			documentVersion: documentMeta.info.Version ?? "1.0.0"
		}
	};
}
