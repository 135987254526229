<template>
	<PermissionsWrapper>
		<FullPageLoader v-if="isLoading" />
		<ErrorPage
			v-else-if="hasFetchError"
			message="Something went wrong when fetching components."
			data-qa="components-fetch-error"
		/>
		<f-div v-if="component" width="100%" height="100%" direction="column">
			<ComponentCatalogHeader :component="component" />

			<!-- Notification start -->
			<f-div
				v-if="notificationState && showBanner"
				padding="small"
				gap="auto"
				:state="notificationState.bgState"
				align="middle-left"
				height="hug-content"
			>
				<f-div gap="medium" align="middle-left">
					<f-icon :source="notificationState.icon" size="small" state="inherit"></f-icon>
					<f-text variant="para" size="medium" weight="regular" state="default">{{
						notificationState.message
					}}</f-text></f-div
				>
				<f-div width="hug-content" align="middle-left">
					<f-button
						label="got it"
						category="outline"
						variant="round"
						size="small"
						state="neutral"
						@click="showBanner = false"
					></f-button>
				</f-div>
			</f-div>
			<!-- Notification end -->

			<!-- Sub header start -->
			<f-div height="hug-content" width="fill-container" state="secondary">
				<f-div
					gap="medium"
					align="middle-left"
					width="fill-container"
					padding="medium medium medium large"
				>
					<f-div width="hug-content" align="middle-left" gap="medium">
						<f-text variant="para" size="medium" weight="bold" state="default" align="left">
							Classification
						</f-text>
						<f-icon size="small" source="i-info-fill"></f-icon>
					</f-div>
					<f-div v-if="isComponentRejected" align="middle-right">
						<f-button
							label="RESEND REQUEST"
							state="primary"
							size="small"
							:loading="resendBtnState.isSubmitting"
							:disabled="resendBtnState.isDisabled"
							@click="classificationResendRequest"
						></f-button>
					</f-div>
				</f-div>
			</f-div>
			<!-- Sub header end -->

			<f-divider state="secondary" />

			<f-div class="f-three-columns">
				<f-div class="f-middle-column" width="fill-container">
					<ClassificationDetails
						:component="component"
						:action-type="actionType"
						@on-close-modal="onCloseModal"
						@on-form-value-change="resendClassificationFormValues = $event"
						@on-form-state-change="classificationFormState = $event"
					/>
				</f-div>
				<f-div
					width="20vw"
					style="min-width: 350px"
					state="default"
					border="small solid secondary left"
				>
					<ClassificationActionRightPane
						:component="component"
						@show-classification-action-modal="onActionTypeChange"
					/>
				</f-div>
			</f-div>
		</f-div>
	</PermissionsWrapper>
</template>

<script lang="ts">
import { FDivStateProp } from "@ollion/flow-core";
import { isEqual } from "lodash-es";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { classificationResendRequest } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import ClassificationActionRightPane from "@/modules/release-cockpit-v2/components/classification-approval/ClassificationActionRightPane.vue";
import ClassificationDetails from "@/modules/release-cockpit-v2/components/classification-approval/ClassificationDetails.vue";
import ComponentCatalogHeader from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentCatalogHeader.vue";
import { ComponentType } from "@/protocol/cockpit";
import { ClassificationReSubmissionRequest } from "@/protocol/workflow";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";
import { COMPONENT_STATUS } from "@/shared/constants";
import { captureError } from "@/utils";

import {
	ClassificationActionType,
	ClassificationTaxonValues,
	getComponentClassificationLevels
} from "../release-cockpit-types";

export default defineComponent({
	name: "ComponentClassification",

	components: {
		ClassificationActionRightPane,
		ComponentCatalogHeader,
		ClassificationDetails,
		PermissionsWrapper,
		FullPageLoader,
		ErrorPage
	},

	data() {
		return {
			isLoading: true,
			hasFetchError: false,
			actionType: null as ClassificationActionType | null,
			showBanner: true,
			resendClassificationFormValues: null as ClassificationTaxonValues<string> | null,
			isResendingRequest: false,
			classificationFormState: null as ClassificationTaxonValues<{
				isValid: boolean;
				errors: string[];
			}> | null
		};
	},

	computed: {
		...mapStores(componentCatalogStore, notificationsStore, authStore),

		componentId() {
			if (this.$route.params.componentId) {
				return this.$route.params.componentId;
			}
			return null;
		},

		component() {
			if (!this.componentId) {
				return null;
			}
			return (
				this.componentCatalogStore.components.find(
					component => component.id === this.componentId
				) ?? null
			);
		},

		componentStatus() {
			return this.component?.status;
		},

		isComponentRejected() {
			return this.componentStatus === COMPONENT_STATUS.classificationApprovalRejected;
		},

		isFormValid() {
			return Object.values(this.classificationFormState ?? {}).every(
				classification => classification.isValid
			);
		},

		isFormUnTouched() {
			const initialFormValues = {
				...(this.component ? getComponentClassificationLevels(this.component) : {}),
				justification: this.component?.metadata?.justification ?? "",
				description: this.component?.metadata?.description ?? ""
			};
			return isEqual(this.resendClassificationFormValues, initialFormValues);
		},

		resendBtnState() {
			return {
				isDisabled: !this.isFormValid || this.isFormUnTouched,
				isSubmitting: this.isResendingRequest
			};
		},

		hasCatalogWriteAccess() {
			return Boolean(
				this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled
			);
		},

		notificationState() {
			if (this.componentStatus === COMPONENT_STATUS.classificationApprovalPending) {
				return {
					message: "Review the details of classification and justification.",
					icon: "i-info-fill",
					bgState: "primary" as FDivStateProp
				};
			} else if (
				this.componentStatus === COMPONENT_STATUS.classificationApprovalRejected &&
				this.hasCatalogWriteAccess
			) {
				return {
					message:
						"This request is rejected. Please do the required modification and resend the request.",

					icon: "i-alert",
					bgState: "warning" as FDivStateProp
				};
			}

			return null;
		}
	},

	watch: {
		componentStatus: {
			immediate: true,

			handler() {
				// Handles redirection when the component status is approved.
				// Redirect to onboarding view if component status is artifact required and for all the other cases the component card handles the navigation based on the status.
				if (this.component?.id && this.componentStatus === COMPONENT_STATUS.artifactRequired) {
					this.$router.push({
						name: "component-onboarding-view",
						params: { componentId: this.component.id }
					});
				}
			}
		}
	},

	mounted() {
		this.componentCatalogStore
			.getComponents()
			.catch(() => (this.hasFetchError = true))
			.finally(() => (this.isLoading = false));
	},

	methods: {
		onActionTypeChange(actionType: ClassificationActionType) {
			this.actionType = actionType;
		},

		onCloseModal() {
			this.actionType = null;
		},

		async classificationResendRequest() {
			try {
				this.isResendingRequest = true;

				if (!this.component) {
					return;
				}

				const payload: ClassificationReSubmissionRequest = {
					componentId: this.component.id,
					name: this.component.name,
					version: this.component.version,
					type: this.component.type as ComponentType,
					classification1: this.resendClassificationFormValues?.classification1,
					classification2: this.resendClassificationFormValues?.classification2,
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					classification3: this.resendClassificationFormValues?.classification3 || undefined,
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					classification4: this.resendClassificationFormValues?.classification4 || undefined,
					// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
					classification5: this.resendClassificationFormValues?.classification5 || undefined,
					classification6: this.resendClassificationFormValues?.classification6,
					classification7: this.resendClassificationFormValues?.classification7,
					classification8: this.resendClassificationFormValues?.classification8,
					metadata: {
						justification: this.resendClassificationFormValues?.justification,
						description: this.resendClassificationFormValues?.description
					}
				};

				await classificationResendRequest(payload);

				this.componentCatalogStore
					.getComponents()
					.catch(() => (this.hasFetchError = true))
					.finally(() => (this.isLoading = false));

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-classification-resend",
					title: "Success",
					text: `Classification request for ${this.component.name} is submitted.`,
					status: "success"
				});
			} catch (error) {
				captureError(error);

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-classification-resend-error",
					title: "Error",
					text: `Classification request for ${this.component?.name} is failed.`,
					status: "error"
				});
			} finally {
				this.isResendingRequest = false;
			}
		}
	}
});
</script>
