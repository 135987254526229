<template>
	<f-div v-if="deprecationExists" height="hug-content" direction="column" width="fill-container">
		<f-div
			width="fill-container"
			padding="medium"
			direction="row"
			gap="none"
			:state="deprecationRequestRejected ? 'primary' : 'danger'"
			height="hug-content"
			overflow="visible"
		>
			<f-div gap="small" align="middle-left" width="hug-content" padding="none">
				<f-icon
					size="medium"
					:state="deprecationRequestRejected ? 'primary' : 'danger'"
					:source="deprecationRequestRejected ? 'i-info-fill' : 'i-alert'"
				></f-icon>
				<f-text variant="para" size="medium" weight="regular" state="default" align="left">
					{{
						deprecationRequestRejected
							? `${deprecationDetails.approver} has rejected the deprecation request.`
							: `${deprecationDetails.requestedBy} has requested to deprecate this component.`
					}}
				</f-text>
			</f-div>
			<f-div gap="small" align="top-right">
				<f-div v-if="deprecationRequestRejected" gap="small" align="middle-right">
					<f-button
						label="VIEW REASON"
						state="primary"
						variant="round"
						category="outline"
						size="small"
						icon-left="i-chat-alt"
						data-qa="document-mapping-reset-file-upload-button"
						@click="deprecationRequestPopover = true"
					></f-button>
					<f-button
						label="GOT IT"
						state="neutral"
						variant="round"
						category="outline"
						size="small"
						data-qa="document-mapping-reset-file-upload-button"
						@click="acknowledgeDeprecationRejectionAction"
					></f-button>
				</f-div>
				<f-button
					v-else
					:label="isComponentDeprecated ? 'DEPRECATED' : 'VIEW DEPRECATION REQUEST'"
					state="danger"
					variant="round"
					category="outline"
					size="small"
					icon-left="i-unlink"
					data-qa="document-mapping-reset-file-upload-button"
					@click="openRespectiveDeprecationPopup"
				></f-button>
			</f-div>
		</f-div>

		<!---- View component popover where its deprecation rejected by approver -->
		<DeprecateViewReason
			v-if="deprecationRequestRejected && deprecationRequestPopover"
			:selected-component="component"
			:deprecation-details="deprecationDetails"
			@acknowledge-deprecation-rejection-action="acknowledgeDeprecationRejectionAction"
			@close="deprecationRequestPopover = false"
		/>

		<!---- Open Deprecate component Popup for Approve and reject flow -->
		<DeprecateComponentRequestAction
			v-else-if="deprecationRejectApprovePopover"
			:open-popover="deprecationRejectApprovePopover"
			:selected-component="component"
			:deprecation-details="deprecationDetails"
			:has-approver-rights="hasApproverRights"
			@close-modal="closePopup"
		/>
	</f-div>
</template>

<script lang="ts">
import { mapStores, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import DeprecateComponentRequestAction from "@/modules/release-cockpit-v2/components/catalog-component-list/DeprecateComponentRequestAction.vue";
import DeprecateViewReason from "@/modules/release-cockpit-v2/components/catalog-component-list/DeprecateViewReason.vue";
import { workflowStore } from "@/modules/workflow/workflow-store";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_STATUS } from "@/shared/constants";

import { componentOnboardStore } from "../../component-onboard-store";

export default defineComponent({
	name: "DeprecateRequestIndicator",
	components: { DeprecateComponentRequestAction, DeprecateViewReason },

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => {
		return {
			deprecationRequestPopover: false,
			deprecationRejectApprovePopover: false
		};
	},

	computed: {
		...mapStores(
			authStore,
			componentCatalogStore,
			notificationsStore,
			workflowStore,
			componentOnboardStore
		),

		...mapState(workflowStore, {
			deprecationDetails: "deprecationDetails"
		}),

		deprecationExists() {
			return (
				(this.component.status === COMPONENT_STATUS.deprecationPending ||
					this.isComponentDeprecated ||
					this.component.status === COMPONENT_STATUS.published) &&
				Object.keys(this.deprecationDetails).length > 0 &&
				!this.deprecationDetails.acknowledge
			);
		},

		isComponentDeprecated() {
			return this.component.status === COMPONENT_STATUS.deprecated;
		},

		deprecationRequestRejected() {
			return (
				this.deprecationExists &&
				!this.isComponentDeprecated &&
				!this.deprecationDetails.approved &&
				this.deprecationDetails.justification?.trim() !== ""
			);
		},

		hasApproverRights() {
			return this.authStore.userPermissions[USER_PERMISSIONS.workflowApprove]?.enabled ?? false;
		}
	},

	mounted() {
		/* API Call to check deprecation status */
		this.workflowStore.SET_DEPRECATION_DETAILS_BLANK();
		if (
			this.component.status === COMPONENT_STATUS.deprecationPending ||
			this.component.status === COMPONENT_STATUS.published ||
			this.component.status === COMPONENT_STATUS.deprecated
		) {
			this.componentDeprecationDetails();
		}
	},

	methods: {
		openRespectiveDeprecationPopup() {
			if (this.isComponentDeprecated) {
				this.deprecationRequestPopover = true;
			} else {
				this.deprecationRejectApprovePopover = true;
			}
		},

		closePopup() {
			this.deprecationRejectApprovePopover = false;
		},

		componentDeprecationDetails() {
			this.workflowStore.deprecationByComponentId(this.component.id);
		},

		async acknowledgeDeprecationRejectionAction() {
			try {
				await this.workflowStore.acknowledgeDeprecation({
					componentId: this.component.id,
					acknowledge: true
				});

				await this.componentOnboardStore.getComponentById(this.component.id);
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Deprecation rejection acknowledged.",
					text: `Deprecation rejection acknowledged successfully.`,
					status: "info"
				});
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Request not sent for deprecation acknowledgment",
					text: "There is some error in processcing your request, please try again!!!",
					status: "warning"
				});
			} finally {
				this.deprecationRequestPopover = false;
			}
		}
	}
});
</script>
