<template>
	<DeprecateComponentRequest
		v-if="openDeprecateComponentPopover"
		:open-popover="openDeprecateComponentPopover"
		:selected-component="selectedComponent"
		@close-modal="closeDeprecatedPopup"
	/>
	<f-popover
		v-else
		:target="`#${taggedListId}`"
		:open="openPopover"
		size="small"
		placement="bottom-start"
		:overlay="false"
		shadow
		:close-on-escape="true"
		@esc="onClose()"
		@overlay-click="onClose()"
	>
		<f-div direction="column" state="subtle">
			<f-div
				v-for="(item, idx) in menuItem"
				:key="item.id"
				padding="medium"
				gap="medium"
				clickable
				:border="idx === menuItem.length - 1 ? undefined : 'small solid subtle bottom'"
				:selected="item.id === selected ? 'background' : 'none'"
				@click="select(String(item.id))"
			>
				<f-div
					data-id="menu-list-section-left"
					gap="medium"
					align="middle-left"
					width="hug-content"
				>
					<f-icon size="small" state="default" :source="item.icon"></f-icon>
					<f-text
						variant="para"
						size="medium"
						weight="regular"
						state="default"
						style="text-transform: capitalize"
					>
						{{ item.label.toLocaleLowerCase() }}
					</f-text>
				</f-div>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import DeprecateComponentRequest from "@/modules/release-cockpit-v2/components/catalog-component-list/DeprecateComponentRequest.vue";
import { COMPONENT_CARD_LIST_ACTION_TYPES } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_STATUS } from "@/shared/constants";
import { duplicateComponentDeeplinking } from "@/utils/component-deeplinking";

export default defineComponent({
	name: "ViewMoreList",
	components: { DeprecateComponentRequest },

	props: {
		selectedComponent: {
			type: Object as PropType<Component>,
			required: true
		},

		openPopover: {
			type: Boolean,
			required: true
		},

		taggedListId: {
			type: String,
			required: true
		}
	},

	emits: ["close", "open-action-popover"],

	data: () => {
		return {
			selected: "",
			openDeprecateComponentPopover: false
		};
	},

	computed: {
		...mapStores(notificationsStore, featureFlagStore),

		menuItem() {
			const menu = [
				{
					id: COMPONENT_CARD_LIST_ACTION_TYPES.componentViewDetails,
					label: "View details",
					icon: "i-view-fill"
				},
				// Constellation map feature linking is broken right now
				// {
				// 	id: COMPONENT_CARD_LIST_ACTION_TYPES.viewOnConstellationMap,
				// 	label: "View on constellation map",
				// 	icon: "i-tree"
				// },
				{
					id: COMPONENT_CARD_LIST_ACTION_TYPES.componentCopyLink,
					label: "Copy link",
					icon: "i-copy"
				}
			];

			if (COMPONENT_STATUS.published === this.selectedComponent.status) {
				menu.push({
					id: COMPONENT_CARD_LIST_ACTION_TYPES.componentUnlink,
					label: "Deprecate component",
					icon: "i-unlink"
				});
			}
			/* Remove View details from menu if its already on view page */
			if (
				this.$route.name === "component-onboarding-view" ||
				this.$route.name === "component-classification-approval"
			) {
				menu.shift();
			}

			return menu;
		}
	},

	methods: {
		copyLinkToClipboard() {
			let url = `${window.location.origin}/ui/component-catalog/view/${this.selectedComponent.id}`;

			if (
				this.selectedComponent.status === COMPONENT_STATUS.draft ||
				this.selectedComponent.status === COMPONENT_STATUS.classificationApprovalPending ||
				this.selectedComponent.status === COMPONENT_STATUS.classificationApprovalRejected
			) {
				url = `${url}/classification`;
			} else {
				url = `${url}/onboarding`;
			}

			navigator.clipboard
				.writeText(url)
				.then(() => {
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-ory-verified",
						title: "Link copied to clipboard successfully!",
						text: "",
						status: "info"
					});
				})
				.catch(err => {
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-ory-verified",
						title: "Failed to copy the link to clipboard:",
						text: err,
						status: "info"
					});
				});
		},

		openConstellationMap() {
			const queryParams = duplicateComponentDeeplinking(this.selectedComponent);
			this.$router.push({ name: "constellation-map", query: queryParams });
		},

		select(actionType: string) {
			this.selected = actionType;

			switch (actionType) {
				case COMPONENT_CARD_LIST_ACTION_TYPES.componentViewDetails:
					if (
						this.selectedComponent.status === COMPONENT_STATUS.draft ||
						this.selectedComponent.status === COMPONENT_STATUS.classificationApprovalPending ||
						this.selectedComponent.status === COMPONENT_STATUS.classificationApprovalRejected
					) {
						this.$router.push({
							name: "component-classification-approval",
							params: { componentId: this.selectedComponent.id }
						});
					} else {
						this.$router.push({
							name: "component-onboarding-view",
							params: { componentId: this.selectedComponent.id }
						});
					}

					break;
				case COMPONENT_CARD_LIST_ACTION_TYPES.viewOnConstellationMap:
					this.openConstellationMap();
					break;
				case COMPONENT_CARD_LIST_ACTION_TYPES.componentCopyLink:
					this.copyLinkToClipboard();
					break;
				case COMPONENT_CARD_LIST_ACTION_TYPES.componentUnlink:
					this.openDeprecateComponentPopover = true;
					break;
			}
		},

		onClose() {
			this.$emit("close");
		},

		closeDeprecatedPopup() {
			this.openDeprecateComponentPopover = false;
			this.onClose();
			// this.$router.push({
			// 	name: "component-catalog"
			// });
		}
	}
});
</script>
