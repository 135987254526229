<script setup lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { computed, ref, onBeforeMount } from "vue";

import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { getEvidenceByIds } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import routerModule from "@/modules/router/router-module";
import { Component } from "@/protocol/cockpit";
import { Evidence } from "@/protocol/evidence";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";
import { COMPONENT_STATUS } from "@/shared/constants";
import { useEvidenceType } from "@/shared/useEvidenceType";
import { isValidUrl } from "@/utils";

import ImageRenderer from "./ImageRenderer.vue";
import LogRenderer from "./LogRenderer.vue";
import PdfRenderer from "./PdfRenderer.vue";

const { router } = routerModule;
const isLoading = ref(true);
const hasFetchError = ref(false);
const currentEvidence = ref({} as Evidence);
const componentCatalogStoreObj = componentCatalogStore();
const notificationStoreInstance = notificationsStore();

onBeforeMount(() => {
	/* Get Evidence ID from route */
	getEvidenceDetails();
});

const currentComponent = computed(() => {
	return (componentCatalogStoreObj.components.filter(
		obj => obj.id === currentEvidence.value.componentId
	)[0] ?? []) as Component;
});

async function getEvidenceDetails() {
	const {
		params: { evidenceId }
	} = router.currentRoute.value;
	if (evidenceId) {
		const response = await getEvidenceByIds({
			evidenceIds: [evidenceId as string]
		});

		await componentCatalogStoreObj
			.getComponents()
			.catch(() => (hasFetchError.value = true))
			.finally(() => (isLoading.value = false));
		if (response.evidences?.[0]) {
			currentEvidence.value = response.evidences[0];
		} else {
			hasFetchError.value = true;
		}
	}
}

const { currentEvidenceType } = useEvidenceType(currentEvidence);

function downloadEvidence() {
	const url = currentEvidence.value.content ?? "";

	if (isValidUrl(url)) {
		window.open(url, "_blank");
	} else {
		notificationStoreInstance.ADD_TOAST({
			qaId: "download-evidence-failed",
			text: "Failed to download evidence",
			id: "download-evidence-failed",
			title: "Failed to download evidence",
			status: "error"
		});
	}
}

function redirectOnComponentView() {
	const { componentId } = currentEvidence.value;

	if (
		currentComponent.value.status === COMPONENT_STATUS.draft ||
		currentComponent.value.status === COMPONENT_STATUS.classificationApprovalPending ||
		currentComponent.value.status === COMPONENT_STATUS.classificationApprovalRejected
	) {
		router.push({
			name: "component-classification-approval",
			params: { componentId }
		});
	} else {
		router.push({
			name: "component-onboarding-view",
			params: { componentId }
		});
	}
}
</script>

<template>
	<f-div v-if="isLoading" align="middle-center" padding="large" height="80vh"
		><FullPageLoader
	/></f-div>
	<ErrorPage
		v-else-if="hasFetchError"
		message="Something went wrong while fetching evidence."
		data-qa="evidence-fetch-error"
	/>
	<EmptyState
		v-else-if="!(Object.keys(currentEvidence).length > 0)"
		message="Could not find the component you are looking for..."
		data-qa="component-not-found-error"
	/>
	<f-div v-else width="fill-container" height="100%" direction="column">
		<!-- Header -->
		<f-div
			height="hug-content"
			padding="medium"
			width="fill-container"
			align="middle-left"
			border="medium solid subtle bottom"
		>
			<f-div direction="row" width="fill-container" align="middle-left" gap="large">
				<f-text inline variant="heading" weight="bold" size="medium" data-qa="evidence-subt-title">
					{{ currentEvidence?.name ?? "NA" }}
				</f-text>
			</f-div>
			<f-div align="middle-right">
				<f-button
					label="View component"
					category="fill"
					state="primary"
					size="small"
					@click="redirectOnComponentView"
				></f-button>
			</f-div>
		</f-div>
		<!-- End Header -->

		<f-div padding="small" direction="column" gap="medium">
			<f-div
				padding="large none large none"
				direction="column"
				border="medium solid subtle bottom"
				height="hug-content"
			>
				<f-div height="hug-content">
					<f-text inline variant="para" size="medium" data-qa="evidence-title">
						Evidence: {{ currentEvidence?.evidence ?? "NA" }}
					</f-text>

					<f-div align="middle-right">
						<f-button
							label="Download evidence"
							category="fill"
							state="primary"
							size="small"
							@click="downloadEvidence"
						></f-button>
					</f-div>
				</f-div>
				<f-div height="hug-content">
					<f-text inline variant="para" size="medium" data-qa="evidence-title">
						Evidence Status: {{ currentEvidence?.status ?? "NA" }}
					</f-text>
				</f-div>
			</f-div>
			<f-div direction="column">
				<Suspense v-if="currentEvidenceType === 'pdf' && currentEvidence">
					<PdfRenderer :content="String(currentEvidence.content)" />
				</Suspense>
				<ImageRenderer
					v-else-if="currentEvidenceType === 'image' && currentEvidence"
					:evidence="currentEvidence"
				/>
				<!-- Text file type or -->
				<!-- No file type, assume it's a string or logs -->
				<LogRenderer
					v-else-if="currentEvidence && currentEvidenceType === 'text'"
					:evidence="currentEvidence"
				/>
				<!-- Unknown file type, allow downloading it -->
				<f-div
					v-else-if="currentEvidence?.fileType"
					align="middle-center"
					direction="column"
					gap="large"
				>
					<f-icon source="i-question-filled" size="x-large" state="subtle"></f-icon>
					<f-text state="secondary" align="center"
						>We do not support rendering of
						<f-text :inline="true" weight="bold" variant="code">{{
							currentEvidence?.fileType
						}}</f-text>
						yet.</f-text
					>
					<f-text state="secondary" align="center"> Please download the evidence.</f-text>
					<f-button
						data-qa="download-evidence-button"
						:label="`Download evidence ${currentEvidence.name}`"
						size="medium"
						category="outline"
						icon-right="i-download"
						@click="downloadEvidence"
					></f-button>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>
