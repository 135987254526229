import { FTagStateProp, FTextStateProp } from "@ollion/flow-core";
import localforage from "localforage";
import { cloneDeep } from "lodash-es";
import * as pdfJs from "pdfjs-dist";
import { defineStore } from "pinia";

import { Node, TaxonomyDetail } from "@/protocol/correlation";
import { SimilarityFeedback } from "@/protocol/document";
import {
	CobitMap,
	IndexedParagraph,
	SentenceType,
	captureError,
	formatListKey,
	getCobitMap,
	getParagraphsFromDocument,
	uuidv4
} from "@/utils";

import { getAllNodes } from "../constellation-map/correlation-service";

// The user feedback adjustment is used to adjust the similarity score based on the user's feedback
const USER_FEEDBACK_ADJUSTMENT = 0.3;

const STORE_NAME = "documentMappingStore";

const DOCUMENT_STORE_PREFIX = `${STORE_NAME}-`;

export const documentMappingStore = defineStore(STORE_NAME, {
	state: () => ({
		currentDocumentState: intialiseDocumentState(),
		draftDocuments: [] as DraftDocument[],
		regulatoryOrgs: [] as Node[]
	}),

	getters: {
		currentSelectedStatements(): IndexedParagraph[] {
			const { currentDocumentState } = this;
			return currentDocumentState.statements.filter(
				({ id, type }) =>
					currentDocumentState.selectedStatementIds[id] && type === SentenceType.ACTIVITY
			);
		}
	},

	actions: {
		CREATE_NEW_DRAFT_DOCUMENT() {
			this.currentDocumentState = intialiseDocumentState();

			return this.currentDocumentState;
		},

		async FETCH_DRAFT_DOCUMENTS() {
			try {
				this.draftDocuments = await loadAllDrafts();
			} catch (err) {
				captureError(err);
				this.draftDocuments = [];
			}
		},

		async LOAD_DRAFT_DOCUMENT(documentId: string) {
			const draftItem = await loadDraftDocument(documentId);

			if (draftItem) {
				this.currentDocumentState = draftItem;
			}
		},

		async SAVE_DRAFT_DOCUMENT() {
			// Don't save a document with no statements
			if (this.currentDocumentState.statements.length === 0) {
				return;
			}

			await saveDraftDocument(this.currentDocumentState);
		},

		SET_DRAFT_DOCUMENT_STEP(step: DraftDcumentSteps) {
			this.currentDocumentState.documentStep = step;
		},

		INITIALISE_DOCUMENT_STATE_FROM_JSON(document: object) {
			if ("metadata" in document && "statements" in document) {
				this.currentDocumentState = document as DraftDocument;
			}
		},

		async INITIALISE_DOCUMENT_STATE_FROM_DOCUMENT(
			document: pdfJs.PDFDocumentProxy,
			fileName: string
		) {
			this.currentDocumentState = await getDocumentStateFromDocument(
				this.currentDocumentState.documentId,
				document,
				fileName
			);
		},

		TOGGLE_STATEMENTS_SELECTION(statements: IndexedParagraph[], isSelected: boolean) {
			const selectedStatements = { ...this.currentDocumentState.selectedStatementIds };

			statements.forEach(statement => {
				if (statement.type === SentenceType.ACTIVITY) {
					selectedStatements[statement.id] = isSelected;
				}
			});

			this.currentDocumentState.selectedStatementIds = selectedStatements;
		},

		UPDATE_COBIT_MAP(statementId: string, userCobitMap: CobitMap) {
			const matchingStatement = this.currentDocumentState.statements.find(
				({ id }) => id === statementId
			)?.str;
			const modelCobitMap = this.currentDocumentState.cobitMap[statementId];

			// If the user has selected the same cobit map then ignore it
			if (!matchingStatement || !modelCobitMap || userCobitMap.id === modelCobitMap.id) {
				// Delete maps if the user changed their mind
				delete this.currentDocumentState.similarityFeedback[statementId];
				delete this.currentDocumentState.similarityFeedback[`${statementId}-disagree`];
				return;
			}

			// If a user has selected a different COBIT map, then we have two critical pieces of info
			// 1. They agree with the new map and 2
			// 2. They disagree with the previous
			// We use both and store it for fine-tuning
			this.currentDocumentState.similarityFeedback[statementId] = {
				id: userCobitMap.id,
				statement1: matchingStatement,
				statement2: userCobitMap.activity,
				modelScore: userCobitMap.similarity,
				userScore: Math.min(1, userCobitMap.similarity + USER_FEEDBACK_ADJUSTMENT)
			};

			// The statement user disagreed with
			this.currentDocumentState.similarityFeedback[`${statementId}-disagree`] = {
				id: modelCobitMap.id,
				statement1: matchingStatement,
				statement2: modelCobitMap.activity,
				modelScore: modelCobitMap.similarity,
				userScore: Math.max(0, modelCobitMap.similarity - USER_FEEDBACK_ADJUSTMENT)
			};
		},

		async DELETE_DRAFT_DOCUMENT(documentId: string) {
			await deleteDraftDocument(documentId);
			this.draftDocuments.splice(
				this.draftDocuments.findIndex(({ documentId: id }) => id === documentId),
				1
			);
		},

		SET_DRAFT_CLASSIFICATION(classification: TaxonomyDetail[]) {
			this.currentDocumentState.classification = classification;
		},

		async MAP_SELECTED_STATEMENTS(progressCb?: (progress: number) => void) {
			const { currentDocumentState, currentSelectedStatements } = this;

			const statementsWithMissingMaps = currentSelectedStatements.filter(
				statement => !currentDocumentState.cobitMap[statement.id]
			);

			const totalDocs = statementsWithMissingMaps.length;
			let documentsToMap = statementsWithMissingMaps.length;

			progressCb?.(0);

			// Run all COBIT inferences in parallel
			await Promise.all(
				statementsWithMissingMaps.map(async statement => {
					const cobitPriority = await getCobitMap(statement.str, 1);
					const { cobitMatches } = cobitPriority;
					currentDocumentState.cobitMap[statement.id] = cobitMatches[0]!;
					documentsToMap--;
					progressCb?.((totalDocs - documentsToMap) / totalDocs);
				})
			);

			progressCb?.(1);
		},

		async FETCH_REG_ORGS() {
			this.regulatoryOrgs =
				(
					await getAllNodes({
						nodeType: "RegulatoryOrganisation"
					})
				).nodes ?? [];
		}
	}
});

function intialiseDocumentState(): DraftDocument {
	return {
		documentId: uuidv4(),
		documentStep: "upload",
		selectedStatementIds: {},
		statements: [],
		cobitMap: {},
		similarityFeedback: {},
		metadata: {
			documentName: "",
			documentVersion: ""
		}
	};
}

export type SimilarityFeedbackWithId = Required<SimilarityFeedback> & { id: string };

export type DraftDocument = {
	documentId: string;
	documentStep: DraftDcumentSteps;
	selectedStatementIds: Record<string, boolean>;
	statements: IndexedParagraph[];
	cobitMap: Record<string, CobitMap>;
	classification?: TaxonomyDetail[];
	similarityFeedback: Record<string, SimilarityFeedbackWithId>;
	metadata: {
		documentName: string;
		documentVersion: string;
		documentType?: "Best_Practice" | "Guideline";
		regulatoryOrg?: string;
	};
};

export type DraftDcumentSteps = "upload" | "details" | "statements" | "mapping";

export async function getDocumentStateFromDocument(
	documentId: string,
	document: pdfJs.PDFDocumentProxy,
	fileName: string
): Promise<DraftDocument> {
	const documentMeta = await document.getMetadata();
	const statements = await getParagraphsFromDocument(document);

	return {
		documentStep: "details",
		documentId,
		statements,
		selectedStatementIds: {},
		cobitMap: {},
		similarityFeedback: {},
		metadata: {
			//@ts-expect-error
			documentName: documentMeta.info.Title ?? fileName,
			//@ts-expect-error
			documentVersion: documentMeta.info.Version ?? "1.0.0"
		}
	};
}

type LevelsMap = {
	indexJoin: string;
	depth: number;
	displayString: { text: string; subText: string };
	activityCount: number;
};

export function generateLevels(paras: IndexedParagraph[]): LevelsMap[] {
	const indexMap: Record<string, LevelsMap> = {};

	paras.forEach(para => {
		const { indexJoin, index, listMatch, type } = para;
		const isActivity = type === SentenceType.ACTIVITY;

		if (!indexMap[indexJoin]) {
			indexMap[indexJoin] = {
				indexJoin,
				depth: index.length - 1,
				activityCount: isActivity ? 1 : 0,
				displayString: formatListKey(listMatch.key, index.length)
			};
		} else if (isActivity) {
			indexMap[indexJoin]!.activityCount += 1;
		}
	});

	const values = Object.values(indexMap).sort((a, b) => a.indexJoin.localeCompare(b.indexJoin));

	return values;
}

export const GOOD_MATCH_THRESHOLD = 0.5;

export function getMatchStrengthState(similarity: number): FTextStateProp | FTagStateProp {
	if (similarity >= GOOD_MATCH_THRESHOLD) {
		return "success";
	}

	if (similarity >= GOOD_MATCH_THRESHOLD / 2) {
		return "warning";
	}

	return "danger";
}

async function loadDraftDocument(documentId: string) {
	return await localforage.getItem<DraftDocument>(`${DOCUMENT_STORE_PREFIX}${documentId}`);
}

async function deleteDraftDocument(documentId: string) {
	return await localforage.removeItem(`${DOCUMENT_STORE_PREFIX}${documentId}`);
}

async function saveDraftDocument(document: DraftDocument) {
	return await localforage.setItem<DraftDocument>(
		`${DOCUMENT_STORE_PREFIX}${document.documentId}`,
		cloneDeep(document)
	);
}

async function loadAllDrafts() {
	const keys = await localforage.keys();

	return (await Promise.all(
		keys
			.filter(key => key.startsWith(`${DOCUMENT_STORE_PREFIX}`))
			.map(key => localforage.getItem<DraftDocument>(key))
			.filter(Boolean)
	)) as DraftDocument[];
}
