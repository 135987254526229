<template>
	<FullPageLoader v-if="isLoading"></FullPageLoader>
	<EmptyState
		v-else-if="documents.length === 0"
		:icon="{
			name: 'i-document',
			size: 'medium',
			state: 'light'
		}"
		message="No documents found for onboarding."
		data-qa="no-documents-found-error"
	/>
	<f-div v-else direction="column">
		<!--Start : multi-line slab -->
		<f-div
			v-for="(document, index) in documents"
			:key="index"
			direction="column"
			gap="medium"
			width="fill-container"
		>
			<f-div
				width="fill-container"
				:state="showStatementsDetailView ? getDocumentState(document).headerState : 'default'"
				:padding="showStatementsDetailView ? 'medium' : 'medium medium medium none'"
				gap="medium"
				align="middle-center"
			>
				<f-div width="hug-content" align="middle-center">
					<f-pictogram
						size="x-large"
						:state="getDocumentState(document).textState"
						variant="squircle"
						:category="undefined"
						:source="document.name?.toLocaleUpperCase()"
					>
					</f-pictogram>
				</f-div>

				<f-div direction="column" align="middle-center">
					<!--Start : top row -->
					<f-div data-f-id="slab-left" height="hug-content" align="top-center">
						<f-text variant="heading" size="small" weight="bold">
							{{ document.name }}
						</f-text>

						<f-div align="middle-right" gap="small">
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getDocumentState(document).textState"
								inline
								align="right"
							>
								{{ getDocumentStats[document.id!]?.approvedStatements ?? 0 }}
							</f-text>
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getDocumentState(document).textState"
								inline
								>/</f-text
							>
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getDocumentState(document).textState"
								inline
							>
								{{ getDocumentStats[document.id!]?.totalStatements ?? 0 }}
							</f-text>
						</f-div>
					</f-div>

					<!--End : slab-left -->
					<f-div width="fill-container" gap="x-small" align="middle-left">
						<f-text variant="para" size="medium" weight="regular" inline state="subtle">
							{{ document.applicableValue }}
						</f-text>
						<f-divider></f-divider>
						<f-text inline variant="para" size="medium" weight="regular" state="subtle">
							Published on {{ formatDateString(document.publishedDate ?? "") }}
						</f-text>

						<f-div
							v-if="showStatementsDetailView"
							gap="small"
							align="middle-right"
							width="fill-container"
						>
							<f-div
								v-for="counters in statementsCounterList[document.id!]"
								:key="counters.label"
								gap="small"
								width="hug-content"
							>
								<f-text variant="para" size="medium" weight="regular" state="subtle" inline>
									{{ counters.label }}
								</f-text>
								<f-text
									variant="para"
									size="medium"
									weight="regular"
									:state="counters.state"
									inline
								>
									{{ counters.count }}
								</f-text>
							</f-div>
						</f-div>
						<!--End : section-subtext -->
					</f-div>
				</f-div>
			</f-div>

			<BestPracticeStatements
				v-if="showStatementsDetailView"
				:statements="statementsByDocumentId(document.id!)"
				:component="component"
			></BestPracticeStatements>
		</f-div>
		<!--End : multi-line slab -->
	</f-div>
</template>

<script lang="ts">
import { FTextStateProp } from "@ollion/flow-core";
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import BestPracticeStatements from "@/modules/release-cockpit-v2/components/best-practice-report/BestPracticeStatements.vue";
import { DocumentCounts, DocumentStats } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { Document } from "@/protocol/correlation";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";
import { formatDateString } from "@/utils/format-date";

export default defineComponent({
	name: "BestPracticeInScopeList",

	components: {
		BestPracticeStatements,
		FullPageLoader,
		EmptyState
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		showStatementsDetailView: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		formatDateString
	}),

	computed: {
		...mapStores(componentOnboardStore, authStore),

		documentWiseProgress() {
			return this.componentOnboardStore.getDocumentWiseProgress(this.component.id ?? "");
		},

		documents() {
			return this.componentOnboardStore.getAllDocuments(this.component.id ?? "");
		},

		statements() {
			return this.componentOnboardStore.componentStatements[this.component.id ?? ""] ?? [];
		},

		isLoading() {
			return this.componentOnboardStore.loadingComponent[this.component.id ?? ""];
		},

		getDocumentStats() {
			const documentsProgress = this.componentOnboardStore.getComponentStats(this.component.id);

			return Object.keys(documentsProgress).reduce<Record<string, DocumentStats>>(
				(acc, documentId) => {
					const documentStats = documentsProgress[documentId];
					if (!documentStats) {
						return acc;
					}
					acc[documentId] = documentStats;

					return acc;
				},
				{}
			);
		},

		statementsCounterList() {
			const documentsProgress = this.componentOnboardStore.getComponentStats(this.component.id);

			return Object.keys(documentsProgress).reduce<Record<string, DocumentCounts[]>>(
				(acc, documentId) => {
					const documentStats = documentsProgress[documentId];

					if (!documentStats) {
						return acc;
					}

					acc[documentId] = [
						{
							label: "Approved",
							count: documentStats.approvedStatements,
							state: "success" as FTextStateProp
						},
						{
							label: "Rejected",
							count: documentStats.rejectedStatements,
							state: "warning" as FTextStateProp
						},
						{
							label: "Pending",
							count: documentStats.pendingStatements,
							state: "warning" as FTextStateProp
						},
						{
							label: "No Evidence",
							count: documentStats.noEvidence,
							state: "danger" as FTextStateProp
						}
					].filter(item => item.count > 0);

					return acc;
				},
				{}
			);
		}
	},

	methods: {
		getDocumentState(document: Document) {
			const progress = this.documentProgress(document.id!);
			const isCompliant = progress?.approvedStatements === progress?.totalStatements;

			return {
				textState: isCompliant ? "success" : "danger",
				headerState: isCompliant ? "success" : "warning"
			} as const;
		},

		documentProgress(documentId: string) {
			return this.documentWiseProgress.find(progress => progress.documentId === documentId);
		},

		statementsByDocumentId(documentId: string) {
			return this.statements
				.filter(item => item.documentId === documentId)
				.sort((a, b) => {
					const idA = parseFloat(a.id ?? "");
					const idB = parseFloat(b.id ?? "");
					return idA - idB;
				});
		}
	}
});
</script>
