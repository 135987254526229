<template>
	<div
		ref="data-table-wrapper"
		class="flow-data-table2 flow-add-scrollbar"
		:class="[
			'bar-height-' + scrollBarSize,
			'bar-width-' + scrollBarSize,
			fullWidth ? 'full-width' : ''
		]"
		:style="{ maxHeight: maxHeight }"
	>
		<table>
			<caption></caption>
			<thead>
				<tr>
					<template v-for="(column, c) in columns">
						<th
							v-if="column.renderable !== false"
							:key="column.title"
							:data-qa-column-name="column.title"
							nowrap
							:class="{
								sticky: stickyColumn && stickyColumn === column.title
							}"
							:style="{
								width: column.width ? column.width : undefined,
								maxWidth: column.maxWidth ? column.maxWidth : undefined
							}"
							:selected="selectedColumn === c"
						>
							<Container
								v-if="column.title !== null"
								padding="12px 12px 12px 0"
								align="left top"
								:gap="8"
							>
								<slot
									name="header"
									:column="column"
									:column-index="c"
									:handle-column-change="handleColumnChange"
								>
									<Icon
										v-if="column.icon"
										:name="column.icon.name"
										:size="column.icon.size"
										:state="column.icon.state"
										:type="column.icon.type"
										:effects="false"
									/>
									<Typography overflow="nowrap" type="p2" color="gray-300">{{
										column.title
									}}</Typography>
								</slot>
								<div
									v-if="column.sortable"
									class="display-flex flex-d-c margin-lt-auto j-c-c align-items-center sort-icon"
									:data-qa-sort-column="innerOrder === 'asc' ? 'desc' : 'asc'"
									:data-qa-is-sorted="innerSort === column.title"
									@click.stop="
										sortColumn(column.title, innerOrder === 'asc' ? 'desc' : 'asc', component)
									"
								>
									<Icon
										name="i-triangle-up"
										size="xx-small"
										type="filled"
										:effects="false"
										:state="
											innerSort === column.title && innerOrder === 'asc' ? 'primary' : 'font-light'
										"
										class="cursor-pointer"
									/>
									<Icon
										name="i-triangle-down"
										size="xx-small"
										type="filled"
										:effects="false"
										:state="
											innerSort === column.title && innerOrder === 'desc' ? 'primary' : 'font-light'
										"
										class="cursor-pointer"
									/>
								</div>
							</Container>
						</th>
					</template>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row, index) in rows"
					:key="index"
					:data-qa-data-table-row="getRowDataQa ? getRowDataQa(row) : true"
					class="show-on-hover-parent"
					@mouseover="handleRowHover(index, row)"
					@click="handleRowClick(index, row)"
				>
					<template v-for="(cell, cid) in row">
						<td
							v-if="columns[cid].renderable !== false"
							:key="cid"
							data-qa-data-table-column
							:class="{
								sticky: stickyColumnIndex && cid === stickyColumnIndex
							}"
							:selected="selectedColumn === cid"
						>
							<!-- @slot Used to display custom column data with any additional components.It has scoped props {column,columnIndex,row,rowIndex} -->
							<slot name="column" :column="cell" :column-index="cid" :row="row" :row-index="index">
								<Container padding="0px 12px 12px 0">
									<template v-if="cell">{{ cell ? cell : "" }}</template>
									<span v-else-if="cell === null" class="fc-light">null</span>
									<span v-else-if="cell === undefined" class="fc-light">undefined</span>
								</Container>
							</slot>
						</td>
					</template>
				</tr>
			</tbody>
		</table>
		<div v-if="rows && rows.length === 0 && !loading" class="flow-data-table2-empty">
			<slot name="empty">
				<EmptyState :hide-icon="true">
					<h4 class="fc-light">No data to display</h4>
				</EmptyState>
			</slot>
		</div>
		<div v-if="loading" class="flow-data-table2-loading">
			<slot name="loading">
				<Loader />
			</slot>
		</div>
		<div
			v-if="!noMoreResults && !loadingMore && rows.length !== 0"
			class="flow-data-table2-no-more-results cursor-pointer"
		>
			<Button state="outlined" size="small" @click="loadMore(true)"> Load more data </Button>
		</div>
		<div v-if="loadingMore && !noMoreResults" class="flow-data-table2-loading-more-results">
			<span class="paragraph-1 fc-primary">Loading more data</span>
			<Icon name="loading" :effects="false" state="primary" />
		</div>
	</div>
</template>
<script>
import { Container, Icon, Typography, Button, EmptyState, Loader } from "@ollion/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "DataTable",

	components: {
		Container,
		Icon,
		Typography,
		Button,
		EmptyState,
		Loader
	},

	props: {
		/**
		 * array of array [[...],[...],...]
		 */
		tableData: {
			type: Array,
			default: () => []
		},

		/**
		 * array of 'DataTableColumn'
		 */
		columns: {
			type: Array,
			default: () => []
		},

		/**
		 * max height of table
		 */
		maxHeight: {
			type: String,
			default: "700px"
		},

		/**
		 * sticky column name
		 */
		stickyColumn: {
			type: String,
			default: ""
		},

		/**
		 * column title to sort
		 */
		sort: {
			type: String,
			default: ""
		},

		/**
		 * sorting order 'asc' | 'desc'
		 */
		order: {
			type: String,
			default: "asc"
		},

		/**
		 * true | false <br/> To enable pagination
		 */
		pagination: {
			type: Boolean,
			default: false
		},

		/**
		 * max results per page while paginating
		 */
		resultsPerPage: {
			type: Number,
			default: 100
		},

		/**
		 * static : It will paginate in available 'tableData' <br/>
		 * api : It will paginate based on api , that is on next event we will have to call api and update 'tableData'.
		 */
		paginationType: {
			type: String,
			default: "static",

			validator(value) {
				return ["static", "api"].includes(value);
			}
		},

		/**
		 * true | false <br/> set to true if all results are displayed from api
		 */
		noMoreApiResults: {
			type: Boolean,
			default: false
		},

		/**
		 * 2 | 4 | 6 | 8
		 */
		scrollBarSize: {
			type: Number,
			default: 4,

			validator(value) {
				return [2, 4, 6, 8].includes(value);
			}
		},

		fullWidth: Boolean,

		/**
		 * true | false
		 */
		loading: {
			type: Boolean,
			default: false
		},

		component: {
			type: String,
			default: "users"
		},

		getRowDataQa: {
			type: Function
		}
	},

	emits: ["rowhover", "rowclick", "next", "columnchange"],

	data() {
		return {
			innerSort: "",
			innerOrder: "",
			parentComponent: "",
			offset: 0,
			loadingMore: false,
			scrollTop: 0,
			selectedColumn: -1
		};
	},

	computed: {
		/**
		 * @return {Number} sticky column index
		 */
		stickyColumnIndex() {
			return this.stickyColumn ? this.columns.findIndex(c => c.title === this.stickyColumn) : -1;
		},

		/**
		 * @return {Array} arrray of rows from 'tableData' prop
		 */
		rows() {
			let idx = this.getColumnIndex(this.innerSort);
			if (idx === -1) {
				idx = 0;
			}
			const applySort = (a, b) => {
				let first = a[idx] || "";
				let second = b[idx] || "";
				if (this.parentComponent === "users" && this.innerSort === "Name") {
					first = a[idx].toString().toLowerCase();
					second = b[idx].toString().toLowerCase();
				} else if (this.parentComponent === "project" && this.innerSort === "Name") {
					first = a[idx].name.toLowerCase();
					second = b[idx].name.toLowerCase();
				}
				if (this.parentComponent === "users" && this.innerSort === "Invited on") {
					return this.innerOrder === "asc"
						? new Date(first) - new Date(second)
						: new Date(second) - new Date(first);
				} else {
					if (first.valueOf() < second.valueOf()) {
						return this.innerOrder === "asc" ? -1 : 1;
					}
					if (first.valueOf() > second.valueOf()) {
						return this.innerOrder === "asc" ? 1 : -1;
					}
				}
				return 0;
			};
			if (this.pagination && this.paginationType === "static") {
				return this.tableData.slice(0, this.offset + this.resultsPerPage).sort(applySort);
			} else {
				return this.tableData.slice(0).sort(applySort);
			}
		},

		/**
		 * @return {Boolean} true if all results are displayed.(computed based on 'paginationType')
		 */
		noMoreResults() {
			return this.paginationType === "static"
				? this.rows.length === this.tableData.length && this.tableData.length !== 0
				: this.noMoreApiResults;
		}
	},

	watch: {
		/**
		 * watch for tableData update
		 */
		tableData: {
			handler() {
				//hide loader
				this.loadingMore = false;
			},

			deep: true
		}
	},

	mounted() {
		if (this.sort) {
			this.$nextTick(() => {
				/**
				 * sorting column on default sort and order
				 */
				this.sortColumn(this.sort, this.order, this.parentComponent);
			});
		}
		const tableWrapper = this.$refs["data-table-wrapper"];
		/**
		 * binding scroll event on wrapper
		 */
		tableWrapper.addEventListener("scroll", () => {
			this.loadMore();
		});
	},

	methods: {
		loadMore(ignorescroll = false) {
			const tableWrapper = this.$refs["data-table-wrapper"];
			const currentTop = tableWrapper.scrollTop;
			if (this.scrollTop < currentTop || ignorescroll) {
				this.scrollTop = currentTop;
				if (this.pagination) {
					if (
						tableWrapper.scrollTop + tableWrapper.clientHeight >= tableWrapper.scrollHeight &&
						this.paginationType === "static"
					) {
						if (this.rows.length < this.tableData.length) {
							this.offset += this.resultsPerPage;
						}
					}
					if (
						tableWrapper.scrollTop + tableWrapper.clientHeight >= tableWrapper.scrollHeight &&
						this.paginationType === "api" &&
						!this.loadingMore &&
						!this.noMoreApiResults
					) {
						this.offset += this.resultsPerPage;
						this.loadingMore = true;
						/**
						 * next event triggered when we want to paginate/retrieve next results, that is user can call api with offset and update 'tableData' prop
						 *
						 * @event next
						 */
						this.$emit("next", this.offset);
					}
				}
			}
		},

		/**
		 * @method handleColumnChange
		 * @param {String} oldval old value of column
		 * @param {String} newval new value of column
		 * @param {Boolean} hasError if column has an error
		 * @param {Object} columnObject DataTableColumn object
		 */
		handleColumnChange(oldval, newval, hasError, columnObject) {
			/**
			 * columnchange event
			 *
			 * @event columnchange
			 */
			this.$emit("columnchange", oldval, newval, hasError, columnObject);
		},

		/**
		 * @method getColumnIndex
		 * @param {String} column column title to find index
		 */
		getColumnIndex(column) {
			return this.columns.findIndex(c => c.title === column);
		},

		/**
		 * @method sortColumn
		 * @param {String} column column title to sort
		 * @param {String} order order to sort , that is 'asc' or 'desc'
		 */
		sortColumn(column, order, component) {
			this.innerSort = column;
			this.innerOrder = order;
			this.parentComponent = component;
			const idx = this.getColumnIndex(column);
			// eslint-disable-next-line vue/no-mutating-props
			this.tableData.sort((a, b) => {
				const first = a[idx] || "";
				const second = b[idx] || "";
				if (first.valueOf() < second.valueOf()) {
					return order === "asc" ? -1 : 1;
				}
				if (first.valueOf() > second.valueOf()) {
					return order === "asc" ? 1 : -1;
				}
				return 0;
			});
		},

		handleRowHover(rowIndex, row) {
			/**
			 * rowhover event
			 *
			 * @event rowhover
			 */

			this.$emit("rowhover", rowIndex, row);
		},

		handleRowClick(rowIndex, row) {
			/**
			 * rowclick event
			 *
			 * @event rowclick
			 */

			this.$emit("rowclick", rowIndex, row);
		}
	}
});
</script>
<style lang="scss">
div.flow-data-table2 {
	width: 100%;
	overflow: auto;
	&.full-width {
		> table {
			width: 100%;
		}
	}
	> table {
		max-width: 100%;
		min-width: 70%;
		border-collapse: separate;
		border-spacing: 0;
		> thead {
			tr:first-child th {
				position: sticky;
				top: 0;
				z-index: 1;
				// background-color: var(--dashboard-element-light) !important;
			}
		}
		> thead,
		> tbody {
			> tr {
				> td,
				> th {
					vertical-align: middle;
					text-align: left;
					// min-width: 150px;
					* {
						vertical-align: middle;
					}
					&.sticky {
						position: sticky;
						left: 0;
						z-index: 4;
					}
				}
				> td {
					height: 1px;
					color: var(--dashboard-font-dark);
					font-size: 12px;
					font-weight: 300;
					border-bottom: 1px solid var(--dashboard-border);
				}
				> th {
					color: var(--dashboard-font-light);
					background-color: var(--dashboard-background);
					font-size: 12px;
					border-bottom: 1px solid var(--dashboard-border);
					&.sticky {
						position: sticky;
						top: 0;
						z-index: 5;
					}
					.sort-icon {
						padding-right: 16px;
						margin-left: auto;
						float: right;
					}
					.text-editor {
						display: flex;
						align-items: center;
					}
				}
				&:hover {
					background-color: var(--dashboard-element-light);
				}
			}
		}
		> thead {
			> tr:first-of-type {
				> th:first-of-type {
					padding-left: 5px;
				}
			}
		}
		> tbody {
			> tr:last-of-type {
				> td:first-of-type {
					border-bottom-left-radius: 8px;
				}
				> td:last-of-type {
					border-bottom-right-radius: 8px;
				}
				> td {
					border-bottom: none;
				}
			}
		}
	}
	div.flow-data-table2-no-more-results {
		padding: 12px;
		display: flex;
		width: inherit;
		justify-content: flex-end;
		position: sticky;
		left: 0;
	}
	div.flow-data-table2-loading-more-results {
		padding: 12px;
		display: flex;
		width: inherit;
		justify-content: flex-end;
		align-items: center;
		position: sticky;
		left: 0;
		> * + * {
			margin-left: 8px;
		}
	}
	div.flow-data-table2-empty {
		padding: 24px;
	}
}
</style>
