import { FDivStateProp, FButtonState } from "@ollion/flow-core";

import { ComponentStatusIcon, STANCE_ICONS, EvidenceStatusIcon } from "./icons";

export const CHARACTER_LIMIT = 20;

export const COMPONENT_STATUS = {
	draft: "Draft",
	classificationApprovalPending: "Classification Approval Pending",
	classificationApprovalRejected: "Classification Rejected",
	artifactRequired: "Artifact Required",
	evidenceApprovalPending: "Evidence Approval Pending",
	evidencePartiallyApproved: "Evidence Partially Approved",
	readyToRelease: "Dev Released",
	notarised: "Notarised",
	published: "Published",
	onboardingComplete: "Onboarding Complete",
	deprecationPending: "Deprecation Pending",
	deprecated: "Deprecated",
	nonCompliant: "Non-Compliant"
} as const;

export type ComponentStatus = (typeof COMPONENT_STATUS)[keyof typeof COMPONENT_STATUS];

export const COMPONENT_DISPLAY: Record<
	ComponentStatus,
	{
		icon: ComponentStatusIcon;
		headerState: FDivStateProp;
		state: FDivStateProp;
		text: ComponentStatus | string;
	}
> = {
	[COMPONENT_STATUS.readyToRelease]: {
		state: "custom, #CC8800",
		icon: STANCE_ICONS.APPROVED,
		text: COMPONENT_STATUS.readyToRelease,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.classificationApprovalPending]: {
		state: "custom, #6C4F2B",
		icon: STANCE_ICONS.PENDING_APPROVAL,
		text: COMPONENT_STATUS.classificationApprovalPending,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.evidencePartiallyApproved]: {
		state: "custom, #CC8800",
		icon: STANCE_ICONS.PENDING_APPROVAL,
		text: COMPONENT_STATUS.evidencePartiallyApproved,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.evidenceApprovalPending]: {
		state: "custom, #6C4F2B",
		icon: STANCE_ICONS.PENDING_APPROVAL,
		text: COMPONENT_STATUS.evidenceApprovalPending,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.draft]: {
		state: "custom, #CC8800",
		icon: STANCE_ICONS.DRAFT,
		text: COMPONENT_STATUS.draft,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.artifactRequired]: {
		state: "custom, #006ECC",
		icon: STANCE_ICONS.ARTIFACT_REQUIRED,
		text: COMPONENT_STATUS.artifactRequired,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.classificationApprovalRejected]: {
		state: "custom, #C44040",
		icon: STANCE_ICONS.REJECTED,
		text: COMPONENT_STATUS.classificationApprovalRejected,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.onboardingComplete]: {
		state: "custom, #546237",
		icon: STANCE_ICONS.ONBOARDING_COMPLETE,
		text: COMPONENT_STATUS.onboardingComplete,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.notarised]: {
		state: "custom, #546237",
		icon: STANCE_ICONS.NOTARISED,
		text: "Notarized",
		headerState: "secondary"
	},
	[COMPONENT_STATUS.published]: {
		state: "custom, #237476",
		icon: STANCE_ICONS.PUBLISHED,
		text: COMPONENT_STATUS.published,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.deprecationPending]: {
		state: "custom, #CC8800",
		icon: STANCE_ICONS.DEPRECATION_PENDING,
		text: COMPONENT_STATUS.deprecationPending,
		headerState: "secondary"
	},
	[COMPONENT_STATUS.deprecated]: {
		state: "custom, #892929",
		icon: STANCE_ICONS.DEPRECATED,
		text: COMPONENT_STATUS.deprecated,
		headerState: "warning"
	},
	[COMPONENT_STATUS.nonCompliant]: {
		state: "custom, #A27210",
		icon: STANCE_ICONS.NON_COMPLIANT,
		text: "Obligations changed",
		headerState: "custom, #E0C182"
	}
};

export const COMPONENT_FILTER = {
	all: {
		id: 1,
		filters: [...Object.values(COMPONENT_STATUS)]
	},
	approved: { id: 2, filters: [COMPONENT_STATUS.readyToRelease] },
	artifactRequired: { id: 3, filters: [COMPONENT_STATUS.artifactRequired] },
	draft: { id: 4, filters: [COMPONENT_STATUS.draft] },
	pending: {
		id: 5,
		filters: [
			COMPONENT_STATUS.evidenceApprovalPending,
			COMPONENT_STATUS.classificationApprovalPending,
			COMPONENT_STATUS.evidencePartiallyApproved
		]
	},
	rejected: { id: 6, filters: [COMPONENT_STATUS.classificationApprovalRejected] }
};

export type ComponentFilterType = (typeof COMPONENT_FILTER)[keyof typeof COMPONENT_FILTER];

// Release Cockpit Routes
export const RELEASE_COCKPIT = "release-cockpit";
export const COMPONENT = "component";
export const CREATE_COMPONENT = "create-component";
export const POLICY_STANDARD = "policy-standard";

export const ROUTE_RELEASE_COCKPIT = `/${RELEASE_COCKPIT}`;
export const ROUTE_COMPONENT = `${ROUTE_RELEASE_COCKPIT}/${COMPONENT}`;
export const ROUTE_CREATE_COMPONENT = `${ROUTE_RELEASE_COCKPIT}/${CREATE_COMPONENT}`;
export const ROUTE_POLICY_STANDARD = `${ROUTE_RELEASE_COCKPIT}/${COMPONENT}/${POLICY_STANDARD}/:id`;

// Artifact
export const GCP_STORAGE_DETAILS_BASE_URL =
	"https://console.cloud.google.com/storage/browser/_details/";

export const TAXONOMY_LEVELS = 8;

//Evidence
export const EVIDENCE_STATUS = {
	showAll: "showAll",
	pending: "Pending",
	approved: "Approved",
	rejected: "Rejected",
	notAvailable: "No evidence"
};

export const CLASSIFICATION_STRING = "classification";

export const MANDATORY_CLASSIFICATIONS = [0, 1, 5, 6, 7];
export const OPTIONAL_CLASSIFICATIONS = [2, 3, 4];

export enum CALLBACK_STATUS {
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
	NO_STATUS = "NO_STATUS"
}

export enum TAXONOMY_TYPES {
	DOMAIN = "DOMAIN",
	TBM = "TBM",
	CLASS = "CLASS"
}

export const ASSET_COMPONENT_TYPE = "asset";

export const PASSWORD_LENGTH = 8;

export const MIN_DESKTOP_WIDTH = 1270;

export function getIconFromEnvironment(environment?: string) {
	if (!environment) {
		return "i-box";
	}

	switch (environment.toLocaleLowerCase()) {
		case "sandbox":
		case "development":
			return "i-dev";
		case "test":
		case "staging":
			return "i-staging";
		case "production":
			return "i-prod";
		default:
			return "i-box";
	}
}

export type EvidenceStatus = (typeof EVIDENCE_STATUS)[keyof typeof EVIDENCE_STATUS];

export const EVIDENCE_STATUS_DISPLAY: Record<
	EvidenceStatus,
	{
		icon: EvidenceStatusIcon;
		state: FButtonState;
		text: EvidenceStatus;
	}
> = {
	[EVIDENCE_STATUS.showAll]: {
		state: "success",
		icon: STANCE_ICONS.EVIDENCE_SHOW_ALL,
		text: "Show all"
	},
	[EVIDENCE_STATUS.approved]: {
		state: "success",
		icon: STANCE_ICONS.EVIDENCE_APPROVED,
		text: EVIDENCE_STATUS.approved
	},
	[EVIDENCE_STATUS.rejected]: {
		state: "danger",
		icon: STANCE_ICONS.EVIDENCE_REJECTED,
		text: EVIDENCE_STATUS.rejected
	},

	[EVIDENCE_STATUS.pending]: {
		state: "warning",
		icon: STANCE_ICONS.EVIDENCE_PENDING,
		text: EVIDENCE_STATUS.pending
	},
	[EVIDENCE_STATUS.notAvailable]: {
		state: "custom, #dad5d3",
		icon: STANCE_ICONS.EVIDENCE_NOT_AVAILABLE,
		text: EVIDENCE_STATUS.notAvailable
	}
};

export const COMPONENT_DESCRIPTION_LENGTH = 32768;
export const COMPONENT_JUSTIFICATION_LENGTH = 256;
