import { FIconState } from "@ollion/flow-core";

export const STANCE_ICONS = {
	APPROVED: { icon: "i-tick-double", color: "success" },
	ARTIFACT_REQUIRED: { icon: "i-alert", color: "warning" },
	DRAFT: { icon: "i-edit", color: "neutral" },
	PENDING_APPROVAL: { icon: "i-user-time", color: "custom, #CC8800" },
	REJECTED: { icon: "i-stop", color: "danger" },
	RESET: { icon: "i-reset", color: "neutral" },
	NOTARISED: { icon: "i-tick-double", color: "success" },
	PUBLISHED: { icon: "i-verified", color: "success" },
	ONBOARDING_COMPLETE: { icon: "i-tick-double", color: "success" },
	DEPRECATION_PENDING: { icon: "i-history", color: "warning" },
	DEPRECATED: { icon: "i-unlink", color: "danger" },
	NON_COMPLIANT: { icon: "i-alert", color: "neutral" },

	EVIDENCE_APPROVED: { icon: "i-evidence-approved", color: "success" },
	EVIDENCE_REJECTED: { icon: "i-evidence-rejected", color: "danger" },
	EVIDENCE_PENDING: { icon: "i-evidence-rejected", color: "neutral" },
	EVIDENCE_NOT_AVAILABLE: { icon: "i-evidence-missing", color: "neutral" },
	EVIDENCE_SHOW_ALL: { icon: "i-paper-clip", color: "neutral" }
} satisfies Readonly<Record<string, ComponentStatusIcon>>;

export type ComponentStatusIcon = { icon: string; color: FIconState };

//@todo - This should come from taxons as the consumers can change it
//@question - what is the difference between a component type and it's L1 taxon?
export const COMPONENT_TYPE_DISPLAY: Record<string, { id: string; icon: string; text: string }> = {
	SOFTWARE: {
		id: "SOFTWARE",
		icon: "i-puzzle",
		text: "Software"
	},
	SERVICE: {
		id: "SERVICE",
		icon: "i-cloud",
		text: "Service"
	},
	ASSET: {
		id: "ASSET",
		icon: "i-assets",
		text: "Asset"
	}
};

export type EvidenceStatusIcon = { icon: string; color: FIconState };
