import { AxiosResponse } from "axios";

import { WorkflowService, DeprecationInfo } from "@/protocol/workflow";

import HTTP from "../services/api-service";

const WORKFLOW_SERVICE_PATH = "/workflow-service/v1/workflow";

// Options: [{"(google.api.http)":{"post":"/workflow-service/v1/classification/{componentId}/deprecation","body":"*"}}]
export const createDeprecationRequest: WorkflowService["createDeprecationRequest"] =
	async function ({ componentId, reason }) {
		const payload = {
			reason,
			componentId
		};
		const response: AxiosResponse<DeprecationInfo> = await HTTP.post(
			`${WORKFLOW_SERVICE_PATH}/classification/${componentId}/deprecation`,
			payload
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};

// Options: [{"(google.api.http)":{"post":"/workflow-service/v1/classification/{componentId}/deprecation/approval","body":"*"}}]
export const approveRejectDeprecationTask: WorkflowService["approveRejectDeprecationTask"] =
	async function (payload) {
		const response: AxiosResponse<DeprecationInfo> = await HTTP.put(
			`${WORKFLOW_SERVICE_PATH}/classification/${payload.componentId}/deprecation/approval`,
			payload
		);
		if (response.status === 200) {
			return response.data;
		}
		throw response;
	};
// Options: [{"(google.api.http)":{"put":"/workflow-service/v1/classification/{componentId}/deprecation/acknowledge","body":"*"}}]
export const acknowledgeDeprecation: WorkflowService["acknowledgeDeprecation"] = async function ({
	componentId,
	acknowledge
}) {
	const payload = {
		acknowledge,
		componentId
	};
	const response: AxiosResponse<DeprecationInfo> = await HTTP.put(
		`${WORKFLOW_SERVICE_PATH}/classification/${componentId}/deprecation/acknowledge`,
		payload
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

// Options: [{"(google.api.http)":{"post":"/workflow-service/v1/classification/{componentId}/deprecation","body":"*"}}]
export const componentDeprecationById: WorkflowService["componentDeprecationById"] =
	async function ({ componentId }) {
		const response: AxiosResponse<DeprecationInfo> = await HTTP.get(
			`${WORKFLOW_SERVICE_PATH}/classification/${componentId}/deprecations`
		);
		if (response.status === 200) {
			return response.data;
		} else if (response.status === 500) {
			return {};
		}

		throw response;
	};
