<template>
	<f-div direction="row" gap="medium" padding="large" state="transparent" width="100%">
		<f-div direction="row" height="hug-content" state="transparent">
			<DataTable
				:columns="dataTableColumns"
				:edit-columns="false"
				:loading="loading"
				:no-more-api-results="false"
				:results-per-page="10"
				:table-data="dataTableRows"
				sort="ID"
				sticky-column="ID"
			>
				<template #header="{ column }">
					<WYSIWYG
						v-model:value="column.title"
						:editable="editMode && column.editable"
						:highlight-state="column.hasError ? 'error' : 'primary'"
						text-classes="paragraph-2 fc-light text-nowrap"
						@click.stop=""
					/>
				</template>

				<template #column="{ column, columnIndex }">
					<f-div align="middle-left" gap="small" padding="medium">
						<f-icon
							v-if="columnIndex !== 0"
							:source="dataTableColumns[columnIndex]?.icon?.name ?? 'i-icon'"
						/>
						{{ column }}
					</f-div>
				</template>
			</DataTable>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { WYSIWYG } from "@ollion/flow-vue3";
import { mapState } from "pinia";
import { defineComponent } from "vue";

import { taxonomyStore } from "@/modules/taxonomy-table/taxonomy-table-store";
import DataTable from "@/shared/components/DataTable.vue";

export default defineComponent({
	name: "TaxonomyTableContent",
	components: { WYSIWYG, DataTable },

	data() {
		return {
			loading: false,
			editMode: false
		};
	},

	computed: {
		...mapState(taxonomyStore, ["dataTableColumns", "dataTableRows"])
	}
});
</script>

<style scoped></style>
