<template>
	<div />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { extractNumericVersion, fetchLatestVersion, getAppVersion } from "@/utils";

export default defineComponent({
	name: "AppUpdateChecker",
	emits: ["versionUpdated"],

	mounted() {
		document.addEventListener("visibilitychange", this.visibilityListener);
	},

	beforeUnmount() {
		document.removeEventListener("visibilitychange", this.visibilityListener);
	},

	methods: {
		visibilityListener() {
			if (document.visibilityState === "visible") {
				this.checkForVersionUpdate();
			}
		},

		async checkForVersionUpdate() {
			const fetchedVersion = await fetchLatestVersion();

			if (!fetchedVersion) {
				return;
			}

			const currentVersion = extractNumericVersion(getAppVersion());
			const newVersion = extractNumericVersion(fetchedVersion);

			if (newVersion > currentVersion) {
				this.$emit("versionUpdated", newVersion);
			}
		}
	}
});
</script>
