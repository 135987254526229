export interface UserTheme {
	theme: "f-ollion-light" | "f-ollion-dark";
	mode: "light-mode" | "dark-mode";
}

export type RoleTypes =
	| "Basic"
	| "Developer"
	| "Compliance-Officer"
	| "IT-officer"
	| "Solution-Architect"
	| "Global";

export const RoleTypesList = [
	"Basic",
	"Developer",
	"Compliance-Officer",
	"IT-officer",
	"Solution-Architect",
	"Global"
];

export const EligibleRolesForAdmin = [
	"Basic",
	"Developer",
	"Compliance-Officer",
	"IT-officer"
] as string[];

export const isAdminRole = (roles?: string[]) => {
	return (
		roles &&
		roles.includes("Basic") &&
		roles.includes("Developer") &&
		roles.includes("Compliance-Officer") &&
		roles.includes("IT-officer")
	);
};
