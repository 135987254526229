import { ConfigUtil } from "@ollion/flow-core-config";
import { createApp } from "vue";

import App from "@/modules/core/components/App.vue";

import { startApp } from "./start-app";
import { polyfillXpath } from "./utils";

import "@ollion/flow-code-editor";
import "@ollion/flow-core";
import "@ollion/flow-form-builder";
import "@ollion/flow-product-icon";
import "@ollion/flow-system-icon";
import "@ollion/flow-engineering-icon";
import "@ollion/flow-table";
import "@ollion/flow-log";

import "@ollion/flow-vue3/dist/style.css";
import "@/assets/print.css";

// Flow type imports
import "@ollion/flow-table/dist/types/vue3";
import "@ollion/flow-log/dist/types/vue3";
import "@ollion/flow-core/dist/types/vue3";
import "@ollion/flow-form-builder/dist/types/vue3";

ConfigUtil.setConfig({ theme: "f-ollion-light" });

startApp(createApp(App));

if (window.Cypress) {
	window.appLoaded = true;
}

// Code to help with automation
polyfillXpath();

// Needed for Vue devtools to open files in the editor
if (process.env.NODE_ENV !== "production") {
	window.VUE_DEVTOOLS_CONFIG = {
		openInEditorHost: `http://${window.location.host}/ui/`
	};
}

declare global {
	interface Window {
		VUE_DEVTOOLS_CONFIG: {
			openInEditorHost: string;
		};
	}
}
