<template>
	<f-div overflow="scroll" state="default">
		<f-div padding="none" direction="column" gap="small" height="hug-content">
			<f-div
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="secondary"
				height="hug-content"
				border="medium solid subtle bottom"
				overflow="visible"
				align="middle-center"
			>
				<f-div align="middle-left" width="hug-content" padding="none">
					<f-text size="medium" variant="para" state="secondary" weight="bold">
						Profile settings
					</f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						data-qa="profile-settings-submit-profile"
						label="Save"
						state="primary"
						variant="round"
						size="medium"
						category="outline"
						:disabled="!isSubmitAllowed"
						@click.stop="submitProfile()"
					></f-button>
				</f-div>
			</f-div>
			<f-div direction="column" padding="large">
				<f-div direction="column" gap="medium" padding="medium">
					<f-div
						border="small dashed secondary bottom"
						gap="medium"
						padding="large none medium none"
						align="middle-left"
					>
						<f-icon size="medium" source="i-user-info"></f-icon>
						<f-text variant="heading" size="small" weight="medium">Name</f-text>
					</f-div>
					<f-div :loading="profileLoading ? `skeleton` : undefined" padding="medium" gap="medium">
						<f-div direction="column" gap="x-small" width="20%">
							<f-input
								v-model="profileData.name.first"
								data-qa="profile-settings-first-name"
								placeholder="Enter first name"
							>
								<f-div slot="label" padding="none" gap="none">First name</f-div>
							</f-input>
						</f-div>
						<f-div direction="column" gap="x-small" width="20%">
							<f-input
								v-model="profileData.name.last"
								data-qa="profile-settings-last-name"
								placeholder="Enter last name"
							>
								<f-div slot="label" padding="none" gap="none">Last name</f-div>
							</f-input>
						</f-div>
					</f-div>
				</f-div>
				<f-div direction="column" gap="medium" padding="medium">
					<f-div
						border="small dashed secondary bottom"
						gap="medium"
						padding="large none medium none"
						align="middle-left"
					>
						<f-icon size="medium" source="i-mail"></f-icon>
						<f-text variant="heading" size="small" weight="medium">Email</f-text>
					</f-div>
					<f-div padding="medium" :loading="profileLoading ? `skeleton` : undefined" gap="medium">
						<f-div direction="column" gap="x-small" width="41%">
							<f-input
								v-model="profileData.email"
								data-qa="profile-settings-email"
								:state="emailMeta.state"
								placeholder="Enter email"
								@keyup="validateEmail()"
							>
								<f-text slot="help" variant="para" size="small">{{ emailMeta.errMsg }}</f-text>
							</f-input>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FInputState } from "@ollion/flow-core";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "../../auth/auth-store";

export default defineComponent({
	name: "ProfileSettings",

	data() {
		return {
			profileData: {
				name: {
					first: "",
					last: ""
				},

				email: ""
			},

			emailMeta: {
				state: "default" as FInputState,
				errMsg: ""
			},

			profileLoading: false
		};
	},

	computed: {
		...mapStores(authStore),

		isSubmitAllowed() {
			return !this.emailMeta.errMsg && this.profileData.email;
		}
	},

	mounted() {
		this.setInitialData();
	},

	methods: {
		async setInitialData() {
			this.profileLoading = true;
			await this.authStore.CHECK_SESSION();
			this.profileData = this.authStore.userSession?.identity?.traits;
			this.profileLoading = false;
		},

		validateEmail() {
			this.emailMeta.state = "danger";
			if (this.profileData.email === "") {
				this.emailMeta.errMsg = "The Email field is required";
				return;
			}
			const regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");
			if (!regex.test(this.profileData.email)) {
				this.emailMeta.errMsg = "The Email field must be a valid email";
			} else {
				this.emailMeta.errMsg = "";
				this.emailMeta.state = "default";
			}
		},

		submitProfile() {
			this.$emit("submit", this.profileData);
		}
	}
});
</script>
