<template>
	<Card background="element-light" :separator="false">
		<template #header>
			<Container overflow="visible">
				<Slab :no-padding="true" overflow="visible">
					<Container :padding="0">
						<Pictogram shape="circle" state="border" size="xl">
							<Icon name="i-database" size="small" type="filled" :effects="false" />
						</Pictogram>
						<Container direction="column" :gap="4" :padding="0">
							<Container :gap="4" :padding="0">
								<Typography v-if="nodeData.data && nodeData.data.name" type="h3" color="dark">{{
									nodeData.data.name
								}}</Typography>
							</Container>
						</Container>
					</Container>
					<template #secondary-actions>
						<Icon
							data-qa-close-node-details-popup
							name="i-close"
							size="small"
							type="filled"
							@click="$emit('close')"
						></Icon>
					</template>
				</Slab>
			</Container>
		</template>
		<Tabs :sticky="true" size="small">
			<Tab :selected="true">
				<Container :gap="8" :padding="0">
					<Typography type="p2" weight="medium" color="dark">Details</Typography>
				</Container>
			</Tab>
			<template #content>
				<Wrapper width="450px">
					<Container>
						<table class="key-value" data-key-size="30" data-row-gutter="8px">
							<caption></caption>
							<thead>
								<tr>
									<th>
										<Container align="left center" :padding="0" :gap="4">
											<Typography type="p4" transform="uppercase">key</Typography>
											<Icon name="i-arrow-down" type="filled" size="xx-small" />
										</Container>
									</th>
									<th>
										<Container align="left center" :padding="0">
											<Typography type="p4" transform="uppercase">value</Typography>
										</Container>
									</th>
								</tr>
							</thead>
							<tbody v-if="nodeData.data">
								<tr v-for="(value, key) in nodeData.data.props" :key="key">
									<td>
										<Typography type="p2" color="dark"> {{ getTitleCase(key) }} </Typography>
									</td>
									<td>
										<Typography
											:data-qa-node-value="`data-qa-attribute-${key}`"
											type="p2"
											color="light"
										>
											{{ value }}
										</Typography>
									</td>
								</tr>
							</tbody>
						</table>
					</Container>
				</Wrapper>
			</template>
		</Tabs>
		<template #footer>
			<Container background="element" align="end" :padding="0">
				<Container>
					<!-- TODO Implement export funcationality for node details -->
					<!-- <Button state="outlined" size="small" type="primary" class="margin-rt-auto">
						<Typography weight="medium" type="p1" color="primary"> Export</Typography>
						<Icon size="small" type="outlined" state="primary" name="download"></Icon>
					</Button> -->
				</Container>
			</Container>
		</template>
	</Card>
</template>

<script>
import {
	Card,
	Container,
	Icon,
	Pictogram,
	Slab,
	Tab,
	Tabs,
	Typography,
	Wrapper
} from "@ollion/flow-vue3";
import { defineComponent } from "vue";

import { toTitleCase } from "@/utils";

export default defineComponent({
	name: "PopOverCard",

	components: {
		Typography,
		Container,
		Card,
		Slab,
		Pictogram,
		Tabs,
		Tab,
		Icon,
		Wrapper
	},

	props: {
		nodeData: {
			type: Object,

			default() {
				return {};
			}
		}
	},

	data() {
		return {};
	},

	methods: {
		getTitleCase(key) {
			return toTitleCase(key);
		}
	}
});
</script>

<style></style>
