<script setup lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData } from "@ollion/flow-table";
import { computed, ref, toRefs, watch } from "vue";

import { CobitMap, getCobitMap } from "@/utils";

import { documentMappingStore, getMatchStrengthState } from "../document-mapping-store";

//@todo - implement actual refinement by storeFineTuningFeedback API

const documentStore = documentMappingStore();
const documentState = computed(() => documentStore.currentDocumentState);
const emit = defineEmits(["close"]);
const mappedStatementsTableData = ref<FTableSchemaData | null>(null);
const isLoading = ref(true);

const props = defineProps({
	statementId: {
		type: String,
		required: true
	}
});

const { statementId } = toRefs(props);

const statement = computed(() => {
	return documentState.value.statements.find(({ id }) => id === statementId.value);
});

const modelCobitMap = computed((): CobitMap | null => {
	if (!statement.value) {
		return null;
	}

	return documentState.value.cobitMap[statement.value.id] ?? null;
});

const userCobitMap = computed((): CobitMap | null => {
	if (!statement.value) {
		return null;
	}

	const refinedMap = documentState.value.similarityFeedback[statement.value.id];

	if (refinedMap) {
		return {
			id: refinedMap.id,
			activity: refinedMap.statement2,
			similarity: refinedMap.modelScore
		};
	}

	return null;
});

const userSelectedCobitMap = ref<CobitMap | null>(null);

watch(
	() => statement.value?.str,
	async () => {
		if (!statement.value?.str) {
			return;
		}

		isLoading.value = true;

		const fullMap = await getCobitMap(statement.value.str, Infinity);
		const fullCobitMatches = fullMap.cobitMatches;

		userSelectedCobitMap.value = userCobitMap.value ?? modelCobitMap.value;

		mappedStatementsTableData.value = {
			header: {
				id: {
					value: "ID",
					width: "auto",
					align: "middle-center"
				},
				strength: {
					value: "Strength",
					width: "auto",
					align: "middle-left"
				},
				statement: {
					value: "Compliance statement",
					width: "auto",
					align: "middle-left"
				}
			},
			rows: fullCobitMatches.map(statementCobitMap => {
				const { id, activity, similarity } = statementCobitMap;
				const renderedStrength = Math.floor(similarity * 100);
				const strengthState = getMatchStrengthState(similarity);
				const isSelected = userSelectedCobitMap.value?.id === id;

				return {
					selected: isSelected,
					cobitMap: statementCobitMap,
					id,
					data: {
						id: {
							value: id,
							template: () => {
								return html` <f-text size="small" weight="bold">${id}</f-text> `;
							}
						},
						strength: {
							value: renderedStrength,
							template: () => {
								return html`
									<f-text size="small" weight="bold" state="${strengthState}"
										>${renderedStrength}</f-text
									>
								`;
							}
						},
						statement: {
							value: activity
						}
					}
				};
			})
		};

		isLoading.value = false;
	},
	{
		immediate: true
	}
);

function saveStatement() {
	if (userSelectedCobitMap.value) {
		documentStore.UPDATE_COBIT_MAP(statementId.value, userSelectedCobitMap.value);
	}

	emit("close");
}

function selectRow(event: CustomEvent<{ cobitMap: CobitMap }>) {
	userSelectedCobitMap.value = event.detail.cobitMap;
}
</script>

<template>
	<f-div state="default" direction="column" overflow="scroll">
		<!-- Statement info -->
		<f-div padding="medium" height="hug-content" state="secondary" align="middle-left" gap="large">
			<f-text inline>{{ statement?.displayId }}</f-text>
			<f-text weight="bold">{{ statement?.str }}</f-text>
			<f-button state="primary" size="small" label="Save" @click="saveStatement"></f-button>
		</f-div>

		<f-div overflow="scroll" padding="medium">
			<f-table-schema
				v-if="mappedStatementsTableData"
				variant="underlined"
				selectable="single"
				:data.prop="mappedStatementsTableData"
				:show-search-bar="true"
				highlight-hover
				:highlight-column-hover="false"
				sticky-header
				size="small"
				@row-input="selectRow"
			>
			</f-table-schema>
		</f-div>
	</f-div>
</template>
../document-mapping-store
