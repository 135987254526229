<template>
	<NotificationsWrapper :toast-count="toasts.length">
		<Notification
			v-for="toast in toasts"
			:key="toast.id"
			:toast="toast"
			@remove="removeToast(toast)"
		></Notification>
	</NotificationsWrapper>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { ToastItem } from "../notification-types";
import { notificationsStore } from "../notifications-store";

import Notification from "./Notification.vue";
import NotificationsWrapper from "./NotificationsWrapper.vue";

export default defineComponent({
	name: "NotificationManager",

	components: {
		Notification,
		NotificationsWrapper
	},

	computed: {
		...mapStores(notificationsStore),

		toasts() {
			return this.notificationsStore.toasts;
		}
	},

	methods: {
		async removeToast(toast: ToastItem) {
			// Toast has an internal timer of 500ms before destroying, we need to follow that
			// otherwise the element will be removed from DOM before it has a chance to finish animating.
			await new Promise(resolve => setTimeout(resolve, 500));

			this.notificationsStore.REMOVE_TOAST(toast);
		}
	}
});
</script>
