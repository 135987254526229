<template>
	<f-div
		height="hug-content"
		border="small solid secondary bottom"
		padding="small"
		gap="small"
		align="top-left"
		style="min-height: 50px"
	>
		<f-div gap="medium" align="middle-left" width="200px">
			<f-icon size="medium" state="subtle" :source="classification.icon"></f-icon>
			<f-text inline variant="para" size="medium" weight="regular" state="secondary">
				{{ classification.name }}
			</f-text>
		</f-div>
		<f-div align="middle-left" padding="x-small none none none">
			<f-div v-if="isEditing" gap="medium">
				<f-select
					v-if="classification.selectionOptions"
					:selection-limit="1"
					:searchable="true"
					:state="errors.length ? 'danger' : 'default'"
					:value="modelValue"
					size="small"
					:disabled="classification.selectionOptions.disabled"
					:options="classification.selectionOptions.options"
					@input="inputChange($event)"
				>
					<f-text v-if="errors.length" slot="help" variant="para" size="small">{{
						errors[0]
					}}</f-text>
				</f-select>
				<f-text-area
					v-if="classification.textareaOptions"
					category="fill"
					:placeholder="classification.textareaOptions.placeholder"
					max-length=""
					rows=""
					:value="modelValue"
					:state="errors.length ? 'danger' : 'default'"
					@input="event => inputChange(event)"
				>
					<f-text v-if="errors.length" slot="help" variant="para" size="small">{{
						errors[0]
					}}</f-text></f-text-area
				>
			</f-div>
			<f-text v-else inline variant="para" size="medium" weight="medium" state="default">
				{{ modelValue }}
			</f-text>
		</f-div>
		<f-div gap="medium" align="middle-right" width="hug-content">
			<f-icon-button
				v-if="showEditBtn && !isEditing"
				variant="block"
				category="transparent"
				icon="i-edit"
				state="neutral"
				size="small"
				@click="isEditing = true"
			></f-icon-button>
			<f-div v-if="showFlagSpace" align="middle-center" width="16px">
				<!-- button to toggle flag by approver -->
				<f-icon-button
					v-if="showFlagBtn && classification.selectionOptions"
					variant="block"
					icon="i-flag"
					size="small"
					:state="flagValue ? 'warning' : 'inherit'"
					@click="onFlagClick"
				></f-icon-button>
				<!-- icon for the engineer to show the flag status -->
				<f-icon
					v-if="!showFlagBtn && classification.isFlagged"
					source="i-flag"
					size="small"
					state="warning"
				></f-icon>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import { ClassificationDetailRowType } from "@/modules/release-cockpit-v2/release-cockpit-types";

export default defineComponent({
	name: "ClassificationDetailRow",

	props: {
		classification: {
			type: Object as PropType<ClassificationDetailRowType>,
			required: true
		},

		showEditBtn: {
			type: Boolean,
			required: true
		},

		showFlagBtn: {
			type: Boolean,
			required: true
		},

		modelValue: {
			type: String,
			required: true
		},

		showSelectionField: {
			type: Boolean,
			required: true
		},

		flagValue: {
			type: Boolean,
			required: true
		},

		showFlagSpace: {
			type: Boolean,
			default: true
		}
	},

	emits: ["update:modelValue", "flag-btn-click", "fieldStateChange"],

	data() {
		return {
			isEditing: false,
			errors: [] as string[]
		};
	},

	watch: {
		showSelectionField: {
			immediate: true,

			handler(newValue: boolean) {
				if (newValue) {
					this.isEditing = newValue;
				}
			}
		},

		showEditBtn() {
			this.isEditing = false;
		},

		modelValue: {
			immediate: true,

			handler(newValue: string) {
				this.validateField(newValue);
			}
		}
	},

	methods: {
		inputChange(event: CustomEvent) {
			this.$emit("update:modelValue", event.detail.value);
		},

		onFlagClick() {
			this.$emit("flag-btn-click", {
				classificationLevelKey: this.classification.classificationLevelKey,
				flagValue: !this.flagValue
			});
		},

		validateField(value: string) {
			const { validationRules } =
				this.classification.textareaOptions ?? this.classification.selectionOptions ?? {};

			this.errors = [];

			validationRules?.forEach(rule => {
				const isValid = rule.validator(value);
				if (!isValid) {
					this.errors.push(rule.message);
				}
			});

			this.$emit("fieldStateChange", {
				[this.classification.classificationLevelKey]: {
					errors: this.errors,
					isValid: validationRules?.length ? !this.errors.length : true
				}
			});
		}
	}
});
</script>
