<template>
	<div>
		<FormWrapper>
			<Section padding="horizontal">
				<FormField v-if="showEmailField">
					<template #label>
						<p class="paragraph-2 fc-dark">Email</p>
					</template>
					<template #help>
						<Typography
							v-if="isErrorState(email.state)"
							color="error"
							type="p1"
							data-qa="email-error"
							>{{ email.errorMsg }}
						</Typography>
					</template>
					<InputText
						v-model:value="email.value"
						:state="getState(email.state)"
						placeholder="Enter email"
						type="email"
						data-qa="email-input"
						@donetyping="validateEmail()"
						@keyup="validateEmail()"
					>
					</InputText>
				</FormField>
			</Section>
			<FormField v-if="showPasswordField">
				<template #label>
					<p class="paragraph-2 fc-dark">Password</p>
				</template>
				<template v-if="showForgotPasswordLabel" #info>
					<f-div>
						<f-text
							size="small"
							state="primary"
							style="cursor: pointer"
							data-qa-info-payload="reset-password"
							@click="redirectTo(`send-recovery-email`)"
						>
							Forgot password?
						</f-text></f-div
					>
				</template>
				<template #help>
					<Typography
						v-if="isErrorState(password.state)"
						color="error"
						type="p1"
						data-qa="password-error"
						>{{ password.errorMsg }}
					</Typography>
				</template>
				<InputText
					v-model:value="password.value"
					:show-password-eye="true"
					:state="getState(password.state)"
					placeholder="Enter password"
					data-qa="password-input"
					type="password"
					@donetyping="validatePassword()"
					@keyup="validatePassword()"
					@keypress.enter="submit()"
				>
				</InputText>
			</FormField>
		</FormWrapper>
		<Typography
			v-if="apiErrorMsg"
			class="padding-tp-10"
			color="error"
			data-qa-auth-form-error-message
			type="p1"
		>
			{{ apiErrorMsg }}
		</Typography>
		<Section class="center-of-container" padding="all">
			<div class="center-of-container flex-gap-20px width-80-per">
				<Button
					:disabled="!isSubmitAllowed"
					:full-width="true"
					data-qa="submit-button"
					:loading="isSubmitting!"
					type="primary"
					@click="submit"
					>{{ type }}
				</Button>
				<Button
					v-if="showSendEmailButton"
					:full-width="true"
					state="outlined"
					type="primary"
					data-qa="send-email-button"
					@click="redirectTo(`send-email`)"
					>Send Email
				</Button>
				<Button
					v-if="showSignUpButton"
					:full-width="true"
					state="outlined"
					type="default"
					data-qa="signup-redirect-button"
					@click="redirectTo(`signup`)"
					>Sign up
				</Button>
			</div>
		</Section>
		<f-div v-if="showoidc" align="middle-center" direction="column" gap="large">
			<f-text size="small" state="subtle">OR</f-text>
			<f-button
				category="outline"
				label="Continue with Google"
				icon-left="p-google"
				@click="submitoidc(OIDCProvider.GOOGLE)"
			></f-button>
		</f-div>
	</div>
</template>

<script lang="ts">
import { Button, FormField, FormWrapper, InputText, Section, Typography } from "@ollion/flow-vue3";
import { defineComponent } from "vue";

import {
	CHANGE_PASSWORD,
	LOG_IN,
	RESET_PASSWORD,
	SEND_VERIFICATION_EMAIL,
	SIGN_UP
} from "../auth-constants";
import { OIDCProvider } from "../auth-types";

export default defineComponent({
	name: "AuthForm",

	components: {
		FormWrapper,
		FormField,
		InputText,
		Section,
		Button,
		Typography
	},

	props: {
		apiErrorMsg: {
			type: String,
			required: false
		},

		type: {
			type: String,
			required: true
		},

		isSubmitAllowed: {
			type: Boolean,
			required: false,
			default: true
		},

		isSubmitting: Boolean
	},

	emits: ["submit", "submitoidc"],

	data() {
		return {
			OIDCProvider,
			email: {
				value: "",
				state: "default",
				errorMsg: ""
			},

			password: {
				value: "",
				state: "default",
				errorMsg: ""
			}
		};
	},

	computed: {
		showEmailField() {
			return this.type !== CHANGE_PASSWORD;
		},

		showPasswordField() {
			return this.type !== SEND_VERIFICATION_EMAIL && this.type !== RESET_PASSWORD;
		},

		showSignUpButton() {
			return this.type === LOG_IN && !this.showSendEmailButton;
		},

		showForgotPasswordLabel() {
			return this.type === LOG_IN;
		},

		showSendEmailButton(): boolean | undefined {
			return this.apiErrorMsg?.includes("Account not active yet");
		},

		showoidc() {
			return this.type === LOG_IN || this.type === SIGN_UP;
		}
	},

	methods: {
		validateEmail() {
			//Check valid email on keyup or paste
			this.email.state = "error";
			if (this.email.value === "") {
				this.email.errorMsg = "The Email field is required";
				return false;
			}
			const regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");
			if (!regex.test(this.email.value)) {
				this.email.errorMsg = "The Email field must be a valid email";
				return false;
			} else {
				this.email.errorMsg = "";
				this.email.state = "default";
				return true;
			}
		},

		validatePassword() {
			//Check valid password on keyup or paste
			this.password.state = "error"; // set error state by default
			if (this.password.value === "") {
				this.password.errorMsg = "The Password field is required";
				return false;
			} else if (this.password.value.length < 8) {
				this.password.errorMsg = "The Password field must be at least 8 characters long";
				return false;
			} else {
				this.password.errorMsg = "";
				this.password.state = "default";
				return true;
			}
		},

		redirectTo(screenName: string) {
			this.$router.push({ name: screenName });
		},

		isErrorState(state: string) {
			return state === `error` || this.apiErrorMsg;
		},

		getState(state: string) {
			return this.apiErrorMsg ? "error" : (state as "default" | "success" | "validating");
		},

		validateFields() {
			// Valid by default
			let isValid = true;

			// If email is shown, validate it
			if (this.showEmailField) {
				isValid = this.validateEmail();
			}

			// If password is shown, validate it
			if (this.showPasswordField) {
				isValid = isValid && this.validatePassword();
			}

			return isValid;
		},

		submit() {
			const isValid = this.validateFields();
			if (!isValid) {
				return;
			}
			const isSendEmail = this.type === "Send Email";
			const isEmailStateDefault = this.email.state === "default";
			const isPasswordStateDefault = this.password.state === "default";
			if (
				(!isSendEmail && (!isEmailStateDefault || !isPasswordStateDefault)) ||
				(isSendEmail && !isEmailStateDefault)
			) {
				return;
			}
			const payload = {
				email: this.email,
				password: this.password
			};
			this.$emit("submit", payload);
		},

		submitoidc(provider: OIDCProvider) {
			this.$emit("submitoidc", provider);
		}
	}
});
</script>
