/* eslint-disable complexity */
/* eslint-disable max-statements */
import Three from "compromise/types/view/three";
import { PageViewport } from "pdfjs-dist";
import { PDFPageProxy, TextItem } from "pdfjs-dist/types/src/display/api";

// After how many times of a item's height's empty space should we consider
// that a new line has started (this is useful to detect tables)
export const NEXT_WORD_BREAK_THRESHOLD = 5;

// After how many times of a item's height's empty space should we consider
// that a new indent has started
export const NEXT_INDENT_BREAK_THRESHOLD = 2;

const bulletPoints = [
	"•", // Bullet
	"‣", // Triangular Bullet
	"⁃", // Bullet Operator
	"◦", // White Bullet
	"◘", // Inverse Bullet
	"◦", // White Bullet
	"●", // Black Circle Bullet
	"○", // White Circle Bullet
	"■", // Black Square Bullet
	"□", // White Square Bullet
	"►", // Black Right-Pointing Pointer
	"▻", // White Right-Pointing Pointer
	"▪", // Small Black Square
	"▫", // Small White Square
	"◆", // Black Diamond
	"◇", // White Diamond
	"▶", // Black Right-Pointing Triangle
	"▷", // White Right-Pointing Triangle
	"◉", // Fisheye Bullet
	"❥", // Rotated Heavy Black Heart Bullet
	"⚫", // Medium Black Circle
	"⚪", // Medium White Circle
	"✦", // Black Four Pointed Star
	"✧", // White Four Pointed Star
	"➤", // Black Rightwards Arrowhead
	"➢", // Small White Rightwards Arrow
	"", // Bullet char,
	"—", // Em Dash
	"–", // En Dash
	"‐", // Hyphen
	"−", // Minus Sign
	"-" // Hyphen-Minus
];

const UNORDERED_REGEXP = new RegExp(`^([${bulletPoints.join("")}])(?:\\s|$)(.*)`, "i");

// List of strings which should not be split into multiple lines
// This is the worst case scenario because in some cases it's hard to determine when an item
// is not a list
const stringExceptions = [
	// Self explanatory
	/^24 by 7/i,
	// English i.e. and e.g.
	/^\(i\.e/,
	/^\(e\.g/i,
	// Awful case specific to CIS benchmarks. They have text on new lines like "1.8 or highrr"
	/^\d+\.\d+ (or|and)/i
];

/**
 * Returns a matched list item based on the string
 */
export function getListMatches(str: string): ListMatch[] {
	const listMatches: ListMatch[] = [];

	const defaultListMatch: ListMatch = {
		key: "paragraph",
		listType: "paragraph",
		delimiter: "para",
		depth: 1,
		str
	};

	// In some case it's just not feasible to detect without managing an exceptions list
	// We can be super smart and do a forward/backward pass to check for anomalies but it's
	// out of scope of the current implementation
	if (stringExceptions.some(exception => exception.test(str))) {
		return [defaultListMatch];
	}

	const numbersWithDelimiters = str.match(/^[([]?(\d{1,2}([.):]\d{1,2})*)[.):\]\s]([^\d]+.*)/i);
	if (numbersWithDelimiters?.[3]) {
		const depth = numbersWithDelimiters[1]!
			.split(/\.|:|\)|\]/)
			.filter(item => Boolean(item.trim())).length;

		listMatches.push({
			key: `numbered-${depth}`,
			listType: "numbered",
			delimiter: numbersWithDelimiters[1]!,
			depth,
			str: numbersWithDelimiters[3].trim()
		});
	}

	// Match items like A.1 or B.9
	const alphabetWithNumbers = str.match(/^([a-z]{1,2}\.\d{1,2})\s(.*)/i);
	if (alphabetWithNumbers?.[2]) {
		listMatches.push({
			key: "alphabet-numbers",
			listType: "alphabet-numbers",
			delimiter: alphabetWithNumbers[1]!,
			depth: 1,
			str: alphabetWithNumbers[2].trim()
		});
	}

	const alphabetsWithDelimiters = str.match(/^[([]?([a-z]{1,2})[.)\]]+(.*)/);
	if (alphabetsWithDelimiters?.[2]) {
		listMatches.push({
			key: "alphabet",
			listType: "alphabet",
			delimiter: alphabetsWithDelimiters[1]!,
			depth: 1,
			str: alphabetsWithDelimiters[2].trim()
		});
	}

	const romanNumeralsWithDelimiters = str.match(/^[([]?([IVXLCDM]+)[.)\]:]+(.*)/i);
	if (romanNumeralsWithDelimiters?.[2]) {
		listMatches.push({
			key: "roman",
			listType: "roman",
			delimiter: romanNumeralsWithDelimiters[1]!,
			depth: 1,
			str: romanNumeralsWithDelimiters[2].trim()
		});
	}

	const unorderedListItem = str.match(UNORDERED_REGEXP);
	if (unorderedListItem?.[2]) {
		listMatches.push({
			key: "unordered",
			listType: "unordered",
			// We don't care about the delimiter for unordered lists so we normalise it
			delimiter: "•",
			depth: 1,
			str: unorderedListItem[2].trim()
		});
	}

	return [...listMatches, defaultListMatch];
}

/**
 * Tries to find if a line is a list item and returns the list type and delimiter.
 * This applies smarter logic based on the previous list item to determine if the current
 */
export function getContextualListMatch(
	str: string,
	textContents: ProcessedTextContent[]
): ListMatch {
	const matches = getListMatches(str);

	// Function will always return 1 element
	const firstMatch = matches[0]!;

	// Roman numerals and alphabets are tricky, because (x) can be a roman numeral or a numbered list
	// So we compare it against the last list match to see if it's a continuation of the previous list
	const romanMatch = matches.find(item => item.key === "roman");
	const alphabetMatch = matches.find(item => item.key === "alphabet");
	const lastListItem = textContents.at(-1)?.listMatch;

	// Both roman and alphabet matches are valid so we should compare against the last list item
	if (romanMatch && alphabetMatch) {
		const isStartingRoman = romanMatch.delimiter.toLocaleLowerCase() === "i";

		// Check if a roman list is inside an alphabet list
		// e.g. if we have an i) after an h) then it's a alphabet list
		// but if we have an i) after a b) then it's a roman list
		if (
			isStartingRoman &&
			lastListItem?.listType === "alphabet" &&
			lastListItem.delimiter.toLocaleLowerCase() !== "h"
		) {
			return romanMatch;
		}

		// Roman numbers begin with (i) or (I), if we have no previous list it's roman
		if (
			!lastListItem ||
			(lastListItem.listType !== "alphabet" && lastListItem.listType !== "roman")
		) {
			return isStartingRoman ? romanMatch : alphabetMatch;
		}

		// If the previous list was a roman numeral, then this is a continuation
		const lastRomanMatch = textContents.findLast(item => item.listMatch.key === "roman");
		if (!lastRomanMatch) {
			return alphabetMatch;
		}

		const lastRomanNumber = lastRomanMatch.listMatch.delimiter;
		const currentRomanNumber = romanMatch.delimiter;
		return isRomanInSequence(lastRomanNumber, currentRomanNumber) ? romanMatch : alphabetMatch;
	} else if (alphabetMatch) {
		// If we have an alphabet item, we must ensure that it's delimeter is in sequence
		// from the last alphabet item
		const lastAlphabetItem = textContents.find(item => item.listMatch.key === "alphabet");
		const startsWithA = alphabetMatch.delimiter.toLocaleLowerCase() === "a";

		// No alphabets in the queue and we start with a) so best case assumption
		// is that this is correct. This still has a possibility to include
		// rogue elements if some random sentence start with a. something
		if (!lastAlphabetItem && startsWithA) {
			return alphabetMatch;
		}

		return alphabetMatch;
	} else if (romanMatch) {
		const lastRomanMatch = textContents.findLast(item => item.listMatch.key === "roman");

		// We are entering a new roman list
		if (!lastRomanMatch) {
			return romanMatch.delimiter.toLocaleLowerCase() === "i" ? romanMatch : firstMatch;
		}

		// Else the roman numbers must be in sequence
		const lastRomanNumber = lastRomanMatch.listMatch.delimiter;
		const currentRomanNumber = romanMatch.delimiter;
		return isRomanInSequence(lastRomanNumber, currentRomanNumber) ? romanMatch : firstMatch;
	}

	return firstMatch;
}

export function romanToInt(roman: string): number {
	const romanMap: { [key: string]: number } = {
		I: 1,
		V: 5,
		X: 10,
		L: 50,
		C: 100,
		D: 500,
		M: 1000
	};

	let intValue = 0;
	for (let i = 0; i < roman.length; i++) {
		const currentVal = romanMap[roman[i]!];
		const nextVal = romanMap[roman[i + 1]!];

		// Not a roman number
		if (!currentVal) {
			// eslint-disable-next-line no-console
			console.error("Not a roman number", roman);
			return 0;
		}

		if (nextVal && currentVal < nextVal) {
			intValue -= currentVal;
		} else {
			intValue += currentVal;
		}
	}

	return intValue;
}

export function isRomanInSequence(roman1: string, roman2: string): boolean {
	const value1 = romanToInt(roman1.toUpperCase());
	const value2 = romanToInt(roman2.toUpperCase());

	return value2 - value1 === 1;
}
export type ListKeyTypes =
	| `numbered-${number}`
	| "alphabet-numbers"
	| "alphabet"
	| "roman"
	| "unordered"
	| "paragraph";

export type ListTypes =
	| "numbered"
	| "alphabet-numbers"
	| "alphabet"
	| "roman"
	| "unordered"
	| "paragraph";

export function formatListKey(key: ListKeyTypes, depth: number) {
	let subText = "(1, 2, 3, ...)";

	switch (key) {
		case "alphabet":
			subText = "(a, b, c, ...)";
			break;

		case "alphabet-numbers":
			subText = "(a.1, a.2, a.3, ...)";
			break;

		case "roman":
			subText = "(i, ii, iii, ...)";
			break;

		case "unordered":
			subText = "(unordered)";
			break;

		case "paragraph":
			subText = "(paragraph)";
			break;
	}

	return {
		text: `Level ${depth}`,
		subText
	};
}

// Compares numbered list items and tells which one is greater
// e.g. 2 is greater than 1.2.9 because 1.2.9 is a sub item of 1.2
export function compareNumberedListItems(listItem1: string, listItem2: string) {
	const list1 = listItem1.split(/[^\d]+/).filter(Boolean);
	const list2 = listItem2.split(/[^\d]+/).filter(Boolean);
	const maxLength = Math.max(list1.length, list2.length);

	for (let i = 0; i < maxLength; i++) {
		if (list1[i] === list2[i]) {
			continue;
		}

		if (!list1[i]) {
			return -1;
		}

		if (!list2[i]) {
			return 1;
		}

		return Number(list1[i]) - Number(list2[i]);
	}

	return 0;
}

export type WeightedNumber = {
	number: number;
	weight: number;
};

export function weightedMedian(nums: WeightedNumber[]): number {
	// Step 1: Sort the array based on the 'number' values
	nums.sort((a, b) => a.number - b.number);

	// Step 2: Calculate the total weight
	const totalWeight = nums.reduce((acc, obj) => acc + obj.weight, 0);

	// Step 3: Find the weighted median
	let cumulativeWeight = 0;
	for (let i = 0; i < nums.length; i++) {
		cumulativeWeight += nums[i]!.weight;
		if (cumulativeWeight >= totalWeight / 2) {
			return nums[i]!.number;
		}
	}

	// In case the list is empty, return 0 or some other appropriate value
	return 0;
}

/**
 * Takes an array of strings like (1, '1.1', and '1.1.9') and returns the merged string without duplicates
 * (e.g.
 * 	['1', '1.1', '1.1.9'] === '1.1.9'
 *  ['1', '2', '3'] === '1.2.3'
 *  ['A', 'B', 'C'] === 'A.B.C'
 */
export function deduplicateAndMergeItems(items: string[]) {
	const mergedItems = items.reduce((acc, item) => {
		const splitItem = item.split(".");

		for (let i = 0; i < splitItem.length; i++) {
			const itemSplit = splitItem[i];

			if (!itemSplit || acc[i] === itemSplit) {
				continue;
			}

			if (!acc[i]) {
				acc[i] = itemSplit;
			} else {
				acc[i] = `${acc[i]}.${itemSplit}`;
			}
		}

		return acc;
	}, [] as string[]);

	return mergedItems.join(".");
}

export function getPageMargins(pages: ParsedPage[]): PageMargins {
	let minLeft = Infinity;
	let minTop = Infinity;
	let maxRight = -Infinity;
	let maxBottom = -Infinity;

	for (const { contents } of pages) {
		for (const item of contents) {
			const [, , , , itemLeft, itemTop] = item.transform;

			if (itemLeft < minLeft) {
				minLeft = itemLeft;
			}

			if (itemTop < minTop) {
				minTop = itemTop;
			}

			const right = itemLeft + item.width;
			if (right > maxRight) {
				maxRight = right;
			}

			const bottom = itemTop + item.height;
			if (bottom > maxBottom) {
				maxBottom = bottom;
			}
		}
	}

	return {
		left: minLeft,
		top: minTop,
		right: maxRight,
		bottom: maxBottom,
		height: maxBottom - minTop,
		width: maxRight - minLeft
	};
}

export type PageMargins = {
	left: number;
	right: number;
	top: number;
	bottom: number;
	height: number;
	width: number;
};

export type ListMatch = {
	key: ListKeyTypes;
	listType: ListTypes;
	delimiter: string;
	depth: number;
	str: string;
};

export enum SentenceType {
	INFORMATION = "information",
	ACTIVITY = "activity"
}

export type MergedLine = TextItem & {
	absoluteTop: number;
	top: number;
	left: number;
	lineHeight: number;
	pageNumber: number;

	// We break each line into a quantized grid of 10x10
	// This will come in handy when detecting tables
	xGridStart: number;
	xGridEnd: number;
};

export type MergedParagraph = TextItem & {
	listMatch: ListMatch;
	absoluteTop: number;
	lineHeight: number;
	left: number;
};

export type ProcessedTextContent = MergedParagraph & {
	parsedStr: Three;
	id: string;
	index: string[];
	// For easy filtering
	indexJoin: string;
};

export type IndexedParagraph = ProcessedTextContent & {
	type: SentenceType;
	displayId: string;
};

export type ListStackType = ListMatch & {
	height: number;
	left: number;
	top: number;
};

export type ParsedPage = {
	page: PDFPageProxy;
	viewport: PageViewport;
	contents: TextItem[];
};
