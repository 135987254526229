<template>
	<f-div width="100%">
		<EmptyState
			message="Technology Details"
			subtitle="Technology details not available"
			shape="circle"
		>
		</EmptyState>
	</f-div>
</template>
<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { defineComponent } from "vue";

export default defineComponent({
	name: "TechnologyTab",

	components: {
		EmptyState
	}
});
</script>
