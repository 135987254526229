<template>
	<SlideoutContainer v-if="showSlideout" size="small">
		<Slideout>
			<template #header>
				<Slab size="large">
					<template #primary-action>
						<Icon
							data-qa-taxonomy-tab-upload-slideout-close-icon
							name="i-close"
							size="small"
							state="dark"
							type="outlined"
							@click="close"
						/>
					</template>
					<Typography type="h3" weight="bold">Upload Documents</Typography>
				</Slab>
			</template>
			<transition-group class="flex-1" g="div" mode="out-in" name="trans-content-wrapper" ta>
				<ContentWrapper :key="1">
					<Section padding="all">
						<FormField>
							<FileUpload
								:state="noFileError ? 'error' : 'default'"
								accept=".xlsx, .csv"
								data-qa-taxonomy-tab-upload-slideout-file-upload
								sub-title="(.xlsx, .csv)"
								title="click or drag-and-drop to upload your file"
								@on-file-select="addFiles"
							/>
						</FormField>
					</Section>

					<Section v-for="file in files" :key="file.name">
						<Slab size="medium">
							<template #primary-action>
								<Icon :effects="false" name="i-file" size="medium" type="filled"></Icon>
							</template>

							<Typography type="p2">{{ file.name }}</Typography>
							<template #secondary-actions>
								<Icon
									name="i-close"
									size="small"
									state="dark"
									type="outlined"
									@click="removeFile"
								></Icon>
							</template>
						</Slab>
					</Section>
					<Section v-if="noFileError">
						<Typography color="error" data-qa-taxonomy-tab-upload-slideout-no-file-error>
							No file chosen
						</Typography>
					</Section>
					<Section v-if="multipleFileError">
						<Typography color="error" data-qa-taxonomy-tab-upload-slideout-multiple-files-error>
							Please select only one file each time
						</Typography>
					</Section>
					<Section v-if="error">
						<Typography color="error" data-qa-taxonomy-tab-upload-slideout-uploading-error>
							Error in uploading file
						</Typography>
					</Section>
					<Section v-if="success">
						<Typography color="success" data-qa-taxonomy-tab-upload-slideout-success>
							File uploaded
						</Typography>
					</Section>
					<template #footer>
						<Button
							:full-width="true"
							data-qa-taxonomy-tab-upload-slideout-cancel-button
							state="outlined"
							type="primary"
							@click="close"
						>
							Close
						</Button>
						<Button
							:disabled="loading"
							:full-width="true"
							:loading="loading"
							:type="error ? 'error' : 'primary'"
							data-qa-taxonomy-tab-upload-slideout-upload-button
							@click="upload"
							>Upload
						</Button>
					</template>
				</ContentWrapper>
			</transition-group>
		</Slideout>
	</SlideoutContainer>
</template>
<script lang="ts">
import {
	Button,
	ContentWrapper,
	FileUpload,
	FormField,
	Icon,
	Section,
	Slab,
	Slideout,
	SlideoutContainer,
	Typography
} from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { UPLOAD_TAXONOMY_SHEET_RESPONSE } from "@/modules/taxonomy-table/taxonomy-types";

import { taxonomyStore } from "../taxonomy-table-store";

export default defineComponent({
	name: "UploadSlideoutPanel",

	components: {
		Typography,
		FileUpload,
		SlideoutContainer,
		Slideout,
		Slab,
		ContentWrapper,
		Button,
		Section,
		Icon,
		FormField
	},

	props: {
		showSlideout: {
			type: Boolean,
			default: false
		}
	},

	emits: ["hideSlideout", "uploadSuccess"],

	data() {
		return {
			loading: false,
			success: false,
			error: false,
			files: new Array<File>(),
			noFileError: false,
			multipleFileError: false
		};
	},

	computed: {
		...mapStores(taxonomyStore)
	},

	methods: {
		addFiles(files: File[]): void {
			this.success = false;
			this.error = false;
			this.noFileError = false;
			this.multipleFileError = false;
			if (files.length > 1) {
				this.multipleFileError = true;
				return;
			}
			this.files = files;
		},

		close() {
			this.$emit("hideSlideout");
			this.files = [];
		},

		removeFile() {
			this.files = [];
		},

		upload() {
			this.success = false;
			this.error = false;
			this.noFileError = false;
			this.multipleFileError = false;
			if (!this.files.length) {
				this.noFileError = true;
				return;
			}
			this.loading = true;
			this.taxonomyStore
				.uploadFile(this.files[0])
				.then((data: UPLOAD_TAXONOMY_SHEET_RESPONSE) => {
					this.success = true;
					this.$emit("uploadSuccess", data);
				})
				.catch(() => {
					this.error = true;
				})
				.finally(() => {
					this.loading = false;
					this.files = [];
				});
		}
	}
});
</script>
