/**
 * This file defines utilities which work with similarity-worker.ts to provide
 * similarity between a given string and COBIT statements.
 *
 * It uses workerpool to schedule multiple worker threads, so mapping is faster.
 * It also uses a cache to store COBIT embeddings, so that they are not recalculated each time
 */
import workerpool from "workerpool";

import workerUrl from "@/utils/similarity-worker?worker&url";

import cobit from "./cobit.json";

let numWorkers = 1;

// If we have enough CPU cores, then try to use half of them
// this ensures a good balance between CPU usage and browser responsiveness
if (navigator.hardwareConcurrency && navigator.hardwareConcurrency >= 4) {
	numWorkers = Math.ceil(navigator.hardwareConcurrency / 2);
}

const pool = workerpool.pool(workerUrl, {
	maxWorkers: numWorkers,
	workerOpts: {
		// By default, Vite uses a module worker in dev mode, which can cause your application to fail.
		// Therefore, we need to use a module worker in dev mode and a classic worker in prod mode.
		type: import.meta.env.PROD ? undefined : "module"
	}
});

// Now calculate COBIT embeddings if not already cached and save them
// This will speed up mappings
const embeddingPromise = (async () => {
	if (import.meta.env.MODE === "test") {
		// eslint-disable-next-line no-console
		console.log("In test environment, skipping embedding calculation");
		return true;
	}

	// Trigger cache of COBIT embeddings so that mapping is faster
	await pool.exec("cacheModel");

	const hasEmbeddings = (await pool.exec("hasCobitEmbeddings")) as boolean;

	if (hasEmbeddings) {
		// eslint-disable-next-line no-console
		console.log("Embeddings already cached");
		return true;
	}

	// eslint-disable-next-line no-console
	console.log("Calculating embeddings for COBIT activities...");

	const time = performance.now();
	const calculatedEmbeddings: CobitJSON = await Promise.all(
		cobit.map(async activity => {
			const embedding = await pool.exec("getEmbedding", [activity.Activity]);

			return {
				...activity,
				embedding
			};
		})
	);

	await pool.exec("cacheCobitEmbeddings", [calculatedEmbeddings]);

	// eslint-disable-next-line no-console
	console.log(
		`Embeddings calculated and cached in ${Math.round((performance.now() - time) / 1000)}s`
	);

	return true;
})();

export async function getCobitMap(str: string, maxResults: number) {
	// Ensure cobit embeddings are calculated before moving ahead
	await embeddingPromise;
	const cobitMatches = (await pool.exec("getCosineSimilarityWithCobit", [
		str,
		maxResults
	])) as CobitMap[];

	return {
		statement: str,
		cobitMatches
	};
}

export const STATEMENT_SEVERITY_LEVELS = {
	HIGH: "High",
	MEDIUM: "Medium",
	LOW: "Low"
} as const;

export function getStatementSeverity(str: string) {
	let strength: StrengthLevel = STATEMENT_SEVERITY_LEVELS.LOW;

	const lowerStr = str.toLocaleLowerCase();

	if (lowerStr.includes("should") || lowerStr.includes("recommended")) {
		strength = STATEMENT_SEVERITY_LEVELS.MEDIUM;
	}

	if (lowerStr.includes("must") || lowerStr.includes("shall") || lowerStr.includes("required")) {
		strength = STATEMENT_SEVERITY_LEVELS.HIGH;
	}

	return strength;
}

export type StrengthLevel =
	(typeof STATEMENT_SEVERITY_LEVELS)[keyof typeof STATEMENT_SEVERITY_LEVELS];

export type CobitMap = {
	id: string;
	activity: string;
	similarity: number;
};

export const similarityWorkerPool = pool;

type CobitWithoutEmbedding = {
	No: number;
	ActivityId: string;
	Activity: string;
};

type CobitJSON = Array<
	CobitWithoutEmbedding & {
		embedding: number[];
	}
>;
