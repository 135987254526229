<script setup lang="ts">
import { ref, computed, toRefs, watch } from "vue";

import { IndexedParagraph, SentenceType, getPDFDocumentFromEvent } from "@/utils";

import { generateLevels } from "../document-ingestion/document-mapping-store";

import { DraftDocument, getDocumentStateFromDocument } from "./document-comparison-store";

const draftDocument = ref<DraftDocument | null>(null);
const showOnlySelected = ref(false);
const searchString = ref("");
const selectedStatementIds = ref<Record<string, boolean>>({});
const documentName = ref("");

const emit = defineEmits(["select-statements"]);

const props = defineProps({
	documentId: {
		type: String,
		required: true
	}
});

const { documentId } = toRefs(props);

const indexLevels = computed(() => {
	return draftDocument.value ? generateLevels(draftDocument.value.statements) : [];
});

const indexLevelSelectionMap = computed(() => {
	const selectionMap: Record<string, boolean> = {};

	indexLevels.value.forEach(level => {
		const statementsForIndex =
			draftDocument.value?.statements.filter(statement => {
				return statement.indexJoin === level.indexJoin && statement.type === SentenceType.ACTIVITY;
			}) ?? [];

		selectionMap[level.indexJoin] =
			statementsForIndex.length > 0 &&
			statementsForIndex.every(statement => selectedStatementIds.value[statement.id]);
	});

	return selectionMap;
});

const filteredStatements = computed(() => {
	return draftDocument.value?.statements.filter(statement => {
		if (statement.type === SentenceType.INFORMATION) {
			return false;
		}

		// Handle selected checkbox
		if (showOnlySelected.value && !selectedStatementIds.value[statement.id]) {
			return false;
		}

		if (searchString.value.length > 0) {
			return statement.str.toLocaleLowerCase().includes(searchString.value.toLocaleLowerCase());
		}

		return true;
	});
});

const selectedStatementsCount = computed(() => {
	return Object.values(selectedStatementIds.value).filter(Boolean).length;
});

const selectedStatements = computed(() => {
	return draftDocument.value?.statements.filter(
		statement => selectedStatementIds.value[statement.id]
	);
});

watch(
	[selectedStatements],
	() => {
		emit("select-statements", selectedStatements.value ?? []);
	},
	{
		immediate: true
	}
);

async function uploadDocument(e: CustomEvent<{ value: File }>) {
	const fileName = e.detail.value.name;

	documentName.value = fileName;
	const document = await getPDFDocumentFromEvent(e);
	draftDocument.value = await getDocumentStateFromDocument(documentId.value, document, fileName);
}

function toggleStatements(statements: IndexedParagraph[], isSelected?: boolean) {
	const selectedStatementsTmp = { ...selectedStatementIds.value };

	statements.forEach(statement => {
		if (statement.type === SentenceType.ACTIVITY) {
			selectedStatementsTmp[statement.id] = Boolean(isSelected);
		}
	});

	selectedStatementIds.value = selectedStatementsTmp;
}

function toggleStatementssWithIndexLevel(indexJoin: string) {
	const isSelected = indexLevelSelectionMap.value[indexJoin];

	if (draftDocument.value) {
		toggleStatements(
			draftDocument.value.statements.filter(statement => statement.indexJoin === indexJoin),
			!isSelected
		);
	}
}
</script>

<template>
	<f-div direction="column" overflow="scroll" :show-scrollbar="true">
		<f-div v-if="!filteredStatements" padding="large">
			<f-file-upload
				placeholder="Drag and drop files here or click to upload"
				file-type=".pdf"
				state="primary"
				size="small"
				@input="uploadDocument"
			>
			</f-file-upload>
		</f-div>

		<template v-else>
			<f-div
				:style="{ position: 'sticky', top: 0, zIndex: 1 }"
				direction="column"
				padding="medium"
				align="middle-left"
				gap="medium"
				border="small solid subtle bottom"
				state="default"
				height="hug-content"
			>
				<f-text weight="bold" inline>{{ documentName }}</f-text>
				<f-div gap="medium" align="middle-left">
					<f-search
						category="fill"
						size="small"
						variant="round"
						placeholder="Search statements"
						:value.prop="searchString"
						data-qa="handle-search-cobit-mapping"
						@input="searchString = $event.detail.value"
					></f-search>

					<f-div width="hug-content" gap="medium" align="middle-right">
						<f-switch
							v-model="showOnlySelected"
							tooltip="Show only statements which have been selected."
							:disabled="selectedStatementsCount === 0"
							size="medium"
							state="default"
						>
							<f-div slot="label" padding="none">
								<f-text variant="para" size="small">Only selected</f-text>
							</f-div>
						</f-switch>
					</f-div>
				</f-div>
			</f-div>

			<f-accordion body-padding="none">
				<f-div padding="none" align="middle-left" overflow="scroll" show-scrollbar>
					<f-text weight="bold"> Document index </f-text>
				</f-div>

				<f-div slot="body" direction="column" max-height="200px" overflow="scroll" show-scrollbar>
					<f-div
						v-for="indexLevel in indexLevels"
						:key="indexLevel.indexJoin"
						class="show-on-hover-parent"
						height="hug-content"
						padding="small x-large small large"
						border="small solid subtle bottom"
						align="middle-left"
						gap="small"
						overflow="visible"
					>
						<f-spacer v-for="depth in indexLevel.depth" :key="depth" size="medium"></f-spacer>
						<f-checkbox
							v-if="indexLevel.activityCount > 0"
							size="small"
							:value="indexLevelSelectionMap[indexLevel.indexJoin] ? 'checked' : 'unchecked'"
							@input="toggleStatementssWithIndexLevel(indexLevel.indexJoin)"
						></f-checkbox>
						<f-text inline
							>{{ indexLevel.displayString.text }}
							<f-text inline size="small" state="subtle">{{
								indexLevel.displayString.subText
							}}</f-text></f-text
						>

						<f-icon
							v-if="indexLevel.activityCount > 0"
							source="i-info-fill"
							state="subtle"
							size="small"
							:tooltip="`${indexLevel.activityCount} statements`"
						></f-icon>
					</f-div>
				</f-div>
			</f-accordion>

			<f-divider size="medium" state="subtle"></f-divider>

			<f-div
				v-for="(statement, idx) in filteredStatements"
				:key="idx"
				height="hug-content"
				padding="small"
				align="top-left"
			>
				<template v-if="statement.index.length > 0">
					<f-spacer v-for="idx in statement.index.length - 1" :key="idx" size="large"></f-spacer>
				</template>

				<f-checkbox
					:disabled="statement.type === 'information'"
					size="small"
					:value="selectedStatementIds[statement.id] ? 'checked' : 'unchecked'"
					@input="toggleStatements([statement], !selectedStatementIds[statement.id])"
				></f-checkbox>

				<f-spacer size="medium"></f-spacer>

				<f-text v-if="statement.listMatch.listType !== 'paragraph'" inline
					>{{ statement.listMatch.delimiter }} &nbsp;</f-text
				>
				<f-text>{{ statement.str }}</f-text>
			</f-div>
		</template>
	</f-div>
</template>
