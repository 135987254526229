import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { captureError } from "@/utils";

import { ClientFeatureKey, getFeatureMap } from "./feature-map";

export const FEATURE_FLAGS = "client_features";

const FeatureFlagService = {
	setFeatures(featureFlags: Record<string, unknown>) {
		try {
			localStorage.setItem(FEATURE_FLAGS, JSON.stringify(featureFlags));
		} catch (err) {
			// do nothing
		}
	},

	getFeatures() {
		try {
			const flagsStorage = localStorage.getItem(FEATURE_FLAGS);
			return flagsStorage ? JSON.parse(flagsStorage) : {};
		} catch (err) {
			return {};
		}
	}
};

export const featureFlagStore = defineStore("featureFlagStore", () => {
	const featureMapFull = ref(getFeatureMap());

	// Read persisted features from disk if they exist
	const storedFeatures = FeatureFlagService.getFeatures() || {};
	const featureKeys = Object.keys(storedFeatures);

	for (const key of featureKeys) {
		if (key in featureMapFull.value) {
			featureMapFull.value[key as ClientFeatureKey].isEnabled = storedFeatures[key].isEnabled;
		}
	}

	const featureMap = computed(() => {
		const featureMap_: Record<string, boolean> = {};
		const featureKeys_ = Object.keys(featureMapFull.value) as ClientFeatureKey[];

		for (const key of featureKeys_) {
			featureMap_[key] = featureMapFull.value[key].isEnabled;
		}

		return featureMap_ as Record<ClientFeatureKey, boolean>;
	});

	function setClientFeature({
		featureKey,
		isEnabled
	}: {
		featureKey: ClientFeatureKey;
		isEnabled: boolean;
	}) {
		const featureKeys_ = Object.keys(featureMapFull.value) as ClientFeatureKey[];

		if (featureKeys_.includes(featureKey)) {
			featureMapFull.value[featureKey].isEnabled = isEnabled;
			FeatureFlagService.setFeatures(featureMapFull.value);
		} else {
			captureError(new Error(`Feature key ${featureKey} does not exist`));
		}
	}

	return {
		featureMapFull,
		featureMap,
		setClientFeature
	};
});
