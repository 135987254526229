<template>
	<f-div class="f-middle-column" direction="column">
		<f-div overflow="scroll" direction="column" show-scrollbar>
			<f-div
				v-for="(controlSet, idx) in controlSetTables"
				:key="controlSet.document.id"
				direction="column"
				style="flex: 0 0 auto"
				overflow="hidden"
			>
				<f-div
					gap="medium"
					width="fill-container"
					padding="medium"
					align="middle-left"
					height="hug-content"
					direction="row"
				>
					<f-div width="hug-content" align="middle-center" gap="x-small">
						<f-text inline variant="heading" weight="medium" size="small">{{ idx + 1 }}.</f-text>
						<f-text inline variant="para" weight="medium" size="medium">{{
							controlSet.document.name
						}}</f-text>
					</f-div>
					<f-div gap="small" width="hug-content" align="middle-center" height="hug-content">
						<f-icon
							:source="documentLevelIcon(controlSet.document.applicableTo)"
							state="subtle"
							size="small"
						></f-icon>
						<f-text variant="para" weight="regular" size="medium" state="subtle">
							{{ toTitleCase(controlSet.document.applicableValue ?? "") }}
						</f-text>
					</f-div>
				</f-div>
				<f-div direction="column">
					<f-table-schema
						:selectable="isSelectionEnabled ? 'multiple' : 'none'"
						variant="underlined"
						:data="controlSet.tableData"
						:show-search-bar="false"
						:highlight-hover="true"
						:highlight-column-hover="false"
						sticky-cell-background="secondary"
						sticky-header
						size="small"
						@header-input="$event => handleHeaderSelection(controlSet.document, $event)"
						@header-selected="handleCheckboxClick"
						@row-click="handleStatementClick"
						@row-input="handleCheckboxClick"
					>
						<f-div
							slot="no-data"
							variant="curved"
							width="100%"
							padding="x-large medium medium medium"
							height="60px"
							border="small solid subtle bottom"
						>
							<f-text state="default" align="center">No results found</f-text>
						</f-div>
					</f-table-schema>
				</f-div>
			</f-div>
		</f-div>
	</f-div>

	<GroupEvidenceModal
		v-if="viewedStatementId && viewedDocumentId"
		:is-approval-flow="hasApprovalAccess"
		:component="component"
		:documents="documents"
		:selected-statement-id="viewedStatementId"
		:viewed-document-id="viewedDocumentId"
		@close="
			viewedStatementId = null;
			viewedDocumentId = null;
		"
	/>
</template>

<script lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData, FTableSchemaDataRow } from "@ollion/flow-table";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import GroupEvidenceModal from "@/modules/evidence/components/GroupEvidenceModal.vue";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { DocumentWithStatements } from "@/modules/release-cockpit-v2/catalog-service-types";
import {
	SelectedStatement,
	TaxonMappingIcons
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { Document } from "@/protocol/correlation";
import { toTitleCase } from "@/utils";

import { componentOnboardStore } from "../../component-onboard-store";

export default defineComponent({
	name: "OnboardingControlStatementList",
	components: { GroupEvidenceModal },

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		documents: {
			type: Array as PropType<DocumentWithStatements[]>,
			required: true
		},

		searchString: {
			type: String,
			default: "",
			required: false
		},

		isSelectionEnabled: {
			type: Boolean,
			required: true
		}
	},

	emits: ["view-statement", "selected-statements"],

	data: () => {
		return {
			toTitleCase,
			selectedStatements: [] as SelectedStatement[],
			viewedStatementId: null as string | null,
			viewedDocumentId: null as string | null
		};
	},

	computed: {
		...mapStores(featureFlagStore, authStore, componentOnboardStore),

		hasApprovalAccess() {
			return this.authStore.userPermissions[USER_PERMISSIONS.workflowApprove]?.enabled;
		},

		orderedStatements() {
			return this.documents.map(({ document, statements }) => {
				// Sort by numbers if ids are numeric
				const isNumericIds = /^[\d.]+$/.test(statements[0]?.id ?? "");

				return {
					document,
					statements: statements.sort((a, b) => {
						if (isNumericIds) {
							return parseFloat(String(a.id)) - parseFloat(String(b.id));
						}

						return String(a.id).localeCompare(String(b.id));
					})
				};
			});
		},

		controlSetTables() {
			const allEvidences = this.componentOnboardStore.evidences[this.component.id!] ?? {};

			return this.orderedStatements.map(({ document, statements }) => {
				const tableData: FTableSchemaData = {
					header: {
						status: {
							value: "Status",
							disableSort: true,
							width: "auto",
							align: "middle-center"
						},
						statements: {
							value: "statements",
							disableSort: true,
							width: "auto",
							align: "middle-left",
							template: () => html`
								<f-div gap="small" direction="row" align="middle-center">
									<f-text variant="para" size="medium">Statement</f-text>
									<f-counter .label=${statements.length}></f-counter>
								</f-div>
							`
						},
						id: {
							value: "ID",
							disableSort: true,
							width: "auto",
							align: "middle-left"
						},
						view: {
							value: " ",
							disableSort: true,
							width: "auto",
							align: "middle-left"
						}
					},

					rows: statements.map(controlStatement => {
						const evidences =
							allEvidences[controlStatement.documentId!]?.[controlStatement.id!] ?? [];

						const [latestEvidence] = evidences;

						const firstEvidenceFileName = latestEvidence?.name ?? "";

						const hasEvidences = evidences.length > 0;
						const status = hasEvidences ? "resolved" : "pending";
						const statementId = controlStatement.id;

						const latestEvidenceStatus = latestEvidence?.status ?? "";
						const isApproved = hasEvidences && latestEvidenceStatus === "Approved";
						const isRejected = hasEvidences && latestEvidenceStatus === "Rejected";
						const isPending = hasEvidences && latestEvidenceStatus === "Pending";

						const template = () => {
							if (isApproved) {
								return html`<f-icon
									source="i-tick"
									size="small"
									state="success"
									tooltip="Evidence approved"
								></f-icon>`;
							} else if (isRejected) {
								return html`<f-icon
									source="i-alert-fill"
									size="small"
									state="danger"
									tooltip="Evidence rejected"
								></f-icon>`;
							} else if (isPending) {
								return html`<f-icon
									source="i-alert-2"
									size="small"
									state="subtle"
									tooltip="Evidence approval pending"
								></f-icon>`;
							} else if (!hasEvidences) {
								return html`<f-icon
									source="i-alert-2"
									size="small"
									state="subtle"
									tooltip="No evidence uploaded"
								></f-icon>`;
							} else {
								return html`<f-icon
									source="i-alert-2"
									size="small"
									state="subtle"
									tooltip="action pending"
								></f-icon>`;
							}
						};

						const isSelected = this.selectedStatements.some(
							({ statementId: id, documentId: docId }) =>
								id === statementId && docId === controlStatement.documentId
						);

						return {
							id: `${statementId}doc_id:${controlStatement.documentId}`,
							selected: isSelected,
							disableSelection: hasEvidences && isApproved,
							data: {
								id: {
									value: statementId,
									align: "middle-left"
								},
								status: {
									value: status,
									align: "middle-center",
									template
								},
								statements: {
									value: controlStatement.statement,
									align: "middle-left",
									template: () => html`
										<f-div gap="small" width="100%" direction="row" align="middle-center">
											<f-text
												highlight="${this.searchString}"
												variant="para"
												size="medium"
												weight="medium"
												>${controlStatement.statement}</f-text
											>
										</f-div>
									`
								},
								view: {
									align: "middle-left",
									value: {
										documentId: document.id,
										statementId,
										hasEvidences,
										isRejected
									},
									template: () => {
										if (hasEvidences) {
											return html` <f-div padding="none small">
												<f-icon
													size="small"
													source="i-save-test"
													state="subtle"
													clickable
													tooltip="View Evidence ${firstEvidenceFileName
														? `- ${firstEvidenceFileName}`
														: ""}"
													@click="${() => {
														this.viewedDocumentId = controlStatement.documentId ?? "";
														this.viewedStatementId = statementId ?? "";
													}}"
												></f-icon>
											</f-div>`;
										}
									}
								},
								identifier: {
									value: statementId,
									documentId: document.id ?? "",
									statementId: statementId ?? "",
									hasEvidences,
									isRejected,
									isApproved
								} satisfies SelectedStatement
							}
						};
					}) as FTableSchemaDataRow[]
				};

				return {
					document,
					tableData
				};
			});
		}
	},

	methods: {
		handleHeaderSelection(document: Document, event: CustomEvent) {
			const isSelected = event.detail.value;

			const controlSet = this.orderedStatements.find(({ document: doc }) => doc.id === document.id);

			if (!controlSet) {
				return;
			}

			if (isSelected) {
				const allEvidences = this.componentOnboardStore.evidences[this.component.id!] ?? {};
				controlSet.statements.forEach(statement => {
					const isSelectedIdx = this.selectedStatementIndex({
						statementId: statement.id ?? "",
						documentId: statement.documentId ?? ""
					});

					if (isSelectedIdx === -1) {
						const evidences = allEvidences[statement.documentId!]?.[statement.id!];
						const hasApprovedEvidence = evidences?.some(evidence => evidence.status === "Approved");

						if (!evidences || evidences.length === 0 || !hasApprovedEvidence) {
							this.addSelectedStatement({
								statementId: statement.id ?? "",
								documentId: statement.documentId ?? "",
								hasEvidences: false,
								isRejected: false,
								isApproved: false
							});
						}
					}
				});
			} else {
				controlSet.statements.forEach(statement => {
					this.removeSelectedStatement({
						statementId: statement.id ?? "",
						documentId: statement.documentId ?? ""
					});
				});
			}
			this.publishSelectedStatements();
		},

		handleStatementClick(event: CustomEvent) {
			const { hasEvidences, statementId, documentId } = event.detail.data
				?.identifier as SelectedStatement;

			if (hasEvidences) {
				this.$emit("view-statement", { statementId, documentId });
				return;
			}
		},

		selectedStatementIndex({
			statementId,
			documentId
		}: {
			statementId: string;
			documentId: string;
		}) {
			return this.selectedStatements.findIndex(
				({ statementId: id, documentId: docId }) => id === statementId && docId === documentId
			);
		},

		removeSelectedStatement({
			statementId,
			documentId,
			selectedIdx
		}: {
			statementId: string;
			documentId: string;
			selectedIdx?: number;
		}) {
			const isSelectedIdx = selectedIdx ?? this.selectedStatementIndex({ statementId, documentId });

			if (isSelectedIdx > -1) {
				this.selectedStatements.splice(isSelectedIdx, 1);
			}
		},

		addSelectedStatement({
			statementId,
			documentId,
			hasEvidences,
			isRejected,
			isApproved
		}: SelectedStatement) {
			this.selectedStatements.push({
				statementId,
				documentId,
				hasEvidences,
				isRejected,
				isApproved
			});
		},

		publishSelectedStatements() {
			this.$emit("selected-statements", this.selectedStatements);
		},

		handleCheckboxClick(event: CustomEvent) {
			if (!event.detail.data) {
				return;
			}
			const { hasEvidences, statementId, documentId, isRejected, isApproved } = event.detail?.data
				?.identifier as SelectedStatement;

			if (isApproved) {
				return;
			}
			const isSelectedIdx = this.selectedStatementIndex({ statementId, documentId });

			if (isSelectedIdx > -1) {
				this.removeSelectedStatement({ statementId, documentId, selectedIdx: isSelectedIdx });
			} else {
				this.addSelectedStatement({
					statementId,
					documentId,
					hasEvidences,
					isRejected,
					isApproved
				});
			}

			this.publishSelectedStatements();
		},

		documentLevelIcon(level?: string) {
			const documentLevel = TaxonMappingIcons[level ?? ""];
			if (!documentLevel) {
				return "i-domain";
			}
			return documentLevel.icon;
		}
	}
});
</script>
```
