import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import { USER_PERMISSIONS } from "../auth/auth-types";

import LzComplianceReportForm from "./views/LzComplianceReportForm.vue";
import LzComplianceReportPage from "./views/LzComplianceReportPage.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/lz-compliance-report",
		name: "lz-compliance-report",
		component: LzComplianceReportForm,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.lzComplianceReport,
			noLayout: false
		} as RouteMeta,
		props: true
	},
	{
		path: "/lz-compliance-report/view-report",
		name: "view-lz-compliance-report",
		component: LzComplianceReportPage,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.lzComplianceReport,
			noLayout: true
		} as RouteMeta,
		props: true
	}
];

export function registerLzComplianceReportRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
