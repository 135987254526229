<template>
	<f-div height="fill-container" direction="column" overflow="scroll">
		<f-div
			padding="medium"
			border="small solid secondary bottom"
			gap="small"
			sticky="top"
			height="hug-content"
			width="fill-container"
			state="default"
			direction="row"
		>
			<f-text variant="heading" weight="medium" size="medium">Categories</f-text>
		</f-div>
		<f-div direction="column" height="hug-content" overflow="scroll" padding="none small none none">
			<TaxonomyHierarchyTreeNode
				v-for="(rootNode, index) in rootNodes"
				:key="index"
				:node="rootNode"
				:is-open="index === 0"
				@node-clicked="handleNodeClick"
			/>
		</f-div>
		<f-spacer size="large"></f-spacer>
	</f-div>
</template>

<script lang="ts">
import { PropType } from "vue";

import { Component } from "@/protocol/cockpit";

import { DEFAULT_CATEGORY } from "../catalog-service-types";

import TaxonomyHierarchyTreeNode from "./TaxonomyHierarchyTreeNode.vue";
import { TreeNode, buildTree, renderTreeFromLevel } from "./tree";

export default {
	name: "ComponentCatalogTreeFilter",

	components: {
		TaxonomyHierarchyTreeNode
	},

	props: {
		components: {
			type: Object as PropType<Component[]>,
			required: true
		},

		selectedType: {
			type: String,
			required: false
		}
	},

	emits: ["update-filter"],

	data() {
		return {
			classificationLevel: ""
		};
	},

	computed: {
		filteredComponents() {
			return this.components.filter(component => {
				if (this.selectedType === DEFAULT_CATEGORY.id) {
					return true;
				}
				return component.type === this.selectedType;
			});
		},

		componentTree() {
			return buildTree(this.filteredComponents);
		},

		rootNodes(): TreeNode[] {
			return renderTreeFromLevel(this.componentTree, this.classificationLevel);
		}
	},

	watch: {
		selectedType: {
			handler(newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					if (this.selectedType === DEFAULT_CATEGORY.id) {
						this.$emit("update-filter", []);
						return;
					}

					const [rootClassification] = this.componentTree;
					if (rootClassification) {
						this.$emit("update-filter", [
							{
								name: rootClassification.name,
								classificationLevel: rootClassification.classificationLevel,
								componentId: rootClassification.componentId
							}
						]);
					}
				}
			}
		}
	},

	methods: {
		handleNodeClick(path: { name: string; classificationLevel: string; componentId: string }[]) {
			// eslint-disable-next-line no-console
			const classification9 = path.find(node => node.classificationLevel === "classification9");

			if (classification9) {
				this.$router.push({
					name: "component-onboarding-view",
					params: { componentId: classification9.componentId }
				});
				return;
			}
			// eslint-disable-next-line no-console
			this.$emit("update-filter", path);
		}
	}
};
</script>
