import { FIconButtonState, FProgressBarState, FTextStateProp } from "@ollion/flow-core";

export interface TabNodesObj {
	id: number;
	name: string;
}

export interface DashboardComponent {
	id: number | string;
	compId: string;
	name: string;
	count: number;
	total?: number;
	icon?: string;
	state?: FIconButtonState;
}

export interface EvidenceDashboardComponent {
	component: DashboardComponent | undefined;
	application: ApplicationOptions | undefined;
	overviewCard: DashboardComponent;
}

export interface ApplicationOptions {
	id: string;
	title: string;
}

export interface CurrentOrg {
	id: string;
	name: string;
}

export const DEFAULT_REGION_ITEM = {
	id: "",
	title: "Select Region"
};

export const DEFAULT_COUNTRY_ITEM = {
	id: "",
	title: "Select Country"
};

export const DEFAULT_SUB_LOB_ITEM = {
	id: "",
	title: "Select Sub-line of business"
};

export const DEFAULT_LOB_ITEM = {
	id: "",
	title: "Select line of business"
};

export const DEFAULT_APPLICATION_ITEM = {
	id: "",
	title: "Select application"
};

export const DASHBOARD_INIT_COMPONENT: DashboardComponent[] = [
	{
		id: 1,
		name: "COMPLIANT",
		count: 0,
		total: 0,
		compId: "1"
	},
	{
		id: 2,
		compId: "2",
		name: "CRITICAL VIOLATION",
		count: 0,
		total: 0
	},
	{
		id: 3,
		compId: "3",
		name: "NON-CRITICAL VIOLATION",
		count: 0,
		total: 0
	},
	{
		id: 4,
		compId: "4",
		name: "EXEMPTED",
		count: 0,
		total: 0
	}
];

export const getPercentOfControlsExecuted = (
	value = 0,
	total = 0
): { percent: number; state: FTextStateProp & FProgressBarState } => {
	const perOfControls = (100 * value) / total;
	let state: FTextStateProp = "danger";

	if (perOfControls === 70) {
		state = "success";
	} else if (perOfControls > 70) {
		state = "success";
	} else if (perOfControls > 30) {
		state = "warning";
	}

	return { percent: Math.floor(perOfControls), state };
};
