<template>
	<Container class="height-100-per">
		<Loader size="large" />
	</Container>
</template>

<script lang="ts">
import { Container, Loader } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { getAuthenticatedRoute } from "@/modules/router/router-module";

import { authStore } from "../auth-store";

/**
 * This page is used as a placeholder for the authenticated route.
 * If the user lands here we figure out the right route they have permissions for
 * and should be redirected to
 */
export default defineComponent({
	name: "Authenticated",

	components: {
		Loader,
		Container
	},

	computed: {
		...mapStores(authStore)
	},

	async mounted() {
		await this.authStore.GET_USER_PERMISSIONS();
		const route = getAuthenticatedRoute(this.authStore.userPermissions);

		if (route) {
			this.$router.replace(route);
		} else {
			this.$router.replace({ name: "page-not-found" });
		}
	}
});
</script>
