<template>
	<f-div width="100%">
		<f-div
			direction="column"
			:width="showSidePanel ? '100%' : '70%'"
			padding="small none none small"
		>
			<f-div padding="small none large small" direction="column" gap="medium">
				<f-div width="fill-container" gap="large">
					<f-select v-model="selectedLob" placeholder="Select Lob" size="medium" :options="lobList">
					</f-select>

					<f-select
						v-model="selectedSubLob"
						:data-qa="`selected-lob-${selectedSubLob.title}`"
						placeholder="Select Sub-line of business"
						size="medium"
						:options="subLobList"
					>
					</f-select>
				</f-div>
				<f-div width="fill-container">
					<f-select
						v-model="selectedApp"
						:data-qa="`selected-app-${selectedApp.title}`"
						placeholder="Select application"
						size="medium"
						:options="appOptions"
					>
					</f-select>
				</f-div>
			</f-div>
			<!-- Selected Apps details-->
			<f-div padding="large none none small">
				<f-tab class="margin-lt-0 margin-rt-20 margin-bt-20">
					<f-tab-node
						v-for="tab of tabNodes"
						:key="tab.id"
						:data-qa-tab="tab.name"
						:active="selected === tab.id"
						:content-id="String(tab.id)"
						@click="selected = tab.id"
						><f-div width="100%" height="100%" align="middle-center" direction="column">
							<f-div align="middle-center" height="hug-content" width="hug-content">
								<f-div padding="none" gap="small" align="middle-left">{{ tab.name }} </f-div>
							</f-div>
						</f-div>
					</f-tab-node>
				</f-tab>
				<f-tab-content>
					<f-div gap="x-large">
						<f-div direction="row" width="100%" padding="large" gap="x-large">
							<TabsCardComponents :selected-app="selectedApp" />
						</f-div>

						<f-div gap="small">
							<TechnologyCardComponents
								v-if="selectedApp.id !== ''"
								:key="selectedApp.id"
								:application-id="selectedApp.id"
								:app-options="appOptions"
							/>
							<f-div v-else padding="x-large">
								<EmptyState
									message="No Component Available"
									subtitle="Please select application"
									shape="circle"
								>
								</EmptyState>
							</f-div>
						</f-div>
					</f-div>
				</f-tab-content>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores, mapState } from "pinia";
import { defineComponent } from "vue";

import { captureError } from "@/utils";

import {
	getApplications,
	getSubLobByLobAndOrgId,
	getLobByOrgId
} from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import {
	ApplicationOptions,
	TabNodesObj,
	DEFAULT_SUB_LOB_ITEM,
	DEFAULT_LOB_ITEM,
	DEFAULT_APPLICATION_ITEM
} from "../compliance-report-utils";

import TabsCardComponents from "./TabsCardComponents.vue";
import TechnologyCardComponents from "./TechnologyCardComponents.vue";

export default defineComponent({
	name: "OrgStructureTab",

	components: {
		EmptyState,
		TabsCardComponents,
		TechnologyCardComponents
	},

	emits: ["open-side-panel"],

	data: () => {
		return {
			selectedApp: {} as ApplicationOptions,
			selectedSubLob: {} as ApplicationOptions,
			selectedLob: {} as ApplicationOptions,
			subLobList: [] as ApplicationOptions[],
			lobList: [] as ApplicationOptions[],
			appOptions: [DEFAULT_APPLICATION_ITEM] as ApplicationOptions[],

			selected: 0,
			tabNodes: [{ name: "Technology controls", id: 0 }] as TabNodesObj[]
		};
	},

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			showSidePanel: "showSidePanel"
		})
	},

	watch: {
		selectedLob: {
			handler(lob) {
				if (lob && lob.id !== "") {
					/* Whenever lob change then fetch relative sub-lob */
					this.fetchSubLobList();
				} else {
					this.selectedSubLob = DEFAULT_SUB_LOB_ITEM;
					this.selectedApp = DEFAULT_APPLICATION_ITEM;
				}
			}
		},

		selectedSubLob: {
			handler(subLob) {
				if (subLob && subLob.id !== "" && this.selectedLob.id !== "") {
					/* Whenever lob change then fetch relative sub-lob */
					this.getApplicationsList();
				} else {
					this.selectedSubLob = DEFAULT_SUB_LOB_ITEM;
					this.selectedApp = DEFAULT_APPLICATION_ITEM;
				}
			}
		},

		selectedApp: {
			handler(app) {
				if (app?.id) {
					this.complianceReportStore.setSelectedApp(app);
				}
			},

			deep: true,
			immediate: true
		}
	},

	mounted() {
		this.fetchLobList();
	},

	methods: {
		async fetchSubLobList() {
			this.subLobList = [DEFAULT_SUB_LOB_ITEM];
			this.selectedSubLob = DEFAULT_SUB_LOB_ITEM;

			if (!this.complianceReportStore.currentOrg.id) {
				return;
			}

			const response = await getSubLobByLobAndOrgId({
				lobId: this.selectedLob.id,
				orgId: this.complianceReportStore.currentOrg.id
			});

			if (response.entities && response.entities.length > 0) {
				this.subLobList = response.entities.map(item => ({
					id: item.id ?? "",
					title: item.name ?? ""
				}));
			}
		},

		async fetchLobList() {
			this.lobList = [DEFAULT_LOB_ITEM];
			this.selectedLob = DEFAULT_LOB_ITEM;

			if (!this.complianceReportStore.currentOrg.id) {
				return;
			}

			const response = await getLobByOrgId({
				orgId: this.complianceReportStore.currentOrg.id
			});

			if (response.entities && response.entities.length > 0) {
				this.lobList = response.entities.map(entity => ({
					id: entity.id ?? "",
					title: entity.name ?? ""
				}));
			}
		},

		async getApplicationsList() {
			try {
				const resp = await getApplications({
					lobID: this.selectedLob.id,
					sublobID: this.selectedSubLob.id,
					orgID: this.complianceReportStore.currentOrg.id
				});

				const applications = resp.applications ?? [];
				if (!applications.length) {
					return;
				}

				const [firstApp] = applications;
				this.appOptions = applications.map(item => ({ id: item.id ?? "", title: item.name ?? "" }));

				if (firstApp?.id && firstApp.name) {
					this.selectedApp = { id: firstApp.id, title: firstApp.name };
				}
			} catch (error) {
				captureError(error);
				this.appOptions = [DEFAULT_APPLICATION_ITEM];
			}
		}
	}
});
</script>
