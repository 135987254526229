<template>
	<f-div
		width="20%"
		border="small solid secondary right"
		direction="column"
		padding="none none none large"
		overflow="hidden"
		state="default"
	>
		<f-div
			direction="column"
			align="middle-center"
			height="94px"
			padding="medium medium medium none"
			gap="medium"
			overflow="hidden"
		>
			<f-div>
				<f-div align="middle-left">
					<f-text variant="heading" size="medium" weight="medium">Settings</f-text>
				</f-div>
			</f-div>
			<f-div>
				<f-search v-model="searchValue" variant="round" size="small"></f-search>
			</f-div>
		</f-div>
		<f-div overflow="scroll" direction="column">
			<f-div
				v-for="item in filteredSettingsMenu"
				:key="item.id"
				:selected="selectedMenu.menu === item.menu ? 'notch-right' : 'none'"
				gap="medium"
				align="middle-left"
				height="66px"
				border="small solid secondary bottom"
				padding="medium"
				clickable
				overflow="hidden"
				:data-qa="`account-settings-menu-${item.menu}`"
				state="default"
				@click="selectSlab(item)"
			>
				<f-pictogram variant="squircle" :source="item.pictogram" size="x-large"></f-pictogram>
				<f-div direction="column" gap="x-small" align="middle-left" overflow="hidden">
					<f-text variant="heading" size="medium" weight="medium">{{ item.title }}</f-text>
					<f-text variant="para" size="small" weight="regular" state="secondary">{{
						item.subTitle
					}}</f-text>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { SettingsMenu, SettingsMenuObject } from "../settings-types";

export default defineComponent({
	name: "SettingsMenu",

	data() {
		return {
			settingsMenu: [
				{ id: 1, pictogram: "i-user", title: "Profile settings", menu: "profile-settings" },
				{ id: 2, pictogram: "i-key", title: "Password", menu: "password-settings" },
				{ id: 3, pictogram: "i-clock-outline", title: "Sessions", menu: "sessions" }
			] as SettingsMenu,

			searchValue: "",
			selectedMenu: { menu: "profile-settings" } as SettingsMenuObject
		};
	},

	computed: {
		filteredSettingsMenu() {
			if (!this.searchValue) {
				return this.settingsMenu;
			}
			return this.settingsMenu.filter(item =>
				item.title.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase())
			);
		}
	},

	methods: {
		selectSlab(selected: SettingsMenuObject) {
			this.selectedMenu.menu = selected.menu;
			this.$emit("select-menu", this.selectedMenu);
		}
	}
});
</script>
