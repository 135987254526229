<template>
	<AuthLayout header="Verification">
		<FormWrapper>
			<Section padding="horizontal">
				<FormField>
					<template #label>
						<p class="paragraph-2 fc-dark">Token</p>
					</template>
					<InputText v-model:value="token" placeholder="Enter token" type="text"> </InputText>
				</FormField>
			</Section>
		</FormWrapper>
		<Typography v-if="apiErrorMsg" class="padding-tp-10" type="p1" color="error">{{
			apiErrorMsg
		}}</Typography>
		<Section padding="all" class="center-of-container">
			<div class="center-of-container flex-gap-20px width-80-per">
				<Button
					:disabled="!isSubmitAllowed"
					type="primary"
					:full-width="true"
					data-qa="verification-btn"
					@click="submitVerificationFlow"
					>Submit</Button
				>
				<Button
					v-if="showSendEmailButton"
					type="primary"
					state="outlined"
					:full-width="true"
					data-qa="verification-send-email"
					@click="redirectTo(`send-email`)"
					>Send Email</Button
				>
			</div>
		</Section>
	</AuthLayout>
</template>

<script lang="ts">
import { FormWrapper, FormField, InputText, Section, Button, Typography } from "@ollion/flow-vue3";
import { UpdateVerificationFlowBody } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { captureError } from "@/utils";

import { authStore } from "../auth-store";
import AuthLayout from "../views/AuthLayout.vue";

export default defineComponent({
	name: "Verification",

	components: {
		AuthLayout,
		FormWrapper,
		FormField,
		InputText,
		Section,
		Button,
		Typography
	},

	data() {
		return {
			verificationFlowId: "",
			token: "",
			apiErrorMsg: "",
			showSendEmailButton: false
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore),

		isSubmitAllowed(): boolean {
			return Boolean(this.verificationFlowId);
		}
	},

	mounted() {
		this.getIdToken();
	},

	methods: {
		redirectTo(screenName: string) {
			this.$router.push({ name: screenName });
		},

		getIdToken() {
			//Get flowId and token from route
			this.verificationFlowId = this.$route.query.flow as string;
			this.token = this.$route.query.code as string;
		},

		async submitVerificationFlow() {
			//Submit Verification with token and flowId
			const body: UpdateVerificationFlowBody = {
				csrf_token: "",
				code: this.token,
				method: "code",
				email: ""
			};
			try {
				const resp = await this.authStore.SUBMIT_VERIFICATION_FLOW({
					flow: this.verificationFlowId,
					updateVerificationFlowBody: body
				});
				if (resp.data.ui.messages) {
					//Ory kratos return success when invalid token
					if (resp.data.ui.messages[0]?.type === "error") {
						this.apiErrorMsg = resp.data.ui.messages[0].text;
						this.showSendEmailButton = true;
						return;
					}
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-ory-verified",
						title: resp.data.ui.messages[0]?.text as string,
						text: "Please login to continue",
						status: "success"
					});
					this.$router.replace({ name: "login" });
				}
			} catch (error) {
				captureError(error);
				if (typeof error === "string") {
					this.apiErrorMsg = error;
				}
				this.showSendEmailButton = true;
			}
		}
	}
});
</script>
