<template>
	<f-div overflow="scroll" state="default">
		<f-div padding="none" direction="column" gap="small" height="hug-content">
			<f-div
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="secondary"
				height="hug-content"
				border="medium solid subtle bottom"
				overflow="visible"
				align="middle-left"
			>
				<f-div align="middle-left" width="hug-content" padding="none">
					<f-text size="medium" variant="para" state="secondary" weight="bold"> Sessions </f-text>
				</f-div>
			</f-div>
			<f-div direction="column" padding="large">
				<f-div direction="column" gap="medium" padding="medium">
					<f-div
						border="small dashed secondary bottom"
						gap="medium"
						padding="large none medium none"
						align="middle-left"
					>
						<f-text variant="heading" size="small" weight="medium"
							>This is a list of devices that have logged into your account. Revoke any sessions
							that you do not recognize.
						</f-text>
					</f-div>
					<f-grid :max-cell-width="500" gap="large">
						<!-- Loader -->
						<f-div
							v-if="loadingSessions"
							state="secondary"
							variant="curved"
							padding="medium"
							gap="medium"
							direction="column"
							loading="skeleton"
						>
							<f-div gap="medium">
								<f-div width="hug-content">
									<f-button category="outline" label="Revoke"></f-button>
								</f-div>
							</f-div>
							<f-div direction="column" height="hug-content">
								<f-text variant="para" size="medium" weight="medium">Authenticated at</f-text>
								<f-text variant="para" size="medium" weight="medium">Expires at</f-text>
							</f-div>
						</f-div>
						<!-- Sessions list -->
						<f-div
							v-for="session in activeSessions"
							:key="session.id"
							state="secondary"
							variant="curved"
							padding="medium"
							gap="medium"
							direction="column"
						>
							<f-div gap="medium">
								<f-icon source="i-computer" state="success" size="x-large"></f-icon>
								<f-div v-if="session.devices" direction="column" gap="none">
									<f-text variant="para" size="large" weight="medium">{{
										getBrowserAndOS(session.devices[0]?.user_agent ?? "")
									}}</f-text>
									<f-text variant="para" size="small" weight="medium">
										{{ session.devices[0]?.ip_address }}</f-text
									>
								</f-div>
								<f-div width="hug-content">
									<f-button
										category="outline"
										label="Revoke"
										@click="revokeSession(session.id)"
									></f-button>
								</f-div>
							</f-div>
							<f-div direction="column" height="hug-content">
								<f-text variant="para" size="medium" weight="medium"
									>Authenticated at {{ formattedDate(session.authenticated_at ?? "") }}</f-text
								>
								<f-text variant="para" size="medium" weight="medium"
									>Expires at {{ formattedDate(session.expires_at ?? "") }}</f-text
								>
							</f-div>
						</f-div>
					</f-grid>
				</f-div>
			</f-div>
			<!-- Empty State when no sessions -->
			<f-div
				v-if="activeSessions.length === 0 && !loadingSessions"
				align="middle-center"
				height="500px"
			>
				<f-div direction="column" align="middle-center" gap="large" width="500px">
					<f-pictogram
						variant="circle"
						source="i-clock-outline"
						state="primary"
						size="x-large"
					></f-pictogram>
					<f-text align="center" weight="bold" size="x-large">No Sessions Found</f-text>
					<f-text align="center"
						>You have no active sessions at the moment. Once you log in from another device or
						browser, it will appear here.</f-text
					>
				</f-div>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { Session } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { captureError, formatISODate } from "@/utils";

import { settingsStore } from "../settings-store";

export default defineComponent({
	name: "UserSessions",

	data() {
		return {
			activeSessions: [] as Session[],
			loadingSessions: false
		};
	},

	computed: {
		...mapStores(settingsStore, notificationsStore)
	},

	mounted() {
		this.listUserSessions();
	},

	methods: {
		formattedDate(date: string) {
			return formatISODate(date);
		},

		async listUserSessions() {
			try {
				this.loadingSessions = true;
				const resp = await this.settingsStore.LIST_MY_SESSIONS();
				if (resp?.data) {
					this.activeSessions = resp.data;
				}
				this.loadingSessions = false;
			} catch (error) {
				captureError(error);
				this.loadingSessions = false;
			}
		},

		async revokeSession(id: string) {
			try {
				await this.settingsStore.REVOKE_MY_SESSION({ id });
				this.notificationsStore.ADD_TOAST({
					qaId: "revoke-session-success",
					title: "Revoke session successful",
					text: "Revoke session successful",
					status: "success"
				});
				this.listUserSessions();
			} catch (error) {
				captureError(error);
				this.notificationsStore.ADD_TOAST({
					qaId: "revoke-session-error",
					title: "Revoke session failed",
					text: "Revoke session failed, please try again",
					status: "error"
				});
			}
		},

		getBrowserAndOS(userAgent: string) {
			let browser = "Unknown";
			let os = "Unknown";

			// Check for browser
			if (userAgent.includes("Chrome")) {
				browser = "Chrome";
			} else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
				browser = "Safari";
			} else if (userAgent.includes("Firefox")) {
				browser = "Firefox";
			} else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
				browser = "Internet Explorer";
			}

			// Check for OS
			if (userAgent.includes("Mac OS")) {
				os = "Mac OS";
			} else if (userAgent.includes("Windows NT")) {
				os = "Windows";
			} else if (userAgent.includes("Linux")) {
				os = "Linux";
			} else if (userAgent.includes("Android")) {
				os = "Android";
			} else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
				os = "iOS";
			}

			return `${browser} on ${os}`;
		}
	}
});
</script>
