import { defineStore } from "pinia";

import { ToastItem } from "./notification-types";

export const notificationsStore = defineStore("notificationsStore", {
	state() {
		return {
			toasts: [] as ToastItem[]
		};
	},

	actions: {
		ADD_TOAST(toast: ToastItem) {
			if (!toast.id) {
				const uid = Math.random().toString(16).slice(2);
				toast.id = `toast-${uid}`;
			}

			if (!("autoDismiss" in toast)) {
				toast.autoDismiss = true;
			}

			// Check if a similar toast already exists
			for (const t of this.toasts) {
				if (t.text === toast.text && t.title === toast.title && t.qaId === toast.qaId) {
					return; // Do not add duplicate toast
				}
			}

			this.toasts.push(toast);
		},

		REMOVE_TOAST(toast: ToastItem) {
			this.toasts = this.toasts.filter(t => t.id !== toast.id);
		},

		DISMISS_ALL() {
			this.toasts = [];
		}
	}
});
