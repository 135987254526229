import { computed, Ref } from "vue";

import { Evidence } from "@/protocol/evidence";

export function useEvidenceType(currentEvidence: Ref<Evidence | undefined>) {
	const currentEvidenceType = computed(() => {
		const fileType = currentEvidence.value?.fileType ?? "";
		if (fileType === "application/pdf") {
			return "pdf";
		} else if (fileType.startsWith("image/")) {
			return "image";
		} else if (fileType === "" || fileType.startsWith("text/")) {
			return "text";
		} else {
			return "unknown";
		}
	});

	return {
		currentEvidenceType
	};
}
