<script setup lang="ts">
import { FIconButtonState, FTextStateProp } from "@ollion/flow-core";
import { computed } from "vue";

import { DraftDcumentSteps, documentMappingStore } from "../document-mapping-store";

const documentStore = documentMappingStore();

const currentStep = computed(() => documentStore.currentDocumentState.documentStep);

const steps = computed((): DocumentMappingStep[] => {
	const loadingStatus: DocumentMappingStep["status"] = {
		statusId: "ongoing",
		iconName: "i-circle",
		iconState: "primary",
		textState: "default"
	};
	const doneStatus: DocumentMappingStep["status"] = {
		statusId: "completed",
		iconName: "i-tick-fill",
		iconState: "success",
		textState: "success"
	};
	const lockedStatus: DocumentMappingStep["status"] = {
		statusId: "pending",
		iconName: "i-lock",
		iconState: "neutral",
		textState: "default"
	};

	const currentStepValue = currentStep.value;

	return [
		{
			id: "upload",
			name: "Add document",
			status: currentStepValue === "upload" ? loadingStatus : doneStatus,
			tooltip: "Upload the document to be mapped"
		},
		{
			id: "details",
			name: "Confirm details",
			status:
				// eslint-disable-next-line no-nested-ternary
				currentStepValue === "details"
					? loadingStatus
					: currentStepValue === "statements" || currentStepValue === "mapping"
						? doneStatus
						: lockedStatus,
			tooltip: "Provide and confirm the details about the document"
		},
		{
			id: "statements",
			name: "Select statements",

			status:
				// eslint-disable-next-line no-nested-ternary
				currentStepValue === "statements"
					? loadingStatus
					: currentStepValue === "mapping"
						? doneStatus
						: lockedStatus,
			tooltip: "Select the statements to be included in the document"
		},
		{
			id: "mapping",
			name: "Map statements",
			status: currentStepValue === "mapping" ? loadingStatus : lockedStatus,
			tooltip: "Review the mapped document"
		}
	];
});

const title = computed(() => {
	let computedTitle = "Add new document";

	const docName = documentStore.currentDocumentState.metadata.documentName;

	if (docName) {
		computedTitle += `: ${docName}`;
	}

	return computedTitle;
});

type DocumentMappingStep = {
	id: DraftDcumentSteps;
	name: string;
	status: {
		statusId: "pending" | "completed" | "ongoing" | "alert";
		iconName: string;
		iconState: FIconButtonState;
		textState: FTextStateProp;
	};
	tooltip?: string;
};
</script>

<template>
	<f-div
		height="hug-content"
		width="fill-container"
		state="subtle"
		padding="medium"
		align="middle-left"
		gap="medium"
		flex-wrap
		border="small solid secondary bottom"
	>
		<f-text variant="heading" weight="bold" size="medium">{{ title }}</f-text>

		<f-div
			v-for="(step, idx) in steps"
			:key="step.name"
			align="middle-center"
			width="hug-content"
			height="hug-content"
			gap="small"
			overflow="visible"
		>
			<f-div
				gap="x-small"
				overflow="visible"
				align="middle-center"
				width="hug-content"
				height="hug-content"
			>
				<f-icon-button
					:icon="step.status.iconName"
					size="small"
					category="packed"
					:state="step.status.iconState"
					:effect="step.status.statusId === 'ongoing' ? 'pulse' : undefined"
					:tooltip="step.tooltip"
				></f-icon-button>
				<f-text :state="step.status.textState" variant="para" size="small" weight="medium">{{
					step.name
				}}</f-text>
			</f-div>
			<f-icon v-if="idx < steps.length - 1" state="subtle" source="i-arrow-right"></f-icon>
		</f-div>
	</f-div>
</template>
