<template>
	<f-div padding="none" width="fill-container" gap="small" height="hug-content">
		<f-div width="100%" height="hug-content">
			<f-table>
				<f-trow slot="header" class="padding-0">
					<f-tcell v-for="item in columnTitles" :key="item.id" size="small" :width="item.width"
						>{{ item.title }}
					</f-tcell>
				</f-trow>
				<f-trow>
					<f-tcell width="auto">
						<f-text size="small"> {{ selectedControl.controls?.id }} </f-text> </f-tcell
					><f-tcell width="25%">
						<f-text size="small"> {{ selectedControl.controls?.statement }}</f-text>
					</f-tcell>
				</f-trow>
			</f-table>
		</f-div>
		<f-div width="100%" height="hug-content">
			<f-div>
				<f-tab class="margin-lt-10 margin-rt-10" height="hug-content">
					<f-tab-node
						v-for="tab of tabNodes"
						:key="tab.id"
						:data-qa-tab="`compliance-details-tab-${tab.name}`"
						:active="selected === tab.id"
						:content-id="tab.id"
						@click="selected = tab.id"
						><f-div width="100%" height="100%" align="middle-center" direction="column">
							<f-div align="middle-center" height="hug-content" width="hug-content">
								<f-div padding="none" gap="small" align="middle-left">{{ tab.name }} </f-div>
							</f-div>
						</f-div>
					</f-tab-node>
				</f-tab>
				<f-tab-content class="margin-lt-10 margin-rt-10" height="hug-content">
					<f-div
						v-if="listData !== undefined && Object.keys(listData).length === 0"
						padding="x-large"
						height="200px"
					>
						<EmptyState message="No records available" shape="circle"> </EmptyState>
					</f-div>
					<ControlTabsSection
						v-if="selected === 'best_practice' && listData"
						:list-data="listData"
					/>
					<f-div v-for="(value, key) in listData" v-else :key="key">
						<ControlTabsSection :list-data="value" :show-divider="false"></ControlTabsSection>
					</f-div>
				</f-tab-content>
			</f-div>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { defineComponent, PropType } from "vue";

import { CompositeControls } from "@/protocol/correlation";

import ControlTabsSection from "./ControlTabsSection.vue";

type TabNodes = {
	name: string;
	id: "best_practice" | "regulatory_document" | "evidence";
};

export default defineComponent({
	name: "ControlComponentDetail",

	components: {
		ControlTabsSection,
		EmptyState
	},

	props: {
		selectedControl: {
			type: Object as PropType<CompositeControls>,
			required: true
		}
	},

	data() {
		return {
			columnTitles: [
				{ title: "Best Practice ID", id: "practiceId", width: "30%" },
				{ title: "Best Practice Name", id: "name", width: "70%" }
			],

			selected: "best_practice" as "best_practice" | "regulatory_document" | "evidence",
			tabNodes: [
				{ name: "Best Practice", id: "best_practice" },
				{ name: "Regulatory Document", id: "regulatory_document" },
				{ name: "Evidence", id: "evidence" }
			] as TabNodes[]
		};
	},

	computed: {
		listData() {
			if (this.selected === "best_practice") {
				const { controls } = this.selectedControl;
				return {
					"Best Practice Name": controls?.name,
					"Best Practice Statement ID": controls?.name,
					"Best Practice Statement": controls?.statement
				};
			} else if (this.selected === "regulatory_document") {
				const { regStatements } = this.selectedControl;

				return regStatements?.map(statement => ({
					"Regulatory Statement ID": statement.id,
					"Regulatory Document Name": statement.name,
					"Regulatory Statement": statement.statement
				}));
			} else {
				const evidences = this.selectedControl.controls?.evidences ?? [];

				return evidences.map(evidence => ({
					Type: "/stance.coauth.io/evidence/id1",
					Location: evidence.id,
					"Approval Location": "/stance.coauth.io/evidence/id1",
					"Approval Status": evidence.status,
					"Approval User": "Brendon Bryne"
				}));
			}
		}
	}
});
</script>
