<script setup lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData, FTableSchemaDataRow } from "@ollion/flow-table";
import { PropType, computed, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import {
	DraftDocument,
	documentMappingStore
} from "@/modules/document-ingestion/document-mapping-store";
import { Document } from "@/protocol/document";

import DocumentVersionComparator from "./DocumentVersionComparator.vue";

const emit = defineEmits(["add-document", "link-taxonomy"]);

const props = defineProps({
	canCreate: Boolean,

	documents: {
		type: Array as PropType<Document[]>,
		required: true
	},

	draftDocuments: {
		type: Array as PropType<DraftDocument[]>,
		required: true
	}
});

const { documents, draftDocuments } = toRefs(props);

const router = useRouter();

const documentStore = documentMappingStore();
const activeDocuments = computed(() => {
	return [...draftDocuments.value, ...documents.value.filter(document => document.active)];
});

const documentDropdown = ref<Document | null>(null);
const draftDocumentDropdown = ref<DraftDocument | null>(null);
const confirmDeleteDraft = ref(false);

const versionedDocuments = computed(() => {
	const versions: Record<string, Document[]> = {};

	documents.value.forEach(document => {
		const docName = document.documentName;
		if (!docName) {
			return;
		}

		if (!versions[docName]) {
			versions[docName] = [];
		}

		versions[docName]!.push(document);
	});

	return versions;
});

const comparingDocument = ref<Document | null>(null);

const tableData = computed((): FTableSchemaData => {
	return {
		header: {
			status: {
				value: "Status",
				width: "auto"
			},
			name: {
				value: "Name",
				width: "auto",
				align: "middle-left"
			},
			version: {
				value: "Version",
				width: "auto"
			},
			documentType: {
				value: "Type",
				width: "auto"
			},
			view: {
				value: " ",
				width: "auto",
				disableSort: true
			}
		},

		rows: activeDocuments.value.map(document => {
			const documentName =
				"documentName" in document ? document.documentName : document.metadata.documentName;

			const documentVersion =
				"documentVersion" in document
					? document.documentVersion
					: document.metadata.documentVersion;

			const documentType =
				"documentType" in document ? document.documentType : document.metadata.documentType;

			return {
				id: document.documentId,
				data: {
					status: {
						value: document.documentId,
						align: "middle-left",
						template: () => {
							const isPublished = `documentName` in document;
							return html`<f-tag
								label="${isPublished ? "Published" : "Draft"}"
								icon-left="${isPublished ? "i-verified" : "i-edit"}"
								state="${isPublished ? "success" : "warning"}"
							></f-tag>`;
						}
					},
					name: {
						value: documentName,
						align: "middle-left"
					},
					version: {
						value: documentVersion,
						align: "middle-left"
					},
					documentType: {
						value: documentType,
						align: "middle-left"
					},
					view: {
						value: " ",
						align: "middle-left",
						template: () => {
							return html`
								<f-icon
									data-qa-document-more-action="${document.documentId}"
									style="visibility:var(--hide-icon);"
									source="i-more"
									category="outline"
									size="small"
									clickable
									tooltip="More options"
									@click="${() => showOptionsFor(document)}"
								></f-icon>
							`;
						}
					}
				}
			};
		}) as FTableSchemaDataRow[]
	};
});

function compareDocumentVersions(document: Document) {
	comparingDocument.value = document;
	documentDropdown.value = null;
}

function linkTaxonomy(document: Document) {
	emit("link-taxonomy", document);
	documentDropdown.value = null;
}

function showOptionsFor(document: DraftDocument | Document) {
	if ("documentName" in document) {
		documentDropdown.value = document;
	} else {
		draftDocumentDropdown.value = document;
	}
}

function deleteDraftDocument() {
	if (!draftDocumentDropdown.value) {
		return;
	}

	documentStore.DELETE_DRAFT_DOCUMENT(draftDocumentDropdown.value.documentId);
	draftDocumentDropdown.value = null;
	confirmDeleteDraft.value = false;
}

function editDraftDocument() {
	if (!draftDocumentDropdown.value) {
		return;
	}

	router.push({
		name: "draft-document",
		params: {
			documentId: draftDocumentDropdown.value.documentId
		}
	});

	draftDocumentDropdown.value = null;
}

function getVersionedDocuments(document: Document) {
	return versionedDocuments.value[document.documentName] ?? [];
}
</script>

<template>
	<f-div align="top-left" direction="column" height="fill-container">
		<!-- Header -->
		<f-div align="middle-left" height="hug-content" gap="medium" padding="medium" state="secondary">
			<f-text data-qa-document-ingestion-title variant="heading" weight="bold">Documents</f-text>
			<f-button
				v-if="canCreate"
				category="outline"
				data-qa="upload-document"
				icon-left="i-plus"
				label="Add New"
				@click="$emit('add-document')"
			></f-button>
		</f-div>
		<!-- End Header -->

		<f-div width="fill-container" padding="medium">
			<f-table-schema :data="tableData" variant="underlined" size="small"></f-table-schema>
		</f-div>
	</f-div>

	<DocumentVersionComparator
		v-if="comparingDocument"
		:document="comparingDocument"
		@close="comparingDocument = null"
	/>

	<f-popover
		v-if="confirmDeleteDraft"
		open
		size="custom(480px,auto)"
		:overlay="true"
		@overlay-click="draftDocumentDropdown = null"
		@esc="draftDocumentDropdown = null"
		@close="draftDocumentDropdown = null"
	>
		<f-div direction="column" padding="medium" gap="x-large" height="hug-content">
			<f-text size="medium"
				>Are you sure you want to delete the draft document
				<f-text weight="bold" inline>{{ draftDocumentDropdown?.metadata.documentName }}</f-text
				>?</f-text
			>

			<f-div clickable gap="medium" height="hug-content">
				<f-button label="Delete" state="danger" @click="deleteDraftDocument()"></f-button>
				<f-button
					label="Cancel"
					state="neutral"
					category="outline"
					@click="confirmDeleteDraft = false"
				></f-button>
			</f-div>
		</f-div>
	</f-popover>

	<f-popover
		v-else-if="draftDocumentDropdown"
		open
		:target="`[data-qa-document-more-action='${draftDocumentDropdown?.documentId}']`"
		size="custom(150px,auto)"
		placement="bottom-end"
		:overlay="false"
		shadow
		@overlay-click="draftDocumentDropdown = null"
		@esc="draftDocumentDropdown = null"
		@close="draftDocumentDropdown = null"
	>
		<f-div direction="column">
			<f-div clickable padding="medium" @click="editDraftDocument">
				<f-text size="small">Edit draft</f-text>
			</f-div>

			<f-div clickable padding="medium" @click="confirmDeleteDraft = true">
				<f-text state="danger" size="small">Delete draft</f-text>
			</f-div>
		</f-div>
	</f-popover>

	<f-popover
		v-else-if="documentDropdown"
		open
		:target="`[data-qa-document-more-action='${documentDropdown?.documentId}']`"
		size="custom(150px,auto)"
		placement="left-start"
		:overlay="false"
		shadow
		@overlay-click="documentDropdown = null"
		@esc="documentDropdown = null"
		@close="documentDropdown = null"
	>
		<f-div direction="column">
			<f-div clickable padding="medium" @click="linkTaxonomy(documentDropdown)">
				<f-text size="small">Link taxonomy</f-text>
			</f-div>

			<f-div
				v-if="getVersionedDocuments(documentDropdown).length > 1"
				clickable
				padding="medium"
				@click="compareDocumentVersions(documentDropdown)"
			>
				<f-text size="small">Compare versions</f-text>
			</f-div>
		</f-div>
	</f-popover>
</template>
