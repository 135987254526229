<template>
	<f-div direction="column" width="100%" padding="none">
		<ComponentCatalogHeader :component="component" />
		<ComponentOnboardingTabs
			:component="component"
			:active-tab-id="activeTabId"
			:tabs="onboardTabs"
		/>

		<component
			:is="currentActiveTab.contentComponentName"
			v-if="currentActiveTab"
			:component="component"
			:tab="currentActiveTab"
			:search-string="searchString"
			@retry="retryPipeline"
		/>
	</f-div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import AssetDetailTab from "@/modules/release-cockpit-v2/components/component-catalog-detail/AssetDetailTab.vue";
import ComponentCatalogHeader from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentCatalogHeader.vue";
import ComponentOnboardingAutomatedTab from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingAutomatedTab.vue";
import ComponentOnboardingDetailTab from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingDetailTab.vue";
import ComponentOnboardingManualTab from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingManualTab.vue";
import ComponentOnboardingPipelineLogs from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingPipelineLogs.vue";
import ComponentOnboardingTabs from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingTabs.vue";
import SoftwareDetailTab from "@/modules/release-cockpit-v2/components/component-catalog-detail/SoftwareDetailTab.vue";
import {
	ComponentOnboardingTab,
	OnboardingTabIdType,
	onboardingTabIds,
	onboardingTabs
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { ArtifactType, Component, ComponentType } from "@/protocol/cockpit";

import { updateArtifact } from "../component-catalog-service";

export default defineComponent({
	name: "ComponentOnboardingPage",

	components: {
		ComponentOnboardingPipelineLogs,
		ComponentOnboardingAutomatedTab,
		ComponentOnboardingDetailTab,
		ComponentOnboardingManualTab,
		ComponentOnboardingTabs,
		ComponentCatalogHeader,
		AssetDetailTab,
		SoftwareDetailTab
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		tabName: {
			type: String as PropType<OnboardingTabIdType>,
			required: false
		}
	},

	data: () => {
		return {
			searchString: ""
		};
	},

	computed: {
		...mapStores(componentOnboardStore, authStore),

		componentType() {
			return this.component.type;
		},

		onboardTabs(): ComponentOnboardingTab[] {
			const tabs: ComponentOnboardingTab[] = [];

			if (this.componentType === ComponentType.SERVICE) {
				tabs.push(onboardingTabs.serviceDetails);
				tabs.push(onboardingTabs.automated);
				tabs.push(onboardingTabs.manual);
				tabs.push(onboardingTabs.pipelineLogs);
			} else if (this.componentType === ComponentType.SOFTWARE) {
				tabs.push(onboardingTabs.softwareDetails);
				tabs.push(onboardingTabs.manual);
			} else if (this.componentType === ComponentType.ASSET) {
				tabs.push(onboardingTabs.assetDetails);
			}

			return tabs;
		},

		currentActiveTab(): ComponentOnboardingTab {
			return this.onboardTabs.find(tab => tab.id === this.activeTabId) as ComponentOnboardingTab;
		},

		activeTabId(): OnboardingTabIdType {
			if (this.tabName && onboardingTabIds.includes(this.tabName)) {
				return this.tabName;
			}
			return "details";
		}
	},

	mounted() {
		this.fetchComponentOnboardingDocuments();
	},

	methods: {
		fetchComponentOnboardingDocuments() {
			this.componentOnboardStore.fetchComponentOnboardingDocuments(this.component);
			this.componentOnboardStore.fetchGatesAndEnvironments();
		},

		getUserId() {
			return this.authStore.userSession?.identity?.id;
		},

		async retryPipeline(componentUpdated: () => void) {
			if (!this.component.artifact?.id) {
				return;
			}

			await updateArtifact({
				id: this.component.artifact.id,
				componentId: this.component.id,
				name: this.component.name,
				status: "active",
				createdBy: this.getUserId() ?? "",
				approvedBy: "",
				type: ArtifactType.GIT_REPO,
				metadata: {
					terraformGitRepo: {
						iac: this.component.artifact.metadata?.terraformGitRepo?.iac ?? {},
						pac: this.component.artifact.metadata?.terraformGitRepo?.pac ?? {}
					}
				}
			});
			await this.componentOnboardStore.getComponentById(this.component.id);

			componentUpdated();
		}
	}
});
</script>
