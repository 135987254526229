<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, toRefs, watch } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";

import { IngestRequest, Statement } from "@/protocol/document";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import {
	captureError,
	downloadFile,
	getErrorMessage,
	getPDFDocumentFromEvent,
	getStatementSeverity,
	isValidSemver,
	readFile
} from "@/utils";

import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";
import { featureFlagStore } from "../feature-flags/feature-flags-store";
import { notificationsStore } from "../notifications/notifications-store";

import DocumentDetailsContainer from "./components/DocumentDetailsContainer.vue";
import DocumentMappingContainer from "./components/DocumentMappingContainer.vue";
import DocumentMappingHeader from "./components/DocumentMappingHeader.vue";
import StatementsListContainer from "./components/StatementsListContainer.vue";
import { ingestDocumentsV2, storeFineTuningFeedback } from "./document-ingestion-service";
import { documentIngestionStore } from "./document-ingestion-store";
import { DraftDcumentSteps, documentMappingStore } from "./document-mapping-store";

const router = useRouter();

// Stores
const documentStore = documentMappingStore();
const docIngestionStore = documentIngestionStore();
const notificationsStoreInstance = notificationsStore();
const featureFlagStoreInstance = featureFlagStore();

// State vars
const isPublishingDocument = ref(false);
const isMappingStatements = ref(false);
const documentMappingProgress = ref(0);
const isSavingDraft = ref(false);
const isParsingPDF = ref(false);
const isLoading = ref(true);
const hasPublishedDocument = ref(false);
const errorMessage = ref("");

const props = defineProps({
	documentId: {
		type: String,
		required: true
	}
});

const { documentId } = toRefs(props);

// Computed props
const documentState = computed(() => documentStore.currentDocumentState);
const currentStep = computed(() => documentStore.currentDocumentState.documentStep);
const activeTabId = computed(() => {
	if (currentStep.value === "statements") {
		return "statements";
	} else if (currentStep.value === "mapping") {
		return "mapping";
	} else if (currentStep.value === "details") {
		return "details";
	}

	return null;
});
const supportsExportFeature = computed(() => featureFlagStoreInstance.featureMap.DOCUMENT_EXPORT);

const selectedStatements = computed(() => {
	return Object.values(documentState.value.selectedStatementIds).filter(Boolean).length;
});

const tabs = computed((): Tab[] => {
	const mappingTab: Tab = { id: "mapping", name: "Mapping" };

	const otherTabs: Tab[] = [
		{ id: "details", name: "Document details" },
		{ id: "statements", name: "Statements" }
	];

	if (selectedStatements.value > 0) {
		return [...otherTabs, mappingTab];
	}

	return otherTabs;
});

const isDocumentDetailValid = computed(() => {
	const { metadata, classification } = documentState.value;
	let isValid =
		metadata.documentName !== "" &&
		metadata.documentVersion !== "" &&
		isValidSemver(metadata.documentVersion) &&
		metadata.documentType !== undefined;

	if (metadata.documentType === "Guideline" && !metadata.regulatoryOrg) {
		isValid = false;
	}
	if (
		metadata.documentType === "Best_Practice" &&
		(!classification || classification.length === 0)
	) {
		isValid = false;
	}
	return isValid;
});

onMounted(async () => {
	await documentStore.FETCH_REG_ORGS();

	window.addEventListener("beforeunload", onClose);
});

onUnmounted(() => {
	window.removeEventListener("beforeunload", onClose);
});

watch(
	[documentId],
	async ([newDocumentId]) => {
		isLoading.value = true;
		await documentStore.LOAD_DRAFT_DOCUMENT(newDocumentId);
		isLoading.value = false;
	},
	{
		immediate: true
	}
);

// Before the user leaves the page, save the document as a draft
onBeforeRouteLeave(async (to, from, next) => {
	if (!hasPublishedDocument.value) {
		await safeAsDraft();
	}
	next();
});

// Show prompt to user about unsaved changes
function onClose(event: BeforeUnloadEvent) {
	event.preventDefault();
	event.returnValue = true;
	return true;
}

// eslint-disable-next-line max-statements, complexity
async function uploadDocument(e: CustomEvent<{ value: File }>) {
	isParsingPDF.value = true;
	documentStore.SET_DRAFT_DOCUMENT_STEP("details");
	const file = e.detail.value;
	const fileName = e.detail.value.name;

	if (file.type === "application/json") {
		try {
			const fileContents = await readFile(file);
			const decoder = new TextDecoder("utf-8");
			const decodedJson = decoder.decode(fileContents);
			documentStore.INITIALISE_DOCUMENT_STATE_FROM_JSON(JSON.parse(decodedJson));
		} catch (err) {
			captureError(err);
			errorMessage.value = "Invalid file format. Please upload a valid JSON file.";
		}
	} else {
		const document = await getPDFDocumentFromEvent(e);
		await documentStore.INITIALISE_DOCUMENT_STATE_FROM_DOCUMENT(document, fileName);
	}

	isParsingPDF.value = false;
}

function startMapping() {
	documentStore.SET_DRAFT_DOCUMENT_STEP("mapping");
}

function goToStatements() {
	documentStore.SET_DRAFT_DOCUMENT_STEP("statements");
}

function onTabClick(tab: (typeof tabs)["value"][number]) {
	documentStore.SET_DRAFT_DOCUMENT_STEP(tab.id);
}

// Right now we save the document locally so it's instant, but eventually we'll need to save it to the backend
// So we simulate that experience for now
async function safeAsDraft() {
	isSavingDraft.value = true;
	await documentStore.SAVE_DRAFT_DOCUMENT();
	await new Promise(resolve => setTimeout(resolve, 500));
	isSavingDraft.value = false;
}

async function addNewDocument() {
	isPublishingDocument.value = true;
	const ingestDocumentRequest: IngestRequest = {
		documents: []
	};

	const {
		documentType,
		regulatoryOrg,
		documentName,
		documentVersion: version
	} = documentState.value.metadata;

	if (!documentType || (documentType === "Guideline" && !regulatoryOrg)) {
		throw new Error("Document type is required");
	}

	ingestDocumentRequest.documents.push({
		documentName,
		documentVersion: version,
		documentType,
		publishedBy: regulatoryOrg ?? "internal",

		// Backend has a limitation, it returns the date in string but expects as number
		// And that too with a millisecond precision removed
		//@ts-expect-error
		reviewDate: Math.round(Date.now() / 1000),
		//@ts-expect-error
		publishedDate: Math.round(Date.now() / 1000),
		//@ts-expect-error
		effectiveDate: Math.round(Date.now() / 1000),

		statements: documentStore.currentSelectedStatements
			.map((statement): Statement | null => {
				const cobitMap = documentState.value.cobitMap[statement.id];

				if (!cobitMap) {
					captureError(new Error(`No cobit map found for statement: ${statement.id}`));
					return null;
				}

				const cobitId = cobitMap.id;
				// Control set is the cobit ID without the last part
				const controlSet = cobitId.split(".").slice(0, -1).join(".");

				return {
					documentType,
					statementNativeId: statement.id,
					statement: statement.str,
					cobitMap: cobitId,
					cobitMapType: statement.type,
					controlSet,
					// `statementStrength` and `severity` fields are conflated
					// https://ollion.atlassian.net/wiki/spaces/CP/pages/3311632435/Graph+data+modal
					//@todo - Figure out which field to use
					severity: getStatementSeverity(statement.str),

					// These fields are required by the backend but serve no purpose yet
					scopeWord: "-",
					documentSection: "-",
					documentSectionName: "-"
				};
			})
			.filter((item): item is Statement => Boolean(item))
	});

	try {
		const response = await ingestDocumentsV2(ingestDocumentRequest);

		notificationsStoreInstance.ADD_TOAST({
			qaId: "document-saved-toast",
			status: "success",
			title: "Document saved successfully",
			text: `Document ${documentName} has been saved successfully.`
		});

		// Classify the document
		if (
			documentType === "Best_Practice" &&
			response.documentIds &&
			response.documentIds.length > 0
		) {
			const taxonomies = documentState.value.classification;
			// We have only uploaded 1 document we can't expect more
			await docIngestionStore.LINK_TAXONOMY(response.documentIds[0]!, taxonomies);
		}

		try {
			// Store fine tuning feedback
			const feedbacks = Object.values(documentState.value.similarityFeedback);

			await storeFineTuningFeedback({
				feedbacks: feedbacks.map(({ statement1, statement2, modelScore, userScore }) => ({
					statement1,
					statement2,
					modelScore,
					userScore
				}))
			});
		} catch (err) {
			// Just capture error, since the doc was ingested successfully
			captureError(err);
		}

		hasPublishedDocument.value = true;

		await documentStore.DELETE_DRAFT_DOCUMENT(documentId.value);

		router.replace({ name: "document-ingestion" });
	} catch (error) {
		captureError(error);
		errorMessage.value = getErrorMessage(error);
		notificationsStoreInstance.ADD_TOAST({
			qaId: "error-toast",
			status: "error",
			title: "Failed to ingest document",
			text: errorMessage.value
		});
	} finally {
		isPublishingDocument.value = false;
	}
}

function exportDocument() {
	downloadFile(
		JSON.stringify(documentState.value, null, 2),
		`${documentState.value.metadata.documentName}.json`
	);
}

type Tab = {
	id: DraftDcumentSteps;
	name: string;
};
</script>

<template>
	<PermissionsWrapper>
		<CustomLoader v-if="isLoading"></CustomLoader>
		<f-div v-else direction="column" overflow="visible" state="default">
			<DocumentMappingHeader />

			<!-- Start Tab bar: It is visible on all screens except for upload -->
			<f-div
				v-if="currentStep !== 'upload'"
				height="hug-content"
				align="middle-left"
				gap="medium"
				padding="none medium none none"
				border="small solid secondary bottom"
			>
				<f-div
					width="hug-content"
					align="middle-center"
					:disabled="
						isMappingStatements || isPublishingDocument || isSavingDraft || !isDocumentDetailValid
					"
				>
					<f-tab variant="no-border" alignment="left">
						<f-tab-node
							v-for="tab in tabs"
							:key="tab.id"
							:active="tab.id === activeTabId"
							:content-id="`tab-${tab.id}`"
							:data-qa="`tab-${tab.id}`"
							@click="onTabClick(tab)"
						>
							<f-div align="middle-center" gap="small" width="hug-content" height="20px">
								<f-text
									variant="para"
									size="small"
									:weight="tab.id === activeTabId ? 'bold' : 'regular'"
								>
									{{ tab.name }}
								</f-text>
							</f-div>
						</f-tab-node>
					</f-tab>
				</f-div>

				<f-div gap="medium" align="middle-right">
					<f-button
						:disabled="
							!documentState.metadata.documentName || isMappingStatements || isPublishingDocument
						"
						:loading="isSavingDraft"
						size="small"
						label="Save as draft"
						category="outline"
						@click="safeAsDraft"
					></f-button>

					<f-button
						v-if="supportsExportFeature"
						:disabled="documentState.statements.length === 0"
						icon-left="i-download"
						size="small"
						label="Export"
						category="outline"
						@click="exportDocument"
					></f-button>

					<f-button
						v-if="currentStep === 'statements'"
						:disabled="selectedStatements === 0"
						size="small"
						:label="`Next - Map ${selectedStatements} statements`"
						@click="startMapping"
					></f-button>

					<f-button
						v-else-if="currentStep === 'details'"
						:disabled="!isDocumentDetailValid"
						size="small"
						label="Next - Select statements"
						@click="goToStatements"
					></f-button>

					<f-button
						v-else-if="currentStep === 'mapping'"
						size="small"
						:label="
							documentMappingProgress > 0 && documentMappingProgress < 1
								? `${Math.round(documentMappingProgress * 100)}% complete`
								: 'Publish'
						"
						:disabled="isMappingStatements || isSavingDraft"
						:loading="isPublishingDocument"
						@click="addNewDocument"
					></f-button>
				</f-div>
			</f-div>
			<!-- End Tab bar-->

			<f-div
				v-if="errorMessage"
				state="danger"
				padding="medium"
				align="middle-left"
				gap="small"
				height="hug-content"
			>
				<f-icon source="i-alert-fill" state="danger"></f-icon>
				<f-text>{{ errorMessage }}</f-text>
			</f-div>

			<!-- Start upload screen -->
			<template v-if="currentStep === 'upload'">
				<f-div align="middle-center" width="fill-container">
					<f-div
						padding="medium"
						align="middle-center"
						gap="small"
						direction="column"
						max-width="40%"
					>
						<f-icon source="i-plus-fill" size="large" state="primary"></f-icon>
						<f-text variant="heading" size="small" weight="medium">Add new document</f-text>
						<f-file-upload
							placeholder="Drag and drop files here or click to upload"
							:file-type="supportsExportFeature ? '.pdf,.json' : '.pdf'"
							state="primary"
							size="small"
							@input="uploadDocument"
						>
						</f-file-upload>
					</f-div>
				</f-div>
			</template>
			<!-- End upload screen -->

			<!-- Start details screen -->
			<template v-else-if="currentStep === 'details'">
				<DocumentDetailsContainer :is-parsing-pdf="isParsingPDF" />
			</template>
			<!-- End details screen -->

			<template v-else-if="currentStep === 'statements'">
				<StatementsListContainer />
			</template>

			<template v-else-if="currentStep === 'mapping'">
				<DocumentMappingContainer
					@started-mapping="isMappingStatements = true"
					@finished-mapping="isMappingStatements = false"
					@mapping-progress="documentMappingProgress = $event"
				/>
			</template>
		</f-div>
	</PermissionsWrapper>
</template>
