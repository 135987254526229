<template>
	<Loader v-if="showLoader" :data-qa="dataQa" :size="size" :position="position">
		<p v-if="showMessage" class="paragraph-2 fc-light">{{ message }}</p>
	</Loader>
</template>

<script lang="ts">
import { Loader, LoaderSize } from "@ollion/flow-vue3";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "CustomLoader",

	components: {
		Loader
	},

	props: {
		dataQa: {
			type: String,
			default: "loading-screen"
		},

		position: {
			type: String as PropType<"absolute" | "relative">,
			default: "absolute"
		},

		size: {
			type: String as PropType<LoaderSize>,
			default: "large"
		},

		message: {
			type: String,
			default: "Loading..."
		},

		initialDelay: {
			type: Number,
			default: 250
		}
	},

	data: () => ({
		showLoader: false,
		showMessage: false,
		showLoaderTimeout: 0 as number,
		showMessageTimeout: 0 as number
	}),

	mounted() {
		this.showLoaderTimeout = window.setTimeout(() => {
			this.showLoader = true;
		}, this.initialDelay);

		this.showMessageTimeout = window.setTimeout(() => {
			this.showMessage = true;
		}, 3000);
	},

	beforeUnmount() {
		if (this.showLoaderTimeout) {
			clearTimeout(this.showLoaderTimeout);
		}
		if (this.showMessageTimeout) {
			clearTimeout(this.showMessageTimeout);
		}
	}
});
</script>
