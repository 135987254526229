<template>
	<AuthLayout header="Verification">
		<FormWrapper>
			<Section padding="horizontal">
				<FormField>
					<template #label>
						<p class="paragraph-2 fc-dark">Token</p>
					</template>
					<InputText v-model:value="code" placeholder="Enter token" type="text"> </InputText>
				</FormField>
			</Section>
		</FormWrapper>
		<Typography v-if="apiErrorMsg" class="padding-tp-10" type="p1" color="error">{{
			apiErrorMsg
		}}</Typography>
		<Section padding="all" class="center-of-container">
			<div class="center-of-container flex-gap-20px width-80-per">
				<Button
					:disabled="!isSubmitAllowed"
					type="primary"
					:full-width="true"
					data-qa="recovery-verification-btn"
					@click="submitVerificationFlow"
					>Submit</Button
				>
				<Button
					v-if="showSendEmailButton"
					type="primary"
					state="outlined"
					:full-width="true"
					data-qa="recovery-verification-send-email"
					@click="redirectTo(`send-recovery-email`)"
					>Send Email</Button
				>
			</div>
		</Section>
	</AuthLayout>
</template>

<script lang="ts">
import { FormWrapper, FormField, InputText, Section, Button, Typography } from "@ollion/flow-vue3";
import { UiNode, UiNodeInputAttributes, UpdateRecoveryFlowBody } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { captureError } from "@/utils";

import { authStore } from "../auth-store";
import AuthLayout from "../views/AuthLayout.vue";

export default defineComponent({
	name: "RecoveryVerification",

	components: {
		AuthLayout,
		FormWrapper,
		FormField,
		InputText,
		Section,
		Button,
		Typography
	},

	data() {
		return {
			verificationFlowId: "",
			code: "",
			apiErrorMsg: "",
			showSendEmailButton: false,
			csrfToken: ""
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore),

		isSubmitAllowed(): boolean {
			return true;
		}
	},

	mounted() {
		this.getIdToken();
	},

	methods: {
		redirectTo(screenName: string) {
			this.$router.push({ name: screenName });
		},

		async getIdToken() {
			//Get flowId from route
			const flowId = this.$route.query.flow;
			this.code = this.$route.query.code as string;
			if (flowId) {
				try {
					const resp = await this.authStore.GET_RECOVERY_FLOW({ id: flowId as string });
					this.verificationFlowId = resp.data.id;
					const attributes = resp.data.ui.nodes[0]?.attributes as { value: string };
					this.csrfToken = attributes.value;
				} catch (error) {
					captureError(error);
					this.createRecoveryFlow();
				}
			} else {
				this.createRecoveryFlow();
			}
		},

		async createRecoveryFlow() {
			//Create recovery flow id
			const resp = await this.authStore.CREATE_RECOVERY_FLOW();
			this.verificationFlowId = resp?.data.id as string;
			const csrfTokenNode = resp?.data.ui.nodes.find(
				(node: UiNode) => (node.attributes as UiNodeInputAttributes).name === "csrf_token"
			);
			this.csrfToken = (csrfTokenNode?.attributes as UiNodeInputAttributes).value;
		},

		async submitVerificationFlow() {
			//Submit Verification with token and flowId

			const body: UpdateRecoveryFlowBody = {
				csrf_token: this.csrfToken,
				method: "code",
				code: this.code
			};
			try {
				const resp = await this.authStore.SUBMIT_RECOVERY_FLOW({
					flow: this.verificationFlowId,
					updateRecoveryFlowBody: body
				});
				if (resp.data.ui.messages) {
					//Ory kratos return success when invalid token
					if (resp.data.ui.messages[0]?.type === "error") {
						this.apiErrorMsg = resp.data.ui.messages[0].text;
						this.showSendEmailButton = true;
						return;
					}
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-ory-verified",
						title: resp.data.ui.messages[0]?.text as string,
						text: "Please change your password",
						status: "success"
					});
					this.$router.replace({ name: "change-password" });
				}
			} catch (error) {
				captureError(error);
				if (typeof error === "string") {
					this.apiErrorMsg = error;
				}
				this.showSendEmailButton = true;
			}
		}
	}
});
</script>
