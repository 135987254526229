<template>
	<f-div direction="column" overflow="hidden" width="100%">
		<f-div
			direction="row"
			gap="small"
			padding="medium"
			height="hug-content"
			border="small solid subtle bottom"
		>
			<f-div gap="small" align="middle-left" width="hug-content">
				<f-text variant="heading" size="small" data-qa-manage-members-title>Members</f-text>
				<f-divider size="medium" direction="vertical"></f-divider>
			</f-div>
			<f-div gap="small" align="middle-left">
				<f-div width="586px">
					<f-search
						placeholder="Search members"
						size="small"
						:value="searchText"
						variant="round"
						@input="handleSearch"
					></f-search>
				</f-div>
				<f-div width="200px">
					<f-select
						v-model="selectedFilterRole"
						:data-qa="`selected-app-${selectedFilterRole}`"
						placeholder="All Roles"
						size="small"
						:options="filterRoles"
					>
					</f-select>
				</f-div>
			</f-div>
			<f-div gap="small" align="middle-right" width="hug-content">
				<f-button
					id="popover-dropdown"
					data-qa="invite-new-member"
					icon-left="i-plus"
					label="invite new"
					size="small"
					:disabled="true"
					category="outline"
					variant="round"
				/>
			</f-div>
		</f-div>

		<f-div padding="medium">
			<f-table-schema
				variant="underlined"
				selectable="none"
				:data="tableData"
				:search-term="searchText"
				:show-search-bar="false"
				:highlight-hover="false"
				:highlight-column-hover="false"
				sticky-cell-background="secondary"
				sticky-header
				size="small"
			>
			</f-table-schema>
		</f-div>
	</f-div>

	<AssignRoles
		v-if="openRolesListPopover && selectedUser"
		:open-popover="openRolesListPopover"
		:tagged-list-id="`assign-role-${selectedUser.id}`"
		:selected-user="selectedUser"
		@close="closeViewMorePopup"
	/>
</template>
<script lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData } from "@ollion/flow-table";
import { PropType, defineComponent } from "vue";

import AssignRoles from "@/modules/manage-members/components/AssignRoles.vue";
import {
	RoleTypesList,
	RoleTypes,
	isAdminRole,
	EligibleRolesForAdmin
} from "@/modules/user/user-types";
import { User } from "@/protocol/users";
import { formatDateString } from "@/utils/format-date";

export default defineComponent({
	name: "MembersList",

	components: {
		AssignRoles
	},

	props: {
		users: {
			type: Array as PropType<User[]>,
			required: true
		}
	},

	data: () => {
		return {
			searchText: "",
			openRolesListPopover: false,
			selectedUser: {} as User | null,
			selectedFilterRole: null as string | null,

			filterRoles: ["All Roles", "Admin", ...RoleTypesList] as RoleTypes[]
		};
	},

	computed: {
		filteredUsers() {
			let filteredUsersList = this.users;
			if (this.selectedFilterRole !== null && this.selectedFilterRole !== "All Roles") {
				filteredUsersList = filteredUsersList.filter(user => {
					if (this.selectedFilterRole === "Admin") {
						return EligibleRolesForAdmin.every(element => user.role?.includes(element));
					} else {
						return (
							!isAdminRole(user.role) && user.role?.includes(this.selectedFilterRole as string)
						);
					}
				});
			}
			return filteredUsersList.sort((a: User, b: User) => {
				return new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime();
			});
		},

		tableData() {
			const result: FTableSchemaData = {
				header: {
					name: {
						value: "User",
						width: "auto",
						align: "middle-center"
					},

					email: {
						value: "Email",
						width: "auto",
						align: "middle-left"
					},

					roles: {
						value: "Role",
						width: "30%",
						align: "middle-left"
					},

					added: {
						value: "Added",
						width: "auto",
						align: "middle-center"
					}
				},

				rows: this.filteredUsers.map(user => {
					const fullName = `${user.firstName} ${user.lastName}`;
					const fullNameHtml = html`<f-div padding="x-small none none none"
						><f-text
							variant="para"
							size="small"
							weight="medium"
							align="left"
							.highlight="${this.searchText}"
							>${fullName.trim() ? fullName : "NA"}
						</f-text></f-div
					>`;

					return {
						id: user.id!,
						selected: false,
						data: {
							name: {
								value: `${fullName}`,
								align: "middle-left",
								template: () => {
									return fullName.trim()
										? html`<f-div gap="small" align="middle-left">
												<f-pictogram
													size="small"
													variant="circle"
													state="primary"
													auto-bg="true"
													source="${fullName}"
												>
												</f-pictogram>
												${fullNameHtml}
											</f-div>`
										: fullNameHtml;
								}
							},
							email: {
								value: user.email
							},
							roles: {
								value: user.role,
								align: "middle-left",
								template: () => {
									const hasUserRole = user.role && user.role.length > 0;

									let userRoles = [];

									if (hasUserRole && isAdminRole(user.role)) {
										userRoles = [
											html`
												<f-tag
													clickable
													category="outline"
													size="small"
													state="success"
													label="Admin"
												>
												</f-tag>
											`,
											html` <f-icon
												class="show-on-hover"
												size="x-small"
												source="i-edit"
												style="visibility:var(--hide-icon);"
												clickable
												@click="${() => {
													this.setOpenUserRoleListPopover(user);
												}}"
											></f-icon>`
										];
									} else if (hasUserRole) {
										userRoles = [
											...(user.role ?? []).map(role => {
												return html`
													<f-tag
														clickable
														category="outline"
														size="small"
														state="neutral"
														label="${role}"
													>
													</f-tag>
												`;
											}),
											html` <f-icon
												class="show-on-hover"
												size="x-small"
												source="i-edit"
												style="visibility:var(--hide-icon);"
												clickable
												@click="${() => {
													this.setOpenUserRoleListPopover(user);
												}}"
											></f-icon>`
										];
									} else {
										userRoles = [
											html`<f-tag
												clickable
												category="outline"
												size="small"
												state="primary"
												label="Add new role"
												@click="${() => {
													this.setOpenUserRoleListPopover(user);
												}}"
											>
											</f-tag>`
										];
									}

									return html`
										<f-div
											width="100%"
											align="middle-left"
											gap="small"
											class="show-on-hover-parent"
											id="assign-role-${user.id}"
											height="hug-content"
											overflow="wrap"
										>
											${userRoles}
										</f-div>
									`;
								}
							},
							added: {
								value: user.createdAt ? formatDateString(user.createdAt) : "NA"
							}
						}
					};
				})
			};
			return result;
		}
	},

	methods: {
		closeViewMorePopup() {
			this.openRolesListPopover = false;
			this.selectedUser = null;
		},

		setOpenUserRoleListPopover(user: User) {
			if (this.openRolesListPopover) {
				this.closeViewMorePopup();
				return;
			}
			this.openRolesListPopover = true;
			this.selectedUser = user;
		},

		handleSearch(e: CustomEvent) {
			const { value } = e.detail;
			this.searchText = value ?? "";
		}
	}
});
</script>
