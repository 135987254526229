<template>
	<f-div direction="column" overflow="hidden">
		<f-div height="hug-content" padding="medium" border="small solid secondary bottom">
			<f-text variant="para" size="medium" weight="bold" align="left">Evidence</f-text>
			<f-icon
				source="i-close"
				size="small"
				variant="round"
				state="secondary"
				@click="onClose()"
			></f-icon>
		</f-div>

		<f-div
			v-if="selectedStatements.length > 1"
			height="hug-content"
			state="warning"
			padding="small"
			align="middle-center"
			gap="small"
		>
			<f-icon source="i-alert" state="inherit" size="small"></f-icon>
			<f-text state="default" size="small">Adding evidence for multiple statements</f-text>
		</f-div>
		<f-div
			v-if="errorMessage"
			height="hug-content"
			state="danger"
			padding="small"
			align="middle-center"
			gap="small"
		>
			<f-icon source="i-alert" state="inherit" size="small"></f-icon>
			<f-text state="default" size="small">{{ errorMessage }}</f-text>
		</f-div>

		<f-div
			padding="large"
			gap="small"
			direction="column"
			height="fill-container"
			width="fill-container"
			overflow="scroll"
		>
			<f-form-builder
				ref="formBuilder"
				gap="medium"
				:field.prop="evidenceFormFields"
				:values.prop="formValues"
				@input="handleInput"
				@state-change="formState = $event.detail"
			/>
		</f-div>
		<f-div padding="small" align="bottom-center" height="hug-content">
			<f-button
				variant="block"
				state="success"
				:loading="isSubmitting"
				category="outline"
				:label="buttonLabel"
				:disabled="!formState?.isValid"
				@click="submitEvidence"
			></f-button>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { SelectedStatement } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { Document } from "@/protocol/correlation";
import { captureError, getErrorMessage } from "@/utils";

export default defineComponent({
	name: "OnboardingUploadEvidence",

	props: {
		// eslint-disable-next-line vue/no-unused-properties
		documents: {
			type: Array as PropType<Document[]>,
			required: true
		},

		// eslint-disable-next-line vue/no-unused-properties
		selectedStatements: {
			type: Array as PropType<SelectedStatement[]>,
			required: true
		},

		// eslint-disable-next-line vue/no-unused-properties
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["closeEvidenceModal"],

	data: () => ({
		formValues: {
			evidence: "",
			evidence_name: "",
			upload_evidence: null
		},

		fileType: "",
		errorMessage: "",
		fileContent: null as null | File,
		formState: null as FormBuilderState | null,
		isSubmitting: false
	}),

	computed: {
		...mapStores(componentOnboardStore, notificationsStore),

		buttonLabel() {
			if (this.selectedStatements.length >= 1) {
				return `Resolve ${this.selectedStatements.length} statement${this.selectedStatements.length > 1 ? "s" : ""}`;
			}
			return "Resolve statement";
		},

		evidenceFormFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					upload_evidence: {
						id: "upload_evidence",
						qaId: "uploadFilesQA",
						label: {
							title: "Files to upload",
							subTitle: "Select any file from native os file browser"
						},

						placeholder: "Select file",
						type: "file",
						multiple: false,
						fileType: ".png, .jpeg, .pdf, .docx, .rtf, .txt, .jpg",

						validationRules: [{ name: "required" }]
					},

					evidence_name: {
						id: "evidence_name",
						qaId: "evidenceNameQA",
						type: "text",
						label: {
							title: "Evidence Name"
						},

						placeholder: "Evidence name",
						validationRules: [{ name: "required" }]
					},

					evidence: {
						id: "evidence",
						type: "textarea",
						label: {
							title: "Justification/explanation"
						},

						placeholder: "Justification/explanation goes here..."
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent) {
			this.formValues = event.detail;
			this.fileType = event.detail.upload_evidence?.type;
			const file = event.detail.upload_evidence as File | undefined;

			if (file) {
				this.fileContent = file;
				if (!this.formValues.evidence_name) {
					this.formValues.evidence_name = file.name;
				}
			} else {
				this.fileContent = null;
				this.formValues.evidence_name = "";
			}
		},

		onClose() {
			this.$emit("closeEvidenceModal");
		},

		async submitEvidence() {
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			if (this.formState?.isValid === false) {
				return;
			}

			this.isSubmitting = true;
			this.errorMessage = "";

			try {
				const statementIds = this.selectedStatements.map(statement => statement.statementId);

				const expiresAt = new Date(); // Get the current date
				expiresAt.setFullYear(expiresAt.getFullYear() + 1); // Add one year to the current date

				const evidencePayload = {
					evidenceData: {
						name: this.formValues.evidence_name,
						fileType: this.fileType,
						componentId: this.component.id,
						evidence: this.formValues.evidence,
						createdAt: new Date().getTime(),
						expiresAt: expiresAt.getTime()
					},

					evidenceFile: this.fileContent as File,

					statementRef: this.selectedStatements.map(statement => {
						return {
							statementId: statement.statementId,
							documentId: statement.documentId
						};
					}),

					type: "auto"
				};

				const formData = new FormData();

				formData.append("evidenceData", JSON.stringify(evidencePayload.evidenceData));
				formData.append("evidenceFile", evidencePayload.evidenceFile);
				formData.append("statementRef", JSON.stringify(evidencePayload.statementRef));

				await this.componentOnboardStore.createEvidence({
					payload: formData,
					componentId: this.component.id
				});

				this.$emit(`closeEvidenceModal`, { isSubmit: true });
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-submit-evidence",
					title: "Success",
					text: `Evidence added for the selected statement${statementIds.length > 1 ? "s" : ""}.`,
					status: "success"
				});
			} catch (error) {
				this.errorMessage = getErrorMessage(error);
				captureError(error);
			} finally {
				this.isSubmitting = false;
			}
		}
	}
});
</script>
