<template>
	<f-div direction="column" overflow="visible" border="small" height="100%">
		<StatusHeader />
		<DetailsHeader
			:is-control-selected="controlDetailSelected"
			@update-control-selected-status="isControlSelected"
		/>
		<ControlComponentList
			:is-control-selected="controlDetailSelected"
			@update-control-selected-status="isControlSelected"
		/>
	</f-div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ControlComponentList from "./ControlComponentList.vue";
import DetailsHeader from "./DetailsHeader.vue";
import StatusHeader from "./StatusHeader.vue";

export default defineComponent({
	name: "PanelDetails",

	components: {
		StatusHeader,
		DetailsHeader,
		ControlComponentList
	},

	data() {
		return {
			controlDetailSelected: false
		};
	},

	methods: {
		isControlSelected(status: boolean) {
			this.controlDetailSelected = status;
		}
	}
});
</script>
