import axios from "axios";

import { captureError, getEnvironment } from ".";

let hasLoggedVersion = false;

export function getAppVersion() {
	const appVersion = `stance@${import.meta.env.VITE_GIT_LENGTH}.${
		import.meta.env.VITE_GIT_HEAD
	}-${getEnvironment()}`;

	if (!hasLoggedVersion && process.env.NODE_ENV !== "test") {
		hasLoggedVersion = true;
		// eslint-disable-next-line no-console
		console.log(
			`%cStance version:%c${appVersion}`,
			"background:#161616;color:white;padding:4px 6px 4px 6px;border-radius:4px 0px 0px 4px",
			"background:purple;color:white;padding:4px 6px 4px 6px;border-radius:0px 4px 4px 0px;"
		);
	}

	return appVersion;
}

// Takes input like stance@995.25c2c7cd-DEVELOPMENT and returns995
export function extractNumericVersion(fullVersion: string) {
	const match = fullVersion.match(/@([0-9]+)/)?.[1];
	return match ? Number(match) : -1;
}

export async function fetchLatestVersion() {
	try {
		// cb to bust the cache and prevent the same file from being loaded
		const versionResponse = await axios.get<{ version: string }>(
			`ui/version.json?cb=${Date.now()}`,
			{
				baseURL: window.location.origin
			}
		);

		return versionResponse.data.version;
	} catch (err) {
		// Fail silently and log error
		captureError(err);
		return null;
	}
}
