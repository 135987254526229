<template>
	<f-div width="100%">
		<f-div
			direction="column"
			:width="showSidePanel ? '100%' : '70%'"
			padding="small none none small"
		>
			<f-div padding="small none large small">
				<f-select
					v-model="selectedApp"
					:data-qa="`selected-app-${selectedApp?.title ?? ''}`"
					placeholder="Select application"
					size="medium"
					:options="appOptions"
				>
				</f-select>
			</f-div>
			<!-- Selected Apps details-->
			<f-div padding="large none none small">
				<f-tab class="margin-lt-0 margin-rt-20 margin-bt-20">
					<f-tab-node
						v-for="tab of tabNodes"
						:key="tab.id"
						:data-qa-tab="tab.name"
						:active="selected === tab.id"
						:content-id="String(tab.id)"
						@click="selected = tab.id"
						><f-div width="100%" height="100%" align="middle-center" direction="column">
							<f-div align="middle-center" height="hug-content" width="hug-content">
								<f-div padding="none" gap="small" align="middle-left">{{ tab.name }} </f-div>
							</f-div>
						</f-div>
					</f-tab-node>
				</f-tab>
				<f-tab-content>
					<f-div gap="x-large">
						<f-div direction="row" width="100%" padding="large" gap="x-large">
							<TabsCardComponents v-if="selectedApp" :selected-app="selectedApp" />
						</f-div>

						<f-div gap="small">
							<TechnologyCardComponents
								v-if="selectedApp && selectedApp.id !== ''"
								:key="selectedApp.id"
								:application-id="selectedApp.id"
								:app-options="appOptions"
							>
							</TechnologyCardComponents>
							<f-div v-else padding="x-large">
								<EmptyState
									message="No Component Available"
									subtitle="Please select application"
									shape="circle"
								>
								</EmptyState>
							</f-div>
						</f-div>
					</f-div>
				</f-tab-content>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores, mapState } from "pinia";
import { defineComponent } from "vue";

import { captureError } from "@/utils";

import { getApplications } from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import { ApplicationOptions, TabNodesObj } from "../compliance-report-utils";

import TabsCardComponents from "./TabsCardComponents.vue";
import TechnologyCardComponents from "./TechnologyCardComponents.vue";

export default defineComponent({
	name: "ApplicationsTab",

	components: {
		EmptyState,
		TechnologyCardComponents,
		TabsCardComponents
	},

	emits: ["open-side-panel"],

	data: () => {
		return {
			selectedApp: null as ApplicationOptions | null,

			appOptions: [] as ApplicationOptions[],

			selected: 0,
			tabNodes: [{ name: "Technology controls", id: 0 }] as TabNodesObj[]
		};
	},

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			showSidePanel: "showSidePanel"
		})
	},

	watch: {
		selectedApp: {
			handler(app) {
				if (app?.id) {
					this.complianceReportStore.setSelectedApp(app);
				}
			},

			deep: true,
			immediate: true
		}
	},

	mounted() {
		this.getApplicationsList();
	},

	methods: {
		async getApplicationsList() {
			try {
				const resp = await getApplications({});

				const applications = resp.applications ?? [];
				if (!applications.length) {
					return;
				}

				const [firstApp] = applications;
				this.appOptions = applications.map(item => ({ id: item.id ?? "", title: item.name ?? "" }));

				if (firstApp?.id && firstApp.name) {
					this.selectedApp = { id: firstApp.id, title: firstApp.name };
				}
			} catch (error) {
				captureError(error);
				this.appOptions = [];
			}
		}
	}
});
</script>
