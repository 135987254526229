<template>
	<f-div direction="column" gap="medium" height="fill-container" overflow="scroll">
		<f-div width="100%" class="f-three-columns">
			<!--Start : middle column-->
			<f-div class="f-middle-column" padding="large small large none">
				<f-div direction="column">
					<f-div
						v-for="item in classificationsList"
						:key="item.type"
						border="small dashed secondary bottom"
						padding="large"
						gap="small"
						align="top-left"
						height="hug-content"
					>
						<f-div gap="large" align="middle-left">
							<f-div align="middle-left" width="20%">
								<f-text
									inline
									variant="para"
									size="medium"
									weight="regular"
									state="secondary"
									align="left"
								>
									{{ item.name }}
								</f-text>
							</f-div>
							<f-div align="middle-left" padding="x-small none none none" gap="medium">
								<f-text
									class="capital-heading"
									variant="para"
									size="medium"
									weight="medium"
									state="default"
									align="left"
								>
									{{ item.value }}
								</f-text>

								<f-button
									v-if="item.type === 'name-version' && isComponentNotarized"
									label="Best Practice Report"
									category="outline"
									size="small"
									icon-left="i-printer"
									@click="openBestPracticeReport"
								></f-button>

								<!--START: View on map -->
								<!-- Constellation map linking feature is broken right now -->
								<!-- <f-div clickable gap="medium" width="hug-content" @click="openConstellationMap">
									<f-icon
										v-if="item.type === 'name-version'"
										source="i-tree"
										size="small"
										state="primary"
									></f-icon>
									<f-text
										v-if="item.type === 'name-version'"
										variant="para"
										size="small"
										weight="regular"
										state="primary"
										align="left"
									>
										View on constellation map
									</f-text>
								</f-div> -->
								<!--END: View on map-->
							</f-div>
						</f-div>
					</f-div>
					<f-div v-if="terraformSnippet" padding="medium" direction="column" variant="curved">
						<f-div state="secondary" height="hug-content" padding="medium" width="fill-container">
							<f-div width="fill-container" align="middle-left">
								<f-text variant="heading" weight="medium" align="left" size="medium"
									>Terraform module</f-text
								>
							</f-div>
							<f-div align="middle-right" width="hug-content" height="hug-content">
								<!-- <f-button
									label="View on Git"
									size="x-small"
									icon-left="i-link"
									category="transparent"
									@click="viewOnGit"
								></f-button> -->
								<f-button
									label="COPY CODE"
									size="x-small"
									icon-left="i-copy"
									variant="block"
									@click="copySnippet"
								></f-button>
							</f-div>
							<!-- -->
						</f-div>
						<f-div state="tertiary" height="hug-content" variant="curved">
							<f-log :logs="terraformSnippet" size> </f-log>
						</f-div>
					</f-div>
				</f-div>
			</f-div>
			<!--End : middle column-->
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { getTaxonomiesList } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_STATUS } from "@/shared/constants";

// import { duplicateComponentDeeplinking } from "@/utils/component-deeplinking";

export default defineComponent({
	name: "ComponentOnboardingDetailTab",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	computed: {
		classificationsList() {
			const classificationLists: { type: string; name: string; value: string }[] = [
				{
					type: "name-version",
					name: "Name and version",
					value: `${this.component.name}  ${this.component.version}`
				},
				{
					type: "classification",
					name: "Classification",
					value: getTaxonomiesList(this.component)
						.filter(taxonomy => taxonomy)
						.join(" / ")
				},
				{
					type: "justification",
					name: "Justification",
					value: String(this.component.metadata?.justification ?? "")
				},
				{
					type: "description",
					name: "Description",
					value: String(this.component.metadata?.description ?? "")
				}
			];

			if (this.component.allowedEnvironment && this.isComponentNotarized) {
				classificationLists.push({
					type: "allowed-environment",
					name: "Environments",
					value: `Rated till ${this.component.allowedEnvironment[this.component.allowedEnvironment.length - 1]} environment`
				});
			}

			return classificationLists;
		},

		isComponentNotarized() {
			const { status } = this.component;
			return (
				status === COMPONENT_STATUS.notarised ||
				status === COMPONENT_STATUS.published ||
				status === COMPONENT_STATUS.deprecated ||
				status === COMPONENT_STATUS.deprecationPending
			);
		},

		terraformSnippet() {
			const gitUrl = this.component.artifact?.metadata?.terraformGitRepo?.iac?.repo ?? "";
			const snippet = generateModuleSnippet(gitUrl);

			return snippet;
		}
	},

	methods: {
		openBestPracticeReport() {
			const { location } = window;
			window.open(
				`${location.origin}/ui/component-best-practice-report/${this.component.id}`,
				"_blank"
			);
		},

		copySnippet() {
			const el = document.createElement("textarea");
			el.value = this.terraformSnippet;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		},

		// eslint-disable-next-line vue/no-unused-properties
		viewOnGit() {
			const gitUrl = this.component.artifact?.metadata?.terraformGitRepo?.iac?.repo ?? "";
			window.open(gitUrl, "_blank");
		}

		// openConstellationMap() {
		// 	const queryParams = duplicateComponentDeeplinking(this.component);
		// 	this.$router.push({ name: "constellation-map", query: queryParams });
		// }
	}
});

const generateModuleSnippet = (url: string): string => {
	if (!url) {
		return "";
	}
	// eslint-disable-next-line no-useless-escape
	const regex = /https:\/\/github\.com\/([^\/]+)\/([^\/]+)/;
	const match = url.match(regex);

	if (!match) {
		return "";
	}
	const repoOwner = match[1];
	const repoName = match[2];
	const moduleName = repoName?.replace(/-/g, "_");
	const sourceUrl = `git::https://github.com/${repoOwner}/${repoName}.git`;

	return `module "${moduleName}" {
  source = "${sourceUrl}"
}`;
};
</script>
