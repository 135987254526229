<template>
	<f-div
		height="hug-content"
		width="fill-container"
		state="subtle"
		padding="medium"
		align="middle-left"
		gap="medium"
		flex-wrap
	>
		<ComponentIcon :component="component" size="x-large"></ComponentIcon>
		<f-div direction="column" gap="x-small" width="hug-content">
			<f-div align="middle-center" width="hug-content" gap="medium" height="hug-content">
				<f-text
					variant="heading"
					weight="bold"
					size="medium"
					ellipsis
					:tooltip="`${componentNameAndVersion}`"
					>{{ componentNameAndVersion }}
				</f-text>
				<ComponentStatusBadge :component="component" />
			</f-div>

			<f-text
				variant="para"
				weight="regular"
				size="small"
				state="subtle"
				ellipsis
				:tooltip="componentTaxonomies.tooltip"
			>
				{{ componentTaxonomies.text }}
			</f-text>
		</f-div>
		<f-divider state="secondary"></f-divider>
		<f-div width="hug-content" align="middle-right" style="min-width: 100px">
			<ComponentVersionSelect
				:component="component"
				:selected-component="component"
				:show-version-label="true"
				@on-version-select="goToComponent"
			/>
		</f-div>

		<f-div align="middle-right" gap="small">
			<ComponentLifeCycleStepper v-if="!isComponentPublished" :component="component" />
			<f-icon-button
				:id="`view-more-${component.id}`"
				data-qa="data-qa-view-more-detail-page"
				icon="i-more"
				category="outline"
				size="small"
				state="neutral"
				@click.stop="openComponentListPopover = !openComponentListPopover"
			></f-icon-button>
		</f-div>
		<ViewMoreList
			v-if="openComponentListPopover"
			:open-popover="openComponentListPopover"
			:tagged-list-id="`view-more-${component.id}`"
			:selected-component="component"
			@open-action-popover="openComponentListPopover = false"
			@close="openComponentListPopover = false"
		/>
	</f-div>

	<f-divider state="secondary" />

	<!-- Notification start -->
	<f-div
		v-if="notificationState"
		padding="small"
		gap="auto"
		:state="notificationState.bgState"
		align="middle-left"
		height="hug-content"
	>
		<f-div gap="medium" align="middle-left">
			<f-icon :source="notificationState.icon" size="small" state="inherit"></f-icon>
			<f-text variant="para" size="medium" weight="regular" state="default">{{
				notificationState.message
			}}</f-text></f-div
		>
	</f-div>
	<!-- Notification end -->
</template>

<script lang="ts">
import { FDivStateProp } from "@ollion/flow-core";
import { defineComponent, PropType } from "vue";

import ComponentStatusBadge from "@/modules/release-cockpit-v2/components/catalog-component-list/ComponentStatusBadge.vue";
import ViewMoreList from "@/modules/release-cockpit-v2/components/catalog-component-list/ViewMoreList.vue";
import ComponentLifeCycleStepper from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentLifeCycleStepper.vue";
import { getTaxonomiesList } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import { COMPONENT_STATUS, ComponentStatus } from "@/shared/constants";
import { capitalize } from "@/utils";

import ComponentIcon from "../catalog-component-list/ComponentIcon.vue";
import ComponentVersionSelect from "../catalog-component-list/ComponentVersionSelect.vue";

export default defineComponent({
	name: "ComponentCatalogHeader",

	components: {
		ComponentLifeCycleStepper,
		ComponentVersionSelect,
		ComponentStatusBadge,
		ComponentIcon,
		ViewMoreList
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data() {
		return {
			openComponentListPopover: false
		};
	},

	computed: {
		componentNameAndVersion() {
			return `${this.component.name} ${this.component.version}`;
		},

		isComponentPublished() {
			const publishedStatuses: ComponentStatus[] = [
				COMPONENT_STATUS.published,
				COMPONENT_STATUS.deprecated,
				COMPONENT_STATUS.deprecationPending
			];
			return publishedStatuses.includes(this.component.status as ComponentStatus);
		},

		componentTaxonomies() {
			const tooltip = getTaxonomiesList(this.component)
				.filter(entry => entry.trim() !== "")
				.join(" / ");

			if (!this.component.classification1 && !this.component.classification6) {
				return {
					tooltip: "Classification pending",
					text: "Classification pending"
				};
			}
			return {
				tooltip,
				text: `${capitalize(this.component.classification1 ?? "")} / ${capitalize(this.component.classification6 ?? "")}`
			};
		},

		notificationState() {
			if (this.component.nonCompliant) {
				return {
					message: `This component is non-compliant due to a change in the best practices. Please review and re-release the component.`,
					icon: "i-alert",
					bgState: "warning" as FDivStateProp
				};
			}

			return null;
		}
	},

	methods: {
		goToComponent(selectedComponent: Component) {
			const componentStatus = selectedComponent.status as ComponentStatus;
			switch (componentStatus) {
				case COMPONENT_STATUS.classificationApprovalPending:
					this.$router.push({
						name: "component-classification-approval",
						params: { componentId: selectedComponent.id }
					});
					break;
				case COMPONENT_STATUS.classificationApprovalRejected:
				case COMPONENT_STATUS.draft:
					this.$router.push({
						name: "component-classification-approval",
						params: { componentId: selectedComponent.id }
					});
					break;
				default:
					this.$router.push({
						name: "component-onboarding-view",
						params: { componentId: selectedComponent.id }
					});
					break;
			}
		}
	}
});
</script>
