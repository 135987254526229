<template>
	<DeprecateRequestIndicator :component="component"> </DeprecateRequestIndicator>
	<f-div padding="none" height="hug-content" border="small solid subtle bottom">
		<f-div
			v-if="isComponentOnboarding"
			gap="medium"
			align="middle-left"
			width="hug-content"
			padding="small"
		>
			<f-text weight="bold" size="medium">Onboarding</f-text>
			<f-icon source="i-info-fill" tooltip="Onboarding component" state="subtle" />
			<f-divider></f-divider>
		</f-div>

		<f-div padding="none" width="hug-content">
			<f-tab variant="no-border">
				<f-tab-node
					v-for="tab in tabs"
					:key="tab.id"
					:active="tab.id === activeTabId"
					:content-id="`tab-${tab.id}`"
					:data-qa="`tab-${tab.id}`"
					@click="onTabClick(tab)"
				>
					<f-div align="middle-center" gap="small" width="hug-content" height="20px">
						<f-text
							variant="para"
							size="small"
							:weight="tab.id === activeTabId ? 'bold' : 'regular'"
						>
							{{ tab.name }}
						</f-text>
						<f-progress-bar
							v-if="tab.name === 'Manual'"
							variant="circle"
							size="x-small"
							state="primary"
							:value="progressPercentage.manual"
						/>
						<f-progress-bar
							v-if="tab.name === 'Automated'"
							variant="circle"
							size="x-small"
							state="primary"
							:value="progressPercentage.automated"
						/>
					</f-div>
				</f-tab-node>
			</f-tab>
		</f-div>

		<f-div
			v-if="hasWriteAccess && !isComponentOfAssetType"
			align="middle-right"
			gap="medium"
			padding="small"
		>
			<f-text
				v-if="totalStatements > 0 && !isComponentNotarized"
				variant="para"
				weight="regular"
				state="subtle"
				align="right"
				size="small"
				:loading="isLoading"
			>
				Complete: {{ statementsWithEvidences }}/{{ totalStatements }}
			</f-text>

			<template
				v-if="
					isServiceComponent &&
					ratedEnvironments &&
					eligibleEnvironmentsCount > 0 &&
					isComponentNotarizable
				"
			>
				<f-divider></f-divider>

				<HighlightBorder :is-muted="eligibleEnvironmentsCount < ratedEnvironmentsCount">
					<f-div width="hug-content" height="hug-content" state="default" padding="x-small small">
						<f-text size="small">Eligible for release on {{ eligibleEnvironmentName }}</f-text>
					</f-div>
				</HighlightBorder>

				<f-divider></f-divider>
			</template>

			<f-button
				:label="notariseBtnState.label"
				variant="round"
				size="small"
				:icon-left="notariseBtnState.leftIcon"
				:loading="notariseBtnState.loading"
				:disabled="notariseBtnState.disabled"
				@click="notarise"
			></f-button>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { featureFlagStore } from "@/modules/feature-flags/feature-flags-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import DeprecateRequestIndicator from "@/modules/release-cockpit-v2/components/catalog-component-list/DeprecateRequestIndicator.vue";
import {
	ComponentOnboardingTab,
	ProgressBar
} from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component, ComponentType } from "@/protocol/cockpit";
import HighlightBorder from "@/shared/components/HighlightBorder.vue";
import { COMPONENT_STATUS } from "@/shared/constants";
import { captureError } from "@/utils";

import { componentCatalogStore } from "../../component-catalog-store";

export default defineComponent({
	name: "ComponentOnboardingTabs",
	components: { DeprecateRequestIndicator, HighlightBorder },

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		tabs: {
			type: Array as PropType<ComponentOnboardingTab[]>,
			required: true
		},

		activeTabId: {
			type: String,
			required: true
		}
	},

	data: () => {
		return {
			COMPONENT_STATUS,
			isNotarizing: false
		};
	},

	computed: {
		...mapStores(
			componentOnboardStore,
			featureFlagStore,
			componentCatalogStore,
			notificationsStore,
			authStore
		),

		isLoading() {
			return this.componentOnboardStore.loadingComponent[this.component.id ?? ""];
		},

		statements() {
			return this.componentOnboardStore.getEligibleControls(this.component.id);
		},

		totalStatements() {
			return this.statements.length;
		},

		statementsWithEvidences() {
			const evidences = this.componentOnboardStore.evidences[this.component.id ?? ""] ?? {};

			const allEvidenceMappings = Object.values(evidences).flatMap(evidence =>
				Object.values(evidence)
			);

			return allEvidenceMappings.filter(statementEvidences => statementEvidences.length > 0).length;
		},

		progressPercentage(): Record<"manual" | "automated", ProgressBar> {
			const automatedPercentage = this.componentOnboardStore.getAutomatedStatementsProgress(
				this.component.id
			);
			const manualPercentage = this.componentOnboardStore.getManualStatementsProgress(
				this.component.id
			);
			return {
				manual: `${manualPercentage}%`,
				automated: `${automatedPercentage}%`
			};
		},

		ratedEnvironments() {
			return this.componentOnboardStore.getRatedEnvironment(this.component.id);
		},

		eligibleEnvironmentsCount() {
			return this.ratedEnvironments.eligibleEnvironments?.length ?? 0;
		},

		eligibleEnvironmentName() {
			return this.ratedEnvironments.eligibleEnvironments?.[0]?.name ?? "";
		},

		ratedEnvironmentsCount() {
			return this.ratedEnvironments.environments?.length ?? 0;
		},

		isComponentNotarized() {
			const { status } = this.component;
			return (
				status === this.COMPONENT_STATUS.notarised ||
				status === this.COMPONENT_STATUS.published ||
				status === this.COMPONENT_STATUS.deprecated ||
				status === this.COMPONENT_STATUS.deprecationPending
			);
		},

		isServiceComponent() {
			return this.component.type === ComponentType.SERVICE;
		},

		isSoftwareComponent() {
			return this.component.type === ComponentType.SOFTWARE;
		},

		isComponentOfAssetType() {
			return this.componentType === ComponentType.ASSET;
		},

		isOnboardingComplete() {
			return this.component.status === COMPONENT_STATUS.onboardingComplete;
		},

		isComponentNotarizable() {
			if (this.isComponentNotarized) {
				return false;
			}

			if (this.isServiceComponent) {
				/**
				 * If the component is a service component, then we check if the component has any statements
				 * and if the component has any eligible environments to be notarized
				 * we check if each statement has at least one evidence mapped to its ratedEnvironments.
				 */
				const { eligibleEnvironments } = this.ratedEnvironments;

				const hasEligibleEnvironments = (eligibleEnvironments?.length ?? 0) > 0;

				return this.totalStatements > 0 && hasEligibleEnvironments;
			} else if (this.isSoftwareComponent) {
				/**
				 * If the component is a software component, then we check if the component has any statements
				 * and if the status of the component is onboardingComplete
				 */
				return this.totalStatements > 0 && this.isOnboardingComplete;
			}

			// for asset type component we don't render the notarize button at all
			// so we return false here
			return false;
		},

		hasWriteAccess() {
			return this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled;
		},

		notariseBtnState() {
			if (this.isComponentNotarized) {
				return {
					loading: false,
					disabled: true,
					leftIcon: "i-tick-fill",
					label: "Notarized"
				};
			}

			if (this.isComponentNotarizable) {
				return {
					loading: this.isNotarizing,
					disabled: false,
					leftIcon: undefined,
					label: this.isNotarizing ? "Notarizing..." : "Notarize"
				};
			}

			return {
				loading: false,
				disabled: true,
				leftIcon: undefined,
				label: "Notarize"
			};
		},

		componentType() {
			return this.component.type;
		},

		isComponentOnboarding() {
			return (
				this.component.status === COMPONENT_STATUS.artifactRequired ||
				this.component.status === COMPONENT_STATUS.evidenceApprovalPending ||
				this.component.status === COMPONENT_STATUS.evidencePartiallyApproved ||
				this.component.status === COMPONENT_STATUS.onboardingComplete ||
				this.component.status === COMPONENT_STATUS.notarised
			);
		}
	},

	methods: {
		onTabClick(tab: ComponentOnboardingTab) {
			this.$router.replace({
				name: "component-onboarding-view",
				params: {
					componentId: this.component.id,
					tabName: tab.id
				}
			});
		},

		async markWorkflowCompleted() {
			try {
				await this.componentOnboardStore.updateWorkflow(this.component.id);
			} catch (error) {
				captureError(error);
			}
		},

		async notarise() {
			try {
				this.isNotarizing = true;
				await this.markWorkflowCompleted();
				await this.componentOnboardStore.componentNotarise(this.component.id);
			} catch (error) {
				captureError(error);
				this.notificationsStore.ADD_TOAST({
					qaId: "notarize-error",
					title: "Notarization failed",
					text: error === "string" ? error : "Notarization failed, please try again",
					status: "error"
				});
			} finally {
				this.isNotarizing = false;
			}
		}
	}
});
</script>
