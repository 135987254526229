export interface Classification {
	ID: string;
	L1: string;
	L2: string;
	L3: string;
	L4: string;
	L5: string;
	L6: string;
	L7: string;
}

export const Header = ["ID", "L1", "L2", "L3", "L4", "L5", "L6", "L7"];

export const DOMAIN_HEADERS = ["ID", "L1", "L2"] as const;
export const DOMAIN_ICONS = ["i-caret-down", "i-globe", "i-cloud"] as const;
export const TBM_HEADERS = ["ID", "L3", "L4", "L5"] as const;
export const TBM_ICONS = ["i-caret-down", "i-arrow-split", "i-app", "i-webhook"] as const;
export const CLASS_HEADERS = ["ID", "L6", "L7", "L8"] as const;
export const CLASS_ICONS = ["i-caret-down", "i-pipe", "i-template", "i-puzzle"] as const;

export type TAXONOMY_HEADERS = typeof DOMAIN_HEADERS | typeof TBM_HEADERS | typeof CLASS_HEADERS;
export type TAXONOMY_COLUMN_ICONS = typeof DOMAIN_ICONS | typeof TBM_ICONS | typeof CLASS_ICONS;

export interface TaxonomyData {
	ID: string;
	taxonomy_1: string;
	taxonomy_2: string;
	taxonomy_3: string;
	taxonomy_4: string;
	taxonomy_5: string;
	taxonomy_6: string;
	taxonomy_7: string;
}

export type DOMAIN_ITEM = {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	taxonomy1: string;
	taxonomy2: string;
};

export type TBM_ITEM = {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	taxonomy3: string;
	taxonomy4: string;
	taxonomy5: string;
};

export type CLASS_ITEM = {
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	taxonomy6: string;
	taxonomy7: string;
	taxonomy8: string;
};

// Define a base type for the taxonomy properties
export type TAXONOMY_TYPE = {
	[key: string]: string;
};

// Define types for each request body
export type DOMAIN_REQUEST_BODY = TAXONOMY_TYPE & {
	taxonomy_1: string;
	taxonomy_2: string;
};

export type TBM_REQUEST_BODY = TAXONOMY_TYPE & {
	taxonomy_3: string;
	taxonomy_4: string;
	taxonomy_5: string;
};

export type CLASS_REQUEST_BODY = TAXONOMY_TYPE & {
	taxonomy_6: string;
	taxonomy_7: string;
	taxonomy_8: string;
};

export type UPLOAD_TAXONOMY_SHEET_RESPONSE = {
	domain_rows: number;
	tbm_rows: number;
	class_rows: number;
	num_nodes_added: number;
};
