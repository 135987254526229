<script setup lang="ts">
import { html } from "@ollion/flow-core";
import { FTableSchemaData, FTableSchemaDataRow } from "@ollion/flow-table";
import { PropType, computed, ref, toRefs } from "vue";

import { downloadFile } from "@/utils";
import { IndexedParagraph } from "@/utils/pdf-utils/pdf-utils-common";

import { getMatchStrengthState } from "../document-ingestion/document-mapping-store";

import { CosineSimilarityResponse } from "./document-comparison-store";

const emit = defineEmits(["close"]);

const showNotification = ref(true);

const props = defineProps({
	leftStatements: {
		type: Array as PropType<IndexedParagraph[]>,
		required: true
	},

	rightStatements: {
		type: Array as PropType<IndexedParagraph[]>,
		required: true
	},

	cosineMap: {
		type: Object as PropType<Record<string, CosineSimilarityResponse[]>>,
		required: true
	}
});

const { leftStatements, rightStatements, cosineMap } = toRefs(props);

const STRENGTH_THRESHOLD = 0.5;
const WEAK_THRESHOLD = 0.3;

const missingStatements = computed(() => {
	const cosineValue = cosineMap.value;

	const strongStatementIds = new Set<string>();

	Object.values(cosineValue).forEach(value => {
		value.forEach(statement => {
			if (statement.similarity >= STRENGTH_THRESHOLD) {
				strongStatementIds.add(statement.id);
			}
		});
	});

	return rightStatements.value.filter(statement => {
		return !strongStatementIds.has(statement.id);
	});
});

const mappingStats = computed(() => {
	const cosineValue = cosineMap.value;

	const strongStatements = leftStatements.value.filter(statement => {
		const foundMap = cosineValue[statement.id];
		return foundMap?.[0] && foundMap[0]?.similarity > STRENGTH_THRESHOLD;
	});

	const weakStatements = leftStatements.value.filter(statement => {
		const foundMap = cosineValue[statement.id];
		return (
			foundMap?.[0] &&
			foundMap[0]?.similarity > WEAK_THRESHOLD &&
			foundMap[0]?.similarity <= STRENGTH_THRESHOLD
		);
	});

	return {
		totalStatements: leftStatements.value.length,
		strongStatements,
		weakStatements,
		missingStatements: missingStatements.value
	};
});

const notificationText = computed(() => {
	const messages: string[] = [];
	if (mappingStats.value.strongStatements.length > 0) {
		messages.push(`${mappingStats.value.strongStatements.length} strong`);
	}

	if (mappingStats.value.weakStatements.length > 0) {
		messages.push(`${mappingStats.value.weakStatements.length} weak`);
	}

	if (mappingStats.value.missingStatements.length > 0) {
		messages.push(`${mappingStats.value.missingStatements.length} missing`);
	}

	return [
		"There are (",
		messages.join(", "),
		") matches between your documents. Please look at the table below for more details."
	].join("");
});

const tableData = computed((): FTableSchemaData => {
	const rows: FTableSchemaDataRow[] = [];

	leftStatements.value.forEach(leftStatement => {
		const mapping = cosineMap.value[leftStatement.id]?.[0];

		if (!mapping) {
			return;
		}

		const { similarity } = mapping;

		const parsedId = Number(leftStatement.displayId);
		const isStrong = similarity >= STRENGTH_THRESHOLD;
		const label = isStrong ? "Strong" : "Weak";

		rows.push({
			id: leftStatement.id,
			data: {
				id: {
					value: isNaN(parsedId) ? leftStatement.displayId : parsedId
				},
				leftStatement: {
					value: leftStatement.str,
					template: () =>
						html`<f-text variant="para" size="small" weight="medium" align="left"
							>${leftStatement.str}</f-text
						>`
				},
				rightStatement: {
					value: mapping.str,
					template: () =>
						html`<f-text variant="para" size="small" weight="medium" align="left"
							>${mapping.str}</f-text
						>`
				},
				mappingStrength: {
					value: similarity,
					template: () => {
						const tagState = getMatchStrengthState(similarity);

						return html`
							<f-div width="100%" align="middle-left" gap="medium">
								<f-div>
									<f-tag
										clickable
										category="outline"
										size="small"
										state="${tagState}"
										label="${label}"
									>
									</f-tag>
								</f-div>
							</f-div>
						`;
					}
				}
			}
		});
	});

	missingStatements.value.forEach(missingStatement => {
		rows.push({
			id: missingStatement.id,
			data: {
				id: {
					value: "-"
				},
				leftStatement: {
					value: "-",
					template: () =>
						html`<f-text variant="para" size="small" weight="medium" align="left">-</f-text>`
				},
				rightStatement: {
					value: missingStatement.str,
					template: () =>
						html`<f-text variant="para" size="small" weight="medium" align="left"
							>${missingStatement.str}</f-text
						>`
				},
				mappingStrength: {
					value: 0,
					template: () => {
						return html`
							<f-div width="100%" align="middle-left" gap="medium">
								<f-div>
									<f-tag clickable category="outline" size="small" state="danger" label="Missing">
									</f-tag>
								</f-div>
							</f-div>
						`;
					}
				}
			}
		});
	});

	return {
		header: {
			id: {
				value: "ID",
				width: "80px",
				align: "middle-center"
			},
			leftStatement: {
				value: "Left statement",
				width: "40%",
				align: "middle-left"
			},
			rightStatement: {
				value: "Right statement",
				width: "auto",
				align: "middle-left"
			},
			mappingStrength: {
				value: "Strength",
				width: "120px",
				align: "middle-center"
			}
		},
		rows
	};
});

const headerCellTemplate = (val: string) => {
	return html` <f-div gap="small" align="middle-center" height="hug-content">
		<f-text variant="para" size="small" weight="medium">${val}</f-text>
	</f-div>`;
};

function downloadReport() {
	const csvData = tableData.value.rows.map(row => {
		return [
			row.data.id?.value,
			`"${row.data.leftStatement?.value.replaceAll('"', '""')}"`,
			`"${row.data.rightStatement?.value.replaceAll('"', '""')}"`,
			row.data.mappingStrength?.value
		].join(",");
	});

	const csvContent = ["ID,Left statement,Right statement,Strength", ...csvData].join("\n");

	downloadFile(csvContent, "stance-document-comparison.csv");
}
</script>

<template>
	<f-popover open state="transparent" size="custom(90vw,90vh)">
		<f-div state="default" direction="column">
			<f-div
				height="hug-content"
				width="fill-container"
				state="default"
				padding="medium"
				align="middle-left"
				gap="medium"
				flex-wrap
				border="small solid secondary bottom"
			>
				<f-text variant="heading" weight="bold" size="medium">Comparison result</f-text>
				<f-button
					size="small"
					label="Download report"
					icon-left="i-download"
					category="outline"
					@click="downloadReport"
				></f-button>
				<f-icon clickable source="i-close" @click="emit('close')"></f-icon>
			</f-div>

			<f-div
				v-if="showNotification && notificationText"
				state="primary"
				padding="medium"
				align="middle-left"
				gap="large"
				height="hug-content"
			>
				<f-icon source="i-info-fill" state="primary"></f-icon>
				<f-text>{{ notificationText }}</f-text>
				<f-button
					label="Got it"
					size="small"
					state="neutral"
					category="outline"
					@click="showNotification = false"
				></f-button>
			</f-div>

			<f-table-schema
				variant="underlined"
				:data="tableData"
				:show-search-bar="true"
				:header-cell-template="headerCellTemplate"
				:highlight-hover="true"
				:highlight-column-hover="false"
				:rows-per-page="200"
				sticky-cell-background="secondary"
				sticky-header
				size="small"
			>
				<f-div slot="no-data" state="warning" variant="curved" width="100%" padding="medium">
					<f-text state="default">No results found for your search query.</f-text>
				</f-div>
			</f-table-schema>
		</f-div>
	</f-popover>
</template>
