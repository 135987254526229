<template>
	<f-div
		ref="scrollContainer"
		direction="row"
		width="fill-container"
		overflow="scroll"
		padding="medium"
		height="fill-container"
		@wheel="onScroll"
		@scroll="onScroll"
	>
		<f-grid :min-cell-width="362" gap="medium">
			<component-card
				v-for="component in visibleComponents"
				:key="component.id"
				:component="component"
			/>
		</f-grid>
	</f-div>
	<f-div v-if="loading" align="middle-center" height="hug-content" padding="medium">
		<f-icon source="i-plus" :loading="true" size="large"> </f-icon>
	</f-div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Component } from "@/protocol/cockpit";

import ComponentCard from "./ComponentCard.vue";

export default defineComponent({
	name: "ComponentCardGrid",

	components: {
		ComponentCard
	},

	props: {
		components: {
			type: Array as PropType<Component[]>,
			required: true
		}
	},

	data() {
		return {
			visibleComponents: [] as Component[],
			loading: false,
			itemsPerLoad: 10,
			currentPage: 0
		};
	},

	watch: {
		components: {
			handler() {
				this.resetComponents();
			}
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.calculateInitialComponents();
		});
	},

	methods: {
		onScroll() {
			const container = this.$refs.scrollContainer as HTMLElement;
			const bottomOfWindow =
				container.scrollTop + container.clientHeight >= container.scrollHeight - 100;

			if (bottomOfWindow) {
				this.loadMore();
			}
		},

		loadMore() {
			if (this.loading) {
				return;
			}

			this.loading = true;

			const start = this.currentPage * this.itemsPerLoad;
			const end = start + this.itemsPerLoad;

			if (start >= this.components.length) {
				this.loading = false;
				return;
			}

			this.visibleComponents = this.visibleComponents.concat(this.components.slice(start, end));
			this.currentPage += 1;
			this.loading = false;
		},

		resetComponents() {
			this.visibleComponents = [];
			this.currentPage = 0;
			this.calculateInitialComponents();
		},

		calculateInitialComponents() {
			const container = this.$refs.scrollContainer as HTMLElement | null | undefined;

			if (!container) {
				return;
			}
			const containerWidth = container.clientWidth;
			const containerHeight = container.clientHeight + 200;

			const minCardWidth = 362;
			const minCardHeight = 150;

			const cardsPerRow = Math.floor(containerWidth / minCardWidth);
			const rowsPerPage = Math.floor(containerHeight / minCardHeight);
			const itemsPerLoad = cardsPerRow * rowsPerPage;
			if (itemsPerLoad > this.components.length) {
				this.itemsPerLoad = this.components.length;
			} else if (itemsPerLoad > this.itemsPerLoad) {
				this.itemsPerLoad = itemsPerLoad;
			}

			this.loadMore();
		}
	}
});
</script>
