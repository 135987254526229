import { Session } from "@ory/client";

import { Permission } from "@/protocol/users";

export type FormPayload = { email: { value: string }; password: { value: string } };

export const USER_PERMISSIONS = {
	complianceReport: "compliance-report",
	constellationMap: "constellation-map",
	documentIngest: "document-ingest",
	workflowApprove: "workflow-approve",
	releaseCockpit: "release-cockpit",
	taxonomyTable: "taxonomy-table",
	evidenceUi: "evidence-ui",
	documentMapping: "document-mapping",
	componentCatalogRead: "component-catalog-read",
	componentCatalogWrite: "component-catalog-write",
	manageMembers: "manage-members",
	lzComplianceReport: "lz-report"
} as const;

export type UserPermissions = Record<string, Permission>;

export type AuthState = {
	logoutToken: string;
	userSession: Session | null;
	userPermissions: UserPermissions;
	permissionsLoading: boolean;
};

export enum OIDCProvider {
	GITHUB = "github",
	GOOGLE = "google",
	MICROSOFT = "microsoft"
}
