import { defineStore } from "pinia";

import {
	getComponentDocuments,
	getComponents,
	getComponentWorkflow,
	getControlGates,
	getControlsByClassification,
	getEnvironments,
	getStatementEvidences,
	notariseComponent,
	publishComponent,
	updateWorkflow,
	createEvidence
} from "@/modules/release-cockpit-v2/component-catalog-service";
import { ComponentType, StepStatus, Workflow, WorkflowStatus, Component } from "@/protocol/cockpit";
import {
	Environment,
	Document,
	Classification,
	ControlStatement,
	DocumentReq,
	ControlGateResponse
} from "@/protocol/correlation";
import { captureError } from "@/utils";

import { EvidenceMapping } from "./catalog-service-types";
import { DocumentStats } from "./release-cockpit-types";

export const componentOnboardStore = defineStore("componentOnboardStore", {
	state: () => ({
		components: {} as Record<string, Component>,
		documents: {} as Record<string, Document[]>,
		workflow: {} as Record<string, Workflow>,
		componentStatements: {} as Record<string, ControlStatement[]>,
		evidences: {} as Record<string, Record<string, EvidenceMapping>>,
		loadingComponent: {} as Record<string, boolean>,
		targetEnvironment: {} as Record<string, string>,
		environments: [] as Environment[],
		controlGates: [] as ControlGateResponse[]
	}),

	actions: {
		getEligibleControls(componentId: Component["id"]) {
			if (!componentId) {
				return [];
			}
			const targetEnvironment = this.targetEnvironment[componentId];
			const selectedControlSets =
				this.controlGates
					.find(control => control.id === targetEnvironment)
					?.controlSets?.map(item => item.name) ?? [];

			const automatedDocuments = this.getAutomatedDocuments(componentId).map(
				document => document.id
			);

			return (
				this.componentStatements[componentId]?.filter(statement => {
					// Only filter on automated statements
					if (!automatedDocuments.includes(statement.documentId)) {
						return true;
					}

					if (selectedControlSets.length > 0) {
						return selectedControlSets.includes(statement.controlSet ?? "");
					}
					return true;
				}) ?? []
			);
		},

		getAllDocuments(componentId: string) {
			const documentTaxonomy =
				this.workflow[componentId]?.steps?.map(step => step.applicableValue) ?? [];

			if (!documentTaxonomy.length) {
				return [];
			}

			return (
				this.documents[componentId]?.filter(document =>
					documentTaxonomy.includes(document.applicableValue)
				) ?? []
			);
		},

		getAllStatements(componentId: Component["id"]): ControlStatement[] {
			if (!componentId) {
				return [];
			}
			const documentIds = this.getAllDocuments(componentId).map(document => document.id);
			return this.getEligibleControls(componentId).filter(statement => {
				return documentIds.includes(statement.documentId);
			});
		},

		getAutomatedDocuments(componentId: Component["id"]) {
			if (!componentId) {
				return [];
			}
			const automatedDocumentTaxons =
				this.workflow[componentId]?.steps
					?.filter(step => step.type === "AUTOMATED")
					.map(step => step.applicableValue) ?? [];

			if (!automatedDocumentTaxons.length) {
				return [];
			}

			return (
				this.documents[componentId]?.filter(document =>
					automatedDocumentTaxons.includes(document.applicableValue)
				) ?? []
			);
		},

		getManualDocuments(componentId: Component["id"]) {
			if (!componentId) {
				return [];
			}
			const manualDocumentTaxonomy =
				this.workflow[componentId]?.steps
					?.filter(step => step.type === "MANUAL")
					.map(step => step.applicableValue) ?? [];

			if (!manualDocumentTaxonomy.length) {
				return [];
			}

			return (
				this.documents[componentId]?.filter(document =>
					manualDocumentTaxonomy.includes(document.applicableValue)
				) ?? []
			);
		},

		getAutomatedStatements(componentId: Component["id"]): ControlStatement[] {
			if (!componentId) {
				return [];
			}
			const automatedDocumentIds = this.getAutomatedDocuments(componentId).map(
				document => document.id
			);

			return this.getEligibleControls(componentId).filter(statement => {
				return automatedDocumentIds.includes(statement.documentId);
			});
		},

		getManualStatementsProgress(componentId: Component["id"]) {
			if (!componentId) {
				return 0;
			}
			const manualDocumentIds = this.getManualDocuments(componentId).map(
				document => document.id ?? ""
			);

			if (!manualDocumentIds.length) {
				return 0;
			}

			return this.getDocumentsProgress(componentId, manualDocumentIds);
		},

		getAutomatedStatementsProgress(componentId: Component["id"]) {
			if (!componentId) {
				return 0;
			}
			const automatedDocumentIds = this.getAutomatedDocuments(componentId).map(
				document => document.id ?? ""
			);

			if (!automatedDocumentIds.length) {
				return 0;
			}

			return this.getDocumentsProgress(componentId, automatedDocumentIds);
		},

		getDocumentsProgress(componentId: Component["id"], documentIds: string[]) {
			let totalStatements = 0;
			let statementsWithEvidences = 0;

			this.getDocumentWiseProgress(componentId)
				.filter(progress => documentIds.includes(progress.documentId ?? ""))
				.forEach(progress => {
					totalStatements += progress.totalStatements;
					statementsWithEvidences += progress.statementsWithEvidences;
				});

			return 100 * (statementsWithEvidences / totalStatements);
		},

		getComponentStats(componentId: Component["id"]): Record<string, DocumentStats> {
			if (!componentId) {
				return {};
			}
			const statements = this.componentStatements[componentId] ?? [];
			const evidences = this.evidences[componentId];

			return statements.reduce<Record<string, DocumentStats>>((acc, statement) => {
				if (acc[statement.documentId ?? ""] === undefined) {
					acc[statement.documentId ?? ""] = {
						totalStatements: 0,
						noEvidence: 0,
						approvedStatements: 0,
						rejectedStatements: 0,
						pendingStatements: 0
					} as DocumentStats;
				}
				const doc = evidences?.[statement.documentId ?? ""];
				const docEvidences = doc?.[statement.id ?? ""] ?? [];

				const [latestEvidence] = docEvidences;

				const latestEvidenceStatus = latestEvidence?.status;
				if (docEvidences.length === 0) {
					acc[statement.documentId ?? ""]!.noEvidence++;
				} else if (latestEvidenceStatus === "Approved") {
					acc[statement.documentId ?? ""]!.approvedStatements++;
				} else if (latestEvidenceStatus === "Rejected") {
					acc[statement.documentId ?? ""]!.rejectedStatements++;
				} else {
					acc[statement.documentId ?? ""]!.pendingStatements++;
				}

				acc[statement.documentId ?? ""]!.totalStatements++;

				return acc;
			}, {});
		},

		getDocumentWiseProgress(componentId: Component["id"]) {
			if (!componentId) {
				return [];
			}
			const statements = this.getEligibleControls(componentId);
			const evidences = this.evidences[componentId];
			const allDocuments = this.documents[componentId] ?? [];

			return allDocuments.map(document => {
				const totalStatements = statements.filter(statement => {
					return statement.documentId === document.id;
				});

				if (totalStatements.length === 0) {
					return {
						documentId: document.id,
						progress: "0",
						totalStatements: 0,
						statementsWithEvidences: 0,
						approvedStatements: 0
					};
				}

				const doc = evidences?.[document.id ?? ""];

				const statementsWithEvidences = totalStatements.filter(statement => {
					const docEvidences = doc?.[statement.id ?? ""];
					return docEvidences ? docEvidences.length > 0 : false;
				}).length;

				const approvedStatements = totalStatements.filter(statement => {
					const docEvidences = doc?.[statement.id ?? ""];
					return (
						docEvidences &&
						docEvidences.length > 0 &&
						docEvidences.every(evidence => evidence.status === "Approved")
					);
				}).length;

				const progress = ((statementsWithEvidences / totalStatements.length) * 100).toFixed(2);

				return {
					documentId: document.id,
					progress,
					totalStatements: totalStatements.length,
					statementsWithEvidences,
					approvedStatements
				};
			});
		},

		getAllEvidencesApproved(componentId: Component["id"]) {
			if (!componentId) {
				return false;
			}
			const evidences = this.evidences[componentId];
			const allEvidences = Object.values(evidences ?? {})
				.flatMap(evidenceMapping => Object.values(evidenceMapping))
				.flat();

			return (
				allEvidences.length > 0 && allEvidences.every(evidence => evidence.status === "Approved")
			);
		},

		getApplicableTaxonByType(componentId: Component["id"], type: string) {
			if (!componentId) {
				return [];
			}
			return (
				this.workflow[componentId]?.steps
					?.filter(step => step.type === type)
					.map(step => step.applicableValue) ?? []
			);
		},

		async fetchDocuments(componentId?: Component["id"]) {
			if (!componentId) {
				return;
			}
			const response = await getComponentDocuments({ componentId });
			if (response.documents?.length) {
				this.documents[componentId] = response.documents;
			}
		},

		async fetchWorkflow(componentId: Component["id"]) {
			if (!componentId) {
				return;
			}
			const response = await getComponentWorkflow(componentId);
			if (response.workflow) {
				this.workflow[componentId] = response.workflow;
			}
		},

		async fetchStatements(component: Component) {
			const classification: Classification = {
				...(component.classification1 && {
					level1: {
						name: "Level 1",
						id: component.classification1
					}
				}),
				...(component.classification2 && {
					level2: {
						name: "Level 2",
						id: component.classification2
					}
				}),
				...(component.classification3 && {
					level3: {
						name: "Level 3",
						id: component.classification3
					}
				}),
				...(component.classification4 && {
					level4: {
						name: "Level 4",
						id: component.classification4
					}
				}),
				...(component.classification5 && {
					level5: {
						name: "Level 5",
						id: component.classification5
					}
				}),
				...(component.classification6 && {
					level6: {
						name: "Level 6",
						id: component.classification6
					}
				}),
				...(component.classification7 && {
					level7: {
						name: "Level 7",
						id: component.classification7
					}
				}),
				...(component.classification8 && {
					level8: {
						name: "Level 8",
						id: component.classification8
					}
				})
			};

			const response = await getControlsByClassification({
				componentId: String(component.id),
				classification
			});

			if (response.controls) {
				// Backend can send duplicate controls if they are part of multiple documents
				const processedControls = new Set<string>();

				const filteredControls = response.controls.filter(control => {
					const controlKey = `${control.id}-${control.documentId}`;
					if (processedControls.has(controlKey)) {
						return false;
					}

					processedControls.add(controlKey);
					return true;
				});

				this.componentStatements[component.id ?? ""] = filteredControls;
			}
		},

		async fetchStatementEvidences(componentId: Component["id"]) {
			if (!componentId) {
				return;
			}
			const componentStatements = this.componentStatements[componentId];

			if (!componentStatements) {
				return;
			}

			const groupedByDocumentId: Record<string, ControlStatement[]> = {};
			componentStatements.forEach(control => {
				if (!groupedByDocumentId[control.documentId ?? ""]) {
					groupedByDocumentId[control.documentId ?? ""] = [];
				}
				groupedByDocumentId[control.documentId ?? ""]!.push(control);
			});

			const documentReq: DocumentReq[] = Object.entries(groupedByDocumentId).map(
				([docId, componentStatementsForDoc]) => {
					return {
						documentId: docId,
						statementIds: componentStatementsForDoc.map(statement => statement.id ?? "")
					};
				}
			);

			const response = await getStatementEvidences({
				componentId: String(componentId),
				documentReqs: documentReq
			});

			const { documentMapping } = response;

			if (!documentMapping) {
				return;
			}

			const evidences: Record<string, EvidenceMapping> = {};

			Object.entries(documentMapping).forEach(([documentId, statementMapping]) => {
				// Backend doesn't guarantee sorting of evidences, so we do it ourselves
				const { evidenceMapping } = statementMapping;

				if (!evidenceMapping) {
					return;
				}

				Object.entries(evidenceMapping).forEach(([statementId, evidencesMap]) => {
					const { mapping } = evidencesMap;
					if (!mapping) {
						return;
					}

					Object.entries(mapping).forEach(([, evidencesForMap]) => {
						const { evidences: evidencesResponse } = evidencesForMap;

						if (!evidencesResponse) {
							return;
						}

						evidences[documentId] = {
							...evidences[documentId],
							// eslint-disable-next-line max-nested-callbacks
							[statementId]: evidencesResponse.sort((a, b) => {
								return Number(b.createdAt) - Number(a.createdAt);
							})
						};
					});
				});
			});

			this.evidences[componentId] = evidences;
		},

		async fetchComponentOnboardingDocuments(component: Component) {
			if (!component.id) {
				return;
			}
			this.loadingComponent[component.id] = true;

			try {
				await Promise.all([
					this.fetchDocuments(component.id),
					...(component.type !== ComponentType.ASSET ? [this.fetchWorkflow(component.id)] : []),
					this.fetchStatements(component)
				]);

				// To fetch evidences we need the list of controls which can only come from the fetchStatements call
				await this.fetchStatementEvidences(component.id);
			} catch (error) {
				this.loadingComponent[component.id] = false;
				captureError(error);
				throw error;
			} finally {
				this.loadingComponent[component.id] = false;
			}
		},

		async createEvidence({
			payload,
			componentId
		}: {
			payload: FormData;
			componentId: Component["id"];
		}) {
			const response = await createEvidence(payload);
			this.fetchStatementEvidences(componentId);
			return response;
		},

		async updateWorkflow(componentId: Component["id"]) {
			if (!componentId) {
				return;
			}
			const payload: Workflow = {
				...this.workflow[componentId],
				status: WorkflowStatus.WORKFLOW_COMPLETED,
				steps:
					this.workflow[componentId]?.steps?.map(step => {
						return {
							...step,
							status: StepStatus.STEP_COMPLETED
						};
					}) ?? []
			};
			const response = await updateWorkflow({ componentId, workflow: payload });
			await this.fetchWorkflow(componentId);
			await this.getComponentById(componentId);
			return response;
		},

		async getComponents() {
			const response = await getComponents();
			if (!response.components?.length) {
				return [];
			}

			response.components.forEach(component => {
				this.components[component.id!] = component;
			});

			return response.components;
		},

		// eslint-disable-next-line complexity
		async getComponentById(componentId: Component["id"]) {
			try {
				// const component = await getComponentById({
				// 	componentId
				// });

				const component = (await this.getComponents()).find(comp => comp.id === componentId);

				if (!component?.id) {
					return null;
				}

				this.components[component.id] = component;

				return component;
			} catch (error) {
				captureError(error);
			}
		},

		async componentNotarise(componentId: Component["id"]) {
			if (!componentId) {
				return;
			}

			const response = await notariseComponent({ componentId });
			await this.getComponentById(componentId);
			return response;
		},

		async componentPublish(componentId: Component["id"]) {
			if (!componentId) {
				return;
			}
			const response = await publishComponent({ componentId });
			await this.getComponentById(componentId);
			return response;
		},

		setTargetEnvironment(componentId: Component["id"], targetEnvironment: string) {
			if (!componentId) {
				return;
			}

			this.targetEnvironment[componentId] = targetEnvironment;
		},

		async fetchGatesAndEnvironments() {
			const listEnvs = await getEnvironments();

			if (listEnvs.environments?.length) {
				this.environments = listEnvs.environments;
			}

			const controlResponse = await getControlGates();
			if (controlResponse.controlGates?.length) {
				this.controlGates = controlResponse.controlGates;
			}
		},

		// Returns which environments a component is rated for based on the approved evidences
		getRatedEnvironment(componentId: Component["id"]) {
			if (!componentId) {
				return {};
			}
			const allControls = this.componentStatements[componentId] ?? [];
			const evidences = this.evidences[componentId];
			const { controlGates, environments } = this;
			const eligibleGates: ControlGateResponse[] = [];

			const automatedDocuments = this.getAutomatedDocuments(componentId).map(
				document => document.id
			);

			// Find all the control gates which have their controls satisfied with approved evidences
			controlGates.forEach(gate => {
				const controlSets = gate.controlSets?.map(set => set.name);
				const eligibleControls = allControls.filter(control => {
					// Only filter on automated statements as manual controls are not eligible for rating
					if (!automatedDocuments.includes(control.documentId)) {
						return true;
					}

					return controlSets?.includes(control.controlSet);
				});

				const approvedStatements = eligibleControls.filter(control => {
					const doc = evidences?.[control.documentId ?? ""];
					const docEvidences = doc?.[control.id ?? ""];
					const [latestEvidence] = docEvidences ?? [];

					return (
						docEvidences &&
						docEvidences.length > 0 &&
						//latest evidence is approved
						latestEvidence?.status === "Approved"
					);
				});

				if (approvedStatements.length === eligibleControls.length) {
					eligibleGates.push(gate);
				}
			});

			const eligibleEnvironments = (
				environments
					.map(env => {
						return eligibleGates.find(gate => env.controlGate?.id === gate.id);
					})
					.filter(Boolean) as ControlGateResponse[]
			)
				// Sort by decreasing number of controls
				.sort((a, b) => {
					return (b.controlSets?.length ?? 0) - (a.controlSets?.length ?? 0);
				});

			return { eligibleGates, eligibleEnvironments, environments };
		}
	}
});
