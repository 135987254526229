import { defineStore } from "pinia";

import { Component } from "@/protocol/cockpit";
import {
	DepAcknowledgementRequest,
	DeprecationInfo,
	ApproveRejectWorkflowTaskRequest
} from "@/protocol/workflow";

import {
	componentDeprecationById,
	acknowledgeDeprecation,
	approveRejectDeprecationTask
} from "./workflow-service";

export const workflowStore = defineStore("workflowStore", {
	state: () => ({
		deprecationDetails: {} as DeprecationInfo
	}),
	actions: {
		SET_DEPRECATION_DETAILS_BLANK() {
			this.deprecationDetails = {};
		},
		async deprecationByComponentId(componentId: Component["id"]) {
			try {
				if (!componentId) {
					return;
				}

				const response = await componentDeprecationById({
					componentId
				});
				this.deprecationDetails = response;
			} catch (error) {
				this.deprecationDetails = {};
			}
		},

		async approveRejectDeprecationTask(payload: ApproveRejectWorkflowTaskRequest) {
			try {
				const response = await approveRejectDeprecationTask(payload);
				this.deprecationDetails = response;
			} catch (error) {
				this.deprecationDetails = {};
			}
		},

		async acknowledgeDeprecation(payload: DepAcknowledgementRequest) {
			try {
				const response = await acknowledgeDeprecation(payload);
				this.deprecationDetails = response;
			} catch (error) {
				this.deprecationDetails = {};
			}
		}
	}
});
