import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import Authenticated from "./components/Authenticated.vue";
import ChangePassword from "./components/ChangePassword.vue";
import Login from "./components/Login.vue";
import PageNotFound from "./components/PageNotFound.vue";
import RecoveryVerification from "./components/RecoveryVerification.vue";
import SendRecoveryEmail from "./components/SendRecoveryEmail.vue";
import SendVerificationEmail from "./components/SendVerificationEmail.vue";
import Signup from "./components/Signup.vue";
import Verification from "./components/Verification.vue";

const routes: RouteRecordRaw[] = [
	{ path: "/:pathMatch(.*)*", redirect: { name: "login", params: {} } },
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			transition: "trans-flip",
			title: "Login",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/signup",
		name: "signup",
		component: Signup,
		meta: {
			transition: "trans-flip",
			title: "Signup",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/self-service/verification",
		name: "verification",
		component: Verification,
		meta: {
			transition: "trans-flip",
			title: "Verification",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/send-email",
		name: "send-email",
		component: SendVerificationEmail,
		meta: {
			transition: "trans-flip",
			title: "Send Email",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/authenticated",
		name: "authenticated",
		component: Authenticated,
		meta: {
			transition: "trans-flip",
			title: "",
			requiresAuth: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/page-not-found",
		name: "page-not-found",
		component: PageNotFound,
		meta: {
			transition: "trans-flip",
			title: "",
			requiresAuth: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/send-recovery-email",
		name: "send-recovery-email",
		component: SendRecoveryEmail,
		meta: {
			transition: "trans-flip",
			title: "Send Email",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/self-service/recovery",
		name: "recovery",
		component: RecoveryVerification,
		meta: {
			transition: "trans-flip",
			title: "RecoveryVerification",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	},
	{
		path: "/change-password",
		name: "change-password",
		component: ChangePassword,
		meta: {
			transition: "trans-flip",
			title: "ChangePassword",
			onlyWhenLoggedOut: true
		} as RouteMeta,
		props: true
	}
];

export function registerAuthRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
