<script setup lang="ts">
import { FSelectOptionObject } from "@ollion/flow-core";
import { PropType, computed, ref, toRefs, watch } from "vue";

import { Document } from "@/protocol/document";

import { documentIngestionStore } from "../document-ingestion-store";

const documentStore = documentIngestionStore();

const props = defineProps({
	document: {
		type: Object as PropType<Document>,
		required: true
	}
});

const firstDocument = ref<DocumentSelect | null>(null);
const secondDocument = ref<DocumentSelect | null>(null);
const isLoading = ref(false);
const { document } = toRefs(props);

// Get all versions of the document
const documentVersions = computed(() => {
	return documentStore.documents.filter(doc => doc.documentName === document.value.documentName);
});

// Selection options
const documentSelectOptions = computed((): DocumentSelect[] => {
	return documentVersions.value.map(doc => {
		return {
			title: doc.documentVersion,
			data: { document: doc }
		};
	});
});

// First document and it's statements
const firstDocumentStatements = computed(() => {
	if (!firstDocument.value?.data?.document) {
		return null;
	}

	return documentStore.statements[firstDocument.value.data.document.documentId ?? ""];
});

// Second document and it's statements
const secondDocumentStatements = computed(() => {
	if (!secondDocument.value?.data?.document) {
		return null;
	}

	return documentStore.statements[secondDocument.value.data.document.documentId ?? ""];
});

// Calculate diff of the two documents
const renderedDiff = computed(() => {
	if (!firstDocumentStatements.value || !secondDocumentStatements.value) {
		return null;
	}

	const firstDocStatements = firstDocumentStatements.value;
	const secondDocStatements = secondDocumentStatements.value;
	const firstDocVersion = firstDocument.value?.data?.document.documentVersion ?? "";
	const secondDocVersion = secondDocument.value?.data?.document.documentVersion ?? "";

	const diff: Array<{
		firstDocVersion: string;
		secondDocVersion: string;
		statementNativeId: string;
		firstStatement: string;
		secondStatement: string;
		state: "added" | "removed" | "changed" | "unchanged";
	}> = [];

	firstDocStatements.forEach(statement => {
		const statementNativeId = statement.statementNativeId!;
		const secondDocStatement = secondDocStatements.find(
			docStatement => docStatement.statementNativeId === statementNativeId
		);

		if (!secondDocStatement) {
			diff.push({
				firstDocVersion,
				secondDocVersion,
				statementNativeId,
				firstStatement: statement.statement,
				secondStatement: "",
				state: "removed"
			});
		} else if (statement.statement !== secondDocStatement.statement) {
			diff.push({
				firstDocVersion,
				secondDocVersion,
				statementNativeId,
				firstStatement: statement.statement,
				secondStatement: secondDocStatement.statement,
				state: "changed"
			});
		} else {
			diff.push({
				firstDocVersion,
				secondDocVersion,
				statementNativeId,
				firstStatement: statement.statement,
				secondStatement: secondDocStatement.statement,
				state: "unchanged"
			});
		}
	});

	// Find added statements
	secondDocStatements.forEach(statement => {
		const statementNativeId = statement.statementNativeId!;
		const firstDocStatement = firstDocStatements.find(
			docStatement => docStatement.statementNativeId === statementNativeId
		);

		if (!firstDocStatement) {
			diff.push({
				firstDocVersion,
				secondDocVersion,
				statementNativeId,
				firstStatement: "",
				secondStatement: statement.statement,
				state: "added"
			});
		}
	});

	diff.sort((a, b) => {
		return a.statementNativeId.localeCompare(b.statementNativeId);
	});

	return diff;
});

const emit = defineEmits(["close"]);
function closeModal() {
	emit("close");
}

// Prefill select boxes
watch(
	[documentSelectOptions],
	() => {
		const [firstDocumentArr, secondDocumentArr] = documentSelectOptions.value;

		if (firstDocumentArr) {
			firstDocument.value = firstDocumentArr;
			secondDocument.value = firstDocumentArr;
		}

		if (secondDocumentArr) {
			secondDocument.value = secondDocumentArr;
		}
	},
	{
		immediate: true
	}
);

// Fetch statements when the user selects a box
watch(
	[firstDocument, secondDocument],
	async () => {
		const firstDocId = firstDocument.value?.data?.document.documentId;
		const statementsToFetch: Array<Promise<unknown>> = [];

		if (firstDocId && !documentStore.statements[firstDocId]) {
			statementsToFetch.push(documentStore.FETCH_STATEMENTS(firstDocId));
		}

		const secondDocId = secondDocument.value?.data?.document.documentId;
		if (secondDocId && !documentStore.statements[secondDocId]) {
			statementsToFetch.push(documentStore.FETCH_STATEMENTS(secondDocId));
		}

		if (statementsToFetch.length > 0) {
			isLoading.value = true;
			await Promise.all(statementsToFetch);
			isLoading.value = false;
		}
	},
	{
		immediate: true
	}
);

type DocumentSelect = FSelectOptionObject<{ document: Document }>;
</script>

<template>
	<f-popover
		open
		close-on-escape
		state="transparent"
		size="custom(80vw,auto)"
		@overlay-click="closeModal"
		@esc="closeModal"
		@close="closeModal"
	>
		<f-div
			v-if="isLoading"
			state="default"
			:style="{ minHeight: '80vh' }"
			align="middle-center"
			direction="column"
			gap="medium"
		>
			<f-icon source="i-spinner" loading></f-icon>
			<f-text>Loading...</f-text>
		</f-div>
		<f-div
			v-else
			direction="column"
			overflow="hidden"
			gap="large"
			state="default"
			:style="{ maxHeight: '80vh' }"
		>
			<f-div
				padding="medium"
				height="hug-content"
				align="middle-left"
				border="small solid subtle bottom"
			>
				<f-text variant="heading" weight="bold"
					>Compare versions for {{ document.documentName }}</f-text
				>
				<f-icon source="i-close" clickable @click="closeModal"></f-icon>
			</f-div>

			<f-div gap="x-large" height="hug-content" padding="none medium">
				<f-select
					v-model="firstDocument"
					:data-qa="`selected-app-${firstDocument?.data?.document?.documentId}`"
					placeholder="Select document version"
					size="small"
					:options="documentSelectOptions"
				>
					<f-div slot="label" padding="none" gap="none">First version</f-div>
				</f-select>
				<f-select
					v-model="secondDocument"
					:data-qa="`selected-app-${secondDocument?.data?.document?.documentId}`"
					placeholder="Select document version"
					size="small"
					:options="documentSelectOptions"
				>
					<f-div slot="label" padding="none" gap="none">Second version</f-div>
				</f-select>
			</f-div>
			<!-- Diff start -->
			<f-div
				overflow="scroll"
				gap="x-small"
				padding="none medium"
				show-scrollbar
				direction="column"
				height="fill-container"
			>
				<template v-for="diffItem in renderedDiff" :key="diffItem.statementNativeId">
					<template v-if="diffItem.state === 'unchanged'">
						<f-div
							height="hug-content"
							width="fill-container"
							gap="medium"
							:tooltip="`v${diffItem.firstDocVersion}`"
						>
							<f-text inline size="small" weight="bold">{{ diffItem.statementNativeId }}:</f-text>
							<f-text inline size="small">{{ diffItem.firstStatement }}</f-text>
						</f-div>
					</template>

					<template v-else-if="diffItem.state === 'added'">
						<f-div
							height="hug-content"
							width="fill-container"
							gap="medium"
							state="success"
							:tooltip="`v${diffItem.secondDocVersion}`"
						>
							<f-text inline size="small" weight="bold">{{ diffItem.statementNativeId }}:</f-text>
							<f-text inline size="small">{{ diffItem.secondStatement }}</f-text>
						</f-div>
					</template>

					<template v-else-if="diffItem.state === 'changed'">
						<f-div height="hug-content" direction="column" width="fill-container" state="warning">
							<f-div gap="medium" :tooltip="`v${diffItem.firstDocVersion}`">
								<f-text inline size="small" weight="bold" state="warning"
									>{{ diffItem.statementNativeId }}:</f-text
								>
								<f-text inline size="small" state="warning">{{ diffItem.firstStatement }}</f-text>
							</f-div>

							<f-div gap="medium" :tooltip="`v${diffItem.secondDocVersion}`">
								<f-text inline size="small" weight="bold" state="warning"
									>{{ diffItem.statementNativeId }}:</f-text
								>
								<f-text inline size="small" state="warning">{{ diffItem.secondStatement }}</f-text>
							</f-div>
						</f-div>
					</template>

					<template v-else-if="diffItem.state === 'removed'">
						<f-div
							height="hug-content"
							width="fill-container"
							gap="medium"
							state="danger"
							:tooltip="`v${diffItem.firstDocVersion}`"
						>
							<f-text inline size="small" weight="bold">{{ diffItem.statementNativeId }}:</f-text>
							<f-text inline size="small">{{ diffItem.firstStatement }}</f-text>
						</f-div>
					</template>
				</template>
			</f-div>
			<!-- Diff End -->

			<f-divider state="secondary" variant="dotted"></f-divider>

			<!-- Legend start -->
			<f-div align="middle-left" gap="x-small" padding="none medium medium medium">
				<f-div state="success" width="hug-content" data-legend></f-div>
				<f-text size="small" inline>Added</f-text>

				<f-spacer size="x-small"></f-spacer>

				<f-div state="warning" width="hug-content" data-legend></f-div>
				<f-text size="small" inline>Modified</f-text>

				<f-spacer size="x-small"></f-spacer>

				<f-div state="danger" width="hug-content" data-legend></f-div>
				<f-text size="small" inline>Removed</f-text>
			</f-div>
			<!-- Legend end -->
		</f-div>
	</f-popover>
</template>

<style lang="scss">
[data-legend] {
	width: 1rem !important;
	height: 1rem !important;
	border-radius: 4px !important;
	border: 1px solid #888 !important;
}
</style>
