<template>
	<f-tab node-width="hug-content">
		<f-tab-node
			data-qa="taxonomy-tabnode-domain"
			:active="selectedTaxonomyTab === TAXONOMY_TYPES.DOMAIN"
			content-id="tab-0"
			@click="changeTaxonomyTab(TAXONOMY_TYPES.DOMAIN)"
		>
			<f-div align="middle-center" direction="column" height="100%" width="100%">
				<f-div align="middle-center" height="hug-content" width="hug-content">Domain</f-div>
			</f-div>
		</f-tab-node>
		<f-tab-node
			data-qa="taxonomy-tabnode-tbm"
			:active="selectedTaxonomyTab === TAXONOMY_TYPES.TBM"
			content-id="tab-1"
			@click="changeTaxonomyTab(TAXONOMY_TYPES.TBM)"
		>
			<f-div align="middle-center" direction="column" height="100%" width="100%">
				<f-div align="middle-center" height="hug-content" width="hug-content">TBM</f-div>
			</f-div>
		</f-tab-node>
		<f-tab-node
			data-qa="taxonomy-tabnode-class"
			:active="selectedTaxonomyTab === TAXONOMY_TYPES.CLASS"
			content-id="tab-1"
			@click="changeTaxonomyTab(TAXONOMY_TYPES.CLASS)"
		>
			<f-div align="middle-center" direction="column" height="100%" width="100%">
				<f-div align="middle-center" height="hug-content" width="hug-content">Class</f-div>
			</f-div>
		</f-tab-node>
	</f-tab>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import { taxonomyStore } from "@/modules/taxonomy-table/taxonomy-table-store";
import { TAXONOMY_TYPES } from "@/shared/constants";

export default defineComponent({
	name: "TaxonomyTabs",

	computed: {
		...mapState(taxonomyStore, ["selectedTaxonomyTab"]),

		TAXONOMY_TYPES() {
			return TAXONOMY_TYPES;
		}
	},

	methods: {
		...mapActions(taxonomyStore, ["switchTaxonomyTab"]),

		changeTaxonomyTab(newSelectedTab: TAXONOMY_TYPES) {
			this.switchTaxonomyTab(newSelectedTab);
		}
	}
});
</script>

<style scoped></style>
