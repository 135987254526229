import { AxiosResponse } from "axios";

import {
	AllNodeRequest,
	AllNodeResponse,
	CorrelationService,
	GetAllCategoryResponse,
	NodeNeighboursResponse,
	ShortPathResponse
} from "@/protocol/correlation";
import { CORRELATION_SERVICE_PATH } from "@/shared/service-paths";

import HTTP from "../services/api-service";

export const shortestPath: CorrelationService["getShortestPath"] = async request => {
	const response: AxiosResponse<ShortPathResponse> = await HTTP.post(
		`${CORRELATION_SERVICE_PATH}/shortestpath`,
		request
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getByType: CorrelationService["getAllNodes"] = async request => {
	const response: AxiosResponse<AllNodeResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/all?nodeType=${request.nodeType}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getNeighbours: CorrelationService["nodeNeighbours"] = async request => {
	const response: AxiosResponse<NodeNeighboursResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/neighbours?nodeType=${request.nodeType}&nodeAttr=${request.nodeAttr}&nodeValue=${request.nodeValue}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getTypes: CorrelationService["getAllCategories"] = async () => {
	const response: AxiosResponse<GetAllCategoryResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/categories`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const getAllNodes: CorrelationService["getAllNodes"] = async (request: AllNodeRequest) => {
	const response: AxiosResponse<AllNodeResponse> = await HTTP.get(
		`${CORRELATION_SERVICE_PATH}/all?nodeType=${request.nodeType}`
	);
	if (response.status === 200) {
		return response.data;
	}
	throw response;
};

export const exportGraph = (nodes: { nodeIds: string[] }) => {
	const headers = { "Content-Type": "blob" };
	return HTTP.post(`${CORRELATION_SERVICE_PATH}/export-graph`, nodes, {
		method: "GET",
		responseType: "arraybuffer",
		headers
	});
};
