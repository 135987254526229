export function readFile(file: File) {
	return new Promise<ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = e => {
			resolve(e.target?.result as ArrayBuffer);
		};
		reader.onerror = e => {
			reject(e);
		};
		reader.readAsArrayBuffer(file);
	});
}
