<template>
	<f-div width="100%">
		<f-div
			direction="column"
			:width="showSidePanel ? '100%' : '70%'"
			padding="small none none small"
		>
			<f-div padding="small none large small" direction="column" gap="medium">
				<f-div width="fill-container" gap="large">
					<f-select
						v-model="selectedRegion"
						:data-qa="`selected-region-${selectedRegion.title}`"
						placeholder="Select Region"
						size="medium"
						:options="regionList"
					>
					</f-select>

					<f-select
						v-model="selectedCountry"
						:data-qa="`selected-country-${selectedCountry.title}`"
						placeholder="Select Country"
						size="medium"
						:options="countryList"
					>
					</f-select>
				</f-div>
				<f-div width="fill-container">
					<f-select
						v-model="selectedApp"
						:data-qa="`selected-app-${selectedApp.title}`"
						placeholder="Select application"
						size="medium"
						:options="appOptions"
					>
					</f-select>
				</f-div>
			</f-div>
			<!-- Selected Apps details-->
			<f-div padding="large none none small">
				<f-tab class="margin-lt-0 margin-rt-20 margin-bt-20">
					<f-tab-node
						v-for="tab of tabNodes"
						:key="tab.id"
						:data-qa-tab="tab.name"
						:active="selected === tab.id"
						:content-id="String(tab.id)"
						@click="selected = tab.id"
						><f-div width="100%" height="100%" align="middle-center" direction="column">
							<f-div align="middle-center" height="hug-content" width="hug-content">
								<f-div padding="none" gap="small" align="middle-left">{{ tab.name }} </f-div>
							</f-div>
						</f-div>
					</f-tab-node>
				</f-tab>
				<f-tab-content>
					<f-div gap="x-large">
						<f-div direction="row" width="100%" padding="large" gap="x-large">
							<TabsCardComponents :selected-app="selectedApp" />
						</f-div>

						<f-div gap="small">
							<TechnologyCardComponents
								v-if="selectedApp.id !== ''"
								:key="selectedApp.id"
								:application-id="selectedApp.id"
								:app-options="appOptions"
							/>
							<f-div v-else padding="x-large">
								<EmptyState
									message="No Component Available"
									subtitle="Please select application"
									shape="circle"
								>
								</EmptyState>
							</f-div>
						</f-div>
					</f-div>
				</f-tab-content>
			</f-div>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores, mapState } from "pinia";
import { defineComponent } from "vue";

import { getByType } from "@/modules/constellation-map/correlation-service";
import { captureError } from "@/utils";

import { getCountriesList, getApplications } from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import {
	ApplicationOptions,
	TabNodesObj,
	DEFAULT_REGION_ITEM,
	DEFAULT_COUNTRY_ITEM,
	DEFAULT_APPLICATION_ITEM
} from "../compliance-report-utils";

import TabsCardComponents from "./TabsCardComponents.vue";
import TechnologyCardComponents from "./TechnologyCardComponents.vue";

export default defineComponent({
	name: "GeographyTab",

	components: {
		EmptyState,
		TechnologyCardComponents,
		TabsCardComponents
	},

	emits: ["open-side-panel"],

	data: () => {
		return {
			selectedApp: {} as ApplicationOptions,
			selectedCountry: {} as ApplicationOptions,
			selectedRegion: {} as ApplicationOptions,
			countryList: [] as ApplicationOptions[],
			regionList: [] as ApplicationOptions[],
			appOptions: [DEFAULT_APPLICATION_ITEM] as ApplicationOptions[],

			selected: 0,
			tabNodes: [{ name: "Technology controls", id: 0 }] as TabNodesObj[]
		};
	},

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			showSidePanel: "showSidePanel"
		})
	},

	watch: {
		selectedRegion: {
			handler(lob) {
				if (lob && lob.id !== "") {
					/* Whenever lob change then fetch relative sub-lob */
					this.fetchCountryList();
				} else {
					this.selectedCountry = DEFAULT_COUNTRY_ITEM;
					this.selectedRegion = DEFAULT_REGION_ITEM;
					this.countryList = [];
				}
			}
		},

		selectedCountry: {
			handler(country) {
				if (country && country.id !== "" && this.selectedCountry.id !== "") {
					/* Whenever lob change then fetch relative sub-lob */
					this.getApplicationsList();
				} else {
					this.selectedCountry = DEFAULT_COUNTRY_ITEM;
					this.selectedApp = DEFAULT_APPLICATION_ITEM;
					this.appOptions = [DEFAULT_APPLICATION_ITEM];
				}
			}
		},

		selectedApp: {
			handler(app) {
				if (app?.id) {
					this.complianceReportStore.setSelectedApp(app);
				}
			},

			deep: true,
			immediate: true
		}
	},

	mounted() {
		this.fetchRegionList();
	},

	methods: {
		async fetchCountryList() {
			const countries: ApplicationOptions[] = [DEFAULT_COUNTRY_ITEM];
			const response = await getCountriesList({
				regionId: this.selectedRegion.id
			});
			response.countries?.forEach(country =>
				countries.push({ id: country.id ?? "", title: country.name ?? "" })
			);
			this.countryList = countries;
			this.selectedCountry = DEFAULT_COUNTRY_ITEM;
		},

		async fetchRegionList() {
			const region: ApplicationOptions[] = [DEFAULT_REGION_ITEM];
			const response = await getByType({ nodeType: "Region" });
			response.nodes?.forEach(node =>
				region.push({ id: String(node.props?.id), title: String(node.props?.name) })
			);
			this.regionList = region;
			this.selectedRegion = DEFAULT_REGION_ITEM;
		},

		async getApplicationsList() {
			try {
				const resp = await getApplications({
					countryID: this.selectedCountry.id
				});

				const applications = resp.applications ?? [];
				if (!applications.length) {
					this.appOptions = [DEFAULT_APPLICATION_ITEM];
					return;
				}

				const [firstApp] = applications;
				this.appOptions = applications.map(item => ({ id: item.id ?? "", title: item.name ?? "" }));

				if (firstApp?.id && firstApp.name) {
					this.selectedApp = { id: firstApp.id, title: firstApp.name };
				}
			} catch (error) {
				captureError(error);
				this.appOptions = [DEFAULT_APPLICATION_ITEM];
			}
		}
	}
});
</script>
