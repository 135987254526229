import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import { USER_PERMISSIONS } from "../auth/auth-types";

import ComplianceReport from "./ComplianceReport.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/compliance-report",
		name: "compliance-report",
		component: ComplianceReport,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.complianceReport
		} as RouteMeta,
		props: true
	}
];

export function registerComplianceReportRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
