import * as d3 from "d3";

export function reset() {
	if (this.container) {
		const t = d3.zoomTransform(this.container.node());
		t.k = 1;
		t.x = 0;
		t.y = 0;
		this.zoomContainer(t);
		this.enableReset = false;
	}
}
export function zoomIn() {
	if (this.container) {
		const t = d3.zoomTransform(this.container.node());
		t.k += 0.1;
		if (t.k > 4) {
			t.k = 4;
		}
		this.zoomContainer(t);
	}
}
export function zoomOut() {
	if (this.container) {
		const t = d3.zoomTransform(this.container.node());
		t.k -= 0.1;
		if (t.k < 0.3) {
			t.k = 0.3;
		}
		this.zoomContainer(t);
	}
}
export function zoomContainer(transform) {
	this.enableReset = true;
	this.container.attr("transform", transform);

	if (this.isSafari()) {
		document.querySelectorAll(".lineage-node-wrapper .node-icon-container").forEach(elem => {
			const scale = transform.k;
			//	console.log("Default - " + this.scaleForSafari, " Zoom scale - " + scale);
			elem.style.transform = `scale(${scale}) `;
			elem.style["transform-origin"] = "0% 0% 0px";
		});
	}
}

export default {
	reset,
	zoomIn,
	zoomOut,
	zoomContainer
};
