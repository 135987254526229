<script setup lang="ts">
import { computed, ref } from "vue";

import { IndexedParagraph } from "@/utils";

import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";

import { CosineSimilarityResponse, documentComparisonStore } from "./document-comparison-store";
import DocumentComparisonResult from "./DocumentComparisonResult.vue";
import DocumentStatementsPane from "./DocumentStatementsPane.vue";

const cosineMap = ref<Record<string, CosineSimilarityResponse[]> | null>(null);
const leftStatements = ref<IndexedParagraph[]>([]);
const rightStatements = ref<IndexedParagraph[]>([]);
const showNotification = ref(true);

const currentStatus = ref<"idle" | "embedding" | "comparing">("idle");
const currentProgress = ref(0);

const docComparsionStore = documentComparisonStore();

const hasSelectedStatements = computed(() => {
	return leftStatements.value.length > 0 && rightStatements.value.length > 0;
});

const comparisonLabel = computed(() => {
	if (currentStatus.value === "embedding") {
		return `Processing (${Math.floor(currentProgress.value * 100)}%)`;
	}
	if (currentStatus.value === "comparing") {
		return `Comparing (${Math.floor(currentProgress.value * 100)}%)`;
	}

	return "Compare";
});

async function compareDocuments() {
	let leftTotal = 0;
	let leftDone = 0;
	let rightTotal = 0;
	let rightDone = 0;

	currentStatus.value = "embedding";

	await Promise.all([
		docComparsionStore.CALCULATE_EMBEDDINGS("left", leftStatements.value, (done, total) => {
			leftTotal = total;
			leftDone = done;

			currentProgress.value = (leftDone + rightDone) / (leftTotal + rightTotal);
		}),

		docComparsionStore.CALCULATE_EMBEDDINGS("right", rightStatements.value, (done, total) => {
			rightTotal = total;
			rightDone = done;

			currentProgress.value = (leftDone + rightDone) / (leftTotal + rightTotal);
		})
	]);

	const leftEmbeddings = docComparsionStore.embeddings.left;
	const rightEmbeddings = docComparsionStore.embeddings.right;

	if (!leftEmbeddings || !rightEmbeddings) {
		throw new Error("Embeddings are not calculated");
	}

	currentStatus.value = "comparing";

	cosineMap.value = await docComparsionStore.CALCULATE_COSINE_SIMILARITY(
		leftEmbeddings,
		rightEmbeddings,
		(done, total) => {
			rightTotal = total;
			rightDone = done;

			currentProgress.value = (leftDone + rightDone) / (leftTotal + rightTotal);
		}
	);

	currentStatus.value = "idle";
}
</script>

<template>
	<PermissionsWrapper>
		<f-div width="100%" direction="column">
			<f-div
				height="hug-content"
				width="fill-container"
				state="subtle"
				padding="medium"
				align="middle-left"
				gap="medium"
				flex-wrap
				border="small solid secondary bottom"
			>
				<f-text variant="heading" weight="bold" size="medium">Document comparison</f-text>

				<f-button
					:label="comparisonLabel"
					category="outline"
					:disabled="currentStatus !== 'idle' || !hasSelectedStatements"
					@click="compareDocuments"
				></f-button>
			</f-div>

			<f-div
				v-if="showNotification"
				state="primary"
				padding="medium"
				align="middle-left"
				gap="small"
				height="hug-content"
			>
				<f-icon source="i-info-fill" state="primary"></f-icon>
				<f-text
					>Upload the documents you wish to compare and then select the statements within the
					document you want.</f-text
				>
				<f-button
					label="Got it"
					size="small"
					state="neutral"
					category="outline"
					@click="showNotification = false"
				></f-button>
			</f-div>

			<f-div overflow="hidden" width="100%">
				<DocumentStatementsPane
					document-id="left"
					border="small solid subtle right"
					@select-statements="leftStatements = $event"
				/>
				<DocumentStatementsPane document-id="right" @select-statements="rightStatements = $event" />
			</f-div>
		</f-div>

		<DocumentComparisonResult
			v-if="cosineMap"
			:left-statements="leftStatements"
			:right-statements="rightStatements"
			:cosine-map="cosineMap"
			@close="cosineMap = null"
		></DocumentComparisonResult>
	</PermissionsWrapper>
</template>
