import axios, { AxiosResponse } from "axios";

import {
	CLASS_REQUEST_BODY,
	DOMAIN_REQUEST_BODY,
	TBM_REQUEST_BODY
} from "@/modules/taxonomy-table/taxonomy-types";
import { TAXONOMY_SERVICE_PATH } from "@/shared/service-paths";

import HTTP from "../services/api-service";

export default {
	fetchTaxonomyDomainList(): Promise<AxiosResponse> {
		return HTTP.get(`${TAXONOMY_SERVICE_PATH}/domain`);
	},
	fetchTaxonomyTBMList(): Promise<AxiosResponse> {
		return HTTP.get(`${TAXONOMY_SERVICE_PATH}/tbm`);
	},
	fetchTaxonomyClassList(): Promise<AxiosResponse> {
		return HTTP.get(`${TAXONOMY_SERVICE_PATH}/class`);
	},

	postTaxonomyDomain(data: DOMAIN_REQUEST_BODY): Promise<AxiosResponse> {
		const payload = { domains: [data] };
		return HTTP.post(`${TAXONOMY_SERVICE_PATH}/domain`, payload);
	},
	postTaxonomyTBM(data: TBM_REQUEST_BODY): Promise<AxiosResponse> {
		const payload = { TBMs: [data] };
		return HTTP.post(`${TAXONOMY_SERVICE_PATH}/tbm`, payload);
	},
	postTaxonomyClass(data: CLASS_REQUEST_BODY): Promise<AxiosResponse> {
		const payload = { classes: [data] };
		return HTTP.post(`${TAXONOMY_SERVICE_PATH}/class`, payload);
	},

	getTaxonomies(level: number, { name }: { name: string }) {
		return HTTP.get(`${TAXONOMY_SERVICE_PATH}/${name}/${level}`);
	},
	getChildTaxonomies(level: number, parentTaxonomy: string, { name }: { name: string }) {
		return HTTP.get(`${TAXONOMY_SERVICE_PATH}/${name}/${level}?parentName=${parentTaxonomy}`);
	},
	uploadFile(file: File) {
		const formData = new FormData();
		formData.append("file", file, file.name);
		const config = {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		};
		return HTTP.post(`${TAXONOMY_SERVICE_PATH}/csv`, formData, config);
	},
	export() {
		return axios({
			method: "get",
			url: `${TAXONOMY_SERVICE_PATH}/export`,
			responseType: "arraybuffer"
		});
	}
};
