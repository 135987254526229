import { RouteMeta, Router, RouteRecordRaw } from "vue-router";

import { USER_PERMISSIONS } from "../auth/auth-types";

import TaxonomyTable from "./TaxonomyTable.vue";

const routes: RouteRecordRaw[] = [
	{
		path: "/taxonomy-table",
		name: "taxonomy-table",
		component: TaxonomyTable,
		meta: {
			requiresAuth: true,
			requiredRole: USER_PERMISSIONS.taxonomyTable
		} as RouteMeta,
		props: true
	}
];

export function registerTaxonomyTableRoutes(router: Router) {
	routes.forEach(route => {
		router.addRoute(route);
	});
}
