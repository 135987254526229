export function isValidUrl(url: string): boolean {
	if (typeof url !== "string") {
		return false;
	}

	if (!url.startsWith("http")) {
		return false;
	}

	try {
		const parsedUrl = new URL(url);

		// Ensure the hostname is resolvable and does not include harmful characters
		const isResolvableHostName = /[^.]+\.[^.]+/.test(parsedUrl.hostname);

		// Additional checks to prevent XSS attacks
		const hasNoSpaces = !url.includes(" ");
		const hasNoScripts = !/['"<>()]/.test(url); // Prevent common XSS characters

		return isResolvableHostName && hasNoSpaces && hasNoScripts;
	} catch (e) {
		return false;
	}
}

export function isValidSemver(str: string) {
	// https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
	return /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/.test(str);
}
