import { defineStore } from "pinia";

import { listAllUsers } from "@/modules/user/user-service";
import { User } from "@/protocol/users";

export const userStore = defineStore("userStore", {
	state: () => ({
		membersList: [] as User[]
	}),

	getters: {},

	actions: {
		updateSelectedMemberRole(user: User, newRoles?: string[]) {
			const userIndex = this.membersList.findIndex(member => member.id === user.id);
			if (userIndex !== -1 && this.membersList[userIndex]) {
				this.membersList[userIndex]!.role = newRoles;
			}
		},

		async getMembers() {
			const response = await listAllUsers();
			if (!response.users?.length) {
				return [];
			}

			this.membersList = response.users;
		}
	}
});
