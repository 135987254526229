<template>
	<PermissionsWrapper>
		<CustomLoader v-if="isLoading" />

		<ErrorPage v-else-if="hasFetchError" message="Something went wrong when getting components" />
		<EmptyState
			v-else-if="users.length === 0"
			action="Add New Member"
			:icon="emptyIcon"
			message="Manage Members"
			subtitle="Invite new members"
			shape="circle"
			@actions="showUploadSLideout"
		>
		</EmptyState>

		<MembersList v-else :users="users" />
	</PermissionsWrapper>
</template>

<script lang="ts">
import { EmptyState, EmptyStateIconProp } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import { userStore } from "@/modules/user/user-store";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { captureError } from "@/utils";

import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";

import MembersList from "./components/MembersList.vue";

export default defineComponent({
	name: "ManageMembers",

	components: {
		EmptyState,
		MembersList,
		PermissionsWrapper,
		CustomLoader,
		ErrorPage
	},

	data: () => ({
		hasFetchError: false,
		emptyIcon: {
			name: "i-chat",
			type: "filled",
			state: "primary",
			size: "medium",
			color: "gray-200"
		} as EmptyStateIconProp,

		isLoading: true,
		showSlideout: false
	}),

	computed: {
		...mapStores(userStore),

		users() {
			return this.userStore.membersList;
		}
	},

	mounted() {
		this.loadMembers();
	},

	methods: {
		loadMembers() {
			try {
				// Render from cache
				if (this.users.length > 0) {
					this.isLoading = false;
				}
				this.isLoading = true;
				this.userStore
					.getMembers()
					.catch(() => (this.hasFetchError = true))
					.finally(() => (this.isLoading = false));
			} catch (err) {
				captureError(err);
			}
		},

		showUploadSLideout() {
			this.showSlideout = true;
		}
	}
});
</script>
