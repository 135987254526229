/**
 * Convert month number to month name
 * @param monthNumber
 * @returns
 */
function toMonthName(monthNumber: number): string {
	if (monthNumber < 1 || monthNumber > 12) {
		throw new Error("Month number must be between 1 and 12");
	}
	const date = new Date();
	date.setMonth(monthNumber - 1);
	return date.toLocaleString("en-US", {
		month: "long"
	});
}

/**
 * Convert date of format: year-month-day
 * to format: month, day year
 * @param date
 * @returns
 */
export function formatDate(date: string) {
	if (!date) {
		throw new Error("Date cannot be empty");
	}
	const dateArr = date.substring(0, 10).split("-");

	if (dateArr.length !== 3) {
		throw new Error("Date is not in the correct format");
	}

	if (dateArr.some(val => isNaN(parseInt(val, 10)))) {
		throw new Error("Date must only have numeric values");
	}

	let [year, month, day] = dateArr;
	month = toMonthName(parseInt(month!, 10));

	return `${month}, ${day} ${year}`;
}

//Format epoch time to MMM DD YYYY, HH MM A
export const formatDateString = (dateString: string | number): string => {
	let date: Date;

	if (typeof dateString === "string" && dateString.includes("T")) {
		// Parse ISO 8601 date string
		date = new Date(dateString);
	} else {
		const epochTime = Number(dateString);
		// Handle epoch times
		date = epochTime < 10000000000 ? new Date(epochTime * 1000) : new Date(epochTime);
	}
	const months: string[] = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];

	const hr = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
	const year: string = date.getFullYear().toString().padStart(2, "0");
	const month: string | undefined = months[date.getMonth()];
	const day: string = date.getDate().toString().padStart(2, "0");
	const hour: string = hr.toString().padStart(2, "0");
	const minute: string = date.getMinutes().toString().padStart(2, "0");
	const period: string = date.getHours() >= 12 ? "PM" : "AM";

	return `${month} ${day} ${year}, ${hour}:${minute} ${period}`;
};

export const convertEpochToDateTime = (epochTime: number): string => {
	const date: Date = epochTime < 10000000000 ? new Date(epochTime * 1000) : new Date(epochTime);
	const day = date.getDate();
	const month = date.getMonth() + 1; // Months are zero-based
	const year = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	// Formatting with leading zeroes
	const formattedDate = `${day.toString().padStart(2, "0")}/${month
		.toString()
		.padStart(2, "0")}/${year}`;

	const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
		.toString()
		.padStart(2, "0")}`;

	return `${formattedDate} ${formattedTime}`;
};

export function formatISODate(date: string) {
	if (!date) {
		throw new Error("Date cannot be empty");
	}
	const dateObj = new Date(date);
	if (isNaN(dateObj.getTime())) {
		throw new Error("Date is not in the correct format");
	}

	const year = dateObj.getUTCFullYear();
	const month = toMonthName(dateObj.getUTCMonth() + 1);
	const day = dateObj.getUTCDate();
	const hour = String(dateObj.getUTCHours()).padStart(2, "0");
	const minute = String(dateObj.getUTCMinutes()).padStart(2, "0");
	const second = String(dateObj.getUTCSeconds()).padStart(2, "0");

	return `${month} ${day}, ${year}, ${hour}:${minute}:${second}`;
}
