<template>
	<FullPageLoader v-if="isLoading" />
	<f-div
		v-else
		height="fill-container"
		width="fill-container"
		:data-overlay="isLeftOpen || isRightOpen"
		class="f-three-columns"
	>
		<!--Start : left column-->
		<f-div
			width="320px"
			:data-open="isLeftOpen"
			border="small solid secondary right"
			state="default"
			class="f-left-column"
			overflow="hidden"
		>
			<OnboardingLeftPanel
				:component="component"
				:documents="documents"
				@collapse-panel="isLeftOpen = false"
			/>
		</f-div>
		<f-div
			v-if="!isLeftOpen"
			class="f-left-notch"
			height="76px"
			state="tertiary"
			width="hug-content"
			align="middle-center"
			clickable
			:data-open="isLeftOpen"
			@click="toggleLeftColumn"
		>
			<f-icon
				:source="isLeftOpen ? 'i-notch-left' : 'i-notch-right'"
				size="small"
				state="secondary"
			></f-icon>
		</f-div>
		<!--End : left column-->
		<!--Start : middle column-->

		<f-div direction="column">
			<EmptyState
				v-if="documents.length === 0"
				:icon="{
					name: 'i-document',
					size: 'medium',
					state: 'light'
				}"
				message="No statements found for onboarding"
				data-qa="no-statements-found-error"
			/>
			<f-div
				v-if="documents.length"
				padding="small"
				height="hug-content"
				border="medium dashed subtle bottom"
			>
				<ComponentOnboardingSubHeader
					:component="component"
					:show-all-dropdown="true"
					@update-search-value="updateSearchValue"
					@update-evidence-filter="updateEvidenceFilter"
				></ComponentOnboardingSubHeader>
			</f-div>
			<OnboardingControlStatementList
				v-if="documents.length"
				:component="component"
				:is-selection-enabled="hasComponentCatalogWriteAccess"
				:documents="statementGroupByDocument"
				:search-string="searchString"
				@selected-statements="onStatementSelection"
			/>
		</f-div>

		<!--End : middle column-->
		<!--Start : right column-->

		<f-div
			v-if="showUploadForm"
			width="320px"
			:data-open="isRightOpen"
			state="default"
			class="f-right-column"
			border="small solid secondary left"
		>
			<OnboardingUploadEvidence
				:documents="documents"
				:component="component"
				:selected-statements="selectStatements"
				@close-evidence-modal="handleCloseUpload"
			/>
		</f-div>
		<!--End : right column-->
	</f-div>
</template>

<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { USER_PERMISSIONS } from "@/modules/auth/auth-types";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import ComponentOnboardingSubHeader from "@/modules/release-cockpit-v2/components/component-catalog-detail/ComponentOnboardingSubHeader.vue";
import OnboardingControlStatementList from "@/modules/release-cockpit-v2/components/component-catalog-detail/OnboardingControlStatementList.vue";
import OnboardingLeftPanel from "@/modules/release-cockpit-v2/components/component-catalog-detail/OnboardingLeftPanel.vue";
import OnboardingUploadEvidence from "@/modules/release-cockpit-v2/components/component-catalog-detail/OnboardingUploadEvidence.vue";
import { SelectedStatement } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component } from "@/protocol/cockpit";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";
import { COMPONENT_STATUS, EVIDENCE_STATUS, MIN_DESKTOP_WIDTH } from "@/shared/constants";

import { DocumentWithStatements } from "../../catalog-service-types";

export default defineComponent({
	name: "ComponentOnboardingManualTab",

	components: {
		OnboardingControlStatementList,
		ComponentOnboardingSubHeader,
		OnboardingUploadEvidence,
		OnboardingLeftPanel,
		FullPageLoader,
		EmptyState
	},

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => ({
		isLeftOpen: true,
		isRightOpen: false,
		selectStatements: [] as SelectedStatement[],
		showUploadForm: false,
		searchString: "" as string | "",
		evidenceFilterString: "" as string | ""
	}),

	computed: {
		...mapStores(componentOnboardStore, authStore),

		hasComponentCatalogWriteAccess() {
			const { status } = this.component;
			return (
				Boolean(this.authStore.userPermissions[USER_PERMISSIONS.componentCatalogWrite]?.enabled) &&
				status !== COMPONENT_STATUS.deprecationPending &&
				status !== COMPONENT_STATUS.deprecated &&
				status !== COMPONENT_STATUS.notarised &&
				status !== COMPONENT_STATUS.published
			);
		},

		isLoading() {
			return this.componentOnboardStore.loadingComponent[this.component.id!];
		},

		documents() {
			const manualApplicableTaxon =
				this.componentOnboardStore.workflow[this.component.id ?? ""]?.steps
					?.filter(step => step.type === "MANUAL")
					.map(step => step.applicableValue) ?? [];

			const documents =
				this.componentOnboardStore.documents[this.component.id ?? ""]?.filter(document =>
					manualApplicableTaxon.includes(document.applicableValue)
				) ?? [];

			return documents;
		},

		statements() {
			return this.componentOnboardStore.getEligibleControls(this.component.id);
		},

		filteredStatements() {
			const searchStr = this.searchString.toLocaleLowerCase().trim();
			if (searchStr.length < 3) {
				return this.statements;
			}

			return this.statements.filter(statement => {
				const statementText = statement.statement?.toLocaleLowerCase();
				return statementText?.includes(searchStr);
			});
		},

		statementGroupByDocument(): DocumentWithStatements[] {
			const allEvidences = this.componentOnboardStore.evidences[this.component.id ?? ""] ?? {};

			return this.documents.map(document => {
				const statements = this.filteredStatements.filter(
					statement => statement.documentId === document.id
				);

				if (!this.evidenceFilterString.trim()) {
					return {
						document,
						statements
					};
				}

				return {
					document,
					statements: statements.filter(controlStatementVal => {
						const firstEvidence = allEvidences[document.id!]?.[controlStatementVal.id!]?.[0];
						const hasEvidences = Boolean(firstEvidence);
						const isEvidenceStatusMatchingFilter =
							firstEvidence?.status === this.evidenceFilterString;

						if (this.evidenceFilterString === EVIDENCE_STATUS.notAvailable) {
							return !hasEvidences;
						}

						return hasEvidences && isEvidenceStatusMatchingFilter;
					})
				};
			});
		}
	},

	mounted() {
		const screenWidth = window.innerWidth;
		if (screenWidth < MIN_DESKTOP_WIDTH) {
			this.isLeftOpen = false;
			this.isRightOpen = false;
		}
	},

	methods: {
		toggleLeftColumn() {
			this.isLeftOpen = !this.isLeftOpen;
		},

		onStatementSelection(statements: SelectedStatement[]) {
			this.selectStatements = statements;
			this.showUploadForm = this.selectStatements.length > 0;
			this.isRightOpen = true;
		},

		handleCloseUpload() {
			this.selectStatements.splice(0, this.selectStatements.length);
			this.showUploadForm = false;
			this.isRightOpen = false;
		},

		updateSearchValue(value: string) {
			this.searchString = value;
		},

		updateEvidenceFilter(value: string) {
			this.evidenceFilterString = value;
		}
	}
});
</script>

<style lang="scss">
.f-three-columns {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		transition: opacity 300ms linear;
		background-color: #000;
		opacity: 0;
		z-index: 2;
	}
}
.f-left-notch {
	margin-top: 12px;
	padding: 2px;
	border-radius: 0px 8px 8px 0px !important;
}
.f-right-notch {
	margin-top: 12px;
	padding: 2px;
	border-radius: 8px 0px 0px 8px !important;
}

.f-left-column,
.f-right-column {
	transition: width 300ms linear;

	&[data-open="false"] {
		width: 0px !important;
	}
}
@media (max-width: 1270px) {
	.f-three-columns {
		&[data-overlay="true"] {
			&::before {
				top: 0px;
				bottom: 0px;
				left: 0px;
				right: 0px;
				opacity: 0.6;
			}
		}
	}
	.f-left-column,
	.f-right-column {
		position: absolute;
		z-index: 2;
	}
	.f-left-notch,
	.f-right-notch {
		position: absolute;
		z-index: 2;
	}
	.f-left-notch {
		transition: left 300ms linear;
		left: 320px;
		&[data-open="false"] {
			left: 0px;
		}
	}
	.f-right-notch {
		transition: right 300ms linear;
		right: 320px;
		&[data-open="false"] {
			right: 0px;
		}
	}
	.f-right-column {
		right: 0px;
	}
}
</style>
