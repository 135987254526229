<template>
	<PermissionsWrapper>
		<CustomLoader v-if="isLoading" />

		<EmptyState
			v-else-if="canMapDocuments && totalDocuments === 0"
			action="Upload Documents"
			:icon="emptyIcon"
			message="Document Ingestion"
			subtitle="Upload your new compliance documents"
			shape="circle"
			@actions="goToDraftDocument"
		>
		</EmptyState>

		<EmptyState
			v-else-if="!canMapDocuments && totalDocuments === 0"
			:icon="emptyIcon"
			message="Document Ingestion"
			subtitle="No documents have been uploaded yet"
			shape="circle"
		>
		</EmptyState>

		<DocumentList
			v-else
			:documents="documents"
			:draft-documents="draftDocuments"
			:can-create="canMapDocuments"
			@add-document="goToDraftDocument"
			@link-taxonomy="linkTaxonomy"
		/>

		<f-popover v-if="taxonomyToLink" open size="custom(864px,400px)">
			<TaxonomyModel :document-id="taxonomyToLink" @close="taxonomyToLink = null"></TaxonomyModel>
		</f-popover>
	</PermissionsWrapper>
</template>

<script lang="ts">
import { EmptyState, EmptyStateIconProp } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { Document } from "@/protocol/document";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { captureError } from "@/utils";

import { authStore } from "../auth/auth-store";
import PermissionsWrapper from "../core/components/PermissionsWrapper.vue";
import { featureFlagStore } from "../feature-flags/feature-flags-store";

import DocumentList from "./components/DocumentList.vue";
import TaxonomyModel from "./components/TaxonomyModel.vue";
import { documentIngestionStore } from "./document-ingestion-store";
import { documentMappingStore } from "./document-mapping-store";

export default defineComponent({
	name: "DocumentIngestion",

	components: {
		EmptyState,
		DocumentList,
		TaxonomyModel,
		PermissionsWrapper,
		CustomLoader
	},

	data: () => ({
		emptyIcon: {
			name: "i-file",
			type: "filled",
			state: "primary",
			size: "medium",
			color: "gray-200"
		} as EmptyStateIconProp,

		taxonomyToLink: null as string | null,
		isLoading: true
	}),

	computed: {
		...mapStores(authStore, documentIngestionStore, featureFlagStore, documentMappingStore),

		canMapDocuments() {
			const permissions = this.authStore.userPermissions;
			return permissions["document-mapping"]?.enabled;
		},

		documents() {
			return this.documentIngestionStore.documents;
		},

		draftDocuments() {
			return this.documentMappingStore.draftDocuments;
		},

		totalDocuments() {
			return this.draftDocuments.length + this.documents.length;
		}
	},

	async mounted() {
		try {
			// Render from cache
			if (this.documents.length > 0) {
				this.isLoading = false;
			}

			// Fetch latest
			await Promise.all([
				this.documentIngestionStore.FETCH_DOCUMENTS(),
				this.documentMappingStore.FETCH_DRAFT_DOCUMENTS()
			]);
		} catch (err) {
			captureError(err);
		} finally {
			this.isLoading = false;
		}
	},

	methods: {
		linkTaxonomy(document: Document) {
			this.taxonomyToLink = document.documentId ?? "";
		},

		goToDraftDocument() {
			const draftDocument = this.documentMappingStore.CREATE_NEW_DRAFT_DOCUMENT();

			this.$router.push({
				name: "draft-document",
				params: { documentId: draftDocument.documentId }
			});
		}
	}
});
</script>
