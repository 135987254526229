<script setup lang="ts">
import { Loader } from "@ollion/flow-vue3";
import { PropType, toRefs, onMounted, ref } from "vue";

import { Evidence } from "@/protocol/evidence";
import { captureError, getErrorMessage, getBucketUrl } from "@/utils";

const props = defineProps({
	evidence: {
		type: Object as PropType<Evidence>,
		required: true
	}
});

const { evidence } = toRefs(props);

const imageUrl = ref("");
const isLoading = ref(false);
const errorMessage = ref("");
const fetchImage = async (url: string) => {
	try {
		isLoading.value = true;
		errorMessage.value = "";
		const updatedUrl = getBucketUrl(url);
		const response = await fetch(updatedUrl);
		const blob = await response.blob();
		return URL.createObjectURL(blob);
	} catch (error) {
		captureError(error);
		errorMessage.value = getErrorMessage(error);
		return "";
	} finally {
		isLoading.value = false;
	}
};

function downloadEvidence() {
	const url = evidence.value.content;
	window.open(url, "_blank");
}

onMounted(async () => {
	if (evidence.value.content) {
		imageUrl.value = await fetchImage(evidence.value.content);
	}
});
</script>

<template>
	<f-div data-qa-img-renderer width="fill-container" height="fill-container" align="middle-center">
		<Loader v-if="isLoading" />
		<f-div v-else-if="errorMessage" align="middle-center" direction="column" gap="large">
			<f-icon source="i-question-filled" size="x-large" state="subtle"></f-icon>
			<f-text state="secondary"
				>Failed to render {{ evidence.name }} of type {{ evidence?.fileType }}</f-text
			>
			<f-text state="secondary">{{ errorMessage }}</f-text>
			<f-text state="secondary"> Please download the evidence.</f-text>
			<f-button
				data-qa="download-evidence-button"
				label="Download evidence"
				size="large"
				category="outline"
				icon-right="i-download"
				@click="downloadEvidence"
			></f-button>
		</f-div>

		<img v-else-if="imageUrl" :src="imageUrl" :alt="evidence.comment" />
	</f-div>
</template>

<style lang="scss">
[data-qa-img-renderer] img {
	max-height: 100%;
	max-width: 100%;
}
</style>
