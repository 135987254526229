<template>
	<f-div direction="column" width="fill-container" height="fill-container">
		<!-- asset add form starts -->
		<f-div
			padding="medium"
			gap="medium"
			width="100%"
			align="top-left"
			height="hug-content"
			border="small solid secondary bottom"
		>
			<f-text variant="para" size="medium" weight="medium" state="default" align="left">
				Asset artifacts
			</f-text>
		</f-div>

		<f-div
			v-if="!assetArtifact"
			direction="column"
			padding="medium none medium none"
			width="fill-container"
		>
			<!-- CREATE form -->
			<f-div padding="large" align="top-center" height="hug-content">
				<f-form-builder
					ref="formBuilder"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</f-div>

			<!-- Error message -->
			<f-div
				v-if="submitError"
				padding="medium"
				height="hug-content"
				gap="medium"
				direction="row"
				width="100%"
				state="danger"
			>
				<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
					{{ submitError }}
				</f-text>
				<f-div align="top-right" width="hug-content">
					<f-icon
						class="cursor-pointer"
						source="i-close"
						size="x-small"
						data-qa-error-close-icon
						data-qa-close-control-gate-popover-error-popover
						@click="submitError = ''"
					>
					</f-icon>
				</f-div>
			</f-div>

			<!-- footer -->
			<f-div padding="large" width="fill-container" gap="medium" align="bottom-center">
				<f-button
					label="add artifacts"
					variant="block"
					size="large"
					category="outline"
					state="neutral"
					:disabled="!formState?.isValid"
					:loading="isLoading"
					data-qa="add-artifacts-button"
					@click="addAssetArtifact"
				></f-button>
			</f-div>
		</f-div>

		<!-- asset add form ends -->

		<!-- asset artifacts details links starts-->
		<f-div v-else>
			<f-div gap="large" direction="column" width="fill-container" padding="medium">
				<f-div
					v-if="assetArtifact.baselineEt"
					direction="row"
					gap="medium"
					height="hug-content"
					clickable
					@click="openLink(assetArtifact?.baselineEt)"
				>
					<f-text align="left" variant="para" size="medium" weight="bold" state="primary">
						Baseline ET catalog
					</f-text>
					<f-icon source="i-new-tab" size="small"></f-icon>
				</f-div>
				<f-div
					v-if="assetArtifact.materialMarketing"
					direction="row"
					gap="medium"
					height="hug-content"
					clickable
					@click="openLink(assetArtifact.materialMarketing)"
				>
					<f-text align="left" variant="para" size="medium" weight="bold" state="primary">
						Marketing & demo document
					</f-text>
					<f-icon source="i-new-tab" size="small"></f-icon>
				</f-div>
				<f-div
					v-if="assetArtifact.baselineDDD"
					direction="row"
					gap="medium"
					height="hug-content"
					clickable
					@click="openLink(assetArtifact.baselineDDD)"
				>
					<f-text align="left" variant="para" size="medium" weight="bold" state="primary">
						Solution document
					</f-text>
					<f-icon source="i-new-tab" size="small"></f-icon>
				</f-div>
				<f-div
					v-if="assetArtifact.codeRepo"
					direction="row"
					gap="medium"
					height="hug-content"
					clickable
					@click="openLink(assetArtifact.codeRepo)"
				>
					<f-text align="left" variant="para" size="medium" weight="bold" state="primary">
						Code repository
					</f-text>
					<f-icon source="i-new-tab" size="small"></f-icon>
				</f-div>
			</f-div>
		</f-div>

		<!-- asset artifacts details links ends -->
	</f-div>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { createArtifact } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { Component, ArtifactType, CreateArtifactRequest } from "@/protocol/cockpit";
import { captureError, isValidUrl } from "@/utils";

type FormValues = {
	baselineEtCatalog: string;
	solutionDocument: string;
	marketingDocument: string;
	codeRepository: string;
};

export default defineComponent({
	name: "AssetArtifactRightPane",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	data: () => ({
		formState: null as FormBuilderState | null,
		formValues: {
			baselineEtCatalog: "",
			marketingDocument: "",
			solutionDocument: "",
			codeRepository: ""
		} as FormValues,

		submitError: "",
		isLoading: false
	}),

	computed: {
		...mapStores(authStore, notificationsStore, componentOnboardStore),

		assetArtifact() {
			const { artifact } = this.component;

			if (artifact?.type === ArtifactType.ARTIFACT_UNKNOWN) {
				return null;
			}

			const isAssetDocument = artifact?.type === ArtifactType.ASSET_DOCUMENT;

			if (!isAssetDocument) {
				return null;
			}

			const assetDetails = artifact.metadata?.asset;

			if (!assetDetails) {
				return null;
			}

			return assetDetails;
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					baselineEtCatalog: {
						type: "url",
						qaId: "baseline-et-catalog",
						label: { title: "Baseline ET Catalog" },
						placeholder: "Enter url",
						validationRules: [
							{ name: "required" },
							{
								name: "custom",
								validate: value => {
									return isValidUrl(String(value));
								},

								message: "Please provide correct url"
							}
						]
					},

					marketingDocument: {
						type: "url",
						qaId: "marketing-demo-document",
						label: { title: "Marketing & demo document" },
						placeholder: "Enter url",
						validationRules: [
							// { name: "required" },
							{
								name: "custom",
								validate: value => {
									if (!value) {
										return true;
									}
									return isValidUrl(String(value));
								},

								message: "Please provide correct url"
							}
						]
					},

					solutionDocument: {
						type: "url",
						label: { title: "Solution document" },
						qaId: "solution-document",
						placeholder: "Enter url",
						validationRules: [
							{ name: "required" },
							{
								name: "custom",
								validate: value => {
									return isValidUrl(String(value));
								},

								message: "Please provide correct url"
							}
						]
					},

					codeRepository: {
						type: "url",
						qaId: "code-repository",
						label: { title: "Code repository" },
						placeholder: "Enter url",
						validationRules: [
							{ name: "required" },
							{
								name: "custom",
								validate: value => {
									return isValidUrl(String(value));
								},

								message: "Please provide correct url"
							}
						]
					}
				}
			};
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			const values = { ...event.detail };
			this.formValues = values;
		},

		getUserId() {
			return this.authStore.userSession?.identity?.id;
		},

		openLink(url: string) {
			try {
				new URL(url);
				window.open(url, "_blank");
			} catch (error) {
				captureError(error);
			}
		},

		async addAssetArtifact() {
			this.isLoading = true;
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			try {
				this.isLoading = true;

				const payload: CreateArtifactRequest = {
					componentId: this.component.id ?? "",
					name: this.component.name ?? "",
					status: "active",
					createdBy: this.getUserId() ?? "",
					approvedBy: "",
					type: ArtifactType.ASSET_DOCUMENT,
					metadata: {
						asset: {
							codeRepo: this.formValues.codeRepository,
							baselineEt: this.formValues.baselineEtCatalog,
							baselineDDD: this.formValues.solutionDocument,
							materialMarketing: this.formValues.marketingDocument
						}
					}
				};

				await createArtifact(payload);

				await this.componentOnboardStore.getComponentById(this.component.id);
				this.isLoading = false;

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated",
					title: "Asset artifacts",
					text: "Asset artifacts added successfully.",
					status: "success"
				});
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated-error",
					title: "Error while adding asset artifacts",
					text: error === "string" ? error : "Error while adding asset artifacts, please try again",
					status: "error"
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
});
</script>
