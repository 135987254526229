export function convertImageFileToBase64(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (!file) {
			// eslint-disable-next-line prefer-promise-reject-errors
			reject("No file provided");
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			// Check if the result is a string (base64 data URL)
			if (typeof reader.result === "string") {
				resolve(reader.result);
			} else {
				// eslint-disable-next-line prefer-promise-reject-errors
				reject("Failed to convert file to Base64");
			}
		};

		reader.onerror = error => {
			reader.abort();
			reject(error);
		};

		reader.readAsDataURL(file);
	});
}
