<template>
	<Container :grow="1" :padding="0" align="space-between center">
		<Container :gap="12" :padding="14">
			<Icon data-qa-stance-logo name="p-stance" size="large" @click="redirectToHome" />
			<Icon
				name="i-arrow-left"
				type="filled"
				state="light"
				size="small"
				data-qa-breadcrumb-back
				@click="navigateBack()"
			/>
			<Breadcrumbs :crumbs="breadcrumbs" :crumb-width="300" @click="navigate" />
		</Container>
		<Container :gap="28" :padding="14" overflow="visible">
			<Icon
				v-if="allowDebugMenu"
				id="debug-menu-trigger"
				name="i-bug"
				state="light"
				size="20px"
				type="filled"
				data-qa-debug-menu-icon
				@click="openDebugMenu"
			/>

			<PopOver v-model:open="openProfile" :overlay="false" placement="bottom-end">
				<Icon
					name="i-user"
					size="20px"
					state="light"
					type="filled"
					@click="openProfile = !openProfile"
				/>
				<template #content>
					<Wrapper :border="true" background="element-light" border-radius="4px" width="255px">
						<Container :gap="0" :padding="0" direction="column">
							<Slab :effects="false" border-type="solid" size="medium" type="bottom-border">
								<Typography color="light" transform="uppercase" type="p3">
									Logged in as
								</Typography>
								<Typography color="dark" type="p1"> {{ getUserName }}</Typography>
							</Slab>
							<Slab
								size="large"
								type="transparent"
								:effects="true"
								class="cursor-pointer"
								:data-qa-field="isDark ? 'dark-mode-toggle' : 'light-mode-toggle'"
							>
								<template #primary-action>
									<Icon name="i-theme" type="filled" state="dark" size="small" :effects="false" />
								</template>
								<p class="paragraph-1 fc-dark line-h-14 text-ellipsis">Dark mode</p>
								<template #secondary-actions>
									<SwitchBox v-model:on="isDark" />
								</template>
							</Slab>
							<Slab
								:effects="true"
								class="cursor-pointer"
								size="large"
								type="transparent"
								@click="openAccountSettings"
							>
								<template #primary-action>
									<Icon :effects="false" name="i-setting" size="small" state="dark" type="filled" />
								</template>
								<p class="paragraph-1 fc-dark line-h-14 text-ellipsis">Account settings</p>
							</Slab>
							<Slab
								:effects="true"
								class="cursor-pointer"
								size="large"
								type="transparent"
								@click="logoutUser"
							>
								<template #primary-action>
									<Icon :effects="false" name="i-logout" size="small" state="dark" type="filled" />
								</template>
								<p class="paragraph-1 fc-dark line-h-14 text-ellipsis">Logout</p>
							</Slab>
						</Container>
					</Wrapper>
				</template>
			</PopOver>
		</Container>
	</Container>
</template>
<script lang="ts">
import { ConfigUtil } from "@ollion/flow-core-config";
import { Container, Icon, PopOver, Slab, SwitchBox, Typography, Wrapper } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { debugMenuStore } from "@/modules/debug/debug-store";
import { StorageService } from "@/modules/services/storage-service";
import { UserTheme } from "@/modules/user/user-types";
import Breadcrumbs, { CrumbItem } from "@/shared/components/Breadcrumbs.vue";
import { isProductionEnvironment } from "@/utils";

export default defineComponent({
	name: "Navbar",

	components: {
		Container,
		PopOver,
		Wrapper,
		Slab,
		Typography,
		Icon,
		SwitchBox,
		Breadcrumbs
	},

	data() {
		return {
			openProfile: false,
			allowDebugMenu: !isProductionEnvironment(),
			breadcrumbs: [
				{
					qaId: "project-home",
					label: "Home",
					route: {
						name: "login"
					}
				}
			] satisfies CrumbItem[]
		};
	},

	computed: {
		...mapStores(authStore, debugMenuStore),

		getUserName() {
			return this.authStore.userSession?.identity?.traits?.email;
		},

		theme() {
			return StorageService.getTheme();
		},

		isDark: {
			get() {
				return this.theme?.theme === "f-ollion-dark" && this.theme.mode === "dark-mode";
			},

			set(value: boolean) {
				const scheme: UserTheme = {
					mode: "light-mode",
					theme: "f-ollion-light"
				};
				if (value) {
					scheme.mode = "dark-mode";
					scheme.theme = "f-ollion-dark";
				}
				StorageService.saveTheme(scheme);

				ConfigUtil.setConfig({
					theme: scheme.theme
				});
			}
		}
	},

	methods: {
		async logoutUser() {
			//Creates logout token if session is present
			const resp = await this.authStore.CREATE_LOGOUT_TOKEN();
			if (resp?.data.logout_token) {
				await this.authStore.LOGOUT_USER();
				this.$router.push({ name: "login" });
			}
		},

		redirectToHome() {
			this.$router.push({ path: "/" });
		},

		openDebugMenu() {
			this.debugMenuStore.SHOW_DEBUG_MENU();
		},

		openAccountSettings() {
			this.$router.push({ name: "settings" });
		},

		navigateBack() {
			this.$router.go(-1);
		},

		navigate(obj: CrumbItem) {
			this.$router
				.push({
					name: obj.route.name,
					params: obj.route.props
				})
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				.catch(() => {});
		}
	}
});
</script>
