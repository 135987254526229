<template>
	<PermissionsWrapper>
		<Wrapper data-qa-home-page>
			<f-div
				align="top-left"
				direction="row"
				gap="x-large"
				height="50px"
				padding="x-large"
				width="100%"
			>
				<f-text data-qa-taxonomy-table-title size="large" variant="heading" weight="medium">
					Taxonomy Table
				</f-text>
			</f-div>

			<f-div direction="row" height="hug-content">
				<f-div gap="large" padding="large" width="30%">
					<TaxonomyTabs />
				</f-div>

				<f-div align="top-right" gap="x-large" padding="x-large">
					<f-file-upload
						placeholder="Upload Control Gate and Sets"
						file-type=".xlsx"
						state="primary"
						size="small"
						:style="{ width: '300px' }"
						:loading="isUploadingGatesAndSets"
						@input="uploadControlGateSets"
					></f-file-upload>

					<Button
						:disabled="false"
						:full-width="false"
						size="normal"
						state="curved"
						type="default"
						data-qa="open-taxonomy-row-detail-btn"
						@click="showAddRowSlideOutPanel"
					>
						<Typography color="dark" type="p2" weight="book"> Add </Typography>
						<Icon
							data-qa-taxonomy-tab-button-icon="i-plus"
							name="i-plus"
							size="small"
							state="dark"
						/>
					</Button>

					<Button
						:disabled="false"
						:full-width="false"
						size="normal"
						state="curved"
						type="default"
						data-qa="taxonomy-upload-slideout-btn"
						@click="showUploadSlideout"
					>
						<Typography color="dark" type="p2" weight="book"> Upload </Typography>
						<Icon
							data-qa-taxonomy-tab-button-icon="i-upload"
							name="i-upload"
							size="small"
							state="dark"
						/>
					</Button>

					<Button
						:disabled="false"
						:full-width="false"
						size="normal"
						state="curved"
						type="default"
						data-qa="taxonomy-export-table-btn"
						@click="exportTable"
					>
						<Typography color="dark" type="p2" weight="medium"> Export </Typography>
						<Icon
							data-qa-taxonomy-tab-button-icon="i-download"
							name="i-download"
							size="small"
							state="dark"
						/>
					</Button>
				</f-div>
			</f-div>

			<TaxonomyTableContent />

			<AddRowSlideOutPanel
				:is-add-row-slide-out-panel-visible="isAddRowSlideOutPanelVisible"
				@hide-add-row-slide-out-panel="hideAddRowSlideOutPanel"
				@taxonomy-table-update-success="taxonomyTableUpdateSuccess"
			/>

			<UploadSlideoutPanel
				:show-slideout="isUploadSlideOutPanelVisible"
				@hide-slideout="hideUploadSlideout"
				@upload-success="uploadSlideoutSuccess"
			/>
		</Wrapper>
	</PermissionsWrapper>
</template>

<script lang="ts">
import { Button, Icon, Typography, Wrapper } from "@ollion/flow-vue3";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import AddRowSlideOutPanel from "@/modules/taxonomy-table/component/AddRowSlideOutPanel.vue";
import TaxonomyTableContent from "@/modules/taxonomy-table/component/TaxonomyTableContent.vue";
import {
	downloadFileFromURL,
	generateUploadTaxonomyText
} from "@/modules/taxonomy-table/taxonomy-table-utils";
import { UPLOAD_TAXONOMY_SHEET_RESPONSE } from "@/modules/taxonomy-table/taxonomy-types";
import { UploadDomainResponse } from "@/protocol/taxonomy";

import { ingestControlSetGates } from "../release-cockpit-v2/component-catalog-service";

import TaxonomyTabs from "./component/TaxonomyTabs.vue";
import UploadSlideoutPanel from "./component/UploadSlideoutPanel.vue";
import { taxonomyStore } from "./taxonomy-table-store";

export default defineComponent({
	name: "TaxonomyTable",

	components: {
		TaxonomyTableContent,
		TaxonomyTabs,
		AddRowSlideOutPanel,
		Typography,
		Wrapper,
		Button,
		UploadSlideoutPanel,
		PermissionsWrapper,
		Icon
	},

	data() {
		return {
			isUploadSlideOutPanelVisible: false,
			isAddRowSlideOutPanelVisible: false,
			isUploadingGatesAndSets: false
		};
	},

	computed: {
		...mapState(taxonomyStore, ["dataTableColumns", "dataTableRows"])
	},

	created() {
		this.updateTaxonomyDataList();
	},

	methods: {
		...mapActions(taxonomyStore, ["updateTaxonomyDataList", "export"]),
		...mapActions(notificationsStore, ["ADD_TOAST"]),

		hideAddRowSlideOutPanel() {
			this.isAddRowSlideOutPanelVisible = false;
		},

		taxonomyTableUpdateSuccess(response_data: UploadDomainResponse) {
			this.hideAddRowSlideOutPanel();
			this.ADD_TOAST({
				qaId: "toast-submit-taxonomy-table-row",
				title: "Success",
				text: `Total rows added = ${response_data.totalRowsAdded}`,
				subText: `Total nodes added = ${response_data.totalNodesAdded}`,
				status: "success"
			});
		},

		showAddRowSlideOutPanel() {
			this.isAddRowSlideOutPanelVisible = true;
		},

		hideUploadSlideout() {
			this.isUploadSlideOutPanelVisible = false;
		},

		uploadSlideoutSuccess(response_data: UPLOAD_TAXONOMY_SHEET_RESPONSE) {
			const [text, subText] = generateUploadTaxonomyText(response_data);

			this.hideUploadSlideout();
			this.ADD_TOAST({
				qaId: "toast-upload-taxonomy",
				title: "Success",
				text,
				subText,
				status: "success"
			});
		},

		showUploadSlideout() {
			this.isUploadSlideOutPanelVisible = true;
		},

		async exportTable() {
			const xlsxData = await this.export();
			const url = window.URL.createObjectURL(new Blob([xlsxData]));

			downloadFileFromURL(url, "taxonomy_export", ".xlsx");
		},

		async uploadControlGateSets(e: CustomEvent<{ value: File }>) {
			this.isUploadingGatesAndSets = true;
			const file = e.detail.value;
			await ingestControlSetGates(file);
			this.isUploadingGatesAndSets = false;
		}
	}
});
</script>
