<template>
	<EmptyState
		v-if="components.length === 0"
		:icon="{
			name: 'i-document',
			size: 'medium',
			state: 'light'
		}"
		message="No documents found for onboarding."
		data-qa="no-documents-found-error"
	/>
	<f-div v-else direction="column">
		<!--Start : multi-line slab -->
		<f-div
			v-for="(component, index) in components"
			:key="index"
			direction="column"
			gap="medium"
			width="fill-container"
		>
			<f-div
				width="fill-container"
				:state="getComponentStatementsCount(component).headerState"
				padding="medium"
				gap="medium"
				align="middle-center"
			>
				<f-div width="hug-content" align="middle-center">
					<f-pictogram
						size="x-large"
						state="primary"
						:auto-bg="true"
						variant="squircle"
						:category="undefined"
						:source="component.acronym?.toLocaleUpperCase()"
					>
					</f-pictogram>
				</f-div>

				<f-div direction="column" align="middle-center">
					<!--Start : top row -->
					<f-div data-f-id="slab-left" height="hug-content" align="top-center">
						<f-text variant="heading" size="small" weight="bold">
							{{ component.acronym }}
						</f-text>

						<f-div align="middle-right" gap="small">
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getComponentStatementsCount(component).textState"
								inline
								align="right"
							>
								{{ getComponentStatementsCount(component).compliant }}
							</f-text>
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getComponentStatementsCount(component).textState"
								inline
								>/</f-text
							>
							<f-text
								variant="para"
								size="large"
								weight="bold"
								:state="getComponentStatementsCount(component).textState"
								inline
							>
								{{ getComponentStatementsCount(component).total ?? 0 }}
							</f-text>
						</f-div>
					</f-div>

					<!--End : slab-left -->
					<f-div width="fill-container" gap="x-small" align="middle-left">
						<f-text variant="para" size="medium" weight="regular" inline state="subtle">
							{{ component?.t8?.name ?? "NA" }}
						</f-text>
						<f-divider></f-divider>
						<f-text inline variant="para" size="medium" weight="regular" state="subtle">
							{{ component?.t2?.t1?.name ?? "NA" }}
						</f-text>
						<!--End : section-subtext -->
					</f-div>
				</f-div>
			</f-div>

			<BestPracticeStatements :component="component"></BestPracticeStatements>
		</f-div>
		<!--End : multi-line slab -->
	</f-div>
</template>

<script lang="ts">
import { FTextStateProp, FDivStateProp } from "@ollion/flow-core";
import { EmptyState } from "@ollion/flow-vue3";
import { PropType, defineComponent } from "vue";

import { LzComponent } from "@/modules/lz-compliance-report/lz-compliance-report-types";

import BestPracticeStatements from "./BestPracticeStatements.vue";

export default defineComponent({
	name: "BestPracticeInScopeList",

	components: {
		BestPracticeStatements,
		EmptyState
	},

	props: {
		components: {
			type: Array as PropType<LzComponent[]>,
			required: true
		}
	},

	methods: {
		getComponentStatementsCount(component: LzComponent) {
			let bestPracticeCount = 0,
				compliantStatementsCount = 0,
				nonCompliantStatementsCount = 0;
			if (component.bestPractices.length) {
				component.bestPractices.forEach(bp => {
					bestPracticeCount += bp.statements.length;
					bp.statements.forEach(stmt => {
						stmt.evidences.forEach(ev => {
							if (["Failed", "No Data"].includes(ev.status)) {
								nonCompliantStatementsCount++;
							} else if (ev.status === "Passed") {
								compliantStatementsCount++;
							}
						});
					});
				});
			}
			const isCompliant = compliantStatementsCount === bestPracticeCount;
			return {
				total: bestPracticeCount,
				compliant: compliantStatementsCount,
				nonCompliant: nonCompliantStatementsCount,
				textState: (isCompliant ? "success" : "danger") as FTextStateProp,
				headerState: (isCompliant ? "success" : "warning") as FDivStateProp
			};
		}
	}
});
</script>
