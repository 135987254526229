<script setup lang="ts">
import { PropType, computed, ref, toRefs, onBeforeMount } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { DocumentWithStatements } from "@/modules/release-cockpit-v2/catalog-service-types";
import { approveEvidence } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { Component } from "@/protocol/cockpit";
import { ControlStatement } from "@/protocol/correlation";
import { Evidence } from "@/protocol/evidence";
import { useEvidenceType } from "@/shared/useEvidenceType";
import { captureError, generateFileName, getErrorMessage, isValidUrl } from "@/utils";

import ImageRenderer from "./ImageRenderer.vue";
import LogRenderer from "./LogRenderer.vue";
import PdfRenderer from "./PdfRenderer.vue";

defineEmits(["close"]);

const props = defineProps({
	// @todo - Use vue-acl to check this
	isApprovalFlow: Boolean,

	component: {
		type: Object as PropType<Component>,
		required: true
	},

	selectedStatementId: {
		type: String,
		required: true
	},

	viewedDocumentId: {
		type: String,
		required: false
	},

	documents: {
		type: Array as PropType<DocumentWithStatements[]>,
		required: true
	}
});

const { selectedStatementId, documents, viewedDocumentId, component } = toRefs(props);

const componentStoreInstance = componentOnboardStore();
const notificationStoreInstance = notificationsStore();
const selectedEvidenceIdx = ref<number>(0);
const isSubmitting = ref(false);
const isRejectConfirmationOpen = ref(false);
const rejectionReason = ref("");
const errorMsg = ref<string | null>(null);

// Creates a map of evidence vs the statements it satisfies
const evidenceStatementMap = computed(() => {
	const evidenceMap: Record<string, ControlStatement[]> = {};
	const evidences: Record<string, Evidence> = {};

	const allEvidences = componentStoreInstance.evidences[component.value.id!];

	documents.value.forEach(document => {
		document.statements.forEach(statement => {
			const evidencesForStatement = allEvidences?.[document.document.id!]?.[statement.id!];

			const [latestEvidence] = evidencesForStatement ?? [];

			if (latestEvidence) {
				const evidenceId = latestEvidence.id!;

				if (!evidenceMap[evidenceId]) {
					evidenceMap[evidenceId] = [];
				}

				if (!evidences[evidenceId]) {
					evidences[evidenceId] = latestEvidence;
				}

				evidenceMap[evidenceId]?.push(statement);
			}
		});
	});

	return {
		evidenceMap,
		evidences: Object.values(evidences)
	};
});

const currentEvidence = computed(() => {
	return evidenceStatementMap.value.evidences[selectedEvidenceIdx.value];
});

const currentControls = computed(() => {
	const evidenceId = currentEvidence.value?.id;
	const evidenceControls = evidenceId ? evidenceStatementMap.value.evidenceMap[evidenceId] : null;
	return evidenceControls ?? [];
});

const { currentEvidenceType } = useEvidenceType(currentEvidence);

// Find out if the current control is from an automated document
const isCurrentControlAutomated = computed(() => {
	const currentDocument = documents.value.find(
		document => document.document.id === viewedDocumentId?.value
	);
	const workflow = componentStoreInstance.workflow[component.value.id!];

	const automatedDocumentTaxons =
		workflow?.steps?.filter(step => step.type === "AUTOMATED").map(step => step.applicableValue) ??
		[];

	return automatedDocumentTaxons.includes(currentDocument?.document.applicableValue);
});

// Used to render git repo for automated evidences
const artifactGitRepo = computed(() => {
	const testRepo = component.value.artifact?.metadata?.terraformGitRepo?.pac;

	const [currentControl] = currentControls.value;

	if (testRepo && currentControl) {
		//@todo - this is a hack, we should store file path in the evidence
		// DO NOT modify this logic, frontend uses it to predict the file name for git
		// to sync with VSCode extension
		const fileNameSlug = generateFileName(currentControl.statement ?? "");

		return `${testRepo.repo}/blob/${testRepo.branch}/${testRepo.dir}/${fileNameSlug}.rego`;
	}

	return null;
});

// Find out the first evidence from the statement
onBeforeMount(() => {
	let foundStatement: ControlStatement | undefined;

	documents.value.forEach(document => {
		if (document.document.id === viewedDocumentId?.value) {
			foundStatement = document.statements.find(
				statement => statement.id === selectedStatementId.value
			);
		}
	});

	const allEvidences = componentStoreInstance.evidences[component.value.id!];

	if (!foundStatement) {
		return;
	}

	const firstEvidence = allEvidences?.[foundStatement.documentId!]?.[foundStatement.id!]?.[0];

	if (firstEvidence?.id) {
		selectedEvidenceIdx.value = evidenceStatementMap.value.evidences.findIndex(
			x => x.id === firstEvidence.id
		);
	}
});

function downloadEvidence() {
	const url = currentEvidence.value?.content ?? "";

	if (isValidUrl(url)) {
		window.open(url, "_blank");
	} else {
		notificationStoreInstance.ADD_TOAST({
			qaId: "download-evidence-failed",
			text: "Failed to download evidence",
			id: "download-evidence-failed",
			title: "Failed to download evidence",
			status: "error"
		});
	}
}

//@todo - handle failure
async function approveEvidenceCb() {
	isSubmitting.value = true;
	errorMsg.value = null;
	try {
		const { id, componentId } = currentEvidence.value ?? {};

		if (!id || !componentId) {
			return;
		}

		await approveEvidence({
			id,
			approved: true,
			approvalData: {
				comment: ""
			}
		});

		await componentStoreInstance.fetchStatementEvidences(componentId);
		await componentStoreInstance.getComponentById(componentId);

		goToNextStatement();
	} catch (error) {
		captureError(error);
		errorMsg.value = getErrorMessage(error);
	} finally {
		isSubmitting.value = false;
	}
}

async function rejectEvidenceCb() {
	isSubmitting.value = true;
	errorMsg.value = null;
	try {
		const { id, componentId } = currentEvidence.value ?? {};

		if (!id || !componentId) {
			return;
		}

		await approveEvidence({
			id,
			approved: false,
			approvalData: {
				comment: rejectionReason.value
			}
		});

		await componentStoreInstance.fetchStatementEvidences(componentId);
		await componentStoreInstance.getComponentById(componentId);

		rejectionReason.value = "";
		isRejectConfirmationOpen.value = false;

		goToNextStatement();
	} catch (error) {
		captureError(error);
		errorMsg.value = getErrorMessage(error);
	} finally {
		isSubmitting.value = false;
	}
}

function goToNextStatement() {
	if (selectedEvidenceIdx.value < evidenceStatementMap.value.evidences.length - 1) {
		selectedEvidenceIdx.value++;
	}
}
</script>

<template>
	<f-popover :open="true" size="custom(90vw,90vh)" state="secondary" @esc="$emit('close')">
		<f-div width="fill-container" height="fill-container" direction="column">
			<!-- Header -->
			<f-div
				height="hug-content"
				padding="medium"
				width="fill-container"
				align="middle-left"
				border="small solid subtle bottom"
			>
				<f-div direction="row" width="fill-container" align="middle-left" gap="large">
					<f-text
						inline
						variant="heading"
						weight="bold"
						size="small"
						data-qa="evidence-modal-title"
						>{{ currentEvidence?.name ?? "Evidence" }}</f-text
					>
				</f-div>

				<f-icon
					data-qa="close-evidence-modal"
					tooltip="Close"
					clickable
					source="i-close"
					size="medium"
					@click="$emit('close')"
				></f-icon>
			</f-div>
			<!-- End Header -->

			<f-div
				v-if="errorMsg"
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="danger"
				height="hug-content"
				border="small solid subtle bottom"
				overflow="visible"
			>
				<f-div align="middle-left" width="hug-content" gap="small">
					<f-icon source="i-alert" size="small" state="default"></f-icon>
					<f-text variant="para" size="small" weight="regular" state="default" align="left">
						{{ errorMsg }}
					</f-text>
				</f-div>
				<f-div gap="small" align="top-right">
					<f-button
						label="CLOSE"
						state="warning"
						variant="round"
						category="outline"
						size="x-small"
						data-qa="onboard-reset-yes-button"
						@click="errorMsg = null"
					></f-button>
				</f-div>
			</f-div>

			<f-div>
				<!-- Left Pane -->
				<f-div border="small solid subtle right" direction="column">
					<Transition name="fade-slide">
						<f-div
							:key="currentEvidence?.id"
							:style="{ position: 'absolute', width: '100%', height: '100%' }"
						>
							<Suspense v-if="currentEvidenceType === 'pdf' && currentEvidence">
								<PdfRenderer :content="String(currentEvidence.content)" />
							</Suspense>
							<ImageRenderer
								v-else-if="currentEvidenceType === 'image' && currentEvidence"
								:evidence="currentEvidence"
							/>
							<!-- Text file type or -->
							<!-- No file type, assume it's a string or logs -->
							<LogRenderer
								v-else-if="currentEvidence && currentEvidenceType === 'text'"
								:evidence="currentEvidence"
							/>
							<!-- Unknown file type, allow downloading it -->
							<f-div
								v-else-if="currentEvidence?.fileType"
								align="middle-center"
								direction="column"
								gap="large"
							>
								<f-icon source="i-question-filled" size="x-large" state="subtle"></f-icon>
								<f-text state="secondary" align="center"
									>We do not support rendering of
									<f-text :inline="true" weight="bold" variant="code">{{
										currentEvidence?.fileType
									}}</f-text>
									yet.</f-text
								>
								<f-text state="secondary" align="center"> Please download the evidence.</f-text>
								<f-button
									data-qa="download-evidence-button"
									:label="`Download ${currentEvidence.name}`"
									size="medium"
									category="outline"
									icon-right="i-download"
									@click="downloadEvidence"
								></f-button>
							</f-div>
						</f-div>
					</Transition>
				</f-div>
				<!-- End Left Pane -->

				<!-- Right Pane -->
				<f-div width="320px" direction="column">
					<f-div
						border="small solid subtle bottom"
						padding="medium"
						height="hug-content"
						direction="column"
						gap="small"
					>
						<template v-if="isCurrentControlAutomated && artifactGitRepo">
							<f-text size="medium" weight="medium">Tested for the rules here</f-text>
							<f-text size="small"
								><a :href="artifactGitRepo" target="_blank">{{ artifactGitRepo }}</a></f-text
							>
							<f-spacer></f-spacer>
						</template>
						<f-text v-if="currentControls.length === 1" size="medium" weight="medium"
							>Resolves 1 statement</f-text
						>
						<f-text v-else-if="currentControls.length > 1" size="medium" weight="medium"
							>Resolves {{ currentControls.length }} statements</f-text
						>
					</f-div>

					<f-div direction="column" overflow="scroll" align="top-left" class="flow-add-scrollbar">
						<f-div
							v-for="statement in currentControls"
							:key="statement.id"
							padding="medium"
							height="hug-content"
							border="small solid subtle bottom"
						>
							<f-div padding="none x-large none none" width="20%">
								<f-text size="small" ellipsis :tooltip="statement.id">{{ statement.id }}</f-text>
							</f-div>
							<f-text size="small">{{ statement.statement }}</f-text>
						</f-div>
					</f-div>

					<f-div height="hug-content" padding="medium" gap="small" align="middle-center">
						<f-icon-button
							tooltip="Go to previous statement"
							state="neutral"
							category="transparent"
							size="small"
							icon="i-chevron-double-left"
							:disabled="selectedEvidenceIdx === 0 || isSubmitting"
							@click="selectedEvidenceIdx--"
						></f-icon-button>

						<template v-if="currentEvidence?.status === 'Approved'">
							<f-div align="middle-center" gap="small">
								<f-icon source="i-tick-fill" size="small" state="success"></f-icon>
								<f-text state="success" weight="bold" size="small" inline
									>This evidence is approved.</f-text
								>
							</f-div>
						</template>

						<template v-else-if="currentEvidence?.status === 'Rejected'">
							<f-div align="middle-center" gap="small">
								<f-icon source="i-alert-fill" size="small" state="danger"></f-icon>
								<f-text state="danger" weight="bold" size="small" inline
									>This evidence is rejected.</f-text
								>
								<f-text size="x-small" inline align="center">{{ currentEvidence?.comment }}</f-text>
							</f-div>
						</template>

						<f-div v-else-if="isRejectConfirmationOpen" width="fill-container" gap="medium">
							<f-text-area
								placeholder="Please enter the reason for rejection"
								max-length="1000"
								rows="3"
								resizable
								:value="rejectionReason"
								@input="rejectionReason = $event.detail.value"
							></f-text-area>
							<f-button
								size="small"
								label="Cancel"
								variant="curved"
								:disabled="isSubmitting"
								state="neutral"
								category="outline"
								@click="isRejectConfirmationOpen = false"
							></f-button>
							<f-button
								size="small"
								label="Reject evidence"
								variant="curved"
								state="danger"
								category="outline"
								:loading="isSubmitting"
								:disabled="rejectionReason.length === 0"
								@click="rejectEvidenceCb"
							></f-button>
						</f-div>

						<f-div
							v-else-if="isApprovalFlow"
							width="fill-container"
							gap="small"
							align="middle-center"
						>
							<f-button
								size="small"
								label="Approve"
								icon-left="i-tick-fill"
								category="outline"
								state="success"
								variant="curved"
								:disabled="isSubmitting"
								@click="approveEvidenceCb"
							></f-button>

							<f-button
								data-qa-reject-button
								size="small"
								label="Reject"
								icon-left="i-close-circle"
								category="outline"
								state="danger"
								variant="curved"
								:disabled="isSubmitting"
								@click="
									rejectionReason = '';
									isRejectConfirmationOpen = true;
								"
							></f-button>
						</f-div>

						<f-icon-button
							tooltip="Go to next statement"
							state="neutral"
							category="transparent"
							size="small"
							icon="i-chevron-double-right"
							:disabled="
								selectedEvidenceIdx >= evidenceStatementMap.evidences.length - 1 || isSubmitting
							"
							@click="goToNextStatement"
						></f-icon-button>
					</f-div>
				</f-div>
				<!-- End Right Pane -->
			</f-div>
		</f-div>
	</f-popover>
</template>
