<template>
	<Container class="height-100-per" direction="column">
		<div class="flow-auth-logo-outside">
			<Icon
				name="p-stance-light-horizontal-logo"
				state="dark"
				type="filled"
				:effects="false"
				size="x-large"
				data-qa="stance-logo-home-page"
			/>
		</div>
		<AuthContainer>
			<div class="flow-stages">
				<div class="flow-stage active">
					<Section padding="top">
						<div class="center-of-container margin-bt-12 position-relative">
							<Icon
								v-if="showBackIcon"
								name="i-arrow-left"
								state="dark"
								class="position-absolute left-24"
								size="small"
								data-qa-back-icon
								@click="backClick()"
							/>
							<h2 data-qa-auth-header class="fc-dark">{{ header }}</h2>
						</div>
					</Section>
					<Section padding="vertical" class="margin-tp-24">
						<slot />
					</Section>

					<Section padding="vertical">
						<div class="center-of-container margin-tp-24">
							<p class="paragraph-2 fc-dark">{{ alternateAuthText }}</p>
						</div>
					</Section>

					<Section padding="vertical">
						<slot name="alt-auth" />
					</Section>
					<CustomLoader v-if="loading"></CustomLoader>
				</div>
			</div>
		</AuthContainer>
	</Container>
</template>

<script lang="ts">
import { AuthContainer, Icon, Section, Container } from "@ollion/flow-vue3";
import { defineComponent } from "vue";

import CustomLoader from "@/shared/components/CustomLoader.vue";

export default defineComponent({
	name: "AuthLayout",
	components: { AuthContainer, Icon, Section, Container, CustomLoader },

	props: {
		header: {
			type: String,
			required: false,
			default: ""
		},

		alternateAuthText: {
			type: String,
			required: false,
			default: ""
		},

		showBackIcon: {
			type: Boolean,
			required: false,
			default: false
		},

		loading: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	methods: {
		backClick() {
			this.$emit("backClick");
		}
	}
});
</script>

<style lang="scss" #scoped>
// Adjust height so that modal box is centered
.flow-auth-logo-outside {
	margin-top: -100px;
}
</style>
