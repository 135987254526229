<template>
	<f-div padding="medium" state="default" border="small solid subtle bottom" height="hug-content">
		<f-text
			data-qa="upload-documents-text-inside-upload-form"
			weight="medium"
			size="small"
			variant="heading"
			>Select classification</f-text
		>

		<f-icon
			data-qa="close-upload-document-panel"
			size="small"
			state="default"
			type="outlined"
			source="i-close"
			clickable
			@click="$emit('close')"
		></f-icon>
	</f-div>

	<f-div
		align="middle-center"
		direction="column"
		gap="x-large"
		state="default"
		padding="medium"
		data-qa-document-ingestion-link-taxonomy
	>
		<f-div direction="column" gap="large">
			<f-div height="hug-content">
				<f-form-builder
					gap="small"
					size="small"
					:field.prop="classificationSearchFormField"
					:values.prop="classificationSearchFormValues"
					@input="handleSearch"
				></f-form-builder>
			</f-div>
			<f-div v-if="!showClassificationTaxonForm" direction="column" align="middle-center">
				<f-icon source="i-search" size="medium" state="subtle"></f-icon>
				<f-spacer></f-spacer>
				<f-text state="subtle">Search for classification above to begin.</f-text>
				<f-text state="subtle"
					>Or
					<f-text inline @click="showClassificationTaxonForm = true"
						><a>classify manually</a></f-text
					>.</f-text
				>
			</f-div>
			<f-div v-if="showClassificationTaxonForm" align="top-center" direction="row" gap="large">
				<!-- Level 1 and Level 2 -->
				<f-div direction="column" gap="large">
					<f-text variant="heading" size="small" weight="bold">Belongs to</f-text>
					<f-div
						v-for="(item, i) in taxonomies.slice(0, 2)"
						:key="i"
						align="top-left"
						direction="column"
						gap="none"
						height="hug-content"
					>
						<f-select
							v-model="item.selected"
							:data-qa-document-ingestion-link-taxonomy-root-select="item.level"
							:disabled="item.isDisabled"
							:options="item.options"
							clear
							:icon-left="item.icon"
							size="small"
							placeholder="Select"
							searchable
						>
							<f-text slot="label" variant="heading" size="x-small">{{ item.name }}</f-text>
						</f-select>
					</f-div>
				</f-div>

				<!-- L3, L4, L5 -->
				<f-div direction="column" gap="large" height="hug-content">
					<f-text variant="heading" size="small" weight="bold">Inventory</f-text>
					<f-div
						v-for="(item, i) in taxonomies.slice(2, 5)"
						:key="i"
						direction="column"
						gap="none"
						height="hug-content"
					>
						<f-select
							v-model="item.selected"
							:data-qa-document-ingestion-link-taxonomy-inventory-select="item.level"
							:disabled="item.isDisabled"
							:options="item.options"
							clear
							:icon-left="item.icon"
							size="small"
							placeholder="Select"
							searchable
						>
							<f-text slot="label" variant="heading" size="x-small">{{ item.name }}</f-text>
						</f-select>
					</f-div>
				</f-div>

				<!-- L6,L7, L8 -->
				<f-div direction="column" gap="large" height="hug-content">
					<f-text variant="heading" size="small" weight="bold">Function as</f-text>
					<f-div
						v-for="(item, i) in taxonomies.slice(5, 8)"
						:key="i"
						direction="column"
						gap="none"
						height="hug-content"
					>
						<f-select
							v-model="item.selected"
							:data-qa-document-ingestion-link-taxonomy-use-select="item.level"
							:disabled="item.isDisabled"
							:options="item.options"
							clear
							:icon-left="item.icon"
							size="small"
							placeholder="Select"
							searchable
						>
							<f-text slot="label" variant="heading" size="x-small">{{ item.name }}</f-text>
						</f-select>
					</f-div>
				</f-div>
			</f-div>
		</f-div>

		<f-div align="middle-right" gap="medium" height="hug-content">
			<f-text v-if="hasSubmitError" data-qa="document-uploading-error" state="danger">
				An error occurred when classifying your document.
			</f-text>

			<f-button
				data-qa-document-ingestion-link-taxonomy-submit
				category="outline"
				:disabled="!proceedType"
				:state="proceedType ? 'primary' : 'neutral'"
				:loading="isLinking"
				label="Classify Document"
				size="medium"
				variant="curved"
				@click="submitClassification"
			/>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { html } from "@ollion/flow-core";
import { FormBuilderField } from "@ollion/flow-form-builder";
import { cloneDeep } from "lodash-es";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import {
	TaxonSearchItem,
	componentCatalogStore
} from "@/modules/release-cockpit-v2/component-catalog-store";
import taxonomyTableService from "@/modules/taxonomy-table/taxonomy-table-service";
import { LinkDocumentRequest } from "@/protocol/correlation";
import { captureError } from "@/utils";

import { documentIngestionStore } from "../document-ingestion-store";

type ClassificationSearchFormValues = {
	suggest?: string;
};

export default defineComponent({
	name: "TaxonomyModel",

	props: {
		documentId: {
			type: String as PropType<string>
		}
	},

	emits: ["close", "taxonomy"],

	data() {
		return {
			taxonomies: getTaxonomies(),
			oldTaxonomies: [] as Taxonomy[],
			rootLevel: 1,
			inventoryLevel: 3,
			useLevel: 6,
			lastEnabledTaxonIdx: 0,
			isLinking: false,
			hasSubmitError: false,
			classificationSearchFormValues: {} as ClassificationSearchFormValues,
			showClassificationTaxonForm: false,
			searchString: ""
		};
	},

	computed: {
		...mapStores(notificationsStore, documentIngestionStore, componentCatalogStore),

		proceedType(): boolean {
			const requiredLevels = [1, 3, 6];
			return this.taxonomies.some(
				taxonomy => requiredLevels.includes(taxonomy.level) && taxonomy.selected
			);
		},

		classificationSearchFormField(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: {
					suggest: {
						type: "suggest",
						qaId: "suggest",
						iconLeft: "i-search",
						placeholder: "Search by classification, e.g. Database",
						label: { title: `Classify this document` },
						suggestions: this.searchSuggestions,
						optionsMaxHeight: "350px",
						suggestWhen: () => true
					}
				}
			};
		},

		searchSuggestions() {
			if (!this.searchString) {
				return Object.values(this.componentCatalogStore.searchIndex)
					.map(item => {
						return this.suggestionTemplate(item);
					})
					.slice(0, 50);
			}

			const results = this.componentCatalogStore.duplicateTaxonomyFuse.search(
				this.searchString.replaceAll("/", "")
			);

			const matchingItems = results
				.filter(result => {
					const score = result.score ?? 0;
					return score <= 0.003;
				})
				.map(taxonResult => this.suggestionTemplate(taxonResult.item));

			if (matchingItems.length > 0) {
				return matchingItems.slice(0, 50);
			} else {
				return [this.suggestionTemplate({ displayStr: "No results found", mergedTaxon: {} })];
			}
		}
	},

	watch: {
		// whenever user picks a taxonomy from a dropdown, this function will run
		taxonomies: {
			handler() {
				this.updateTaxonomyHelper();
			},

			deep: true
		},

		searchString() {
			const matchingItem = this.componentCatalogStore.searchIndex[this.searchString]?.mergedTaxon;

			if (matchingItem) {
				const L1Index = this.taxonomies.findIndex(taxon => taxon.level === 1);
				const L2Index = this.taxonomies.findIndex(taxon => taxon.level === 2);
				const L6Index = this.taxonomies.findIndex(taxon => taxon.level === 6);
				const L7Index = this.taxonomies.findIndex(taxon => taxon.level === 7);
				const L8Index = this.taxonomies.findIndex(taxon => taxon.level === 8);

				this.updateTaxonomyHelper();
				this.taxonomies[L1Index]!.selected = matchingItem.taxonomy1 ?? "";
				this.updateTaxonomyHelper();
				this.taxonomies[L2Index]!.selected = matchingItem.taxonomy2 ?? "";
				this.updateTaxonomyHelper();
				this.taxonomies[L6Index]!.selected = matchingItem.taxonomy6 ?? "";
				this.updateTaxonomyHelper();
				this.taxonomies[L7Index]!.selected = matchingItem.taxonomy7 ?? "";
				this.updateTaxonomyHelper();
				this.taxonomies[L8Index]!.selected = matchingItem.taxonomy8 ?? "";
				this.updateTaxonomyHelper();
				this.showClassificationTaxonForm = true;
			}
		}
	},

	mounted() {
		this.fetchSelfClassifications();
		this.componentCatalogStore.fetchTaxonomies();
	},

	methods: {
		async fetchSelfClassifications() {
			const root = await taxonomyTableService.getTaxonomies(this.rootLevel, {
				name: "domain"
			});
			const inventory = await taxonomyTableService.getTaxonomies(this.inventoryLevel, {
				name: "tbm"
			});
			const use = await taxonomyTableService.getTaxonomies(this.useLevel, { name: "class" });
			const { domains } = root.data;
			const { TBMs } = inventory.data;
			const { classes } = use.data;
			const L1Index = this.taxonomies.findIndex(taxon => taxon.level === 1);
			const L3Index = this.taxonomies.findIndex(taxon => taxon.level === 3);
			const L6Index = this.taxonomies.findIndex(taxon => taxon.level === 6);

			this.taxonomies[L1Index]!.options = domains;
			this.taxonomies[L3Index]!.options = TBMs;
			this.taxonomies[L6Index]!.options = classes;
		},

		updateTaxonomyHelper() {
			const idx: number = this.findChangedTaxonomy() as number;
			// Reset the higher levels if user change the middle classification halfway
			// Reset one level above
			if (
				this.taxonomies[idx]?.level === 1 ||
				this.taxonomies[idx]?.level === 4 ||
				this.taxonomies[idx]?.level === 7
			) {
				for (let i = idx + 1; i < idx + 2; i++) {
					this.taxonomies[i]!.selected = "";
					this.taxonomies[i]!.isDisabled = true;
				}
			}
			// Reset 2 levels above
			if (this.taxonomies[idx]?.level === 3 || this.taxonomies[idx]?.level === 6) {
				for (let i = idx + 1; i < idx + 3; i++) {
					this.taxonomies[i]!.selected = "";
					this.taxonomies[i]!.isDisabled = true;
				}
			}
			// Enable user to select the next classification and fetch the next list of options
			if (idx >= 0 && idx < this.taxonomies.length - 1) {
				this.taxonomies[idx + 1]!.isDisabled = false;
				this.lastEnabledTaxonIdx = idx + 1;
				this.fetchChildClassifications(idx + 1, this.taxonomies[idx]?.selected ?? "");
			}

			this.oldTaxonomies = cloneDeep(this.taxonomies);
		},

		findChangedTaxonomy() {
			if (this.oldTaxonomies.length === 0) {
				return -1;
			}

			// Find the index of the classification that was modified
			for (let i = 0; i < this.taxonomies.length; i++) {
				if (this.taxonomies[i]!.selected !== this.oldTaxonomies[i]!.selected) {
					return i;
				}
			}
		},

		async fetchChildClassifications(idx: number, taxonomy: string) {
			if (this.taxonomies[idx]?.level === 2) {
				const res = await taxonomyTableService.getChildTaxonomies(
					idx + 1,
					this.encodeURIComponent(taxonomy),
					{ name: "domain" }
				);
				const { domains } = res.data;
				if (domains) {
					this.taxonomies[idx]!.options = domains;
				}
			}

			if (this.taxonomies[idx]?.level === 4 || this.taxonomies[idx]?.level === 5) {
				const res = await taxonomyTableService.getChildTaxonomies(
					idx + 1,
					this.encodeURIComponent(taxonomy),
					{ name: "tbm" }
				);
				const { TBMs } = res.data;
				if (TBMs) {
					this.taxonomies[idx]!.options = TBMs;
				}
			}

			if (this.taxonomies[idx]?.level === 7 || this.taxonomies[idx]?.level === 8) {
				const res = await taxonomyTableService.getChildTaxonomies(
					idx + 1,
					this.encodeURIComponent(taxonomy),
					{ name: "class" }
				);
				const { classes } = res.data;
				if (classes) {
					this.taxonomies[idx]!.options = classes;
				}
			}
		},

		// Escaping special characters manually for proper data ingested into backend
		encodeURIComponent(taxonomy: string): string {
			if (taxonomy) {
				taxonomy = taxonomy.replace(" ", "+");
				taxonomy = taxonomy.replace("&", "%26");
				taxonomy = taxonomy.replace(".", "%2C");
			}
			return taxonomy;
		},

		async submitClassification() {
			const { documentId } = this;

			const taxonomies: LinkDocumentRequest["taxonomy"] = this.taxonomies
				.filter(taxon => Boolean(taxon.selected))
				.map(taxon => {
					return {
						taxonomyId: taxon.selected,
						taxonomyLevel: taxon.level
					};
				});

			// We don't have a document ID, just emit the event and close the modal
			if (!documentId) {
				this.$emit("taxonomy", taxonomies);
				this.$emit("close");
				return;
			}

			try {
				this.isLinking = true;
				this.hasSubmitError = false;

				await this.documentIngestionStore.LINK_TAXONOMY(documentId, taxonomies);

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-document-added",
					title: "Document added successfully",
					text: "The document you have uploaded has been added successfully.",
					status: "success"
				});

				this.$emit("close");
			} catch (err) {
				captureError(err);
				this.hasSubmitError = true;
			} finally {
				this.isLinking = false;
			}
		},

		handleSearch(event: CustomEvent) {
			this.searchString = event.detail.suggest ?? "";
		},

		suggestionTemplate(taxon: TaxonSearchItem) {
			return {
				value: taxon.displayStr,
				template: () => html`
					<f-div padding="small" direction="column">
						<f-text variant="para" size="small" weight="medium" highlight="${this.searchString}">
							${taxon.displayStr}</f-text
						>
					</f-div>
				`,

				toString: () => taxon.displayStr
			};
		}
	}
});

function getTaxonomies() {
	return [
		{
			selected: "",
			icon: "i-domain",
			isDisabled: false,
			options: [],
			level: 1,
			name: "Domain",
			taxon: "t1"
		},
		{
			selected: "",
			icon: "i-division",
			isDisabled: true,
			options: [],
			level: 2,
			name: "Division",
			taxon: "t2"
		},
		{
			selected: "",
			icon: "i-type",
			isDisabled: false,
			options: [],
			level: 3,
			name: "Type",
			taxon: "t3"
		},
		{
			selected: "",

			icon: "i-category",
			isDisabled: true,
			options: [],
			level: 4,
			name: "Category",
			taxon: "t4"
		},
		{
			selected: "",
			icon: "i-name",
			isDisabled: true,
			options: [],
			level: 5,
			name: "Name",
			taxon: "t5"
		},
		{
			selected: "",
			icon: "i-class",
			isDisabled: false,
			options: [],
			level: 6,
			name: "Class",
			taxon: "t6"
		},
		{
			selected: "",
			icon: "i-family",
			isDisabled: true,
			options: [],
			level: 7,
			name: "Family",
			taxon: "t7"
		},
		{
			selected: "",

			icon: "i-canonical",
			isDisabled: true,
			options: [],
			level: 8,
			name: "Canonical Name",
			taxon: "t8"
		}
	] satisfies Taxonomy[];
}

interface Taxonomy {
	selected?: string;
	icon: string;
	isDisabled: boolean;
	options: string[];
	level: number;
	name: string;
	taxon?: string;
}
</script>
