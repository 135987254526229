<template>
	<f-div width="100%" padding="none">
		<f-div v-if="isLoading" width="fill-container">
			<CustomLoader />
		</f-div>
		<ControlComponentDetail
			v-else-if="isControlSelected && selectedControl"
			:selected-control="selectedControl"
		/>
		<f-div v-else padding="none">
			<EmptyState
				v-if="rows.length <= 0"
				message="Compliance control details"
				subtitle="Control details not available"
				shape="circle"
			>
			</EmptyState>
			<f-table-schema
				v-else
				:data.prop="tableSchema"
				:highlight-selected="true"
				:highlight-hover="true"
				selectable="none"
				variant="stripped"
				size="small"
				:sticky-header="true"
				:rows-per-page="10"
				sort-by="name"
				sort-order="asc"
				:show-search-bar="false"
				@row-click="toggleRowDetails"
			>
			</f-table-schema>
		</f-div>
	</f-div>
</template>

<script lang="ts">
import { FTableSchemaDataRow } from "@ollion/flow-table";
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores, mapState } from "pinia";
import { defineComponent } from "vue";

import { CompositeControls } from "@/protocol/correlation";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { captureError } from "@/utils";

import { getControlsByComponentId } from "../../compliance-report-service";
import { complianceReportStore } from "../../compliance-report-store";

import ControlComponentDetail from "./ControlComponentDetail.vue";

export default defineComponent({
	name: "ControlComponentList",

	components: {
		ControlComponentDetail,
		EmptyState,
		CustomLoader
	},

	props: {
		isControlSelected: {
			type: Boolean,
			default: false
		}
	},

	emits: ["update-control-selected-status"],

	data() {
		return {
			selectedControl: null as CompositeControls | null,
			compositeControls: {} as Record<string, CompositeControls>,
			rows: [] as FTableSchemaDataRow[],
			isLoading: false
		};
	},

	computed: {
		...mapStores(complianceReportStore),

		...mapState(complianceReportStore, {
			selectedComponent: state => state.selectedComponent
		}),

		tableSchema() {
			const tableData = {
				header: {
					practiceId: {
						value: "Best Practice ID",
						width: "30%"
					},

					name: {
						value: "Best Practice Name",
						width: "70%"
					}
				},

				rows: this.rows
			};
			return tableData;
		}
	},

	watch: {
		selectedComponent: {
			immediate: true,

			handler(current, prev) {
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (current && prev && current.overviewCard.compId !== prev.overviewCard.compId) {
					this.getControlsListByComponent();
				}
			}
		}
	},

	mounted() {
		if (this.selectedComponent.overviewCard.compId) {
			this.getControlsListByComponent();
		}
	},

	methods: {
		async getControlsListByComponent() {
			this.isLoading = true;
			try {
				const response = await getControlsByComponentId({
					componentId: this.selectedComponent.overviewCard.compId
				});
				const controls = response.compositeControls ?? {};
				this.compositeControls = controls;

				this.setTableRows(controls);
				/* Update store to get selected component's details */
				this.complianceReportStore.setSelectedComponentDetails(response);
				this.isLoading = false;
			} catch (error) {
				captureError(error);
				this.resetComponentPanel();
			}
		},

		resetComponentPanel() {
			this.compositeControls = {};
			this.rows = [];
			this.isLoading = false;
			this.selectedControl = null;
			this.$emit("update-control-selected-status", false);
		},

		setTableRows(compositeControls: Record<string, CompositeControls>) {
			this.rows = Object.entries(compositeControls).map(
				([compositeControlId, compositeControl]) => {
					const { controls } = compositeControl;
					return {
						id: compositeControlId,
						selected: Boolean(this.selectedControl?.controls?.id === compositeControlId),
						data: {
							id: { value: compositeControlId },
							practiceId: {
								value: compositeControlId
							},
							name: {
								value: controls?.statement
							}
						}
					};
				}
			);
		},

		toggleRowDetails(row: CustomEvent) {
			const { data } = row.detail;
			this.selectedControl =
				Object.values(this.compositeControls).find(compositeControl => {
					return compositeControl.controls?.id === data.id.value;
				}) ?? null;

			this.$emit("update-control-selected-status", true);
		}
	}
});
</script>
