import { App } from "vue";

import { registerAuthRoutes } from "@/modules/auth/auth-routes";
import { registerComplianceReportRoutes } from "@/modules/compliance-report/compliance-report-routes";
import { registerConstellationMapRoutes } from "@/modules/constellation-map/constellation-map-routes";
import { registerDocumentComparisonRoutes } from "@/modules/document-comparison/document-comparison-routes";
import { registerDocumentRoutes } from "@/modules/document-ingestion/document-ingestion-routes";
import { registeEvidenceRoutes } from "@/modules/evidence/evidence-routes";
import { registerLzComplianceReportRoutes } from "@/modules/lz-compliance-report/lz-compliance-report-routes";
import { registerManageMembersRoutes } from "@/modules/manage-members/manage-members-routes";
import { registerReleaseCockpitV2Routes } from "@/modules/release-cockpit-v2/release-cockpit-v2-routes";
import routerModule from "@/modules/router/router-module";

import { registerSettingsRoutes } from "./modules/settings/settings-routes";
import { pinia } from "./modules/store";
import { registerTaxonomyTableRoutes } from "./modules/taxonomy-table/taxonomy-table-routes";
import { ENVIRONMENTS, getEnvironment, initSentry } from "./utils";

export async function startApp(app: App<Element>) {
	const { router } = routerModule;
	registerDocumentRoutes(router);
	registerDocumentComparisonRoutes(router);
	registerComplianceReportRoutes(router);
	registerConstellationMapRoutes(router);
	registerAuthRoutes(router);
	registerReleaseCockpitV2Routes(router);
	registerTaxonomyTableRoutes(router);
	registerSettingsRoutes(router);
	registeEvidenceRoutes(router);
	registerManageMembersRoutes(router);
	registerLzComplianceReportRoutes(router);
	app.use(pinia);
	routerModule.install(app);

	if (getEnvironment() !== ENVIRONMENTS.DEVELOPMENT) {
		initSentry({
			app,
			router
		});
	}

	await router.isReady();

	app.mount("#app");

	return { router };
}
