<template>
	<DropdownMenu :width="238" :open="openProjectFilter" class="fl-right position-fixed">
		<template #trigger>
			<Button
				v-if="!openProjectFilter"
				data-qa-hide-constellation-map-filter
				state="curved"
				type="primary"
				class="margin-rt-auto rotated custom-margin"
				@click="openProjectFilter = !openProjectFilter"
			>
				Mapping Filter
			</Button>
		</template>
		<Container direction="row" align="space-between" padding="8">
			<Typography data-qa-mapping-filter-text disable-hover>Mapping Filter</Typography>
			<Icon
				data-qa-swap-constellation-map-filter
				name="i-sort"
				size="small"
				tooltip="Swap"
				@click="swapFilter"
			></Icon>
		</Container>
		<Container
			direction="column"
			class="height-100-per add-scroll align-items-start"
			align="left"
			:gap="10"
			:padding="10"
		>
			<Container class="width-100-per" overflow="visible" direction="column" :gap="0" :padding="0">
				<Container direction="row" align="space-between center" padding="0">
					<Typography weight="medium" type="p2" color="dark">Start Point</Typography>
					<Tag
						data-qa-clear-constellation-map-filter
						background="var(--element-light)"
						clickable
						@click="clearAll"
					>
						<Typography color="light" link weight="medium" type="p3">Clear all</Typography>
					</Tag>
				</Container>
				<FormField class="width-100-per height-100-per">
					<template #label>
						<p class="paragraph-2 fc-dark">Type</p>
					</template>
					<Multiselect
						v-model="mapStartType"
						data-qa-select-start-type
						:options="mapTypes"
						placeholder="Select Type"
						label="label"
						:show-labels="false"
						@select="onSelectOfType($event, `start`)"
					/>
				</FormField>
			</Container>

			<Container
				direction="column"
				class="height-100-per width-100-per"
				overflow="visible"
				:padding="0"
				:gap="0"
			>
				<FormField class="width-100-per">
					<template #label>
						<p class="paragraph-2 fc-dark">Point</p>
					</template>
					<Multiselect
						v-model="mapStartPoint"
						data-qa-select-start-point
						:options="mapStartPoints"
						:loading="sPointLoading"
						:disabled="sPointLoading"
						type="curved"
						placeholder="Select Point"
						label="type"
						:show-labels="false"
						@select="onSelectOfStartPoint($event)"
					/>
				</FormField>
			</Container>
			<div class="width-100-per">
				<DragDropList
					v-model:list="inbetweenPoints"
					item-key="type"
					:handle="true"
					@drag-end="generateMap"
				>
					<template #default="{ element, index }">
						<Container
							:padding="0"
							:gap="10"
							direction="column"
							class="height-100-per width-100-per"
						>
							<Container
								direction="column"
								class="height-100-per width-100-per margin-tp-10"
								overflow="visible"
								:padding="0"
								:gap="2"
							>
								<Container direction="row" align="space-between" padding="0">
									<Typography weight="medium" type="p2" color="dark"
										>In-between Point {{ index + 1 }}</Typography
									>
									<Container direction="row" :gap="10" :padding="0">
										<Icon
											data-qa-drag-inbetween-point
											size="small"
											type="filled"
											name="i-drag-vertical"
											tooltip="Drag"
											class="handle"
										></Icon>
										<Icon
											data-qa-remove-inbetween-point
											name="i-close"
											state="white"
											size="x-small"
											:effects="false"
											type="filled"
											tooltip="Remove"
											@click="removePoint(index)"
										/>
									</Container>
								</Container>
								<FormField class="width-100-per">
									<template #label>
										<p class="paragraph-2 fc-dark">Type</p>
									</template>
									<Multiselect
										v-model="element.type"
										data-qa-select-inbetween-point-type
										:options="mapTypes"
										type="curved"
										label="label"
										placeholder="Select Type"
										:show-labels="false"
										@select="onSelectOfPointType($event, index)"
									/>
								</FormField>
							</Container>
							<Container
								direction="column"
								class="height-100-per width-100-per"
								overflow="visible"
								:padding="0"
								:gap="0"
							>
								<FormField class="width-100-per">
									<template #label>
										<p class="paragraph-2 fc-dark">Point</p>
									</template>
									<Multiselect
										v-model="element.point"
										data-qa-select-inbetween-point
										:options="element.points"
										:loading="element.loading"
										:disabled="element.loading"
										type="curved"
										placeholder="Select Point"
										label="type"
										:show-labels="false"
										@select="onSelectOfPoint($event, index)"
									/>
								</FormField>
							</Container>
						</Container>
					</template>
				</DragDropList>
			</div>
			<Container
				v-if="inbetweenPoints.length < pointsLimit"
				direction="column"
				class="height-100-per width-100-per"
				overflow="visible"
				:gap="5"
				:padding="0"
			>
				<Button
					data-qa-add-inbetween-point
					type="default"
					state="curved"
					full-width
					class="addpoint"
					@click="addPoints()"
				>
					<Typography type="p2" color="light">+ {{ addButtonText }} point</Typography>
				</Button>
			</Container>
			<Container
				direction="column"
				class="height-100-per width-100-per margin-tp-10"
				overflow="visible"
				:padding="0"
				:gap="5"
			>
				<Typography weight="medium" type="p2" color="dark">End Point</Typography>
				<FormField class="width-100-per">
					<template #label>
						<p class="paragraph-2 fc-dark">Type</p>
					</template>
					<Multiselect
						v-model="mapEndType"
						data-qa-select-endpoint-type
						:options="mapTypes"
						type="curved"
						label="label"
						placeholder="Select Type"
						:show-labels="false"
						@select="onSelectOfType($event, `end`)"
					/>
				</FormField>
			</Container>
			<Container
				direction="column"
				class="height-100-per width-100-per"
				overflow="visible"
				:padding="0"
				:gap="0"
			>
				<FormField class="width-100-per">
					<template #label>
						<p class="paragraph-2 fc-dark">Point</p>
					</template>
					<Multiselect
						v-model="mapEndPoint"
						data-qa-select-endpoint-point
						:options="mapEndPoints"
						:loading="ePointLoading"
						:disabled="ePointLoading"
						type="curved"
						placeholder="Select Point"
						label="type"
						:show-labels="false"
						@select="onSelectOfEndPoint($event)"
					/>
				</FormField>
			</Container>
		</Container>
		<Divider :size="4" class="padding-0"></Divider>
		<Container class="height-100-per width-100-per" :gap="0">
			<Button
				data-qa-hide-constellation-map-filter
				state="curved"
				type="default"
				class="margin-rt-auto width-100-per"
				full-width
				@click="openProjectFilter = !openProjectFilter"
			>
				<Container direction="row" class="height-100-per width-100-per" align="left">
					<Icon name="i-chevron-left" state="white" size="small" :effects="false" type="filled" />
					<Typography type="h4" weight="medium" color="dark">Hide</Typography>
				</Container>
			</Button>
		</Container>
	</DropdownMenu>
</template>
<script lang="ts">
import {
	Container,
	Multiselect,
	Typography,
	DropdownMenu,
	Button,
	Icon,
	FormField,
	Tag,
	Divider,
	DragDropList
} from "@ollion/flow-vue3";
import { defineComponent } from "vue";

import { toTitleCase } from "@/utils";

import { MapPointType, MapType, Payload, MapTypes, InPointsType } from "../constellation-map-types";
import { getByType, getTypes } from "../correlation-service";

import { setColors } from "./typeColors";

export default defineComponent({
	name: "MapFilter",

	components: {
		FormField,
		Multiselect,
		Typography,
		Container,
		Icon,
		DropdownMenu,
		Button,
		Tag,
		Divider,
		DragDropList
	},

	emits: ["setErrorMsg", "clearAll", "generate"],

	data() {
		return {
			loadingKey: false,
			openProjectFilter: true,
			mapStartType: "" as MapType | string,
			mapStartPoint: null as MapPointType | null,
			mapEndType: "" as MapType | string,
			mapEndPoint: null as MapPointType | null,
			mapStartPoints: [] as Array<MapPointType>,
			mapEndPoints: [] as Array<MapPointType>,
			mapTypes: [] as Array<MapTypes>,
			sPointLoading: false,
			ePointLoading: false,
			inbetweenPoints: [] as Array<InPointsType>,
			pointsLimit: 7
		};
	},

	computed: {
		addButtonText() {
			return (this.inbetweenPoints as Array<InPointsType>).length > 0 ? "Add" : "In-between";
		}
	},

	mounted() {
		this.getTypes();
	},

	methods: {
		getQueries() {
			//Get queries from params
			const queryParams = this.$route.query;
			//check if type and params is array else create new array
			const typeValues = Array.isArray(queryParams.type) ? queryParams.type : [queryParams.type];
			const pointValues = Array.isArray(queryParams.point)
				? queryParams.point
				: [queryParams.point];
			if (Object.keys(queryParams).length === 0) {
				return;
			}
			if (typeValues.length !== pointValues.length) {
				this.$emit(
					"setErrorMsg",
					"Invalid query parameters. Please select another in filter to generate map"
				);
				return;
			}
			if (typeValues.length < 1) {
				this.$emit("setErrorMsg", "At least one type and point pairs are required");
				return;
			}
			this.inbetweenPoints = [];
			//Loop types and points from query params
			for (let i = 0; i < typeValues.length; i++) {
				let type = typeValues[i] as string;
				type = decodeURIComponent(type).split(" ").join(""); //Decode type from url and remove spaces
				const point = pointValues[i] as string;
				const pointObj = { label: type, type: point };
				const typeObj = { attribute: "name", label: type, type };
				if (i === 0) {
					//Start point
					this.mapStartType = typeObj;
					this.mapStartPoint = pointObj;
					this.onSelectOfType(typeObj, "start");
				} else if (i === typeValues.length - 1) {
					//End point
					this.mapEndType = typeObj;
					this.mapEndPoint = pointObj;
					this.onSelectOfType(typeObj, "end");
				} else {
					//Inbetween points
					this.inbetweenPoints.push({
						point: pointObj,
						type: typeObj,
						points: [],
						loading: false
					});
					this.onSelectOfPointType(typeObj, this.inbetweenPoints.length - 1, pointObj);
				}
			}
			this.generateMap();
		},

		async getTypes() {
			const response = await getTypes();
			if (!response.categories?.length) {
				return;
			}
			setColors(response.categories);
			response.categories.forEach(category => {
				this.mapTypes.push({
					label: toTitleCase(category),
					type: category,
					attribute: "name"
				});
			});
			this.getQueries();
		},

		// eslint-disable-next-line
		async onSelectOfType(selectedData: MapType, type: string): Promise<void> {
			// Check if type is start type or end type and update data accordingly
			// const loadingKey = type === "start" ? "sPointLoading" : "ePointLoading";
			// const selectedPointsKey = type === "start" ? "mapStartPoint" : "mapEndPoint";
			const mapPointsKey = type === "start" ? "mapStartPoints" : "mapEndPoints";

			this.loadingKey = true;

			const response = await getByType({
				nodeType: selectedData.type
			});
			this[mapPointsKey] =
				response.nodes?.map(node => ({
					label: selectedData.label,
					type: String(node.props?.[selectedData.attribute])
				})) ?? [];

			this.loadingKey = false;
			this.$emit("setErrorMsg", null);
		},

		async onSelectOfPointType(
			selectedData: MapType,
			pointIndex: number,
			point?: MapPointType
		): Promise<void> {
			const payload = {
				nodeType: selectedData.type
			};
			const inbetweenPoint = this.inbetweenPoints[pointIndex];
			if (inbetweenPoint) {
				inbetweenPoint.loading = true;
				inbetweenPoint.point = point ?? null;
				const response = await getByType(payload);

				inbetweenPoint.points =
					response.nodes?.map(node => ({
						label: selectedData.label,
						type: String(node.props?.[selectedData.attribute] ?? "")
					})) ?? [];

				inbetweenPoint.loading = false;
			}
		},

		onSelectOfStartPoint(selectedData: MapPointType): void {
			this.mapStartPoint = selectedData;
			this.generateMap();
		},

		onSelectOfEndPoint(selectedData: MapPointType): void {
			this.mapEndPoint = selectedData;
			this.generateMap();
		},

		onSelectOfPoint(selectedData: MapPointType, pointIndex: number): void {
			if (this.inbetweenPoints[pointIndex]) {
				this.inbetweenPoints[pointIndex]!.point = selectedData;
				this.generateMap();
			}
		},

		swapFilter() {
			if (!this.mapEndType || !this.mapStartType || !this.mapEndPoint || !this.mapStartPoint) {
				return;
			}
			[this.mapEndType, this.mapStartType] = [this.mapStartType, this.mapEndType];
			[this.mapEndPoint, this.mapStartPoint] = [this.mapStartPoint, this.mapEndPoint];
			this.inbetweenPoints = this.inbetweenPoints.length > 0 ? this.inbetweenPoints.reverse() : [];
			this.generateMap();
		},

		clearAll() {
			this.mapStartType = "";
			this.mapStartPoint = null;
			this.mapEndType = "";
			this.mapEndPoint = null;
			this.mapStartPoints = [];
			this.mapEndPoints = [];
			this.inbetweenPoints = [];
			this.$emit("clearAll");
		},

		addPoints() {
			this.inbetweenPoints.push({
				type: {},
				points: [],
				point: null,
				loading: false
			});
		},

		removePoint(i: number) {
			this.inbetweenPoints.splice(i, 1);
			this.generateMap();
		},

		generateMap() {
			const payload: Payload = {
				startPoint: {},
				endPoint: {}
			};
			if (this.mapStartPoint && this.mapStartType) {
				payload.startPoint.startType = this.mapStartType;
				payload.startPoint.startPoint = this.mapStartPoint;
			}
			if (this.mapEndPoint && this.mapEndType) {
				payload.endPoint.endType = this.mapEndType;
				payload.endPoint.endPoint = this.mapEndPoint;
			}
			if (this.inbetweenPoints.length > 0) {
				payload.inbetweenPoints = [...this.inbetweenPoints];
			}
			this.$emit("generate", payload);
		}
	}
});
</script>
<style scoped>
.addpoint {
	border: 2px dashed var(--gray-300) !important;
	background-color: var(--gray-500) !important;
}
/* Override scroll styling for filter */
.add-scroll {
	max-height: 56vh !important;
	overflow-y: auto !important;
}
.add-scroll::-webkit-scrollbar {
	display: block !important;
	width: 4px;
}
.add-scroll:hover::before {
	background-color: var(--gray-500) !important;
}
</style>
