const colors: string[] = [
	"var(--primary-400)",
	"var(--color2-300)",
	"var(--success-400)",
	"var(--color6-300)",
	"var(--warning-400)",
	"var(--color3-300)",
	"var(--danger-500)",
	"var(--color2-200)",
	"var(--warning-500)",
	"var(--color5-300)",
	"var(--success-500)",
	"var(--color3-300)",
	"var(--secondary-500)",
	"var(--primary-500)",
	"var(--color6-200)",
	"#aa2e25",
	"#75330b",
	"#6d1b7b",
	"#3c5236",
	"#23544b",
	"var(--gray-700)",
	"#3f50b5",
	"#b23c17",
	"#b26a00",
	"#ab003c",
	"#618833",
	"#b28704",
	"#0276aa",
	"#b2a429",
	"#00695f",
	"#8f9a27",
	"#357a38",
	"#008394",
	"#a31545",
	"#1769aa",
	"#2c387e",
	"#6d1b7b",
	"#aa2e25",
	"#6e6d19",
	"#a74b00",
	"#205723",
	"#482880",
	"#b26400",
	"#3b6120",
	"#972e0e",
	"#ae7519",
	"#004940"
];
const typeColors: {
	[key: string]: string;
} = {};
export const setColors = (nodeTypes: string[]) => {
	nodeTypes.forEach((type: string, index: number) => {
		typeColors[type] = colors[index] ?? "var(--primary-400)";
	});
};

export default typeColors;
