<template>
	<f-div direction="column" width="fill-container" height="fill-container">
		<f-div direction="column" width="fill-container">
			<f-div
				v-if="!isTestRepoForm"
				padding="none medium medium medium"
				gap="large"
				width="100%"
				align="top-left"
				height="hug-content"
				border="small solid secondary bottom"
			>
				<f-text variant="para" size="medium" weight="medium" state="secondary" align="left">
					Terraform Module
				</f-text>
			</f-div>

			<f-div
				v-if="isTestRepoForm && !canAddTestModule"
				width="fill-container"
				padding="medium"
				direction="row"
				gap="none"
				state="danger"
				height="hug-content"
				border="small solid subtle bottom"
				overflow="visible"
			>
				<f-div align="middle-left" width="hug-content" gap="small">
					<f-icon source="i-alert" size="small" state="default"></f-icon>
					<f-text variant="para" size="small" weight="regular" state="default" align="left">
						You need to add a Terraform module before adding a test module.
					</f-text>
				</f-div>
			</f-div>

			<!-- CREATE form -->
			<f-div padding="large" align="top-center">
				<f-form-builder
					ref="formBuilder"
					:field.prop="formFields"
					:values.prop="formValues"
					@input="handleInput"
					@state-change="formState = $event.detail"
				/>
			</f-div>

			<!-- Error message -->
			<f-div
				v-if="submitError"
				padding="medium"
				height="hug-content"
				gap="medium"
				direction="row"
				width="100%"
				state="danger"
			>
				<f-text size="small" weight="regular" variant="para" color="danger" data-qa-submit-err-text>
					{{ submitError }}
				</f-text>
				<f-div align="top-right" width="hug-content">
					<f-icon
						class="cursor-pointer"
						source="i-close"
						size="x-small"
						data-qa-error-close-icon
						data-qa-close-control-gate-popover-error-popover
						@click="submitError = ''"
					>
					</f-icon>
				</f-div>
			</f-div>
		</f-div>

		<!-- footer -->
		<f-div
			padding="large"
			width="fill-container"
			height="hug-content"
			gap="medium"
			align="middle-left"
		>
			<f-button
				:label="buttonLabel"
				variant="curved"
				size="small"
				category="outline"
				state="neutral"
				:disabled="!formState?.isValid"
				:loading="isLoading"
				data-qa-add-new-terraform-module-btn
				@click="addNewTerraform"
			></f-button>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { FFormBuilder, FormBuilderField, FormBuilderState } from "@ollion/flow-form-builder";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { authStore } from "@/modules/auth/auth-store";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import {
	createArtifact,
	updateArtifact
} from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import {
	ArtifactType,
	Component,
	CreateArtifactRequest,
	RepoConfig,
	TerraformGitRepo
} from "@/protocol/cockpit";
import { parseGitUrl, validRelativeDirectoryRule } from "@/utils";

type FormValues = {
	gitUrl: string;
	branch: string;
	directoryPath: string;
};

export default defineComponent({
	name: "TerraformFormComponent",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		isTestRepoForm: {
			type: Boolean,
			default: false
		},

		artifactData: {
			type: Object as PropType<TerraformGitRepo>
		}
	},

	emits: ["artifact-created"],

	data() {
		const { isTestRepoForm } = this;
		const artitfactData = isTestRepoForm ? this.artifactData?.pac : this.artifactData?.iac;

		return {
			formState: null as FormBuilderState | null,
			formValues: {
				gitUrl: artitfactData?.repo ?? "",
				branch: artitfactData?.branch ?? "",
				directoryPath: artitfactData?.dir ?? ""
			} as FormValues,

			submitError: "",
			isLoading: false
		};
	},

	computed: {
		...mapStores(authStore, notificationsStore, componentOnboardStore),

		hasExistingTerraformModule() {
			return this.component.artifact?.metadata?.terraformGitRepo?.iac !== undefined;
		},

		buttonLabel() {
			if (this.isTestRepoForm) {
				return "Run Tests";
			}

			return this.hasExistingTerraformModule ? "Update Module" : "Add Module";
		},

		formFields(): FormBuilderField {
			return {
				type: "object",
				direction: "vertical",
				fields: this.isTestRepoForm
					? {
							gitUrl: {
								type: "text",
								qaId: "git-url-for-policy",
								label: { title: "Git URL for test" },
								disabled: !this.canAddTestModule,
								placeholder: "Enter git URL for test",
								validationRules: [
									{ name: "required" },
									{
										name: "custom",
										validate: value => {
											// Check if the url is a git URL or a container registry URL
											return parseGitUrl(String(value));
										},

										message: "Please provide correct git repository url"
									}
								]
							},

							branch: {
								type: "text",
								qaId: "git-branch",
								label: { title: "Branch" },
								disabled: !this.canAddTestModule,
								placeholder: "Select branch",
								validationRules: [{ name: "required" }]
							},

							directoryPath: {
								type: "text",
								qaId: "directory-path-for-policy",
								label: { title: "Directory path for test" },
								disabled: !this.canAddTestModule,
								placeholder: "Enter directory path",
								validationRules: [{ name: "required" }, validRelativeDirectoryRule]
							}
						}
					: {
							gitUrl: {
								type: "text",
								qaId: "git-url",
								label: { title: "Git URL of module" },
								placeholder: "Enter git URL for Terraform module",
								validationRules: [
									{ name: "required" },
									{
										name: "custom",
										validate: value => {
											// Check if the url is a git URL or a container registry URL
											return parseGitUrl(String(value));
										},

										message: "Please provide correct git repository url"
									}
								]
							},

							branch: {
								type: "text",
								qaId: "git-branch",
								label: { title: "Branch" },
								placeholder: "Select branch",
								validationRules: [{ name: "required" }]
							},

							directoryPath: {
								type: "text",
								qaId: "directory-path",
								label: { title: "Directory Path" },
								placeholder: "Enter directory path",
								validationRules: [{ name: "required" }, validRelativeDirectoryRule]
							}
						}
			};
		},

		canAddTestModule() {
			return (
				this.isTestRepoForm &&
				this.component.artifact?.metadata?.terraformGitRepo?.iac !== undefined
			);
		}
	},

	methods: {
		handleInput(event: CustomEvent<FormValues>) {
			const values = { ...event.detail };
			this.formValues = values;
		},

		getUserId() {
			return this.authStore.userSession?.identity?.id;
		},

		async addNewTerraform() {
			this.isLoading = true;
			(this.$refs.formBuilder as InstanceType<typeof FFormBuilder>).submit();
			try {
				const existingGithubCodeRepo =
					this.component.artifact?.metadata?.terraformGitRepo?.iac ?? {};

				const existingGithubTestRepo =
					this.component.artifact?.metadata?.terraformGitRepo?.pac ?? {};

				this.isLoading = true;

				const repoInfo: RepoConfig = {
					repo: this.formValues.gitUrl.trim(),
					dir: this.formValues.directoryPath.trim(),
					branch: this.formValues.branch.trim()
				};

				let gitCodeRepo: TerraformGitRepo = {
					iac: repoInfo,
					pac: {
						repo: existingGithubTestRepo.repo?.trim() ?? "",
						dir: existingGithubTestRepo.dir?.trim() ?? "",
						branch: existingGithubTestRepo.branch?.trim() ?? ""
					}
				};

				if (this.isTestRepoForm) {
					gitCodeRepo = {
						iac: {
							repo: existingGithubCodeRepo.repo?.trim() ?? "",
							dir: existingGithubCodeRepo.dir?.trim() ?? "",
							branch: existingGithubCodeRepo.branch?.trim() ?? ""
						},

						pac: repoInfo
					};
				}

				const payload: CreateArtifactRequest = {
					componentId: this.component.id ?? "",
					name: this.component.name ?? "",
					status: "active",
					createdBy: this.getUserId() ?? "",
					approvedBy: "",
					type: ArtifactType.GIT_REPO,
					metadata: {
						terraformGitRepo: gitCodeRepo
					}
				};

				// The way backend is designed, updating an artifact runs the pipeline, but creating it doesn't
				if (this.component.artifact?.id && this.isTestRepoForm) {
					await updateArtifact({
						id: this.component.artifact.id,
						...payload
					});
				} else {
					await createArtifact(payload);
				}

				await this.componentOnboardStore.getComponentById(this.component.id);
				this.isLoading = false;

				this.$emit("artifact-created");

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated",
					title: "Terraform module updated successfully.",
					text: "Terraform module git credentials sent for verification successfully.",
					status: "success"
				});
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-meta-data-updated-error",
					title: "Terraform git credentials not updated.",
					text:
						error === "string" ? error : "Error while uploading terraform module, please try again",
					status: "error"
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
});
</script>
