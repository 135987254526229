<script setup lang="ts">
import { Loader } from "@ollion/flow-vue3";
import { PropType, toRefs, onMounted, ref } from "vue";

import { Evidence } from "@/protocol/evidence";
import { captureError, getBucketUrl, getErrorMessage } from "@/utils";

const props = defineProps({
	evidence: {
		type: Object as PropType<Evidence>,
		required: true
	}
});

const { evidence } = toRefs(props);

const logContent = ref("");
const isLoading = ref(false);
const errorMessage = ref("");

async function fetchLog(url?: string) {
	if (!url) {
		return "";
	}

	const updatedUrl = getBucketUrl(url);

	try {
		isLoading.value = true;
		errorMessage.value = "";

		const response = await fetch(updatedUrl);
		const blob = await response.blob();
		let evidenceString = await blob.text();

		if (!evidenceString.includes("\n")) {
			try {
				const parsed = JSON.parse(evidenceString);
				evidenceString = JSON.stringify(parsed, null, 2);
			} catch (error) {
				try {
					evidenceString = JSON.stringify(evidenceString, null, 2);
				} catch (err) {
					evidenceString = `${evidenceString}`;
				}
			}
		}

		return evidenceString;
	} catch (error) {
		captureError(error);
		errorMessage.value = getErrorMessage(error);
		return "";
	} finally {
		isLoading.value = false;
	}
}

function downloadEvidence() {
	const url = evidence.value.content;
	window.open(url, "_blank");
}

onMounted(async () => {
	if (evidence.value.content) {
		logContent.value = await fetchLog(evidence.value.content);
	}
});
</script>

<template>
	<f-div data-qa-img-renderer width="fill-container" height="fill-container" align="middle-center">
		<Loader v-if="isLoading" />
		<f-div v-else-if="errorMessage" align="middle-center" direction="column" gap="large">
			<f-icon source="i-question-filled" size="x-large" state="subtle"></f-icon>
			<f-text state="secondary"
				>Failed to render {{ evidence.name }} of type {{ evidence?.fileType }}</f-text
			>
			<f-text state="secondary">{{ errorMessage }}</f-text>
			<f-text state="secondary"> Please download the evidence.</f-text>
			<f-button
				data-qa="download-evidence-button"
				label="Download evidence"
				size="large"
				category="outline"
				icon-right="i-download"
				@click="downloadEvidence"
			></f-button>
		</f-div>

		<f-log v-if="logContent" wrap-text :logs="logContent"></f-log>
	</f-div>
</template>
