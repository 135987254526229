<template>
	<AuthLayout :header="type" :loading="loading">
		<AuthForm
			:api-error-msg="apiErrorMsg"
			:type="type"
			:is-submitting="isSubmitting"
			@submit="submitLoginFlow"
			@submitoidc="submitLoginoidcFlow"
		></AuthForm>
	</AuthLayout>
</template>

<script lang="ts">
import { UiContainer, UiText, UpdateLoginFlowBody } from "@ory/client";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { exchangeCodeWithToken } from "@/modules/user/user-service";
import { ExchangeCodeWithTokenRequest } from "@/protocol/users";
import { captureError } from "@/utils";

import { ACCOUNT_EXISTS_CODE, ACCOUNT_LINKING_MESSAGE, LOG_IN } from "../auth-constants";
import { authStore } from "../auth-store";
import { FormPayload, OIDCProvider } from "../auth-types";
import AuthLayout from "../views/AuthLayout.vue";

import AuthForm from "./AuthForm.vue";

export default defineComponent({
	name: "Login",

	components: {
		AuthForm,
		AuthLayout
	},

	data() {
		return {
			isSubmitting: false,
			loginFlowid: "",
			apiErrorMsg: "",
			csrfToken: "",
			type: LOG_IN,
			loading: false
		};
	},

	mounted() {
		this.handleoidcCallback();
	},

	computed: {
		...mapStores(authStore, notificationsStore)
	},

	methods: {
		async handleoidcCallback() {
			const { code } = this.$route.query;
			if (code) {
				this.loading = true;
				const payload: ExchangeCodeWithTokenRequest = {
					code: code.toString(),
					redirectUri: `${window.location.protocol}//${window.location.host}/ui/login/self-service/methods/oidc/callback/google`
				};
				try {
					const resp = await exchangeCodeWithToken(payload);
					if (resp.idToken) {
						this.submitLoginoidcFlow(OIDCProvider.GOOGLE, resp.idToken);
					}
				} catch (error) {
					this.loading = false;
					captureError(error);
					this.notificationsStore.ADD_TOAST({
						qaId: "toast-exchange-code-with-token-error",
						title: "Failed to get token",
						text: `Please try again`,
						status: "error"
					});
				}
			}
		},

		async createLoginFlow() {
			//create login flow id
			const resp = await this.authStore.CREATE_LOGIN_FLOW({ refresh: true });
			this.loginFlowid = resp?.data.id as string;
			const csrfNode = resp?.data.ui.nodes.find(node => {
				return "name" in node.attributes && node.attributes.name === "csrf_token";
			});
			if (csrfNode && "value" in csrfNode.attributes) {
				this.csrfToken = (csrfNode.attributes as { value: string }).value;
			}
		},

		async submitLoginFlow(loginPayload: FormPayload) {
			// Submit Login with username and password
			if (!this.loginFlowid) {
				await this.createLoginFlow();
			}
			const body: UpdateLoginFlowBody = {
				csrf_token: this.csrfToken,
				identifier: loginPayload.email.value,
				method: "password",
				password: loginPayload.password.value
			};

			try {
				this.isSubmitting = true;
				const resp = await this.authStore.SUBMIT_LOGIN_FLOW({
					flow: this.loginFlowid,
					updateLoginFlowBody: body
				});
				if (resp.data.session.active) {
					this.$router.replace({ name: "authenticated" });
				}
			} catch (error) {
				captureError(error);
				if (typeof error === "string") {
					this.apiErrorMsg = error;
				}
			} finally {
				this.isSubmitting = false;
			}
		},

		async submitLoginoidcFlow(provider: OIDCProvider, id_token: string) {
			await this.createLoginFlow();
			const body: UpdateLoginFlowBody = {
				csrf_token: this.csrfToken,
				method: "oidc",
				provider,
				id_token
			};

			try {
				const resp = await this.authStore.SUBMIT_LOGIN_FLOW({
					flow: this.loginFlowid,
					updateLoginFlowBody: body
				});
				if ("ui" in resp.data && "id" in resp.data) {
					const uinode = resp.data.ui as UiContainer;
					const existingAccountError = uinode.messages?.find(
						(message: UiText) => message.id === ACCOUNT_EXISTS_CODE && message.type === "error"
					);
					if (existingAccountError) {
						//If existing account, call the login flow again with flow id from response to link account
						this.apiErrorMsg = `${existingAccountError.text} ${ACCOUNT_LINKING_MESSAGE} ${provider}.`;
						this.loginFlowid = resp.data.id as string;
					}
				}
				if ("session" in resp.data) {
					if (resp.data.session.active) {
						this.$router.replace({ name: "authenticated" });
					}
				}
				this.loading = false;
			} catch (error) {
				this.loading = false;
				captureError(error);
				if (typeof error === "string") {
					this.apiErrorMsg = error;
				}
			}
		}
	}
});
</script>
