<template>
	<f-pictogram :source="componentName" :size="size" :auto-bg="true" variant="square"></f-pictogram>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Component } from "@/protocol/cockpit";

export default defineComponent({
	name: "ComponentIcon",

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		},

		size: {
			type: String as PropType<"small" | "medium" | "large" | "x-large">,
			default: "medium"
		}
	},

	computed: {
		componentName() {
			return this.component.name;
		}
	}
});
</script>
