<template>
	<f-popover
		target="[data-qa-onboard-types-list]"
		placement="bottom-start"
		:open="openPopover"
		size="small"
		:overlay="true"
		:close-on-escape="true"
		@esc="onClose()"
		@overlay-click="onClose()"
	>
		<f-div direction="column">
			<f-div
				v-for="item in menuItem"
				:key="item.id"
				state="secondary"
				padding="medium"
				gap="medium"
				clickable
				:selected="item.id === selected ? 'background' : 'none'"
				@click="select(item.id)"
			>
				<f-div
					data-f-id="menu-list-section-left"
					gap="medium"
					align="middle-left"
					width="hug-content"
				>
					<f-icon size="small" state="default" :source="item.icon"></f-icon>
					<f-text
						variant="para"
						size="medium"
						weight="regular"
						state="default"
						style="text-transform: capitalize"
					>
						{{ item.label.toLocaleLowerCase() }}
					</f-text>
					<f-icon
						size="small"
						state="subtle"
						:source="item.infoIcon"
						:tooltip="item.tooltip"
					></f-icon>
				</f-div>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ComponentType } from "@/protocol/cockpit";

export default defineComponent({
	name: "OnboardTypesList",

	props: {
		openPopover: {
			type: Boolean,
			required: true
		}
	},

	emits: ["close", "select-onboard-component-type"],

	data: () => {
		return {
			selected: "",
			menuItem: [
				{
					id: ComponentType.SERVICE,
					label: ComponentType.SERVICE,
					icon: "i-cloud",
					infoIcon: "i-info-fill",
					tooltip: "Select service to onboard new service component."
				},
				{
					id: ComponentType.SOFTWARE,
					label: ComponentType.SOFTWARE,
					icon: "i-puzzle",
					infoIcon: "i-info-fill",
					tooltip: "Select software to onboard new software component."
				},
				{
					id: ComponentType.ASSET,
					label: ComponentType.ASSET,
					icon: "i-assets",
					infoIcon: "i-info-fill",
					tooltip: "Select asset to onboard new asset component."
				}
			]
		};
	},

	methods: {
		select(id: string) {
			this.selected = id;
			this.$emit("select-onboard-component-type", id);
		},

		onClose() {
			this.$emit("close");
		}
	}
});
</script>
