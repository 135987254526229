import nlp from "compromise/three";

import type Three from "compromise/types/view/three";

if (import.meta.env.DEV) {
	//@ts-expect-error
	window.nlp = nlp;
}

const world = nlp.world();

// https://github.com/spencermountain/compromise/issues/1107
//@ts-expect-error
world.model.one.lexicon.to = "Preposition";
//@ts-expect-error
world.model.one.lexicon.hardware = "Noun";
//@ts-expect-error
world.model.two.switches.transmits = "Plural|Verb";
//@ts-expect-error
world.model.one.lexicon.transmit = "Verb";

const COORDINATING_CONJUNCTIONS = "(and|but|or|nor|so|yet)";

export function splitParagraph(paragraph: string) {
	const para = nlp(paragraph);

	const sentences = para.sentences();

	const paragraphOutput: Array<{ sentence: string; splits: string[] }> = [];

	sentences.forEach(sentence => {
		const text = sentence.text();

		const split = splitSentence(text);

		paragraphOutput.push({
			sentence: text,
			splits: split
		});
	});

	return paragraphOutput;
}

type SubjVerbPredicates = {
	subject: string;
	verb: string;
	predicate: string;
};

// eslint-disable-next-line max-statements
function findClauses(sentence: Three) {
	const fixedSentence = sentence.match("[#Noun] #Preposition", 0).tag("Verb").all();

	fixedSentence.debug();

	const sentences: Sentence[] = [];

	let currentSubject = "";
	let currentVerb = "";

	const svbPairs: SubjVerbPredicates[] = [];

	let currentTerm = fixedSentence;

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
	while (true) {
		if (!currentTerm.text()) {
			break;
		}

		let wasHandled = false;

		// 1. Try to grow until you find no more nouns and check if they can become subjects
		const nextNouns = currentTerm.match("^#Preposition? #Determiner? #Adjective? #Noun");
		if (nextNouns.has(".")) {
			if (currentSubject && currentVerb) {
				svbPairs.push({
					subject: currentSubject,
					verb: currentVerb,
					predicate: nextNouns.text()
				});
			} else {
				currentSubject = nextNouns.text();
			}

			wasHandled = true;
			currentTerm = nextNouns.after(".+");
		}

		const subjectVerb = currentTerm.match("^(#PastTense|#PresentTense)+");
		if (subjectVerb.has(".")) {
			if (!currentSubject) {
				currentSubject = subjectVerb.text();
			} else if (!currentVerb) {
				currentVerb = subjectVerb.text();
			} else {
				svbPairs.push({
					subject: currentSubject,
					verb: currentVerb,
					predicate: subjectVerb.text()
				});
			}

			wasHandled = true;
			currentTerm = subjectVerb.after(".+");
		}

		const nextVerbs = currentTerm.match("^#Preposition? #Determiner? #Adjective? (#Adverb|#Verb)+");
		if (nextVerbs.has(".")) {
			if (!currentSubject) {
				currentSubject = nextVerbs.text();
			} else if (!currentVerb) {
				currentVerb = nextVerbs.text();
			} else {
				svbPairs.push({
					subject: currentSubject,
					verb: currentVerb,
					predicate: nextVerbs.text()
				});
			}

			wasHandled = true;
			currentTerm = nextVerbs.after(".+");
		}

		const nextConjunction = currentTerm.match(`^${COORDINATING_CONJUNCTIONS}`);
		if (nextConjunction.has(".")) {
			wasHandled = true;
			currentTerm = nextConjunction.after(".+");
		}

		const nextSubjugation = currentTerm.match("^#Conjunction");
		if (nextSubjugation.has(".")) {
			const remainingSentence = nlp(nextSubjugation.after(".+").text());
			// eslint-disable-next-line no-console
			console.log("clause", findClauses(remainingSentence));
			wasHandled = true;
			currentTerm = nextSubjugation.after(".+");
		}

		if (!wasHandled) {
			// eslint-disable-next-line no-console
			console.error("Unhandled term:", currentTerm.text());
			currentTerm = currentTerm.after(".+");
		}
	}

	// eslint-disable-next-line no-console
	console.table(svbPairs);

	return sentences;
}

type Sentence = {
	subject?: string;
	subjectDeterminer?: string;
	verbDeterminer?: string;
	auxVerb?: string;
	mainVerb?: string;
	predicate?: string;
	subjugatedClause?: string;
};

export function splitSentence(sentence: string) {
	const parsedSentence = nlp(sentence);

	const matches = [sentence];

	// eslint-disable-next-line no-console
	console.table(findClauses(parsedSentence));

	return matches;
}
