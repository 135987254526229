import { defineStore } from "pinia";

export const debugMenuStore = defineStore("debugMenuStore", {
	state() {
		return {
			isDebugMenuVisible: false
		};
	},

	actions: {
		SHOW_DEBUG_MENU() {
			this.isDebugMenuVisible = true;
		},

		HIDE_DEBUG_MENU() {
			this.isDebugMenuVisible = false;
		}
	}
});
