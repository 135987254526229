import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

import { prepareDataTableColumns } from "@/modules/taxonomy-table/taxonomy-table-utils";
import {
	CLASS_HEADERS,
	CLASS_ICONS,
	CLASS_ITEM,
	CLASS_REQUEST_BODY,
	DOMAIN_HEADERS,
	DOMAIN_ICONS,
	DOMAIN_ITEM,
	DOMAIN_REQUEST_BODY,
	TBM_HEADERS,
	TBM_ICONS,
	TBM_ITEM,
	TBM_REQUEST_BODY,
	UPLOAD_TAXONOMY_SHEET_RESPONSE
} from "@/modules/taxonomy-table/taxonomy-types";
import { UploadClassResponse, UploadDomainResponse, UploadTBMResponse } from "@/protocol/taxonomy";
import { CALLBACK_STATUS, TAXONOMY_TYPES } from "@/shared/constants";
import { DataTableColumn } from "@/shared/types";

import taxonomyTableService from "./taxonomy-table-service";

export const taxonomyStore = defineStore("taxonomyStore", {
	state: () => {
		return {
			// all these properties will have their type inferred automatically
			selectedTaxonomyTab: TAXONOMY_TYPES.DOMAIN,
			taxonomyDomainList: Array<DOMAIN_ITEM>(),
			taxonomyTBMList: Array<TBM_ITEM>(),
			taxonomyClassList: Array<CLASS_ITEM>(),
			dataTableColumns: Array<DataTableColumn>(),
			dataTableRows: Array<Array<string | number>>()
		};
	},
	actions: {
		setTaxonomyDomainList(taxonomyDomainList: Array<DOMAIN_ITEM>) {
			this.taxonomyDomainList = taxonomyDomainList;
		},
		setTaxonomyTBMList(taxonomyTBMList: Array<TBM_ITEM>) {
			this.taxonomyTBMList = taxonomyTBMList;
		},
		setTaxonomyClassList(taxonomyClassList: Array<CLASS_ITEM>) {
			this.taxonomyClassList = taxonomyClassList;
		},

		async updateAndReturn(
			response: AxiosResponse
		): Promise<[CALLBACK_STATUS, UploadDomainResponse]> {
			if (
				(response.status === 200 && response.data.totalRowsAdded > 0) ||
				(response.status === 200 && response.data.totalNodesAdded > 0)
			) {
				await this.updateTaxonomyDataList();
				return [CALLBACK_STATUS.SUCCESS, response.data as UploadDomainResponse];
			}
			return [CALLBACK_STATUS.FAILURE, response.data];
		},

		async updateTaxonomyDomainList(
			data: DOMAIN_REQUEST_BODY
		): Promise<[CALLBACK_STATUS, UploadDomainResponse]> {
			const response: AxiosResponse = await taxonomyTableService.postTaxonomyDomain(data);
			return await this.updateAndReturn(response);
		},

		async updateTaxonomyTBMList(
			data: TBM_REQUEST_BODY
		): Promise<[CALLBACK_STATUS, UploadTBMResponse]> {
			const response: AxiosResponse = await taxonomyTableService.postTaxonomyTBM(data);

			return await this.updateAndReturn(response);
		},
		async updateTaxonomyClassList(
			data: CLASS_REQUEST_BODY
		): Promise<[CALLBACK_STATUS, UploadClassResponse]> {
			const response: AxiosResponse = await taxonomyTableService.postTaxonomyClass(data);

			return await this.updateAndReturn(response);
		},

		async getTaxonomyDomainList(): Promise<DOMAIN_ITEM[]> {
			const response: AxiosResponse = await taxonomyTableService.fetchTaxonomyDomainList();
			if (response.status === 200) {
				const { domains } = response.data;
				return domains;
			}
			throw response;
		},
		async getTaxonomyTBMList(): Promise<TBM_ITEM[]> {
			const response: AxiosResponse = await taxonomyTableService.fetchTaxonomyTBMList();
			if (response.status === 200) {
				const { TBMs } = response.data;
				return TBMs;
			}
			throw response;
		},
		async getTaxonomyClassList(): Promise<CLASS_ITEM[]> {
			const response: AxiosResponse = await taxonomyTableService.fetchTaxonomyClassList();
			if (response.status === 200) {
				const { classes } = response.data;
				return classes;
			}
			throw response;
		},

		checkTabAndUpdateContent(): void {
			switch (this.selectedTaxonomyTab) {
				case TAXONOMY_TYPES.DOMAIN:
					this.dataTableColumns = prepareDataTableColumns(DOMAIN_HEADERS, DOMAIN_ICONS);
					this.dataTableRows = this.taxonomyDomainList.map(({ id, taxonomy1, taxonomy2 }) => [
						id,
						taxonomy1,
						taxonomy2
					]);
					break;
				case TAXONOMY_TYPES.TBM:
					this.dataTableColumns = prepareDataTableColumns(TBM_HEADERS, TBM_ICONS);
					this.dataTableRows = this.taxonomyTBMList.map(
						({ id, taxonomy3, taxonomy4, taxonomy5 }) => [id, taxonomy3, taxonomy4, taxonomy5]
					);
					break;
				case TAXONOMY_TYPES.CLASS:
					this.dataTableColumns = prepareDataTableColumns(CLASS_HEADERS, CLASS_ICONS);
					this.dataTableRows = this.taxonomyClassList.map(
						({ id, taxonomy6, taxonomy7, taxonomy8 }) => [id, taxonomy6, taxonomy7, taxonomy8]
					);
					break;
			}
		},

		async updateTaxonomyDataList(): Promise<void> {
			switch (this.selectedTaxonomyTab) {
				case TAXONOMY_TYPES.DOMAIN:
					this.setTaxonomyDomainList(await this.getTaxonomyDomainList());
					break;
				case TAXONOMY_TYPES.TBM:
					this.setTaxonomyTBMList(await this.getTaxonomyTBMList());
					break;
				case TAXONOMY_TYPES.CLASS:
					this.setTaxonomyClassList(await this.getTaxonomyClassList());
					break;
			}
			this.checkTabAndUpdateContent();
		},

		async switchTaxonomyTab(newSelectedTab: TAXONOMY_TYPES): Promise<void> {
			this.selectedTaxonomyTab = newSelectedTab;
			await this.updateTaxonomyDataList();
		},

		async export() {
			const res = await taxonomyTableService.export();
			if (res.status === 200) {
				return res.data;
			}
			throw res;
		},

		async uploadFile(file?: File) {
			if (file === undefined) {
				throw new Error("File is undefined");
			} else {
				const resp: AxiosResponse = await taxonomyTableService.uploadFile(file);
				if (resp.status === 200) {
					await this.updateTaxonomyDataList();
					return resp.data as UPLOAD_TAXONOMY_SHEET_RESPONSE;
				}
				throw resp.data;
			}
		}
	}
});
