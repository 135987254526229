import {
	FrontendApiCreateBrowserLoginFlowRequest,
	FrontendApiGetLoginFlowRequest,
	FrontendApiGetRegistrationFlowRequest,
	FrontendApiUpdateLoginFlowRequest,
	FrontendApiUpdateRegistrationFlowRequest,
	FrontendApiGetVerificationFlowRequest,
	FrontendApiUpdateVerificationFlowRequest,
	FrontendApiUpdateRecoveryFlowRequest,
	Session,
	FrontendApiGetRecoveryFlowRequest
} from "@ory/client";
import { AxiosError } from "axios";
import { defineStore } from "pinia";

import { captureError } from "@/utils";

import { StorageService } from "../services/storage-service";
import { listPermissions } from "../user/user-service";

import { handleAPIError, sdk } from "./../services/ory-sdk-service";
import { AuthState, USER_PERMISSIONS } from "./auth-types";

export const DEFAULT_ORG_IDENTIFIER = "default";

export const authStore = defineStore("authStore", {
	state: (): AuthState => ({
		logoutToken: "",
		userSession: null,
		userPermissions: {},
		permissionsLoading: false
	}),

	actions: {
		async CHECK_SESSION() {
			try {
				const resp = await sdk.toSession();
				if (resp.data.active === true) {
					this.SET_SESSION(resp.data);
					StorageService.saveToken(resp.data.id);
					return resp;
				}
			} catch (error) {
				if ((error as AxiosError | undefined)?.response?.data) {
					StorageService.removeToken();
				}
				captureError(error);
				handleAPIError(error);
			}
		},

		async GET_USER_PERMISSIONS() {
			this.permissionsLoading = true;
			try {
				const resp = await listPermissions({
					permissions: Object.values(USER_PERMISSIONS)
				});

				if (!resp.features) {
					throw new Error("No features returned from permissions");
				}

				this.userPermissions = resp.features;
				/* Check if user has all rights enaled than make him admin user */
				if (
					this.userPermissions["compliance-report"]?.enabled &&
					this.userPermissions["component-catalog-read"]?.enabled &&
					this.userPermissions["component-catalog-write"]?.enabled &&
					this.userPermissions["constellation-map"]?.enabled &&
					this.userPermissions["document-ingest"]?.enabled &&
					this.userPermissions["document-mapping"]?.enabled &&
					this.userPermissions["evidence-ui"]?.enabled &&
					this.userPermissions["release-cockpit"]?.enabled &&
					this.userPermissions["taxonomy-table"]?.enabled &&
					this.userPermissions["workflow-approve"]?.enabled
				) {
					this.userPermissions["manage-members"] = { enabled: true };
				}
				this.permissionsLoading = false;
			} catch (error) {
				captureError(error);
				this.permissionsLoading = false;
			}
		},

		async CREATE_LOGOUT_TOKEN() {
			try {
				const resp = await sdk.createBrowserLogoutFlow(undefined, { params: { return_url: "/" } });
				this.SET_LOGOUT_TOKEN(resp.data.logout_token);
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async GET_LOGIN_FLOW(body: FrontendApiGetLoginFlowRequest) {
			try {
				const resp = await sdk.getLoginFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				throw handleAPIError(error);
			}
		},

		async CREATE_LOGIN_FLOW(body: FrontendApiCreateBrowserLoginFlowRequest) {
			try {
				const resp = await sdk.createBrowserLoginFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async SUBMIT_LOGIN_FLOW(body: FrontendApiUpdateLoginFlowRequest) {
			try {
				const resp = await sdk.updateLoginFlow(body);
				if ("session" in resp.data) {
					//updateLoginFlow return type of SuccessfulNativeLogin but it actually returns LoginFlow type response if account exists
					if (resp.data.session.active === true) {
						this.SET_SESSION(resp.data.session);
						StorageService.saveToken(resp.data.session.id);
					}
				}
				return resp;
			} catch (error) {
				captureError(error);
				const err = await handleAPIError(error, { isSubmitFlow: true });
				throw err?.ui?.messages[0]?.text;
			}
		},

		async GET_SIGNUP_FLOW(body: FrontendApiGetRegistrationFlowRequest) {
			try {
				const resp = await sdk.getRegistrationFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				throw handleAPIError(error);
			}
		},

		async CREATE_SIGNUP_FLOW() {
			try {
				const resp = await sdk.createBrowserRegistrationFlow();
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async SUBMIT_SIGNUP_FLOW(body: FrontendApiUpdateRegistrationFlowRequest) {
			try {
				const resp = await sdk.updateRegistrationFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				const err = await handleAPIError(error, { isSubmitFlow: true });
				throw err;
			}
		},

		async GET_VERIFICATION_FLOW(body: FrontendApiGetVerificationFlowRequest) {
			try {
				const resp = await sdk.getVerificationFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				throw handleAPIError(error);
			}
		},

		async CREATE_VERIFICATION_FLOW() {
			try {
				const resp = await sdk.createBrowserVerificationFlow();
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async SUBMIT_VERIFICATION_FLOW(body: FrontendApiUpdateVerificationFlowRequest) {
			try {
				const resp = await sdk.updateVerificationFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				const err = await handleAPIError(error, { isSubmitFlow: true });
				throw err?.ui?.messages[0]?.text;
			}
		},

		async GET_RECOVERY_FLOW(body: FrontendApiGetRecoveryFlowRequest) {
			try {
				const resp = await sdk.getRecoveryFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				throw handleAPIError(error);
			}
		},

		async CREATE_RECOVERY_FLOW() {
			try {
				const resp = await sdk.createBrowserRecoveryFlow();
				return resp;
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		async SUBMIT_RECOVERY_FLOW(body: FrontendApiUpdateRecoveryFlowRequest) {
			try {
				const resp = await sdk.updateRecoveryFlow(body);
				return resp;
			} catch (error) {
				captureError(error);
				const err = await handleAPIError(error, { isSubmitFlow: true }, "change-password");
				throw err?.ui?.messages[0]?.text;
			}
		},

		async LOGOUT_USER() {
			try {
				await sdk.updateLogoutFlow({ token: this.logoutToken });
				this.REMOVE_TOKEN();
				this.userPermissions = {};
				StorageService.removeToken();
			} catch (error) {
				captureError(error);
				handleAPIError(error);
			}
		},

		SET_SESSION(userSession: Session) {
			this.userSession = userSession;
		},

		SET_LOGOUT_TOKEN(logoutToken: string) {
			this.logoutToken = logoutToken;
		},

		REMOVE_TOKEN() {
			this.logoutToken = "";
			this.userSession = null;
		}
	}
});
