<template>
	<f-div direction="column" width="100%" padding="none">
		<!-- @todo - Remove position hack once f-log supports slots -->

		<f-div overflow="scroll" padding="medium">
			<f-div v-if="isFetchingLogs" width="fill-container">
				<CustomLoader />
			</f-div>
			<EmptyState
				v-else-if="!hasTerraformTestModuleAdded"
				:icon="{
					name: 'i-file-code',
					size: 'medium',
					state: 'light'
				}"
				message="Please add the Terraform test module to run the pipeline"
				data-qa="no-terraform-test-found-error"
			/>
			<ErrorPage v-else-if="error" :message="error" />
			<f-log
				v-else
				label="Logs"
				:logs="logs"
				:wrap-text="true"
				:show-toolbar="true"
				:log-levels="logLevels"
			>
				<f-div
					slot="header"
					width="hug-content"
					direction="row"
					gap="small"
					align="middle-left"
					padding="small"
					height="hug-content"
					state="secondary"
					:style="{ borderRadius: '4px' }"
				>
					<f-text inline variant="para" size="small" weight="bold" state="default" align="left">
						Status:
					</f-text>
					<f-icon v-if="isPolling" size="small" state="default" source="i-loader" :loading="true" />
					<f-text
						inline
						variant="para"
						size="small"
						weight="regular"
						:state="pipelineStatus.titleState"
						align="left"
					>
						{{ pipelineStatus.title }}
					</f-text>

					<f-button
						v-if="!isComponentNotarized && !isPolling"
						label="Retry Tests"
						size="x-small"
						:state="pipelineStatus.buttonState"
						:disabled="isPolling"
						@click="onRetryPipeline"
					></f-button>
				</f-div>
			</f-log>
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { FButtonState, FTextStateProp } from "@ollion/flow-core";
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import { notificationsStore } from "@/modules/notifications/notifications-store";
import { getJobPipelineById } from "@/modules/release-cockpit-v2/component-catalog-service";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import { JOB_STATUS_TYPES } from "@/modules/release-cockpit-v2/release-cockpit-types";
import { Component, JobDetails, JobStatus } from "@/protocol/cockpit";
import CustomLoader from "@/shared/components/CustomLoader.vue";
import { COMPONENT_STATUS } from "@/shared/constants";
import { captureError } from "@/utils";

export default defineComponent({
	name: "ComponentOnboardingPipelineLogs",
	components: { CustomLoader, ErrorPage, EmptyState },

	props: {
		component: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["retry"],

	data: () => ({
		jobDetails: undefined as JobDetails | undefined,
		error: "",
		isFetchingLogs: false,
		isPolling: false,
		hasPolled: false,
		timeoutId: 0
	}),

	computed: {
		...mapStores(componentOnboardStore, notificationsStore),

		isComponentNotarized() {
			const { status } = this.component;
			return (
				status === COMPONENT_STATUS.notarised ||
				status === COMPONENT_STATUS.published ||
				status === COMPONENT_STATUS.deprecated ||
				status === COMPONENT_STATUS.deprecationPending
			);
		},

		logLevels() {
			const logLevels = new Set<string>(["ALL"]);

			this.logs.split("\n").forEach(logLine => {
				const match = logLine.match(/^\[.*\]/);
				const logLevel = match?.[0].replace("[", "").replace("]", "");
				if (logLevel) {
					logLevels.add(logLevel);
				}
			});

			return Array.from(logLevels);
		},

		logs() {
			return this.jobDetails?.logs ?? "";
		},

		pipelineStatus(): {
			icon: string;
			titleState: FTextStateProp;
			title: string;
			buttonState: FButtonState;
		} {
			const jobStatus = this.jobDetails?.status;

			if (!jobStatus && this.error) {
				return {
					icon: "i-error",
					titleState: "danger",
					title: "Error",
					buttonState: "danger"
				};
			}

			switch (jobStatus) {
				case JobStatus.JOB_FAILED:
					return {
						icon: "i-error",
						titleState: "danger",
						title: JOB_STATUS_TYPES[JobStatus.JOB_FAILED],
						buttonState: "danger"
					};
				case JobStatus.JOB_COMPLETED:
					return {
						icon: "i-success",
						titleState: "success",
						title: JOB_STATUS_TYPES[JobStatus.JOB_COMPLETED],
						buttonState: "success"
					};
				case JobStatus.JOB_IN_PROGRESS:
				case JobStatus.JOB_PENDING:
				case JobStatus.JOB_STATUS_UNKNOWN:
				default:
					return {
						icon: "i-loader",
						titleState: "default",
						title: JOB_STATUS_TYPES[JobStatus.JOB_IN_PROGRESS],
						buttonState: "primary"
					};
			}
		},

		hasTerraformTestModuleAdded() {
			if (this.component.artifact?.type !== "GIT_REPO") {
				return false;
			}

			const terraformGitRepo = this.component.artifact.metadata?.terraformGitRepo;

			if (terraformGitRepo?.iac?.repo && terraformGitRepo.pac?.repo) {
				return true;
			}
			return false;
		}
	},

	mounted() {
		if (this.hasTerraformTestModuleAdded) {
			this.isFetchingLogs = true;
			this.isPolling = true;
			this.loadPipelineLogs();
		}
	},

	beforeUnmount() {
		// Clear the timeout when the component is destroyed
		if (this.timeoutId) {
			this.stopPolling();
		}
	},

	methods: {
		onRetryPipeline() {
			this.isFetchingLogs = true;
			this.isPolling = true;
			this.$emit("retry", this.loadPipelineLogs);
		},

		async loadPipelineLogs() {
			try {
				const response = await getJobPipelineById(this.component.id ?? "");
				this.isFetchingLogs = false;
				const jobStatus = response.data.jobDetails?.status ?? JobStatus.JOB_STATUS_UNKNOWN;
				this.error = "";
				this.jobDetails = response.data.jobDetails;

				if (jobStatus === JobStatus.JOB_COMPLETED || jobStatus === JobStatus.JOB_FAILED) {
					this.componentOnboardStore.fetchStatementEvidences(this.component.id);

					const hasJobCompleted = jobStatus === JobStatus.JOB_COMPLETED;

					// To prevent the success notification from showing right away
					if (this.hasPolled) {
						this.notificationsStore.ADD_TOAST({
							qaId: hasJobCompleted ? "pipeline-success" : "pipeline-fail",
							title: hasJobCompleted ? "Test pipeline successful" : "Test pipeline failed",
							text: hasJobCompleted
								? "Your test pipeline has completed successfully."
								: "Your test pipeline has failed. Please look at the logs for more information.",
							status: hasJobCompleted ? "success" : "error"
						});
					}

					this.stopPolling();
				}
			} catch (resp: any) {
				this.error =
					resp.response.data.Message ?? "Something went wrong when getting job pipeline logs";
				this.stopPolling();
				captureError(resp);

				this.notificationsStore.ADD_TOAST({
					qaId: "pipeline-error",
					title: "Something went wrong.",
					text: "Something went wrong when trying to run the pipeline.",
					status: "error"
				});
			} finally {
				const jobStatus = this.jobDetails?.status ?? JobStatus.JOB_STATUS_UNKNOWN;
				if (jobStatus === JobStatus.JOB_IN_PROGRESS || jobStatus === JobStatus.JOB_PENDING) {
					this.timeoutId = window.setTimeout(this.loadPipelineLogs, 10000);
				}
				this.hasPolled = true;
			}
		},

		stopPolling() {
			window.clearTimeout(this.timeoutId);
			this.isPolling = false;
			this.isFetchingLogs = false;
			this.hasPolled = false;
		}
	}
});
</script>
