import {
	TAXONOMY_COLUMN_ICONS,
	TAXONOMY_HEADERS,
	UPLOAD_TAXONOMY_SHEET_RESPONSE
} from "@/modules/taxonomy-table/taxonomy-types";
import { formattedDateTime } from "@/shared/format-date";
import { DataTableColumn } from "@/shared/types";

export const prepareDataTableColumns = (
	headers: TAXONOMY_HEADERS,
	icons: TAXONOMY_COLUMN_ICONS
) => {
	const dataTableColumns: DataTableColumn[] = Array<DataTableColumn>();

	for (let i = 0; i < headers.length; i++) {
		const header = headers[i]!;
		const icon = icons[i]!;

		const column: DataTableColumn = {
			title: header,
			hasError: false,
			editable: false,
			sortable: true,
			icon: { name: icon, size: "md", state: "default", type: "default" }
		};
		dataTableColumns.push(column);
	}

	return dataTableColumns;
};

export const generateUploadTaxonomyText = (
	response_data: UPLOAD_TAXONOMY_SHEET_RESPONSE
): [string, string] => {
	const textPieces: string[] = [];

	if (response_data.domain_rows) {
		textPieces.push(`Domain rows added = ${response_data.domain_rows}`);
	}
	if (response_data.tbm_rows) {
		textPieces.push(`TBM rows added = ${response_data.tbm_rows}`);
	}
	if (response_data.class_rows) {
		textPieces.push(`Class rows added = ${response_data.class_rows}`);
	}

	const subText: string = response_data.num_nodes_added
		? `Total nodes added = ${response_data.num_nodes_added}`
		: "";

	return [textPieces.join(", "), subText];
};

export const downloadFileFromURL = (url: string, file_name: string, extension: string) => {
	const link: HTMLAnchorElement = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("download", `${file_name}_${formattedDateTime()}_${extension}`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
