<template>
	<f-popover size="medium" :open="true" :overlay="true">
		<f-div width="432px" state="secondary" direction="column" variant="curved" padding="none">
			<f-div align="middle-center" padding="medium" border="small solid subtle bottom">
				<f-div height="hug-content" padding="none"
					><f-text variant="heading" size="medium" weight="bold" state="default" align="left">
						Deprecate component
					</f-text>
				</f-div>
				<f-div width="hug-content" height="hug-content"
					><f-icon-button
						icon="i-close"
						variant="block"
						category="packed"
						state="neutral"
						size="small"
						data-qa="close-deprecate-modal-button"
						@click="closeModal"
					></f-icon-button
				></f-div>
			</f-div>

			<f-div gap="medium" direction="column" padding="large" height="hug-content">
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div>
						<f-text variant="heading" size="x-small" weight="medium" state="danger" align="left">
							Are you sure?
						</f-text>
					</f-div>
					<f-div>
						<f-text variant="heading" size="x-small" weight="medium" state="danger" align="left">
							If you deprecate this component, no applications can use it anymore, and existing
							applications that use it might be affected.
						</f-text>
					</f-div>
				</f-div>
				<f-divider variant="solid" state="subtle"></f-divider>
				<f-div direction="column" gap="large" height="hug-content" padding="none">
					<f-div gap="small" width="hug-content">
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="medium"
							state="secondary"
							align="left"
						>
							Reason for deprecation of
						</f-text>
						<f-text
							inline
							variant="heading"
							size="x-small"
							weight="bold"
							state="default"
							align="left"
						>
							{{ componentTitle }}
						</f-text>
					</f-div>
					<f-div>
						<f-text-area
							v-model="deprecationReason"
							height="73px"
							padding="small"
							placeholder="Enter reason for deprecation"
						></f-text-area>
					</f-div>
				</f-div>
			</f-div>

			<f-divider variant="solid" state="subtle"></f-divider>

			<f-div padding="medium" align="middle-right" gap="small">
				<f-button
					label="Cancel"
					variant="round"
					category="outline"
					size="small"
					state="neutral"
					icon-left="i-close"
					data-qa-id="close-modal"
					@click="closeModal"
				></f-button>
				<f-button
					label="Send Request"
					variant="round"
					size="small"
					state="danger"
					icon-left="i-unlink"
					data-qa-id="sbumit-workflow-state"
					:disabled="!deprecationReason.trim()"
					:loading="isLoading"
					@click="submitAction"
				></f-button>
			</f-div>
		</f-div>
	</f-popover>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { defineComponent, PropType } from "vue";

import { notificationsStore } from "@/modules/notifications/notifications-store";
import { componentCatalogStore } from "@/modules/release-cockpit-v2/component-catalog-store";
import { createDeprecationRequest } from "@/modules/workflow/workflow-service";
import { Component } from "@/protocol/cockpit";

import { componentOnboardStore } from "../../component-onboard-store";

export default defineComponent({
	name: "DeprecateComponentRequest",

	props: {
		selectedComponent: {
			type: Object as PropType<Component>,
			required: true
		}
	},

	emits: ["submit", "closeModal"],

	data() {
		return {
			deprecationReason: "",
			isLoading: false
		};
	},

	computed: {
		...mapStores(notificationsStore, componentCatalogStore, componentOnboardStore),

		componentTitle() {
			return `${this.selectedComponent.name} ${this.selectedComponent.version}`;
		}
	},

	methods: {
		async submitAction() {
			this.isLoading = true;
			try {
				await createDeprecationRequest({
					componentId: this.selectedComponent.id,
					reason: this.deprecationReason
				});

				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Request sent for deprecation",
					text: `${this.componentTitle} is sent for deprecation approval.`,
					status: "info"
				});
				this.isLoading = false;
				// Refresh the component list and component details for both the stores as this components in rendered in both onboarding and component catalog
				this.componentCatalogStore.getComponents();
				this.componentOnboardStore.getComponentById(this.selectedComponent.id);
				this.closeModal();
			} catch (error) {
				this.notificationsStore.ADD_TOAST({
					qaId: "toast-ory-verified",
					title: "Request not sent for deprecation",
					text: "There is some error in processing your request, please try again!!!",
					status: "warning"
				});
				this.isLoading = false;
			}
		},

		closeModal() {
			this.$emit("closeModal");
		}
	}
});
</script>
