const commonWords: string[] = [
	"should",
	"be",
	"using",
	"organization",
	"standard",
	"and",
	"or",
	"with",
	"in",
	"on",
	"to",
	"from",
	"shall",
	"encrypted",
	"at",
	"rest",
	"deployed",
	"secured",
	"appropriate",
	"not",
	"restricted",
	"configured",
	"ensure",
	"enabled",
	"covered",
	"equivalent",
	"collect",
	"prevent"
];

export function generateFileName(input: string): string {
	// Convert to lowercase and remove all numbers
	let fileName: string = input.toLowerCase().replace(/[0-9]/g, "");

	fileName = fileName
		.split(" ")
		.filter(word => !commonWords.includes(word))
		.join("_");

	fileName = fileName
		.replace(/[^a-z_]+/gi, "_") // Replace all special characters with underscore, numbers already removed
		.replace(/_+/g, "_") // Replace consecutive underscores with a single underscore
		.replace(/(^_+|_+$)/g, ""); // Remove leading and trailing underscores

	if (fileName.length === 0) {
		fileName = "file";
	} else if (fileName.length > 55) {
		// Intelligent Trimming: Retain both the beginning and the end of the string
		const frontPartLength = 27; // Keep the first 27 characters
		const backPartLength = 28; // Keep the last 28 characters, adjusting for underscore and balance
		const frontPart = fileName.substring(0, frontPartLength);
		const backPart = fileName.substring(fileName.length - backPartLength);
		fileName = `${frontPart}_${backPart}`.replace(/_+/g, "_").replace(/(^_+|_+$)/g, ""); // Use double underscore as a clear separator
	}

	return fileName;
}
