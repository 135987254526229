<template>
	<f-div width="100%" height="hug-content">
		<f-div direction="column" gap="small" padding="small" width="100%" height="hug-content">
			<f-div width="100%" padding="large" direction="row" gap="auto" height="hug-content">
				<f-div width="100%" padding="none none x-small none" direction="row" gap="auto">
					<f-div width="hug-content" height="hug-content" padding="none none large none">
						<f-text size="large" variant="heading" weight="bold" data-qa="dashboard-title">
							Compliance Report Dashboard
						</f-text>
					</f-div>
					<f-div width="hug-content" height="hug-content" padding="none">
						<f-button
							v-if="complianceReportStore.selectedApp"
							id="evidenceOptions"
							category="outline"
							label="DOWNLOAD REPORT"
							size="small"
							data-qa="download-report-button"
							:state="reportError ? 'danger' : 'neutral'"
							variant="round"
							:loading="reportLoading"
							@click="generateReport"
						></f-button>
					</f-div>
				</f-div>

				<f-div direction="row" gap="x-small" width="100%" padding="none">
					<f-text size="medium" variant="para"> Overview </f-text>
				</f-div>
			</f-div>
			<f-div direction="row" gap="none" height="hug-content" padding="none none large large">
				<f-div padding="small small none none" class="width-100-per" gap="small">
					<ComponentCard v-for="(comp, i) in overviewCards" :key="i" :comp="comp" />
				</f-div>
			</f-div>
			<TabsContainer />
		</f-div>
	</f-div>
</template>
<script lang="ts">
import { cloneDeep } from "lodash-es";
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { getByType } from "@/modules/constellation-map/correlation-service";
import { captureError } from "@/utils";

import { getControlSCount, generateReport } from "../compliance-report-service";
import { complianceReportStore } from "../compliance-report-store";
import { DASHBOARD_INIT_COMPONENT } from "../compliance-report-utils";

import ComponentCard from "./ComponentCard.vue";
import TabsContainer from "./TabsContainer.vue";

export default defineComponent({
	name: "ComplianceReportComponentContainer",

	components: {
		TabsContainer,
		ComponentCard
	},

	data: () => {
		return {
			overviewCards: cloneDeep(DASHBOARD_INIT_COMPONENT),
			reportLoading: false,
			reportError: false,
			orgError: false
		};
	},

	computed: {
		...mapStores(complianceReportStore)
	},

	mounted() {
		this.getControlsCount();
	},

	methods: {
		async getOrgs() {
			try {
				this.orgError = false;
				const data = {
					nodeType: "Organisation"
				};
				const resp = await getByType(data);
				if (resp.nodes?.length) {
					const [firstNode] = resp.nodes;

					if (firstNode?.props?.id && firstNode.props.name) {
						this.complianceReportStore.setCurrentOrg({
							id: String(firstNode.props.id),
							name: String(firstNode.props.name)
						});
					}
				}
			} catch (error) {
				captureError(error);
				this.orgError = true;
			}
		},

		async getControlsCount() {
			//Get critical, non-critical and total controls based on organisation
			await this.getOrgs();

			if (!this.complianceReportStore.currentOrg.id) {
				return;
			}

			const [
				totalOrgControls,
				criticalControls,
				nonCriticalControls,
				complaintControls,
				exemptedControls
			] = await Promise.all([
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					filter: "critical",
					status: "non-compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					filter: "non-critical",
					status: "non-compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					status: "compliant"
				}),
				getControlSCount({
					orgId: this.complianceReportStore.currentOrg.id,
					filter: "exempted",
					status: "non-compliant"
				})
			]);
			for (const card of this.overviewCards) {
				card.total = Number(totalOrgControls.controlsCount);
				if (card.name === "CRITICAL VIOLATION") {
					card.count = Number(criticalControls.controlsCount);
				}
				if (card.name === "NON-CRITICAL VIOLATION") {
					card.count = Number(nonCriticalControls.controlsCount);
				}
				if (card.name === "COMPLIANT") {
					card.count = Number(complaintControls.controlsCount);
				}
				if (card.name === "EXEMPTED") {
					card.count = Number(exemptedControls.controlsCount);
				}
			}
		},

		async generateReport() {
			this.reportLoading = true;
			this.reportError = false;
			try {
				const application = this.complianceReportStore.selectedApp.title;
				const response = await generateReport(application);
				const pdfBlob = new Blob([response.data], { type: "application/pdf" });
				const pdfUrl = URL.createObjectURL(pdfBlob);
				const a = document.createElement("a");
				a.href = pdfUrl;
				a.download = `Report-${Date.now()}.pdf`;
				a.click();
				URL.revokeObjectURL(pdfUrl);
				this.reportLoading = false;
			} catch (error) {
				captureError(error);
				this.reportLoading = false;
				this.reportError = true;
			}
		}
	}
});
</script>
