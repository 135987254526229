import { ConfigUtil } from "@ollion/flow-core-config";
import { reactive } from "vue";

import { UserTheme } from "./../user/user-types";

const TOKEN_KEY = "access_token";

const THEME_KEY = "theme";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/

const StorageService = reactive({
	token: null as null | string,
	userTheme: null as null | UserTheme,

	getToken() {
		if (!this.token) {
			this.token = localStorage.getItem(TOKEN_KEY);
		}

		return this.token;
	},

	saveToken(accessToken: string) {
		this.token = accessToken;
		localStorage.setItem(TOKEN_KEY, accessToken);
	},

	removeToken() {
		this.token = null;
		localStorage.removeItem(TOKEN_KEY);
	},

	getTheme() {
		if (!this.userTheme?.theme) {
			const initialUserTheme: UserTheme = {
				mode: "light-mode",
				theme: "f-ollion-light"
			};

			const storedTheme = localStorage.getItem(THEME_KEY);
			const initialUserThemeWithStoredTheme = storedTheme
				? JSON.parse(storedTheme)
				: initialUserTheme;

			if (initialUserThemeWithStoredTheme.theme === "light-theme") {
				initialUserThemeWithStoredTheme.theme = "f-ollion-light";
			} else if (initialUserThemeWithStoredTheme.theme === "dark-theme") {
				initialUserThemeWithStoredTheme.theme = "f-ollion-dark";
			}

			ConfigUtil.setConfig({
				theme: initialUserThemeWithStoredTheme.theme
			});

			this.userTheme = initialUserThemeWithStoredTheme;
		}

		return this.userTheme;
	},

	saveTheme(userTheme: UserTheme) {
		this.userTheme = userTheme;
		localStorage.setItem(THEME_KEY, JSON.stringify(userTheme));
	}
});

export { StorageService };
