<template>
	<PermissionsWrapper>
		<FullPageLoader v-if="isLoading" />
		<ErrorPage
			v-else-if="hasFetchError"
			message="Something went wrong when fetching components."
			data-qa="components-fetch-error"
		/>
		<EmptyState
			v-else-if="!component"
			message="Could not find the component you are looking for..."
			data-qa="component-not-found-error"
		/>
		<ComponentOnboardingPage v-else :component="component" :tab-name="tabName" />
	</PermissionsWrapper>
</template>

<script lang="ts">
import { EmptyState } from "@ollion/flow-vue3";
import { mapStores } from "pinia";
import { PropType, defineComponent } from "vue";

import ErrorPage from "@/modules/auth/components/ErrorPage.vue";
import PermissionsWrapper from "@/modules/core/components/PermissionsWrapper.vue";
import { componentOnboardStore } from "@/modules/release-cockpit-v2/component-onboard-store";
import FullPageLoader from "@/shared/components/FullPageLoader.vue";

import { OnboardingTabIdType } from "../release-cockpit-types";

import ComponentOnboardingPage from "./ComponentOnboardingPage.vue";

export default defineComponent({
	name: "ComponentOnboardingWrapper",

	components: {
		ComponentOnboardingPage,
		PermissionsWrapper,
		FullPageLoader,
		EmptyState,
		ErrorPage
	},

	props: {
		tabName: {
			type: String as PropType<OnboardingTabIdType>,
			required: false
		}
	},

	data: () => {
		return {
			isLoading: true,
			hasFetchError: false
		};
	},

	computed: {
		...mapStores(componentOnboardStore),

		componentId() {
			if (this.$route.params.componentId) {
				return String(this.$route.params.componentId);
			}
			return null;
		},

		component() {
			if (!this.componentId) {
				return null;
			}

			return this.componentOnboardStore.components[this.componentId] ?? null;
		}
	},

	watch: {
		componentId: {
			immediate: true,

			handler(newValue, oldValue) {
				if (newValue !== oldValue) {
					this.refreshComponent();
				}
			}
		}
	},

	methods: {
		refreshComponent() {
			if (this.componentId) {
				this.isLoading = true;
				this.componentOnboardStore
					.getComponentById(this.componentId)
					.catch(() => (this.hasFetchError = true))
					.finally(() => (this.isLoading = false));
			}
		}
	}
});
</script>
